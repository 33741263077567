/* eslint-disable react/display-name */
import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import { withStyles } from '@material-ui/core/styles';
import { fetchProfile, saveProfile } from '@actions/settings-actions';
import SettingsForm from '@components/settings/settings-form';
import * as colors from '@constants/colors';
import { formMapStateToProps, formMapDispatchToProps } from '@shared/form';
import { getUserAvatar } from '@utils/data-table/user';
import './settings.scss';

const CustomTextField = withStyles({
  root: {
    '& input': {
      color: colors.dotmapsBlack80
    },
    '& input.Mui-disabled': {
      color: colors.dotmapsBlack40
    },
    '& label.Mui-disabled': {
      color: colors.dotmapsBlack40
    },
    '& label.Mui-error': {
      color: colors.dotmapsError
    }
  }
})(TextField);

class Profile extends SettingsForm {
  // Override SettingsForm's componentWillMount load the user profile instead of the settings.
  UNSAFE_componentWillMount() {
    this.props.fetchProfile();
  }

  handleNameChange = event => this.onChange('name', event.target.value);

  handlePhoneChange = event => this.onChange('phone', event.target.value);

  handleSave = () => {
    this.props.saveProfile(this.state.data);
    this.setState({ modified: false });
  };

  renderPreForm = () => {
    const { data } = this.state;
    const userAvatarIcon = getUserAvatar(data, 'giant');
    return (
      <div data-testid="settings-profile-form-avatar" styleName="avatar">
        <div data-testid="settings-profile-form-icon" styleName="icon">
          {userAvatarIcon}
        </div>
        <div styleName="content">
          <div data-testid="settings-profile-form-name" styleName="name">
            {data.name}
          </div>
          <div data-testid="settings-profile-form-status" styleName="status">
            {data.is_active ? 'Active' : 'Inactive'}
          </div>
        </div>
      </div>
    );
  };

  getLayout = () => ({
    title: 'Your profile'
  });

  // eslint-disable-next-line no-unused-vars
  renderForm = layout => {
    const { data } = this.state;
    const { permissions } = this.props;
    return (
      <div data-testid="settings-profile-form" styleName="form">
        <div styleName="row row-two-columns">
          <div styleName="col1">
            <CustomTextField
              fullWidth
              label="Name"
              value={data.name}
              helperText=" "
              onChange={this.handleNameChange}
            />
          </div>
          <div styleName="col2">
            <CustomTextField
              fullWidth
              disabled
              label="Email"
              value={data.email}
              helperText="To update your email you need to contact app admins."
            />
          </div>
        </div>
        <div styleName="row row-two-columns">
          <div styleName="col1">
            <CustomTextField
              fullWidth
              label="Phone number"
              value={data.phone}
              helperText=" "
              onChange={this.handlePhoneChange}
            />
          </div>
          <div styleName="col2">
            <CustomTextField
              fullWidth
              disabled
              label="Agency"
              value={data.agency_name}
              helperText="To update your agency you need to contact app admins."
            />
          </div>
        </div>
        <div styleName="row row-two-columns">
          <CustomTextField
            fullWidth
            disabled
            label="Permission"
            value={data.permission ? permissions[data.permission].name : ''}
            helperText="To update your permission you need to contact app admins."
          />
        </div>
      </div>
    );
  };
}

Profile.propTypes = {
  fetchProfile: PropTypes.func,
  permissions: PropTypes.object,
  saveProfile: PropTypes.func
};

const mapStateToProps = state => {
  const { user: data } = state.settings;
  const permissions = state.dataTypes.permission;
  return { data, permissions, ...formMapStateToProps(state) };
};

const mapDispatchToProps = {
  fetchProfile,
  saveProfile,
  ...formMapDispatchToProps
};

export default connect(mapStateToProps, mapDispatchToProps)(Profile);
