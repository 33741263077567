/* eslint-disable react/jsx-no-bind */
import React from 'react';
import PropTypes from 'prop-types';
import { cloneDeep, isEmpty } from 'lodash';
import { detailEdit } from '@constants/mui-theme';
import AddIcon from '@material-ui/icons/Add';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import Button from '@material-ui/core/Button';
import DateDivider from '@shared/dialogs/edit-schedule-dialog-date-divider';
import Exclusions from '@shared/dialogs/edit-schedule-dialog-exclusions';
import RecurrenceList from '@shared/dialogs/edit-schedule-dialog-recurrence-list';
import FormattedDatePicker from '@shared/formatted-date-picker';
import { getUniqueWeekdaysWithOvernight } from '@utils/segment-schedule/common';
import {
  addSegmentScheduleRecurrence,
  deleteSegmentScheduleRecurrence,
  toggleSegmentScheduleException,
  segmentScheduleRecurrenceAllDayToggle,
  segmentScheduleRecurrenceDayChange,
  segmentScheduleRecurrenceTimeChange
} from '@utils/segment-schedule/recurrence';

import { pluralize } from '@utils/shared-utils';
import './edit-schedule-dialog.scss';

const RecurringScheduleType = ({
  endDate,
  errors,
  exclusionsOpen,
  getId,
  maxDate,
  minDate,
  onEndDateChange,
  onStartDateChange,
  segment,
  setExclusionsOpen,
  setSegment,
  startDate
}) => {
  const dateProps = { minDate, maxDate };
  const schedule = segment.schedules[0];

  const toggleExclusions = () => setExclusionsOpen(!exclusionsOpen);

  const uniqueWeekdays = getUniqueWeekdaysWithOvernight(schedule.recurrences);

  const addRecurrence = () => setSegment(addSegmentScheduleRecurrence(cloneDeep(segment)));
  const deleteRecurrence = recurrence => setSegment(deleteSegmentScheduleRecurrence(cloneDeep(segment), recurrence));
  const onRecurrenceAllDayToggle = recurrence => setSegment(segmentScheduleRecurrenceAllDayToggle(cloneDeep(segment), recurrence));
  const onRecurrenceDayChange = (recurrence, values) => setSegment(segmentScheduleRecurrenceDayChange(cloneDeep(segment), recurrence, values));
  const onRecurrenceStartTimeChange = (recurrence, value) => setSegment(
    segmentScheduleRecurrenceTimeChange(cloneDeep(segment), recurrence, 'start_time', value)
  );
  const onRecurrenceEndTimeChange = (recurrence, value) => setSegment(
    segmentScheduleRecurrenceTimeChange(cloneDeep(segment), recurrence, 'end_time', value)
  );
  const onExcludeDate = date => setSegment(toggleSegmentScheduleException(cloneDeep(segment), date));

  return (
    <div>
      <div styleName="section">
        <div styleName="title">Between</div>
        <div styleName="content content-dates">
          <FormattedDatePicker
            {...dateProps}
            id={getId('start_date_recurring')}
            clearable={false}
            errorText={errors.start_date}
            floatingLabelText="Start date"
            name="start_date"
            onChange={onStartDateChange}
            value={startDate}
            style={{...detailEdit.segmentEdit.dateRange}}
          />
          <DateDivider text="and" />
          <FormattedDatePicker
            {...dateProps}
            id={getId('end_date_recurring')}
            clearable={false}
            errorText={errors.end_date}
            floatingLabelText="End date"
            name="end_date"
            onChange={onEndDateChange}
            value={endDate}
            style={{...detailEdit.segmentEdit.dateRange}}
          />
        </div>
      </div>
      <div styleName="section-blank-divider" />
      <div styleName="section">
        <div styleName="title">
          <div styleName="title-text">
            Recurrence patterns
          </div>
          <div styleName="title-action">
            <Button startIcon={<AddIcon />} onClick={addRecurrence} color="primary">
              ADD PATTERN
            </Button>
          </div>
        </div>
        <div styleName="content">
          <RecurrenceList
            deleteRecurrence={deleteRecurrence}
            onAllDayToggle={onRecurrenceAllDayToggle}
            onDayChange={onRecurrenceDayChange}
            onStartTimeChange={onRecurrenceStartTimeChange}
            onEndTimeChange={onRecurrenceEndTimeChange}
            recurrences={schedule.recurrences}
          />
        </div>
      </div>
      <div styleName="section">
        <div onClick={toggleExclusions} styleName="title-toggle section-heading-divider" role="presentation">
          <div styleName="toggle-icon">
            {exclusionsOpen && <ArrowDropDownIcon />}
            {!exclusionsOpen && <ArrowRightIcon />}
          </div>
          <div styleName="toggle-label">
            Set exclusions
            {!isEmpty(schedule.exceptions) &&
              <div styleName="exclusions-count">
                ({schedule.exceptions.length} {pluralize(schedule.exceptions.length, 'exclusion')})
              </div>
            }
          </div>
        </div>
        {exclusionsOpen &&
          <div styleName="content">
            <div styleName="text">
              Scheduled hours that extend overnight will display as two days on the calendar.
              Excluding a day will impact all schedules overlapping with that day.
            </div>
            <div styleName="calendar">
              <Exclusions
                exceptions={schedule.exceptions}
                maxDate={endDate}
                minDate={startDate}
                onExcludeDate={onExcludeDate}
                weekdays={uniqueWeekdays}
              />
            </div>
          </div>
        }
      </div>
    </div>
  );
};

RecurringScheduleType.propTypes = {
  endDate: PropTypes.object,
  errors: PropTypes.object,
  exclusionsOpen: PropTypes.bool,
  getId: PropTypes.func,
  maxDate: PropTypes.object,
  minDate: PropTypes.object,
  onEndDateChange: PropTypes.func,
  onStartDateChange: PropTypes.func,
  segment: PropTypes.object,
  setExclusionsOpen: PropTypes.func,
  setSegment: PropTypes.func,
  startDate: PropTypes.object
};

export default RecurringScheduleType;
