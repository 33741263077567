import React, { memo } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import * as colors from '@constants/colors';
import SetBaselineDialog from '@components/group/dialogs/set-baseline';
import UpdateBaselineDialog from '@components/group/dialogs/update-baseline';
import { getGroupIsLoading } from '@selectors/groups-selector';
import Divider from '@material-ui/core/Divider';
import DotmapsLoader from '@shared/dotmaps-loader';
import { renderSavingsLong } from '@utils/gantt-utils';
import BaselineRow from './baseline-row';
import MetricsRows from './metrics-rows';
import '../drawer.scss';

const Metrics = () => {
  const { group } = useSelector(state => state.groups.edit);
  const loading = useSelector(getGroupIsLoading);
  const { dataId } = useParams();

  if (dataId === 'new') {
    return null;
  }

  const { baseline, current } = group;

  if (loading || !group || !group.id) {
    return (
      <div styleName="group-drawer-tab-loader-container">
        <DotmapsLoader color={colors.dotmapsBlue} display />
      </div>
    );
  }

  return (
    <div styleName="group-drawer-tab-content">
      <div styleName="container">
        <div styleName="header">
          <div styleName="title">Comparison</div>
        </div>
        {renderSavingsLong(baseline, current)}
      </div>
      <MetricsRows />
      <Divider />
      <div styleName="container">
        <div styleName="header">
          <div styleName="title">Baseline for comparison</div>
        </div>
        <div styleName="description">
          You can take a snapshot of this group's current state to track
          day savings. This calculation will update every time there is a
          change in the group.
        </div>
        <BaselineRow />
      </div>
      <SetBaselineDialog />
      <UpdateBaselineDialog />
    </div>
  );
};

export default memo(Metrics);
