import {
  dotmapsPointColor,
  dotmapsSegmentColor,
  dotmapsSelectedSegmentColor
} from './colors';

export const detailCircleStyle = {
  icon: {
    scale: 5,
    fillColor: dotmapsPointColor,
    fillOpacity: 1,
    strokeWeight: 0,
    googleSymbolPath: 'CIRCLE'
  },
  zIndex: 10
};

export const detailEditCircleStyle = {
  icon: {
    ...detailCircleStyle.icon,
    fillColor: '#fff',
    strokeColor: dotmapsSelectedSegmentColor,
    strokeWeight: 3,
    strokeOpacity: 1
  },
  zIndex: 50
};

export const measurementCircleStyle = {
  icon: {
    ...detailCircleStyle.icon,
    fillColor: '#fff',
    strokeColor: '#000',
    strokeWeight: 1,
    strokeOpacity: 1
  },
  zIndex: 50
};

export const detailActiveCircleStyle = {
  icon: {
    ...detailEditCircleStyle.icon,
    strokeColor: '#e62'
  },
  zIndex: 30
};

export const detailLineStyle = {
  geodesic: true,
  strokeColor: dotmapsSegmentColor,
  strokeWeight: 10
};

export const detailPolyLineStyle = {
  fillColor: dotmapsSegmentColor,
  fillOpacity: 0.2
};

export const detailPolygonStyle = {
  fillColor: dotmapsSegmentColor,
  fillOpacity: 0
};

export const detailEditLineStyle = {
  ...detailLineStyle,
  icons: [{
    icon: {
      path: 'M 0,-1 0,1',
      strokeOpacity: 0.7,
      scale: 4
    },
    offset: '0',
    repeat: '20px'
  }],
  strokeOpacity: 0,
  zIndex: 40
};

export const detailActiveLineStyle = {
  ...detailLineStyle,
  geodesic: true,
  strokeColor: dotmapsSelectedSegmentColor,
  zIndex: 20
};
