import {
  FETCH_CONFIG_SUCCESS,
  FETCH_CONFIG_UPDATE_SUCCESS
} from '@components/config/constants';

const initialState = {
  siteLoaded: false,
  userLoaded: false
};

const configReducer = (state = initialState, action) => {
  switch (action.type) {
  case FETCH_CONFIG_SUCCESS: {
    return {
      ...state,
      ...action.payload.data,
      siteLoaded: true
    };
  }
  // A config update was received, it brings all the configuration
  // settings that gets filled after the user is
  // authenticated.
  case FETCH_CONFIG_UPDATE_SUCCESS: {
    return {
      ...state,
      ...action.payload.data,
      userLoaded: true
    };
  }
  default:
    return state;
  }
};

export default configReducer;
