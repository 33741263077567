import React, { memo, useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import Paginator from '@shared/paginator';

import { getCount } from '@selectors/data-table-selector';
import { getPortalPaging } from '../selectors';

import { setDatatableFilter } from '@actions/data-table-actions';

import { useLegacyTypes } from '../hooks';

import styles from './footer.scss';

const PortalFooter = ({ dataType, subType }) => {
  // Replicates mapStateToProps bindings with hooks instead.
  // Alters filter binding to be compatible with new filters code.
  // Alters selectors to improve memoization.
  const dispatch = useDispatch();
  const stableProps = useMemo(() => ({ dataType, subType }), [dataType, subType]);
  const [legacyDataType, legacySubType] = useLegacyTypes(dataType, subType);
  const pageParams = useSelector(state => getPortalPaging(state, dataType, subType));
  const count = useSelector(state => getCount(state, stableProps));

  const handleLimitChange = useCallback(limit => {
    // Clamp offset to the new page ranges.
    const newPage = Math.floor(pageParams.offset / limit);
    const newOffset = newPage * limit;
    dispatch(setDatatableFilter(legacyDataType, legacySubType, { limit, offset: newOffset }));
  }, [legacyDataType, legacySubType, dispatch, pageParams]);

  const handleOffsetChange = useCallback(offset => {
    dispatch(setDatatableFilter(legacyDataType, legacySubType, { offset }));
  }, [legacyDataType, legacySubType, dispatch]);

  if (count > 0) {
    return (
      <div className={styles.footerSection}>
        <Paginator
          count={count}
          limit={Math.min(pageParams.limit || count, count)}
          offset={pageParams.offset || 0}
          onLimitChange={handleLimitChange}
          onOffsetChange={handleOffsetChange}
        />
      </div>
    );
  }
  return null;
};

PortalFooter.propTypes = {
  dataType: PropTypes.string,
  subType: PropTypes.string
};

export default memo(PortalFooter);
