import React, { memo } from 'react';
import { useSelector } from 'react-redux';
import { includes } from 'lodash';
import DateFilter from '@components/map/tray/filter/date-filter';
import EntityFilter from '@components/map/tray/filter/entity-filter';
import GroupsFilter from '@components/map/tray/filter/groups-filter';
import LayersFilter from '@components/map/tray/filter/layers-filter';
import { mapTabsConfig } from '@constants/component-configs';
import { getConfig } from '@utils/config-utils';
import '../tray.scss';

const components = {
  date: DateFilter,
  entity: EntityFilter,
  group: GroupsFilter,
  layer: LayersFilter
};

const Filter = () => {
  const { currentTab } = useSelector(state => state.map);
  const { types } = useSelector(state => state.entities);
  const entityTypes = Object.keys(types);

  if (currentTab !== mapTabsConfig.tabs.filters.value) {
    // Don't render if it's not the filters tab.
    return null;
  }

  const { filters } = getConfig().filters;

  return (
    <div styleName="map-tray-body-wrapper">
      <div styleName="map-tray-body map-tray-body-filter">
        {/* extra enclosing DIV required for scrollbars. */}
        <div>
          {filters.map(filter => {
            const FilterComponent = components[filter.name];
            if (filter.name === 'entity') {
              // Filters defined as 'entity' must exists not only as
              // filters config, but as an entity type.
              if (!includes(entityTypes, filter.type)) {
                // A filter for an entity type was defined,
                // but the entity type no longer exists or was renamed,
                // thus don't render a filter for it:
                return null;
              }
            }
            if (FilterComponent) {
              // eslint-disable-next-line react/jsx-key
              return <FilterComponent key={`${filter.name}-${filter.type}`} config={{...filter}} />;
            }
            return null;
          })}
        </div>
      </div>
    </div>
  );
};

export default memo(Filter);
