import React, { Fragment, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { openDashboardDialog } from '@actions/dashboard-actions';
import * as colors from '@constants/colors';
import * as dialog from '@constants/dialogs';
import Button from '@material-ui/core/Button';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import InsertDriveFileOutlinedIcon from '@material-ui/icons/InsertDriveFileOutlined';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import './top-bar-action-task-type-icons.scss';

const TopBarActionTaskTypeIcons = () => {
  const dispatch = useDispatch();
  const { data } = useSelector(state => state.dataDetail);
  const id = data ? data.id : null;
  const openDialog = useCallback(
    () => dispatch(openDashboardDialog(dialog.TASK_TYPE_PUBLISH, { id })),
    [dispatch, id]
  );
  if (!data) {
    return null;
  }
  const { draft, version } = data;
  const isUnpublished = draft && version > 1;

  return [
    <div styleName="task-type-icons-container" key={data.id}>
      {draft && (
        <Fragment>
          <div styleName="chip">
            {!isUnpublished && <InsertDriveFileOutlinedIcon htmlColor={colors.dotmapsDisabledText} />}
            {isUnpublished && <ErrorOutlineIcon htmlColor="#FF9800" />}
            <div styleName="chip-label">
              {!isUnpublished && 'Draft'}
              {isUnpublished && 'Unpublished changes'}
            </div>
          </div>
          <Button
            color="primary"
            onClick={openDialog}
            variant="contained"
          >
            PUBLISH
          </Button>
        </Fragment>
      )}
      {!draft && (
        <div styleName="chip">
          <LockOutlinedIcon htmlColor="#66BB6A" />
          <div styleName="chip-label">Published</div>
        </div>
      )}
    </div>
  ];
};

export default TopBarActionTaskTypeIcons;
