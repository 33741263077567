import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import { push } from 'connected-react-router';
import { getConfirmableDeleteAndNavigateAction } from '@actions/data-detail-actions';
import { showConfirmationDialog } from '@actions/confirmation-actions';
import { Icon } from '@mui';
import IconMenu from '@shared/icon-menu';
import IconMenuItem from '@shared/icon-menu-item';
import { getDashboardBackURL } from '@utils/navigation-utils';
import { canAccessWorkflow, canViewWorkflows } from '@utils/permission-utils';

const TopBarActionCycleMenu = () => {
  const dispatch = useDispatch();
  const { cycle } = useSelector(state => state.workflow);
  const { id } = useParams();
  const location = useLocation();

  const deleteItem = useCallback(
    () => {
      const source = getDashboardBackURL(location, 'cycle');
      dispatch(showConfirmationDialog(
        'Delete workflow?',
        `Are you sure you want to delete workflow "${cycle.name}"?`,
        getConfirmableDeleteAndNavigateAction('cycle', 'cycle', cycle.id, source)
      ));
    },
    [cycle, dispatch, location]
  );

  const goToActivityLog = useCallback(
    () => dispatch(push(
      {
        pathname: `/logs/workflow/cycle/${cycle.id}/`,
        state: { source: `/cycle/${cycle.id}` }
      }
    )),
    [cycle, dispatch]
  );

  if (id && canViewWorkflows()) {
    return (
      <IconMenu>
        <IconMenuItem key="activity" icon={<Icon>history</Icon>} onClick={goToActivityLog}>
          View activity log
        </IconMenuItem>
        {canAccessWorkflow('cycle', 'delete') &&
          <IconMenuItem key="delete" icon={<Icon>delete</Icon>} onClick={deleteItem}>
            Delete workflow
          </IconMenuItem>
        }
      </IconMenu>
    );
  }

  return null;
};

export default TopBarActionCycleMenu;
