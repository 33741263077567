import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { closeDashboardDialog } from '@actions/dashboard-actions';
import { fetchLogsData } from '@actions/logs-actions';
import * as dialog from '@constants/dialogs';
import EntityTypeIcon from '@icons/entity-type-icon';
import Dialog from '@shared/dialogs/dialog';
import DialogSnackbar from '@shared/dialogs/dialog-snackbar';
import LogsBox from '@shared/dialogs/overlap-status-dialog/logs-box';
import ActionsBox from '@shared/dialogs/overlap-status-dialog/actions-box';
import StatusBox from '@shared/dialogs/overlap-status-dialog/status-box';
import TriptychWidget from '@shared/list-item/widgets/triptych-widget';
import { getStatus } from '@utils/data-detail/conflicts';
import {
  reachingTableEnd
} from '@utils/data-table-utils';
import { getConfigLabel, getConfigValue } from '@utils/list-item-utils';
import {
  allowResolution,
  getResolutionPermissions
} from '@utils/permission-utils';
import './overlap-status-dialog.scss';

class OverlapStatusDialog extends Component {
  // Check if the entity.id exists in the list of opportunities of
  // the main entity.
  isOpportunity = () => {
    const { opportunities, entity } = this.props;
    const { id } = entity;
    return typeof opportunities.find(({ id: opportunityId }) => opportunityId === id) !== 'undefined';
  };

  getOverlapStatus = () => {
    const {
      mainEntity: { overlap_status: mainOverlapStatus },
      entity: { overlap_status }
    } = this.props;
    return getStatus(mainOverlapStatus, overlap_status, this.isOpportunity());
  };

  handleCancel = () => this.props.closeDashboardDialog(dialog.OVERLAP_STATUS);

  renderTitle = () => {
    const { entity } = this.props;
    return (
      <div styleName="overlap-status-title-container">
        <div styleName="icon">
          <EntityTypeIcon type={entity.type_name} />
        </div>
        <div styleName="text">ID {entity.id}</div>
      </div>
    );
  };

  getTitleName = () => getConfigValue(this.props.entity.type_name, 'title', this.props.entity);

  getEntityIcon = () => {
    const { entity } = this.props;
    const { icon } = entity;
    return (
      <div styleName="icon">
        {icon && <img src={icon} alt="" />}
      </div>
    );
  };

  getTitle = () => {
    const { entity } = this.props;
    const label = getConfigLabel(entity.type_name, 'title');
    return (
      <div styleName="title-row">
        {label && <div styleName="label">{label}:</div>}
        {this.getEntityIcon()}
        <div styleName="title">{this.getTitleName()}</div>
      </div>
    );
  };

  getPriority = categories => categories.find(
    category => category.name === 'priority'
  );

  getDate = () => {
    const { entity } = this.props;
    const { category_dict, start_date, end_date } = entity;
    const priority = category_dict && this.getPriority(category_dict);
    return (
      <TriptychWidget
        displayDuration
        priority={priority ? priority.id : null}
        start={start_date}
        end={end_date}
      />
    );
  };

  renderEntityCard = () => {
    const { entity } = this.props;
    return (
      <div styleName="overlap-status-entity-card-container">
        <div styleName="name">{entity.name}</div>
        {this.getTitle()}
        <div styleName="date-row">{this.getDate()}</div>
      </div>
    );
  };

  onScroll = event => {
    const { loading, next } = this.props;
    if (reachingTableEnd(event) && !loading && next) {
      const { mainEntity } = this.props;
      this.props.fetchLogsData('overlap', 'conflict', mainEntity.id, next, { filters: 'comment_filters' });
    }
  };

  renderBody = () => {
    const { entity, mainEntity } = this.props;
    const { status } = this.getOverlapStatus();
    const isOpportunity = this.isOpportunity();
    const { overlap_status } = entity;
    const overlapStatus = overlap_status && overlap_status.length > 0 ? overlap_status[0] : null;
    return (
      <div styleName="overlap-status-body-container" onScroll={this.onScroll}>
        {this.renderEntityCard()}
        {allowResolution(entity.type_name, mainEntity.type_name) && (
          <StatusBox
            agency1={mainEntity.agency_name}
            agency2={entity.agency_name}
            overlapStatus={overlapStatus}
            status={status}
          />
        )}
        <ActionsBox
          entity={entity}
          isOpportunity={isOpportunity}
          mainEntity={mainEntity}
          permissions={getResolutionPermissions(entity, mainEntity, status)}
        />
        <LogsBox
          app="overlap"
          id={entity.id}
          isOpportunity={isOpportunity}
          mainId={mainEntity.id}
          mainTypeName={mainEntity.type_name}
          model="conflict"
          typeName={entity.type_name}
        />
      </div>
    );
  };

  render() {
    const { activeDialogs, mainEntity, entity } = this.props;
    if (!entity || !mainEntity || !activeDialogs[dialog.OVERLAP_STATUS]) {
      return null;
    }
    return (
      <Dialog
        maxWidth="md"
        onClose={this.handleCancel}
        title={this.renderTitle()}
      >
        {this.renderBody()}
        <DialogSnackbar />
      </Dialog>
    );
  }
}

OverlapStatusDialog.propTypes = {
  activeDialogs: PropTypes.object,
  closeDashboardDialog: PropTypes.func,
  entity: PropTypes.object,
  fetchLogsData: PropTypes.func,
  loading: PropTypes.bool,
  mainEntity: PropTypes.object,
  next: PropTypes.string,
  opportunities: PropTypes.array
};

const getMainEntity = (props, dialogParams) => {
  if (props.mainEntity) {
    return props.mainEntity;
  }
  if (dialogParams && dialogParams.mainEntity) {
    return dialogParams.mainEntity;
  }
  return null;
};

const mapStateToProps = (state, props) => {
  const {
    activeDialogs,
    dialogParams
  } = state.dashboard;
  const { loading, next } = state.logs;
  const { entity } = dialogParams[dialog.OVERLAP_STATUS] || {};
  const mainEntity = getMainEntity(props, dialogParams[dialog.OVERLAP_STATUS]);
  return { activeDialogs, entity, loading, mainEntity, next };
};

const mapDispatchToProps = { closeDashboardDialog, fetchLogsData };

export default connect(mapStateToProps, mapDispatchToProps)(OverlapStatusDialog);
