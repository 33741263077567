import React, { Component } from 'react';
import PropTypes from 'prop-types';

export default class MapData extends Component {
  UNSAFE_componentWillMount() {
    if (this.props.fetchData) {
      this.props.fetchData(this.props.next);
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { next } = nextProps;
    const {fetchData, setEmpty} = this.props;
    if (this.props.next !== next) {
      if (next) {
        if (fetchData) {
          fetchData(next);
        }
      } else {
        if (setEmpty) { // eslint-disable-line no-lonely-if
          setEmpty();
        }
      }
    }
  }

  render() {
    return this.props.list.map(element => {
      const key = element.id;
      return <this.props.elementRenderer key={key} {...element} />;
    });
  }
}

MapData.propTypes = {
  elementRenderer: PropTypes.oneOfType(
    // Something that can be used as a component.
    [PropTypes.func, PropTypes.string, PropTypes.shape({render: PropTypes.func})]
  ),
  fetchData: PropTypes.func,
  list: PropTypes.array,
  next: PropTypes.string,
  setEmpty: PropTypes.func
};
