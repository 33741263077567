/* eslint-disable react/jsx-no-bind */
/* eslint-disable react/prop-types */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { clearLogs, fetchLogsData } from '@actions/logs-actions';
import {
  dotmapsBlack40Alt,
  dotmapsBlack80,
  dotmapsBlue,
  dotmapsVeryLightGray,
  overlayTextColor
} from '@constants/colors';
import LogItem from '@shared/comments/log-item';
import CustomChip from '@shared/custom-chip';
import DotmapsLoader from '@shared/dotmaps-loader';
import CommentBox from '@shared/dialogs/overlap-status-dialog/actions-comment-box';
import { allowResolution } from '@utils/permission-utils';
import './logs-box.scss';

class LogsBox extends Component {
  state = { filter: 'All' };

  UNSAFE_componentWillMount() {
    this.initialLoad();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.reload) {
      this.initialLoad();
    }
  }

  initialLoad = () => {
    const { app, mainId, model } = this.props;
    // Logs must be cleared before the first retrieval, since they were
    // designed to always append (when we scroll), it doesn't work like
    // all other fetch APIs in this app.
    this.props.clearLogs();
    this.props.fetchLogsData(app, model, mainId, null, { filters: 'comment_filters' });
  };

  // Return all log items, including only the ones related to the two entities
  // of this overlap.
  //
  // The Activity Log API call returns all entries about 'resolve', 'revoke' and 'comment'
  // actions for the specified entity ID, however for 'resolve' and 'revoke', all records
  // where this entity ID is referenced are retrieved and that includes other entity
  // IDs other than then second entity ID of this overlap.
  //
  // We need to restrict the list to only 'resolve' and 'revoke' actions that belong
  // only to the two entity IDs of this overlap.
  getItems = () => {
    const { id, items, mainId } = this.props;
    return items.filter(item => {
      const {
        entry: { action },
        attrs: { id1, id2 }
      } = item;
      // 'resolve' or 'revoke' actions contain id1 and  id2 attributes.
      if ((action === 'overlap_resolve' || action === 'overlap_revoke') && id1 && id2) {
        // For resolve/revoke, include the item only if the two ids in this overlap
        // are both referenced in the log entry attributes.
        return (id1 === id && id2 === mainId) || (id1 === mainId && id2 === id);
      }
      // For comments, check if one of the ids matches the overlapping entity.
      if (action === 'overlap_comment' && id1 && id2) {
        return id === id1 || id === id2;
      }
      return true;
    });
  };

  // Return items filtered by the currently selected filter.
  getFilteredItems = () => {
    const items = this.getItems();
    const { filter } = this.state;
    if (filter === 'Comments') {
      return items.filter(item => item.entry.action === 'overlap_comment');
    } else
      if (filter === 'Resolutions') {
        return items.filter(
          item => item.entry.action === 'overlap_resolve' || item.entry.action === 'overlap_revoke'
        );
      }
    // Else, filter === 'All':
    return items;
  };

  // Returns true if there any log item about a resolve or a revoke:
  hasResolutionItems = () => this.getItems().filter(
    item => item.entry.action === 'overlap_resolve' || item.entry.action === 'overlap_revoke'
  ).length > 0;

  // Return the index of the first specified action.
  getIndex = action => this.getItems().findIndex(item => item.entry.action === action);

  renderLogs = () => this.getFilteredItems().map(
    (item, index) => (
      <LogItem
        key={index}
        index={index}
        item={item}
        resolveIndex={this.getIndex('overlap_resolve')}
        revokeIndex={this.getIndex('overlap_revoke')}
      />
    ));

  setFilter = id => this.setState({ filter: id });

  renderFilterChip = id => (
    <div styleName="filter">
      <CustomChip
        colors={{
          background: this.state.filter === id ? dotmapsBlack40Alt : dotmapsVeryLightGray,
          foreground: this.state.filter === id ? overlayTextColor : dotmapsBlack80
        }}
        label={id}
        onClick={() => this.setFilter(id)}
      />
    </div>
  );

  renderFilters = () => {
    const { isOpportunity, mainTypeName, typeName } = this.props;
    // Hide filters if this entity or the main entity don't
    // allow resolution:
    if (!allowResolution(mainTypeName, typeName)) {
      return null;
    }
    // Also hide it, if it's an opportunity and there are
    // no resolve/revoke items (which means it wasn't
    // previously a conflict).
    if (isOpportunity && !this.hasResolutionItems()) {
      return null;
    }
    return (
      <div styleName="button-filters">
        <div styleName="filter">Show:</div>
        {this.renderFilterChip('All')}
        {this.renderFilterChip('Comments')}
        {this.renderFilterChip('Resolutions')}
      </div>
    );
  };

  render() {
    const { loading, next } = this.props;
    const items = this.getFilteredItems();
    const hasLogs = items && items.length > 0;

    return (
      <div styleName="logs-box-container">
        <div styleName="title">
          Activity
        </div>
        {this.renderFilters()}
        <CommentBox />
        <div styleName="log-list">
          {hasLogs && this.renderLogs()}
          {!hasLogs && !loading && (
            <span styleName="empty">No activity to show.</span>
          )}
        </div>
        <DotmapsLoader color={dotmapsBlue} display={loading && !next} />
        {/* When we load more through scrolling, show the indicator at the bottom. */}
        {loading && next && (
          <div styleName="spinner">
            <DotmapsLoader color={dotmapsBlue} display={loading && next} />
          </div>
        )}
      </div>
    );
  }
}

LogsBox.propTypes = {
  app: PropTypes.string,
  clearLogs: PropTypes.func,
  fetchLogsData: PropTypes.func,
  id: PropTypes.number,
  isOpportunity: PropTypes.bool,
  items: PropTypes.array,
  loading: PropTypes.bool,
  mainId: PropTypes.number,
  mainTypeName: PropTypes.string,
  model: PropTypes.string,
  next: PropTypes.string,
  reload: PropTypes.bool,
  typeName: PropTypes.string
};

const mapStateToProps = state => {
  const { list: items, loading, next, reload } = state.logs;
  return { items, loading, next, reload };
};

const mapDispatchToProps = { clearLogs, fetchLogsData };

export default connect(mapStateToProps, mapDispatchToProps)(LogsBox);
