import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import ListItem from './row-list-item';
import LocationListItem from './row-location-list-item';
import { isLocationTypeOpen } from '@utils/gantt-utils';
import './row.scss';

const Row = ({
  row: {
    id,
    label,
    nestedLocations,
    rows,
    start_date,
    end_date
  }
}) => {
  const { locationType } = useSelector(state => state.groups.gantt.filters);
  const { locationTypeGroups } = useSelector(state => state.groups.gantt);
  const isOpen = isLocationTypeOpen(id, locationTypeGroups, locationType);

  return (
    <div styleName="gantt-drawer-row">
      <LocationListItem
        start_date={start_date}
        end_date={end_date}
        isOpen={isOpen}
        id={id}
        key={id}
        label={label}
        level={0}
      />
      {isOpen && rows.map(row => {
        if (nestedLocations) {
          const isNestedOpen = isLocationTypeOpen(row.id, locationTypeGroups, locationType);
          return (
            <div key={row.id}>
              <LocationListItem
                start_date={row.start_date}
                end_date={row.end_date}
                isOpen={isNestedOpen}
                id={row.id}
                key={row.id}
                label={row.label}
                level={1}
              />
              {isNestedOpen && row.rows.map(nestedRow => <ListItem key={nestedRow.id} level={2} {...nestedRow} />)}
            </div>
          );
        }
        return <ListItem key={row.id} level={1} {...row} />;
      })}
    </div>
  );
};

Row.propTypes = {
  row: PropTypes.object
};

export default Row;
