import { includes } from 'lodash';
import { getEntitiesListConfig } from '@constants/config';
import { getConfig } from '@utils/config-utils';

const getEnvironmentUrl = () => {
  switch (process.env.ENVIRONMENT) {
  case 'prod':
  case 'stage':
    return '';
  default:
    // return 'https://dev.dotmapsapp.com';
    return 'http://localhost:8000';
  }
};

// XXX we should serve https if the page is served https
export const BASE_URL = getEnvironmentUrl();
export const BASE_API_URL = `${BASE_URL}/api`;

const FORMAT = 'format=json';

export const isEntity = type => includes(getEntitiesListConfig(), type);

// TODO: This list will grow and we might want to make it configurable via the backend's config:
export const isAdminDataType = type => type === 'task_type' || type === 'workflow';

export const getAPIRequestUrl = (type, id = null, format = FORMAT) => {
  if (isEntity(type) || type === 'moratorium') {
    return `${BASE_API_URL}/map/${id ? `${id}/` : ''}?type=${type}&${format}`;
  }
  return `${BASE_API_URL}/${type}/${id ? `${id}/` : ''}?${format}`;
};

export const getTaskAPIRequestUrl = (model, type, value, format = FORMAT) => {
  return `${BASE_API_URL}/${model}/?${type}=${value}&${format}`;
};

export const getDetailAPIRequestUrl = (type, id, format = '') => {
  if (isEntity(type)) {
    return `${BASE_API_URL}/map/${id ? `${id}/?type=${type}` : `?type=${type}`}${format}`;
  }
  return `${BASE_API_URL}/${type}/${id ? `${id}/` : ''}${format}`;
};

export const getPublicDetailAPIRequestUrl = (type, id, format = '') => {
  return `${BASE_API_URL}/public/${id ? `${id}/?type=${type}` : `?type=${type}`}${format}`;
};

export const getAuthRequestUrl = type => `${BASE_URL}/${type}/?${FORMAT}`;

export const getAutocompleteRequestUrl = (type, param, subType, attrField, filters = '') => {
  if (isEntity(type)) {
    if (attrField) {
      return `${BASE_URL}/map/autocomplete/?param=${param}&type=${type}&filter_field=${attrField}${filters}`;
    }
    return `${BASE_URL}/map/autocomplete/?param=${param}&type=${type}${filters}`;
  }
  if (subType && type !== subType) {
    // If it's a search for overlaps, set the entity type parameter:
    return `${BASE_URL}/${type}/autocomplete/?param=${param}&type=${subType}${filters}`;
  }
  return `${BASE_URL}/${type}/autocomplete/?param=${param}${filters}`;
};

export const getBucketEndPoint = () => getConfig().GOOGLE_CLOUD_STORAGE_BUCKET_URL;
