/* eslint-disable react/jsx-no-bind */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useContext, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';

import { Button, Icon, IconButton, Modal, Tooltip } from '@mui';
import Skeleton from '@material-ui/lab/Skeleton';
import { isValidUrlWithMessage } from '@utils/validation-utils';

import {
  CARD_SIZE_SM,
  CARD_STATE_LOADING,
  CARD_TYPE_EMBED
} from './constants';

import CustomEmbedFormView from './dialogs/custom-embed-form-view';
import DashboardContext from './dashboard-context';

import './dashboard-card.scss';

const MAX_CHAR_WIDTH_SM = 30;
const MAX_CHAR_WIDTH_LG = 90;
const TITLE_LOAD_SKELETON_WIDTH = '160px';

const blankIcon = (
  <div key="blank-icon" style={{ visibility: 'hidden' }}>
    <IconButton>
      <Icon>blank</Icon>
    </IconButton>
  </div>
);

const DashboardCardTitlebar = ({ card }) => {
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [urlErrorMsg, setUrlErrorMsg] = useState('');
  const {
    handleDeleteEmbedCard,
    handleEditEmbedCard,
    loadingEmbedCard
  } = useContext(DashboardContext);
  const [cardData, setCardData] = useState(card);

  const isLoading = card.contentState === CARD_STATE_LOADING;
  const isEditable = card.type === CARD_TYPE_EMBED;
  const charThreshold =
    card.size === CARD_SIZE_SM ? MAX_CHAR_WIDTH_SM : MAX_CHAR_WIDTH_LG;
  const overflowing = card.title.length > charThreshold;

  const closeAndEditCard = () => {
    setOpenEditModal(false);
    handleEditEmbedCard(cardData);
  };

  const editIcon = isEditable ? (
    <div
      key="edit-card-icon"
      styleName="card-action-icon"
      onClick={() => setOpenEditModal(true)}
      data-cy={`dashboardCardEditIcon_${card.title}`}
    >
      <Tooltip title="Delete Card">
        <IconButton>
          <Icon>edit</Icon>
        </IconButton>
      </Tooltip>
    </div>
  ) : (
    <div />
  );

  const deleteIcon = isEditable ? (
    <div
      key="delete-card-icon"
      styleName="card-action-icon"
      onClick={() => setOpenDeleteModal(true)}
      data-cy={`dashboardCardDeleteIcon_${card.title}`}
    >
      <Tooltip title="Delete Card">
        <IconButton>
          <Icon>delete</Icon>
        </IconButton>
      </Tooltip>
    </div>
  ) : (
    <div />
  );

  const getDeleteCardFooter = () => (
    <div>
      <Button
        onClick={() => setOpenDeleteModal(false)}
        style={{ marginRight: '0.5rem' }}
      >
        CANCEL
      </Button>
      <Button
        color="error"
        variant="contained"
        onClick={() => handleDeleteEmbedCard(card)}
      >
        DELETE
      </Button>
    </div>
  );

  const editFormIsValid = useMemo(() => {
    const [isURLValid, urlError] = isValidUrlWithMessage(cardData.embedLink);
    setUrlErrorMsg(urlError);
    const isHyperlinkValid = !R.isEmpty(cardData.embedLink) && isURLValid;

    return isHyperlinkValid && !R.isEmpty(cardData.title);
  }, [cardData]);

  const cardActionIcons =
    !isLoading && isEditable
      ? [
        ...(deleteIcon ? [deleteIcon] : []),
        ...(editIcon ? [editIcon] : [])
      ]
      : [blankIcon];

  return (
    <>
      <div styleName="card-titlebar">
        <Tooltip title={overflowing ? card.title : ''}>
          <div styleName="card-title">
            {isLoading ? (
              <Skeleton width={TITLE_LOAD_SKELETON_WIDTH} />
            ) : (
              card.title
            )}
          </div>
        </Tooltip>
        <div styleName="card-title-icons">
          {cardActionIcons.map(icon => icon)}
        </div>
      </div>
      <Modal
        title="Edit Card: Custom Embed"
        open={openEditModal}
        onCancel={() => setOpenEditModal(false)}
        onExited={() => setOpenEditModal(false)}
        onConfirm={closeAndEditCard}
        disabled={!editFormIsValid || loadingEmbedCard}
        data-cy={`dashboardCardEditModal_${card.title}`}
        confirmButtonText="SAVE"
      >
        <CustomEmbedFormView
          cardData={cardData}
          setCardData={setCardData}
          urlErrorMsg={urlErrorMsg}
        />
      </Modal>
      <Modal
        title="Delete Card?"
        open={openDeleteModal}
        onCancel={() => setOpenDeleteModal(false)}
        onExited={() => setOpenDeleteModal(false)}
        onConfirm={() => handleDeleteEmbedCard(card)}
        disabled={loadingEmbedCard}
        data-cy={`dashboardCardDeleteModal_${card.title}`}
        width="sm"
        footer={getDeleteCardFooter()}
      >
        Are you sure you want to delete this card?
      </Modal>
    </>
  );
};

DashboardCardTitlebar.propTypes = {
  card: PropTypes.object
};

export default DashboardCardTitlebar;
