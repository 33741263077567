import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';

// Allow custom dialog width values (we either use the Material UI defaults, or a custom one).
const getWidthValue = (theme, width) => {
  // It the 'maxWidth' value exists in the Material UI breakpoint array
  // (i.e. it's xs, sm, md, lg, xl), then use that breakpoint value.
  if (width in theme.breakpoints.values) {
    return theme.breakpoints.values[width];
  }

  // If it's not a xs, sm, md, etc value, then it's a custom width:
  return width;
};

const useStyles = makeStyles(theme => ({
  paper: ({ maxWidth = 'sm' }) => ({
    width: getWidthValue(theme, maxWidth)
  })
}));

const styles = theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(2)
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[700]
  }
});

const DialogTitle = withStyles(styles)(props => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles(theme => ({
  root: {
    padding: theme.spacing(2)
  }
}))(MuiDialogContent);

const DialogActions = withStyles(theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(1)
  }
}))(MuiDialogActions);

const CustomizedDialog = ({
  actions,
  dividers = false,
  children,
  contentStyle,
  fullWidth = true,
  maxWidth = 'sm',
  onClose,
  open = true,
  title
}) => {
  const classes = useStyles({ maxWidth });
  return (
    <Dialog
      aria-labelledby="customized-dialog-title"
      classes={{ paper: classes.paper }}
      fullWidth={fullWidth}
      maxWidth={false}
      onClose={onClose}
      open={open}
    >
      <DialogTitle id="customized-dialog-title" onClose={onClose}>
        {title}
      </DialogTitle>
      <DialogContent dividers={dividers} style={contentStyle}>
        {children}
      </DialogContent>
      {actions && (
        <DialogActions>
          {actions}
        </DialogActions>
      )}
    </Dialog>
  );
};

CustomizedDialog.propTypes = {
  actions: PropTypes.node,
  children: PropTypes.node,
  contentStyle: PropTypes.object,
  dividers: PropTypes.bool,
  fullWidth: PropTypes.bool,
  maxWidth: PropTypes.string,
  onClose: PropTypes.func,
  open: PropTypes.bool,
  title: PropTypes.string
};

export default CustomizedDialog;
