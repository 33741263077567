/* eslint-disable dot-notation */
import React from 'react';
import { useSelector } from 'react-redux';
import { colors } from '@constants/colors';
import { Icon } from '@mui';
import { getCycleGroupCycles } from '@selectors/groups-selector';
import CycleCards from '@shared/cycle-cards';
import commonStyles from '../entity-list/entity-list.scss';  // eslint-disable-line css-modules/no-unused-class
import styles from './cycle-list.scss';

const CycleList = () => {
  const cycles = useSelector(getCycleGroupCycles);
  return (
    <div className={styles['cycle-list']}>
      {cycles.length === 0 && (
        <div className={commonStyles['no-records']}>
          <div className={commonStyles['icon']}>
            <Icon
              color={colors.opacity.black0_4}
              style={{ fontSize: '70px' }}
            >
              view_list
            </Icon>
          </div>
          <div className={commonStyles['title']}>No workflows</div>
        </div>
      )}
      <CycleCards cycles={cycles} />
    </div>
  );
};

export default CycleList;
