import React from 'react';
import PropTypes from 'prop-types';
import { Icon, IconButton } from '@mui';

const MapToolbarIconButton = ({
  children,
  color,
  icon,
  iconInactive,
  onClick,
  tooltip
}) => (
  <IconButton
    onClick={onClick}
    tooltip={tooltip}
    style={{
      padding: 0,
      margin: '2px 6px',
      width: '24px',
      height: '24px',
      ...(iconInactive && { cursor: 'default' })
    }}
  >
    {children}
    {!children && (
      <Icon
        color={color}
        style={{...(iconInactive && { opacity: 0.5 })}}
      >
        {icon}
      </Icon>
    )}
  </IconButton>
);

MapToolbarIconButton.propTypes = {
  children: PropTypes.node,
  color: PropTypes.string,
  icon: PropTypes.string,
  iconInactive: PropTypes.bool,
  onClick: PropTypes.func,
  tooltip: PropTypes.string
};

export default MapToolbarIconButton;
