// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.src-components-group-drawer-entity-list-conflict-list-___conflict-list__conflict-loader-container{height:5.5em;margin:1.5rem 0 0 0;position:relative}.src-components-group-drawer-entity-list-conflict-list-___conflict-list__conflict-list-container{overflow:visible}
`, "",{"version":3,"sources":["webpack://./src/components/group/drawer/entity-list/conflict-list/conflict-list.scss"],"names":[],"mappings":"AAGA,mGACE,YAAa,CACb,mBAAoB,CACpB,iBAAkB,CACnB,iGAGC,gBAAiB","sourcesContent":["@import '../../../../../styles/vars';\n@import '../../../../../styles/mixins';\n\n.conflict-loader-container {\n  height: 5.5em;\n  margin: 1.5rem 0 0 0;\n  position: relative;\n}\n\n.conflict-list-container {\n  overflow: visible;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"conflict-loader-container": `src-components-group-drawer-entity-list-conflict-list-___conflict-list__conflict-loader-container`,
	"conflictLoaderContainer": `src-components-group-drawer-entity-list-conflict-list-___conflict-list__conflict-loader-container`,
	"conflict-list-container": `src-components-group-drawer-entity-list-conflict-list-___conflict-list__conflict-list-container`,
	"conflictListContainer": `src-components-group-drawer-entity-list-conflict-list-___conflict-list__conflict-list-container`
};
export default ___CSS_LOADER_EXPORT___;
