import {
  DATA_TYPES_FETCH_SUCCESS
} from '@constants/action-types';
import * as R from 'ramda';
import { getAPIRequestUrl } from '@constants/endpoints';
import axios from 'axios';
import { getServerErrorAction } from './shared-actions';

const fetchDataTypesSuccess = (data, dataType) => ({
  type: DATA_TYPES_FETCH_SUCCESS,
  payload: data,
  dataType
});

export const fetchDataType = (type, url_type = null) => async dispatch => {
  const url = `${url_type ? getAPIRequestUrl(url_type) : getAPIRequestUrl(type)}&all=True&pagination=off`;
  await axios
    .get(url)
    .then(
      data => dispatch(fetchDataTypesSuccess(data, type)),
      error => dispatch(getServerErrorAction(type, error))
    );
};

// Fetch all data types from the backend
export const fetchDataTypes = () => (dispatch, getState) => {
  // Types to skip:
  const skipTypes = [
    'group',  // This is loaded only on the page that needs it.
    'segment_direction'  // This is hardcoded.
  ];
  const dataTypes = R.without(skipTypes, Object.keys(getState().dataTypes));
  dataTypes.map(type => fetchDataType(type)(dispatch));
};

export const fetchPublicDataTypes = () => dispatch => {
  // datatypes needed for public intake form
  const publicTypes = [
    'map_category_type',
    'map_category',
    'segment_impact_type',
    'map_type'
  ];
  publicTypes.map(type => fetchDataType(type)(dispatch));
  // The public form needs agency and user data for filling the form,
  // call a public API to retrieve non-sensitive data (only id and name are returned).
  fetchDataType('agency', 'agency_public')(dispatch);
  fetchDataType('user', 'user_public')(dispatch);
};

export const saveDataType = (type, id, payload, callback, errorCallback) => dispatch => {
  const method = id ? 'patch' : 'post';
  const url = `${getAPIRequestUrl(type, id)}`;
  const request = axios[method](url, payload);
  request.then(
    data => {
      if (callback) {
        callback(data.data);
      }
    },
    error => {
      if (errorCallback) {
        errorCallback(error);
      } else {
        dispatch(getServerErrorAction(type, error));
      }
    }
  );
};
