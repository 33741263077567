import {
  MAP_TRAY_OVERLAP_ENTITY_CACHE_ADD,
  MAP_TRAY_OVERLAP_ENTITY_CACHE_REMOVE
} from '@constants/action-types';

import { BASE_API_URL } from '@constants/endpoints';
import axios from 'axios';

export const removeEntitiesFromOverlapCacheAction = entityIds => ({
  type: MAP_TRAY_OVERLAP_ENTITY_CACHE_REMOVE,
  entityIds
});

export const addEntitiesToOverlapCacheAction = (entityType, fetchIds, entityDetails) => ({
  type: MAP_TRAY_OVERLAP_ENTITY_CACHE_ADD,
  entityType,
  fetchIds,
  entityDetails
});

let overlapEntityDetailsController = new AbortController();
let failureCount = 0;
export const addToOverlapCache = entities => async dispatch => {
  const [type, ids] = Object.entries(entities).find(([, values]) => values && values.length > 0);
  const fetchIds = ids.slice(0, 500);
  const url = `${BASE_API_URL}/map/?type=${type}&pk__in=${fetchIds.join(',')}&limit=1000&geometry=false`;
  overlapEntityDetailsController.abort();
  overlapEntityDetailsController = new AbortController();
  const request = axios.get(
    url,
    {signal: overlapEntityDetailsController.signal}
  );
  let entityDetails;
  try {
    const response = await request;
    entityDetails = response.data.results;
  } catch (error) {
    if (axios.isCancel(error)) { //eslint-disable-line import/no-named-as-default-member
      return null;
    }
    failureCount = failureCount + 1;
    // Force a state change to cause re-calculation and re-attempt at request
    await new Promise(resolve => setTimeout(resolve, failureCount * 1000));
    return dispatch(addEntitiesToOverlapCacheAction(type, [], []));
  }
  return dispatch(addEntitiesToOverlapCacheAction(type, fetchIds, entityDetails));
};
