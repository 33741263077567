import React from 'react';
import PropTypes from 'prop-types';
import './tray-tab-header.scss';

const TrayTabHeader = ({ actionButton, title }) => (
  <div styleName="tray-tab-header">
    <div styleName="tray-tab-header-title">{title}</div>
    {actionButton}
  </div>
);

TrayTabHeader.propTypes = {
  actionButton: PropTypes.element,
  title: PropTypes.object.isRequired
};

export default TrayTabHeader;
