import React from 'react';
import PropTypes from 'prop-types';
import { isFunction } from 'lodash';
import { CONFLICT_STATUS_ROW } from '@utils/data-detail/conflicts';
import './status-box.scss';

const StatusBox = ({
  agency1,
  agency2,
  overlapStatus,
  status
}) => {
  const { badge, dialogHint } = CONFLICT_STATUS_ROW[status];
  const hint = isFunction(dialogHint) ? dialogHint({ agency1, agency2, overlapStatus }) : dialogHint;
  return (
    <div styleName="status-box-container">
      <div styleName="badge">
        {badge}
      </div>
      <div styleName="hint">
        {hint}
      </div>
    </div>
  );
};

StatusBox.propTypes = {
  agency1: PropTypes.string,
  agency2: PropTypes.string,
  overlapStatus: PropTypes.object,
  status: PropTypes.string
};

export default StatusBox;
