import React, { memo } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { capitalize } from 'lodash';
import {
  renderWarning,
  showEntityScheduleContainsWarning
} from '@utils/segment-schedule/common';
import '../../../../../forms/forms.scss';

const ScheduleWarning = () => {
  const { dataType } = useParams();
  const { data } = useSelector(state => state.dataDetail);

  if (showEntityScheduleContainsWarning(data.start_date, data.end_date, data.segments)) {
    return (
      <div styleName="form-warning">
        {renderWarning(`${capitalize(dataType)} schedule does not contain all location schedules.`)}
      </div>
    );
  }
  return null;
};

export default memo(ScheduleWarning);
