// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.src-components-map-tray-overlap-___overlap-tray__list-header{padding-inline:1rem;padding-block:0.75rem}
`, "",{"version":3,"sources":["webpack://./src/components/map/tray/overlap/overlap-tray.scss"],"names":[],"mappings":"AAAA,8DACI,mBAAoB,CACpB,qBAAsB","sourcesContent":[".list-header {\n    padding-inline: 1rem;\n    padding-block: 0.75rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"list-header": `src-components-map-tray-overlap-___overlap-tray__list-header`,
	"listHeader": `src-components-map-tray-overlap-___overlap-tray__list-header`
};
export default ___CSS_LOADER_EXPORT___;
