import React, {memo} from 'react';
import PropTypes from 'prop-types';

import classNames from 'classnames';

import Icon from '@material-ui/core/Icon';
import EntityTypeIcon from '@icons/entity-type-icon';
import GroupTypeIcon from '@icons/group-type-icon';

import styles from './icon.scss';

const MenuIcon = ({icon, type, active}) => {
  if (icon === '[entity]') {
    const color = active ? '406EE3' : '616161';
    return <EntityTypeIcon type={type} size="1.5rem" color={color} />;
  }
  if (icon === '[group]') {
    const color = active ? '406EE3' : '616161';
    return <GroupTypeIcon type={type} size="1.5rem" color={color} />;
  }
  return (
    <Icon
      classes={{
        root: classNames(
          'material-icons',
          styles.icon,
          {[styles.active]: active}
        )
      }}
      translate="no">
      {icon}
    </Icon>
  );
};

MenuIcon.propTypes = {
  active: PropTypes.bool,
  icon: PropTypes.string,
  type: PropTypes.string
};

export default memo(MenuIcon);
