/* eslint-disable react/jsx-key */
// The IconMenu component was removed from the new Material UI library, so in order
// to continue using an IconMenu component, we need to create our own if we want to
// fully use the new Material UI library version.
import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { IconButton, Menu, Tooltip } from '@material-ui/core';
import * as colors from '@constants/colors';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import './icon-menu.scss';

const IconMenu = ({
  children, icon, iconStyles, iconButtonStyles, MenuProps, tooltip
}) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = useCallback(event => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  }, [setAnchorEl]);

  const handleClose = useCallback(event => {
    event.stopPropagation();
    setAnchorEl(null);
  }, [setAnchorEl]);

  const menuIcon = (
    <IconButton onClick={handleClick} style={iconButtonStyles}>
      {icon}
      {!icon && <MoreVertIcon style={iconStyles} titleAccess="more actions" />}
    </IconButton>
  );
  return (
    <div styleName="icon-menu-container">
      {tooltip && <Tooltip title={tooltip}>{menuIcon}</Tooltip>}
      {!tooltip && menuIcon}
      <Menu
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
        getContentAnchorEl={null}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        onClick={handleClose}
        {...MenuProps}
      >
        {children}
      </Menu>
    </div>
  );
};

IconMenu.propTypes = {
  MenuProps: PropTypes.object,
  /*
   * Contains the children nodes for the menu, and they can be like these
   *
   * import { Divider } from '@material-ui/core';
   *
   * ...
   * ...
   *
   * <IconMenu>
   *
   *   // The simplest menu item we can use:
   *   <IconMenuItem onClick={() => someFunc())}>Simple</IconMenuItem>
   *
   *   // A menu item with all features (icon and secondary text):
   *   <IconMenuItem onClick={() => someFunc())} icon={some icon} secondary={xxx}>All Features</IconMenuItem>
   *
   *   // We can also use a <Divider>:
   *   <Divider />
   *
   * </IconMenu>
   */
  children: PropTypes.node,
  icon: PropTypes.node,
  iconButtonStyles: PropTypes.object,
  iconStyles: PropTypes.object,
  tooltip: PropTypes.string
};

IconMenu.defaultProps = {
  iconButtonStyles: { padding: 0 },
  iconStyles: { color: colors.dotmapsBlack80 },
  MenuProps: {}
};

export default IconMenu;
