/* eslint-disable react/jsx-no-bind */
import React, { memo, useCallback, useEffect, useMemo } from 'react';
import { isEmpty } from 'lodash';
import { Prompt, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { createNewDataDetail } from '@actions/data-detail-actions';
import ScrollIntoFirstError from '@components/entity/info/drawer/form/scroll-into-first-error';
import { isWorkflowEnabled } from '@constants/config';
import EditScheduleDialog from '@shared/dialogs/edit-schedule-dialog';
import StartCycleDialog from '@shared/dialogs/start-cycle-dialog';
import BulkScheduleDialog from '@shared/dialogs/bulk-schedule-dialog';
import ViewScheduleDialog from '@shared/dialogs/view-schedule-dialog';
import { onScroll } from '@utils/form-utils';
import { isReadOnly } from '@utils/permission-utils';
import { routerWillLeave } from '@utils/router-utils';
import FormTab from './form-tab';
import NonFieldErrors from './non-field-errors';
import '../../../../forms/forms.scss';

const Form = () => {
  const dispatch = useDispatch();
  const { dataType, id } = useParams();
  const { details } = useSelector(state => state.config);
  const { data, modified } = useSelector(state => state.dataDetail);
  const dataTypes = useSelector(state => state.dataTypes);
  const { discard } = useSelector(state => state.confirmation);

  const userDataLoaded = useMemo(() => !isEmpty(dataTypes.user), [dataTypes.user]);

  useEffect(() => {
    // Check if we are on the details page to create a new record and create
    // and empty entity to work with.
    if (!id && data === null && userDataLoaded) {
      dispatch(createNewDataDetail(dataType));
    }
  }, [data, dataType, dispatch, id, userDataLoaded]);

  const tabs = useMemo(() => details?.[dataType]?.tabs.filter(tab => !tab.hide_internal), [dataType, details]);
  const readOnly = useMemo(() => isReadOnly(dataType), [dataType]);
  const onFormScroll = useCallback(() => onScroll(tabs), [tabs]);

  if (!data) {
    return null;
  }

  return (
    <div id="form-container" onScroll={onFormScroll} styleName="form-container">
      <ScrollIntoFirstError />
      {readOnly && (
        <div styleName="read-only-message">
          Edit is disabled on read-only fields. Some fields may still be editable.
        </div>
      )}
      <NonFieldErrors />
      {tabs?.map(tab => <FormTab key={tab.id} tab={tab} />)}
      <BulkScheduleDialog />
      <ViewScheduleDialog />
      <EditScheduleDialog />
      {isWorkflowEnabled() && <StartCycleDialog />}
      <Prompt when={modified && !discard} message={nextLocation => routerWillLeave(dispatch, nextLocation)} />
    </div>
  );
};

export default memo(Form);
