/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/display-name */
/* eslint-disable react/jsx-no-bind */
import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { toggleLocationTypeGroup } from '@actions/groups-actions';
import { textColor } from '@constants/colors';
import { listItemStyles } from '@constants/mui-theme';
import { Icon, IconButton } from '@mui';
import {
  calculateLevelStyle,
  calculateLeftContainerLevelStyle,
  renderDays
} from '@utils/gantt-utils';
import '../../../../../shared/list-item/list-item.scss';

const LocationListItem = ({
  end_date,
  id,
  isOpen,
  label,
  level,
  start_date
}) => {
  const dispatch = useDispatch();

  const toggleLocationType = () => {
    dispatch(toggleLocationTypeGroup(id));
  };

  if (!label) {
    return null;
  }

  const daysLabel = renderDays(start_date, end_date);
  const style = calculateLevelStyle(level);

  const isAllBar = label === 'All';
  const allStyle = isAllBar ? 'gantt-location-list-item-all' : '';
  const rowStyle = `list-item gantt-list-item gantt-location-list-item ${allStyle}`;
  const leftContainerStyle = calculateLeftContainerLevelStyle(level);

  return (
    <div style={style} styleName={rowStyle}>
      <div styleName="left-container" style={leftContainerStyle}>
        <div styleName="header">
          <IconButton
            onClick={toggleLocationType}
            style={listItemStyles.locationTypeButtonStyle}
          >
            <Icon color={textColor}>
              {isOpen ? 'expand_less' : 'expand_more'}
            </Icon>
          </IconButton>
          {label}
        </div>
      </div>
      <div styleName="right-container">
        {daysLabel}
      </div>
    </div>
  );
};

LocationListItem.propTypes = {
  end_date: PropTypes.string,
  id: PropTypes.number,
  isOpen: PropTypes.bool,
  label: PropTypes.string,
  level: PropTypes.number,
  start_date: PropTypes.string
};

export default memo(LocationListItem);
