import React, { Fragment, memo, useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import moment from 'moment-timezone';

import Button from '@material-ui/core/Button';
import MenuList from '@material-ui/core/MenuList';

import ChipButton from './shared/button';
import FilterChipPopover from './shared/popover';
import FilterChipMenuItem from './shared/menu-item';
import DashboardDateRangeDialog from '@shared/dialogs/date-range-dialog';

import { getDateDisplayFormat } from '@components/config/selectors';
import { useDeferredCallback } from '@hooks/utils';

import styles from './date.scss';

const CUSTOM_RANGE_VALUE = 'CUSTOM_RANGE_VALUE';

const DateFilterChip = ({ label, typeLabel, dateOptions, dateValues, onChange, customRange = false, contained}) => {
  const [prevValues, setPrevValues] = useState(dateValues);
  const [pendingValues, setPendingValues] = useState(dateValues);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [open, setOpen] = useState(false);
  const [customOpen, setCustomOpen] = useState(false);
  const dateDisplayFormat = useSelector(getDateDisplayFormat);

  const selectedOption = useMemo(() => {
    return pendingValues && dateOptions.find(({value: optionValue}) => (
      optionValue.start === pendingValues.start &&
      optionValue.end === pendingValues.end &&
      (!contained || optionValue.contained === pendingValues.contained)
    )) || null;
  }, [dateOptions, contained, pendingValues]);

  const [chipValues, filterActive] = useMemo(() => {
    if (pendingValues !== null) {
      if (selectedOption) {
        return [[selectedOption.label], true];
      }
      if (pendingValues.start && pendingValues.end) {
        const startDisplay = moment(pendingValues.start).format(dateDisplayFormat);
        const endDisplay = moment(pendingValues.end).format(dateDisplayFormat);
        return [[`${startDisplay} - ${endDisplay}`], true];
      }
    }
    return [[], false];
  }, [pendingValues, dateDisplayFormat, selectedOption]);

  const customRangeActive = !selectedOption && filterActive;

  const handleClose = useCallback(() => {
    setOpen(false);
    setAnchorEl(null);
    if (pendingValues !== dateValues) {
      if (filterActive) {
        onChange(pendingValues);
      } else {
        onChange(null);
      }
    }
  }, [dateValues, onChange, pendingValues, setAnchorEl, setOpen, filterActive]);

  const handleCustomClose = useDeferredCallback(() => {
    setCustomOpen(false);
    if (customRangeActive && pendingValues !== dateValues) {
      handleClose();
    }
  }, [dateValues, setCustomOpen, pendingValues, customRangeActive, handleClose]);

  const handleButtonClick = useCallback(event => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
    if (!open) {
      setOpen(true);
    } else {
      handleClose();
    }
  }, [anchorEl, setAnchorEl, open, setOpen, handleClose]);

  const handleSelectItem = useCallback(optionValues => {
    if (pendingValues === optionValues) {
      setPendingValues(null);
    } else {
      setPendingValues(optionValues);
    }
  }, [setPendingValues, pendingValues]);

  const handleSelectCustomRange = useCallback(() => {
    if (customRangeActive) {
      setPendingValues(null);
    } else {
      setCustomOpen(true);
    }
  }, [customRangeActive]);

  const handleClearValues = useCallback(() => {
    setPendingValues(null);
  }, [setPendingValues]);

  if (dateValues !== prevValues) {
    setPendingValues(dateValues);
    setPrevValues(dateValues);
  }

  return (
    <Fragment>
      <ChipButton
        label={label}
        values={chipValues}
        onClick={handleButtonClick}
      />
      <FilterChipPopover
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        classes={{
          root: styles.dateMenu
        }}
      >
        <MenuList>
          {dateOptions.map(option => (
            <FilterChipMenuItem
              key={option.label}
              option={option}
              onSelect={handleSelectItem}
              checked={option === selectedOption}
            />
          ))}
          {customRange &&
            <FilterChipMenuItem
              option={{
                label: 'Custom range',
                value: CUSTOM_RANGE_VALUE
              }}
              onSelect={handleSelectCustomRange}
              checked={customRangeActive}
            />
          }
        </MenuList>
        <div className={styles.buttons}>
          <Button
            onClick={handleClearValues}
            disabled={chipValues.length === 0}
            classes={{
              root: styles.button,
              disabled: styles.disabledButton
            }}
          >
            Clear
          </Button>
        </div>
      </FilterChipPopover>
      <DashboardDateRangeDialog
        contained={contained}
        defaultRange={pendingValues}
        onChange={handleSelectItem}
        onClose={handleCustomClose}
        open={customOpen}
        typeLabel={typeLabel}
      />
    </Fragment>
  );
};

DateFilterChip.propTypes = {
  contained: PropTypes.bool,
  customRange: PropTypes.bool,
  dateOptions: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string,
    values: PropTypes.shape({
      start: PropTypes.string.isRequired,
      end: PropTypes.string.isRequired,
      contained: PropTypes.bool
    })
  })),
  dateValues: PropTypes.shape({
    start: PropTypes.string.isRequired,
    end: PropTypes.string.isRequired,
    contained: PropTypes.bool
  }),
  label: PropTypes.string,
  onChange: PropTypes.func,
  typeLabel: PropTypes.string
};

export default memo(DateFilterChip);
