import React from 'react';
import PropTypes from 'prop-types';
import './view-field.scss';

const ViewFieldColumn = props => (
  <div styleName="view-field view-field-column">
    <div styleName="view-field-label">
      {props.label}
    </div>
    <div styleName="view-field-value">
      {props.value}
    </div>
  </div>
);

ViewFieldColumn.propTypes = {
  label: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.node])
};

export default ViewFieldColumn;
