import React from 'react';
import { useParams } from 'react-router-dom';
import * as colors from '@constants/colors';
import { isEntity } from '@constants/endpoints';
import EntityTypeIcon from '@icons/entity-type-icon';
import WorkflowIcon from '@icons/workflow-icon';
import './top-bar.scss';

const TopBarTypeIcon = () => {
  const { dataType } = useParams();
  if (isEntity(dataType)) {
    return (
      <div styleName="icon">
        <EntityTypeIcon type={dataType} size="1.5rem" color={colors.textColor} />
      </div>
    );
  } else
    if (dataType === 'cycle') {
      return (
        <div styleName="icon">
          <WorkflowIcon />
        </div>
      );
    }
  return null;
};

export default TopBarTypeIcon;
