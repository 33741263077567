import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { getGroupIsLoading } from '@selectors/groups-selector';
import { calculateMetrics, renderSavingsShort } from '@utils/gantt-utils';
import './metrics-bar.scss';

const Item = ({ label, value }) => (
  <div styleName="item">
    <div styleName="label">{label}</div>
    <div styleName="value">{value}</div>
  </div>
);

Item.propTypes = {
  label: PropTypes.string,
  value: PropTypes.any  // eslint-disable-line react/forbid-prop-types
};

const Metrics = ({ duration, endDate, startDate, style, title }) => (
  <div styleName={`${style} metrics`}>
    <div styleName="metrics-title">{title}</div>
    <div styleName="metrics-content">
      <Item label="Duration" value={duration} />
      <Item label="Start Date" value={startDate} />
      <Item label="End Date" value={endDate} />
    </div>
  </div>
);

Metrics.propTypes = {
  duration: PropTypes.number,
  endDate: PropTypes.object,
  startDate: PropTypes.object,
  style: PropTypes.string,
  title: PropTypes.string
};

const MetricsBar = () => {
  const loading = useSelector(getGroupIsLoading);
  const { group } = useSelector(state => state.groups.edit);
  const { baseline, current, type } = group;

  const savings = loading ? '' : renderSavingsShort(baseline, current);
  const metrics = loading ? { baseline: {}, current: {} } : calculateMetrics(baseline, current, type);

  return (
    <div styleName="metrics-bar">
      <div styleName="title">Comparison</div>
      <div styleName="content">
        <div styleName="message">{savings}</div>
        <div styleName="items">
          <Metrics {...metrics.baseline} style="baseline" title="Baseline" />
          <Metrics {...metrics.current} style="current" title="Current" />
        </div>
      </div>
      <div styleName="right-panel" />
    </div>
  );
};

export default MetricsBar;
