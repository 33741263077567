import React from 'react';
import Skeleton from '@material-ui/lab/Skeleton';

import './skeleton-row.scss';

const SkeletonListRow = () => (
  <div styleName="skeleton-list-row">
    <div styleName="skeleton-list-bullet">
      <Skeleton variant="circular" />
    </div>
    <div styleName="skeleton-list-item">
      <Skeleton width="100%" />
    </div>
  </div>
);

export default SkeletonListRow;
