/* global google */
import * as R from 'ramda';
import turfCircle from '@turf/circle';
import { polygon as turfPolygon } from '@turf/helpers';
import { featureToWKT } from '@utils/map-utils';

const polygonEvents = ['set_at', 'insert_at', 'remove_at'];
const circleEvents = ['center_changed', 'radius_changed'];

export const setShapeEventListener = (shape, action) => {
  if (shape.radius) {
    circleEvents.forEach(event => {
      shape.addListener(event, action);
    });
  }
  if (shape.getPath) {
    polygonEvents.forEach(event => {
      shape.getPath().addListener(event, action);
    });
  }
  return shape;
};

const closeRing = points => [...points, points[0]];

const pointToArray = point => [point.lng(), point.lat()];

// google polygons are not closed so we use closeRing to make it a proper geoJson feature
const mapsPolygonToFeature = polygon => turfPolygon([closeRing(polygon
  .getPath()
  .getArray()
  .map(pointToArray)
)]);

const mapsCircleToFeature = circle => turfCircle(pointToArray(circle.getCenter()), circle.radius, {units: 'meters'});

const getFeatureFromGoogleMapsShape = R.cond([[R.has('radius'), mapsCircleToFeature], [R.hasIn('getPath'), mapsPolygonToFeature]]);

export const convertFromGoogleMapsShape = R.pipe(getFeatureFromGoogleMapsShape, R.path(['geometry']));

export const getFiltersFromGoogleMapsShape = R.pipe(getFeatureFromGoogleMapsShape, featureToWKT);

const metersToSquareMiles = meters => (meters / 2589988.11).toPrecision(3);

const metersToFeets = meters => (meters * 3.28084);

const feetsToMiles = feets => (feets * 0.00018939);

const getCircleArea = ({ radius }) => metersToSquareMiles(Math.PI * radius * radius);

export const getPolygonArea = polygon => metersToSquareMiles(google.maps.geometry.spherical.computeArea(polygon.getPath()));

export const getShapeArea = R.cond([[R.has('radius'), getCircleArea], [R.hasIn('getPath'), getPolygonArea]]);

export const getLineDistance = coords => {
  if (coords && coords.length > 0) {
    const latLngPath = coords.map(coord => new google.maps.LatLng(coord.lat, coord.lng));
    const polylineLengthInMeters = google.maps.geometry.spherical.computeLength(latLngPath);
    return metersToFeets(polylineLengthInMeters).toFixed(2);
  }
  return null;
};

export const renderDistance = distance => {
  if (distance === null) {
    return null;
  }

  if (distance < 5280) {
    return `${distance} ft`;
  }
  // Convert to miles:
  return `${feetsToMiles(distance).toFixed(2)} mi`;
};
