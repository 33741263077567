//
// Timeline chart component utility methods.
//
import moment from 'moment-timezone';
import * as R from 'ramda';
import { isEmpty, isString } from 'lodash';
import { getGanttConfig } from '@constants/config';
import { parseDate } from '@utils/shared-utils';

// Return all valid start and end dates into an array:
const getAllDates = rows => {
  const startDates = rows.map(row => moment(row.start_date)).filter(date => date.isValid());
  const endDates = rows.map(row => moment(row.end_date)).filter(date => date.isValid());
  return startDates.concat(endDates);
};

export const getMinDate = rows => {
  return moment.min(getAllDates(rows));
};

export const getMaxDate = rows => {
  return moment.max(getAllDates(rows));
};

const toDate = date => {
  if (isString(date)) {
    return parseDate(date);
  }

  if (date instanceof Date) {
    return moment(date);
  }

  return date;
};

export const calculateDays = (start, end) => {
  const startDate = toDate(start);
  const endDate = toDate(end);

  if (startDate && endDate) {
    return endDate.diff(startDate, 'days');
  }

  // If dates are invalid return no duration:
  return 0;
};

// Calculate the width for the specified date range and date unit:
export const calculateWidth = (start, end, dateUnit, offset) => {
  const days = calculateDays(start, end);
  // offset is needed, since for some calculations (bar width or total
  // container width, if the start/end dates are the same, the duration
  // will be zero, but it should actually be one day as minimum.
  const duration = moment.duration(days + offset, 'days');
  const { dateUnits } = getGanttConfig();
  switch (dateUnit) {  // eslint-disable-line default-case
  case dateUnits.day.id: return duration.asDays() * dateUnits.day.pixels;
  case dateUnits.week.id: return duration.asWeeks() * dateUnits.week.pixels;
  case dateUnits.month.id: return duration.asMonths() * dateUnits.month.pixels;
  case dateUnits.quarter.id:
    // moment.js doesn't have a duration.asQuarters() method, thus we must calculate it manually:
    return duration.asDays() / 91.25 * dateUnits.quarter.pixels;
  case dateUnits.year.id: return duration.asYears() * dateUnits.year.pixels;
  }
  return null;
};

// Calculate the offset from the start of the date unit.
//
// For month, quarter and year, the start of the chart is day 1 for
// the first month, quarter or year.
//
// Since the bar doesn't exactly start on day 1, we must calculate
// and show that offset.
const calculateStartOffset = (minDate, dateUnit) => {
  const minimumDate = moment(minDate); // Clone, since startOf() mutates the object.
  const { dateUnits } = getGanttConfig();
  switch (dateUnit) {  // eslint-disable-line default-case
  case dateUnits.month.id: return calculateWidth(minimumDate.startOf('month'), minDate, dateUnit, 0);
  case dateUnits.quarter.id: return calculateWidth(minimumDate.startOf('quarter'), minDate, dateUnit, 0);
  case dateUnits.year.id: return calculateWidth(minimumDate.startOf('year'), minDate, dateUnit, 0);
  }
  // No difference for day and week:
  return 0;
};

// Calculate the width (in px) of the whole Gantt container:
export const calculateGanttContainerWidth = (rows, dateUnit) => {
  const minDate = getMinDate(rows);
  const maxDate = getMaxDate(rows);
  const width = calculateWidth(minDate, maxDate, dateUnit, 1) + calculateStartOffset(minDate, dateUnit);
  return `${width}px`;
};

// Calculate the start (in a calendar, in px) of a Gantt bar:
export const calculateGanttBarStartWidth = (start, minDate, dateUnit) => {
  const width = calculateWidth(minDate, start, dateUnit, 0) + calculateStartOffset(minDate, dateUnit);
  return `${width}px`;
};

// Calculate the width (in px) of a single Gantt bar:
export const calculateGanttBarWidth = (start, end, dateUnit) => {
  const width = calculateWidth(start, end, dateUnit, 1);
  const minBarWidth = R.find(R.propEq(dateUnit, 'id'))(R.values(getGanttConfig().dateUnits)).minBarWidth;
  if (width < minBarWidth) {
    return `${minBarWidth}px`;
  }
  return `${width}px`;
};

// Calculate the number of days for the date date of the
// specified rows:
export const calculateRowsDays = rows => {
  const minDate = getMinDate(rows);
  const maxDate = getMaxDate(rows);
  return calculateDays(minDate, maxDate);
};

// For the metrics tab, calculate how many days are saved
// between the baseline and the current group's data.
export const calculateDaysSaved = (baseline, current) => {
  if (isEmpty(baseline)) {
    return 0;
  }

  return baseline.duration - current.duration;
};

// If the user already looked at the metrics tab,
// the alert is dismissed and must not be shown.
const isBaselineAlertDismissed = baseline => {
  return typeof baseline.dismissed === 'undefined' ? false : baseline.dismissed;
};

// Returns true if the baseline count values of each entity
// is not the same as the current one.
const hasBaselineEntitiesChanged = (baseline, current) => {
  const entities = {};
  Object.keys(current.entities_count).forEach(key => {
    entities[key] = baseline.entities_count[key] !== current.entities_count[key];
  });
  return Object.values(entities).some(element => element);
};

// Returns true if there are differences between the baseline
// and the current group's data.
export const hasBaselineChanged = (baseline, current) => {
  if (isEmpty(baseline)) {
    return false;
  }

  if (isBaselineAlertDismissed(baseline)) {
    return false;
  }

  const daysSaved = calculateDaysSaved(baseline, current);
  return daysSaved !== 0 || hasBaselineEntitiesChanged(baseline, current);
};
