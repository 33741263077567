/* eslint-disable react/jsx-no-bind */
/* eslint-disable no-return-assign */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { colors } from '@constants/colors';
import { Icon } from '@mui';
import './details-table-blob-field.scss';

class DetailsTableBlobField extends Component {
  state = { expanded: false, shouldExpand: false };

  componentDidMount() {
    if (this.divElement && this.innerElement) {
      this.setShouldExpand(this.innerElement.clientHeight > this.divElement.clientHeight);
    }
  }

  setShouldExpand = shouldExpand => this.setState({shouldExpand});

  toggleShowMore = () => this.setState({expanded: !this.state.expanded});

  render() {
    const { value } = this.props;
    return (
      <div>
        <div>
          <div styleName={`value ${!this.state.expanded ? 'truncated' : ''}`} ref={divElement => this.divElement = divElement}>
            <p ref={innerElement => this.innerElement = innerElement}>{value}</p>
            {this.state.shouldExpand && !this.state.expanded && <div styleName="gradient">&nbsp;</div>}
          </div>
        </div>
        {this.state.shouldExpand &&
          <div styleName="show-more" onClick={this.toggleShowMore} role="presentation">
            <Icon color={colors.opacity.black0_6} style={{ paddingRight: '12px', width: '24px'}}>more_horiz</Icon>
            <div>{!this.state.expanded ? 'SHOW MORE' : 'SHOW LESS'}</div>
          </div>
        }
      </div>
    );
  }
}

DetailsTableBlobField.propTypes = {
  fields: PropTypes.array,
  value: PropTypes.string
};

export default DetailsTableBlobField;
