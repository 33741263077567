/* eslint-disable react/jsx-no-bind */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './details-table-owners-list.scss';

class DetailsTableOwnersList extends Component {
  getOwnerCards = owners => owners.map(owner => (
    <div styleName="owner-card" key={owner.id} role="presentation">
      <div styleName="title">
        {owner.name}
      </div>
      <div styleName="body">
        {owner.email}{owner.phone ? ', ' : ''}{owner.phone}
      </div>
    </div>
  ));

  render() {
    const { owner_dict } = this.props.entity;
    return (
      <div styleName="owners-container">
        {owner_dict.length === 0 &&
          <div styleName="empty-list">No owners to show</div>
        }
        {owner_dict.length > 0 &&
          <div styleName="owners-list">{this.getOwnerCards(owner_dict)}</div>
        }
      </div>
    );
  }
}

DetailsTableOwnersList.propTypes = {
  entity: PropTypes.object
};

export default DetailsTableOwnersList;
