import React from 'react';
import PropTypes from 'prop-types';
import { colors } from '@constants/colors';
import Avatar from '@material-ui/core/Avatar';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  legendSize: {
    backgroundColor: colors.neutral.white,
    fontSize: 12,
    marginRight: '0.5rem',
    width: 24,
    height: 24
  }
});

const LegendAvatar = ({ src }) => {
  const classes = useStyles();
  return <Avatar className={classes.legendSize} src={src} />;
};

LegendAvatar.propTypes = {
  src: PropTypes.string
};

export default LegendAvatar;
