/* eslint-disable react/jsx-no-bind */
import React, { memo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as R from 'ramda';
import {
  disableDrawingManager,
  enableDrawingManager,
  setGroupApplyShape,
  setGroupShape
} from '@actions/groups-actions';
import BoundaryIcon from '@icons/boundary-icon';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import CancelIcon from '@material-ui/icons/Cancel';
import EditIcon from '@material-ui/icons/Edit';
import { canEditGroups } from '@utils/permission-utils';
import '../drawer.scss';

const BoundaryRow = () => {
  const dispatch = useDispatch();
  const { drawing, group } = useSelector(state => state.groups.edit);

  const applyShape = () => dispatch(setGroupApplyShape());
  const removeGroupShape = () => dispatch(setGroupShape(null));
  const disableDrawing = () => dispatch(disableDrawingManager());
  const enableDrawing = () => dispatch(enableDrawingManager());

  // Buttons to show when we are not editing a shape.
  const renderEditButtons = shape => {
    if (!shape) {
      return <Button color="primary" onClick={enableDrawing}>Draw Boundary</Button>;
    }

    return (
      <div>
        <IconButton onClick={removeGroupShape}>
          <Tooltip title="Remove boundary">
            <CancelIcon />
          </Tooltip>
        </IconButton>
        <IconButton onClick={enableDrawing}>
          <Tooltip title="Edit boundary">
            <EditIcon />
          </Tooltip>
        </IconButton>
      </div>
    );
  };

  // Buttons to shown when we are in edit mode (i.e. when we are modifying a shape).
  const renderDrawingButtons = shape => (
    <div>
      {shape && <Button color="primary" onClick={applyShape} variant="contained">Apply</Button>}
      <Button color="primary" onClick={disableDrawing}>Cancel</Button>
    </div>
  );

  if (!canEditGroups()) {
    return null;
  }

  // If check if area capture is disallowed:
  if (group.area_capture === 0) {
    return null;
  }

  const shape = R.path(['auto_area', 'shape'], group);
  const label = shape ? 'Boundary' : 'No boundary';
  const labelStyle = shape ? 'label set' : 'label';

  return (
    <div styleName="set-value-row">
      <div styleName="icon"><BoundaryIcon color="#000000" /></div>
      <div styleName={labelStyle}>{label}</div>
      {drawing && renderDrawingButtons(shape)}
      {!drawing && renderEditButtons(shape)}
    </div>
  );
};

export default memo(BoundaryRow);
