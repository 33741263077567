import React, { memo, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import { clearDashboardDetailsDataError, updateDashboardField } from '@actions/dashboard-actions';
import { Switch } from '@mui';
import './fields.scss';

const styles = {
  switchLabel: {
    marginLeft: 0,
    width: '100%',
    justifyContent: 'space-between'
  }
};

const ToggleField = ({ disabled, field, label, required, value }) => {
  const { dataType } = useParams();
  const dispatch = useDispatch();

  const onChange = useCallback(
    event => {
      dispatch(clearDashboardDetailsDataError(dataType, field?.split('.')));
      const newValue = !(value === 'true' || value === true);
      dispatch(updateDashboardField(dataType, event.target.name, newValue));
    },
    [dataType, dispatch, field, value]
  );

  return (
    <div styleName="field-container">
      <div styleName="field-input">
        <Switch
          labelPlacement="start"
          label={<span>{label}&nbsp;{required ? '*' : ''}</span>}
          name={field}
          style={styles.switchLabel}
          checked={value === 'true'}
          onChange={onChange}
          disabled={disabled}
        />
      </div>
    </div>
  );
};

ToggleField.propTypes = {
  disabled: PropTypes.bool,
  field: PropTypes.string,
  label: PropTypes.string,
  required: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
};

export default memo(ToggleField);
