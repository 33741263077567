/* eslint-disable react/jsx-no-bind */
import React, { memo } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { showConfirmationDialog } from '@actions/confirmation-actions';
import { confirmableDeleteDashboardEntry } from '@actions/dashboard-actions';
import { colors } from '@constants/colors';
import { Icon, IconButton } from '@mui';
import { isAppAdmin } from '@utils/permission-utils';

const AgencyActions = () => {
  const { id } = useParams();
  const dispatch = useDispatch();

  const onDelete = () => {
    dispatch(showConfirmationDialog(
      'Delete agency',
      'Are you sure you want to delete this agency',
      confirmableDeleteDashboardEntry('agency', Number(id))
    ));
  };

  if (!isAppAdmin() || id === 'new') {
    return null;
  }

  return (
    <IconButton onClick={onDelete} tooltip="Delete">
      <Icon color={colors.opacity.black0_8}>delete</Icon>
    </IconButton>
  );
};

export default memo(AgencyActions);
