import Drawer from '@components/group/drawer';
import LoadingIndicator from '@components/group/loading-indicator';
import Map from '@components/group/map';
import SubNav from '@components/group/sub-nav';
import Tray from '@components/map/tray';
import FilePreview from '@shared/files/file-preview';
import FragmentLayout from '@shared/fragment-layout';

const Group = FragmentLayout(LoadingIndicator, Drawer, Map, SubNav, Tray, FilePreview);

export default Group;
