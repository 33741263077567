import { createSelector } from 'reselect';
import * as R from 'ramda';
import {
  buildTaskListTitle,
  canViewActivity
} from '@utils/workflow-utils';

const getCycle = state => state.workflow.cycle;
const getTaskTypes = state => state.dataTypes.task_type;
const getTask = state => state.dataDetail.data;

const getWorkflowCycleGroupEntities = state => R.pathOr([], ['workflow', 'cycle', 'group', 'entities'], state);

export const getCycleGroupEntities = createSelector(
  [getWorkflowCycleGroupEntities],
  (workflowCycleGroupEntities) => workflowCycleGroupEntities
);

// Return the list of tasks that can be displayed depending on the user's permissions.
export const getTasks = createSelector([getCycle], cycle => {
  return cycle?.tasks.filter(task => task.activities.some(activity => canViewActivity(task, activity)));
});

export const getTasksTitle = createSelector([getTasks], tasks => buildTaskListTitle(tasks));

export const getTaskType = createSelector([getTaskTypes, getTask], (taskTypes, task) => taskTypes[task?.task_type]);
