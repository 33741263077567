/* global google */
/* eslint-disable no-use-before-define */
import { measurementCircleStyle } from '@constants/data-detail';
import {
  getLineDistance,
  getPolygonArea,
  renderDistance
} from '@utils/map-tools-utils';

const getMeasurementMarkerIcon = () => {
  const { googleSymbolPath, ...iconProps } = measurementCircleStyle.icon;
  const path = google.maps.SymbolPath[googleSymbolPath];
  return { ...iconProps, path };
};

// Disable specified marker icon while we are dragging it, to
// let the user see the cross icon.
const disableMarkerIcon = marker => {
  const icon = { ...getMeasurementMarkerIcon(), scale: 0 };
  marker.setIcon(icon);
};

export const getArea = () => getPolygonArea(window.measure.line);

const getDistance = () => {
  if (window.measure.interactive) {
    return getLineDistance([...window.measure.path, window.measure.interactiveLine.getPath().getArray()[1].toJSON()]);
  }
  return getLineDistance(window.measure.path);
};

const getEndLabelDistance = () => renderDistance(getDistance());

const updatePolyline = () => window.measure.line.setPath([...window.measure.path]);

// Updates the tooltip with the current distance:
export const updateDistanceTooltip = () => {
  if (window.measure.type === 'polyline' && window.measure.interactive) {
    const label = {
      className: 'measurementTooltip',
      fontFamily: 'Roboto',
      fontSize: '12px',
      color: 'white',
      text: getEndLabelDistance()
    };
    window.measure.interactiveMarker.setLabel(label);
  } else {
    // When not in interactive mode (or when we closed the path
    // and converted it into a polygon) we don't need to render
    // the tooltip.
    window.measure.interactiveMarker.setLabel(null);
  }
};

// Update the polyline path and tooltip distance label:
const update = () => {
  updatePolyline();
  updateDistanceTooltip();
};

export const getLastIndex = () => window.measure ? window.measure.path.length - 1 : 0;

// Called when we move the mouse over the map, if we are in interactive
// mode, the tooltip position will be updated.
export const onMeasurementMouseMove = event => {
  if (window.measure && window.measure.interactive) {
    const lastLine = window.measure.path[getLastIndex()];
    const currentLine = event.latLng.toJSON();
    window.measure.interactiveLine.setPath([lastLine, currentLine]);
    window.measure.interactiveMarker.setPosition(currentLine);
    updateDistanceTooltip();
  }
};

const addMarker = (position, onMeasureDragEnd, onMeasureDone) => {
  const markerProps = {
    map: window.mapInstance,
    ...measurementCircleStyle,
    icon: getMeasurementMarkerIcon()
  };

  const marker = new google.maps.Marker({
    position,
    ...markerProps
  });

  const markerIndex = window.measure.markers.length;

  // Add a mouseover listener to the first marker, to change the circle's
  // background color when hovering it, to flag we can click on it and close
  // the path. This mimics Polyline's edit behavior.
  if (markerIndex === 0) {
    marker.addListener('mouseover', () => {
      marker.setIcon({
        ...getMeasurementMarkerIcon(),
        // Change background to exactly what Polyline uses on hover.
        fillColor: '#7F7F7F'
      });
    });
    // Restore default background:
    marker.addListener('mouseout', () => {
      marker.setIcon(getMeasurementMarkerIcon());
    });
  }

  marker.addListener('click', () => {
    // Check if we are in edit mode:
    if (window.measure.interactive) {
      const lastVertexIndex = getLastIndex();

      // If no path was drawn yet, don't allow clicking on a vertex:
      if (lastVertexIndex === 0) {
        return;
      }

      // Clicking on the first vertex means to close the path,
      // thus converting it into a polygon to calculate the area:
      if (markerIndex === 0) {
        convertToPolygon(onMeasureDragEnd, onMeasureDone);
      }

      // Clicking on the last vertex means to stop editing:
      if (markerIndex === lastVertexIndex) {
        onInteractiveStop(onMeasureDragEnd, onMeasureDone);
      }
    }
  });

  window.measure.markers.push(marker);
};

// Add a new vertex to the polyline:
export const addVertex = (position, onMeasureDragEnd, onMeasureDone) => {
  window.measure.path.push(position);
  addMarker(position, onMeasureDragEnd, onMeasureDone);
  if (getLastIndex() > 0) {
    update();
  }
};

// If the interactive line or marker are clicked, act as if we issued a click
// on the map, since the Polyline or marker sometimes receives the click event
// preventing it to reach the map's handler.
const onInteractiveClick = (event, onMeasureDragEnd, onMeasureDone) => {
  addVertex(event.latLng.toJSON(), onMeasureDragEnd, onMeasureDone);
  onMeasureDragEnd();  // Call onDragEnd, to update the distance on the info window.
};

// Clear Polyline listeners (due to measurement tool destroy, or to add new listeners
// after the path changed).
const clearLineListeners = () => {
  google.maps.event.clearInstanceListeners(window.measure.line.getPath());
  google.maps.event.clearInstanceListeners(window.measure.line);
};

const clearMarkers = () => {
  window.measure.markers.forEach(marker => {
    marker.setMap(null);
    google.maps.event.clearInstanceListeners(marker);
  });
};

// A vertex from the completed polyline changed (dragged).
const onVertexChange = onMeasureDragEnd => {
  // Convert the new polyline path into the array we use for the line.setPath() call.
  const coords = window.measure.line.getPath().getArray();
  window.measure.path = coords.map(coord => coord.toJSON());

  // Set listeners again after setting new path (in update()):
  setListeners(onMeasureDragEnd);

  // Call the dragend callback to calculate the new distance on the info-window.
  onMeasureDragEnd();
};

// Set Polyline listeners to update distance:
const setListeners = onMeasureDragEnd => {
  // Clear line and line.path listeners, since we'll create a new path:
  clearLineListeners();

  // Update polyline path and tooltip.
  update();

  // Set new listeners:
  window.measure.line.addListener('dragend', () => onVertexChange(onMeasureDragEnd));
  window.measure.line.getPath().addListener('insert_at', () => onVertexChange(onMeasureDragEnd));
  window.measure.line.getPath().addListener('remove_at', () => onVertexChange(onMeasureDragEnd));
  window.measure.line.getPath().addListener('set_at', () => onVertexChange(onMeasureDragEnd));
};

// Stop polyline measurement editing.
export const onInteractiveStop = (onMeasureDragEnd, onMeasureDone) => {
  window.measure.line.setEditable(true);
  window.measure.interactive = false;
  window.measure.interactiveLine.setVisible(false);
  clearMarkers();
  onMeasureDone(true);
  // Set polyline listeners, this can only be done once
  // we finished creating the polyline path.
  setListeners(onMeasureDragEnd);
};

// Creates the dashed line and marker while editing the polyline:
const createInteractiveLine = (position, onMeasureDragEnd, onMeasureDone) => {
  const dashedLine = {
    path: 'M 0,-1 0,1',
    strokeOpacity: 0.5,
    scale: 4
  };

  // Create the interactive Polyline (i.e. the one that changes
  // as we move the mouse).
  window.measure.interactiveLine = new google.maps.Polyline({
    strokeOpacity: 0,
    icons: [{
      icon: dashedLine,
      offset: '0',
      repeat: '20px'
    }]
  });
  window.measure.interactiveLine.setMap(window.mapInstance);

  // When we use this interactive line, and we click on the map, sometimes
  // the main map onClick handler is called and sometimes this line's onClick
  // handler is called, thus we must handle interactive line clicks
  // like if they were map's clicks:
  window.measure.interactiveLine.addListener('click', event => onInteractiveClick(event, onMeasureDragEnd, onMeasureDone));
  window.measure.interactiveLine.addListener('dblclick', () => onInteractiveStop(onMeasureDragEnd, onMeasureDone));

  // Create an interactive marker to place the distance label:
  window.measure.interactiveMarker = new google.maps.Marker({
    map: window.mapInstance,
    position
  });

  // Hide marker icon on the interactive line:
  disableMarkerIcon(window.measure.interactiveMarker);

  // Also handle the marker click event (see the click handler above for the interactive line).
  window.measure.interactiveMarker.addListener('click', event => onInteractiveClick(event, onMeasureDragEnd, onMeasureDone));
  window.measure.interactiveMarker.addListener('dblclick', () => onInteractiveStop(onMeasureDragEnd, onMeasureDone));

  // Set interactive mode (i.e. enables the interactive line and marker
  // to show calculated distance as we move the mouse).
  window.measure.interactive = true;
};

// Convert the existing Polyline into a Polygon:
const convertToPolygon = (onMeasureDragEnd, onMeasureDone) => {
  window.measure.type = 'polygon';  // Set measurement line type.

  // Remove the polyline:
  clearLineListeners();
  window.measure.line.setMap(null);

  // Create a polygon:
  window.measure.line = new google.maps.Polygon({ path: window.measure.path, editable: true, draggable: true });
  window.measure.line.setMap(window.mapInstance);

  // Stop editing:
  onInteractiveStop(onMeasureDragEnd, onMeasureDone);
};

//
// Initialize the measurement tool.
//
// This should be executed when we click on the map after selecting the measurement
// tool ruler icon on the toolbar.
//
// This directly uses the Google Maps API, since updating the Polyline while dragging
// will be too slow to do with React.
export const initializeMeasurement = (position, onMeasureDragEnd, onMeasureDone) => {
  window.measure = { markers: [], path: [], type: 'polyline' };

  // Add first vertex:
  addVertex(position, onMeasureDragEnd, onMeasureDone);

  // Create the Polyline:
  window.measure.line = new google.maps.Polyline({ path: [position] });
  window.measure.line.setMap(window.mapInstance);

  // Create the interactive line:
  createInteractiveLine(position, onMeasureDragEnd, onMeasureDone);
};

// Destroy all Marker and Polyline objects when we turn the measurement tool off:
export const destroyMeasurement = onMeasureDone => {
  if (window.measure) {
    clearLineListeners();
    google.maps.event.clearInstanceListeners(window.measure.interactiveLine);
    window.measure.interactiveLine.setMap(null);
    google.maps.event.clearInstanceListeners(window.measure.interactiveMarker);
    window.measure.interactiveMarker.setMap(null);
    window.measure.line.setMap(null);
    clearMarkers();
    window.measure = null;
  }
  onMeasureDone(false);
};
