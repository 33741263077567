// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.src-components-overlap-___map__map-content{height:100%;width:100%}
`, "",{"version":3,"sources":["webpack://./src/components/overlap/map.scss"],"names":[],"mappings":"AAGA,4CACE,WAAY,CACZ,UAAW","sourcesContent":["@import '../../styles/mixins';\n@import '../../styles/vars';\n\n.map-content {\n  height: 100%;\n  width: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"map-content": `src-components-overlap-___map__map-content`,
	"mapContent": `src-components-overlap-___map__map-content`
};
export default ___CSS_LOADER_EXPORT___;
