import React, { memo } from 'react';
import PropTypes from 'prop-types';

import { colors } from '@constants/colors';

import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';

import './batch-upload-errors.scss';

const BatchUploadErrors = ({ errors }) => (
  <div styleName="error-container">
    <div styleName="error-icon">
      <ErrorOutlineIcon htmlColor={colors.brand.red} style={{ fontSize: '1.5rem' }} />
    </div>
    <div styleName="error-list">
      <ul>
        {errors.map(error => <li key={error}>{error}</li>)}
      </ul>
    </div>
  </div>
);

BatchUploadErrors.propTypes = {
  errors: PropTypes.array
};

export default memo(BatchUploadErrors);
