/* eslint-disable react/jsx-no-bind */
import React, { memo } from 'react';
import PropTypes from 'prop-types';
import SelectField from '@shared/select-field';
import MenuItem from '@material-ui/core/MenuItem';
import './top-bar-view.scss';

const View = ({ day, month, selected, week }) => {
  const onChange = event => {
    const { value } = event.target;
    if (value === 'day') {
      day();
    } else
      if (value === 'week') {
        week();
      } else
        if (value === 'month') {
          month();
        }
  };

  return (
    <div styleName="view-container">
      <SelectField
        onChange={onChange}
        selectStyles={{
          root: {
            minWidth: '6rem',
            padding: '0.5rem'
          }
        }}
        value={selected}
        variant="outlined"
      >
        <MenuItem value="day">Day</MenuItem>
        <MenuItem value="week">Week</MenuItem>
        <MenuItem value="month">Month</MenuItem>
      </SelectField>
    </div>
  );
};

View.propTypes = {
  day: PropTypes.func,
  month: PropTypes.func,
  selected: PropTypes.string,
  week: PropTypes.func
};

export default memo(View);
