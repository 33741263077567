/* eslint-disable react/jsx-key */
import React, { useCallback, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty } from 'lodash';
import { openDashboardDialog } from '@actions/dashboard-actions';
import { errorDataField, updateDataFieldInline } from '@actions/data-detail-actions';
import { changeTaskStatus } from '@actions/workflow-actions';
import DetailsContext from '@components/workflow/details-context';
import * as dialog from '@constants/dialogs';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import SelectField from '@shared/select-field';
import LateLabel from '@shared/workflow/late-label';
import TaskStatusChip from '@shared/workflow/task-status-chip';
import { canRespondTaskActivity } from '@utils/permission-utils';
import {
  getTaskStatuses,
  isLate,
  isOwner,
  isOwnerAgency,
  isPastDue,
  validateResponseForm
} from '@utils/workflow-utils';
import TransitionDialog from './status-transition-dialog';
import './status.scss';

const Status = () => {
  const dispatch = useDispatch();
  const { activity, task } = useContext(DetailsContext);
  const { task_status: taskStatuses, task_type: taskTypes } = useSelector(state => state.dataTypes);

  const setError = useCallback(
    error => dispatch(errorDataField(error.field, error.value, true)),
    [dispatch]
  );

  const onSelectChange = useCallback(
    event => {
      const { id } = activity;
      const { target: { value } } = event;
      const { activities, task_type: taskTypeId } = task;
      const index = activities.findIndex(act => act.id === id);
      const fieldPrefix = `activities.${index}`;
      const late = isPastDue(task.due_date);

      if (value === 'Completed' || value === 'Submitted') {
        const taskType = taskTypes[taskTypeId];
        const params = { activityId: id, fieldPrefix, isLate: late, statusName: value };
        if (taskType.response) {
          const errors = validateResponseForm(activity);
          if (isEmpty(errors)) {
            dispatch(openDashboardDialog(dialog.TASK_STATUS, params));
          } else {
            errors.map(error => setError(error));
          }
        } else {
          dispatch(openDashboardDialog(dialog.TASK_STATUS, params));
        }
      } else
        if (value === 'Assigned' || value === 'In progress') {
          const fieldPath = fieldPrefix ? `${fieldPrefix}.status_name` : 'status_name';
          dispatch(updateDataFieldInline(fieldPath, value));
          dispatch(changeTaskStatus(id, value, late));
        }
    },
    [activity, dispatch, setError, task, taskTypes]
  );

  if (!activity) {
    return null;
  }
  const { status_name: statusName } = activity;
  const statuses = getTaskStatuses(statusName, taskStatuses, task, activity);
  const isResponder = canRespondTaskActivity(activity);
  // We can transition if we are the responder or an owner when the status is 'Submitted'.
  const canTransition = isResponder || (isOwnerAgency(task) || isOwner(task) && statusName === 'Submitted');
  return (
    <div styleName="activities-status">
      <FormControl variant="outlined" size="small">
        <SelectField
          onChange={onSelectChange}
          disabled={statuses.length === 1 || !canTransition}
          value={statusName}
          selectStyles={{
            root: {
              marginLeft: '0',
              paddingLeft: '0.5rem'
            }
          }}
        >
          {statuses.map(taskStatus => (
            <MenuItem key={taskStatus.name} value={taskStatus.name}>
              <TaskStatusChip taskStatus={taskStatus} />
            </MenuItem>
          ))}
        </SelectField>
      </FormControl>
      {isLate(activity, task.due_date) && <div styleName="status-late-label"><LateLabel /></div>}
      {canTransition && <TransitionDialog />}
    </div>
  );
};

export default Status;
