import React from 'react';
import PropTypes from 'prop-types';
import MuiArrowBackIcon from '@material-ui/icons/ArrowBack';
import * as colors from '@constants/colors';

const ArrowBackIcon = ({ color, onClick, style }) => (
  <MuiArrowBackIcon
    color={color}
    onClick={onClick}
    style={{
      alignItems: 'center',
      display: 'flex',
      flexGrow: 0,
      cursor: 'pointer',
      padding: '0.75rem',
      height: '1.5rem',
      width: '1.5rem',
      ...style
    }}
  />
);

ArrowBackIcon.propTypes = {
  color: PropTypes.string,
  onClick: PropTypes.func,
  style: PropTypes.object
};

ArrowBackIcon.defaultProps = {
  color: colors.textColor
};

export default ArrowBackIcon;
