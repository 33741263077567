import React from 'react';
import PropTypes from 'prop-types';
import SvgIcon from 'material-ui/SvgIcon';

const BoundaryIcon = props => (
  <SvgIcon {...props} style={{...props.style, height: '24px', width: '24px'}} viewBox="0 0 24 24">
    <path
      fillOpacity=".1"
      stroke="#999"
      strokeDasharray="3"
      strokeWidth="2"
      d="M3.429 8.571l10.285-4.285L20.571 18l-17.142.857V8.571z"
    />
  </SvgIcon>
);

BoundaryIcon.propTypes = {
  style: PropTypes.object
};

export default BoundaryIcon;
