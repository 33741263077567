import React from 'react';
import PropTypes from 'prop-types';
import Entity from './entity';
import Group from './group';
import Layer from './layer';

const ListItem = ({
  item: { position, entity, layer, group },
  item
}) => (
  <div>
    {entity && <Entity {...entity} position={position} />}
    {group && <Group {...group} position={position} />}
    {layer && (
      <Layer
        {...layer}
        id={item.layerId}
        icon={item.icon ? item.icon.url : null}
        name={layer.layerType}
        type={item.type}
      />
    )}
  </div>
);

ListItem.propTypes = {
  item: PropTypes.object
};

export default ListItem;
