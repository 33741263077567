import { connect } from 'react-redux';
import { openLayerTray, setOverlapHoverLayer } from '@actions/map-actions';
import LayerListItem from '@shared/new-list-item/layer-list-item';

class Layer extends LayerListItem {
  onClick = () => {
    const { id, type } = this.props;
    const trayProps = {
      id,
      type
    };
    this.props.openLayerTray(trayProps);
  };

  onMouseEnter = () => {
    this.props.setOverlapHoverLayer(this.props);
  };

  onMouseLeave = () => {
    this.props.setOverlapHoverLayer();
  };
}

export default connect(null, { openLayerTray, setOverlapHoverLayer })(Layer);
