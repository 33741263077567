/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { setActiveTab } from '@actions/data-detail-actions';
import OverflowTooltipableLabel from '@shared/overflow-tooltipable-label';
import './tab.scss';

// A vertical tab component that scrolls into the specified id when a
// tab is selected.
class Tab extends Component {
  componentDidMount = () => {
    this.scrollOnSelection();
  };

  componentDidUpdate = prevProps => {
    if (prevProps.currentTab !== this.props.currentTab) {
      this.scrollOnSelection();
    }
  };

  scrollOnSelection = () => {
    if (this.isSelected()) {
      const { id } = this.props;
      const titleElement = document.getElementById(`tab-section-${id}-title`);
      if (titleElement) {
        titleElement.scrollIntoView();
      }
    }
  };

  isSelected = () => {
    const { currentTab, id } = this.props;
    return currentTab === id;
  };

  onClick = () => {
    const { dataType, id } = this.props;
    this.props.setActiveTab(dataType, id);
    // Since we are also handling scrolling in the DOM,
    // if we click on any tab, we MUST force the update
    // manually too:
    this.forceUpdate();
  };

  render() {
    const { label, id } = this.props;
    return (
      <div onClick={this.onClick}
        role="presentation"
        id={`tab-section-${id}-option-item`}
        styleName="tab-container"
      >
        <OverflowTooltipableLabel>{label}</OverflowTooltipableLabel>
      </div>
    );
  }
}

Tab.propTypes = {
  currentTab: PropTypes.string,
  dataType: PropTypes.string,
  id: PropTypes.string,
  label: PropTypes.string,
  onClick: PropTypes.func,
  /**
   * Defines if the current tab is selected or not.
   */
  selected: PropTypes.bool,
  setActiveTab: PropTypes.func
};

const mapStateToProps = (state, props) => {
  const { currentTab } = state.dataDetail;
  return { currentTab: currentTab[props.dataType] };
};

const mapDispatchToProps = { setActiveTab };

export default connect(mapStateToProps, mapDispatchToProps)(Tab);
