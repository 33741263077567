import {
  DATA_TYPES_FETCH_SUCCESS
} from '@constants/action-types';
import { arrayToObjectIds } from '@utils/data-types-utils';
import initialState from '../store/initial-state';

const dataTypesReducer = (state = initialState.dataTypes, action) => {
  switch (action.type) {
  case DATA_TYPES_FETCH_SUCCESS: {
    const { data } = action.payload;
    let types = null;
    if (Array.isArray(data)) {
      types = arrayToObjectIds(data);
    } else {
      types = data;
    }
    return {
      ...state,
      [action.dataType]: types
    };
  }
  default:
    return state;
  }
};

export default dataTypesReducer;
