import React from 'react';
import { getConfig } from '@utils/config-utils';
import './empty.scss';

const Empty = () => (
  <div styleName="search-empty-wrapper">
    <div styleName="search-empty-content">
      {getConfig().search.empty.map((row, index) => (
        <div key={`row-${index}`}>
          <div styleName="search-empty-heading">
            {row.heading.prefix}
            <span styleName="standout">&nbsp;{row.heading.standout}&nbsp;</span>
            {row.heading.suffix}
          </div>
          <ul>
            {row.list.map((item, itemIndex) => <li key={`item-${itemIndex}`}>{item}</li>)}
          </ul>
        </div>
      ))}
    </div>
  </div>
);

export default Empty;
