import React from 'react';

const ExcelIcon = () => (
  <svg width="18px" height="18px" version="1.1" viewBox="0 0 18 18">
    <g id="Excel1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Excel2" transform="translate(-1391.000000, -76.000000)">
        <g id="subnav" transform="translate(0.000000, 60.000000)">
          <g id="btn_xls" transform="translate(1384.000000, 9.000000)">
            <g id="ic_xls" transform="translate(4.000000, 4.000000)">
              <rect id="Rectangle" x="0" y="0" width="24" height="24" />
              <path
                d={`
                    M19,3 L5,3 C3.9,3 3,3.9 3,5 L3,19
                    C3,20.1 3.9,21 5,21 L19,21
                    C20.1,21 21,20.1 21,19 L21,5
                    C21,3.9 20.1,3 19,3 Z M11.75,9
                    L11.75,13.75 L14.25,13.75
                    L14.25,15 L11.75,15
                    C10.9,15 10.5,14.35 10.5,13.5
                    L10.5,9 L11.75,9 Z M7.75,12
                    L9.5,15 L8,15 L7,13.285 L6,15
                    L4.5,15 L6.25,12 L4.5,9 L6,9
                    L7,10.715 L8,9 L9.5,9 L7.75,12 Z
                    M19.25,10.25 L16.5,10.25 L16.5,11.38
                    L18.25,11.38 C18.8,11.38 19.25,11.95
                    19.25,12.5 L19.25,14 C19.25,14.55
                    18.8,15 18.25,15 L15.25,15 L15.25,13.75
                    L18,13.75 L18,12.62 L16.26,12.62
                    C15.71,12.62 15.26,12.05 15.26,11.5
                    L15.26,10 C15.26,9.45 15.71,9
                    16.26,9 L19.25,9 L19.25,10.25 Z
                   `}
                id="Shape"
                fill="#FFFFFF"
                fillRule="nonzero"
              />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default ExcelIcon;
