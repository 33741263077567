import React from 'react';
import { useSelector } from 'react-redux';
import { getDashboardBackgroundColor } from '@constants/config';
import TaskList from './task-list';
import AddTask from './add-task';
import DotmapsLoader from '@shared/dotmaps-loader';
import EntityCard from '@shared/list-card/entity-card';
import GroupCard from '@shared/list-card/group-card';
import { SELECT_MODE_NONE } from '@shared/ui-library/card/entity-data-card';
import './drawer.scss';

const Drawer = () => {
  const { cycle, loading } = useSelector(state => state.workflow);

  if (loading) {
    return (
      <div styleName="cycle-drawer-container">
        <DotmapsLoader display color={getDashboardBackgroundColor()} />
      </div>
    );
  }

  if (!cycle) {
    return null;
  }

  const { entity, group } = cycle;

  return (
    <div styleName="cycle-drawer-container">
      <div styleName="cycle-drawer-header">
        Workflow started on
      </div>
      <div styleName="cycle-drawer-card">
        {entity && entity.id && <EntityCard entity={entity} selectMode={SELECT_MODE_NONE} />}
        {group && group.id && <GroupCard group={group} />}
      </div>
      <AddTask />
      <TaskList />
    </div>
  );
};

export default Drawer;
