// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.src-components-public-___files__container{padding:1rem 2rem;font-weight:400;font-size:.875rem;line-height:1.25rem}.src-components-public-___files__upload-button{margin:2rem 0}
`, "",{"version":3,"sources":["webpack://./src/components/public/files.scss"],"names":[],"mappings":"AAGA,2CACE,iBAAkB,CAClB,eAAgB,CAChB,iBAAkB,CAClB,mBAAoB,CACrB,+CAGC,aAAc","sourcesContent":["@import '../../styles/vars';\n@import '../../styles/mixins';\n\n.container {\n  padding: 1rem 2rem;\n  font-weight: 400;\n  font-size: .875rem;\n  line-height: 1.25rem;\n}\n\n.upload-button {\n  margin: 2rem 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `src-components-public-___files__container`,
	"upload-button": `src-components-public-___files__upload-button`,
	"uploadButton": `src-components-public-___files__upload-button`
};
export default ___CSS_LOADER_EXPORT___;
