/* eslint-disable id-length */
/* eslint-disable guard-for-in */
import * as R from 'ramda';
import { createSelector } from 'reselect';
import { getLayerIcon } from '@utils/icon-utils';
import { optimizeSegmentForMap } from '@utils/map-utils';
import { sortBy } from '@utils/shared-utils';

export const optimizeLayerForMap = (name, icon, items) =>
  items.map(({ attrs, bounds, id, center, geohash, shape}) => {
    const segment = { center, geohash, shape };
    return {
      attrs,
      bounds,
      id,
      name,
      segments: [optimizeSegmentForMap(segment)],
      icon: getLayerIcon(icon)
    };
  });

const getLayers = state => state.layers;
const getBounds = state => state.map.viewport.bounds;
const getLayerFilterEnabled = state => state.map.filterToggles.layer;

export const getLayersIfEnabled = createSelector([
  getLayers,
  getLayerFilterEnabled
], (
  layers,
  enabled
) => {
  if (enabled) {
    return layers;
  }
  return {};
});

// Filter layers and return only the ones within the viewport (and that are visible,
// i.e. that were selected to be displayed with the UI toggle).
export const boundsLayersSelector = createSelector([getLayers, getBounds, getLayerFilterEnabled], (layers, bounds, enabled) => {
  const layerWithinBounds = {};
  if (bounds && enabled) {
    // eslint-disable-next-line guard-for-in
    for (const name in layers) {
      const layer = layers[name];
      if (layer.visible) {
        const { list } = layer;
        const listWithinBounds = list.filter(layerItem => bounds.intersects(layerItem.bounds));
        layerWithinBounds[name] = { ...layer, list: listWithinBounds };
      }
    }
  }
  return layerWithinBounds;
});

export const layerTypesSelector = createSelector([
  boundsLayersSelector,
  getLayers
], (
  layers,
  layerTypes
) => {
  // This works like getSortedUniqueDataType(), which is for entities,
  // however with them we must loop through each element and get the entity type,
  // here layers are already grouped by type, so we only need to get the keys:
  const uniqueLayers = Object.keys(layers);
  const existing = [...uniqueLayers].map(layer => layerTypes[layer]);
  return sortBy(R.values(existing), 'name'); // Sort by name.
});

// Return the layers as a list grouped and ordered by parent.
//
// Since each layer may have a parent layer, we want that list sorted in this way:
//
//   parent1
//   child1
//   child2
//   parent2
//   child1
//   child2
//   ...
export const parentOrderedLayersSelector = createSelector(
  [getLayers],
  layers => {
    const allLayers = Object.values(layers);
    const parents = allLayers.filter(layer => layer.parent_id === null);
    const children = allLayers.filter(layer => layer.parent_id !== null);

    // Group children by parent_id:
    const groupedChildren = children.reduce((acc, child) => {
      if (!acc[child.parent_id]) {
        acc[child.parent_id] = [];
      }
      acc[child.parent_id].push(child);
      return acc;
    }, {});

    // Sort parents and children by the 'order' attribute:
    parents.sort((a, b) => a.order - b.order);
    for (const parentId in groupedChildren) {
      groupedChildren[parentId].sort((a, b) => a.order - b.order);
    }

    // Combine parents with their corresponding children:
    const ordered = [];
    parents.forEach(parent => {
      ordered.push(parent);
      if (groupedChildren[parent.id]) {
        parent.children = groupedChildren[parent.id].length;  // Annotate in the parent
                                                              // a property with the number of children.
        ordered.push(...groupedChildren[parent.id]);
      }
    });

    return ordered;
  }
);
