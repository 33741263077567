/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { colors } from '@constants/colors';
import { Icon } from '@mui';
import DefaultDate from '@shared/formatted-date-helper';
import { PriorityIcon } from '@utils/icon-utils';
import { getConfigValue } from '@utils/list-item-utils';
import './list-item.scss';

class NewBaseListItem extends Component {
  // Implement these in subclasses:
  getType = () => {};
  getTypeIconName = () => {};
  getStatus = () => {};
  onClick = () => {};
  onMouseEnter = () => {};
  onMouseLeave = () => {};
  getEntityTypeName = () => {};

  // Define to set additional CSS class names
  // to append to 'list-item'.
  getStyleNames = () => '';

  getTypeIcon = () => (
    <div styleName="icon type-icon">
      <Icon
        color={colors.opacity.black0_6}
        style={{ fontSize: 16 }}
      >
        {this.getTypeIconName()}
      </Icon>
    </div>
  );

  getTitleName = () => getConfigValue(this.props.type_name, 'title', this.props);

  getHeaderIcon = () => this.props.icon;

  getEntityIcon = () => {
    const icon = this.getHeaderIcon();
    return (
      <div styleName={`icon entity-icon entity-${this.getEntityTypeName()}`}>
        {icon && <img src={icon} alt="" />}
      </div>
    );
  };

  getTitle = () => (
    <div styleName="title">
      {this.getTitleName()}
    </div>
  );

  getHeaderId = () => {
    const { id } = this.props;
    return (
      <div styleName="id">
        ID {id}
      </div>
    );
  };

  getName = () => {
    const { name } = this.props;
    return (
      <div styleName="name">{name}</div>
    );
  };

  getHeader = () => (
    <div styleName="header">
      {this.getEntityIcon()}
      {this.getTitle()}
      {this.getTypeIcon()}
      {this.getHeaderId()}
    </div>
  );

  getContent = () => (
    <div styleName="content">
      {this.getName()}
    </div>
  );

  getFooter = () => (
    <div styleName="footer">
      {this.getPriorityCell()}
      {this.getDate()}
      {this.getType()}
    </div>
  );

  getPriority = () => this.props.category_dict && this.props.category_dict.find(category => category.name === 'priority');

  getPriorityCell = () => {
    const priority = this.getPriority();
    if (!priority) {
      return null;
    }
    return (
      <div styleName="footer-icon">
        <span><PriorityIcon priority={priority.id} /></span>
        <span>&nbsp;&nbsp;&bull;&nbsp;&nbsp;</span>
      </div>
    );
  };

  getDateContent = () => {
    const {
      start_date: startDate,
      end_date: endDate
    } = this.props;
    if (startDate && endDate) {
      return (
        <span><DefaultDate value={startDate} />&ndash;<DefaultDate value={endDate} /></span>
      );
    } else if (startDate || endDate) {
      return (
        startDate ?
          <span><DefaultDate value={startDate} /></span> :
          <span><DefaultDate value={endDate} /></span>
      );
    }
    return 'Start/end dates unspecified.';
  };

  getDate = () => (
    <div styleName="footer-date">{this.getDateContent()}</div>
  );

  render() {
    const { id } = this.props;
    if (!id) {
      return null;
    }
    return (
      <div key={id}
        onClick={this.onClick}
        onMouseEnter={this.onMouseEnter}
        onMouseLeave={this.onMouseLeave}
        role="presentation"
        styleName={`list-item ${this.getStyleNames()}`}>
        {this.getHeader()}
        {this.getContent()}
        {this.getFooter()}
      </div>
    );
  }
}

NewBaseListItem.propTypes = {
  agency_name: PropTypes.string,
  category_dict: PropTypes.object,
  end_date: PropTypes.string,
  icon: PropTypes.string,
  id: PropTypes.number,
  name: PropTypes.string,
  onClick: PropTypes.func,
  priority: PropTypes.number,
  segments: PropTypes.object,
  start_date: PropTypes.string,
  type_name: PropTypes.string
};

export default NewBaseListItem;
