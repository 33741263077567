import React from 'react';
import PropTypes from 'prop-types';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import '../layouts/not-authenticated/not-authenticated.scss';

const Success = ({ isOnboarding }) => {
  const confirmationMessage = isOnboarding ?
    'You have successfully created a Coordinate account. Go to the login page to sign into the app.' :
    'Your password was successfully reset.';

  return (
    <div styleName="success-section">
      <div styleName="success-check-icon">
        <CheckCircleIcon htmlColor="#1DAF54" style={{ fontSize: '5rem' }} />
      </div>
      <div styleName="auth-heading">Great</div>
      <div>
        <div styleName="auth-section">
          <div>
            { confirmationMessage }
          </div>
        </div>
      </div>
    </div>
  );
};

Success.propTypes = {
  isOnboarding: PropTypes.bool
};

export default Success;
