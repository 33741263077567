import React from 'react';
import DataUriIcon from './index';

// Map PNG image (PNG to base64):
const data = 'iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNS' +
  'R0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAVqSURBVHgBpVbpbhNnFD3exkvs8dhObMfEThyHJE1KGgRUap+CV+gLIPVJ+' +
  'gi8AlX/VGrVllalFYJmD5AACQTv24y3GY+3nvkcbGhL/3Cjicaeb+757jnn3s82MM7OzpZGo9FdXtv8qODj457dbv86nU6f' +
  '26zkw+Fw58dff1IePPoTeleHw+GAzWbDrbU1zCsRHJ1koWqdydvxqILVTAzZXAXHT8/hcUuIxsOQnA64XBKW0svYunFTJch' +
  '1x507d+4e7u1vP95/jEqjLhKMeNl4nRcK6A8H+GxtCYPBEM2WIZ632oa4X0nHoIRkFEsqalUNdqcTLqcNtVrVWuaZi8W27b' +
  'y5/fCPR4i4Q/h8bQtetwekiomH4A5wkn2D+/t7SCYUJoyysnEVNbWNveM3CMl+bF3LwB/0o1ysIcfL6A1wevLUyiMA0O/3' +
  'Ua9rqBU0bC9uIB1PiuRWNouusqbhh52/4Pe78MnVBJ+NUbRGB/vHFwj6fVi7uoBwLIyubiKXLaHMiiw9BcDKShLhsEIa+nxY' +
  'hB9e3Mhswu/ximrsBGrpOn7e2wWcQ3y6vjDeAKPR0nHw5AJheQapxBxC0RAkalIuVMRzsaper0KWPchkFuHzedFotFAt1LG' +
  'ZWMXKfArDSxCj18NvB/vojUyCXJmAaE0dh8+yiEZkxCKKoEuJKFMAKzqdNprNOhKJWaRS8+LlfL4EV8+JWyvXEJgJCP5N0vn' +
  '74QEGtv4lyJiuWr2NkxcFLJAm2e+F5JGmAA6qHwpHSAdLbqjUxEA6vYC5uTCBDZRyVaxHl/BJKkMrOmEOBri/s0PXjLC+Mj' +
  '8RvlDScJGtYvlKFG63awow4K4amioM6iHvluiqWoXX66Q+i5iZ8aFYrGLQHOH68ibCARmdbhe/7O1hxi9heTE66ZGz12WKr' +
  'yNzJTYFcLvdFHgwXmGzPnvErWHorKiGWCyEpaUFVjhEMVtBOpTEtcw6en1qQrriURnzMWXSQ8enOdGo0wpGDgSDYWFJg27p' +
  'dg14fT7IQUW4qNnU+F2bIAnE43NsJA3D1gA3qY3klPDg6AhX2SNywIux7Qd4cpqdAlTUOrKlMjzeAL0uC3S90xG02R0W+Nj' +
  'CqlrjKLBsnSJ9HhRYTWImRsrCeJHPY2M1weeOyx7R33dRnwmypSJqWouWDVGLMU1DUqcbBkHtcFJgqzpNqyESCWB5OQldN2C' +
  'oJiTbDNySC6vpON4NO/4RHQ671/kCun22IQedldRkUot/j9fLnfsEba1Wkw5rIJmMU6MICrkynpH72Ygf0Vn5wwBjoUaoN2' +
  'i5fBEuiVrIQUFbq9nkjjuiuqASAqewqMbGnrDcBlaZY4NaQ/AtVU7rn8/noVWH6Jrme0CD0QD5CrVxudmlYfR6hgAwSFm3az' +
  'KfjaPaw+90mGaFWs0wsYdV25FOzk4r2Ht5zKn5nAv88Hm9/6rI6HVJW5709REKRcTMtygbDUfCBHa7QzSbNQ0aHPmWwG9tKw' +
  'CshT2+8PDJLkqNMvycR2/nzCSYQCPvr7IFJpW4mZBY0261SNUAAdLo8/kEcK2uwuiaU4rsPIksPq2R8ez5KcqzNWxlNtA3+8J' +
  'B78aQf0UeKJLTxcE2y/dMtNstWlqMZ7ipj4terqvNSQWql7TYLncsK0Fq0cXuiyOecFUocgD/FSa7+KKQ57g26LZZSJJbfN+' +
  '19DG64AE4BqDldr+8+YWwnsmyhsJD4qzBef4NXhYvEAl/4HcA1zT1Nl7lsnzfcUmbg2YwkeK5zJzfTg79e99/pxw8PSQluphLP' +
  'YK52a0Wzwq7ezWRRqVWx/+Fy+7Elfk4FlILWNvYFIe+mEiXIN/w9jY+PlT2zC6vr6yfLX8DihGXmAP8gaUAAAAASUVORK5CYII=';

const SatelliteMapTypeIcon = props => <DataUriIcon data={data} {...props} />;

export default SatelliteMapTypeIcon;
