import { AUTH_LOGOUT_RESET } from '@constants/action-types';

export const rootAuthReducer = (state, action) => {
  // Dan Abramov's solution to reset the state after logout:
  //
  //     https://stackoverflow.com/questions/35622588/how-to-reset-the-state-of-a-redux-store
  //
  // State is still maintained after we logout, thus a new user
  // might have data cached from the previous browser session.
  // The solution is to reset the whole global state.
  if (action.type === AUTH_LOGOUT_RESET) {
    state = undefined; // eslint-disable-line no-param-reassign, no-undefined
  }

  return state;
};
