// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.src-components-portal-___portal__portal{display:flex;height:100%}.src-components-portal-___portal__portal .src-components-portal-___portal__filters{padding-block:1rem;padding-inline:1.5rem}.src-components-portal-___portal__portal .src-components-portal-___portal__data{display:flex;flex-direction:column;flex:1 1 auto;overflow:hidden;background:white}.src-components-portal-___portal__portal .src-components-portal-___portal__data>*{flex:0 0 auto}.src-components-portal-___portal__portal .src-components-portal-___portal__data>.src-components-portal-___portal__table{flex:1 1 auto;overflow-x:auto;overflow-y:hidden}
`, "",{"version":3,"sources":["webpack://./src/components/portal/portal.scss"],"names":[],"mappings":"AAAA,yCACE,YAAa,CACb,WAAY,CAFd,mFAKI,kBAAmB,CACnB,qBAAsB,CAN1B,gFAUI,YAAa,CACb,qBAAsB,CAEtB,aAAc,CACd,eAAgB,CAChB,gBAAiB,CAfrB,kFAkBM,aAAc,CAlBpB,wHAsBM,aAAc,CACd,eAAgB,CAChB,iBAAkB","sourcesContent":[".portal {\n  display: flex;\n  height: 100%;\n\n  .filters {\n    padding-block: 1rem;\n    padding-inline: 1.5rem;\n  }\n\n  .data {\n    display: flex;\n    flex-direction: column;\n\n    flex: 1 1 auto;\n    overflow: hidden;\n    background: white;\n\n    & > * {\n      flex: 0 0 auto;\n    }\n\n    & > .table {\n      flex: 1 1 auto;\n      overflow-x: auto;\n      overflow-y: hidden;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"portal": `src-components-portal-___portal__portal`,
	"filters": `src-components-portal-___portal__filters`,
	"data": `src-components-portal-___portal__data`,
	"table": `src-components-portal-___portal__table`
};
export default ___CSS_LOADER_EXPORT___;
