//
// Utility methods to process markers.
//
// The old version of this code used Ramda for array and object processing,
// however since this is a critical part of the code, all that code was converted
// to plain Javascript which has better performance (i.e. Object.keys() instead of
// Ramda's keys(), Object.values() vs values(), Object.map, using Set(), etc).
import { includes, isEmpty } from 'lodash';
import { dotmapsClusterBlue, overlayTextColor } from '@constants/colors';
import {
  getMarkerAlwaysVisibleZoom,
  getMarkerVisibleZoom,
  getZoomIndexes
} from '@constants/config';
import { optimizeLayerForMap } from '@selectors/layers-selector';

// Count the number of entries for an entity or layer.
export const entityCount = items => {
  let count = 0;
  Object.values(items).forEach(item => {
    if (Array.isArray(item)) {
      count += item.length;
    } else {
      Object.keys(item).forEach(key => {
        if (Array.isArray(item[key])) {
          count += item[key].length;
        } else {
          count += 1;
        }
      });
    }
  });
  return count;
};

// Generate a unique Id for the entity:
export const generateEntityId = items => {
  const ids = [];
  Object.keys(items).forEach(key => {
    ids.push(Object.keys(items[key]).join('-'));
  });
  return ids.join('--');
};

const getSources = (entities, layers) => {
  const sources = {};
  Object.keys(entities).forEach(key => {
    sources[key] = entities[key];
  });
  // Append layers to the source list:
  Object.keys(layers).forEach(key => {
    const { label, icon, list, visible } = layers[key];
    if (visible && list && list.length > 0) {
      const isPoint = list[0].shape.type === 'Point';
      // Only calculate clusters for 'Point' type layers:
      if (isPoint) {
        sources[key] = optimizeLayerForMap(label, icon, list);
      }
    }
  });
  return sources;
};

const extractIds = items => {
  const entities = {};
  items.forEach(item => {
    for (const key of Object.keys(item)) {
      Object.keys(item[key]).forEach(id => {
        if (!entities[key]) {
          // Using Set() is faster than using Object to store and
          // check for 'contains' for item data.
          entities[key] = new Set();
        }
        entities[key].add(parseInt(id, 10));
      });
    }
  });
  return entities;
};

// If we contain more than one geohash, we are clustering by proximity:
const isProximityCluster = geohashes => geohashes.size > 1;

const getMarkerStyle = (count, isProximity) => {
  // Increase the diameter and font depending on the items count:
  const digits = count.toString().length;
  const diameterIncrement = 0.5 * (digits - 1);  // Use 8px increments.
  const fontSizeIncrement = 0.125 * (digits - 1);  // Use 2px increments.
  if (isProximity) {
    const diameter = `${3 + diameterIncrement}rem`;
    const fontSize = `${1 + fontSizeIncrement}rem`;
    return {
      background: 'rgba(66,132,244,0.8)',  // Semitransparent blue background effect for proximity clusters
      boxShadow: '0 0 0 .625em rgba(66,133,244,0.2)',
      color: overlayTextColor,
      fontSize,
      height: diameter,
      width: diameter
    };
  }
  const diameter = `${1.75 + diameterIncrement}rem`;
  const fontSize = `${0.75 + fontSizeIncrement}rem`;
  return {
    background: overlayTextColor,
    boxShadow: '1px -1px 2px 0 rgba(0,0,0,0.4)',
    color: dotmapsClusterBlue,
    fontSize,
    height: diameter,
    width: diameter
  };
};

// Annotate each cluster with some required calculated data:
const annotateCenters = centers => centers.map(center => {
  const count = entityCount(center.items);
  const isProximity = isProximityCluster(center.geohashes);
  return {
    center: center.center,
    count,
    isProximity,
    items: center.items,
    markerStyle: getMarkerStyle(count, isProximity)
  };
});

const generateGroupedMarkers = centers => {
  const items = centers.map(center => center.items);
  return {
    calculated: true,
    centers: annotateCenters(centers),
    ...extractIds(items)
  };
};

export const groupMarkers = (entities = {}, layers = {}, zoom) => {
  const centers = {};
  const zoomIndex = getZoomIndexes(zoom);
  const sources = getSources(entities, layers);
  Object.keys(sources).forEach(type => {
    const items = sources[type];
    items.forEach(item => {
      item.segments.forEach(segment => {
        if (segment.geohash) {
          // substring() is faster than substr().
          const idx = zoomIndex ? segment.geohash.substring(0, zoomIndex) : segment.geohash;
          if (!centers[idx]) {
            centers[idx] = {
              center: segment.center,
              // Save geohash to calculate cluster type at the end:
              geohashes: new Set(),
              items: { [type]: { [item.id]: item } }
            };
            centers[idx].geohashes.add(segment.geohash);
          } else {
            const center = centers[idx];
            if (typeof center.items[type] === 'undefined') {
              center.items[type] = {};
            }
            if (!center.items[type][item.id]) {
              center.items[type][item.id] = item;
            }
            center.geohashes.add(segment.geohash);
          }
        }
      });
    });
  });
  return generateGroupedMarkers(Object.values(centers).filter(center => entityCount(center.items) > 1));
};

// Tells whether to render markers (entity Markers or cluster overlays),
// based on the current zoom level.
export const isMarkerVisible = (markers, zoom, entityName, id) => {
  // Markers will appear only:
  if (zoom >= getMarkerVisibleZoom() &&  // If we are on a 'visible' zoom level.
      markers.calculated &&  // After we calculated the clusters.
      (
        zoom >= getMarkerAlwaysVisibleZoom() ||  // After a specific zoom level, we must
                                                                         // always show markers (after calculation).
        // And for clustering zoom levels, don't show the marker
        // if it's inside a cluster.
        (
          typeof markers[entityName] !== 'undefined' &&
          !markers[entityName].has(id)
        )
      )
  ) {
    return true;
  }
  return false;
};

// Like isMarkerVisible() but for layers.
export const isLayerMarkerVisible = (state, layerName, layer) => {
  const { id, shape } = layer;
  if (shape.type !== 'Point') {
    return true;
  }
  if (state.map.viewport.zoom < getMarkerVisibleZoom() ||  // Layers are always visible below the
                                                           // markerVisibleZoom zoom level, it's the
                                                           // opposite of isMarkerVisible().
     (state.markers.calculated &&  // On cluster zoom levels, only show the layer if
       (
         !state.markers[layerName] ||  // The layer is not clustered
         !state.markers[layerName].has(id)  // Or if it's clustered and the id is not on a cluster.
       )
     )
  ) {
    return true;
  }
  return false;
};

export const areAllEntitiesEmpty = entities => {
  if (entities) {
    for (const key of Object.keys(entities)) {
      if (!isEmpty(entities[key])) {
        return false;
      }
    }
  }
  return true;
};

const getFirstNonEmptyEntity = entities => {
  if (entities) {
    for (const key of Object.keys(entities)) {
      if (!isEmpty(entities[key])) {
        return {
          type: key,
          entity: entities[key]
        };
      }
    }
  }
  return null;
};

const getEntityId = entityData => {
  const { entity } = entityData;
  if (entity) {
    return entity.id;
  }
  return null;
};

// Returns true if there's a single entity in the tray (entity or layer)
// and it also exists on the supplied cluster item list.
export const isTrayEntityInCluster = (traySingleEntries, clusterItems) => {
  const entityData = getFirstNonEmptyEntity(traySingleEntries);  // Get the single tray entity.
  if (entityData) {
    const id = getEntityId(entityData);
    if (id) {
      const items = clusterItems[`${entityData.type}s`];  // Obtain the cluster list for that entity.
      if (items && id in items) {  // If the entity id is in the list, then it exists in the cluster.
        return true;
      }
    }
  }
  return false;
};

export const isHoveredTrayEntityInCluster = (trayOverlapHoverEntries, clusterItems) => {
  const entityData = getFirstNonEmptyEntity(trayOverlapHoverEntries);  // Get the tray entity being hovered.
  if (entityData) {
    const id = entityData.entity.id;
    if (id) {
      const items = clusterItems[`${entityData.type}s`];
      if (items && id in items) {
        return true;
      }
    }
  }
  return false;
};

const clusterContains = (type, ids, clusterItems) => {
  if (!isEmpty(ids)) {
    const items = clusterItems[type];
    if (items) {
      const itemIds = Object.keys(items).map(id => parseInt(id, 10));
      // eslint-disable-next-line id-length, no-plusplus
      for (let i = 0; i < ids.length; i++) {
        // eslint-disable-next-line max-depth
        if (includes(itemIds, ids[i])) {
          return true;
        }
      }
    }
  }
  return false;
};

export const isAnyTrayItemInCluster = (trayEntitiesIds, trayLayersIds, clusterItems) => {
  if (clusterItems) {
    for (const key of Object.keys(clusterItems)) {
      if (key === 'entities' && trayEntitiesIds && clusterContains('entities', trayEntitiesIds, clusterItems)) {
        return true;
      } else
        if (trayLayersIds && clusterContains(key, trayLayersIds[key], clusterItems)) {
          return true;
        }
    }
  }
  return false;
};
