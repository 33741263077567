/* eslint-disable react/jsx-no-bind */
import React, { Fragment, memo, useCallback, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { push } from 'connected-react-router';
import PropTypes from 'prop-types';

import { downloadCSVExport } from '@actions/data-table-actions';
import { openDashboardDialog } from '@actions/dashboard-actions';

import { BATCH_DATA_TYPE, GROUP_DATA_TYPE } from '@components/config/constants';
import { useCanAccessType, useTypeIsReadOnly } from '@components/config/permissions/hooks';
import { getDataTypeConfig } from '@components/config/selectors';
import { getDataTypeFilterParams } from '@components/filters/selectors';
import BatchUploadEntitiesDialog from '@components/portal/table/batch-upload-entities-dialog';
import BatchUploadLayersDialog from '@components/portal/table/batch-upload-layers-dialog';

import { colors } from '@constants/colors';
import { isNewBatch } from '@constants/config';
import * as dialog from '@constants/dialogs';

import ChevronRightIcon from '@material-ui/icons/ChevronRight';

import { Icon } from '@mui';

import AddButton from '@shared/add-button';
import ExportButton from '@shared/export-button';
import BatchUploadForm from '@shared/batch-upload-form';
import IconMenu from '@shared/icon-menu';
import IconMenuItem from '@shared/icon-menu-item';

import { useLegacyTypes } from '../hooks';

import styles from './header.scss';

// Since AddButton removes padding, restore the default one when padding is needed,
// so AddButton matches other IconButtons when rendered together.
const DEFAULT_ICONBUTTON_PADDING = '12px';

const applyLabelPostfix = (label, dataType) => {
  if (dataType === GROUP_DATA_TYPE) {
    return `${label} groups`;
  }
  return label;
};

const getAddTooltip = (dataType, subType) => {
  if (dataType === GROUP_DATA_TYPE) {
    return `Add ${subType} group`;
  }
  return `Add ${subType ? subType : dataType}`;
};

const PortalHeader = ({ dataType, subType }) => {
  const dispatch = useDispatch();
  const [batchOpen, setBatchOpen] = useState(false);

  const typeConfig = useSelector(state => getDataTypeConfig(state, dataType, subType));
  const {['top-bar']: {link, permission} = {} } = typeConfig;
  const readOnly = useTypeIsReadOnly(dataType, subType);
  const hasLinkAccess = useCanAccessType(null, dataType, subType, permission);
  const addTooltip = useMemo(() => getAddTooltip(dataType, subType), [dataType, subType]);

  const labels = useMemo(() => {
    if (typeConfig.children && subType) {
      const childLabel = typeConfig.children.find(child => child.type === subType)?.label;
      return [
        typeConfig.label,
        childLabel && applyLabelPostfix(childLabel, dataType)
      ].filter(label => Boolean(label));
    }
    return [applyLabelPostfix(typeConfig.label, dataType)];
  }, [typeConfig, dataType, subType]);

  const openBatch = useCallback(() => {
    setBatchOpen(true);
  }, [setBatchOpen]);

  const closeBatch = useCallback(() => {
    setBatchOpen(false);
  }, [setBatchOpen]);

  const openEntities = useCallback(() => dispatch(openDashboardDialog(dialog.BATCH_ENTITY)), [dispatch]);
  const openLayers = useCallback(() => dispatch(openDashboardDialog(dialog.BATCH_LAYER)), [dispatch]);

  const onAdd = useCallback(() => {
    if (link) {
      dispatch(push(link.to));
    }
  }, [dispatch, link]);

  const [legacyDataType, legacySubType] = useLegacyTypes(dataType, subType);

  const filterParams = useSelector(state => getDataTypeFilterParams(state, dataType, subType));

  const onExport = useCallback(() => {
    dispatch(downloadCSVExport(legacyDataType, legacySubType, filterParams));
  }, [dispatch, legacyDataType, legacySubType, filterParams]);

  return (
    <div className={styles.header}>
      <span className={styles.title}>
        {labels.map((label, index) => (
          <Fragment key={label}>
            {index > 0 && <ChevronRightIcon/>}
            <span className={styles.label}>{label}</span>
          </Fragment>
        ))}
      </span>
      {link && hasLinkAccess && !readOnly && dataType !== BATCH_DATA_TYPE &&
        <AddButton onClick={onAdd} style={{ padding: DEFAULT_ICONBUTTON_PADDING }} tooltip={addTooltip} />
      }
      {/* Old add button for batch: */}
      {dataType === BATCH_DATA_TYPE && !isNewBatch() &&
        <Fragment>
          <AddButton onClick={openBatch} style={{ padding: DEFAULT_ICONBUTTON_PADDING }} tooltip="Upload batch" />
          <BatchUploadForm open={batchOpen} onClose={closeBatch} />
        </Fragment>
      }
      {dataType === BATCH_DATA_TYPE && isNewBatch() &&
        <Fragment>
          <BatchUploadEntitiesDialog />
          <BatchUploadLayersDialog />
          <IconMenu
            icon={<Icon color={colors.opacity.black0_54}>add</Icon>}
            iconButtonStyles={{}}
            MenuProps={{
              anchorOrigin: { horizontal: 'center', vertical: 'bottom' },
              transformOrigin: { horizontal: 'center', vertical: 'top' }
            }}
            tooltip="Upload batch"
          >
            <IconMenuItem icon={<Icon>work</Icon>} onClick={openEntities}>Entities</IconMenuItem>
            <IconMenuItem icon={<Icon>layers</Icon>} onClick={openLayers}>City Layers</IconMenuItem>
          </IconMenu>
        </Fragment>
      }
      <ExportButton onClick={onExport} />
    </div>
  );
};

PortalHeader.propTypes = {
  dataType: PropTypes.string,
  subType: PropTypes.string
};

export default memo(PortalHeader);
