// This class is the same as the material-ui Popover class, but positions the
// popover element at the 'x,y' position where we clicked.
import Popover from 'material-ui/Popover';
import PropTypes from 'prop-types';

class XYPopover extends Popover {
  setPlacement = scrolling => {  // eslint-disable-line no-unused-vars
    const { layer } = this.popoverRefs;
    const targetEl = layer.getLayer() &&
                     layer.getLayer().children &&
                     layer.getLayer().children[0];
    if (!targetEl) {
      return;
    }

    // Check for windows boundaries to place the popup inside it:
    const popupWidth = targetEl.offsetWidth;
    const popupHeight = targetEl.offsetHeight;
    let xPosition = this.props.x;
    let yPosition = this.props.y;
    if (xPosition + popupWidth > window.innerWidth) {
      xPosition -= popupWidth;
    }
    if (yPosition + popupHeight > window.innerHeight) {
      yPosition -= popupHeight;
    }

    targetEl.style.top = `${yPosition}px`;
    targetEl.style.left = `${xPosition}px`;
    targetEl.style.maxHeight = `${window.innerHeight}px`;
  };
}

XYPopover.propTypes = {
  x: PropTypes.number,  // eslint-disable-line id-length
  y: PropTypes.number  // eslint-disable-line id-length
};

export default XYPopover;
