/* eslint-disable id-length */
import React from 'react';
import PropTypes from 'prop-types';
import * as colors from '@constants/colors';
import CustomChip from '@shared/custom-chip';

const TaskStatusChip = ({ taskStatus }) => {
  if (!taskStatus) {
    return null;
  }
  return (
    <CustomChip
      colors={{
        background: taskStatus.color,
        foreground: colors.overlayTextColor
      }}
      label={taskStatus.name}
      size="small"
    />
  );
};

TaskStatusChip.propTypes = {
  taskStatus: PropTypes.object
};

export default TaskStatusChip;
