/* eslint-disable id-length */
/* eslint-disable react/jsx-no-bind */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import { isEmpty } from 'lodash';
import MenuItem from 'material-ui/MenuItem';
import SelectField from 'material-ui/SelectField';
import TextField from 'material-ui/TextField';
import { resolveConflict as dashboardResolve } from '@actions/dashboard-actions';
import { resolveConflict as groupsResolve } from '@actions/groups-actions';
import * as dialog from '@constants/dialogs';
import { dialogStyles, styleGuide } from '@constants/mui-theme';
import Button from '@material-ui/core/Button';
import { Checkbox } from '@mui';
import { OVERLAP_STATUS } from '@utils/data-detail/conflicts';
import { isAppAdmin } from '@utils/permission-utils';
import { sortBy } from '@utils/shared-utils';
import './actions-resolve-box.scss';

const defaultState = { notes: '', reason: '', bothSides: false };

const MAX_NOTES_LEN = 5000;

class ResolveBox extends Component {
  constructor(props) {
    super(props);
    this.state = defaultState;
  }

  onCancel = () => {
    this.props.onClose();
  };

  onResolve = () => {
    const { dialogParams } = this.props;
    const payload = {
      ...dialogParams,
      ...this.state
    };
    // Dispatch action to resolve the conflict on the backend:
    if (location.pathname.startsWith('/group/')) {
      this.props.groupsResolve(payload);
    } else {
      this.props.dashboardResolve(payload);
    }
    this.setState(defaultState);
    this.props.onClose();
  };

  onSelectChange = (event, index, value) => this.setState({reason: value});

  onTextChange = (event, value) => this.setState({notes: value.substring(0, MAX_NOTES_LEN)});

  disableBothSidesCheck = (entity, overlapId) => {
    if (!entity || !overlapId || !isAppAdmin()) {
      return true;
    }
    const overlap = R.find(R.propEq(overlapId, 'overlap'))(entity.overlap_status);
    return !overlap || overlap.overlap_status !== OVERLAP_STATUS.new;
  };

  onCheck = () => this.setState({ bothSides: !this.state.bothSides });

  getStatus = () => {
    const { entity: { overlap_status } } = this.props;
    return overlap_status[0];
  };

  getReasons = () => {
    const { overlapResolutionReasons } = this.props;
    return sortBy(R.values(overlapResolutionReasons), 'name');
  };

  render() {
    const { dialogParams, entity, loading } = this.props;
    if (loading) {
      return null;
    }
    const reasons = this.getReasons();
    const { bothSides, reason, notes } = this.state;
    return (
      <div styleName="resolve-box-form-container">
        <div styleName="title">
          Resolve Conflict
        </div>
        <div styleName="resolve-box-form">
          <div styleName="controls">
            <div styleName="field">
              <SelectField
                floatingLabelText={reason ? null : 'How was this conflict resolved?'}
                fullWidth
                onChange={this.onSelectChange}
                value={reason}
                multiple
                {...dialogStyles.select}
              >
                {reasons.map(r => <MenuItem key={r.name} value={r.name} primaryText={r.name} />)}
                <MenuItem value="Other" primaryText="Other" />
              </SelectField>
              <div styleName="field-footer">
                <div styleName="hint">
                  {reason.length > 0 ? '' : 'You can select multiple.'}
                </div>
                <div styleName="counter" />
              </div>
            </div>
            <div styleName="field">
              <TextField
                {...styleGuide.textField}
                floatingLabelText={notes ? null : 'Description (optional)'}
                fullWidth
                multiLine
                onChange={this.onTextChange}
                value={notes}
              />
              <div styleName="field-footer">
                <div styleName="hint" />
                <div styleName="counter">
                  {notes ? notes.length : 0}/{MAX_NOTES_LEN}
                </div>
              </div>
            </div>
          </div>
          <div styleName="footer">
            <div styleName="footer-grow">
              {isAppAdmin() && (
                <Checkbox
                  checked={bothSides}
                  disabled={this.disableBothSidesCheck(entity, dialogParams.overlapId)}
                  label="Resolve the conflict from both sides."
                  onChange={this.onCheck}
                />
              )}
            </div>
            <div styleName="footer-button">
              <Button onClick={this.onCancel}>CANCEL</Button>
              <Button
                color="primary"
                disabled={isEmpty(reason)}
                onClick={this.onResolve}
                style={{ marginLeft: '0.5rem' }}
                variant="contained"
              >
                SAVE
              </Button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

ResolveBox.propTypes = {
  dashboardResolve: PropTypes.func,
  dialogParams: PropTypes.object,
  entity: PropTypes.object,
  groupsResolve: PropTypes.func,
  loading: PropTypes.bool,
  onClose: PropTypes.func,
  overlapResolutionReasons: PropTypes.object
};

const mapStateToProps = state => {
  const {
    dialogParams,
    resolution: { loading }
  } = state.dashboard;
  const { overlap_resolution_reason } = state.dataTypes;
  return {
    dialogParams: dialogParams[dialog.OVERLAP_STATUS],
    loading,
    overlapResolutionReasons: overlap_resolution_reason
  };
};

const mapDispatchToProps = { dashboardResolve, groupsResolve };

export default connect(mapStateToProps, mapDispatchToProps)(ResolveBox);
