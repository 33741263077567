import React from 'react';
import PropTypes from 'prop-types';
import Avatar from '@material-ui/core/Avatar';
import { makeStyles } from '@material-ui/core/styles';
import Skeleton from '@material-ui/lab/Skeleton';

const useStyles = makeStyles(theme => ({
  bigger: ({ style }) => ({
    border: '1px solid #9B9B9B',
    fontSize: 30,
    margin: '1rem 2rem 1rem 0',
    width: theme.spacing(7),
    height: theme.spacing(7),
    ...style
  })
}));

const HeaderAvatar = ({ loaded, src, style, text }) => {
  const classes = useStyles({ style });

  if (!loaded && src) {
    return <Skeleton variant="circle" width="3.5rem" height="3.5rem" />;
  }

  return (
    <Avatar className={classes.bigger} src={src}>
      {text}
    </Avatar>
  );
};

HeaderAvatar.propTypes = {
  loaded: PropTypes.bool,
  src: PropTypes.string,
  style: PropTypes.object,
  text: PropTypes.string
};

export default HeaderAvatar;
