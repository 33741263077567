import React from 'react';

const SettingsIcon = () => (
  <svg width="24px" height="24px" version="1.1" viewBox="0 0 24 24">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d={`
         M18.943 12C18.943 12.3206 18.9199 12.6294 18.8852
         12.9263L20.8176 14.4937C21.0028 14.6362 21.0491
         14.8856 20.9334 15.0994L19.0819 18.3888C18.9662
         18.6025 18.7232 18.6856 18.5149 18.6025L16.2121
         17.6525C15.7377 18.0206 15.2169 18.3413 14.6499
         18.5788L14.3028 21.0963C14.2796 21.3337 14.0829
         21.5 13.8515 21.5H10.1485C9.91709 21.5 9.73195
         21.3337 9.68566 21.0963L9.33851 18.5788C8.7715
         18.3413 8.26234 18.0325 7.77633 17.6525L5.47356
         18.6025C5.26527 18.6738 5.02226 18.6025 4.90655
         18.3888L3.05508 15.0994C2.95093 14.8975 2.99722
         14.6362 3.17079 14.4937L5.12641 12.9263C5.08012
         12.6294 5.05698 12.3088 5.05698 12C5.05698 11.6912
         5.0917 11.3706 5.13798 11.0737L3.18236 9.50625C2.99722
         9.36375 2.95093 9.11438 3.06665 8.90063L4.91812
         5.61125C5.03384 5.3975 5.27684 5.31437 5.48513
         5.3975L7.7879 6.3475C8.26234 5.97938 8.78307
         5.65875 9.35008 5.42125L9.69723 2.90375C9.73195
         2.66625 9.91709 2.5 10.1485 2.5H13.8515C14.0829
         2.5 14.2796 2.66625 14.3143 2.90375L14.6615
         5.42125C15.2285 5.65875 15.7377 5.9675 16.2237
         6.3475L18.5264 5.3975C18.7347 5.32625 18.9777
         5.3975 19.0935 5.61125L20.9449 8.90063C21.0491
         9.1025 21.0028 9.36375 20.8292 9.50625L18.8736
         11.0737C18.9199 11.3706 18.943 11.6794 18.943
         12ZM8.52849 12C8.52849 13.9594 10.0907 15.5625
         12 15.5625C13.9093 15.5625 15.4715 13.9594
         15.4715 12C15.4715 10.0406 13.9093 8.4375
         12 8.4375C10.0907 8.4375 8.52849 10.0406 8.52849 12Z
         `}
      fill="black"
      fillOpacity="0.6"
    />
  </svg>
);

export default SettingsIcon;
