import React from 'react';
import PropTypes from 'prop-types';
import EntityTypeIcon from '@icons/entity-type-icon';
import { highlightTerm } from '@utils/shared-utils';
import './entity-list-item.scss';

const EntityListItem = ({ item, term }) => {
  const { extra, id, title, type } = item;
  const text = highlightTerm(title, term);

  return (
    <div styleName="entity-list-item">
      <div styleName="data">
        <div styleName="header">
          <div styleName="icon"><EntityTypeIcon type={type} /></div>
          <div styleName="id">ID {id}</div>
          {extra && <div styleName="bull">&bull;</div>}
          {extra && <div styleName="title">{extra}</div>}
        </div>
        <div styleName="content">{text}</div>
      </div>
    </div>
  );
};

EntityListItem.propTypes = {
  item: PropTypes.object,
  term: PropTypes.string
};

export default EntityListItem;
