import React from 'react';
import PropTypes from 'prop-types';
import { colors } from '@constants/colors';
import Badge from '@material-ui/core/Badge';
import { withStyles } from '@material-ui/core/styles';
import { Icon } from '@mui';

const StyledBadge = withStyles({
  // Override styles applied to the root element
  // if `anchorOrigin={{ 'bottom', 'right' }} overlap="circle"`. */
  anchorOriginBottomRightCircle: {
    bottom: '24%',
    right: '24%',
    transform: 'scale(1) translate(50%, 50%)',
    transformOrigin: '100% 100%',
    '&$invisible': {
      transform: 'scale(0) translate(50%, 50%)'
    }
  }
})(Badge);

const BadgeIcon = ({ children, size }) => (
  <StyledBadge
    overlap="circle"
    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
    badgeContent={
      <Icon color={colors.brand.red} style={{ fontSize: size }}>block</Icon>
    }
  >
    {children}
  </StyledBadge>
);

BadgeIcon.propTypes = {
  children: PropTypes.node,
  size: PropTypes.number
};

export default BadgeIcon;
