import React, { memo } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import CommentInput from '@shared/comments/comment-input';
import { getUserAvatar } from '@utils/data-table/user';
import './comment-avatar-input.scss';

const CommentAvatarInput = ({ filterUsers, onComment }) => {
  const { user } = useSelector(state => state.config);
  return (
    <div styleName="comment-avatar-input-container">
      <div styleName="avatar">
        {getUserAvatar(user)}
      </div>
      <CommentInput filterUsers={filterUsers} onComment={onComment} />
    </div>
  );
};

CommentAvatarInput.propTypes = {
  filterUsers: PropTypes.func,
  onComment: PropTypes.func
};

export default memo(CommentAvatarInput);
