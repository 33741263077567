/* eslint-disable */
import React, { Component } from 'react';
import { useSelector } from 'react-redux';
import { getGanttConfig } from '@constants/config';
import {
  getFistItemTopUnitSize,
  getTopUnitSize,
  getTopUnitItems,
  getUnitItems,
  getUnitSize
} from '@utils/gantt-utils';
import {
  calculateGanttContainerWidth
} from '@utils/timeline-chart-utils';
import './header.scss';

const Header = () => {
  const { rows } = useSelector(state => state.groups.gantt);
  const { dateUnit } = useSelector(state => state.groups.gantt.filters);

  const renderItem = (item, prefix, index, style, showTitle) => {
    const itemProps = { key: `${prefix}_${index}`, style };
    if (showTitle) {
      // Always show a tooltip for the heading row,
      // since it may contain the first item with ellipsis.
      itemProps.title = item;
    }
    return <div {...itemProps}>{item}</div>;
  };

  const getFirstItemStyle = unitSize => {
    // The first item uses ellipsis if it doesn't fit the assigned space.
    //
    // However if the unit size is too small for an ellipsis, Firefox
    // won't render the ellipsis and Chrome will only render an ellipsis.
    //
    // Thus on that case, don't add the ellipsis effect.
    if (unitSize < getGanttConfig().smallestUnitSize) {
      return { width: `${unitSize}px` };
    } else {
      return {
        maxWidth: `${unitSize}px`,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        width: `${unitSize}px`
      };
    }
  };

  const renderTop = () => {
    const items = getTopUnitItems(dateUnit, rows);
    const renderedItems = [];

    for (let index = 0; index < items.length; index++) {
      let item = null;
      if (index === 0) {
        // Calculate the right width for the first item and prepend it to the list:
        const unitSize = getFistItemTopUnitSize(dateUnit, rows, items, index);

        const style = getFirstItemStyle(unitSize);

        let formattedText = items[index].formatted;

        // If the unit size is too small even for an ellipsis, we won't
        // render the ellipsis nor the text:
        if (unitSize < getGanttConfig().smallestUnitSize) {
          formattedText = '';
        }

        item = renderItem(formattedText, 'top', index, style, true);
      } else {
        const unitSize = getTopUnitSize(dateUnit, rows, items, index);
        const style = {
          width: `${unitSize}px`
        };
        item = renderItem(items[index].formatted, 'top', index, style, false);
      }
      renderedItems.push(item);
    }

    return renderedItems;
  };

  const renderBottom = () => {
    const unitSize = getUnitSize(dateUnit);
    const style = { width: `${unitSize}px` };
    const items = getUnitItems(dateUnit, rows);
    return items.map((item, index) => renderItem(item.formatted, 'bottom', index, style, false));
  };

  const containerStyle = {
    // width: calculateGanttContainerWidth(rows, dateUnit)
  };

  return (
    <div style={containerStyle} styleName="gantt-content-header">
      <div styleName="gantt-content-header-row row-top">
        {renderTop()}
      </div>
      {dateUnit !== getGanttConfig().dateUnits.year.id &&
        <div styleName="gantt-content-header-row row-bottom">
          {renderBottom()}
        </div>
      }
    </div>
  );
};

export default Header;
