/* eslint-disable react/jsx-no-bind */
import React, { memo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import ResetRequest from './reset-request';
import PasswordCreate from './password-create';
import Success from './success';
import {
  authSetUserField,
  sendPasswordChangeRequest,
  sendNewPasswords
} from '@actions/auth-actions';
import { loginStyles } from '@constants/mui-theme';
import { validateEmail, validatePasswords } from '@utils/auth-utils';
import '../layouts/not-authenticated/not-authenticated.scss';

const PasswordRecovery = () => {
  const dispatch = useDispatch();
  const { token, userPk, onboarding } = useParams();
  const {
    email,
    emailErrorText,
    fullname,
    passwordErrorText,
    passwordChangeData,
    requestedReset
  } = useSelector(state => state.auth.user);
  const [password, setPassword] = useState('');
  const [passwordTwo, setPasswordTwo] = useState('');

  const handleFormChange = event => {
    const { name, value } = event.target;
    if (name !== 'password' && name !== 'passwordTwo') {
      dispatch(authSetUserField(name, value));
    } else
      if (name === 'password') {
        setPassword(value);
      } else {
        setPasswordTwo(value);
      }
  };

  const handlePasswordRequest = () => {
    const error = validateEmail(email) ? '' : 'Invalid email address';
    dispatch(authSetUserField('emailErrorText', error));
    if (error === '') {
      dispatch(sendPasswordChangeRequest(email));
    }
  };

  const handleNewPasswordSent = () => {
    const error = validatePasswords(password, passwordTwo);
    dispatch(authSetUserField('passwordErrorText', error));
    if (error === '') {
      dispatch(sendNewPasswords({
        userPk,
        token,
        new_password1: password,
        new_password2: passwordTwo,
        ...(onboarding && {name: fullname})
      }));
    }
  };

  const handleFormSubmit = event => {
    event.preventDefault();
    if (token && userPk) {
      handleNewPasswordSent();
    } else {
      handlePasswordRequest();
    }
  };

  const isOnboarding = onboarding === 'onboarding';

  if (passwordChangeData.status !== 201) {
    return (
      <div styleName="not-authenticated-container">
        {(token && userPk)
          ? <PasswordCreate
            formChange={handleFormChange}
            formSubmit={handleFormSubmit}
            isOnboarding={isOnboarding}
            passwordResetSuccessStatus={passwordChangeData.status}
            passwordErrorText={passwordErrorText}
            fullname={fullname}
            password={password}
            passwordTwo={passwordTwo} />
          : <ResetRequest
            emailErrorText={emailErrorText}
            formChange={handleFormChange}
            formSubmit={handleFormSubmit}
            requestedResetStatus={requestedReset.status} />
        }
        <div styleName="auth-section">
          <div styleName="link-text">
            Already have an account?<br /><Link style={loginStyles.links} to={'/login'}>Login</Link>
            <br/>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div styleName="not-authenticated-container authentication-success-container">
      <Success
        isOnboarding={isOnboarding} />
      <div styleName="auth-section">
        <div>
          <Link style={loginStyles.successLinks} to={'/login'}>Login Now</Link>
        </div>
      </div>
    </div>
  );
};

export default memo(PasswordRecovery);
