/* eslint-disable react/jsx-no-bind */
import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { push } from 'connected-react-router';
import { capitalize } from 'lodash';
import {
  getConfirmableRemoveEntityFromGroupWithMessage,
  openGroupAddEntityDialog
} from '@actions/groups-actions';
import { showConfirmationDialog } from '@actions/confirmation-actions';
import { dotmapsGray } from '@constants/colors';
import { getEntityTypeLabel } from '@constants/config';
import Divider from '@material-ui/core/Divider';
import { Icon } from '@mui';
import IconMenu from '@shared/icon-menu';
import IconMenuItem from '@shared/icon-menu-item';
import { canEditEntities, canEditGroups } from '@utils/permission-utils';

const MoreButton = ({
  dataType,
  entity,
  groupId,
  groupType,
  source
}) => {
  const dispatch = useDispatch();

  const goToEntityInfo = () => {
    dispatch(push({ pathname: `/${dataType}/${entity.id}`, state: { source } }));
  };

  const goToActivityLog = () => {
    dispatch(push({ pathname: `/logs/map/entity/${entity.id}`, state: { source } }));
  };

  const removeFromGroup = () => {
    const entityTypeName = capitalize(dataType);
    dispatch(showConfirmationDialog(
      `Remove ${entityTypeName} From Group`,
      `Are you sure you want to remove ${dataType} ${entity.id} from the current group?`,
      getConfirmableRemoveEntityFromGroupWithMessage(
        entity.id, groupId, `${entityTypeName} ${entity.id} removed from the group`
      )
    ));
  };

  const addToGroup = () => dispatch(openGroupAddEntityDialog(dataType, entity));

  const allowAddToGroup = () => canEditGroups(groupType);

  const allowRemoveFromGroup = () => canEditGroups(groupType) && groupId;

  return (
    <IconMenu
      icon={<Icon color={dotmapsGray}>more_vert</Icon>}
      iconButtonStyles={{ padding: 0, margin: 0 }}
      MenuProps={{
        anchorOrigin: { horizontal: 'left', vertical: 'top' },
        transformOrigin: { horizontal: 'left', vertical: 'top' }
      }}
      tooltip="more actions"
    >
      {canEditEntities(entity.agency, dataType) &&
        <IconMenuItem onClick={goToEntityInfo}>
          Edit {getEntityTypeLabel(dataType)}
        </IconMenuItem>
      }
      <IconMenuItem onClick={goToActivityLog}>
        View activity log
      </IconMenuItem>
      {allowAddToGroup() &&
        <IconMenuItem onClick={addToGroup}>
          Add to group
        </IconMenuItem>
      }
      {allowAddToGroup() && allowRemoveFromGroup() && <Divider />}
      {allowRemoveFromGroup() &&
        <IconMenuItem onClick={removeFromGroup}>
          Remove from group
        </IconMenuItem>
      }
    </IconMenu>
  );
};

MoreButton.propTypes = {
  dataType: PropTypes.string,
  entity: PropTypes.object,
  groupId: PropTypes.number,
  groupType: PropTypes.string,
  source: PropTypes.string
};

export default memo(MoreButton);
