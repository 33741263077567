/* eslint-disable max-depth */
import { getListItemConfig } from '@constants/config';

const getLayoutConfig = dataType => {
  if (dataType) {
    return getListItemConfig()[dataType];
  }
  return null;
};

export const getConfigLabel = (dataType, key) => {
  const config = getLayoutConfig(dataType);
  if (config && config[key]) {
    const entry = config[key];
    return entry.label;
  }
  return null;
};

export const getConfigValue = (dataType, key, props) => {
  const config = getLayoutConfig(dataType);
  if (config && config[key]) {
    const entry = config[key];
    if (entry.type === 'agency') {
      return props.agency_name;
    } else
      if (entry.type === 'category') {
        const { category_dict } = props;
        if (category_dict) {
          const categoryItem = category_dict.find(category => category.name === entry.source);
          if (categoryItem) {
            return categoryItem.value;
          }
        }
      } else
        if (entry.type === 'field') {
          return props[entry.source];
        }
  }
  return null;
};
