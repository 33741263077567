import React, { memo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import DragIndicatorIcon from '@material-ui/icons/DragIndicator';
import { Draggable } from 'react-beautiful-dnd';

import DmListItemContents from './dm-list-item-contents';

import styles from './dm-list-item.scss';

const DmListItem = ({
  afterList = null,
  beforeList = null,
  border = false,
  children,
  className,
  draggableProps = null,
  onClick = null,
  onMouseEnter = null,
  onMouseLeave = null,
  secondaryText = null,
  ...passThroughProps
}) => {

  if (draggableProps) {
    return (
      <Draggable
        {...draggableProps}
      >
        {(provided, snapshot) => (
          <li
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
            className={classNames(
              styles.listItemWrap,
              styles.draggable,
              {[styles.dragging]: snapshot}
            )}
            ref={provided.innerRef}
            {...provided.draggableProps}
          >
            <div
              className={styles.dragHandle}
              {...provided.dragHandleProps}
            >
              <DragIndicatorIcon />
            </div>
            <DmListItemContents
              afterList = {afterList}
              beforeList = {beforeList}
              border = {border}
              children = {children}
              className = {className}
              onClick = {onClick}
              secondaryText = {secondaryText}
              {...passThroughProps}
            />
          </li>
        )}
      </Draggable>
    );
  }

  return (
    <li
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      className={styles.listItemWrap}
    >
      <DmListItemContents
        afterList = {afterList}
        beforeList = {beforeList}
        border = {border}
        children = {children}
        className = {className}
        onClick = {onClick}
        secondaryText = {secondaryText}
        {...passThroughProps}
      />
    </li>
  );
};

DmListItem.propTypes = {
  afterList: PropTypes.node,
  beforeList: PropTypes.node,
  border: PropTypes.bool,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  draggableProps: PropTypes.object,
  onClick: PropTypes.func,
  onMouseEnter: PropTypes.func,
  onMouseLeave: PropTypes.func,
  secondaryText: PropTypes.string
};

export default memo(DmListItem);
