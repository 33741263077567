/* eslint-disable jsx-a11y/no-autofocus */
import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { scrollToField, updateDataField } from '@actions/data-detail-actions';
import * as colors from '@constants/colors';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import InfoIcon from '@material-ui/icons/Info';
import InsertLinkIcon from '@material-ui/icons/InsertLink';
import PlaceIcon from '@material-ui/icons/Place';
import { Button } from '@mui';
import FileUpload from '@shared/files/file-upload';
import './add-task-header.scss';

const useStyles = makeStyles({
  input: {
    fontSize: '1.375rem !important',
    lineHeight: '1.375'
  }
});

const AddTaskHeader = ({ taskId, taskType }) => {
  const dispatch = useDispatch();
  const { data: task, error } = useSelector(state => state.dataDetail);
  const { cycle } = useSelector(state => state.workflow);
  const classes = useStyles();

  const onAddLocations = useCallback(
    () => {
      dispatch(updateDataField('segments', cycle.entity.segments));
      dispatch(scrollToField('locations-section'));
    },
    [cycle, dispatch]
  );

  const onLinkEntity = useCallback(
    () => {
      dispatch(scrollToField('linked-entities-section'));
      dispatch(updateDataField('_showLinkedEntitiesForm', true));
    },
    [dispatch]
  );

  const onAttach = useCallback(
    () => dispatch(scrollToField('attachments-section')),
    [dispatch]
  );

  const onChange = useCallback(
    event => dispatch(updateDataField('name', event.target.value)),
    [dispatch]
  );

  if (!cycle) {
    return null;
  }

  const errors = error ? error.name : null;
  const subtextStyle = errors ? { paddingTop: '1rem' } : {};

  const buttonStyle = {
    border: `1px solid ${colors.dotmapsGrayBorder}`,
    marginRight: '1rem',
    padding: '0 2rem'
  };

  return (
    <div styleName="header">
      <div styleName="header-title">
        <div styleName="header-title-textfield">
          <TextField
            autoFocus
            fullWidth
            error={errors?.length > 0}
            helperText={Array.isArray(errors) ? errors.join(', ') : null}
            InputLabelProps={{ shrink: true }}
            InputProps={{
              classes: {
                input: classes.input
              }
            }}
            label="Task Name"
            placeholder="Untitled Task"
            onChange={onChange}
            value={task?.name}
          />
        </div>
        <div styleName="header-title-subtext" style={subtextStyle}>
          {taskType.name}
        </div>
      </div>
      <div styleName="header-links">
        {cycle.entity && (
          <Button onClick={onAddLocations} startIcon={<PlaceIcon />} style={buttonStyle}>
            ADD LOCATIONS
          </Button>
        )}
        {cycle.group && (
          <Button onClick={onLinkEntity} startIcon={<InsertLinkIcon />} style={buttonStyle}>
            LINK ENTITY
          </Button>
        )}
        <FileUpload contentType="task" objectId={taskId}>
          <Button onClick={onAttach} startIcon={<AttachFileIcon />} style={buttonStyle}>
            ATTACH
          </Button>
        </FileUpload>
      </div>
      {!taskType.editing && (
        <div styleName="header-info">
          <div styleName="header-info-icon">
            <InfoIcon htmlColor={colors.dotmapsBlack40} />
          </div>
          <div styleName="header-info-text">
            This task cannot be edited after being assigned
          </div>
        </div>
      )}
    </div>
  );
};

AddTaskHeader.propTypes = {
  taskId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  taskType: PropTypes.object
};

export default AddTaskHeader;
