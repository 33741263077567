import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import { getEntityTypeLabel } from '@constants/config';
import Tooltip from '@material-ui/core/Tooltip';
import { calculateMetrics } from '@utils/gantt-utils';
import '../drawer.scss';

const Row = ({ baseline, current, label }) => (
  <div styleName="metrics-row">
    <div styleName="metrics-column first">{label}</div>
    <div styleName="metrics-column bolder">{baseline}</div>
    <div styleName="metrics-column bolder">{current}</div>
  </div>
);

Row.propTypes = {
  baseline: PropTypes.any,  // eslint-disable-line react/forbid-prop-types
  current: PropTypes.any,  // eslint-disable-line react/forbid-prop-types
  label: PropTypes.any  // eslint-disable-line react/forbid-prop-types
};

const MetricsRows = () => {
  const { group } = useSelector(state => state.groups.edit);
  const { baseline, current, type } = group;

  const hasBaseline = !isEmpty(baseline);
  const baselineStyle = {
    borderBottom: '2px dotted blue',
    display: 'inline',  // Set to 'inline' so the dotted line is limited to the text only.
    position: 'relative'
  };
  const metrics = calculateMetrics(baseline, current, type);

  return (
    <div styleName="container">
      <div styleName="metrics-title-row">
        <div styleName="metrics-column first" />
        <div styleName="metrics-column bolder">
          {hasBaseline && <div>Baseline</div>}
          {!hasBaseline && (
            <Tooltip title="No baseline set" >
              <span style={baselineStyle}>Baseline</span>
            </Tooltip>
          )}
        </div>
        <div styleName="metrics-column bolder">Current</div>
      </div>
      <Row label="Duration" baseline={metrics.baseline.duration} current={metrics.current.duration} />
      <Row label="Start date" baseline={metrics.baseline.startDate} current={metrics.current.startDate} />
      <Row label="End date" baseline={metrics.baseline.endDate} current={metrics.current.endDate} />
      {metrics.entities.map(entity => (
        <Row
          key={entity}
          baseline={metrics.baseline[entity]}
          current={metrics.current[entity]}
          label={`Number of ${getEntityTypeLabel(entity)}s`}
        />
      ))}
    </div>
  );
};

export default MetricsRows;
