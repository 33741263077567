import React from 'react';

const OverlapLinkIcon = () => (
  <svg width="24px" height="24px" version="1.1" viewBox="0 0 24 24">
    <path
      clipRule="evenodd"
      fillRule="evenodd"
      d={`
          M15.4286 19.5C15.4286 17.015 17.3476 15
          19.7143 15C22.081 15 24 17.015 24 19.5C24
          21.985 22.081 24 19.7143 24C18.5286 24
          17.4619 23.495 16.6857 22.68L17.3619
          21.97C17.9619 22.605 18.7952 23 19.7143
          23C21.5571 23 23.0476 21.435 23.0476
          19.5C23.0476 17.565 21.5571 16 19.7143
          16C17.8714 16 16.381 17.565 16.381
          19.5H17.8095L15.8857 21.515L15.8524
          21.445L14 19.5H15.4286ZM19.2381
          20V17.5H19.9524V19.625L21.619
          20.665L21.2762 21.27L19.2381 20Z
         `}
      fill="white"
    />
    <path
      clipRule="evenodd"
      d={`
          M14.416 21C14.1484 20.3875 14 19.7111 14
          19C14 16.2561 16.2103 14.0284 18.9475 14.0003L12
          2L1 21H14.416ZM11 16V18H13V16H11ZM13 10V14H11V10H13Z
        `}
      fill="white"
      fillRule="evenodd"
    />
  </svg>
);

export default OverlapLinkIcon;
