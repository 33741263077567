/* eslint-disable react/jsx-no-bind */
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { push } from 'connected-react-router';
import { Link } from 'react-router-dom';
import { openDashboardDialog } from '@actions/dashboard-actions';
import { openGroupAddEntityDialog } from '@actions/groups-actions';
import { setSelectedRow } from '@actions/data-table-actions';
import * as dialog from '@constants/dialogs';
import { colors } from '@constants/colors';
import AddToGroupIcon from '@icons/add-to-group-icon';
import ShowOnMapIcon from '@icons/show-on-map-icon';
import WorkflowIcon from '@icons/workflow-icon';
import { MenuItem, Popover } from '@material-ui/core';
import { Icon, IconButton } from '@mui';
import { entityURLFieldBuilder } from '@utils/entity-utils';
import { renderCustomIconAndLabel } from '@utils/icon-utils';
import { verifyPermissions, canAccessEntity, isReadOnly } from '@utils/permission-utils';
import './actions-menu.scss';

const getIcon = iconName => {
  if (iconName === 'RestoreIcon') {
    return <Icon>restore</Icon>;
  }
  if (iconName === 'AddToGroupIcon') {
    return <AddToGroupIcon />;
  }
  if (iconName === 'WorkflowIcon') {
    return <WorkflowIcon />;
  }
  if (iconName === 'post_add') {
    return <Icon>post_add</Icon>;
  }
  if (iconName === 'CopyContentIcon') {
    return <Icon>content_copy</Icon>;
  }
  return null;
};

const iconStyle = { padding: '0 0.5rem', width: 'auto' };

const ActionsMenu = ({ actions, dataId, dataType, row }) => {
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const dispatch = useDispatch();

  const openMenu = event => {
    event.stopPropagation();
    setOpen(true);
    setAnchorEl(event.currentTarget);
    dispatch(setSelectedRow(dataId));
  };

  const close = event => {
    if (event) {
      event.stopPropagation();
    }
    setOpen(false);
    dispatch(setSelectedRow(null));
  };

  const openOnMap = event => {
    close(event);
    dispatch(push(`/map?${dataType}=${dataId}`));
  };

  const openAddEntityToGroupDialog = event => {
    close(event);
    dispatch(openGroupAddEntityDialog(dataType, row));
  };

  const openAddToCycleDialog = event => {
    close(event);
    const params = { entityId: row.id, reloadEntity: true };
    dispatch(openDashboardDialog(dialog.START_CYCLE, params));
  };

  const createDuplicate = event => {
    close(event);
    dispatch(push(`/${dataType}/${dataId}/duplicate`));
  };

  return (
    <div styleName="actions-menu-container">
      <div styleName="actions-menu-icon">
        <IconButton
          onClick={openOnMap}
          tooltip="Open on map"
          style={iconStyle}
        >
          <ShowOnMapIcon index={dataId} color={colors.opacity.black0_6} />
        </IconButton>
      </div>
      <div styleName="actions-menu-icon">
        <IconButton
          onClick={openMenu}
          style={iconStyle}
        >
          <Icon color={colors.opacity.black0_6}>more_vert</Icon>
        </IconButton>
      </div>
      <Popover
        {...{
          open,
          anchorEl,
          onClose: close,
          anchorOrigin: { horizontal: 'right', vertical: 'bottom' },
          targetOrigin: { horizontal: 'right', vertical: 'top' }
        }}
      >
        {actions && actions.map(({
          allowedEntities,
          dialog: dialogName,
          icon,
          label,
          link,
          permissions,
          url,
          url_fields
        }) => {

          const editable = canAccessEntity(null, dataType, 'add') && !isReadOnly(dataType);

          if ((allowedEntities && !allowedEntities.includes(dataType)) || !verifyPermissions(permissions) || (label === 'Duplicate' && !editable)) {
            return null;
          }

          let onClick = null;
          let text = <div styleName="actions-menu-item">{renderCustomIconAndLabel(label, getIcon(icon))}</div>;

          if (label === 'Duplicate') {
            onClick = createDuplicate;
          } else
            if (dialogName) {
              if (dialogName === 'add-to-group') {
                onClick = openAddEntityToGroupDialog;
              } else
                if (dialogName === 'add-to-cycle') {
                  onClick = openAddToCycleDialog;
                }
            } else
              if (url) {
                let newUrl = url;
                if (url_fields) {
                  newUrl = entityURLFieldBuilder(url, row, url_fields);
                }
                onClick = () => window.open(newUrl, '_blank');
              } else
                if (link) {
                  onClick = event => event.stopPropagation();
                  text = (
                    <div styleName="actions-menu-item-link">
                      <Link to={`${link}${dataId}`}>{text}</Link>
                    </div>
                  );
                }
          return (
            <MenuItem key={label} onClick={onClick}>
              {text}
            </MenuItem>
          );
        })}
      </Popover>
    </div>
  );
};

ActionsMenu.propTypes = {
  actions: PropTypes.array,
  dataId: PropTypes.number,
  dataType: PropTypes.string,
  row: PropTypes.object
};

export default ActionsMenu;
