/* eslint-disable max-depth */
import * as R from 'ramda';
import { isAdminDataType } from '@constants/endpoints';
import { canCreateTasks, canViewWorkflows } from '@utils/permission-utils';
import { findActivity, isAgency, isAssignee, isOwner, isOwnerAgency } from '@utils/workflow-utils';

const getDefaultPortalPath = dataType => {
  if (dataType === 'cycle') {
    return '/workflow/cycle';
  }
  if (isAdminDataType(dataType)) {
    return `/admin/data/${dataType}`;
  }
  return `/library/entity/${dataType}`;
};

// Build the default listing page URL for the workflow back arrow button.
//
// The location.state property should already let us go back from where we came from, but if for some
// reason that information is missing, this method tries to guess which listing page URL would be best
// to return to.
export const getDefaultWorkflowBackURL = (task, activityId) => {
  if (task && canCreateTasks()) {
    // Task owners should go back to the 'Owned by me' page.
    if (isOwner(task)) {
      return '/workflow/task/owner';
    }
    // Else if the agency match go to 'Owned by my agency'.
    if (isOwnerAgency(task)) {
      return '/workflow/task/agency';
    }
  }

  if (task?.activities?.length > 0) {
    let activity = null;
    if (activityId) {
      activity = findActivity(activityId, task.activities);
    } else {
      activity = task.activities[0];
    }
    if (activity) {
      // We are on an activity page that is assigned to me:
      if (isAssignee(activity)) {
        return '/workflow/activity/assignee';
      }
      // Else check if the activity is assigned to my agency:
      if (isAgency(activity)) {
        return '/workflow/activity/agency';
      }
    }
  }

  // If we can't guess go to the workflow listing page (if we have permissions).
  if (canViewWorkflows()) {
    return '/workflow/cycle';
  }

  // Return to the map if all fails.
  return '/map';
};

// Returns an URL to return (that can be used on a nav-bar back button
// or as an action, i.e. after deleting an entity).
//
// We should return to the page from which we reached the current location,
// and if such data is no available (because the URL was pasted manually,
// was clicked from an email link, or opened in a new window), default
// it to the library listing page for the specified data type).
export const getDashboardBackURL = (location, dataType, custom = null) => {
  const source = R.path(['state', 'source'], location);
  const defaultPortalPath = getDefaultPortalPath(dataType);
  return source || custom || defaultPortalPath;
};

// Returns the URL for the overlap activity log page, based on the specified
// location, which is a location of an entity activity log page.
export const getOverlapActivityLogURL = location => {
  const pathname = R.propOr(null, ['pathname'], location);
  if (pathname) {
    const idSlice = R.slice(4, 5);
    const id = parseInt(R.pipe(R.split('/'), idSlice)(pathname), 10);
    return `/logs/overlap/conflict/${id}`;
  }
  return null;
};

const getDataId = pathname => {
  const idSlice = R.slice(2, 3);
  return parseInt(R.pipe(R.split('/'), idSlice)(pathname), 10);
};

const getDataType = pathname => {
  const dataTypeSlice = R.slice(1, 2);
  return R.pipe(R.split('/'), dataTypeSlice, R.join('-'))(pathname);
};

// Location changes should clear dashboard details pages, so requesting
// the /<entity-name>/1234 page after visiting /<entity-name>/1111 doesn't display's
// 1111's data until 1234 data is loaded, else while we wait for the fetch request
// to finish, we'll be displaying old data, which after fetch success will change
// to the new data.
//
// So, it's better to clear the data, so the user only see a spinning image until the new data loads.
//
// However, if we are navigating within the same entity type, i.e. by selecting
// permits or opening them into the tray, like /conflict/1234?focus=2222&type=permit&open=2222
// we shouldn't clear the redux store data, since we are still displaying the same data.
export const shouldClearData = (state, pathname) => {
  const dataId = R.pathOr(null, ['data', 'id'], state);
  const dataType = R.pathOr(null, ['dataType'], state);

  if (!dataId && !dataType) {
    return false;  // No data was set, thus no need to clear.
  }

  // Get the id and dataType from the new URL.
  // (i.e. from '/project/1234', get '1234' and 'project').
  const urlId = getDataId(pathname);
  const urlDataType = getDataType(pathname);

  // If the URL is a cycle one (i.e. '/cycle/1234'), it would be just '/cycle/1234',
  // or it will be a task URL '/cycle/1234/task/5678' or an activity one
  // '/cycle/1234/task/5678/activity/{type}/910'.
  //
  // Thus it will pass the above test, but we must actually check if the task
  // id is different.
  if (urlDataType === 'cycle') {
    const newPath = pathname.replace(`/cycle/${urlId}`, '');
    return shouldClearData(state, newPath);
  }

  return dataType !== urlDataType || dataId !== urlId;
};

// Simulate CTRL-CLICK behavior on links (open link in a new tab).
// (works on Chrome and Firefox).
export const openInNewTab = url => {
  const win = window.open(url);
  win.focus();
};
