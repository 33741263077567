/* eslint-disable react/jsx-no-bind */
import React, { useEffect, useMemo, useState } from 'react';
import * as R from 'ramda';
import { v4 as uuid } from 'uuid';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { Progress, Snackbar } from '@mui';
import { colors } from '@constants/colors';
import { updateAtId } from '@utils/state-utils';

import {
  fetchDashboard,
  updateDashboard
} from './actions';
import {
  CARD_STATE_LOADING,
  CARD_STATE_READY,
  CARD_SIZE_LG,
  CARD_SIZE_SM,
  CARD_TYPE_EMBED
} from './constants';
import CustomizeDashboardDialog from './dialogs/customize-dashboard-dialog';
import DashboardContext from './dashboard-context';
import DashboardEmptyState from './dashboard-empty-state';
import DashboardGrid from './dashboard-grid';
import { CARD_CONFIG } from './settings';
import DashboardTitleBar from './dashboard-title-bar';
import {
  getColumn,
  getColumnIndex,
  getColumnsData,
  initCards
} from './utils';

import './dashboard.scss';

const DashboardPortal = () => {
  const name = useSelector(state => state.config?.user?.name);
  const { portalType } = useParams();
  const [allCards, setAllCards] = useState([]);
  const [cardsRaw, setCardsRaw] = useState([]);
  const [loadingInitData, setLoadingInitData] = useState(true);
  const [loading, setLoading] = useState(false);
  const [updatingLayout, setUpdatingLayout] = useState(false);
  const [showCustomize, setShowCustomize] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    const onDashboardFetchComplete = (cards, columns) => {
      if (!R.isEmpty(cards)) {
        setCardsRaw(cards.data);
      }
      setAllCards(
        initCards(
          R.isEmpty(cards) ? [] : cards.data,
          R.isEmpty(columns) ? [] : columns.data
        )
      );
      setLoadingInitData(false);
    };
    dispatch(fetchDashboard(portalType, onDashboardFetchComplete));
  }, []);  // eslint-disable-line react-hooks/exhaustive-deps

  const updateCards = cards => {
    setUpdatingLayout(true);
    const onUpdateDashboardComplete = () => {
      setUpdatingLayout(false);
    };
    dispatch(updateDashboard(`${portalType}-cards`, cards, onUpdateDashboardComplete));
  };

  const updateLayout = columns => {
    setUpdatingLayout(true);
    const onUpdateDashboardComplete = () => {
      setUpdatingLayout(false);
    };
    dispatch(updateDashboard(`${portalType}-columns`, columns, onUpdateDashboardComplete));
  };

  const updateCardState = (card, state) => {
    const cardUpdatedIdx = allCards.indexOf(card);
    setAllCards(prevAllCards =>
      R.update(cardUpdatedIdx, { ...card, contentState: state }, prevAllCards)
    );
  };

  const updateAllCardsState = state => {
    // Good example of when prevState is needed in useState hook
    //  - setting contentState by mapping over allCards
    //    carries over stale values and causes LOADING to persist on cards
    setAllCards(prevAllCards =>
      prevAllCards.map(card => ({
        ...card,
        contentState: state
      }))
    );
  };

  const enabledCards = allCards.filter(card => card.order !== -1);

  const lgColumn = useMemo(
    () => getColumn(CARD_SIZE_LG, enabledCards),
    [enabledCards]
  );

  const smColumn = useMemo(
    () => getColumn(CARD_SIZE_SM, enabledCards),
    [enabledCards]
  );

  const someCardIsLoading = useMemo(
    () => enabledCards.some(card => card.contentState === CARD_STATE_LOADING),
    [enabledCards]
  );

  const emptyBoard = R.isEmpty(enabledCards);

  const displayError = () => {
    setLoading(false);
    Snackbar.error({
      message:
        'Something went wrong. Please try again or contact an administrator.'
    });
  };

  const handleAddEmbedCard = async card => {
    setLoading(true);
    const targetColumnIdx = getColumnIndex(card.size);
    const columns = getColumnsData(lgColumn, smColumn);
    const order = columns[targetColumnIdx].length;

    try {
      const newCard = {
        id: uuid(),
        ...card,
        type: CARD_TYPE_EMBED
      };
      const newCards = [...cardsRaw, newCard];
      setCardsRaw(newCards);
      updateCards(newCards);
      columns[targetColumnIdx] = [...columns[targetColumnIdx], newCard.id];
      updateLayout(columns);
      setShowCustomize(false);
      setAllCards(prevAllCards => [
        ...prevAllCards,
        {
          ...newCard,
          order,
          contentState: CARD_STATE_LOADING,
          ...CARD_CONFIG[CARD_TYPE_EMBED]
        }
      ]);
      setLoading(false);
    } catch (error) {
      displayError();
    }
  };

  const handleDeleteEmbedCard = async card => {
    setLoading(true);
    const targetColumnIdx = getColumnIndex(card.size);
    const columns = getColumnsData(lgColumn, smColumn);

    try {
      const newCards = [...cardsRaw.filter(rawCard => rawCard.id !== card.id)];
      setCardsRaw(newCards);
      updateCards(newCards);
      columns[targetColumnIdx] = R.without([card.id], columns[targetColumnIdx]);
      updateLayout(columns);
      setAllCards(prevAllCards => R.without([card], prevAllCards));
      setLoading(false);
    } catch (error) {
      displayError();
    }
  };

  const handleEditEmbedCard = async newCardFields => {
    setLoading(true);
    const cardId = newCardFields.id;
    const cardToUpdate = allCards.find(card => card.id === cardId);
    const columnSwap = cardToUpdate.size !== newCardFields.size;
    const columns = getColumnsData(lgColumn, smColumn);
    const nameChangeOnly = cardToUpdate.size === newCardFields.size &&
      cardToUpdate.embedLink === newCardFields.embedLink &&
      cardToUpdate.title !== newCardFields.title;
    let newOrder = cardToUpdate.order;

    try {
      const newCards = cardsRaw.map(rawCard => {
        if (rawCard.id === cardId) {
          return {
            id: cardId,
            title: newCardFields.title,
            type: newCardFields.type,
            size: newCardFields.size,
            embedLink: newCardFields.embedLink
          };
        }
        return rawCard;
      });
      setCardsRaw(newCards);
      updateCards(newCards);

      if (columnSwap) {
        const targetColumnIdx = getColumnIndex(newCardFields.size);
        const sourceColumnIdx = getColumnIndex(cardToUpdate.size);

        columns[targetColumnIdx] = [...columns[targetColumnIdx], cardId];
        columns[sourceColumnIdx] = R.without(
          [cardId],
          columns[targetColumnIdx]
        );
        newOrder = columns[targetColumnIdx].length;
        updateLayout(columns);
      }

      const updatedCards = updateAtId(
        cardId,
        R.evolve({
          title: () => newCardFields.title,
          embedLink: () => newCardFields.embedLink,
          size: () => newCardFields.size,
          contentState: () =>
            nameChangeOnly
              ? CARD_STATE_READY
              : CARD_STATE_LOADING,
          order: () => newOrder
        })
      )(allCards);
      setAllCards(updatedCards);
      setLoading(false);
    } catch (error) {
      displayError();
    }
  };

  const loadingEmbedCard = loading;

  const contextValue = {
    emptyBoard,
    allCards,
    setAllCards,
    updateCardState,
    updateAllCardsState,
    setShowCustomize,
    lgColumn,
    smColumn,
    updateLayout,
    updatingLayout,
    someCardIsLoading,
    handleAddEmbedCard,
    handleDeleteEmbedCard,
    handleEditEmbedCard,
    loadingEmbedCard
  };

  // eslint-disable-next-line no-nested-ternary
  const dashboardBody = loadingInitData ? (
    <Progress style={{ height: '80vh' }} />
  ) : emptyBoard ? (
    <DashboardEmptyState />
  ) : (
    <DashboardGrid />
  );

  return (
    <DashboardContext.Provider value={contextValue}>
      {loadingEmbedCard && (
        <Progress
          style={{
            height: '100vh',
            width: '100%',
            position: 'fixed',
            top: 0,
            left: 0,
            opacity: 0.5,
            background: colors.neutral.ash,
            zIndex: 1000
          }}
        />
      )}
      <div styleName="portal-container">
        <DashboardTitleBar />
        <div styleName="dashboard-hello">Hello, {name}</div>
        {dashboardBody}
      </div>
      <CustomizeDashboardDialog
        open={showCustomize}
        onClose={() => setShowCustomize(false)}
      />
    </DashboardContext.Provider>
  );
};

export default DashboardPortal;
