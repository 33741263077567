import React, { memo } from 'react';
import PropTypes from 'prop-types';

import { Select } from '@mui';

import './batch-upload-dialog.scss';

const BatchUploadSelectType = ({ menuItems, onChange, renderer, title, value }) => (
  <div styleName="section">
    <div styleName="section-header">
      {title}
    </div>
    <div styleName="section-select">
      <Select displayEmpty onChange={onChange} renderValue={renderer} value={value}>
        {menuItems}
      </Select>
    </div>
  </div>
);

BatchUploadSelectType.propTypes = {
  menuItems: PropTypes.array,
  onChange: PropTypes.func,
  renderer: PropTypes.func,
  title: PropTypes.string,
  value: PropTypes.object
};

export default memo(BatchUploadSelectType);
