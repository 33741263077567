/* eslint-disable react/jsx-no-bind */
/* eslint-disable jsx-a11y/iframe-has-title */
import React, { memo, useState } from 'react';
import PropTypes from 'prop-types';

import { colors } from '@constants/colors';

import CardLoading from '../card-states/card-loading';
import { getCardHeight } from '../utils';

import '../dashboard-card.scss';

const CardCustomEmbed = ({ card, embedLink }) => {
  const [loading, setLoading] = useState(true);
  const style = {
    height: getCardHeight(card),
    borderTop: `1px solid ${colors.neutral.ash}`
  };

  const iFrameHeight = loading ? '1%' : '100%';

  return (
    <div style={style} styleName="card-content">
      {loading && (
        <CardLoading card={card} style={{ height: getCardHeight(card) }} />
      )}
      <iframe
        width="100%"
        height={iFrameHeight}
        src={embedLink}
        style={{ border: 0, overflow: 'hidden' }}
        allowFullScreen
        onLoad={() => setLoading(false)}
      />
    </div>
  );
};

CardCustomEmbed.propTypes = {
  card: PropTypes.object,
  embedLink: PropTypes.string
};

export default memo(CardCustomEmbed);
