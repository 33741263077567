import React, { useContext, useEffect, useMemo, memo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { capitalize } from 'lodash';
import { toggleMarkerModal } from '@actions/map-actions';
import OverlapContext from '@components/overlap/overlap-context';
import * as colors from '@constants/colors';
import LegendIcon from '@icons/map-legend/legend-icon';
import OverlappingIcon from '@icons/map-legend/overlapping-icon';
import { isLeadEntityVisible } from '@utils/overlap-utils';
import '../map/legend/legend.scss';

const MapLegend = () => {
  const dispatch = useDispatch();
  const { legendOpen } = useSelector(state => state.map);
  const { markerModalOpen } = useSelector(state => state.map.markers);
  const {
    filteredEntities,
    getOpenConflicts,
    getOpportunities,
    getPendingConflicts,
    getResolvedConflicts,
    isSelected,
    mainEntity
  } = useContext(OverlapContext);

  useEffect(() => {
    if (legendOpen && markerModalOpen) {
      dispatch(toggleMarkerModal());
    }
  }, [dispatch, legendOpen, markerModalOpen]);

  const leadLabel = useMemo(() => {
    if (mainEntity && mainEntity.entity_type) {
      return `${capitalize(mainEntity.entity_type)} ${mainEntity.id}`;
    }
    return null;
  }, [mainEntity]);

  // Return only the entities shown on the current map boundaries:
  const boundsEntities = useMemo(() => {
    if (window.mapInstance) {
      if (filteredEntities?.length > 0) {
        const mapBounds = window.mapInstance.getBounds();
        if (mapBounds) {
          return filteredEntities.filter(({ bounds }) => mapBounds.intersects(bounds));
        }
      }
    }
    return null;
  }, [filteredEntities]);

  // Build on/off attributes to display overlap types on the map's legend dynamically.
  const overlapTypes = useMemo(() => {
    if (boundsEntities && mainEntity) {
      const isLeadVisible = isLeadEntityVisible(mainEntity, boundsEntities).length > 0;
      return {
        lead: isLeadVisible,
        open: getOpenConflicts(boundsEntities).length > 0,
        opportunity: getOpportunities(boundsEntities).length > 0,
        pending: getPendingConflicts(boundsEntities).length > 0,
        resolved: getResolvedConflicts(boundsEntities).length > 0,
        // If the main entity is not the selected one, then other one is,
        // thus the overlapping geometry will be enabled.
        overlapping: isLeadVisible && !isSelected(mainEntity.id)
      };
    }
    return {};
  }, [boundsEntities, getOpenConflicts, getOpportunities, getPendingConflicts, getResolvedConflicts, isSelected, mainEntity]);

  const items = useMemo(() => {
    const list = [];
    if (overlapTypes.lead) {
      list.push({ icon: <LegendIcon color={colors.dotmapsOverlapLead} />, label: leadLabel });
    }
    if (overlapTypes.open) {
      list.push({ icon: <LegendIcon color={colors.dotmapsOverlapOpen} />, label: 'Open Conflict' });
    }
    if (overlapTypes.pending) {
      list.push({ icon: <LegendIcon color={colors.dotmapsOverlapPending} />, label: 'Pending Conflict' });
    }
    if (overlapTypes.resolved) {
      list.push({ icon: <LegendIcon color={colors.dotmapsOverlapResolved} />, label: 'Resolved Conflict' });
    }
    if (overlapTypes.opportunity) {
      list.push({ icon: <LegendIcon color={colors.dotmapsOverlapOpportunity} />, label: 'Opportunity' });
    }
    if (overlapTypes.overlapping) {
      list.push({ icon: <OverlappingIcon />, label: 'Overlapping Geometry' });
    }
    if (list.length === 0) {
      return (
        <div styleName="map-legend-list-group">
          <div styleName="map-legend-empty">
            No records shown
          </div>
        </div>
      );
    }
    return (
      <div styleName="map-legend-list-group">
        {list.map(item => (
          <div styleName="map-legend-list-item" key={item.label}>
            <div styleName="icon">
              {item.icon}
            </div>
            <div styleName="text">
              {item.label}
            </div>
          </div>
        ))}
      </div>
    );
  }, [leadLabel, overlapTypes]);

  if (!legendOpen) {
    return null;
  }

  return (
    <div styleName="map-legend-container">
      <div styleName="map-legend-list">
        {items}
      </div>
    </div>
  );
};

export default memo(MapLegend);
