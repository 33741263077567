import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import './drawing-tools.scss';

const ShapeTool = ({ entities, closeWindow }) => (
  <div styleName="tool-container">
    <div styleName="title">Map Selection</div>
    <div styleName="close-tools">
      <IconButton onClick={closeWindow} style={{ padding: 0 }}>
        <CloseIcon />
      </IconButton>
    </div>
    {!entities && (
      <div styleName="text">
        Draw an area on map to load all the filtered records that fall within that area.
      </div>
    )}
    {entities && (
      <div styleName="text">
        Drag and move the corners to adjust the selection.
      </div>
    )}
    {entities && entities.map(entity => (
      <div key={entity.label} styleName="entity">
        {entity.label}: {entity.value}
      </div>
    ))}
    {entities && (
      <div styleName="action">
        <Button color="primary" key="clear-selection" onClick={closeWindow}>CLEAR SELECTION</Button>
      </div>
    )}
  </div>
);

ShapeTool.propTypes = {
  closeWindow: PropTypes.func,
  entities: PropTypes.array
};

export default ShapeTool;
