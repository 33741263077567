import React from 'react';
import SvgIcon from 'material-ui/SvgIcon';

const ShowOnMapIcon = props => (
  <SvgIcon {...props}>
    <path
      d={`
          M9.38 12.552a32.658 32.658 0 0 0 2.398 3.1v4.451l-4.534-1.96 1.76-5.713.376.122zm5.243-.004l.364-.118
          1.76 5.712-4.534 1.961v-4.441a32.647 32.647 0 0 0 2.41-3.114zm-9.214.874l3.15-1.022-1.796 5.832-4.457
          1.927a.222.222 0 0 1-.246-.047.208.208 0 0 1-.037-.241l3.26-6.343a.219.219 0 0 1 .126-.106zm16.535
          6.403a.206.206 0 0 1 .056.14c0 .12-.101.21-.221.213h-.005a.223.223 0 0 1-.088-.019l-4.459-1.928-1.795-5.831
          3.153 1.022c.055.018.1.057.126.106l3.233 6.297zM12 3.8c2.211 0 4 1.721 4 3.85 0 2.887-4 7.15-4 7.15s-4-4.263-4-7.15C8
          5.521 9.789 3.8 12 3.8zm0 5.225c.789 0 1.429-.616 1.429-1.375S12.789 6.275 12 6.275s-1.429.616-1.429 1.375.64
          1.375 1.429 1.375z
         `}
    />
  </SvgIcon>
);

export default ShowOnMapIcon;
