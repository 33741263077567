/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { memo } from 'react';
import PropTypes from 'prop-types';

import DropzoneSingle from '@shared/files/dropzone-single';

import './batch-upload-dialog.scss';

const BatchUploadDropzone = ({ accept, caption, file, onFileChange, onFileReset, openDrawer, rowCount, status, value }) => (
  <div styleName="section">
    <div styleName="section-header" style={{ padding: '1rem 0 0 0'}}>
      Choose a file:
    </div>
    {value &&
      <div styleName="section-subheader">
        Before you upload your file, make sure your file is <span onClick={openDrawer} styleName="section-link">ready to be uploaded</span>.
      </div>
    }
    <div styleName="section-row">
      <DropzoneSingle
        accept={accept}
        caption={caption}
        file={file}
        onFileChange={onFileChange}
        onFileReset={onFileReset}
        rowCount={rowCount}
        status={status}
      />
    </div>
  </div>
);

BatchUploadDropzone.propTypes = {
  accept: PropTypes.string,
  caption: PropTypes.string,
  file: PropTypes.object,
  onFileChange: PropTypes.func,
  onFileReset: PropTypes.func,
  openDrawer: PropTypes.func,
  rowCount: PropTypes.number,
  status: PropTypes.number,
  value: PropTypes.object
};

export default memo(BatchUploadDropzone);
