// Shows a pin on the map on search results.
import React from 'react';
import { useSelector } from 'react-redux';
import { Marker } from '@react-google-maps/api';

const SearchMarker = () => {
  const { viewport } = useSelector(state => state.map);
  if (viewport && viewport.defaultMarkerPosition) {
    return <Marker position={viewport.defaultMarkerPosition} />;
  }
  return null;
};

export default SearchMarker;
