/* eslint-disable react/jsx-no-bind */
import React, { Fragment, useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import { saveCustomField } from '@actions/workflow-actions';
import { getWorkflowCustomFieldTypesAsDataSource } from '@constants/config';
import { LIST_FIELDS } from '@constants/workflow';
import DataTypesSelect from '@forms/data-types-select';
import ArrowBackIcon from '@shared/arrow-back-icon';
import SwitchControl from '@shared/form/switch-control';
import { renderCustomFieldIconAndLabel } from '@utils/icon-utils';
import TaskTypeDrawerEditOptions from './task-type-drawer-edit-options';
import './details-layout-drawer.scss';

const TaskTypeDrawerEdit = ({ field, onCancel, order, sectionId }) => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const [name, setName] = useState(null);
  const [options, setOptions] = useState([]);
  const [type, setType] = useState(null);
  const [required, setRequired] = useState(false);
  const [errors, setErrors] = useState({});

  useEffect(() => {
    if (field) {
      setName(field.name);
      setOptions(field.options);
      setRequired(field.required);
      setType(field.type);
    }
  }, [field]);

  const isEdit = field && field.id;

  const fieldTypes = getWorkflowCustomFieldTypesAsDataSource();

  const onNameChange = useCallback(
    event => {
      setName(event.target.value);
      setErrors({});
    },
    [setErrors, setName]
  );

  const onTypeChange = useCallback(
    (event, index, value) => {
      setType(value);
      setErrors({});
    },
    [setErrors, setType]
  );

  const toggleRequired = () => setRequired(!required);
  const buildPayload = () => {
    if (isEdit) {
      // For editing existing fields, only the "name" and "required" properties
      // can be edited, and the options if the field type uses them
      // (but field type can't be changed).
      return {
        name,
        options,
        required,
        task_type: id  // Send this too to refetch task template on success.
      };
    }
    // Data for creating a new field:
    return {
      name,
      options,
      order: order + 1,
      required,
      section: sectionId,
      task_type: id,
      type
    };
  };

  const saveError = useCallback(
    error => setErrors({ error }),
    [setErrors]
  );

  const onSave = () => dispatch(saveCustomField(field && field.id, buildPayload(), onCancel, saveError));

  const saveEnabled = !isEmpty(name) && isEmpty(errors) && type !== null;
  const renderOptions = LIST_FIELDS.includes(type);
  const title = isEdit ? 'Edit field' : 'Add field';

  return (
    <Fragment>
      <div data-testid="task-type-drawer-edit-header" styleName="header row">
        <div styleName="row-left">
          <ArrowBackIcon onClick={onCancel} style={{ padding: '0 1rem 0 0' }} />
          {title}
        </div>
      </div>
      <div styleName="edit-form">
        <div styleName="edit-row">
          <TextField
            fullWidth
            InputLabelProps={{
              // Force shrinking (numeric/date fields have trouble in MUI v4).
              shrink: true
            }}
            label="Field title*"
            onChange={onNameChange}
            size="small"
            value={name}
          />
        </div>
        {errors.error && <div styleName="error-field">{errors.error}</div>}
        <div styleName="edit-row">
          <DataTypesSelect
            dataSource={fieldTypes}
            disabled={isEdit}
            customItemFormatter={item => renderCustomFieldIconAndLabel(fieldTypes, item)}
            floatingLabelText="Field type*"
            fullWidth
            value={type}
            onChange={onTypeChange}
          />
        </div>
        {renderOptions && <TaskTypeDrawerEditOptions options={options} setOptions={setOptions} />}
        <div styleName="edit-row">
          <SwitchControl label="This is a required field" onChange={toggleRequired} value={required} />
        </div>
      </div>
      <div styleName="edit-footer">
        <Button variant="contained" color="primary" onClick={onSave} disabled={!saveEnabled}>
          SAVE
        </Button>
        <div styleName="button-sep" />
        <Button onClick={onCancel}>
          CANCEL
        </Button>
      </div>
    </Fragment>
  );
};

TaskTypeDrawerEdit.propTypes = {
  field: PropTypes.object,
  onCancel: PropTypes.func,
  order: PropTypes.number,
  sectionId: PropTypes.number
};

export default TaskTypeDrawerEdit;
