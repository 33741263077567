export class PageEllipses {
  constructor(repr = '\u2026') {
    this.repr = repr;
  }

  str() {
    return this.repr;
  }
}

export const generatePageRange = (currentPage, lastPage, pageDelta = 1) => {
  const pageEllipses = new PageEllipses();
  const range = [];
  let delta = pageDelta;
  if (currentPage === 1 || currentPage === lastPage) {
    delta = 2;
  }

  for (let index = Math.max(2, (currentPage - delta));
    index <= Math.min((lastPage - 1), (currentPage + delta));
    index += 1) {
    range.push(index);
  }

  if ((currentPage - delta) > 2) {
    if (range.length === lastPage - 3) {
      range.unshift(2);
    } else {
      range.unshift(pageEllipses);
    }
  }

  if ((currentPage + delta) < (lastPage - 1)) {
    if (range.length === lastPage - 3) {
      range.push(lastPage - 1);
    } else {
      range.push(pageEllipses);
    }
  }

  range.unshift(1);

  if (lastPage !== 1) {
    range.push(lastPage);
  }

  return range;
};
