import React, { memo } from 'react';
import Header from './comments-header';
import Body from './comments-body';
import sectionStyles from '../section/section.scss';  // eslint-disable-line no-unused-vars, css-modules/no-unused-class
import activitiesStyles from './comments.scss';  // eslint-disable-line no-unused-vars, css-modules/no-unused-class

const Comments = () => (
  <div styleName="activitiesStyles.activities-comments">
    <div styleName="sectionStyles.section">
      <Header />
      <Body />
    </div>
  </div>
);

export default memo(Comments);
