import React, { memo, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { updateDataField } from '@actions/data-detail-actions';
import CustomEditField from '@components/workflow/section/custom-edit-field';

const EditField = ({ isPreview, field, position, sectionId }) => {
  const dispatch = useDispatch();
  const { data, error } = useSelector(state => state.dataDetail);

  const onChangeField = useCallback(
    (fieldName, value) => {
      if (!isPreview) {
        dispatch(updateDataField(fieldName, value));
      }
    },
    [dispatch, isPreview]
  );

  // Return the first part of the key to save the field's value on the Redux store.
  // We save the task form data inside 'field_values' at the position that
  // the field is displayed in the UI.
  const fieldPath = `${sectionId}.field_values.${position}`;

  // The path where to store the field id:
  const fieldIdPath = `${fieldPath}.field`;

  // The path where to store the field value:
  const fieldValuePath = `${fieldPath}.value`;

  const onChange = useCallback(
    (event, value) => {
      // Store the field id (it's empty if we are creating a new task):
      onChangeField(fieldIdPath, field.id);
      // And store the value we've just edited:
      onChangeField(fieldValuePath, value);
    },
    [field.id, fieldIdPath, fieldValuePath, onChangeField]
  );

  const errors = error?.[fieldIdPath] || null;

  return (
    <CustomEditField
      data={isPreview ? null : data}
      errors={errors}
      field={field}
      onChange={onChange}
      sectionId={sectionId}
    />
  );
};

EditField.propTypes = {
  field: PropTypes.object,
  isPreview: PropTypes.bool,
  position: PropTypes.number,
  sectionId: PropTypes.number
};

export default memo(EditField);
