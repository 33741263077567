export const PERMISSION_GROUPS = 'groups';
export const PERMISSION_TEAMS = 'teams';

export const PERMISSION_IS_APP_ADMIN = 'is_app_admin';
export const PERMISSION_IS_ADMIN_AGENCY = 'is_admin_agency';
export const PERMISSION_CAN_CREATE_TASKS_AGENCY = 'can_create_tasks_agency';
export const PERMISSION_IS_SUPERUSER = 'is_superuser';

export const PERMISSION_GROUP_APP_ADMIN = 'App Admin';
export const PERMISSION_GROUP_ADMIN = 'Admin';
export const PERMISSION_GROUP_ANALYST = 'Analyst';
export const PERMISSION_GROUP_MANAGER = 'Manager';

export const PERMISSION_AGENCY_ID = 'agency_id';
export const PERMISSION_USER_ATTRS = 'user_attrs';
export const PERMISSION_USER_ID = 'user_id';
