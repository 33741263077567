/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import DetailCard from '@shared/list-card/detail-card';
import './list-item.scss';

class BaseListItem extends Component {
  // Implement these in subclasses:
  getAgency = () => null;
  getDate = () => null;
  getFooterContent = () => {};
  getHeader = () => null;
  getType = () => {};
  getTypeIcon = () => null;
  getStatus = () => {};
  onMouseEnter = () => {};
  onMouseLeave = () => {};
  // Define to set additional CSS class names
  // to append to 'list-item'.
  getStyleNames = () => '';

  getName = () => <div styleName="name">{this.props.name}</div>;

  getActions = () => (
    <div styleName="actions">
      { this.props.moreButton }
    </div>
  );

  getContent = () => (
    <div styleName="content">
      {this.getName()}
      {this.getTitle()}
      {this.getType()}
      {this.getDate()}
    </div>
  );

  getAdditional = () => null;

  getFooter = () => (
    <div styleName="footer">
      {this.getFooterContent()}
    </div>
  );

  onClick = () => {
    const { dataType, id, segments, name, icon } = this.props;
    this.props.onClick(dataType, id, segments, name, icon);
  };

  render() {
    const { id } = this.props;
    if (!id) {
      return null;
    }
    return (
      <div
        key={id}
        id={`list-item-${id}`}
        onClick={this.onClick}
        onMouseEnter={this.onMouseEnter}
        onMouseLeave={this.onMouseLeave}
        role="presentation"
        styleName={`list-item ${this.getStyleNames()}`}
      >
        <DetailCard
          id={`list-item-${id}`}
          onClick={this.onClick}
          onSelect={this.onMouseEnter}
          onDeselect={this.onMouseLeave}
          entity={{...this.props}}
          moreButton={this.getActions()}
        />
        {this.getAdditional()}
        {this.getFooter()}
      </div>
    );
  }
}

BaseListItem.propTypes = {
  agency_name: PropTypes.string,
  category_dict: PropTypes.object,
  dataType: PropTypes.string,
  end_date: PropTypes.string,
  icon: PropTypes.string,
  id: PropTypes.number,
  moreButton: PropTypes.object,
  name: PropTypes.string,
  onClick: PropTypes.func,
  segments: PropTypes.object,
  start_date: PropTypes.string
};

export default BaseListItem;
