import React, { memo } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { includes } from 'lodash';
import {
  getCalendarEntity,
  getDefaultEntity,
  getEntitiesListConfig,
  isWorkflowEnabled
} from '@constants/config';
import { ENTITY_DATA_TYPE } from '@components/config/constants';
import { isSuperuser } from '@utils/permission-utils';
import { getFirstWorkflowMenuOption } from '@utils/workflow-utils';
import './header.scss';

const Links = () => {
  const location = useLocation();

  const isCalendarEnabled = () => {
    // Check if we have defined a calendar entity:
    const calendarEntity = getCalendarEntity();
    if (calendarEntity) {
      const entities = getEntitiesListConfig();
      // If the calendar entity exists in the list of entities, the calendar is enabled.
      return includes(entities, calendarEntity);
    }
    return false;
  };

  const linkButton = (label, url) => {
    const { pathname } = location;
    const baseUrl = url.split('/')[1];
    const selected = pathname.startsWith(`/${baseUrl}`);
    return (
      <div styleName={`link-button ${selected ? 'selected' : ''}`}>
        <Link to={url}>{label}</Link>
      </div>
    );
  };

  return (
    <div styleName="links-container">
      {linkButton('Map', '/map')}
      {linkButton('Library', `/library/${ENTITY_DATA_TYPE}/${getDefaultEntity()}`)}
      {isCalendarEnabled() && linkButton('Calendar', '/calendar')}
      {isWorkflowEnabled() && linkButton('Work', getFirstWorkflowMenuOption())}
      {linkButton('People', '/people/user')}
      {isSuperuser() && isWorkflowEnabled() && linkButton('Admin', '/admin')}
    </div>
  );
};

export default memo(Links);
