import { PUSH_APPLICATION_MESSAGE, POP_APPLICATION_MESSAGE } from '@constants/action-types';
import initialState from '../store/initial-state';

const messagesReducer = (state = initialState.messages, action) => {
  switch (action.type) {
  case PUSH_APPLICATION_MESSAGE: {
    return [action.message, ...state];
  }
  case POP_APPLICATION_MESSAGE: {
    const [, ...remaining] = state;
    return remaining;
  }
  default:
    return state;
  }
};

export default messagesReducer;
