import {
  SETTINGS_FETCH_SUCCESS
} from '@constants/action-types';
import initialState from '../store/initial-state';

const settingsReducer = (state = initialState.settings, action) => {
  switch (action.type) {
  case SETTINGS_FETCH_SUCCESS: {
    const { payload: { data } } = action;
    return {
      ...state,
      ...data
    };
  }
  default:
    return state;
  }
};

export default settingsReducer;
