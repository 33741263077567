/* eslint-disable react/jsx-no-bind */
import React, { Fragment, useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { openDashboardDialog } from '@actions/dashboard-actions';
import * as dialog from '@constants/dialogs';
import DeleteTaskTypeFieldDialog from '@shared/dialogs/delete-task-type-field-dialog';
import TaskTypeEdit from './task-type-drawer-edit';
import TaskTypeList from './task-type-drawer-list';

const PANELS = {
  LIST: 'list',
  EDIT: 'edit'
};

const TaskTypeDrawer = () => {
  const [editField, setEditField] = useState(null);
  const [order, setOrder] = useState(null);
  const [sectionId, setSectionId] = useState(null);
  const [content, setContent] = useState(PANELS.LIST);
  const dispatch = useDispatch();

  const onAdd = useCallback((id, fieldOrder) => {
    setContent(PANELS.EDIT);
    setEditField(null);
    setOrder(fieldOrder);
    setSectionId(id);
  }, [setContent, setEditField, setOrder, setSectionId]);

  const onEdit = useCallback(field => {
    setContent(PANELS.EDIT);
    setEditField(field);
  }, [setContent, setEditField]);

  const onCancel = useCallback(() => setContent(PANELS.LIST), [setContent]);

  const onDelete = field => dispatch(openDashboardDialog(dialog.DELETE_TASK_TYPE_FIELD, { ...field }));

  return (
    <Fragment>
      {content === PANELS.LIST && <TaskTypeList onAdd={onAdd} onDelete={onDelete} onEdit={onEdit} />}
      {content === PANELS.EDIT && <TaskTypeEdit field={editField} onCancel={onCancel} order={order} sectionId={sectionId} />}
      <DeleteTaskTypeFieldDialog />
    </Fragment>
  );
};

export default TaskTypeDrawer;
