import React, { memo } from 'react';
import { useSelector } from 'react-redux';
// Use Material UI's RefreshIndicator instead of our DotmapsLoader,
// since we need to customize its wrapper, and the style of the component,
// for correct centering.
import RefreshIndicator from 'material-ui/RefreshIndicator';
import { dotmapsBlue } from '@constants/colors';
import './actions-loading-box.scss';

const LoadingBox = () => {
  const { loading } = useSelector(state => state.dashboard.resolution);
  if (loading) {
    return (
      <div styleName="loading-box-container">
        <RefreshIndicator
          left={-50}
          loadingColor={dotmapsBlue}
          size={70}
          top={-50}
          status="loading"
          style={{
            top: 'auto',
            left: 'auto',
            transform: 'none'
          }}
        />
      </div>
    );
  }
  return null;
};

export default memo(LoadingBox);
