/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/display-name */
/* eslint-disable react/jsx-no-bind */
import React from 'react';
import PropTypes from 'prop-types';
import { fetchUserSettings, saveUserSettings } from '@actions/settings-actions';
import Button from '@material-ui/core/Button';
import { Icon } from '@mui';
import Form, { formMapDispatchToProps } from '@shared/form';
import CheckboxControl from '@shared/form/checkbox-control';
import './settings.scss';

class SettingsForm extends Form {
  UNSAFE_componentWillMount() {
    this.props.fetchUserSettings();
  }

  // Implement in subclass.
  getPayload = () => {};
  postSave = () => {};

  handleSave = () => {
    const payload = this.getPayload();
    this.props.saveUserSettings(payload);
    this.setState({ modified: false });
    this.postSave();
  };

  // Implement in subclass:
  onBackClick = () => {};

  renderBackLink = () => (
    <div
      onClick={() => this.onBackClick()}
      role="presentation"
      styleName="back-link"
    >
      <Icon>chevron_left</Icon>
    </div>
  );

  renderTitle = layout => (
    <div data-testid="settings-form-title" styleName="title">
      {layout.backLink && this.renderBackLink()}
      <div styleName="text">
        {layout.title}
      </div>
    </div>
  );

  renderHint = layout => (
    <div styleName="hint">
      {layout.hint}
    </div>
  );

  // Implement in subclass if needed:
  renderPreForm = () => null;

  renderForm = layout => {
    const { data } = this.state;
    return (
      <div styleName="form">
        {layout.columns.map(column => (
          <div styleName="row" key={column.field}>
            {column.type === 'checkbox' && (
              <CheckboxControl
                children={column.children && column.children.map(nested => (
                  <CheckboxControl
                    disabled={!data[column.field]}
                    key={column.field}
                    label={nested.label}
                    onChange={val => this.onChange(nested.field, val)}
                    value={data[nested.field]}
                  />
                ))}
                label={column.label}
                onChange={val => this.onChange(column.field, val)}
                value={data[column.field]}
              />
            )}
          </div>
        ))}
      </div>
    );
  };

  renderSaveButton = () => (
    <div styleName="button">
      <Button
        color="primary"
        disabled={!this.state.modified}
        onClick={this.handleSave}
        variant="contained"
      >
        SAVE
      </Button>
    </div>
  );

  render() {
    const { data } = this.state;
    if (!data) {
      return null;
    }
    const layout = this.getLayout();
    return (
      <div styleName="settings-form-container">
        {this.renderTitle(layout)}
        {this.renderHint(layout)}
        {this.renderPreForm()}
        {this.renderForm(layout)}
        {this.renderSaveButton()}
        {this.renderPrompt()}
      </div>
    );
  }
}

SettingsForm.propTypes = {
  fetchUserSettings: PropTypes.func,
  saveUserSettings: PropTypes.func
};

export const settingsFormMapDispatchToProps = {
  fetchUserSettings,
  saveUserSettings,
  ...formMapDispatchToProps
};

export default SettingsForm;
