/* eslint-disable jsx-a11y/mouse-events-have-key-events */
/* eslint-disable react/jsx-no-bind */
import React from 'react';
import PropTypes from 'prop-types';
import OverflowTooltipable from '@shared/overflow-tooltipable';

class OverflowTooltipableLabel extends OverflowTooltipable {
  render() {
    const { children } = this.props;
    const tooltip = this.props.tooltip || children;
    const hoverAction = event => this.hoverOn(event, true);
    return (
      <div
        onMouseOver={hoverAction}
        onMouseOut={this.hoverOff}
        role="presentation"
        style={{
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap'
        }}
      >
        {children}
        {this.renderTooltip(tooltip)}
      </div>
    );
  }
}

OverflowTooltipableLabel.propTypes = {
  children: PropTypes.node,
  targetId: PropTypes.number,
  tooltip: PropTypes.oneOfType([PropTypes.string, PropTypes.node])
};

export default OverflowTooltipableLabel;
