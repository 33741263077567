/* eslint-disable react/jsx-no-bind */
import React, { memo, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty } from 'lodash';
import { openDashboardDialog } from '@actions/dashboard-actions';
import { dotmapsGreen } from '@constants/colors';
import { trayHeaderConfig } from '@constants/component-configs';
import { getEntityType, isWorkflowEnabled } from '@constants/config';
import * as dialog from '@constants/dialogs';
import { mapTrayStyles } from '@constants/mui-theme';
import Header from '@components/map/tray/header';
import Divider from '@material-ui/core/Divider';
import { Tabs, Tab } from 'material-ui/Tabs';
import CycleCards from '@shared/cycle-cards';
import DotmapsLoader from '@shared/dotmaps-loader';
import EntityCard from '@shared/list-card/entity-card';
import MainGroupCard from '@shared/main-group-card';
import ListHeader from '@shared/ui-library/list/list-header';
import { getTrayTop } from '@selectors/map-selector';
import { canEditGroups, canAccessWorkflow } from '@utils/permission-utils';
import styles from './group.scss';

const Group = () => {
  const [activeTab, setActiveTab] = useState('list');
  const [scrolling, setScrolling] = useState(false);
  const tabsElement = useRef(null);
  const dispatch = useDispatch();
  const tray = useSelector(getTrayTop);
  const group = tray?.data?.group;

  if (!group) {
    return null;
  }

  const onListActive = () => setActiveTab('list');

  const onCyclesActive = () => setActiveTab('cycles');

  const onScroll = event => {
    const headerHeightDifference = trayHeaderConfig.fullHeight - trayHeaderConfig.scrollingHeight;
    if ((tabsElement.current.clientHeight + headerHeightDifference < event.target.scrollHeight) || scrolling) {
      setScrolling(event.target.scrollTop > 0);
    }
  };

  const editGroupAction = (id, type_name) => ({
    label: 'Edit group',
    link: `/group/${type_name}/${id}`
  });

  const startCycle = () => {
    const params = { groupId: group.id, reloadGroupTray: true };
    dispatch(openDashboardDialog(dialog.START_CYCLE, params));
  };

  const renderEmpty = msg => (
    <div className={styles.emptyElements}>
      {msg}
    </div>
  );

  const renderEntitiesByType = entities => {
    if (entities.length === 0) {
      return renderEmpty('No entities');
    }
    const groupedEntities = {};
    const entityTypes = new Set();
    entities.forEach(entity => {
      const type = entity.type_name;
      if (type) {
        if (!groupedEntities[type]) {
          groupedEntities[type] = [];
        }
        groupedEntities[type].push(entity);
        entityTypes.add(type);
      }
    });
    if (isEmpty(entityTypes)) {
      return <DotmapsLoader color={dotmapsGreen} display small />;
    }
    return [...entityTypes].map((entityType, index) => {
      const type = getEntityType(entityType);
      if (!type) {
        // If there's no type, it means it's an entity type
        // that should not be rendered in the UI (i.e. moratoriums).
        return null;
      }
      const { label } = type;
      const list = groupedEntities[entityType];
      return (
        <div key={label} className={styles.elementsSectionContainer}>
          {index > 0 && <Divider />}
          <ul className={styles.entityList}>
            <ListHeader
              text={label}
              count={list.length}
              className={styles.listHeader}
            />
            {list.map(entity => <EntityCard key={entity.id} entity={entity} />)}
          </ul>
        </div>
      );
    });
  };

  const renderCycles = () => {
    const { cycles } = group;
    if (cycles.length === 0) {
      return renderEmpty('No workflows');
    }
    return (
      <div className={styles.cycleElements}>
        <CycleCards cycles={cycles} />
      </div>
    );
  };

  const tabExists = (tabs, id) => tabs.find(tab => tab.id === id);

  const getTabProps = tab => {
    if (activeTab === tab) {
      return mapTrayStyles.activeTab;
    }
    return mapTrayStyles.tab;
  };

  const renderListTab = tabs => {
    if (tabExists(tabs, 'list')) {
      return (
        <Tab {...getTabProps('list')} label="LIST" onActive={onListActive}>
          {renderEntitiesByType(group.entities)}
        </Tab>
      );
    }
    return null;
  };

  const renderCyclesTab = tabs => {
    if (tabExists(tabs, 'cycles')) {
      return (
        <Tab {...getTabProps('cycles')} label="WORKFLOWS" onActive={onCyclesActive}>
          {renderCycles()}
        </Tab>
      );
    }
    return null;
  };

  const renderElements = () => {
    if (isWorkflowEnabled() && group.type_name === 'relational') {
      const tabs = [
        { id: 'list' },
        { id: 'cycles' }
      ];
      return (
        <div className={styles.mapTrayTabs} ref={tabsElement}>
          <Tabs {...mapTrayStyles.tabs} onScroll={onScroll}>
            {renderListTab(tabs)}
            {renderCyclesTab(tabs)}
          </Tabs>
        </div>
      );
    }
    return (
      <div className={styles.elements} onScroll={onScroll} ref={tabsElement}>
        {renderEntitiesByType(group.entities)}
      </div>
    );
  };

  const getActions = () => {
    const actions = [];
    if (canEditGroups()) {
      actions.push(editGroupAction(group.id, group.type_name));
    }
    if (isWorkflowEnabled() && canAccessWorkflow('cycle', 'add') && group.type_name === 'relational') {
      actions.push({ label: 'Start a workflow', action: startCycle });
    }
    return actions;
  };

  return (
    <div className={styles.mapTrayGroup}>
      <Header moreActions={getActions()} />
      <div className={styles.mapTrayGroupDetails}>
        <MainGroupCard group={group} scrolling={scrolling} />
      </div>
      <Divider />
      {renderElements()}
    </div>
  );
};

export default memo(Group);
