/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import { openDashboardDialog } from '@actions/dashboard-actions';
import * as dialog from '@constants/dialogs';
import OverlapStatusRow from '@shared/overlap-status-row';
import './conflict-list-item.scss';

class ConflictListItem extends Component {
  onClick = () => {
    const { entity } = this.props;
    const { id, segments, type_name } = entity;
    this.props.onClick(type_name, id, segments);
  };

  onViewStatusClick = event => {
    event.stopPropagation();
    const { entity, mainEntity, groupId } = this.props;
    if (entity && mainEntity) {
      const {
        agency_name,
        category_dict,
        id,
        icon,
        name,
        start_date,
        end_date,
        overlap_status,
        type_name
      } = entity;
      const params = {
        entityId: mainEntity.id,
        groupId,
        entity: {
          agency_name,
          category_dict,
          id,
          icon,
          name,
          start_date,
          end_date,
          overlap_status,
          type_name
        },
        mainEntity,
        overlapEntityId: id,
        overlapId: R.pathOr(null, [0, 'overlap'], overlap_status)
      };
      this.props.openDashboardDialog(dialog.OVERLAP_STATUS, params);
    }
  };

  render() {
    const { mainEntity, entity } = this.props;
    const { id, name } = entity;
    return (
      <div styleName="conflict-list-item-container" onClick={this.onClick} role="presentation">
        <div styleName="title">
          {name} &bull;&nbsp;<div styleName="id">ID&nbsp;{id}</div>
        </div>
        <div styleName="footer">
          <OverlapStatusRow
            isOpportunity={false}
            itemEntityType={entity.type_name}
            mainAgency={mainEntity.agency}
            mainEntityType={mainEntity.type_name}
            mainOverlapStatus={mainEntity.overlap_status}
            onClick={this.onViewStatusClick}
            overlapStatus={entity.overlap_status}
          />
        </div>
      </div>
    );
  }
}

ConflictListItem.propTypes = {
  entity: PropTypes.object,
  groupId: PropTypes.number,
  id: PropTypes.number,
  mainEntity: PropTypes.object,
  name: PropTypes.string,
  onClick: PropTypes.func,
  openDashboardDialog: PropTypes.func
};

const mapStateToProps = state => ({ groupId: state.groups.edit.group.id });

export default connect(mapStateToProps, { openDashboardDialog })(ConflictListItem);
