import { LOCATION_CHANGE } from 'connected-react-router';
import {
  HANDLE_SERVER_ERROR,
  LOGS_CLEAR,
  LOGS_FETCH_SUCCESS,
  LOGS_LOADING,
  LOGS_RELOAD
} from '@constants/action-types';
import initialState from '../store/initial-state';

const logsReducer = (state = initialState.logs, action) => {
  switch (action.type) {
  case LOGS_CLEAR: {
    return initialState.logs;
  }
  case LOGS_FETCH_SUCCESS: {
    const {
      count,
      model,
      current_pms,
      name,
      next,
      type,
      results
    } = action.payload;
    return {
      count,
      model,
      current_pms,
      list: [...state.list, ...results],
      loading: false,
      name,
      next,
      type
    };
  }
  case LOCATION_CHANGE: {
    if (action && action.payload) {
      const { location: { pathname } } = action.payload;
      if (pathname.startsWith('/logs/')) {
        return initialState.logs;
      }
    }
    return state;
  }
  case HANDLE_SERVER_ERROR: {
    return { ...state, loading: false };
  }
  case LOGS_LOADING: {
    return { ...state, loading: true };
  }
  case LOGS_RELOAD: {
    return { ...state, reload: true };
  }
  default:
    return state;
  }
};

export default logsReducer;
