const fundedRequires = ['City', 'SDOT'];

const cityAgencies = 'City';

// Default metaScript processing for entities:
export const getEntityMetaScript = (data, styleTemplate) => {
  let updates = {};

  // The "funded" metascript code is specific to Seattle,
  // we have to find a way to make this configurable.
  if (data.funded) {
    updates = {
      ...updates,
      start_date: {
        required: true
      },
      end_date: {
        required: true
      }
    };
    if (data.agencyType &&
        (fundedRequires.includes(data.agencyType.name) ||
         (data.agencyType.name &&
         data.agencyType.name.startsWith(cityAgencies))
        )) {
      updates = {
        ...updates,
        budget_id: {
          required: true
        }
      };
    }
  }

  // If all_day is set (i.e. time parts are hidden),
  // make start and end date field use half and half of
  // the screen width:
  if (data.all_day) {
    updates = {
      ...updates,
      start_date: {
        style: 'col50',
        type: 'date',
        ...updates.start_date
      },
      end_date: {
        style: 'col50',
        type: 'date',
        ...updates.end_date
      }
    };
  }

  // If start or end dates are set, setup min/max dates and field association:
  if (data.start_date) {
    updates = {
      ...updates,
      end_date: {
        ...updates.end_date,
        minDate: data.start_date,
        associatedField: 'start_date',  // Field to validate together.
        isFirstInAssociation: false
      }
    };
  }
  if (data.end_date) {
    updates = {
      ...updates,
      start_date: {
        ...updates.start_date,
        maxDate: data.end_date,
        associatedField: 'end_date',
        isFirstInAssociation: true
      }
    };
  }

  // Do the same as above but for additional custom start/end date fields:
  for (const [key, value] of Object.entries(styleTemplate)) {
    const { associatedField } = value;
    if (data[key] && associatedField) {
      let minMaxField = null;
      const { isFirstInAssociation } = styleTemplate[associatedField];
      if (isFirstInAssociation === true) {
        minMaxField = 'maxDate';
      } else {
        minMaxField = 'minDate';
      }
      updates = {
        ...updates,
        [associatedField]: {
          ...updates[associatedField],
          [minMaxField]: data[key]
        }
      };
    }
  }

  return updates;
};
