/* eslint-disable react/jsx-no-bind */
import React from 'react';
import PropTypes from 'prop-types';
import { colors } from '@constants/colors';
import { Icon, IconButton } from '@mui';
import LoaderIconWrapper from '@shared/loader-icon-wrapper';
import './file-preview.scss';

const FilePreviewHeader = ({
  assetUrl,
  closePreview,
  current,
  downloadUrl,
  fileName,
  total
}) => {
  const printAsset = () => {
    const printWindow = window.open(assetUrl);
    printWindow.print();
  };

  return (
    <div styleName="file-preview-header">
      <div>{fileName}</div>
      <div>{current} / {total}</div>
      <div>
        <IconButton onClick={printAsset}>
          <Icon color={colors.neutral.white}>print</Icon>
        </IconButton>
        <LoaderIconWrapper>
          <a href={downloadUrl} download>
            <IconButton>
              <Icon color={colors.neutral.white}>file_download</Icon>
            </IconButton>
          </a>
        </LoaderIconWrapper>
        <IconButton onClick={closePreview}>
          <Icon color={colors.neutral.white}>close</Icon>
        </IconButton>
      </div>
    </div>
  );
};

FilePreviewHeader.propTypes = {
  assetUrl: PropTypes.string,
  closePreview: PropTypes.func,
  current: PropTypes.number,
  downloadUrl: PropTypes.string,
  fileName: PropTypes.string,
  total: PropTypes.number
};

export default FilePreviewHeader;
