import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty } from 'lodash';
import moment from 'moment-timezone';
import { openDashboardDialog } from '@actions/dashboard-actions';
import * as dialog from '@constants/dialogs';
import Button from '@material-ui/core/Button';
import VerticalAlignBottom from '@material-ui/icons/VerticalAlignBottom';
import { canEditGroups } from '@utils/permission-utils';
import '../drawer.scss';

const BaselineRow = () => {
  const dispatch = useDispatch();
  const { group: { baseline, id } } = useSelector(state => state.groups.edit);

  const params = { groupId: id };
  const openSetBaselineDialog = () => dispatch(openDashboardDialog(dialog.SET_BASELINE, params));
  const openUpdateBaselineDialog = () => dispatch(openDashboardDialog(dialog.UPDATE_BASELINE, params));

  const hasBaseline = !isEmpty(baseline);
  const modified = hasBaseline ? moment(baseline.modified).format('M/D/YYYY hh:mm A') : '';
  const label = hasBaseline ? `Set ${modified}` : 'No baseline';
  const labelStyle = hasBaseline ? 'label set' : 'label';
  const buttonClick = hasBaseline ? openUpdateBaselineDialog : openSetBaselineDialog;
  const buttonLabel = hasBaseline ? 'UPDATE' : 'SET BASELINE';

  return (
    <div styleName="set-value-row">
      <div styleName="icon"><VerticalAlignBottom /></div>
      <div styleName={labelStyle}>{label}</div>
      {canEditGroups() && <Button color="primary" onClick={buttonClick}>{buttonLabel}</Button>}
    </div>
  );
};

export default BaselineRow;
