import React, { memo, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { getEntityTypeLabel } from '@constants/config';
import './success.scss';

const PublicDetailsSuccess = () => {
  const { dataType } = useParams();
  const { publicFormSuccessBackgroundPath } = useSelector(state => state.config);

  const url = useMemo(() => {
    if (publicFormSuccessBackgroundPath) {
      return publicFormSuccessBackgroundPath;
    }
    return 'https://storage.googleapis.com/coordinate-assets/background/public_form_success.png';
  }, [publicFormSuccessBackgroundPath]);

  const style = useMemo(() => ({
    backgroundRepeat: 'no-repeat',
    backgroundImage: `url(${url})`
  }), [url]);

  return (
    <div styleName="container">
      <div styleName="inner-container" style={style}>
        <div styleName="info-box">
          <div styleName="title">Submission successful!</div>
          <div styleName="info">
            Your application has submitted successfully and is under review.
            You will be reached out to via email about further progress.
          </div>
          <Link to={`/publicform/${dataType}`}>APPLY FOR ANOTHER {getEntityTypeLabel(dataType)}</Link>
        </div>
      </div>
    </div>
  );
};

export default memo(PublicDetailsSuccess);
