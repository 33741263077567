import React, {Component} from 'react';
import PropTypes from 'prop-types';
import * as colors from '@constants/colors';
import EnhancedButton from 'material-ui/internal/EnhancedButton';

const getStyles = props => ({
  root: {
    color: props.selected ? colors.secondaryColor : colors.dotmapsBlack40,
    fontWeight: 500,
    fontSize: 14,
    width: props.width,
    textTransform: 'uppercase',
    padding: 0
  },
  button: {
    display: 'flex',
    flexDirection: 'column',
    height: '3rem',
    alignItems: 'center',
    justifyContent: 'center'
  }
});

class Tab extends Component {
  handleTouchTap = (event) => {
    if (this.props.onClick) {
      this.props.onClick(this.props.value, event, this);
    }
  };

  render() {
    const {
      icon,
      index, // eslint-disable-line no-unused-vars
      onActive, // eslint-disable-line no-unused-vars
      onClick, // eslint-disable-line no-unused-vars
      selected, // eslint-disable-line no-unused-vars
      label,
      buttonStyle,
      style,
      value, // eslint-disable-line no-unused-vars
      width, // eslint-disable-line no-unused-vars
      ...other
    } = this.props;

    const styles = getStyles(this.props, this.context);

    let iconElement;
    if (icon && React.isValidElement(icon)) {
      iconElement = React.cloneElement(icon, {
        style: {
          fontSize: 24,
          color: (icon.props && icon.props.style && icon.props.style.color) ?
            icon.props.style.color : styles.root.color,
          marginBottom: label ? 5 : 0
        }
      });
    }

    const rippleOpacity = 0.3;
    const rippleColor = this.context.muiTheme.tabs.selectedTextColor;

    return (
      <EnhancedButton
        {...other}
        style={Object.assign(styles.root, style)}
        focusRippleColor={rippleColor}
        touchRippleColor={rippleColor}
        focusRippleOpacity={rippleOpacity}
        touchRippleOpacity={rippleOpacity}
        onClick={this.handleTouchTap}
      >
        <div style={Object.assign(styles.button, buttonStyle)} >
          {iconElement}
          {label}
        </div>
      </EnhancedButton>
    );
  }
}

Tab.propTypes = {
  /**
   * Override the inline-styles of the button element.
   */
  buttonStyle: PropTypes.object,
  /**
   * The css class name of the root element.
   */
  className: PropTypes.string,
  /**
   * Sets the icon of the tab, you can pass `FontIcon` or `SvgIcon` elements.
   */
  icon: PropTypes.node,
  /**
   * @ignore
   */
  index: PropTypes.oneOfType([PropTypes.object, PropTypes.number]),
  /**
   * Sets the text value of the tab item to the string specified.
   */
  label: PropTypes.node,
  /**
   * Fired when the active tab changes by touch or tap.
   * Use this event to specify any functionality when an active tab changes.
   * For example - we are using this to route to home when the third tab becomes active.
   * This function will always recieve the active tab as it\'s first argument.
   */
  onActive: PropTypes.func,
  /**
   * @ignore
   * This property is overriden by the Tabs component.
   */
  onClick: PropTypes.func,
  /**
   * @ignore
   * Defines if the current tab is selected or not.
   * The Tabs component is responsible for setting this property.
   */
  selected: PropTypes.bool,
  /**
   * Override the inline-styles of the root element.
   */
  style: PropTypes.object,
  /**
   * If value prop passed to Tabs component, this value prop is also required.
   * It assigns a value to the tab so that it can be selected by the Tabs.
   */
  value: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  /**
   * @ignore
   * This property is overriden by the Tabs component.
   */
  width: PropTypes.string
};

Tab.muiName = 'Tab';

Tab.contextTypes = {
  muiTheme: PropTypes.object.isRequired
};

export default Tab;
