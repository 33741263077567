/* eslint-disable max-nested-callbacks */
import axios from 'axios';
import { push } from 'connected-react-router';
import * as R from 'ramda';
import {
  AUTOCOMPLETE_LOADER_CHANGED,
  AUTOCOMPLETE_VALUES_CHANGED,
  DASHBOARD_DETAIL_DATA_CHANGED,
  DASHBOARD_DETAIL_UPDATE_FIELD,
  DASHBOARD_DETAIL_DATA_CLEAR_SAVE_ERROR_FIELD,
  DASHBOARD_DETAIL_DATA_DELETED,
  DASHBOARD_DETAIL_DATA_SAVE_ERROR,
  DASHBOARD_DETAIL_DATA_SAVING,
  DASHBOARD_DIALOG_PARAM,
  DASHBOARD_CLOSE_DIALOG,
  DASHBOARD_OPEN_DIALOG,
  DASHBOARD_POP_MESSAGE,
  DASHBOARD_PUSH_MESSAGE,
  DASHBOARD_RESOLUTION_LOADING
} from '@constants/action-types';
import { buildConfirmableAction } from '@utils/confirmation-utils';
import { parseEmptyStrings } from '@utils/dashboard-details-utils';
import {
  clearDataType,
  fetchDataDetail,
  navigateAfterSave
} from './data-detail-actions';
import { setLogReload } from './logs-actions';
import { pushApplicationMessage } from './messages-actions';
import {
  BASE_API_URL,
  getAutocompleteRequestUrl,
  getDetailAPIRequestUrl
} from '@constants/endpoints';
import { getServerErrorAction } from './shared-actions';

const getDataUpdatedAction = (dataType, payload) => ({
  type: DASHBOARD_DETAIL_DATA_CHANGED,
  dataType,
  payload
});

const getDataSaveErroredAction = (dataType, payload) => ({
  type: DASHBOARD_DETAIL_DATA_SAVE_ERROR,
  dataType,
  payload
});

const getClearDataErrorAction = (dataType, path) => ({
  type: DASHBOARD_DETAIL_DATA_CLEAR_SAVE_ERROR_FIELD,
  dataType,
  path
});

const getDashboardDataDeleted = (dataType, id) => ({
  type: DASHBOARD_DETAIL_DATA_DELETED,
  payload: {
    type: dataType,
    id
  }
});

export const fetchDashboardDetailsData = (dataType, id) => dispatch => {
  const url = getDetailAPIRequestUrl(dataType, id);
  axios
    .get(url)
    .then(data => dispatch(getDataUpdatedAction(dataType, data.data)));
};

export const createEmptyDataSet = (dataType, initialValues) => dispatch =>
  dispatch(getDataUpdatedAction(dataType, { ...initialValues }));

export const changeDashboardData = (dataType, data) => dispatch =>
  dispatch(getDataUpdatedAction(dataType, data));

export const clearDashboardDetailsDataError = (dataType, path) => dispatch =>
  dispatch(getClearDataErrorAction(dataType, path));

const getDashboardDetailsDataSavingAction = dataType => ({
  type: DASHBOARD_DETAIL_DATA_SAVING,
  dataType
});

export const updateDashboardDetailsData = dataType => (dispatch, state) => {
  dispatch(getDashboardDetailsDataSavingAction(dataType));
  const data = R.pathOr(false, ['dashboard', 'details', dataType, 'data'], state());
  if (data) {
    const request = axios({
      method: data.id ? 'put' : 'post',
      url: getDetailAPIRequestUrl(dataType, data.id),
      data: parseEmptyStrings(data)
    });
    request.then(
      success => {
        dispatch(getDataUpdatedAction(dataType, success.data));
        dispatch(navigateAfterSave(dataType, success.data.id, `/${dataType}/${success.data.id}`));
        dispatch(pushApplicationMessage(`${dataType} has been saved. ID ${success.data.id}`));
        return Promise.resolve();
      },
      ({response: error}) => {
        if (error.status < 500) {
          dispatch(getDataSaveErroredAction(dataType, error.data));
        } else {
          dispatch(getServerErrorAction(dataType, error.data));
        }
        return Promise.reject();
      }
    );
  }
};

const updateDashboardFieldAction = (dataType, field, data) => ({
  type: DASHBOARD_DETAIL_UPDATE_FIELD,
  dataType,
  field,
  data
});

export const updateDashboardField = (dataType, field, data) => dispatch =>
  dispatch(updateDashboardFieldAction(dataType, field, data));

const getAutocompleteChangeAction = payload => ({
  type: AUTOCOMPLETE_VALUES_CHANGED,
  payload
});

const getAutocompleteLoaderChangeAction = payload => ({
  type: AUTOCOMPLETE_LOADER_CHANGED,
  payload
});

export const requestAutocompleteValues = (dataType, query, subType) => dispatch => {
  const url = getAutocompleteRequestUrl(dataType, query, subType);
  dispatch(getAutocompleteLoaderChangeAction(true));
  const request = axios.get(url);
  request.then(
    success => {
      dispatch(getAutocompleteChangeAction(success.data));
      dispatch(getAutocompleteLoaderChangeAction(false));
    },
    error => dispatch(getServerErrorAction(dataType, error))
  );
};

export const emptyAutocompleteValues = () => dispatch =>
  dispatch(getAutocompleteChangeAction({ suggestions: [] }));

export const setDialogParam = (dialog, params) => dispatch => dispatch({ type: DASHBOARD_DIALOG_PARAM, dialog, params });

export const openDashboardDialogAction = (dialog, params) => ({ type: DASHBOARD_OPEN_DIALOG, dialog, params });

export const openDashboardDialog = (dialog, params) => dispatch => dispatch(openDashboardDialogAction(dialog, params));

export const closeDashboardDialog = dialog => dispatch => dispatch({ type: DASHBOARD_CLOSE_DIALOG, dialog });

export const pushDashboardMessage = message => dispatch => dispatch({ type: DASHBOARD_PUSH_MESSAGE, message });

export const popDashboardMessage = () => dispatch => dispatch({ type: DASHBOARD_POP_MESSAGE });

export const setResolutionLoading = loading => dispatch => {
  dispatch({ type: DASHBOARD_RESOLUTION_LOADING, loading });
  return Promise.resolve();
};

export const resolveConflict = payload => {
  const { entityId } = payload;
  const url = `${BASE_API_URL}/overlap/${entityId}/resolve/`;
  const params = {
    notes: payload.notes,
    overlap_id: payload.overlapId,
    both_sides: payload.bothSides,
    reason: payload.reason ? payload.reason.join(', ') : ''
  };
  return dispatch => dispatch(setResolutionLoading(true)).then(() => {
    const request = axios.post(url, params);
    request.then(
      data => {  // eslint-disable-line no-unused-vars
        dispatch(clearDataType('overlap')).then(() => {
          // Reload the data:
          dispatch(fetchDataDetail('overlap', entityId)).then(() => {
            dispatch(setLogReload()).then(() => {
              dispatch(setResolutionLoading(false)).then(() => {
                dispatch(pushDashboardMessage('Conflict resolved.'));
              });
            });
          });
        });
      }
    );
  });
};

export const revokeConflict = payload => {
  const { entityId } = payload;
  const url = `${BASE_API_URL}/overlap/${entityId}/revoke/`;
  const params = {
    overlap_id: payload.overlapId
  };
  return dispatch => dispatch(setResolutionLoading(true)).then(() => {
    const request = axios.post(url, params);
    request.then(
      data => {  // eslint-disable-line no-unused-vars
        dispatch(clearDataType('overlap')).then(() => {
          // Reload the data:
          dispatch(fetchDataDetail('overlap', entityId)).then(() => {
            dispatch(setLogReload()).then(() => {
              dispatch(setResolutionLoading(false)).then(() => {
                dispatch(pushDashboardMessage('Resolution revoked.'));
              });
            });
          });
        });
      }
    );
  });
};

export const deleteDashboardEntry = (dataType, id) => {
  const deleteUrl = `${BASE_API_URL}/${dataType}/${id}/`;
  const request = axios.delete(deleteUrl);
  return dispatch =>
    request.then(
      () => {
        dispatch(getDashboardDataDeleted(dataType, id));
        dispatch(pushApplicationMessage(`The selected ${dataType.replace(/_/g, ' ')} has been successfully deleted.`));
        dispatch(push(`/library/${dataType}`));
      },
      error => dispatch(getServerErrorAction(dataType, error))
    );
};

export const confirmableDeleteDashboardEntry = buildConfirmableAction(
  deleteDashboardEntry, 'dashboardActionsDeleteDashboardEntry'
);

export const deactivateDashboardEntry = (dataType, id) => {
  const deactivateUrl = `${BASE_API_URL}/${dataType}/${id}/`;
  const request = axios.delete(deactivateUrl);
  return dispatch =>
    request.then(
      () => {
        dispatch(fetchDashboardDetailsData(dataType, id));
        dispatch(pushApplicationMessage(`The selected ${dataType.replace(/_/g, ' ')} has been successfully deactivated.`));
        dispatch(push(`/${dataType}/${id}`));
      },
      error => dispatch(getServerErrorAction(dataType, error))
    );
};

export const reactivateDashboardEntry = (dataType, id) => {
  const reactivateUrl = `${BASE_API_URL}/${dataType}/${id}/reactivate/`;
  const request = axios.get(reactivateUrl);
  return dispatch =>
    request.then(
      () => {
        dispatch(fetchDashboardDetailsData(dataType, id));
        dispatch(pushApplicationMessage(`The selected ${dataType.replace(/_/g, ' ')} has been successfully reactivated.`));
        dispatch(navigateAfterSave(dataType, id, `/${dataType}/${id}`));
      },
      error => dispatch(getServerErrorAction(dataType, error))
    );
};

export const confirmableDeactivateDashboardEntry = buildConfirmableAction(
  deactivateDashboardEntry, 'dashboardActionsDeactivateDashboardEntry'
);

export const confirmableReactivateDashboardEntry = buildConfirmableAction(
  reactivateDashboardEntry, 'dashboardActionsReactivateDashboardEntry'
);
