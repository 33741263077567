import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { push } from 'connected-react-router';
import { isEmpty } from 'lodash';
import * as dialog from '@constants/dialogs';
import { openDashboardDialog } from '@actions/dashboard-actions';
import { errorDataField } from '@actions/data-detail-actions';
import Button from '@material-ui/core/Button';
import { validateForm } from '@utils/workflow-utils';
import './add-task-footer.scss';

const AddTaskFooter = () => {
  const dispatch = useDispatch();
  const { taskTypeId } = useParams();
  const { data: task, error } = useSelector(state => state.dataDetail);
  const { task_type } = useSelector(state => state.dataTypes);
  const { cycle } = useSelector(state => state.workflow);

  const isFormValid = () => task && isEmpty(error);

  const setError = useCallback(
    err => dispatch(errorDataField(err.field, err.value, true)),
    [dispatch]
  );

  const onAssign = useCallback(
    () => {
      // Validate custom fields:
      if (task) {
        const errors = validateForm(task, task_type, taskTypeId);
        if (isEmpty(errors)) {
          dispatch(openDashboardDialog(dialog.ASSIGN_TASK, {loading: false}));
        } else {
          errors.map(err => setError(err));
        }
      }
    },
    [task, dispatch, setError, task_type, taskTypeId]
  );

  const onCancel = useCallback(() => dispatch(push(`/cycle/${cycle?.id}`)), [cycle?.id, dispatch]);

  return (
    <div styleName="footer">
      <Button
        color="primary"
        disabled={!isFormValid()}
        onClick={onAssign}
        variant="contained"
      >
        ASSIGN
      </Button>
      <div styleName="footer-separator" />
      <Button onClick={onCancel}>CANCEL</Button>
    </div>
  );
};

export default AddTaskFooter;
