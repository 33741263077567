import React, { memo } from 'react';
import Entities from '@components/map/components/entities';
import DotMapsGoogleMap from '@shared/dotmaps-google-map';
import './print-map.scss';

const Body = () => (
  <div styleName="body">
    <DotMapsGoogleMap components={<Entities markerVisible />} />
  </div>
);

export default memo(Body);
