import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { setDrawingMode } from '@actions/map-actions';
import { downloadCSVExport } from '@actions/data-table-actions';
import { textColor, dotmapsBlue } from '@constants/colors';
import DrawLineIcon from '@icons/draw-line-icon';
import DrawCircleIcon from '@icons/draw-circle-icon';
import MeasurementIcon from '@icons/measurement-icon';
import { getToolbarConfig } from '@selectors/map-selector';
import ActionDownloadIconButton from '@shared/action-download-icon-button';
import MapToolbarIconButton from '@shared/map-toolbar-icon-button';
import { shallowEqual } from '@utils/react-utils';
import Separator from './separator';
import './tools.scss';

class Tools extends Component {
  constructor(props) {
    super(props);
    this.setMeasurement = this.setDrawingMode.bind(this, 'measurement');
    this.setPolygon = this.setDrawingMode.bind(this, 'polygon');
    this.setCircle = this.setDrawingMode.bind(this, 'circle');
  }

  // eslint-disable-next-line no-unused-vars
  shouldComponentUpdate(nextProps, nextState) {
    return !shallowEqual(nextProps.mode, this.props.mode);
  }

  setDrawingMode = mode => {
    this.props.setDrawingMode(this.props.mode === mode ? '' : mode);
  };

  exportCSV = () => {
    const { filters } = this.props;
    Object.keys(filters).forEach(filter => {
      this.props.downloadCSVExport(filter, null, filters[filter]);
    });
  };

  render() {
    const { mode, toolsConfig } = this.props;
    const { measurement = true, polygon = true, circle = true} = toolsConfig;
    return (
      <div data-testid="map-tools" styleName="map-tools">
        {measurement && (
          <MapToolbarIconButton onClick={this.setMeasurement} tooltip="Measure distance">
            <MeasurementIcon color={mode === 'measurement' ? dotmapsBlue : textColor} />
          </MapToolbarIconButton>
        )}
        {polygon && (
          <MapToolbarIconButton onClick={this.setPolygon} tooltip="Select an area">
            <DrawLineIcon color={mode === 'polygon' ? dotmapsBlue : textColor} />
          </MapToolbarIconButton>
        )}
        {circle && (
          <MapToolbarIconButton onClick={this.setCircle} tooltip="Select an area">
            <DrawCircleIcon color={mode === 'circle' ? dotmapsBlue : textColor} />
          </MapToolbarIconButton>
        )}
        <Separator />
        <Link to={'/map/print'}>
          <MapToolbarIconButton icon="print" color={textColor} tooltip="Print" />
        </Link>
        <ActionDownloadIconButton color={textColor} loaderColor={dotmapsBlue} onClick={this.exportCSV} toolbar />
      </div>
    );
  }
}

Tools.propTypes = {
  downloadCSVExport: PropTypes.func,
  filters: PropTypes.object,
  mode: PropTypes.string,
  openDashboardDialog: PropTypes.func,
  setDrawingMode: PropTypes.func,
  toolsConfig: PropTypes.object
};

const mapStateToProps = state => {
  const { drawing } = state.map;
  const toolsConfig = getToolbarConfig(state);

  const { map, entities: { types } } = state;

  const filters = {};
  Object.keys(types).forEach(type => {
    filters[type] = {
      ...map.filters,
      ...types[type].filters
    };
  });

  return { ...drawing, toolsConfig, filters };
};

export default connect(mapStateToProps, { downloadCSVExport, setDrawingMode })(Tools);
