import React, { Fragment } from 'react';
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';

// Standard (default) dialog action buttons.
const DialogActions = ({
  cancelLabel = 'CANCEL',
  disabledCancel = false,
  disabledSubmit = false,
  onCancel,
  onSubmit,
  submitColor = 'primary',
  submitLabel,
  submitProps = {}
}) => (
  <Fragment>
    <Button
      disabled={disabledCancel}
      onClick={onCancel}
      style={{ marginRight: '1rem' }}
    >
      {cancelLabel}
    </Button>
    <Button
      color={submitColor}
      disabled={disabledSubmit}
      onClick={onSubmit}
      variant="contained"
      {...submitProps}
    >
      {submitLabel}
    </Button>
  </Fragment>
);

DialogActions.propTypes = {
  cancelLabel: PropTypes.string,
  disabledCancel: PropTypes.bool,
  disabledSubmit: PropTypes.bool,
  onCancel: PropTypes.func,
  onSubmit: PropTypes.func,
  submitColor: PropTypes.string,
  submitLabel: PropTypes.string,
  submitProps: PropTypes.object
};

export default DialogActions;
