import { withStyles } from '@material-ui/core/styles';
import TableCell from '@material-ui/core/TableCell';
import * as colors from '@constants/colors';

const Cell = withStyles(theme => ({  // eslint-disable-line no-unused-vars
  root: {
    color: colors.dotmapsBlack80,
    fontFamily: 'Roboto',
    fontSize: '0.875rem',
    fontWeight: '400',
    lineHeight: '1.25rem',
    padding: '1rem'
  },
  head: {
    backgroundColor: colors.dotmapsLightGray,
    color: colors.dotmapsBlack50,
    textTransform: 'uppercase',
    fontSize: '0.75rem',
    fontWeight: '500',
    lineHeight: '1.125rem',
    padding: '0.5rem 1rem'
  }
}))(TableCell);

export default Cell;
