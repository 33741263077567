/* eslint-disable react/jsx-no-bind */
import React from 'react';
import PropTypes from 'prop-types';
import Tooltip from '@material-ui/core/Tooltip';
import '../table.scss';

const Actions = ({ actions, row }) => {
  const allowedActions = actions.filter(action => !action.permission || action.permission(row));
  const iconProps = { htmlColor: '#616161', style: { fontSize: '1.25rem', padding: '0 0 0 0.5rem' } };
  return (
    <div styleName="row-action">
      {allowedActions.map(action => (
        <Tooltip title={action.label} key={row.id}>
          <action.icon {...iconProps} onClick={event => {
            event.stopPropagation();
            action.onClick(row);
          }} />
        </Tooltip>
      ))}
    </div>
  );
};

Actions.propTypes = {
  actions: PropTypes.array,
  row: PropTypes.object
};

export default Actions;
