import axios from 'axios';
import { v4 as uuid } from 'uuid';
import {
  CALENDAR_FETCH_DATA,
  CALENDAR_FETCH_ERROR,
  CALENDAR_FETCH_START,
  CALENDAR_FETCH_SUCCESS,
  CALENDAR_UPDATE_SELECTED_FILTERS
} from '@constants/action-types';
import { getCalendarEntity } from '@constants/config';
import { getAPIRequestUrl } from '@constants/endpoints';

const fetchCalendarStart = fetchId => ({
  type: CALENDAR_FETCH_START,
  payload: { fetchId }
});

const fetchCalendarData = data => ({
  type: CALENDAR_FETCH_DATA,
  payload: { data }
});

const fetchCalendarSuccess = () => ({
  type: CALENDAR_FETCH_SUCCESS
});

const fetchCalendarError = payload => ({
  type: CALENDAR_FETCH_ERROR,
  payload
});

const executeCalendarFetch = (fetchId, url, filters = {}) => {
  const request = axios.get(url, { params: filters });
  return (dispatch, getState) => {
    request.then(
      ({data}) => {
        if (getState().calendar.fetchId === fetchId) {
          dispatch(fetchCalendarData(data));
          if (data.next) {
            return dispatch(executeCalendarFetch(fetchId, data.next));
          }
          return dispatch(fetchCalendarSuccess());
        }
        return Promise.resolve();
      },
      error => dispatch(fetchCalendarError(error))
    );
  };
};

export const fetchCalendar = (filters = {}) => {
  const url = getAPIRequestUrl(getCalendarEntity());
  const fetchId = uuid();
  return dispatch => {
    dispatch(fetchCalendarStart(fetchId));
    return dispatch(executeCalendarFetch(fetchId, url, filters));
  };
};

export const updatedSelectedFilters = (dataType, selectedKeys) => dispatch => {
  dispatch({
    type: CALENDAR_UPDATE_SELECTED_FILTERS,
    dataType,
    selectedKeys
  });
  return Promise.resolve();
};
