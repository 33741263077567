/* eslint-disable react/jsx-no-bind */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';

import { Icon, Snackbar, Switch } from '@mui';
import { updateAtId } from '@utils/state-utils';

import {
  CARD_STATE_LOADING,
  CARD_TYPE_EMBED
} from '../constants';

import DashboardContext from '../dashboard-context';
import { getColumnIndex, getColumnsData } from '../utils';

import { ADD_CUSTOM_VIEW } from './customize-dashboard-dialog';

import './customize-dashboard-dialog.scss';

const styles = {
  switchLabel: {
    marginLeft: 0,
    width: '100%',
    justifyContent: 'space-between'
  }
};

const getOption = (card, onChange, disabled) => (
  <div key={card.id} styleName="customize-dashboard-option">
    <Switch
      labelPlacement="start"
      label={
        <div styleName="customize-dashboard-label">
          <div styleName="option-icon">{card.icon}</div>
          <div>
            <div styleName="customize-option-heading">{card.heading}</div>
            <span styleName="customize-option-subheading">
              {card.subheading}
            </span>
          </div>
        </div>
      }
      style={styles.switchLabel}
      checked={card.order !== -1}
      onChange={() => onChange(card)}
      disabled={disabled}
    />
  </div>
);

const CustomizeDashboardView = ({ setView }) => {
  const {
    allCards,
    setAllCards,
    lgColumn,
    smColumn,
    updateLayout,
    updatingLayout
  } = useContext(DashboardContext);
  const optionCards = allCards.filter(card => card.type !== CARD_TYPE_EMBED);

  const onChange = async cardUpdated => {
    const isEnabling = cardUpdated.order === -1;
    const targetColumnIdx = getColumnIndex(cardUpdated.size);
    const columns = getColumnsData(lgColumn, smColumn);
    const newOrder = isEnabling ? columns[targetColumnIdx].length : -1;

    if (isEnabling) {
      columns[targetColumnIdx] = [...columns[targetColumnIdx], cardUpdated.id];
    } else {
      columns[targetColumnIdx] = R.without(
        [cardUpdated.id],
        columns[targetColumnIdx]
      );
    }

    try {
      updateLayout(columns);
      const updatedCards = updateAtId(
        cardUpdated.id,
        R.evolve({
          order: () => newOrder,
          contentState: () => CARD_STATE_LOADING
        })
      )(allCards);
      setAllCards(updatedCards);
    } catch (error) {
      Snackbar.error({
        message:
          'Something went wrong. Please try again or contact an administrator.'
      });
    }
  };

  const disabled = updatingLayout || R.isEmpty(allCards);

  return (
    <div styleName="customize-dashboard-options">
      <div
        styleName="customize-dashboard-option"
        onClick={() => setView(ADD_CUSTOM_VIEW)}
      >
        <div styleName="customize-dashboard-label">
          <div styleName="option-icon">
            <Icon>code</Icon>
          </div>
          <div>
            <div styleName="customize-option-heading">Custom Embed</div>
            <span styleName="customize-option-subheading">
              Insert an embed link.
            </span>
          </div>
        </div>
        <div styleName="customize-option-right">
          <Icon>chevron_right</Icon>
        </div>
      </div>
      {optionCards.map(card =>
        getOption(
          { ...card },
          onChange,
          disabled
        )
      )}
    </div>
  );
};

CustomizeDashboardView.propTypes = {
  setView: PropTypes.func
};

export default CustomizeDashboardView;
