import React from 'react';
import PropTypes from 'prop-types';
import Row from '@components/workflow/section/view-field-row';
import InlineAttachmentsControl from './inline-attachments-control';
import '../inline-edit-field.scss';

const InlineAttachmentsField = ({
  activity,
  attachments,
  errors,
  hasErrors,
  isReadOnly,
  label
}) => {
  const rowStyles = { alignItems: 'flex-start' };
  const rowLabelStyles = { minHeight: '50px' };
  const value = (
    <div styleName="control-wrapper">
      <InlineAttachmentsControl
        activity={activity}
        attachments={attachments}
        errors={errors}
        isReadOnly={isReadOnly}
      />
    </div>
  );

  rowStyles.padding = hasErrors ? '0 0 1.25rem 0' : '0 0 0.5rem 0';

  return <Row label={label} value={value} labelStyle={rowLabelStyles} style={rowStyles} />;
};

InlineAttachmentsField.propTypes = {
  activity: PropTypes.object,
  attachments: PropTypes.array,
  errors: PropTypes.array,
  hasErrors: PropTypes.bool,
  isReadOnly: PropTypes.bool,
  label: PropTypes.string
};

export default InlineAttachmentsField;
