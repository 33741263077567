import {
  ATTACHMENT_CLOSE_PREVIEW,
  ATTACHMENT_DELETE_STORED_SUCCESS,
  ATTACHMENT_DELETE_SUCCESS,
  ATTACHMENT_FETCH_ERROR,
  ATTACHMENT_FETCH_SUCCESS,
  ATTACHMENT_LOADING,
  ATTACHMENT_SHOW_PREVIEW,
  ATTACHMENT_STORE_CLEAR,
  ATTACHMENT_STORE_SUCCESS,
  ATTACHMENT_TEMPFILE_DELETE_SUCCESS,
  ATTACHMENT_UPLOAD_ERROR,
  ATTACHMENT_UPLOAD_SUCCESS
} from '@constants/action-types';
import initialState from '../store/initial-state';

const attachmentReducer = (state = initialState.attachments, action) => {
  switch (action.type) {
  case ATTACHMENT_DELETE_SUCCESS: {
    const attachments = { ...state.attachments };
    const attachmentId = action.payload;
    const newAttachments = Object.values(attachments)[0].filter(attachment => attachment.id !== attachmentId);
    const attachmentKey = Object.keys(attachments)[0];
    return {
      ...state,
      attachments: {
        [attachmentKey]: newAttachments
      }
    };
  }
  case ATTACHMENT_LOADING: {
    return { ...state, loading: true };
  }
  case ATTACHMENT_TEMPFILE_DELETE_SUCCESS: {
    const attachments = { ...state.attachments };
    const {objectId, fileName, contentType} = action;
    const attachmentKey = `${contentType}${objectId}`;
    const newAttachments = attachments[attachmentKey].filter(attach => attach.file_name !== fileName);
    return {
      ...state,
      attachments: {
        [attachmentKey]: newAttachments
      }
    };
  }
  case ATTACHMENT_FETCH_SUCCESS: {
    const attachments = { ...state.attachments };
    attachments[`${action.contentType}${action.objectId}`] =
        action.payload.data.results;
    return { ...state, attachments, loading: false };
  }
  case ATTACHMENT_FETCH_ERROR: {
    return { ...state, errors: [...state.errors, action.payload], loading: false };
  }
  case ATTACHMENT_UPLOAD_SUCCESS: {
    const attachments = { ...state.attachments };
    const contentKey = `${action.contentType}${action.objectId}`;
    const replace = action.replace;
    let contentAttachments = attachments[contentKey]
      ? attachments[contentKey]
      : [];
    if (replace) {
      contentAttachments = contentAttachments.map(attch => {
        return attch.file_name === action.payload.data.file_name ? action.payload.data : attch;
      });
    } else {
      contentAttachments = contentAttachments.concat([action.payload.data]);
    }
    attachments[contentKey] = contentAttachments;
    return { ...state, attachments };
  }
  case ATTACHMENT_UPLOAD_ERROR: {
    return { ...state, errors: [...state.errors, action.payload] };
  }
  case ATTACHMENT_SHOW_PREVIEW: {
    const { contentType, objectId, index } = action;
    return { ...state, contentType, objectId, index };
  }
  case ATTACHMENT_CLOSE_PREVIEW: {
    return { ...state, contentType: null, objectId: null, index: -1 };
  }
  case ATTACHMENT_STORE_SUCCESS: {
    const preUploadAttachments = { ...state.preUploadAttachments };
    const contentKey = `${action.contentType}${action.objectId}`;
    let contentAttachments = preUploadAttachments[contentKey]
      ? preUploadAttachments[contentKey]
      : [];
    contentAttachments = contentAttachments.concat([action.attachment]);
    preUploadAttachments[contentKey] = contentAttachments;
    return { ...state, preUploadAttachments };
  }
  case ATTACHMENT_STORE_CLEAR: {
    const preUploadAttachments = { ...state.preUploadAttachments };
    const contentKey = `${action.contentType}${action.objectId}`;
    preUploadAttachments[contentKey] = [];
    return { ...state, preUploadAttachments };
  }
  case ATTACHMENT_DELETE_STORED_SUCCESS: {
    const attachments = { ...state.preUploadAttachments };
    const attachmentId = action.payload;
    const newAttachments = Object.values(attachments)[0].filter(attachment => attachment.id !== attachmentId);
    const attachmentKey = Object.keys(attachments)[0];
    return {
      ...state,
      preUploadAttachments: {
        [attachmentKey]: newAttachments
      }
    };
  }
  default:
    return state;
  }
};

export default attachmentReducer;
