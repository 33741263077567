import React from 'react';
import DataUriIcon from './index';

// Map PNG image (PNG to base64):
const data = 'iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAACXBIWXMAAAsTAAALEwEAmp' +
  'wYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAATDSURBVHgBpVZZbxtVFP5sj+1M7MSOm8SO02wSNC' +
  'VUJQ0U0ZQ0W0tKQVUFFULiqRISD7zAL+lPqHhAAomtbyBICS0UNSqkJCUpzeLES2I7tjPex/bY5tzjxLHV+Kl' +
  'HGs29M+d+Z/vOmdGBxOPx9JfL5Vt0DdPWjueXH/R6/ecDAwObOgFeKpUWtkNeuy+wCU3TkEyksLa2XnfC1eVEV' +
  '5eL1zqdDnbbMZiNMnJqDo+XFxHeDfE7s9mMk4NDGBudUMjIGYnAb967P2fXiipMZhMrhcPhOnBxyOXs5HWTWcb' +
  'ZkVH0HO+FwWDgZ9NTl/Dtd19jYfFvlEtlrK+vobXFZh8+PXJLT++vbW5tVMGFFIulOgOUOpRhgNvVi4sTV9DfN' +
  '8BRxGJRKHEFsizj+vsfott9HAWtgFQ6ifmHf4pzw5IAaGmx1AHKzTLS6TRsrTZMTb6FU0On0NxsgclkYuBcLod' +
  'Vzwr2kmF41r2YmXwXTqcL42NT+PKrLxhD6JDYRQRwHHNUwSllSKVSsFis+OTjTzF2/gLa2hwwGo3I5/MIhXaw' +
  '+OQhYokQR5ZIxHHnt1/47IkXB1mvVjiCg1wKCfi3oWZVXH3nPbhcXeyJN+CBkoxQ+HloxUKNM2X4faSfLrBjolY' +
  'tVhtie5F6A7WSyWQgN8l4deQ13gvwncgWjpJSscigIm1V0dXr6FFTyN1wBFnyfnDwJUqRhb2PKiE0EuF1PpdHm' +
  '90BoiRTvEiXUTKSHV19BJFIFH5/gBQNxOFxPqDEY8hrakMDjxdX2Al3l5v3e8oe3wUh6iJIpzOQ9usgwPv6+omqR' +
  'WyHfA3BBbDf5+e1w9G+7+TuM3ocwerTVUoR8PLQaVyeucLee/2bSGaVhgYEZZuI/xly7qCHVFUlEmjsnKYVDg0Mn' +
  '3kFbmcP3hyd4KJFqYH8wQ16U25oIODfYfBKBlJ8t1qtKBCVy3yupgaXp69SmMeYrqIzn24uQSsVGoILeiYTyere5' +
  '/MC54C+3n7q6mZk1SwM+hoDHR2dzIhgOIgN3xMKM1cFUrNZGmjE/0Il9ALdha6azVUNrPy3zKAigtfPvoGfZ39EXQ' +
  '08W2uIxSPI5BJUixIDppIZToEAO0pSyUpauAcoI/PzDzB+YYKuSSwuLSAUrtCbWfTP8l/wbK5hxx+Ef2sHwe1wBY' +
  'DONltk2B02tLRaIUmHHZ+sGtAzKR7M36fhF0MzpeiD6x9RLZsODSTjSWQzKlEvD71BTzyW4XJ3oqevGx3Odq6N0STB' +
  '0e5AV7cT5iYTz6WKlOmcipgSw/e3v+Fm6yeaz1x6+zBFbImArVYLe1vb+vG9OJS9RHVvJkoGQ+GaglM9cpVm/HdlC' +
  'XN3f8X05EWcPzdGxfcdjgoDdbBklPiDodJXKk6gIUpVLbiQ7UAQWx4vGsnsnZ8QCPirU1UYUCSDxBGIsLepDhX' +
  'gOBs6kDJ5KmaVj7pXzK1aqUzRVvEVY5re++Mup4pTRMqP3K6eiVRO4UJqNo0LKCLSS3q6V4o4N/s7DcLskV4Lx4y' +
  'SifWEca9vC9FoVKxv0wgy3OjpHlhQ0hF7Ro1zmtoc9T8W4VCkIThHR6D5Qh6yQWYjJ144Kb6ICmF/xtXc/7O4Sctr' +
  'eH5RiCSP6Lohflv+B3LtYqerKeptAAAAAElFTkSuQmCC';

const TerrainMapTypeIcon = props => <DataUriIcon data={data} {...props} />;

export default TerrainMapTypeIcon;
