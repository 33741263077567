/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/display-name */
/* eslint-disable react/jsx-no-bind */
import React from 'react';
import PropTypes from 'prop-types';
import { dotmapsLightGrayText } from '@constants/colors';
import EntityTypeIcon from '@icons/entity-type-icon';
import { Icon } from '@mui';
import {
  calculateLevelStyle,
  calculateLeftContainerLevelStyle,
  renderDays
} from '@utils/gantt-utils';
import Badge from '@shared/badge';
import '@shared/list-item/list-item.scss';

const ListItem = ({
  end_date,
  entityType,
  id,
  level,
  moreButton,
  name,
  on_street,
  start_date,
  title
}) => {
  const onClick = () => {
    window.open(`/${entityType}/${id}?target=newWindow`, '_blank');
  };

  const renderHeader = () => (
    <div styleName="header">
      <div styleName="type-icon">
        <EntityTypeIcon type={entityType} />
      </div>
      <div styleName="title">
        {title} <span>&bull; ID {id}</span>
      </div>
      <div styleName="actions">
        { moreButton }
      </div>
    </div>
  );

  const renderContent = () => (
    <div styleName="content">
      <div styleName="name">{name}</div>
    </div>
  );

  const renderFooter = () => {
    const streets = on_street ? on_street.split(',') : [];
    const label = `+${streets.length - 1} more`;
    return (
      <div styleName="footer">
        {streets[0]}
        {streets.length > 1 && <Badge color="gray" label={label} small />}
      </div>
    );
  };

  if (!id) {
    return null;
  }

  const daysLabel = renderDays(start_date, end_date);
  const style = calculateLevelStyle(level);
  const leftContainerStyle = calculateLeftContainerLevelStyle(level);

  return (
    <div key={id}
      onClick={onClick}
      role="presentation"
      style={style}
      styleName="list-item gantt-list-item">
      <div styleName="left-container" style={leftContainerStyle}>
        {renderHeader()}
        {renderContent()}
        {renderFooter()}
      </div>
      <div styleName="right-container">
        <div styleName="text">
          {daysLabel}
        </div>
        <div styleName="icon">
          <Icon color={dotmapsLightGrayText}>open_in_new</Icon>
        </div>
      </div>
    </div>
  );
};

ListItem.propTypes = {
  end_date: PropTypes.string,
  entityType: PropTypes.string,
  id: PropTypes.number,
  level: PropTypes.number,
  moreButton: PropTypes.object,
  name: PropTypes.string,
  on_street: PropTypes.string,
  start_date: PropTypes.string,
  title: PropTypes.string
};

export default ListItem;
