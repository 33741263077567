import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { push } from 'connected-react-router';
import { Icon, IconButton } from '@mui';
import { colors } from '@constants/colors';
import { getDashboardBackURL } from '@utils/navigation-utils';
import { withRouter } from '@utils/router-utils';
import './top-bar.scss';

const TopBar = ({ location }) => {
  const dispatch = useDispatch();

  const onBackClick = useCallback(
    () => {
      const source = getDashboardBackURL(location, null, '/map');
      dispatch(push(source));
    },
    [dispatch, location]
  );

  return (
    <div data-testid="settings-top-bar" styleName="top-bar-container">
      <div styleName="content">
        <IconButton onClick={onBackClick}>
          <Icon color={colors.neutral.white}>arrow_back</Icon>
        </IconButton>
        <Icon color={colors.neutral.white}>settings</Icon>
        <div styleName="title">
          Settings
        </div>
      </div>
    </div>
  );
};

TopBar.propTypes = {
  location: PropTypes.object
};

export default withRouter(TopBar);

export { TopBar as PureTopBar };
