import React, { memo, useMemo } from 'react';
import PropTypes from 'prop-types';

import SelectFilterChip from '../chips/select';

import { useDataTypeFilterHandleChange } from '../hooks';

const CheckboxFilter = ({filter, value, dataType, subType, filterKey}) => {
  const handleChange = useDataTypeFilterHandleChange(dataType, subType, filterKey);
  const options = useMemo(() => {
    const onValue = 'onValue' in filter ? filter.onValue : true;
    const offValue = 'offValue' in filter ? filter.offValue : false;
    return [
      {
        label: 'Checked',
        value: onValue
      },
      {
        label: 'Uncheked',
        value: offValue
      }
    ];
  }, [filter]);

  return (
    <SelectFilterChip
      label={filter.label}
      options={options}
      value={value}
      onChange={handleChange}
    />
  );
};

CheckboxFilter.propTypes = {
  dataType: PropTypes.string.isRequired,
  filter: PropTypes.object.isRequired,
  filterKey: PropTypes.string.isRequired,
  subType: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.array, PropTypes.number, PropTypes.string, PropTypes.bool])
};

export default memo(CheckboxFilter);
