/* eslint-disable react/display-name */
/* eslint-disable jsx-a11y/mouse-events-have-key-events */
import React from 'react';
import PropTypes from 'prop-types';
import { moreListTooltipStyles } from '@constants/mui-theme';
import Tooltipable from '@shared/tooltipable';
import Tooltip from '@material-ui/core/Tooltip';
import CountAvatar from '@shared/count-avatar';
import { getAgencyIcon, renderCustomIconAndLabel } from '@utils/icon-utils';
import './conflict-agencies.scss';

class ConflictAgencies extends Tooltipable {
  getIcon = agencyId => {
    const { agencies, agencyTypes } = this.props;
    const agency = agencies[agencyId];
    if (agency) {
      const agencyType = agencyTypes[agency.type];
      const icon = getAgencyIcon(agencyType);
      return (
        <Tooltip title={agency.name}>
          <img src={icon} alt="" styleName="item" />
        </Tooltip>
      );
    }
    return null;
  };

  renderList = list => (
    <div>
      {list.slice(0, 5).map((agencyId, index) => this.renderItem(agencyId, index))}
      {list.length > 5 && (
        <div style={moreListTooltipStyles.seeDetailsStyle}>
          See overlaps detail for complete list
        </div>
      )}
    </div>
  );

  renderItem = (agencyId, index) => {
    const { agencies } = this.props;
    const agency = agencies[agencyId];
    if (agency) {
      return (
        <div key={index} styleName="tooltip-item">
          {renderCustomIconAndLabel(agency.name, this.getIcon(agencyId))}
        </div>
      );
    }
    return null;
  };

  getAvatar = (list, value) => (
    <div
      onMouseOver={this.hoverOn}
      onMouseOut={this.hoverOff}
    >
      <CountAvatar count={value} />
      {this.renderTooltip(this.renderList(list))}
    </div>
  );

  render() {
    const { conflict } = this.props;
    const { agencies } = conflict;
    if (!agencies) {
      return null;
    }
    const list = agencies.split(',');
    return (
      <div styleName="column-renderer-conflict-agencies-container">
        {list.slice(0, 5).map(agencyId => this.getIcon(agencyId))}
        {list.length > 5 && this.getAvatar(list.slice(5), `+${list.length - 5}`)}
      </div>
    );
  }
}

ConflictAgencies.propTypes = {
  agencies: PropTypes.object,
  agencyTypes: PropTypes.object,
  conflict: PropTypes.object
};

ConflictAgencies.defaultProps = {
  tooltipStyle: { ...moreListTooltipStyles.tooltip }
};

export default ConflictAgencies;
