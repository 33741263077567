/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { memo, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import EntityListItem from '@components/shared/list/entity-list-item';

import { getDataTypeConfig } from '@components/config/selectors';
import { viewEntityOnMap } from '@actions/map-actions';

import styles from './record-item.scss';

const RecordItem = ({result}) => {
  const { id, entity_type: entityTypeName, entity } = result;
  const typeConfig = useSelector(state => getDataTypeConfig(state, 'entity', entityTypeName));
  const dispatch = useDispatch();
  const handleClick = useCallback(() => {
    if (typeConfig.name) {
      dispatch(viewEntityOnMap(id, entityTypeName));
    }
  }, [dispatch, typeConfig, id, entityTypeName]);

  return (
    <EntityListItem
      className={styles.recordItem}
      onClick={handleClick}
      entity={entity}
    />
  );
};

RecordItem.propTypes = {
  result: PropTypes.object
};

export default memo(RecordItem);
