/* eslint-disable react/jsx-no-bind */
import React, { useCallback, useContext } from 'react';
import DrawerOverlapCard from '@components/overlap/drawer-overlap-card';
import OverlapContext from '@components/overlap/overlap-context';
import { detailStyles, listItem } from '@constants/mui-theme';
import './drawer-list.scss';

const DrawerList = () => {
  const {
    conflicts,
    data,
    filterEntities,
    getItemId,
    isSelected,
    highlightGeometry,
    loading,
    opportunities,
    setHighlightGeometry
  } = useContext(OverlapContext);

  const toggleHighlightGeometry = useCallback(() => {
    setHighlightGeometry(!highlightGeometry);
  }, [highlightGeometry, setHighlightGeometry]);

  const renderListItem = useCallback(
    (entity, isOpportunity) => (
      <div
        id={getItemId(entity)}
        key={entity.id}
        style={isSelected(entity.id) ? { ...listItem, ...detailStyles.selectedItem } : listItem}
      >
        <DrawerOverlapCard
          entity={entity}
          isOpportunity={isOpportunity}
          toggleHighlightGeometry={toggleHighlightGeometry}
        />
      </div>
    ),
    [getItemId, isSelected, toggleHighlightGeometry]
  );

  const getNestedItems = useCallback((entities, isOpportunity) => filterEntities(entities).map(
    entity => renderListItem(entity, isOpportunity)
  ), [filterEntities, renderListItem]);

  const items = [].concat(
    getNestedItems(conflicts, false),
    getNestedItems(opportunities, true)
  );

  if (items.length === 0 && !loading && data) {
    return (
      <div styleName="no-overlaps">
        No overlaps found
      </div>
    );
  }
  return items;
};

export default DrawerList;
