import React, { memo, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { replace } from 'connected-react-router';
import { fetchCycle } from '@actions/workflow-actions';
import TopBar from '@components/details/top-bar';
import { getTasks } from '@selectors/workflow-selector';
import { goToDefaultTask } from '@utils/workflow-utils';
import Drawer from './drawer';
import Content from './content';
import './cycle.scss';

const Cycle = () => {
  const dispatch = useDispatch();
  const { id, taskId, taskTypeId } = useParams();
  const tasks = useSelector(getTasks);

  const replaceFunc = useCallback(url => {
    dispatch(replace(url));
  }, [dispatch]);

  useEffect(() => {
    if (id) {
      dispatch(fetchCycle(id));
    }
  }, [dispatch, id]);

  useEffect(() => {
    // If there's no task is in the URL redirect to the default task/activity:
    if (!taskId && !taskTypeId && tasks?.length > 0) {
      goToDefaultTask(tasks[0], replaceFunc);
    }
  }, [replaceFunc, taskId, taskTypeId, tasks]);

  return (
    <div styleName="cycle-content-container">
      <TopBar />
      <div styleName="cycle-container">
        <Drawer />
        <Content />
      </div>
    </div>
  );
};

export default memo(Cycle);
