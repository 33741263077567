import { combineReducers } from 'redux';
import auth from './auth-reducer';
import attachments from './attachment-reducer';
import calendar from './calendar-reducer';
import config from '@components/config/reducer';
import dashboard from './dashboard-reducer';
import dataDetail from './data-detail-reducer';
import dataTables from './data-table-reducer';
import dataTypes from './data-types-reducer';
import dialogs from './dialogs-reducer';
import errors from './error-reducer';
import filters from '@components/filters/reducer';
import groups from './groups-reducer';
import map from './map-reducer';
import markers from './markers-reducer';
import entities from './entities-reducer';
import messages from './messages-reducer';
import layers from './layers-reducer';
import logs from './logs-reducer';
import confirmation from './confirmation-reducer';
import notifications from './notifications-reducer';
import settings from './settings-reducer';
import tooltips from './tooltips-reducer';
import tracking from './tracking-reducer';
import workflow from './workflow-reducer';
import { rootAuthReducer } from './root/auth';
import { rootMarkerReducer } from './root/marker';
import { connectRouter } from 'connected-react-router';

// Read: https://github.com/supasate/connected-react-router/blob/master/FAQ.md#how-to-migrate-from-v4-to-v5v6
//
const createRootReducer = history => combineReducers({
  router: connectRouter(history),
  auth,
  attachments,
  calendar,
  config,
  dashboard,
  dataDetail,
  dataTables,
  dataTypes,
  dialogs,
  errors,
  filters,
  groups,
  map,
  markers,
  entities,
  messages,
  logs,
  confirmation,
  notifications,
  settings,
  tooltips,
  tracking,
  layers,
  workflow
});

const rootReducer = history => (state, action) => {
  // Execute all the root reducers
  //
  // (there are reducers that need access to all branches of the state, while
  // normal reducers, defined above, are contained by their name).
  let newState = rootAuthReducer(state, action);

  newState = createRootReducer(history)(newState, action);

  // Run the marker reducer last, since it not only must act
  // on a global state tree, but it needs values calculated
  // by other reducers.
  return rootMarkerReducer(newState, action);
};

export default rootReducer;
