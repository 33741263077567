import React, { memo, useCallback, useContext, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { clearLogs, fetchLogsData } from '@actions/logs-actions';
import { getDashboardBackgroundColor } from '@constants/config';
import DetailsContext from '@components/workflow/details-context';
import LogItem from '@shared/comments/log-item';
import DotmapsLoader from '@shared/dotmaps-loader';
import './comments-body.scss';

const CommentsList = () => {
  const dispatch = useDispatch();
  const { activity, scrollTop } = useContext(DetailsContext);
  const { list: items, loading, next, reload } = useSelector(state => state.logs);

  const fetchLogs = useCallback(() => {
    dispatch(clearLogs());
    dispatch(fetchLogsData('workflow', 'taskactivity', activity?.id, next, { filters: 'comments_only_filters' }));
  }, [activity?.id, dispatch, next]);

  // Initial load.
  useEffect(() => fetchLogs(), [fetchLogs]);

  // Reload if scrollTop changes:
  useEffect(() => {
    // 'scrollTop' will only be set when we reach the containers end, so if it changes
    // load the next batch of log entries:
    if (!loading && next && scrollTop) {
      fetchLogs();
    }
  }, [fetchLogs, loading, next, scrollTop]);

  // Fetch again if 'reload' changes:
  useEffect(() => {
    if (reload) {
      fetchLogs();
    }
  }, [fetchLogs, reload]);

  return (
    <div styleName="list-container">
      {items.map((item, index) => <LogItem key={index} item={item} />)}
      <DotmapsLoader color={getDashboardBackgroundColor()} display={loading && !next} />
      {/* When we load more through scrolling, show the indicator at the bottom. */}
      {loading && next && (
        <div styleName="spinner">
          <DotmapsLoader color={getDashboardBackgroundColor()} display={loading && next} />
        </div>
      )}
    </div>
  );
};

export default memo(CommentsList);
