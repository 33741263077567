import React, { memo, useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import DefaultDate from '@shared/formatted-date-helper';
import EntityChips from '@shared/list-card/entity-chips';
import EntityDataCard from '@shared/ui-library/card/entity-data-card';
import EntityTypeIcon from '@icons/entity-type-icon';

import { daysDiff } from '@utils/map-utils';

import { getHoverEntityId } from '@selectors/map-selector';
import { setOverlapHoverEntity, openEntityTray } from '@actions/map-actions';

const EntityCard = ({
  entity,
  trayHover = false,
  ...passThroughProps
}) => {
  const dispatch = useDispatch();
  const {
    id,
    name,
    segments,
    start_date: startDate,
    end_date: endDate,
    type_name: typeName,
    entityType
  } = entity;
  const selectedEntityId = useSelector(state => getHoverEntityId(state));
  const selected = id === selectedEntityId;

  const selectEntity = useCallback(() => dispatch(setOverlapHoverEntity(entity)), [dispatch, entity]);
  const deselectEntity = useCallback(() => dispatch(setOverlapHoverEntity()), [dispatch]);
  const handleClick = useMemo(() => {
    if (selected) {
      return deselectEntity;
    }
    return selectEntity;
  }, [selected, selectEntity, deselectEntity]);

  const handleMouseEnter = useMemo(() => {
    if (trayHover) {
      return () => dispatch(setOverlapHoverEntity(entity));
    }
    return null;
  }, [dispatch, entity, trayHover]);

  const handleMouseExit = useMemo(() => {
    if (trayHover) {
      return () => dispatch(setOverlapHoverEntity());
    }
    return null;
  }, [dispatch, trayHover]);

  const openEntity = useCallback(
    () => {
      const segment = segments[0];
      const entityProps = {
        id,
        position: segment.center ? {lat: segment.center[1], lng: segment.center[0]} : null,
        type: typeName || entityType
      };
      dispatch(openEntityTray({...entityProps, fitEntityBounds: true}));
    },
    [dispatch, id, segments, typeName, entityType]
  );
  return (
    <EntityDataCard
      selected={selected}
      icon={
        <EntityTypeIcon type={typeName || entityType} size="1.125rem"/>
      }
      onSelect={handleClick}
      onViewDetails={openEntity}
      title={name}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseExit}
      {...passThroughProps}
    >
      {startDate && endDate &&
        <span>
          <DefaultDate value={startDate} valueFormat="YYYY-MM-DD"/>–<DefaultDate value={endDate} valueFormat="YYYY-MM-DD"/>
          <span>{` (${daysDiff(startDate, endDate)} days)`}</span>
        </span>
      }
      <EntityChips entity={entity} />
    </EntityDataCard>
  );
};

EntityCard.propTypes = {
  entity: PropTypes.object,
  trayHover: PropTypes.bool
};

export default memo(EntityCard);
