//
// This file contains browser detection functions.
//
// It's generally agreed that browser detection is bad and feature detection
// should be used instead, but there are cases in which we must do it.
//
// In our case old IE versions won't work even with a polyfill, thus we need
// an isIE() method to apply workarounds.
//
// For old Chrome and Firefox versions (and also for old IE, non Edge ones),
// the new code doesn't work either, due to lack of support for modern Javascript
// features (ES2015+). But on these cases, I use feature detection, if we
// don't have X feature, then load babel-polyfill to have it.
// (this also works for any old browser, Safari or Opora, which lacks those
// features).


// Detects old Internet Explorer versions (IE 6-11).
//
// More information on these URLs:
//
//     https://github.com/mbasso/react-browser-detection/blob/master/src/index.js
//     https://stackoverflow.com/questions/9847580/how-to-detect-safari-chrome-ie-firefox-and-opera-browser
//     https://docs.microsoft.com/en-us/scripting/javascript/reference/at-cc-on-statement-javascript
export const isOldIE = () => {
  // Test for conditional compilation "cc_on".
  // It works for IE 10 standards mode and earlier
  // but doesn't for IE 11 and Windows 8.x Store apps.
  return /*@cc_on!@*/false ||
         !!document.documentMode;  // documentMode exists only in IE11.
};

// Detects if it's an IE browser (version 6-11 and Edge too):
export const isIE = () => {
  return isOldIE() ||
         !!window.StyleMedia;  // This is for Microsoft Edge.
};

// Detect if the browser is old and lacks ES2015 features:
export const isES2015Supported = () => {
  return window.Promise &&
         window.fetch &&
         window.Symbol &&
         Object.assign;
};
