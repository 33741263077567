import React from 'react';
import PropTypes from 'prop-types';
import { Icon as MUIIcon } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { colors } from '@constants/colors';

const useStyles = makeStyles({
  root: ({ color, style, invisible }) => ({
    color: color || colors.opacity.black0_7,
    fontSize: '24px',
    height: 'auto',
    paddingLeft: '0',
    visibility: invisible ? 'hidden' : 'inherit',
    ...style
  })
});

export const Icon = props => {
  const classes = useStyles(props);

  // eslint-disable-next-line id-length, no-unused-vars
  const { color: _, ...data } = props;

  return (
    <MUIIcon {...data} classes={{ root: classes.root, ...props.classes }} />
  );
};

Icon.propTypes = {
  classes: PropTypes.object,
  color: PropTypes.string
};

export default Icon;
