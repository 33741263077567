import React from 'react';
import PropTypes from 'prop-types';
import './edit-schedule-dialog.scss';

// We need to wrap the date divider text with these CSS classes, to achieve the same alignment
// effect than the date/time picker components use (i.e. align vertically the divider with the
// pickers).
const DateDivider = ({ text }) => (
  <div styleName="dates-divider-wrapper" style={{width: `${0.5 * text.length}rem`}}>
    <div styleName="dates-divider">{text}</div>
  </div>
);

DateDivider.propTypes = {
  text: PropTypes.string
};

export default DateDivider;
