import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { updateDataField } from '@actions/data-detail-actions';
import { autocompleteSearchStyles, detailEdit } from '@constants/mui-theme';
import DataTypesSelect from '@forms/data-types-select';
import { useOnChangeHandlers } from '@hooks/workflow-hooks';
import FormattedDatePicker from '@shared/formatted-date-picker';
import InputSearch from '@shared/input-search';
import {
  renderRoleListItem,
  renderUserListItem,
  simpleToOptions
} from '@utils/autocomplete-utils';
import { squareChipDeleteIcon } from '@utils/shared-utils';
import { getFieldValue } from '@utils/workflow-utils';
import AttachmentsField from './inline-attachments-field';

const CoreEditField = ({ field, isPreview, label, required, source }) => {
  const dispatch = useDispatch();
  const { data, error } = useSelector(state => state.dataDetail);
  const { user: users, user_roles: roles } = useSelector(state => state.dataTypes);

  const onChange = useCallback(
    (event, value) => {
      if (!isPreview) {
        dispatch(updateDataField(field, value));
      }
    },
    [dispatch, field, isPreview]
  );

  const { onSelectChange, onDateChange, onChipSelectChange } = useOnChangeHandlers(onChange);

  const newLabel = required ? `${label}*` : label;
  const value = isPreview ? getFieldValue(null, field) : getFieldValue(data, field);
  const errors = error?.[field] || null;
  const commonProps = {
    errorText: Array.isArray(errors) ? errors.join(', ') : null,
    errorStyle: { ...detailEdit.errors },
    floatingLabelText: newLabel,
    floatingLabelStyle: { whiteSpace: 'nowrap' },
    fullWidth: true,
    id: field,
    name: field,
    value
  };

  if (field === 'activities') {
    return (
      <DataTypesSelect
        {...commonProps}
        dataName={source}
        multiple
        all={{ label: 'All Agencies' }}
        onChange={onSelectChange}
      />
    );
  } else
    if (field === 'due_date') {
      return <FormattedDatePicker {...commonProps} onChange={onDateChange} />;
    } else
      if (field === 'owner' || field === 'roles') {
        const multiSelectProps = {
          ...autocompleteSearchStyles.normal,
          chipSize: 'medium',
          errors,
          label: newLabel,
          onChange: onChipSelectChange,
          storePath: ['dataTypes', source],
          values: value
        };
        if (field === 'owner') {
          multiSelectProps.limit = 1;
          multiSelectProps.avatarProps = { type: 'user' };
          // eslint-disable-next-line no-unused-vars
          multiSelectProps.renderOption = (option, state) => renderUserListItem(option, users, 'medium');
        } else
          if (field === 'roles') {
            multiSelectProps.squareChips = true;
            multiSelectProps.toOptions = simpleToOptions;
            // eslint-disable-next-line no-unused-vars
            multiSelectProps.renderOption = (option, state) => renderRoleListItem(option, roles);
            multiSelectProps.deleteIcon = squareChipDeleteIcon;
          }
        return <InputSearch {...multiSelectProps} />;
      } else
        // These are response fields, we don't need them to have any functionality in this component
        // since they are for preview only here (the real response fields are rendered on the activities page
        // and they render in a different way, since they are inline fields).
        if (field === 'attachments') {
          return <AttachmentsField activity={{ id: '', task: {}}} attachments={[]} label={newLabel} />;
        } else
          if (field === 'response' || field === 'assignee') {
            return <DataTypesSelect {...commonProps} dataName={source} multiple onChange={() => {}} />;  // eslint-disable-line react/jsx-no-bind
          }
  return null;
};

CoreEditField.propTypes = {
  field: PropTypes.string,
  isPreview: PropTypes.bool,
  label: PropTypes.string,
  required: PropTypes.bool,
  source: PropTypes.string
};

export default CoreEditField;
