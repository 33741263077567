/* eslint-disable react/jsx-no-bind */
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty } from 'lodash';
import TextField from 'material-ui/TextField';
import { saveAttachment } from '@actions/attachment-actions';
import { closeDashboardDialog } from '@actions/dashboard-actions';
import * as dialog from '@constants/dialogs';
import Dialog from '@shared/dialogs/dialog';
import DialogActions from '@shared/dialogs/dialog-actions';
import { formatDate } from '@utils/shared-utils';

const EditFileDialog = () => {
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const dispatch = useDispatch();
  const { activeDialogs, dialogParams } = useSelector(state => state.dashboard);
  const {
    id,
    file_name,
    description: file_description,
    created,
    created_by,
    modified,
    modified_by
  } = dialogParams[dialog.EDIT_FILE]?.data || {};

  useEffect(() => {
    setName(file_name);
    setDescription(file_description);
  }, [id, file_description, file_name, setDescription, setName]);

  const onClose = useCallback(
    () => {
      setName('');
      setDescription('');
      dispatch(closeDashboardDialog(dialog.EDIT_FILE));
    },
    [dispatch]
  );

  const onSave = useCallback(
    () => {
      dispatch(saveAttachment(id, { name, description }));
      onClose();
    },
    [onClose, description, dispatch, id, name]
  );

  if (!activeDialogs[dialog.EDIT_FILE]) {
    return null;
  }

  const buildAuditLabel = (date, user) => {
    const by = user ? `by ${user?.name}` : '';
    return `${formatDate(date)} ${by}`;
  };

  const uploaded = buildAuditLabel(created, created_by);
  const lastModified = buildAuditLabel(modified, modified_by);

  return (
    <Dialog
      actions={
        <DialogActions
          disabledSubmit={isEmpty(name)}
          onCancel={onClose}
          onSubmit={onSave}
          submitLabel="SAVE"
        />
      }
      dividers
      onClose={onClose}
      title={name}
    >
      <TextField
        floatingLabelText="Name *"
        fullWidth name="name"
        onChange={(event, value) => setName(value)} value={name}
      />
      <TextField
        floatingLabelText="Description"
        fullWidth name="description"
        multiLine
        onChange={(event, value) => setDescription(value)}
        rowsMax={10}
        value={description}
      />
      <TextField
        disabled
        floatingLabelText="Uploaded"
        fullWidth
        value={uploaded}
      />
      <TextField
        disabled
        floatingLabelText="Last modified"
        fullWidth
        value={lastModified}
      />
    </Dialog>
  );
};

export default EditFileDialog;
