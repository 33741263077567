import React from 'react';
import PropTypes from 'prop-types';
import Skeleton from '@material-ui/lab/Skeleton';
import DefaultDate from '@shared/formatted-date-helper';
import { enDash } from '@utils/shared-utils';
import './fields.scss';

const renderContent = (type, value) => {
  if (type === 'date') {
    return <DefaultDate value={value} />;
  }

  if (type === 'boolean') {
    return value === 'true' || value === true ? 'Yes' : 'No';
  }

  return value || enDash;
};

const ReadOnlyField = ({ label, loaded, type, value }) => {
  const content = renderContent(type, value);
  return (
    <div data-testid="field" styleName="field-container">
      <div data-testid="field-label" styleName="field-label">{label}</div>
      <div styleName="field-content">
        {!loaded && <Skeleton variant="text" width="100%" height="1.25rem" />}
        {loaded && content}
      </div>
    </div>
  );
};

ReadOnlyField.propTypes = {
  label: PropTypes.string,
  loaded: PropTypes.bool,
  type: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
};

export default ReadOnlyField;
