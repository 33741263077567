/* eslint-disable react/jsx-no-bind */
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { closeDashboardDialog } from '@actions/dashboard-actions';
import * as dialog from '@constants/dialogs';
import Dialog from '@shared/dialogs/dialog';
import DialogActions from '@shared/dialogs/dialog-actions';

const SubmitPublicFormDialog = () => {
  const dispatch = useDispatch();

  const dashboard = useSelector(state => state.dashboard);
  const { activeDialogs } = dashboard;
  const dialogParams = dashboard.dialogParams[dialog.SUBMIT_PUBLIC_FORM];
  const isDialogActive = activeDialogs[dialog.SUBMIT_PUBLIC_FORM];

  if (!isDialogActive) {
    return null;
  }

  const onClose = () => {
    dispatch(closeDashboardDialog(dialog.SUBMIT_PUBLIC_FORM));
  };

  const onSubmit = () => {
    const { submitForm } = dialogParams;
    submitForm();
    onClose();
  };

  return (
    <Dialog
      actions={
        <DialogActions
          onCancel={onClose}
          onSubmit={onSubmit}
          submitLabel="SUBMIT"
        />
      }
      title="Submit"
    >
      Make sure you have the correct information provided. You cannot view or edit the information once it’s submitted.
    </Dialog>
  );
};

export default SubmitPublicFormDialog;
