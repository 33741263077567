import React from 'react';
import PropTypes from 'prop-types';
import CycleCards from '@shared/cycle-cards';
import './details-table-cycles-list.scss';

const DetailsTableCyclesList = ({ entity: { cycles = [] } }) => (
  <div styleName="cycles-container">
    <div styleName="header">
      Workflows{cycles.length > 0 && <div styleName="cycle-count">{cycles.length}</div>}
    </div>
    {cycles.length === 0 &&
      <div styleName="empty-list">No workflow started</div>
    }
    {cycles.length > 0 && <CycleCards cycles={cycles} />}
  </div>
);

DetailsTableCyclesList.propTypes = {
  entity: PropTypes.object
};

export default DetailsTableCyclesList;
