import {
  DATATABLE_FETCH_ROWS_SUCCESS,
  DATATABLE_SET_SELECTED_ROW,
  DATATABLE_TOGGLE_LOADER,
  EXPORT_DOWNLOADING,
  EXPORT_DOWNLOADING_FINISHED,
  SET_DATATABLE_FILTERS
} from '@constants/action-types';
import axios from 'axios';
import * as R from 'ramda';
import FileDownload from 'js-file-download';
import { getAPIRequestUrl, getDetailAPIRequestUrl, getTaskAPIRequestUrl } from '@constants/endpoints';
import { processAttributes, processCategories } from '@utils/filter-utils';
import { getUserId, getAgencyId } from '@utils/permission-utils';
import { getServerErrorAction } from './shared-actions';

export const fetchDatatableRowsSuccess = (payload, dataType, subType) => ({
  type: DATATABLE_FETCH_ROWS_SUCCESS,
  payload,
  dataType,
  subType
});

export const toggleLoader = (dataType, subType, payload) => dispatch => {
  dispatch({ type: DATATABLE_TOGGLE_LOADER, dataType, payload, subType });
  return Promise.resolve();
};

// We should only have one datatable open at a time so we don't need to track the cancel token for multiple requests.
let datatableSource = axios.CancelToken.source();

const getDataURL = (type, subType, format) => {
  if (type === 'activity') {
    const value = subType === 'agency' ? getAgencyId() : getUserId();
    return getTaskAPIRequestUrl('task_activity', subType, value, format);
  }
  if (type === 'task') {
    const value = subType === 'agency' ? getAgencyId() : getUserId();
    return getTaskAPIRequestUrl('task', subType, value, format);
  }

  return getAPIRequestUrl(type, null, format);
};

export const fetchDatatableData = (type, subType, filters) => dispatch => {
  return dispatch(toggleLoader(type, subType, true)).then(async () => {
    const url = getDataURL(type, subType, 'format=json');
    datatableSource.cancel();
    datatableSource = axios.CancelToken.source();
    const params = {
      ...processAttributes(processCategories(type, filters)),
      'data-table': 'True'
    };
    if (subType) {
      params.type = subType;
    }
    const request = axios.get(
      url,
      { cancelToken: datatableSource.token, params }
    );
    try {
      const response = await request;
      dispatch(fetchDatatableRowsSuccess(response, type, subType));
    } catch (error) {
      if (!axios.isCancel(error)) {
        const { code, message, name } = error;
        // Strip properties that can cause state mutations in the store:
        const strippedError = { code, message, name };
        dispatch(getServerErrorAction(type, strippedError));
      }
    }
  });
};

const newFilterValues = (type, subType, filters) => ({
  type: SET_DATATABLE_FILTERS,
  payload: filters,
  dataType: type,
  subType
});

export const setDatatableFilter = (type, subType, filters) => dispatch => dispatch(newFilterValues(type, subType, filters));

// Sets a flag on the store to tell we are downloading a CSV file:
const setDownloading = () => dispatch => {
  dispatch({ type: EXPORT_DOWNLOADING });
  return Promise.resolve();
};

const setFinishDownloading = () => dispatch => {
  dispatch({ type: EXPORT_DOWNLOADING_FINISHED });
  return Promise.resolve();
};

export const downloadCSVExport = (type, subType, filters, id = null) => dispatch => dispatch(setDownloading()).then(() => {
  let url = '';
  if (id) {
    const typeURL = type === 'activity' ? 'task_activity' : type;
    url = getDetailAPIRequestUrl(typeURL, id);
  } else {
    url = getDataURL(type, subType, '');
  }
  const cleanFilters = processAttributes(
    processCategories(type, R.omit(['limit', 'offset', 'ordering', 'search'], filters))
  );
  const params = { ...cleanFilters, format: 'csv', pagination: 'off' };
  if (subType && !['agency', 'batch', 'user'].includes(subType)) {
    params.type = subType;
  }
  const request = axios.get(url, { params });
  request.then(
    data => dispatch(setFinishDownloading()).then(() => {
      const filename = subType && subType !== type ? `${type}-${subType}` : type;
      const suffix = id ? `-${id}` : '';
      FileDownload(data.data, `${filename}-export${suffix}.csv`);
    }),
    error => dispatch(setFinishDownloading()).then(() => {
      dispatch(getServerErrorAction(type, error));
    })
  );
});

export const downloadCSVFromURL = (url, filename, type) => dispatch => dispatch(setDownloading()).then(() => {
  const request = axios.get(url);
  request.then(
    data => dispatch(setFinishDownloading()).then(() => {
      FileDownload(data.data, filename);
    }),
    error => dispatch(setFinishDownloading()).then(() => {
      dispatch(getServerErrorAction(type, error));
    })
  );
});

export const setSelectedRow = id => dispatch => dispatch({ type: DATATABLE_SET_SELECTED_ROW, id });
