import React from 'react';

const initialState = {
  emptyBoard: false,
  allCards: [],
  setAllCards: () => {},
  updateCardState: () => {},
  updateAllCardsState: () => {},
  setShowCustomize: () => {},
  lgColumn: [],
  smColumn: [],
  updateLayout: () => {},
  updatingLayout: false,
  handleAddEmbedCard: () => {},
  handleDeleteEmbedCard: () => {},
  handleEditEmbedCard: () => {},
  loadingEmbedCard: false
};

const DashboardContext = React.createContext(initialState);

export default DashboardContext;
