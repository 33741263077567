/* eslint-disable react/display-name */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';

import Divider from '@material-ui/core/Divider';
import { RadioButton, RadioButtonGroup } from 'material-ui/RadioButton';

import { openDashboardDialog } from '@actions/dashboard-actions';
import { setMapFilters } from '@actions/map-actions';

import { dotmapsBlue } from '@constants/colors';
import * as dialog from '@constants/dialogs';
import { filterStyles } from '@constants/mui-theme';

import FilterCheckbox from '@shared/filter-checkbox';

import {
  decodeDateFilters,
  generateDateFilters,
  selectedDateFilterValue
} from '@utils/date-utils';
import { shallowEqual } from '@utils/react-utils';
import { formatDate } from '@utils/shared-utils';

import { BaseTrayFilter } from './base-filter';

import './filter.scss';

class DateFilter extends BaseTrayFilter {
  getToggle = () => {};  // Disable toggle for the date filter

  getIconStyle = () => 'icon no-toggle';

  getIconColor = () => dotmapsBlue;

  getLabel = () => {
    const { start, end } = this.props;
    return (
      <div styleName="bold">
        {start && end && (
          <div>
            {formatDate(start)} - {formatDate(end)}
          </div>
        )}
        {(!start || !end) && 'Any time'}
      </div>
    );
  };

  shouldComponentUpdate(nextProps, nextState) {
    return (
      !shallowEqual(nextProps.start, this.props.start) ||
      !shallowEqual(nextProps.end, this.props.end) ||
      !shallowEqual(nextProps.contain, this.props.contain) ||
      !shallowEqual(nextProps.undated, this.props.undated) ||
      !shallowEqual(nextProps.undatedOnly, this.props.undatedOnly) ||
      this.state !== nextState
    );
  }

  filterChange = (event, value) => {
    const { contain } = this.props;
    let end = null;
    let start = null;
    switch (value) {
    case 'today': {
      start = moment();
      end = moment();
      break;
    }
    case 'tomorrow': {
      start = moment().add(1, 'days');
      end = moment().add(1, 'days');
      break;
    }
    case 'next7': {
      start = moment();
      end = moment().add(7, 'days');
      break;
    }
    case 'next30': {
      start = moment();
      end = moment().add(30, 'days');
      break;
    }
    case 'next90': {
      start = moment();
      end = moment().add(90, 'days');
      break;
    }
    case 'nextYear': {
      start = moment();
      end = moment().add(365, 'days');
      break;
    }
    case 'next3Years': {
      start = moment();
      end = moment().add(1095, 'days');
      break;
    }
    case 'custom': {
      // Use last assigned start/end dates:
      start = this.props.start;
      end = this.props.end;
      break;
    }
    default:
      break;
    }
    this.props.setMapFilters(generateDateFilters({ start, end, contain }));
  };

  getRadio = (value, label, button) => (
    <RadioButton
      value={value}
      label={(
        <div styleName="radio">
          {button}
          <div styleName="label">
            {label}
          </div>
        </div>
      )}
      {...filterStyles.dateRadio}
    />
  );

  toggleContain = () => {
    const { start, end, contain, undated, undatedOnly } = this.props;
    this.props.setMapFilters(generateDateFilters({ start, end, contain: !contain, undated, undatedOnly }));
  };

  toggleUndated = () => {
    const { start, end, contain, undated, undatedOnly } = this.props;
    const newUndatedOnly = undated ? false : undatedOnly;
    this.props.setMapFilters(generateDateFilters({ start, end, contain, undated: !undated, undatedOnly: newUndatedOnly }));
  };

  toggleUndatedOnly = () => {
    const { start, end, contain, undated, undatedOnly } = this.props;
    this.props.setMapFilters(generateDateFilters({ start, end, contain, undated, undatedOnly: !undatedOnly }));
  };

  openCustomDateDialog = () => this.props.openDashboardDialog(dialog.DATE_RANGE);

  getContent = () => {
    const {
      contain,
      selected,
      undated,
      undatedOnly
    } = this.props;
    const customEditButton = (
      <div styleName="button custom-button"
        onClick={this.openCustomDateDialog}
        role="presentation">
        EDIT
      </div>
    );
    return (
      <div styleName="content-list-container radio-button-group-container">
        <RadioButtonGroup
          name="date"
          defaultSelected={selected}
          valueSelected={selected}
          onChange={this.filterChange}
          {...filterStyles.radioGroup}
        >
          {this.getRadio('today', 'Today')}
          {this.getRadio('tomorrow', 'Tomorrow')}
          {this.getRadio('next7', 'Next 7 days')}
          {this.getRadio('next30', 'Next 30 days')}
          {this.getRadio('next90', 'Next 90 days')}
          {this.getRadio('nextYear', '1 year')}
          {this.getRadio('next3Years', '3 years')}
          {this.getRadio('custom', 'Custom range', customEditButton)}
        </RadioButtonGroup>
        <div styleName="divider">
          <Divider />
        </div>
        <div styleName="list-item">
          <div styleName="checkbox">
            <FilterCheckbox checked={contain} onChange={() => this.toggleContain()} />
          </div>
          <div styleName="label">
            Show fully contained records only
          </div>
        </div>
        <div styleName="list-item">
          <div styleName="checkbox">
            <FilterCheckbox checked={undated} onChange={this.toggleUndated} />
          </div>
          <div styleName="label">
              Show undated records
          </div>
        </div>
        <div styleName="list-item indent">
          <div styleName="checkbox">
            <FilterCheckbox disabled={!undated} checked={undatedOnly} onChange={this.toggleUndatedOnly} />
          </div>
          <div styleName="label">
              Show undated only
          </div>
        </div>
      </div>
    );
  };
}

DateFilter.propTypes = {
  contain: PropTypes.bool,
  end: PropTypes.string,
  openDashboardDialog: PropTypes.func,
  selected: PropTypes.string,
  setMapFilters: PropTypes.func,
  start: PropTypes.string,
  undated: PropTypes.bool,
  undatedOnly: PropTypes.bool
};

const mapStateToProps = state => {
  const { start, end, contain, undated, undatedOnly } = decodeDateFilters(state.map.filters);
  const selected = selectedDateFilterValue(start, end);

  return { start, end, contain, selected, undated, undatedOnly};
};

const mapDispatchToProps = { openDashboardDialog, setMapFilters };

export default connect(mapStateToProps, mapDispatchToProps)(DateFilter);
