import axios from 'axios';

import { getServerErrorAction } from '@actions/shared-actions';
import { fetchDatatableRowsSuccess, toggleLoader } from '@actions/data-table-actions';

import { DATA_TYPES } from '@components/config/constants';
import { BASE_URL } from '@constants/endpoints';

let source = axios.CancelToken.source();

export const fetchPortalData = (dataType, subType, filterParams, orderParams, pageParams) => async dispatch => {
  const typeInfo = DATA_TYPES[dataType];
  if (!typeInfo) {
    return;
  }

  await dispatch(toggleLoader(dataType, subType, true));

  source.cancel();
  source = axios.CancelToken.source();
  const params = {
    ...filterParams,
    ...orderParams,
    ...pageParams,
    'data-table': 'True'
  };
  if (typeInfo.subType) {
    params[typeInfo.subType] = subType;
  }
  const request = axios.get(
    `${BASE_URL}${typeInfo.api}`,
    { cancelToken: source.token, params }
  );
  try {
    const response = await request;
    dispatch(fetchDatatableRowsSuccess(response, dataType, subType));
  } catch (error) {
    if (!(error instanceof axios.Cancel)) {
      dispatch(getServerErrorAction(dataType, error));
    }
  }
};
