import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import FilePreviewHeader from './file-preview-header';
import FilePreviewDisplay from './file-preview-display';
import { closeFilePreview } from '@actions/attachment-actions';
import './file-preview.scss';

class FilePreview extends Component {
  constructor(props, state) {
    super(props, state);
    this.state = {
      index: 0
    };
    this.closePreview = this.closePreview.bind(this);
  }
  UNSAFE_componentWillMount() {
    this.setState({ open: true });
  }
  componentDidMount() {
    window.addEventListener('keydown', key => {
      switch (key.code) {
      case 'ArrowLeft':
        this.setState({
          index: this.state.index === 0
            ? this.props.attachments.length - 1
            : this.state.index - 1
        });
        break;
      case 'ArrowRight':
        this.setState({
          index: this.state.index === this.props.attachments.length - 1
            ? 0
            : this.state.index + 1
        });
        break;
      default:
        // do nothing;
      }
    });
  }
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.index < 0 && nextProps.index >= 0) {
      this.setState({ index: nextProps.index });
    }
  }
  getCurrentAsset() {
    if (
      this.props.attachments && this.props.attachments.length > this.state.index
    ) {
      return this.props.attachments[this.state.index];
    }
    return null;
  }
  closePreview() {
    this.props.closeFilePreview();
  }
  render() {
    if (
      !this.props.attachments ||
      this.props.attachments.length === 0 ||
      this.props.index < 0
    ) {
      return null;
    }
    const asset = this.getCurrentAsset();
    return (
      <div styleName="file-preview-container">
        <FilePreviewHeader
          fileName={asset.file_name}
          total={this.props.attachments.length}
          current={this.state.index + 1}
          assetUrl={asset.signed_url}
          downloadUrl={asset.signed_download_url}
          closePreview={this.closePreview}
        />
        <FilePreviewDisplay asset={asset} />
      </div>
    );
  }
}

FilePreview.propTypes = {
  attachments: PropTypes.array,
  closeFilePreview: PropTypes.func,
  contentType: PropTypes.string,
  index: PropTypes.number,
  objectId: PropTypes.number
};

FilePreview.defaultProps = {
  attachments: [],
  index: -1,
  objectId: null,
  contentType: null
};

const mapStateToProps = state => {
  const { attachments, contentType, objectId, index } = state.attachments;
  return {
    attachments: attachments[contentType + objectId],
    contentType,
    index,
    objectId
  };
};

export default connect(mapStateToProps, { closeFilePreview })(FilePreview);
