import React from 'react';
import { useSelector } from 'react-redux';
import { getGroupEntityCount } from '@utils/entity-utils';
import './boundary-entities.scss';

const BoundaryEntities = () => {
  const { loading, polygonEntities } = useSelector(state => state.groups.edit);

  if (loading || !polygonEntities || !polygonEntities.entities) {
    return null;
  }

  const entities = getGroupEntityCount(polygonEntities.entities);

  if (entities.length === 0) {
    return (
      <div styleName="container">
        <div styleName="title">There are no records within boundary</div>
      </div>
    );
  }

  return (
    <div styleName="container">
      <div styleName="title">Records within boundary:</div>
      <br />
      {entities.map(entity => (
        <div key={entity.label} styleName="entity">
          {entity.label}: {entity.value}
        </div>
      ))}
    </div>
  );
};

export default BoundaryEntities;
