import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { includes } from 'lodash';
import { dismissGanttDialog } from '@actions/groups-actions';
import Button from '@material-ui/core/Button';
import Dialog from '@shared/dialogs/dialog';

const WarningDialog = ({ dialogType, groupId, text }) => {
  const dispatch = useDispatch();
  const { dialogs } = useSelector(state => state.groups.gantt);
  const dismissed = dialogs[dialogType];
  const open = !includes(dismissed, groupId);

  const onClose = useCallback(
    () => dispatch(dismissGanttDialog(dialogType, groupId)),
    [dialogType, dispatch, groupId]
  );

  return (
    <Dialog
      actions={
        <Button color="primary" onClick={onClose}>OK</Button>
      }
      open={open}
    >
      {text}
    </Dialog>
  );
};

WarningDialog.propTypes = {
  dialogType: PropTypes.number,
  dismissGanttDialog: PropTypes.func,
  groupId: PropTypes.string,
  text: PropTypes.string
};

export default WarningDialog;
