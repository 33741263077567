/* eslint-disable react/jsx-no-bind */
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import { showConfirmationDialog } from '@actions/confirmation-actions';
import { getConfirmableDeleteAndNavigateAction } from '@actions/data-detail-actions';
import * as colors from '@constants/colors';
import IconMenu from '@shared/icon-menu';
import IconMenuItem from '@shared/icon-menu-item';
import { getDashboardBackURL } from '@utils/navigation-utils';
import { canDeleteEntities } from '@utils/permission-utils';

const TopBarActionWorkflowMenu = () => {
  const { data } = useSelector(state => state.dataDetail);
  const location = useLocation();
  const dispatch = useDispatch();

  if (data) {
    const { agency } = data;

    const deleteItem = () => {
      const source = getDashboardBackURL(location, 'workflow');
      dispatch(showConfirmationDialog(
        'Delete workflow?',
        <span>
          This workflow template will no longer be available to create new workflow.<br />
          However, you can still work with tasks that already exist.
        </span>,
        getConfirmableDeleteAndNavigateAction('workflow', 'workflow', data.id, source)
      ));
    };

    if (canDeleteEntities(agency, 'workflow')) {
      return (
        <IconMenu iconButtonStyles={{ padding: 0, margin: '0 0 0 1rem' }}>
          <IconMenuItem key="delete" icon={<DeleteOutlineIcon htmlColor={colors.dotmapsRed} />} onClick={() => deleteItem()}>
            <div style={{ color: colors.dotmapsRed }}>DELETE</div>
          </IconMenuItem>
        </IconMenu>
      );
    }
  }
  return null;
};

export default TopBarActionWorkflowMenu;
