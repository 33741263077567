import React from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import * as colors from '@constants/colors';
import DotmapsLoader from '@shared/dotmaps-loader';

const LoadingIndicator = () => {
  const { id } = useParams();
  const { data } = useSelector(state => state.dataDetail);
  if (!data && id) {
    return <DotmapsLoader display color={colors.dotmapsGreen} />;
  }
  return null;
};

export default LoadingIndicator;
