/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/mouse-events-have-key-events */
import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import { getSegmentTitle } from '@utils/segment-schedule/common';
import './location-row.scss';

const LocationRow = ({
  edit, onDelete, onSelect, segment, selectedSegmentId
}) => {
  const [hovered, setHovered] = useState(false);

  const hoverOn = useCallback(() => setHovered(true), [setHovered]);
  const hoverOff = useCallback(() => setHovered(false), [setHovered]);
  const deleteLocation = useCallback(() => onDelete(segment.id), [segment, onDelete]);
  const selectLocation = useCallback(() => onSelect(segment.id), [segment, onSelect]);

  const active = selectedSegmentId === segment.id;
  const style = `location-row-container ${active ? 'active-row' : ''}`;

  return (
    <div onMouseOver={hoverOn} onMouseOut={hoverOff} styleName={style}>
      <div
        onClick={selectLocation}
        role="presentation"
        styleName="location-row-address"
      >
        {getSegmentTitle(segment)}
      </div>
      {edit && (
        <div styleName="location-row-action">
          <IconButton
            onClick={deleteLocation}
            style={{ opacity: hovered ? 1 : 0, padding: 0 }}
          >
            <Tooltip title="Delete">
              <CloseIcon />
            </Tooltip>
          </IconButton>
        </div>
      )}
    </div>
  );
};

LocationRow.propTypes = {
  edit: PropTypes.bool,
  onDelete: PropTypes.func,
  onSelect: PropTypes.func,
  segment: PropTypes.object,
  selectedSegmentId: PropTypes.string
};

export default LocationRow;
