/* eslint-disable react/display-name */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable react/jsx-key */
import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { throttle } from 'lodash';
import { getAutocompleteRequestUrl } from '@constants/endpoints';
import {
  buildSearchFilters,
  CustomAutocomplete,
  defaultAutoCompleteProps,
  renderInput,
  renderTags
} from '@utils/autocomplete-utils';
import { processCategories } from '@utils/filter-utils';
import '@shared/input-search.scss';

export default function AsyncInputSearch(props) {
  const {
    autocompleteContainerStyles,
    containerStyles,
    dataType,
    disabled,
    fieldName,
    filters,
    groupBy,
    label,
    limitTags,
    onChange,
    placeHolder,
    renderGroup,
    renderOption,
    subType,
    toOptions,
    value,
    variant,
    attrField
  } = props;
  const [values, setValues] = useState([]);
  const [inputValue, setInputValue] = useState('');
  const [options, setOptions] = useState([]);
  const [errors, setErrors] = useState(null);

  const autoCompleteProps = { ...defaultAutoCompleteProps };
  if (renderOption) {
    autoCompleteProps.renderOption = renderOption;
  }
  if (limitTags) {
    autoCompleteProps.limitTags = limitTags;
  }

  const fetch = useMemo(
    () =>
      throttle((query, successCallback, errorCallback) => {
        const searchFilters = buildSearchFilters(processCategories(dataType, filters));
        const url = getAutocompleteRequestUrl(dataType, query, subType, attrField, searchFilters);
        const request = axios.get(url);
        request.then(
          success => successCallback(toOptions(success.data)),
          error => errorCallback(error)
        );
      }, 500),
    [dataType, subType, filters, attrField]  // eslint-disable-line react-hooks/exhaustive-deps
  );

  useEffect(() => {
    setValues(value);
  }, [value]);

  useEffect(() => {
    let active = true;

    if (inputValue === '') {
      setOptions(values);
      return undefined;  // eslint-disable-line no-undefined
    }

    fetch(
      inputValue,
      results => {
        if (active) {
          let newOptions = [...values];

          if (results) {
            newOptions = [...newOptions, ...results];
          }

          setOptions(newOptions);
        }
      },
      error => {
        setErrors([error]);
      }
    );

    return () => {
      active = false;
    };
  }, [values, inputValue, fetch]);

  return (
    <div styleName="input-search-container" style={{...containerStyles}}>
      <div styleName="input-search-autocomplete-container" style={{...autocompleteContainerStyles}}>
        <CustomAutocomplete
          {...autoCompleteProps}
          disabled={disabled}
          id={`${fieldName}-input-search-textfield`}
          groupBy={groupBy}
          noOptionsText={inputValue === '' ? '' : 'No results'}
          onChange={(event, newValues) => {
            setOptions([...options, ...newValues]);
            setValues(newValues);
            onChange(newValues);
          }}
          onInputChange={(event, newInputValue) => {
            setInputValue(newInputValue);
          }}
          open={inputValue !== ''}
          options={options}
          renderGroup={renderGroup}
          renderInput={renderInput(errors, label, placeHolder, variant)}
          renderTags={renderTags(props)}
          value={values}
        />
      </div>
    </div>
  );
}

AsyncInputSearch.propTypes = {
  attrField: PropTypes.string,
  autocompleteContainerStyles: PropTypes.object,
  avatarProps: PropTypes.object,
  containerStyles: PropTypes.object,
  dataType: PropTypes.string,
  disabled: PropTypes.bool,
  fieldName: PropTypes.string,
  filters: PropTypes.object,
  groupBy: PropTypes.func,
  label: PropTypes.string,
  limitTags: PropTypes.number,
  onChange: PropTypes.func,
  placeHolder: PropTypes.string,
  renderGroup: PropTypes.func,
  renderOption: PropTypes.func,
  subType: PropTypes.string,
  toOptions: PropTypes.func,
  value: PropTypes.array,
  variant: PropTypes.string
};

AsyncInputSearch.defaultProps = {
  dataType: 'common',  // Use the 'common' autocomplete API by default if no dataType is selected.
  value: [],
  variant: 'standard'
};
