import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { push } from 'connected-react-router';
import * as colors from '@constants/colors';
import { Button } from '@mui';
import TextField from '@material-ui/core/TextField';
import '../layouts/not-authenticated/not-authenticated.scss';

const ResetRequest = ({
  requestedResetStatus, emailErrorText, formChange, formSubmit
}) => {
  const dispatch = useDispatch();

  const gotoLogin = useCallback(() => dispatch(push('/login/')), [dispatch]);

  return (
    <div data-testid="reset-request">
      <div styleName="auth-heading"> Reset Password </div>
      {(requestedResetStatus !== 201)
        ?
        <div>
          <div styleName="auth-section">
            <div>
              Please enter your email address. We will send you an email that will contain a link to a page where you can reset your password.
            </div>
          </div>
          <form onSubmit={formSubmit}>
            <div styleName="not-authenticated-form-text-field">
              <TextField
                fullWidth
                id="email"
                name="email"
                label="Email address"
                onChange={formChange}
                error={emailErrorText?.length > 0}
                helperText={emailErrorText}
              />
            </div>
            <div>
              <Button
                color="primary"
                fullWidth
                type="submit"
                variant="contained"
              >
                SEND PASSWORD RESET
              </Button>
            </div>
          </form>
        </div>
        :
        <div styleName="auth-section">
          <div>
            If the email is correct, we will send a password reset to your email address.
          </div>
          <Button
            onClick={gotoLogin}
            style={{
              color: colors.dotmapsDoneGreen,
              border: `.1em solid ${colors.dotmapsDoneGreen}`,
              borderRadius: '.2em',
              lineHeight: '2em',
              width: '12em'
            }}
          >
            Done
          </Button>
        </div>
      }
    </div>
  );
};

ResetRequest.propTypes = {
  emailErrorText: PropTypes.string,
  formChange: PropTypes.func,
  formSubmit: PropTypes.func,
  requestedResetStatus: PropTypes.number
};

export default ResetRequest;
