import React, { memo, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { createNewDataDetail } from '@actions/data-detail-actions';
import { toggleTraffic, toggleBicycle, toggleTransit } from '@actions/map-actions';
import LoadingIndicator from '@components/details/loading-indicator';
import InfoContext from '@components/entity/info/info-context';
import Map from '@components/entity/info/map';
import Drawer from '@components/public/drawer';
import TopBar from '@components/public/top-bar';
import './details.scss';

const PublicDetails = () => {
  const dispatch = useDispatch();
  const { dataType } = useParams();
  const { details } = useSelector(state => state.config);
  const { data } = useSelector(state => state.dataDetail);

  useEffect(() => {
    dispatch(createNewDataDetail(dataType, true));
  }, [dispatch, dataType]);

  const mapTools = useMemo(
    () => details?.[dataType]?.form?.default_map_tools?.public,
    [details, dataType]
  );

  useEffect(() => {
    // Toggle entity specific map tools upon first load.
    if (mapTools?.length > 0) {
      if (mapTools.includes('bicycle')) {
        dispatch(toggleBicycle());
      }
      if (mapTools.includes('traffic')) {
        dispatch(toggleTraffic());
      }
      if (mapTools.includes('transit')) {
        dispatch(toggleTransit());
      }
    }
  }, [dispatch, mapTools]);

  const [activeSegmentId, setActiveSegmentId] = useState(null);
  const [activeType, setActiveType] = useState(null);
  const [activeStart, setActiveStart] = useState(null);
  const [activeEnd, setActiveEnd] = useState(null);
  const [geometryLocked, setGeometryLocked] = useState(false);

  const contextValue = {
    activeSegmentId,
    setActiveSegmentId,
    activeType,
    setActiveType,
    activeStart,
    setActiveStart,
    activeEnd,
    setActiveEnd,
    geometryLocked,
    setGeometryLocked
  };

  return (
    <div styleName="public-details-container">
      <LoadingIndicator />
      <div styleName="public-details-top-bar">
        <TopBar />
      </div>
      <div styleName="public-details-body">
        <InfoContext.Provider value={contextValue}>
          {data && <Drawer />}
          <Map />
        </InfoContext.Provider>
      </div>
    </div>
  );
};

export default memo(PublicDetails);
