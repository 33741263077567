import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchUserSettings } from '@actions/settings-actions';
import { getEmailSettingsConfig } from '@constants/config';
import MenuItem from './menu-item';
import './email.scss';

const Email = () => {
  const { email: emailSettings } = useSelector(state => state.settings);
  const dispatch = useDispatch();

  useEffect(() => dispatch(fetchUserSettings()), [dispatch]);

  if (!emailSettings) {
    return null;
  }

  const layout = getEmailSettingsConfig();

  return (
    <div styleName="email-container">
      <div styleName="title">
        {layout.title}
      </div>
      {layout.menu.map((item, index) => (
        emailSettings[item.type] &&
        <MenuItem
          key={index}
          item={item}
          emailSettings={emailSettings}
        />
      ))}
    </div>
  );
};

export default Email;
