/* eslint-disable max-depth */
import {
  LAYER_FETCH_SUCCESS,
  SET_LAYER_VISIBLE,
  LAYER_FETCH_CONFIG,
  LAYER_MAP_LOAD_OUTSIDE_VIEWPORT,
  LAYER_MAP_LOADING_COMPLETE,
  LAYER_MAP_SAVE_VIEWPORT
} from '@constants/action-types';
import initialState from '../store/initial-state';
import { optimizeLayerConfig } from '@utils/layer-utils';

// Helper method to set values on the state.
const updateState = (state, name, values) => {
  if (name in state) {
    return {
      ...state,
      [name]: {
        ...state[name],
        ...values
      }
    };
  }

  return state;
};

const layerReducer = (state = initialState.layers, action) => {
  switch (action.type) {
  case LAYER_FETCH_SUCCESS: {
    const { data } = action.payload;
    const { results, next } = data;
    const { name } = action;
    const newLayerIDs = new Set();
    results.forEach(layer => {
      newLayerIDs.add(layer.id);
    });
    const layer = state[name];
    return updateState(
      state,
      name,
      {
        list: [...layer.list.filter(item => !newLayerIDs.has(item.id)), ...results],
        next: next ? next : layer.url
      }
    );
  }
  case LAYER_MAP_LOAD_OUTSIDE_VIEWPORT: {
    const { name } = action;
    return updateState(state, name, { inViewport: false });
  }
  case LAYER_MAP_LOADING_COMPLETE: {
    const { name } = action;
    return updateState(state, name, { loadingComplete: true });
  }
  case LAYER_MAP_SAVE_VIEWPORT: {
    const { name, viewport } = action;
    return updateState(state, name, { viewport });
  }
  case SET_LAYER_VISIBLE: {
    const { name, visible } = action.payload;
    return updateState(state, name, { visible });
  }
  case LAYER_FETCH_CONFIG: {
    const { results } = action.payload.data;
    const layerList = {};
    results.map(layer => {
      layerList[layer.name] = optimizeLayerConfig({...layer});
      return layerList;
    });
    return {...state, ...layerList};
  }
  default:
    return state;
  }
};

export default layerReducer;
