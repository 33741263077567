/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { memo, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

import GroupListItem from '@components/shared/list/group-list-item';

import { viewGroupOnMap } from '@actions/map-actions';

import styles from './group-item.scss';

const GroupItem = ({result}) => {
  const { id, group } = result;
  const dispatch = useDispatch();
  const handleClick = useCallback(
    () => dispatch(viewGroupOnMap(id)),
    [dispatch, id]
  );

  return (
    <GroupListItem
      className={styles.groupItem}
      onClick={handleClick}
      group={group}
    />
  );
};

GroupItem.propTypes = {
  result: PropTypes.object
};

export default memo(GroupItem);
