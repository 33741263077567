/* eslint-disable react/jsx-no-bind */
import React, { useContext, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';

import * as R from 'ramda';

import Typography from '@material-ui/core/Typography';
import { Icon, Modal } from '@mui';

import { isValidUrlWithMessage } from '@utils/validation-utils';

import { CARD_SIZE_LG } from '../constants';
import DashboardContext from '../dashboard-context';

import CustomEmbedFormView from './custom-embed-form-view';
import CustomizeDashboardView from './customize-dashboard-view';

import './customize-dashboard-dialog.scss';

export const ROOT_VIEW = 'ROOT';
export const ADD_CUSTOM_VIEW = 'ADD_CUSTOM';

const INIT_EMBED_FORM_FIELDS = {
  size: CARD_SIZE_LG,
  title: '',
  embedLink: ''
};

const CustomizeDashboardDialog = ({ open, onClose }) => {
  const [view, setView] = useState(ROOT_VIEW);

  const { handleAddEmbedCard, loadingEmbedCard } = useContext(DashboardContext);
  const [urlErrorMsg, setUrlErrorMsg] = useState('');
  const [cardData, setCardData] = useState(INIT_EMBED_FORM_FIELDS);

  useEffect(() => {
    if (open) {
      setView(ROOT_VIEW);
      setCardData(INIT_EMBED_FORM_FIELDS);
    }
  }, [open]);

  const isValid = useMemo(() => {
    const [isURLValid, urlError] = isValidUrlWithMessage(cardData.embedLink);
    setUrlErrorMsg(urlError);
    const isHyperlinkValid = !R.isEmpty(cardData.embedLink) && isURLValid;

    return isHyperlinkValid && !R.isEmpty(cardData.title);
  }, [cardData]);

  const modalTitle =
    view === ADD_CUSTOM_VIEW ? (
      <div styleName="modal-header custom-embed">
        <Icon onClick={() => setView(ROOT_VIEW)}>arrow_back</Icon>
        <div>Add Card: Custom Embed</div>
      </div>
    ) : (
      <Typography component="h2" variant="h6" styleName="modal-header">
        <div>Customize Dashboard</div>
        <Icon onClick={onClose}>close</Icon>
      </Typography>
    );

  // Disable the use of Typography within DialogTitle, so we can use our own
  // and control styling:
  const dialogTitleProps = view === ADD_CUSTOM_VIEW ? {} : { disableTypography: true };

  return (
    <Modal
      title={modalTitle}
      open={open}
      onCancel={onClose}
      onExited={onClose}
      onConfirm={() => handleAddEmbedCard(cardData)}
      data-cy="customizeDashboardModal"
      width="sm"
      disabled={!isValid || loadingEmbedCard}
      disableFooter={view === ROOT_VIEW}
      confirmButtonText="ADD"
      DialogTitleProps={dialogTitleProps}
    >
      {view === ADD_CUSTOM_VIEW ? (
        <CustomEmbedFormView
          cardData={cardData}
          setCardData={setCardData}
          urlErrorMsg={urlErrorMsg}
        />
      ) : (
        <CustomizeDashboardView setView={setView} />
      )}
    </Modal>
  );
};

CustomizeDashboardDialog.propTypes = {
  onClose: PropTypes.func,
  open: PropTypes.bool
};

export default CustomizeDashboardDialog;
