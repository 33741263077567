import React from 'react';
import PropTypes from 'prop-types';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle
} from '@material-ui/core';

import { Button } from '@mui';

import { makeStyles } from '@material-ui/core/styles';

import { colors } from '@constants/colors';

const getWidthValue = width => {
  switch (width) {
  case 'sm':
    return '31.25rem';
  case 'lg':
    return '43.75rem';
  case 'xl':
    return '62.5rem';
  case 'xxl':
    return '77rem';
  default:
    return '23rem';
  }
};

const useStyles = makeStyles({
  paper: ({ width = 'sm' }) => ({
    // 340, 500, 700, 1000 pixels respectively
    width: getWidthValue(width)
  }),
  title: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    borderBottom: `1px solid ${colors.neutral.ash}`
  },
  content: ({ contentStyle }) => ({
    minHeight: '3rem',
    ...contentStyle
  }),
  footer: {
    borderTop: `1px solid ${colors.neutral.ash}`
  }
});

export const Modal = props => {
  const {
    open,
    onCancel,
    title,
    children,
    loading,
    disabled,
    footer,
    cancelButtonText = 'Cancel',
    CancelButtonProps = {},
    onConfirm,
    confirmButtonText = 'Ok',
    onExited,
    ConfirmButtonProps = {},
    DialogTitleProps = {},
    DialogContentProps = {},
    DialogFooterProps = {},
    disableEscapeKeyDown = true,
    disableHeader = false,
    disableFooter = false,
    onClear,
    clearButtonText = 'Clear'
  } = props;

  const classes = useStyles(props);

  return (
    <Dialog
      open={open}
      onClose={onCancel}
      TransitionProps={{
        onExited
      }}
      disableEscapeKeyDown={disableEscapeKeyDown}
      classes={{ paper: classes.paper }}
      maxWidth={false}
      fullWidth
    >
      {!disableHeader && (
        <DialogTitle
          {...DialogTitleProps}
          classes={{ root: classes.title, ...DialogTitleProps.classes }}
        >
          {title}
        </DialogTitle>
      )}
      <DialogContent
        {...DialogContentProps}
        style={props.contentStyle}
        classes={{
          root: classes.content,
          ...DialogContentProps.classes
        }}
      >
        {children}
      </DialogContent>
      {!disableFooter && (
        <DialogActions
          classes={{
            root: classes.footer,
            ...DialogFooterProps.classes
          }}
        >
          {footer || (
            <>
              {onClear && (
                <>
                  <Button onClick={onClear}>{clearButtonText}</Button>
                  <div style={{ flexGrow: 1 }} />
                </>
              )}
              <Button onClick={onCancel} {...CancelButtonProps}>
                {cancelButtonText}
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={onConfirm}
                disabled={disabled || loading}
                {...ConfirmButtonProps}
              >
                {confirmButtonText}
              </Button>
            </>
          )}
        </DialogActions>
      )}
    </Dialog>
  );
};

Modal.propTypes = {
  CancelButtonProps: PropTypes.object,
  ConfirmButtonProps: PropTypes.object,
  DialogContentProps: PropTypes.object,
  DialogFooterProps: PropTypes.object,
  DialogTitleProps: PropTypes.object,
  cancelButtonText: PropTypes.string,
  children: PropTypes.node,
  clearButtonText: PropTypes.string,
  confirmButtonText: PropTypes.string,
  contentStyle: PropTypes.object,
  disableEscapeKeyDown: PropTypes.bool,
  disableFooter: PropTypes.bool,
  disableHeader: PropTypes.bool,
  disabled: PropTypes.bool,
  footer: PropTypes.node,
  loading: PropTypes.bool,
  onCancel: PropTypes.func,
  onClear: PropTypes.func,
  onConfirm: PropTypes.func,
  onExited: PropTypes.func,
  open: PropTypes.bool,
  title: PropTypes.object
};

export default Modal;
