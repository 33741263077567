import React, { memo } from 'react';
import { useSelector } from 'react-redux';
import OverlapCountMarker from '@components/map/marker-overlaps/overlap-count-marker';
import { boundsMarkersSelector } from '@selectors/markers-selector';

const MarkerOverlaps = () => {
  const centers = useSelector(boundsMarkersSelector);
  return centers.map((center, index) => <OverlapCountMarker {...center} key={index} />);
};

export default memo(MarkerOverlaps);
