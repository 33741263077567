import React, { memo, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { closeDashboardDialog } from '@actions/dashboard-actions';
import { setBaseline } from '@actions/groups-actions';
import * as dialog from '@constants/dialogs';
import Dialog from '@shared/dialogs/dialog';
import DialogActions from '@shared/dialogs/dialog-actions';

const SetBaseline = () => {
  const dispatch = useDispatch();
  const { activeDialogs, dialogParams } = useSelector(state => state.dashboard);
  const isDialogActive = activeDialogs[dialog.SET_BASELINE];

  const onClose = useCallback(
    () => {
      dispatch(closeDashboardDialog(dialog.SET_BASELINE));
    },
    [dispatch]
  );

  const onSet = useCallback(
    () => {
      dispatch(setBaseline({ ...dialogParams[dialog.SET_BASELINE] }));
      dispatch(closeDashboardDialog(dialog.SET_BASELINE));
    },
    [dialogParams, dispatch]
  );

  if (!isDialogActive) {
    return null;
  }

  return (
    <Dialog
      actions={
        <DialogActions
          onCancel={onClose}
          onSubmit={onSet}
          submitLabel="SET"
        />
      }
      title="Set baseline"
    >
      Are you sure you want to save this current state of the group as the baseline?
      You can update the baseline any time.
    </Dialog>
  );
};

export default memo(SetBaseline);
