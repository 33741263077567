import { useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';

import moment from 'moment-timezone';

import { updateFilter } from './actions';

export const useDataTypeFilterHandleChange = (dataType, subType, filterKey) => {
  const dispatch = useDispatch();
  return useCallback(value => {
    dispatch(updateFilter(dataType, subType, { [filterKey]: value }));
  }, [dispatch, dataType, subType, filterKey]);
};

export const useDefaultFutureDateOptions = () => {
  return useMemo(() => {
    const today = moment();
    return [
      {
        label: 'Today',
        value: {
          start: today.format('YYYY-MM-DD'),
          end: today.format('YYYY-MM-DD'),
          contained: false
        }
      },
      {
        label: 'Next 7 days',
        value: {
          start: today.format('YYYY-MM-DD'),
          end: (moment(today)
            .add(7, 'days')
            .format('YYYY-MM-DD')
          ),
          contained: false
        }
      },
      {
        label: 'Next 30 days',
        value: {
          start: today.format('YYYY-MM-DD'),
          end: (moment(today)
            .add(30, 'days')
            .format('YYYY-MM-DD')
          ),
          contained: false
        }
      },
      {
        label: 'Next 90 days',
        value: {
          start: today.format('YYYY-MM-DD'),
          end: (moment(today)
            .add(90, 'days')
            .format('YYYY-MM-DD')
          ),
          contained: false
        }
      },
      {
        label: 'Any time',
        value: {
          contained: false
        }
      }
    ];
  }, []);
};

export const useDefaultPastDateOptions = () => {
  return useMemo(() => {
    const today = moment();
    return [
      {
        label: 'Today',
        value: {
          start: today.format('YYYY-MM-DD'),
          end: today.format('YYYY-MM-DD'),
          contained: false
        }
      },
      {
        label: 'Last 7 days',
        value: {
          start: (moment(today)
            .subtract(7, 'days')
            .format('YYYY-MM-DD')
          ),
          end: today.format('YYYY-MM-DD'),
          contained: false
        }
      },
      {
        label: 'Last 30 days',
        value: {
          start: (moment(today)
            .subtract(30, 'days')
            .format('YYYY-MM-DD')
          ),
          end: today.format('YYYY-MM-DD'),
          contained: false
        }
      },
      {
        label: 'Last 90 days',
        value: {
          start: (moment(today)
            .subtract(90, 'days')
            .format('YYYY-MM-DD')
          ),
          end: today.format('YYYY-MM-DD'),
          contained: false
        }
      }
    ];
  }, []);
};
