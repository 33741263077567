// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.src-components-filters-chips-shared-___menu-item__item.src-components-filters-chips-shared-___menu-item__item{overflow:hidden;display:flex;align-items:center;gap:1rem;padding-block:0.5rem;padding-inline:1rem}.src-components-filters-chips-shared-___menu-item__item .src-components-filters-chips-shared-___menu-item__check-area,.src-components-filters-chips-shared-___menu-item__item .src-components-filters-chips-shared-___menu-item__icon-area{display:flex;align-items:center;justify-content:center}.src-components-filters-chips-shared-___menu-item__item .src-components-filters-chips-shared-___menu-item__check-area{color:#406EE3;height:1.25rem;width:1.5rem}.src-components-filters-chips-shared-___menu-item__item .src-components-filters-chips-shared-___menu-item__icon-area{max-height:1.25rem;max-width:2rem}.src-components-filters-chips-shared-___menu-item__item .src-components-filters-chips-shared-___menu-item__item-text{margin:0}.src-components-filters-chips-shared-___menu-item__item .src-components-filters-chips-shared-___menu-item__item-text .src-components-filters-chips-shared-___menu-item__primary{color:#212121;font-size:0.875rem;line-height:1.25rem}.src-components-filters-chips-shared-___menu-item__item .src-components-filters-chips-shared-___menu-item__item-text .src-components-filters-chips-shared-___menu-item__secondary{color:#616161;font-size:0.75rem;line-height:1rem}
`, "",{"version":3,"sources":["webpack://./src/components/filters/chips/shared/menu-item.scss"],"names":[],"mappings":"AAAA,+GAEI,eAAgB,CAEhB,YAAa,CACb,kBAAmB,CACnB,QAAS,CAET,oBAAqB,CACrB,mBAAoB,CATxB,2OAcI,YAAa,CACb,kBAAmB,CACnB,sBAAuB,CAhB3B,sHAoBI,aAAc,CACd,cAAe,CACf,YAAa,CAtBjB,qHA0BI,kBAAmB,CACnB,cAAe,CA3BnB,qHA+BI,QAAS,CA/Bb,gLAkCM,aAAc,CACd,kBAAmB,CACnB,mBAAoB,CApC1B,kLAwCM,aAAc,CACd,iBAAkB,CAClB,gBAAiB","sourcesContent":[".item {\n  &.item {\n    overflow: hidden;\n\n    display: flex;\n    align-items: center;\n    gap: 1rem;\n\n    padding-block: 0.5rem;\n    padding-inline: 1rem;\n  }\n\n  .check-area,\n  .icon-area {\n    display: flex;\n    align-items: center;\n    justify-content: center;\n  }\n\n  .check-area {\n    color: #406EE3;\n    height: 1.25rem;\n    width: 1.5rem;\n  }\n\n  .icon-area {\n    max-height: 1.25rem;\n    max-width: 2rem;\n  }\n\n  .item-text {\n    margin: 0;\n\n    .primary {\n      color: #212121;\n      font-size: 0.875rem;\n      line-height: 1.25rem;\n    }\n\n    .secondary {\n      color: #616161;\n      font-size: 0.75rem;\n      line-height: 1rem;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"item": `src-components-filters-chips-shared-___menu-item__item`,
	"check-area": `src-components-filters-chips-shared-___menu-item__check-area`,
	"checkArea": `src-components-filters-chips-shared-___menu-item__check-area`,
	"icon-area": `src-components-filters-chips-shared-___menu-item__icon-area`,
	"iconArea": `src-components-filters-chips-shared-___menu-item__icon-area`,
	"item-text": `src-components-filters-chips-shared-___menu-item__item-text`,
	"itemText": `src-components-filters-chips-shared-___menu-item__item-text`,
	"primary": `src-components-filters-chips-shared-___menu-item__primary`,
	"secondary": `src-components-filters-chips-shared-___menu-item__secondary`
};
export default ___CSS_LOADER_EXPORT___;
