import React from 'react';
import PropTypes from 'prop-types';
import { getDashboardMenuConfig } from '@constants/config';
import NameDescriptionDialog from '@shared/dialogs/name-description-dialog';
import TaskTypeDiscardDialog from '@shared/dialogs/task-type-discard-dialog';
import TaskTypePublishDialog from '@shared/dialogs/task-type-publish-dialog';
import MenuSection from './menu-section';
import './dashboard.scss';

const Dashboard = ({ children }) => (
  <div styleName="dashboard-container">
    <div styleName="dashboard-menu-container">
      {getDashboardMenuConfig().map(section => <MenuSection key={section.title} section={section} />)}
    </div>
    <div styleName="dashboard-content">
      {children}
    </div>
    <NameDescriptionDialog />
    <TaskTypeDiscardDialog />
    <TaskTypePublishDialog />
  </div>
);

Dashboard.propTypes = {
  children: PropTypes.element
};

export default Dashboard;
