// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.src-components-forms-___input-datatype-search__input-datatype-search-container{padding:0 2rem 1rem 1rem}
`, "",{"version":3,"sources":["webpack://./src/components/forms/input-datatype-search.scss"],"names":[],"mappings":"AAAA,gFACE,wBAAyB","sourcesContent":[".input-datatype-search-container {\n  padding: 0 2rem 1rem 1rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"input-datatype-search-container": `src-components-forms-___input-datatype-search__input-datatype-search-container`,
	"inputDatatypeSearchContainer": `src-components-forms-___input-datatype-search__input-datatype-search-container`
};
export default ___CSS_LOADER_EXPORT___;
