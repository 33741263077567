import React from 'react';
import PropTypes from 'prop-types';
import SvgIcon from 'material-ui/SvgIcon';

const MeasurementIcon = props => (
  <SvgIcon {...props} style={{...props.style, height: '12px', width: '22px'}} viewBox="0 0 22 12">
    <path
      d={`
          M20 0H2C0.9 0 0 0.9 0 2V10C0 11.1 0.9 12 2 12H20C21.1
          12 22 11.1 22 10V2C22 0.9 21.1 0 20 0ZM20
          10H2V2H4V6H6V2H8V6H10V2H12V6H14V2H16V6H18V2H20V10Z
         `}
      fill={props.color}
    />
  </SvgIcon>
);

MeasurementIcon.propTypes = {
  color: PropTypes.string,
  style: PropTypes.object
};

export default MeasurementIcon;
