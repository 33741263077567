import React, { memo } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import MapEntities from './map-entities';
import { getEnabledEntityTypes } from '@selectors/entities-selector';

const Entities = ({ markerVisible }) => {
  // Get enabled entity types (the ones checked on the map filters).
  const entityTypes = useSelector(getEnabledEntityTypes);
  // Filter the entity types to show to the enabled ones only:
  const { types } = useSelector(state => state.entities);
  const entities = {};
  entityTypes.forEach(type => {
    if (types[type] && types[type].queryViewport) {
      entities[type] = types[type];
    }
  });

  return Object.keys(entities).map(type => <MapEntities key={type} markerVisible={markerVisible} type={type} />);
};

Entities.propTypes = {
  markerVisible: PropTypes.bool
};

export default memo(Entities);
