import React from 'react';
import { useSelector } from 'react-redux';
import * as R from 'ramda';
import { includes, isEmpty } from 'lodash';
import { getGanttEntityLabels } from '@utils/gantt-utils';
import './header.scss';

const getLabel = (entity, type) => {
  const ganttEntityLabels = getGanttEntityLabels(type);
  if (!isEmpty(entity) && entity.length === 1) {
    return R.values(ganttEntityLabels).find(value => value.id === entity[0]).simpleLabel;
  } else
    if (isEmpty(entity) || entity.length === Object.keys(ganttEntityLabels).length) {
      return 'All Entities';
    }
  const labels = R.values(ganttEntityLabels).filter(value => includes(entity, value.id));
  const label = labels.map(value => value.simpleLabel).join(', ');
  return label;
};

const Header = () => {
  const { type } = useSelector(state => state.groups.edit.group);
  const { entity } = useSelector(state => state.groups.gantt.filters);
  return (
    <div styleName="gantt-drawer-header">
      <div styleName="gantt-drawer-header-left">
        {getLabel(entity, type)}
      </div>
      <div styleName="gantt-drawer-header-right">Duration</div>
    </div>
  );
};

export default Header;
