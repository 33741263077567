import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import memoize from 'memoize-one';
import { openAreaTray, openEntityTray } from '@actions/map-actions';
import BaseMapFigure from '@components/map/map-figure';
import withShouldHighlight from '@components/map/with-should-highlight';
import { getHoverEntityId, getMapModesSet, getTrayEntitiesIds } from '@selectors/map-selector';
import { getEntityMode } from '@utils/entity-utils';
import { getEntityMarker, getEntityStarIcon } from '@utils/icon-utils';
import { isMarkerVisible } from '@utils/markers-utils';

class EntityMapFigure extends Component {
  constructor() {
    super();
    this.getEntityMarker = memoize(getEntityMarker);
  }

  getMarker = highlight => {
    const {
      agencyTypeId,
      icon,
      type,
      openConflicts,
      pendingConflicts,
      resolvedConflicts,
      opportunities,
      mapModes
    } = this.props;
    return this.getEntityMarker(
      agencyTypeId,
      type,
      icon,
      highlight,
      getEntityMode(openConflicts, pendingConflicts, resolvedConflicts, opportunities, mapModes)
    );
  };

  getOffsetMarker = highlight => getEntityStarIcon(highlight);

  render() {
    const { showOffset, type } = this.props;
    return (
      <BaseMapFigure
        getMarker={this.getMarker}
        getOffsetMarker={this.getOffsetMarker}
        showOffset={showOffset}
        type={type}
        {...this.props}
      />
    );
  }
}

EntityMapFigure.propTypes = {
  agencyTypeId: PropTypes.number,
  conflicts: PropTypes.string,
  icon: PropTypes.number,
  mapModes: PropTypes.instanceOf(Set),
  markerSource: PropTypes.string,
  openConflicts: PropTypes.array,
  opportunities: PropTypes.string,
  pendingConflicts: PropTypes.array,
  resolvedConflicts: PropTypes.array,
  segments: PropTypes.array,
  showOffset: PropTypes.bool,
  type: PropTypes.string
};

const mapStateToProps = (state, props) => {
  const { zoom } = state.map.viewport;

  let markers = null;
  if (props.markerSource && props.markerSource === 'group') {
    markers = state.groups.markers;
  } else {
    markers = state.markers;
  }
  const markerVisible = props.markerVisible || isMarkerVisible(markers, zoom, props.type, props.id);
  const drawingMode = state.map.drawing.mode;
  return {
    drawingMode,
    mapModes: getMapModesSet(state),
    markerVisible,
    showOffset: props.ownEntity
  };
};

const mapDispatchToProps = {
  openAreaTray,
  openEntityInTray: openEntityTray
};

export default withShouldHighlight(
  connect(mapStateToProps, mapDispatchToProps)(EntityMapFigure),
  getTrayEntitiesIds,
  getHoverEntityId
);
