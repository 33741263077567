import React from 'react';
import Subheader from './comments-subheader';
import Input from './comments-input';
import List from './comments-list';
import './comments-body.scss';

const CommentsBody = () => (
  <div styleName="body-container">
    <Subheader />
    <Input />
    <List />
  </div>
);

export default CommentsBody;
