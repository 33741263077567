import React, { memo, useCallback, useMemo } from 'react';
import * as R from 'ramda';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import { clearDashboardDetailsDataError, updateDashboardField } from '@actions/dashboard-actions';
import { TextField as MUITextField } from '@material-ui/core';
import './fields.scss';

const TextField = ({ disabled, field, label, required, value }) => {
  const { dataType } = useParams();
  const dispatch = useDispatch();
  const errors = useSelector(state => state.dashboard.details?.[dataType]?.error);

  const onChange = useCallback(
    event => {
      dispatch(clearDashboardDetailsDataError(dataType, field?.split('.')));
      dispatch(updateDashboardField(dataType, event.target.name, event.target.value));
    },
    [dataType, dispatch, field]
  );

  const errorText = useMemo(() => {
    // Take care of nested fields (like contact.email, contact.name, etc).
    if (field?.includes('.')) {
      return R.pathOr(null, field.split('.'), errors);
    }
    return errors?.[field];
  }, [field, errors]);

  return (
    <div styleName="field-container">
      <div styleName="field-label">
        {label}&nbsp;{required ? '*' : ''}
      </div>
      <div styleName="field-input">
        <MUITextField
          disabled={disabled}
          error={errorText}
          fullWidth
          helperText={errorText}
          id={field}
          name={field}
          onChange={onChange}
          value={value}
        />
      </div>
    </div>
  );
};

TextField.propTypes = {
  disabled: PropTypes.bool,
  field: PropTypes.string,
  label: PropTypes.string,
  required: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
};

export default memo(TextField);
