import { connect } from 'react-redux';
import { fetchDataType } from '@actions/data-types-actions';
import SettingsForm, { settingsFormMapDispatchToProps } from '@components/settings/settings-form';
import { getNotificationsSettingsConfig } from '@constants/config';
import { formMapStateToProps } from '@shared/form';

class Notifications extends SettingsForm {
  getPayload = () => {
    const { data } = this.state;
    return {
      notifications: data
    };
  };

  // Reload notifications after saving notification settings:
  postSave = () => this.props.fetchDataType('notification');

  getLayout = () => getNotificationsSettingsConfig();
}

const mapStateToProps = state => {
  const { notifications: data } = state.settings;
  return { data, ...formMapStateToProps(state) };
};

const mapDispatchToProps = { ...settingsFormMapDispatchToProps, fetchDataType };

export default connect(mapStateToProps, mapDispatchToProps)(Notifications);
