import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { closeDashboardDialog } from '@actions/dashboard-actions';
import { discardTaskType } from '@actions/workflow-actions';
import * as dialog from '@constants/dialogs';
import Dialog from '@shared/dialogs/dialog';
import DialogActions from '@shared/dialogs/dialog-actions';

const TaskTypeDiscardDialog = () => {
  const dispatch = useDispatch();

  const { activeDialogs, dialogParams } = useSelector(state => state.dashboard);

  const { id } = dialogParams[dialog.TASK_TYPE_DISCARD] || {};
  const isDialogActive = activeDialogs[dialog.TASK_TYPE_DISCARD];

  const onClose = useCallback(
    () => {
      dispatch(closeDashboardDialog(dialog.TASK_TYPE_DISCARD));
    },
    [dispatch]
  );

  const onDiscard = useCallback(
    () => {
      dispatch(discardTaskType(id));
    },
    [dispatch, id]
  );

  if (!isDialogActive) {
    return null;
  }

  return (
    <Dialog
      actions={
        <DialogActions
          onCancel={onClose}
          onSubmit={onDiscard}
          submitLabel="DISCARD"
        />
      }
      title="Discard unpublished changes?"
    >
      This will permanently delete your unpublished changes and revert the task type to the last published version. Are you sure?
    </Dialog>
  );
};

export default TaskTypeDiscardDialog;
