import React from 'react';
import PropTypes from 'prop-types';
import { TextField as MUITextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { colors } from '@constants/colors';
import { fonts } from '@constants/fonts';

const getInputLabel = ({ floatingLabelFixed = true }) => {
  return floatingLabelFixed
    ? { position: 'unset', color: colors.neutral.gray }
    : {
      color: colors.neutral.gray,
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      bottom: '0px',
      maxWidth: '100%'
    };
};

const getInputLabelShrink = ({ floatingLabelFixed = true }) => {
  return floatingLabelFixed
    ? { transform: 'unset', fontSize: fonts.sm }
    : {
      fontSize: fonts.sm,
      overflow: 'unset',
      textOverflow: 'unset',
      whiteSpace: 'unset'
    };
};

const useStyles = makeStyles(theme => ({
  inputRoot: {
    fontSize: fonts.md
  },
  inputUnderline: {
    '&:before': {
      borderBottom: `1px solid ${colors.neutral.silver}`
    }
  },
  inputLabel: getInputLabel,
  inputLabelShrunk: getInputLabelShrink,
  inputLabelFocused: {
    color: `${colors.neutral.gray} !important`
  },
  inputLabelError: {
    color: `${theme.palette.error.main} !important`
  },
  helperText: {
    height: 0,
    marginTop: 0
  },
  formControl: {
    'label + &': {
      marginTop: '0'
    }
  }
}));

const defaultContainerStyle = {
  width: '100%'
};

export const TextField = props => {
  const classes = useStyles(props);

  const {
    style,
    floatingLabelFixed = true,
    fullWidth = true,
    autoComplete = 'off',
    disableUnderline = false,
    variant = 'standard',
    FormHelperTextProps = {},  // eslint-disable-line no-unused-vars
    ...data
  } = props;

  return (
    <div style={{ ...defaultContainerStyle, ...style }}>
      <MUITextField
        {...data}
        variant={variant}
        fullWidth={fullWidth}
        label={props.label}
        autoComplete={autoComplete}
        InputLabelProps={{
          ...props.InputLabelProps,
          classes: {
            root: classes.inputLabel,
            focused: classes.inputLabelFocused,
            error: classes.inputLabelError,
            ...(floatingLabelFixed && {
              shrink: classes.inputLabelShrunk
            }),
            ...props.InputLabelProps?.classes
          },
          ...(floatingLabelFixed && { shrink: true })
        }}
        InputProps={{
          ...props.InputProps,
          ...{ disableUnderline },
          classes: {
            root: classes.inputRoot,
            underline: classes.inputUnderline,
            formControl: classes.formControl,
            ...props.InputProps?.classes
          }
        }}
        FormHelperTextProps={{
          ...props.FormHelperTextProps,
          classes: {
            root: classes.helperText,
            ...props.FormHelperTextProps?.classes
          }
        }}
      />
    </div>
  );
};

TextField.propTypes = {
  FormHelperTextProps: PropTypes.object,
  InputLabelProps: PropTypes.object,
  InputProps: PropTypes.object,
  autoComplete: PropTypes.string,
  disableUnderline: PropTypes.bool,
  floatingLabelFixed: PropTypes.bool,
  fullWidth: PropTypes.bool,
  label: PropTypes.string,
  style: PropTypes.object,
  variant: PropTypes.string
};

export default TextField;
