import React from 'react';
import PropTypes from 'prop-types';
import Chip from '@material-ui/core/Chip';
import { withStyles } from '@material-ui/core/styles';

// Chip customization to be able to use a different color than the defaults.
const CustomChip = props => {
  const { colors, ...other } = props;
  const StyledChip = withStyles({
    root: {
      color: colors.foreground,
      backgroundColor: `${colors.background} !important`,
      '&:hover, &:focus': {
        backgroundColor: colors.background,
        filter: 'brightness(108%)'
      },
      '&:active': {
        boxShadow: 'none',
        backgroundColor: colors.background,
        borderColor: colors.background
      }
    }
  })(Chip);
  return <StyledChip color="primary" {...other} />;
};

CustomChip.propTypes = {
  colors: PropTypes.object
};

export default CustomChip;
