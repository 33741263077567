/* eslint-disable jsx-a11y/mouse-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/display-name */
import React, { Fragment, useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import Chip from '@material-ui/core/Chip';
import Popover from '@material-ui/core/Popover';
import Row from '@components/workflow/section/view-field-row';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { getChipProps, SquareChip } from '@utils/autocomplete-utils';
import { formatDate } from '@utils/shared-utils';
import { getFieldValue } from '@utils/workflow-utils';
import './view-field.scss';

const CoreViewField = ({ field, label, options: fieldOptions, source }) => {
  const { data } = useSelector(state => state.dataDetail);
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const dataTypes = useSelector(state => state.dataTypes);
  const value = getFieldValue(data, field);

  const getOptions = () => {
    if (source) {
      return dataTypes[source];
    }
    if (fieldOptions) {
      return fieldOptions;
    }
    return [];
  };

  const options = getOptions();

  const popoverOpen = useCallback(event => {
    event.preventDefault();
    setOpen(true);
    setAnchorEl(event.currentTarget);
  }, [setOpen, setAnchorEl]);

  const onClose = useCallback(() => setOpen(false), [setOpen]);

  const renderActivities = () => {
    const activities = value.map(val => val.agency && options[val.agency] && options[val.agency].name);

    if (activities && activities.length > 0) {
      // If there's a single agency, render its name.
      if (activities.length === 1) {
        return activities[0];
      }
      // Else render the number of agencies with the Agencies label.
      return (
        <Fragment>
          <div styleName="popover-link" onClick={popoverOpen} role="presentation">
            {activities.length}&nbsp;Agencies&nbsp;&nbsp;<ArrowDropDownIcon />
          </div>
          <Popover
            open={open}
            anchorEl={anchorEl}
            anchorOrigin={{horizontal: 'left', vertical: 'bottom'}}
            targetOrigin={{horizontal: 'left', vertical: 'top'}}
            onClose={onClose}
          >
            <div styleName="popover-content">
              {activities.map(activity => (
                <div styleName="popover-item" key={activity}>
                  {activity}
                </div>
              ))}
            </div>
          </Popover>
        </Fragment>
      );
    }

    // There are no options, render 'None'.
    return 'None';
  };

  if (value === null) {
    return <Row label={label} value="None" />;
  }
  if (field === 'due_date') {
    return <Row label={label} value={formatDate(value)} />;
  } else
    if (field === 'activities') {
      const activities = renderActivities();
      return <Row label={label} value={activities} />;
    } else
      if (field === 'owner') {
        const option = options[value] || { name: data.owner_name };
        const props = { avatarProps: { type: 'user' } };
        const chip = (
          <Chip
            label={option.name}
            {...getChipProps(props, { title: option.name })}
          />
        );
        return <Row label={label} value={chip} />;
      } else
        if (field === 'roles') {
          const optionList = value.map(val => options[val] && options[val].name);
          if (optionList && optionList.length > 0) {
            const chips = optionList.map(option => <div styleName="chip-sep" key={option}><SquareChip label={option} /></div>);
            return <Row label={label} value={chips} />;
          }
          return <Row label={label} value="None" />;
        }
  return null;
};

CoreViewField.propTypes = {
  field: PropTypes.string,
  label: PropTypes.string,
  options: PropTypes.object,
  source: PropTypes.string
};

export default CoreViewField;
