/* eslint-disable react/jsx-key */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import { push } from 'connected-react-router';
import { isEmpty } from 'lodash';
import * as colors from '@constants/colors';
import { Icon, IconButton } from '@mui';
import './top-bar-action-entity-icons.scss';

const TopBarActionEntityIcons = () => {
  const { data } = useSelector(state => state.dataDetail);
  const { dataType } = useParams();
  const dispatch = useDispatch();
  const location = useLocation();

  const hasOverlaps = useCallback(() => data && !isEmpty(data.overlaps), [data]);
  const overlapsCount = useCallback(() => {
    return (data.overlaps.conflicts ? data.overlaps.conflicts.split(',').length : 0) +
    (data.overlaps.opportunities ? data.overlaps.opportunities.split(',').length : 0);
  }, [data]);

  const viewOverlaps = useCallback(
    () => {
      if (hasOverlaps()) {
        dispatch(push(
          {
            pathname: `/overlap/${dataType}/${data.id}/`,
            state: {
              source: location.pathname
            }
          }
        ));
      }
    },
    [data, dataType, dispatch, hasOverlaps, location]
  );

  let tooltip = `This ${dataType} has no overlaps.`;
  let label = 'No overlaps';
  if (hasOverlaps()) {
    tooltip = 'View overlaps';
    label = `${overlapsCount()} overlaps`;
  }

  return [
    <div styleName="button-container" onClick={viewOverlaps} role="button" tabIndex="0">
      <IconButton
        onClick={viewOverlaps}
        tooltip={tooltip}
        style={{
          padding: 0,
          width: '24px',
          height: '24px',
          ...(!hasOverlaps() && { cursor: 'default' })
        }}
      >
        <Icon
          color={colors.textColor}
          style={{...(!hasOverlaps() && { opacity: 0.5 })}}
        >
          warning
        </Icon>
      </IconButton>
      <div styleName="button-label">{label}</div>
    </div>
  ];
};

export default TopBarActionEntityIcons;
