/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/jsx-no-bind */
import React, { memo, useCallback, useContext } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import { openDashboardDialog } from '@actions/dashboard-actions';
import DrawerOverlapToggle from '@components/overlap/drawer-overlap-toggle';
import MoreButton from '@components/overlap/drawer-overlap-more-button';
import OverlapContext from '@components/overlap/overlap-context';
import * as dialog from '@constants/dialogs';
import DetailCard from '@shared/list-card/detail-card';
import OverlapStatusRow from '@shared/overlap-status-row';
import '../shared/list-item/list-item.scss';

const DrawerOverlapCard = ({ entity, isOpportunity, toggleHighlightGeometry }) => {
  const dispatch = useDispatch();
  const { isSelected, mainEntity, selectEntity } = useContext(OverlapContext);

  const onViewStatusClick = useCallback(event => {
    event.stopPropagation();
    const {
      agency_name,
      category_dict,
      id,
      icon,
      name,
      start_date,
      end_date,
      overlap_status,
      type_name
    } = entity;
    const params = {
      entityId: mainEntity.id,
      entity: {
        agency_name,
        category_dict,
        id,
        icon,
        name,
        start_date,
        end_date,
        overlap_status,
        type_name
      },
      overlapEntityId: id,
      overlapId: R.pathOr(null, [0, 'overlap'], overlap_status)
    };
    dispatch(openDashboardDialog(dialog.OVERLAP_STATUS, params));
  }, [dispatch, entity, mainEntity]);

  const onClick = useCallback(() => selectEntity(entity), [entity, selectEntity]);

  return (
    <div
      key={entity?.id}
      id={`list-item-${entity?.id}`}
      onClick={onClick}
      role="presentation"
      styleName="list-item overlap-list-item"
    >
      <DetailCard id={`list-item-${entity?.id}`} entity={entity} moreButton={<MoreButton entity={entity} />} />
      {isSelected(entity.id) &&
        <div styleName="additional">
          <DrawerOverlapToggle toggleHighlightGeometry={toggleHighlightGeometry} />
        </div>
      }
      <div styleName="footer">
        <OverlapStatusRow
          isOpportunity={isOpportunity}
          itemEntityType={entity.type_name}
          mainAgency={mainEntity.agency}
          mainEntityType={mainEntity.type_name}
          mainOverlapStatus={mainEntity.overlap_status}
          onClick={onViewStatusClick}
          overlapStatus={entity.overlap_status}
          showShadow
        />
      </div>
    </div>
  );
};

DrawerOverlapCard.propTypes = {
  entity: PropTypes.object,
  isOpportunity: PropTypes.bool,
  toggleHighlightGeometry: PropTypes.func
};

export default memo(DrawerOverlapCard);
