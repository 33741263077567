/* eslint-disable react/jsx-no-bind */
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { closeDashboardDialog } from '@actions/dashboard-actions';
import { deleteCustomField } from '@actions/workflow-actions';
import * as dialog from '@constants/dialogs';
import Dialog from '@shared/dialogs/dialog';
import DialogActions from '@shared/dialogs/dialog-actions';

const DeleteTaskTypeFieldDialog = () => {
  const dispatch = useDispatch();
  const dashboard = useSelector(state => state.dashboard);
  const { activeDialogs } = dashboard;
  const isDialogActive = activeDialogs[dialog.DELETE_TASK_TYPE_FIELD];
  if (!isDialogActive) {
    return null;
  }

  const dialogParams = dashboard.dialogParams[dialog.DELETE_TASK_TYPE_FIELD];
  const { id, name, task_type } = dialogParams;

  const onClose = () => dispatch(closeDashboardDialog(dialog.DELETE_TASK_TYPE_FIELD));

  const onDelete = () => dispatch(deleteCustomField(id, { name, task_type }, onClose));

  return (
    <Dialog
      actions={
        <DialogActions
          onCancel={onClose}
          onSubmit={onDelete}
          submitColor="secondary"
          submitLabel="DELETE"
        />
      }
      title="Delete field?"
    >
      Field "{name}" will be permanently deleted. This cannot be undone.
    </Dialog>
  );
};

export default DeleteTaskTypeFieldDialog;
