import React, {memo, useCallback, useMemo, useRef} from 'react';
import PropTypes from 'prop-types';

import { useDispatch, useSelector } from 'react-redux';
import { useLegacyTypes, useMemoFilterMulti } from '../hooks';

import { PureDataTable } from '@components/shared/data-table';

import { getLoader } from '@selectors/data-table-selector';
import { getColumns, getOptimizedPortalRows } from '../selectors';

import { push } from 'connected-react-router';
import { fetchPortalData } from '../actions';

class PurePortalDataTable extends PureDataTable {
  // Cuts out some of the logic.
  componentDidMount() {}
  componentDidUpdate() {}
  getIsDataTypeEmpty = () => {
    const { dataTypesCount } = this.props;
    return dataTypesCount === 0;
  };
}

PurePortalDataTable.propTypes = {
  ...PureDataTable.propTypes,
  dataTypesCount: PropTypes.number
};

const PortalDataTable = ({dataType, subType}) => {
  // Replicates mapStateToProps bindings with hooks instead.
  // Alters filter binding to be compatible with new filters code.
  // Alters selectors to improve memoization.
  const dispatch = useDispatch();
  const [legacyDataType, legacySubType] = useLegacyTypes(dataType, subType);
  const previousFetchTypes = useRef({});

  const stableProps = useMemo(() => ({dataType, subType}), [dataType, subType]);
  const loader = useSelector(state => getLoader(state, stableProps));
  const dataTypesCount = useSelector(state => {
    if (legacySubType) {
      return state.dataTypes.stats?.stats?.count?.[legacyDataType]?.[legacySubType];
    }
    return state.dataTypes.stats?.stats?.count?.[legacyDataType];
  });
  const rows = useSelector(state => getOptimizedPortalRows(state, dataType, subType));
  const rowsEmpty = rows === null || rows.length === 0;
  const columns = useSelector(state => getColumns(state, dataType, subType));

  const [{filterParams, orderParams, pageParams}, filterChanged] = useMemoFilterMulti(dataType, subType);

  const dispatchPush = useCallback(url => dispatch(push(url)), [dispatch]);

  const {current: prevFetch} = previousFetchTypes;
  const dataTypeChange = dataType !== prevFetch.dataType || subType !== prevFetch.subType;
  const doFetch = (
    filterChanged ||
    (dataTypeChange && (
      loader || // If loader is true, then there may be an incomplete fetch, so retry the fetch
      (dataTypesCount !== 0 && rowsEmpty)
    ))
  );
  if (doFetch) {
    setTimeout(() => {
      dispatch(fetchPortalData(dataType, subType, filterParams, orderParams, pageParams));
    }, 0);
    previousFetchTypes.current = {dataType, subType};
  }

  return (
    <PurePortalDataTable
      columns={columns}
      dataType={legacyDataType}
      subType={legacySubType}
      dataTypesCount={dataTypesCount}
      loader={loader || doFetch}
      push={dispatchPush}
      rows={rows}
    />
  );
};

PortalDataTable.propTypes = {
  dataType: PropTypes.string,
  subType: PropTypes.string
};

export default memo(PortalDataTable);
