import { getTrayConfig } from '@constants/config';

// Similar to arrayToObjectIds() in 'utils/data-types-utils.js',
// but this one, fills the entry with a 'true' boolean value (to flag
// that the id is checked).
const arrayToChecked = array => {
  const object = {};
  if (array) {
    array.forEach(element => {
      object[element.id] = true;
    });
  }
  return object;
};

// Convert the object containing the entities selected in the map, into
// and object still grouped by entity type, but keyed by each item id,
// and containing a boolean value, to be able to select/unselect them.
export const entitiesToChecked = entities => {
  const checked = {};
  Object.keys(entities).forEach(key => {
    if (key === 'entities') {
      checked[key] = {};
      Object.keys(entities[key]).forEach(entityKey => {
        checked[key][entityKey] = arrayToChecked(entities[key][entityKey]);
      });
    } else {
      checked[key] = arrayToChecked(entities[key]);
    }
  });
  return checked;
};

// Convert the entities objects which has a boolean for each entity id,
// into one with only the ids, as a list.
export const entitiesToIds = (entities, flattenEntities) => {
  const entityIds = {};
  Object.keys(entities).forEach(key => {
    const entries = entities[key];
    entityIds[key] = [];
    if (key === 'entities') {
      if (flattenEntities) {
        // When adding entities to a group, we can use a flat list of ids.
        // since entities share the same table, the ids doesn't repeat
        // for different entity types.
        Object.keys(entries).forEach(entryKey => {
          Object.keys(entries[entryKey]).forEach(id => {
            if (entries[entryKey][id]) {
              entityIds[key].push(id);
            }
          });
        });
      } else {
        entityIds[key] = {};
        Object.keys(entries).forEach(entryKey => {
          entityIds[key][entryKey] = [];
          Object.keys(entries[entryKey]).forEach(id => {
            if (entries[entryKey][id]) {
              entityIds[key][entryKey].push(id);
            }
          });
        });
      }
    } else {
      Object.keys(entries).forEach(entryKey => {
        if (entries[entryKey]) {
          entityIds[key].push(entryKey);
        }
      });
    }
  });
  return entityIds;
};

// Find entity 'key' entry on the list of selected entities.
export const findTrayEntity = (key, entityType, selectedEntities) => {
  const entities = selectedEntities[key];
  if (entities) {
    return key === 'entities' ? entities[entityType] : entities;
  }
  return {};
};

// Returns true if the specified 'item' id exists in the list of selected entities,
// using the key and entity type as a path within the 'selectedEntities' object.
export const isTrayEntitySelected = (key, entityType, selectedEntities, item) => {
  const entities = findTrayEntity(key, entityType, selectedEntities);
  return entities[item.id];
};

// Similar to isTrayEntitySelected() but this one checks for the whole entity type category:
export const isTrayCategorySelected = (key, entityType, selectedEntities) => {
  const entities = findTrayEntity(key, entityType, selectedEntities);
  return Object.values(entities).every(entity => entity);
};

// Return the title for a tray for the specified entity.
export const getTrayTitle = entity => {
  const trayConfig = getTrayConfig();
  const config = trayConfig[entity.type_name];
  if (config && config.title) {
    const { title } = config;
    if (title.type === 'agency') {
      const { agency_name } = entity;
      return agency_name;
    } else
      if (title.type === 'category') {
        const { category_dict } = entity;
        if (category_dict) {
          const titleCategory = category_dict.find(category => category.name === title.source);
          return titleCategory.value;
        }
      } else
        if (title.type === 'field') {
          return entity[title.source];
        }
  }
  return null;
};
