import React, { memo, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import HeaderAvatar from '@components/portal/details/header-avatar';
import { colors } from '@constants/colors';
import Skeleton from '@material-ui/lab/Skeleton';
import {
  dashboardDetailsDataSelector
} from '@selectors/dashboard-details-selector';
import { getAgencyIcon } from '@utils/icon-utils';
import './header.scss';

const Header = () => {
  const { dataType } = useParams();
  const data = useSelector(state => dashboardDetailsDataSelector(state, dataType));
  const { agency_type: agencyTypes } = useSelector(state => state.dataTypes);

  const loaded = useMemo(() => typeof data !== 'undefined' && data !== null, [data]);

  return (
    <div styleName="header-container">
      {dataType === 'agency' &&
        <HeaderAvatar loaded={loaded} src={getAgencyIcon(agencyTypes?.[data?.type])} style={{ backgroundColor: colors.neutral.white }} />
      }
      {dataType === 'user' &&
        <HeaderAvatar loaded={loaded} text={data?.name?.[0] || ''} style={{ backgroundColor: '#D8D8D8' }} />
      }
      <div styleName="header-text">
        {!loaded && <Skeleton variant="text" width="100%" height="1.75rem" />}
        {loaded && <div>{data.name}</div>}
        {!loaded && dataType === 'user' && <Skeleton variant="text" width="100%" height="1.25rem" />}
        {loaded && dataType === 'user' && <div styleName="header-subtext">{data.is_active ? 'Active' : 'Inactive'}</div>}
      </div>
    </div>
  );
};

export default memo(Header);
