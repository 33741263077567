import React from 'react';
import PropTypes from 'prop-types';
import DownloadAll from '@components/attachments/download-all';
import AddButton from '@shared/add-button';
import FileUpload from '@shared/files/file-upload';
import './section.scss';

const Header = ({
  addLabel, count, edit, headerLabel, onAdd, attachmentsHeader, taskId, attachments
}) => (
  <div styleName="section-header">
    <div styleName="section-header-title">
      <div styleName="section-header-title-text">
        {headerLabel}
      </div>
      {count > 0 && <div styleName="section-header-title-count">{count}</div>}
    </div>
    {edit && attachmentsHeader && (
      <div styleName="section-header-action">
        <FileUpload contentType="task" objectId={taskId}>
          <AddButton tooltip={addLabel} onClick={onAdd} />
        </FileUpload>
      </div>
    )}
    {attachments && attachments.length > 0 && !taskId.includes('temp') &&
      <DownloadAll attachments={attachments} />
    }
    {edit && !attachmentsHeader && (
      <div styleName="section-header-action">
        <AddButton tooltip={addLabel} onClick={onAdd} />
      </div>
    )}
  </div>
);

Header.propTypes = {
  addLabel: PropTypes.string,
  attachments: PropTypes.array,
  attachmentsHeader: PropTypes.bool,
  count: PropTypes.number,
  edit: PropTypes.bool,
  headerLabel: PropTypes.string,
  onAdd: PropTypes.func,
  taskId: PropTypes.string
};

export default Header;
