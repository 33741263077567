import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import debounce from 'lodash.debounce';
import { DrawingManager, Polygon } from '@react-google-maps/api';
import {
  openAreaResultsTray,
  setMapFilters,
  setDrawingDone,
  setDrawingPath,
  setDrawingProps
} from '@actions/map-actions';
import { mapConfig } from '@constants/component-configs';
import { getEntityCount } from '@utils/entity-utils';
import {
  convertFromGoogleMapsShape,
  getFiltersFromGoogleMapsShape,
  getLineDistance,
  getShapeArea,
  renderDistance,
  setShapeEventListener
} from '@utils/map-tools-utils';
import { convertToGoogleMapsShape } from '@utils/map-utils';
import { destroyMeasurement } from '@utils/measurement-utils';
import MeasurementTool from './measurement-tool';
import ShapeTool from './shape-tool';

class DrawingTools extends Component {
  constructor() {
    super();
    this.state = {
      active: false,
      mapsShape: null,
      complete: false
    };
    this.debounceCalculateInside = debounce(this.calculateInside.bind(this), 500);
  }

  UNSAFE_componentWillMount() {
    this.initializePage();
    if (!this.state.active && Boolean(this.props.mode.length)) {
      this.setState({ active: true });
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.mode !== nextProps.mode) {
      this.setState({ active: true });
    }
  }

  initializePage = () => {
    const { shape } = this.props;
    if (shape) {
      this.setState({
        complete: true,
        mapsShape: setShapeEventListener(convertToGoogleMapsShape(shape), this.debounceCalculateInside)
      });
      this.props.openAreaResultsTray();
    }
  };

  calculateInside() {
    const { mapsShape } = this.state;
    const geom_in = getFiltersFromGoogleMapsShape(mapsShape);
    this.props.setMapFilters({ geom_in, shape_selection: true });
    this.setState({ active: false });
  }

  resetShape = () => {
    this.removeShape(this.state.mapsShape);
    this.setState({ mapsShape: null, active: false, complete: false });
    this.props.setDrawingProps({ shape: null, mode: '' });
    this.props.setMapFilters({ geom_in: null, shape_selection: false });
  };

  // Closes the measurement window & functionality:
  closeMeasurement = () => {
    destroyMeasurement(this.props.setDrawingDone);
    this.props.setDrawingProps({ mode: '', path: null });
  };

  // Resets the measurement coordinates to start again:
  resetMeasurement = () => {
    destroyMeasurement(this.props.setDrawingDone);
    this.props.setDrawingProps({ mode: 'measurement', path: null });
  };

  removeShape = mapsShape => {
    if (mapsShape && mapsShape.setMap) {
      mapsShape.setMap(null);
    }
  };

  calculateShape = mapsShape => {
    this.removeShape(this.state.mapsShape);
    this.setState({ mapsShape: setShapeEventListener(mapsShape, this.debounceCalculateInside) });
    const shape = convertFromGoogleMapsShape(mapsShape);
    this.props.setDrawingProps({ shape });
    this.calculateInside();
    this.props.openAreaResultsTray();
  };

  onPolygonComplete = mapsShape => {
    if (mapsShape.getPath().getArray().length < 3) {
      this.removeShape(mapsShape);
      return;
    }
    this.calculateShape(mapsShape);
  };

  onCircleComplete = mapsShape => this.calculateShape(mapsShape);

  setPolygonRef = ref => {
    this.polygonRef = ref;
  };

  getEntities = () => {
    const { mapsShape } = this.state;
    if (mapsShape) {
      const { entityCounts } = this.props;
      return [
        { label: 'Area', value: `${getShapeArea(mapsShape)} square miles` },
        ...(entityCounts ? entityCounts : [])
      ];
    }
    return null;
  };

  render() {
    const { done, mode, path } = this.props;
    if (mode === 'measurement') {
      const distance = getLineDistance(path);
      return (
        <div>
          <MeasurementTool
            done={done}
            closeWindow={this.closeMeasurement}
            distance={{
              label: 'Distance',
              value: renderDistance(distance)
            }}
            onMeasureDone={this.props.setDrawingDone}
            resetWindow={this.resetMeasurement}
            setDrawingPath={this.props.setDrawingPath}
          />
        </div>
      );
    }
    const { active, complete, mapsShape } = this.state;
    return (
      <div>
        {Boolean(mode.length) && active &&
          <DrawingManager
            drawingMode={mode}
            options={mapConfig.drawingManager}
            onPolygonComplete={this.onPolygonComplete}
            onCircleComplete={this.onCircleComplete}
          />
        }
        {mapsShape && complete &&
          <Polygon
            editable
            paths={mapsShape.getPath()}
            ref={this.setPolygonRef}
          />
        }
        {Boolean(mode.length) &&
          <ShapeTool
            closeWindow={this.resetShape}
            entities={this.getEntities()}
          />
        }
      </div>
    );
  }
}

DrawingTools.propTypes = {
  done: PropTypes.bool,
  entityCounts: PropTypes.array,
  mapsShape: PropTypes.object,
  mode: PropTypes.string,
  openAreaResultsTray: PropTypes.func,
  path: PropTypes.object,
  setDrawingDone: PropTypes.func,
  setDrawingPath: PropTypes.func,
  setDrawingProps: PropTypes.func,
  setMapFilters: PropTypes.func,
  shape: PropTypes.object
};

const mapStateToProps = state => {
  const { drawing: { done, mode, shape, path } } = state.map;
  const entityCounts = getEntityCount(state.entities.types);
  return { entityCounts, done, mode, shape, path };
};

const mapDispatchToProps = {
  openAreaResultsTray,
  setMapFilters,
  setDrawingDone,
  setDrawingPath,
  setDrawingProps
};

export default connect(mapStateToProps, mapDispatchToProps)(DrawingTools);
