import React from 'react';
import PropTypes from 'prop-types';
import Header from './header';
import Row from './row';
import './drawer.scss';

const Drawer = ({ rows }) => (
  <div styleName="gantt-drawer-container">
    <Header />
    {rows.map(row => <Row key={row.id} row={row} />)}
  </div>
);

Drawer.propTypes = {
  rows: PropTypes.array
};

export default Drawer;
