import React from 'react';
import {
  useHistory,
  useLocation,
  // useNavigate,
  useParams
} from 'react-router-dom';
import {
  getConfirmableDiscardChanges,
  showConfirmationDialog
} from '@actions/confirmation-actions';

// Since we replaced the 'react-router' library with the 'react-router-dom' one,
// the withRouter method is no longer available, since we should use hooks with
// the new library.
//
// But since we still use old React components and components can't use hooks,
// we still need withRouter, thus here's the official implementation for those cases.
//
// See:
//
//     https://reactrouter.com/en/6.4.0/start/faq#what-happened-to-withrouter-i-need-it
//
export const withRouter = Component => {
  const ComponentWithRouterProp = props => {
    const history = useHistory();
    const location = useLocation();
    // const navigate = useNavigate();  // useNavigate is for react-router v6
    const params = useParams();
    return (
      <Component
        {...props}
        location={location}
        params={params}
        router={{ location, history, /* navigate, */ params }}
      />
    );
  };

  return ComponentWithRouterProp;
};

export const routerWillLeave = (dispatch, nextLocation) => {
  showConfirmationDialog(
    'Discard changes',
    'You have unsaved changes. Navigating away from this page without saving will discard the changes.',
    getConfirmableDiscardChanges(nextLocation.pathname),
    { confirmationType: 'discard' }
  )(dispatch);
  return false;
};
