/* eslint-disable react/jsx-boolean-value */
import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import ControlLabel from './control-label';
import './form.scss';

const CheckboxControl = ({
  children, disabled, indeterminate, label, onChange, value
}) => {
  const handleChange = useCallback(event => onChange(event.target.checked), [onChange]);

  return (
    <div styleName="control-container checkbox-control">
      <FormControlLabel
        control={
          <Checkbox
            checked={value === true}
            color="primary"
            indeterminate={indeterminate}
            disabled={disabled}
            onChange={handleChange}
            size="small"
          />
        }
        label={<ControlLabel disabled={disabled} label={label} />}
      />
      {children && (
        <div styleName="nested-control">
          {children}
        </div>
      )}
    </div>
  );
};

CheckboxControl.propTypes = {
  children: PropTypes.array,
  disabled: PropTypes.bool,
  indeterminate: PropTypes.bool,
  label: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.bool
};

export default CheckboxControl;
