
export const DEFAULT_LOCALE = 'en';
export const FORMATS = {
  date: {
    default: {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit'
    }
  }
};
