import React from 'react';
import PropTypes from 'prop-types';
import AddButton from '@shared/add-button';
import FileUpload from '@shared/files/file-upload';

const FilesUploadButton = ({ objectId }) => (
  <FileUpload contentType="entity" objectId={objectId}>
    <AddButton tooltip="Add files" />
  </FileUpload>
);

FilesUploadButton.propTypes = {
  objectId: PropTypes.number
};

export default FilesUploadButton;
