// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.src-components-filters-chips-shared-___popover__popover.src-components-filters-chips-shared-___popover__popover{margin:0.25rem 0;max-width:30rem;max-height:32rem}
`, "",{"version":3,"sources":["webpack://./src/components/filters/chips/shared/popover.scss"],"names":[],"mappings":"AAAA,iHAEI,gBAAiB,CACjB,eAAgB,CAChB,gBAAiB","sourcesContent":[".popover {\n  &.popover {\n    margin: 0.25rem 0;\n    max-width: 30rem;\n    max-height: 32rem;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"popover": `src-components-filters-chips-shared-___popover__popover`
};
export default ___CSS_LOADER_EXPORT___;
