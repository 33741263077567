export const fonts = {
  xxxl: '1.5rem' /* 24px */,
  xxl: '1.25rem' /* 20px */,
  xl: '1.125rem' /* 18px */,
  lg: '1rem' /* 16px */,
  md: '0.875rem' /* 14px */,
  sm: '0.75rem' /* 12px */,
  xs: '0.625rem' /* 10px */,
  xxs: '0.5625rem' /* 9px */
};
