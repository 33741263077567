import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { authLogout } from '@actions/auth-actions';
import { Box, CircularProgress, Typography } from '@material-ui/core';
import './layouts/not-authenticated/not-authenticated.scss';

const Logout = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(authLogout());
  }, []);  // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div styleName="not-authenticated-container">
      <Box display="flex" justifyContent="center" alignItems="center">
        <Box display="flex" flexDirection="column" alignItems="center">
          <Typography variant="h6">Signing out...</Typography>
          <Box m={12}>
            <CircularProgress size={70} thickness={4} />
          </Box>
        </Box>
      </Box>
    </div>
  );
};

export default Logout;
