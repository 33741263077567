import React from 'react';
import PropTypes from 'prop-types';

import { getCardHeight } from '../utils';

import '../dashboard-card.scss';

const CardEmpty = ({ card }) => {
  const style = {
    height: getCardHeight(card),
    fontSize: 'inherit'
  };
  return (
    <div style={style} styleName="card-body-empty">
      No Results
    </div>
  );
};

CardEmpty.propTypes = {
  card: PropTypes.object
};

export default CardEmpty;
