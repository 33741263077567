import React from 'react';
import { useSelector } from 'react-redux';
import TaskItemCard from './task-item-card';
import { getTasks } from '@selectors/workflow-selector';
import './task-list.scss';

const TaskList = () => {
  const { cycle } = useSelector(state => state.workflow);
  const tasks = useSelector(getTasks);

  if (!cycle) {
    return null;
  }

  const empty = () => {
    if (cycle.tasks?.length === 0) {
      // The unfiltered tasks list has no tasks yet:
      return 'No task created.';
    }
    // There are tasks but you are not allowed to see them:
    return 'You have no assigned tasks in this workflow.';
  };

  const taskCount = tasks?.length || 0;

  return (
    <div styleName="task-list-container">
      {taskCount === 0 && (
        <div styleName="task-list-empty">
          {empty()}
        </div>
      )}
      {taskCount > 0 && (
        <ul styleName="task-list-body">
          {tasks.map(task => (
            <TaskItemCard key={task.id} task={task} />
          ))}
        </ul>
      )}
    </div>
  );
};

export default TaskList;
