export const CONFLICT_FILTERS = {
  OPEN: 'open',
  OPPORTUNITIES: 'opportunities',
  PENDING: 'pending',
  RESOLVED: 'resolved'
};

export const DEFAULT_FILTERS = {
  [CONFLICT_FILTERS.OPEN]: true,
  [CONFLICT_FILTERS.OPPORTUNITIES]: true,
  [CONFLICT_FILTERS.PENDING]: true,
  [CONFLICT_FILTERS.RESOLVED]: false
};
