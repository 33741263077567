// Returns true if all data types that we keep track finished loading:
import { createSelector } from 'reselect';
import * as R from 'ramda';
import { isEmpty } from 'lodash';
import {
  ENTITY_TRAY,
  LAYER_TRAY,
  GROUP_TRAY,
  OVERLAP_TRAY
} from '@constants/map-trays';
import { getEnabledEntities } from '@selectors/entities-selector';
import { getLayersIfEnabled } from '@selectors/layers-selector';
import { groupMarkers } from '@utils/markers-utils';

export const getMap = state => state.map;
const hasAnyTrueValues = R.pipe(R.values, R.any(value => value === true));
const hasAnyNonEmptyValue = R.pipe(R.values, R.any(value => !isEmpty(value)));
const getMapLoadingFromState = R.defaultTo({}, R.path(['map', 'loading']));
export const getZoom = state => state.map.viewport.zoom;
export const getTrays = state => state.map.trays;
const getMapModes = state => state.map.modes;
export const getToolbarConfig = state => state.map.toolbar;

const getIds = (overlap, entityName) => {
  if (!isEmpty(overlap) && !isEmpty(overlap[entityName]) && Array.isArray(overlap[entityName])) {
    return overlap[entityName].map(entity => entity.id);
  }
  return [];
};

const getLayerIds = overlap => {
  const ids = {};
  if (overlap) {
    for (const key of Object.keys(overlap)) {
      // Layers are within the overlaps, but we share that space with entities thus skip them:
      if (key !== 'entities') {
        ids[key] = getIds(overlap, key);
      }
    }
  }
  return ids;
};

export const isAnyMapItemLoading = createSelector([getMapLoadingFromState], loading => hasAnyTrueValues(loading));

const DEFAULT_TRAY_TOP = {};
const DEFAULT_TRAY_SECOND = {};
const DEFAULT_TRAY_HOVER = {};
export const getTrayTop = createSelector([getTrays], trays => trays[trays.length - 1] || DEFAULT_TRAY_TOP);
export const getTraySecond = createSelector([getTrays], trays => trays[trays.length - 2] || DEFAULT_TRAY_SECOND);
export const getTrayDepth = createSelector([getTrays], trays => trays.length);
export const getTrayHover = createSelector([getTrayTop], ({ hover }) => hover || DEFAULT_TRAY_HOVER);
export const getTraySelectedSegmentId = createSelector([getTrayTop], R.pathOr(null, ['selectedSegment', 'id']));

const getTrayOverlap = createSelector(
  [getTrayTop], ({trayType, data}) => (trayType === OVERLAP_TRAY && data.overlap) || {}
);

export const isAnyTrayItemHovered = createSelector([getTrayHover], hovering => hasAnyNonEmptyValue(hovering));

const getTrayEntity = createSelector(
  [getTrayTop], ({trayType, data}) => (trayType === ENTITY_TRAY && data.entity) || null
);

export const getTrayEntitiesIds = createSelector(
  [getTrayEntity, getTrayOverlap], (entity, overlap) => entity ? [entity.id] : getIds(overlap, 'entities')
);

export const getHoverEntityId = createSelector([getTrayHover], R.pathOr(null, ['entity', 'id']));

export const getTrayLayer = createSelector(
  [getTrayTop], ({trayType, data}) => (trayType === LAYER_TRAY && data.layer) || null
);

export const getTrayLayersIds = createSelector(
  [getTrayTop, getTrayLayer, getTrayOverlap],
  (tray, layer, overlap) => layer ? {[tray.data.type]: [layer.id]} : getLayerIds(overlap)
);

export const getHoverLayerId = createSelector([getTrayHover], R.pathOr(null, ['layer', 'id']));

export const getTrayGroup = createSelector(
  [getTrayTop], ({trayType, data}) => (trayType === GROUP_TRAY && data.group) || null
);

export const getTrayGroupIds = createSelector(
  [getTrayGroup, getTrayOverlap], (group, overlap) => group ? [group.id] : getIds(overlap, 'groups')
);

export const getHoverGroupId = createSelector([getTrayHover], R.pathOr(null, ['group', 'id']));

export const getOverlappingMarkers = createSelector([
  getEnabledEntities,
  getLayersIfEnabled,
  getZoom
], (
  entities,
  layers,
  zoom
) => groupMarkers(entities, layers, zoom)
);

export const getMapModesSet = createSelector(
  [getMapModes],
  modes => new Set(modes)
);
