import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { openDashboardDialog } from '@actions/dashboard-actions';
import RemoveDateRangeDialog from '@components/group/dialogs/remove-daterange';
import * as dialog from '@constants/dialogs';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import CalendarToday from '@material-ui/icons/CalendarToday';
import CancelIcon from '@material-ui/icons/Cancel';
import EditIcon from '@material-ui/icons/Edit';
import { getGroupDateRange } from '@utils/group-utils';
import { canEditGroups } from '@utils/permission-utils';
import '../drawer.scss';

// Renders the date range row on the group details drawer.
const DateRow = () => {
  const dispatch = useDispatch();
  const { group } = useSelector(state => state.groups.edit);

  const openDateRangeDialog = useCallback(() => dispatch(openDashboardDialog(dialog.DATE_RANGE)), [dispatch]);

  const removeGroupDate = useCallback(() => dispatch(openDashboardDialog(dialog.REMOVE_DATE_RANGE)), [dispatch]);

  const renderDate = date => {
    if (!canEditGroups()) {
      return null;
    }

    if (date) {
      return (
        <div>
          <IconButton onClick={removeGroupDate}>
            <Tooltip title="Clear date range">
              <CancelIcon />
            </Tooltip>
          </IconButton>
          <IconButton onClick={openDateRangeDialog}>
            <Tooltip title="Edit date range">
              <EditIcon />
            </Tooltip>
          </IconButton>
        </div>
      );
    }

    return (
      <Button color="primary" onClick={openDateRangeDialog}>ADD DATE RANGE</Button>
    );
  };

  const date = getGroupDateRange(group);
  const label = date ? date : 'No date range';
  const labelStyle = date ? 'label set' : 'label';
  return (
    <div styleName="set-value-row">
      <div styleName="icon"><CalendarToday /></div>
      <div styleName={labelStyle}>{label}</div>
      {renderDate(date)}
      <RemoveDateRangeDialog />
    </div>
  );
};

export default DateRow;
