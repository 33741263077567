import { connect } from 'react-redux';
import { mapBoundsGroupsSelector } from '@selectors/groups-selector';
import MapData from '../../map-data';
import MapGroup from './group-figure';

const mapStateToProps = state => ({
  elementRenderer: MapGroup,
  list: mapBoundsGroupsSelector(state)
});

export default connect(mapStateToProps, {})(MapData);
