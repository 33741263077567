import { connect } from 'react-redux';
import { closeDashboardDialog } from '@actions/dashboard-actions';
import { setDateRange } from '@actions/groups-actions';
import * as dialog from '@constants/dialogs';
import { dateToString} from '@utils/date-utils';
import BaseDateRangeDialog from '@shared/dialogs/base-date-range-dialog';

class DateRange extends BaseDateRangeDialog {
  UNSAFE_componentWillReceiveProps(nextProps) {
    // If we are about to open this dialog
    if ((this.props.activeDialogs[dialog.DATE_RANGE] === false ||
         typeof this.props.activeDialogs[dialog.DATE_RANGE] === 'undefined') &&
        nextProps.activeDialogs[dialog.DATE_RANGE] === true) {
      // Sync it to the current dashboard's filter state
      this.setState(this.getDefaultState());
    }
  }

  resetFilters = () => this.setState({
    start: null,
    end: null,
    contain: false
  });

  getSetFiltersFunction = () => this.props.setFilter.bind(this);

  getFilters = () => ({
    start_date: dateToString(this.state.start),
    end_date: dateToString(this.state.end)
  });

  showContain = () => false;
}

export const mapStateToProps = state => {
  const { activeDialogs } = state.dashboard;
  const { start_date: start, end_date: end } = state.groups.edit.group.auto_area || { start_date: null, end_date: null };
  return { activeDialogs, start, end, contain: false };
};

export default connect(mapStateToProps, { closeDialog: closeDashboardDialog, setFilter: setDateRange })(DateRange);
