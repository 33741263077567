import React, { memo } from 'react';
import Logo from '@icons/header/logo';
import ActionItems from './action-items';
import Links from './links';
import { getConfig } from '@utils/config-utils';
import './header.scss';

const Header = () => (
  <div styleName="header-container">
    <div styleName="header-inner-container">
      <div styleName="logo"><Logo/></div>
      <div styleName="label">{getConfig().cityName}</div>
      <Links />
    </div>
    <ActionItems />
  </div>
);

export default memo(Header);
