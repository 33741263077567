import React from 'react';
import { useSelector } from 'react-redux';
import { getGroupIsLoading } from '@selectors/groups-selector';
import DotmapsLoader from '@shared/dotmaps-loader';

const LoadingIndicator = () => {
  const loading = useSelector(getGroupIsLoading);
  return <DotmapsLoader display={loading} />;
};

export default LoadingIndicator;
