import React, { memo } from 'react';
import PropTypes from 'prop-types';
import PublicViewerSourceField from '@components/people/public-viewer-source-field';
import PublicViewerWhatField from '@components/people/public-viewer-what-field';
import '../portal/details/sections.scss';

const PublicViewerSection = ({ edit }) => {
  return (
    <div styleName="sections-container">
      <div key="public_viewer" styleName="sections-section">
        <div styleName="sections-title">Public Viewer settings</div>
        <div styleName="sections-subtitle">
          Determine which contact information should be displayed on the Public Viewer for each project.
        </div>
      </div>
      <PublicViewerSourceField edit={edit} />
      <PublicViewerWhatField edit={edit} />
    </div>
  );
};

PublicViewerSection.propTypes = {
  edit: PropTypes.bool
};

export default memo(PublicViewerSection);
