import React, { memo } from 'react';
import PropTypes from 'prop-types';
import '../forms/forms.scss';

const DrawerReviewSectionTitle = ({ tab }) => (
  <div styleName="review-title">
    <div styleName="form-title" id={`tab-section-${tab.id}-title`}>
      <div styleName="form-title-label-large">
        {tab.label}
      </div>
    </div>
  </div>
);

DrawerReviewSectionTitle.propTypes = {
  tab: PropTypes.object
};

export default memo(DrawerReviewSectionTitle);
