// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.src-components-shared-ui-library-attribute-___attribute-label__container{display:inline-flex;align-items:center;gap:0.25rem}.src-components-shared-ui-library-attribute-___attribute-label__container.src-components-shared-ui-library-attribute-___attribute-label__contained{padding:0rem 0.25rem;border-radius:0.25rem;background:#EEE}.src-components-shared-ui-library-attribute-___attribute-label__container .src-components-shared-ui-library-attribute-___attribute-label__icon{display:flex;width:1.125rem;height:1.125rem;justify-content:center;align-items:center}.src-components-shared-ui-library-attribute-___attribute-label__container .src-components-shared-ui-library-attribute-___attribute-label__icon>*{width:100%;height:100%}.src-components-shared-ui-library-attribute-___attribute-label__container .src-components-shared-ui-library-attribute-___attribute-label__text{color:rgba(0,0,0,0.8);font-size:0.875rem;font-style:normal;font-weight:400;line-height:1.25rem}
`, "",{"version":3,"sources":["webpack://./src/components/shared/ui-library/attribute/attribute-label.scss"],"names":[],"mappings":"AAAA,0EACE,mBAAoB,CACpB,kBAAmB,CACnB,WAAY,CAHd,mJAMI,oBAAqB,CACrB,qBAAsB,CACtB,eAAgB,CARpB,+IAYI,YAAa,CACf,cAAe,CACf,eAAgB,CAChB,sBAAuB,CACvB,kBAAmB,CAhBrB,iJAkBI,UAAW,CACX,WAAY,CAnBhB,+IAwBI,qBAA0B,CAC1B,kBAAmB,CACnB,iBAAkB,CAClB,eAAgB,CAChB,mBAAoB","sourcesContent":[".container {\n  display: inline-flex;\n  align-items: center;\n  gap: 0.25rem;\n\n  &.contained {\n    padding: 0rem 0.25rem;\n    border-radius: 0.25rem;\n    background: #EEE;\n  }\n\n  .icon {\n    display: flex;\n  width: 1.125rem;\n  height: 1.125rem;\n  justify-content: center;\n  align-items: center;\n   & > * {\n    width: 100%;\n    height: 100%;\n   }\n  }\n\n  .text {\n    color: rgba(0, 0, 0, 0.80);\n    font-size: 0.875rem;\n    font-style: normal;\n    font-weight: 400;\n    line-height: 1.25rem; /* 142.857% */\n  }\n};\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `src-components-shared-ui-library-attribute-___attribute-label__container`,
	"contained": `src-components-shared-ui-library-attribute-___attribute-label__contained`,
	"icon": `src-components-shared-ui-library-attribute-___attribute-label__icon`,
	"text": `src-components-shared-ui-library-attribute-___attribute-label__text`
};
export default ___CSS_LOADER_EXPORT___;
