import { createSelector } from 'reselect';
import { getMarkerVisibleZoom } from '@constants/config';
import { getZoom } from '@selectors/map-selector';

const getCenters = state => state.markers.centers;
const getGroupCenters = state => state.groups.markers.centers;
const getBounds = state => state.map.viewport.bounds;

// The centers bounds check is not like the entities and layers ones
// (which checks that the whole segments of the entities intersects with the viewport).
//
// For centers, since we are only displaying a point (which is the cluster with the number
// of items), we only need to check if it's contained in the viewport.
// And for contains() we only need an object with (lat, lng) attributes, not a Google Map's
// LatLngBounds object, we just use the center point which is already there (thus less
// calculations).
export const boundsMarkersSelector = createSelector(
  [getCenters, getBounds, getZoom],
  (centers, bounds, zoom) => {
    const markerVisible = (zoom >= getMarkerVisibleZoom());

    if (markerVisible && bounds) {
      return centers.filter(({ center }) => bounds.contains(center));
    }
    return [];
  }
);

export const boundsMarkersGroupSelector = createSelector(
  [getGroupCenters, getBounds, getZoom],
  (centers, bounds, zoom) => {
    const markerVisible = (zoom >= getMarkerVisibleZoom());

    if (markerVisible && bounds) {
      return centers.filter(({ center }) => bounds.contains(center));
    }
    return [];
  }
);
