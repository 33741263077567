import React, { Component } from 'react';
import PropTypes from 'prop-types';
import DateWidget from '../date-widget';
import { PriorityIcon } from '@utils/icon-utils';
import { calculateDays } from '@utils/timeline-chart-utils';
import './triptych-widget.scss';

class TriptychWidget extends Component {
  renderDuration = () => {
    const { end, start } = this.props;
    const duration = calculateDays(start, end);
    return `${duration} ${duration === 1 ? 'day' : 'days'}`;
  };

  render() {
    const { displayDuration, end, name, priority, start, style } = this.props;
    return (
      <div styleName="triptych-widget-container" style={style}>
        <DateWidget start={start} end={end} />
        {!priority && <div styleName="bull">&bull;</div>}
        {priority && <div styleName="bull-left">&bull;</div>}
        {priority && <PriorityIcon priority={priority} />}
        {priority && <div styleName="bull-right">&bull;</div>}
        {displayDuration && this.renderDuration()}
        {name}
      </div>
    );
  }
}

TriptychWidget.propTypes = {
  displayDuration: PropTypes.bool,
  end: PropTypes.string,
  name: PropTypes.string,
  priority: PropTypes.number,
  start: PropTypes.string,
  style: PropTypes.object
};

TriptychWidget.defaultProps = {
  // Default styling includes bottom padding:
  style: { padding: '0 0 0.75rem 0' }
};

export default TriptychWidget;
