import * as R from 'ramda';
import { HANDLE_SERVER_ERROR } from '@constants/action-types';
import initialState from '../store/initial-state';

const reducer = (state = initialState.errors, action) => {
  switch (action.type) {
  case HANDLE_SERVER_ERROR: {
    let error;
    if (action.payload.error instanceof Error) {
      // Copy over standard error properties
      error = {
        name: action.payload.error.name,
        message: action.payload.error.message
      };
      // The axios error contains a config object which is unsafe to put in the store.
      // Copy over all other properties except for config.
      Object.keys(action.payload.error)
        .filter(key => key !== 'config')
        .forEach(key => {
          error[key] = action.payload.error[key];
        });
    } else {
      error = action.payload.error;
    }
    return R.append(error, state);
  }
  default:
    return state;
  }
};

export default reducer;
