/* eslint-disable react/jsx-no-bind */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { closeDashboardDialog } from '@actions/dashboard-actions';
import { addToGroups } from '@actions/groups-actions';
import * as dialog from '@constants/dialogs';
import { autocompleteSearchStyles } from '@constants/mui-theme';
import AsyncInputSearch from '@shared/async-input-search';
import Dialog from '@shared/dialogs/dialog';
import DialogActions from '@shared/dialogs/dialog-actions';
import { prepareGroupsForBulkAdd } from '@utils/group-utils';
import { shallowEqual } from '@utils/react-utils';
import EntityListItem from './entity-list-item';

class AddRecords extends Component {
  state = {
    entities: []
  };

  shouldComponentUpdate(nextProps, nextState) {  // eslint-disable-line no-unused-vars
    return (
      !shallowEqual(nextState.entities, this.state.entities) ||
      !shallowEqual(nextProps.activeDialogs, this.props.activeDialogs) ||
      !shallowEqual(nextProps.dialogParams, this.props.dialogParams) ||
      !shallowEqual(nextProps.groupTypeName, this.props.groupTypeName)
    );
  }

  handleCancel = () => this.props.closeDashboardDialog(dialog.ADD_RECORDS_TO_GROUP);

  handleEntityListChange = newValues => {
    const entities = newValues.map(newValue => newValue.value);
    this.setState({ entities });
  };

  handleSubmit = () => {
    const { dialogParams, groupTypeName } = this.props;
    const { group } = dialogParams;
    const payload = prepareGroupsForBulkAdd({
      selectedGroups: [group.id],
      entityIds: this.state.entities,
      includeOverlaps: false
    });
    this.props.addToGroups(groupTypeName, payload, null, false, null, true);
    this.props.closeDashboardDialog(dialog.ADD_RECORDS_TO_GROUP);
  };

  toOptions = results => results.suggestions.filter(
    suggestion => suggestion.type !== 'Group' && !suggestion.type.startsWith('Attribute_')
  ).map(
    ({ id, display, extra, type }) => ({ id, title: display, value: id, extra, type })
  );

  render() {
    const { activeDialogs, dialogParams } = this.props;
    if (!dialogParams || !dialogParams.group || !activeDialogs[dialog.ADD_RECORDS_TO_GROUP]) {
      return null;
    }
    return (
      <Dialog
        actions={
          <DialogActions
            disabledSubmit={this.state.entities.length === 0}
            onCancel={this.handleCancel}
            onSubmit={this.handleSubmit}
            submitLabel="ADD"
          />
        }
        title="Add records to group"
      >
        <AsyncInputSearch
          {...autocompleteSearchStyles.normal}
          avatarProps={{ type: 'entity' }}
          fieldName="entity_ids"
          onChange={this.handleEntityListChange}
          label="Search records"
          renderOption={(option, state) => <EntityListItem item={option} term={state.inputValue} />}
          toOptions={this.toOptions}
        />
      </Dialog>
    );
  }
}

AddRecords.propTypes = {
  activeDialogs: PropTypes.object,
  addToGroups: PropTypes.func,
  closeDashboardDialog: PropTypes.func,
  dialogParams: PropTypes.object,
  groupTypeName: PropTypes.string
};

const mapStateToProps = state => {
  const { activeDialogs, dialogParams } = state.dashboard;
  return { activeDialogs, dialogParams: dialogParams[dialog.ADD_RECORDS_TO_GROUP] };
};

export default connect(mapStateToProps, {
  addToGroups,
  closeDashboardDialog
})(
  AddRecords
);
