/* eslint-disable jsx-a11y/mouse-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/display-name */
import React from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import * as colors from '@constants/colors';
import DataFetchTooltip from '@shared/data-fetch-tooltip';
import DotmapsLoader from '@shared/dotmaps-loader';
import './tooltip.scss';

class BaseTooltip extends DataFetchTooltip {
  // Implement in subclass:
  renderHeader = () => null;
  renderContent = () => null;
  renderClickLabel = () => null;
  getTitle = () => null;
  onLinkClick = () => null;
  onMouseOver = event => null;  // eslint-disable-line no-unused-vars
  getDataType = () => null;

  // To check if other tooltips in this namespace
  // are open, to close them.
  getNameSpace = () => 'activity-log';

  renderTitle = () => (
    <div styleName="body-title body-item">
      {this.getTitle()}
    </div>
  );

  renderFooter = () => (
    <div styleName="footer">
      <div styleName="spacer" />
      <div
        role="presentation"
        onClick={this.onLinkClick}
        styleName="link"
      >
        {this.renderClickLabel()}
      </div>
    </div>
  );

  renderPopover = () => (
    <div
      onMouseOver={this.onMouseOverChildren}
      onMouseOut={this.onMouseOutChildren}
      role="presentation"
      styleName="tooltip-popover"
    >
      <div styleName="body">
        {this.renderHeader()}
        {this.renderTitle()}
        {this.renderContent()}
      </div>
      <div styleName="sep" />
      {this.renderFooter()}
    </div>
  );

  renderErrorPopover = () => (
    <div
      onMouseOver={this.onMouseOverChildren}
      onMouseOut={this.onMouseOutChildren}
      role="presentation"
      styleName="tooltip-popover"
    >
      <div styleName="body error">
        {this.renderErrorMessage()}
      </div>
    </div>
  );

  renderSpinner = () => (
    <div styleName="tooltip-popover">
      <div styleName="body spinner">
        <DotmapsLoader color={colors.dotmapsBlue} display />
      </div>
    </div>
  );

  hasError = () => {
    const { error, id } = this.props;
    return typeof error[id] !== 'undefined';
  };

  getErrorStatus = () => {
    const { error, id } = this.props;
    return R.pathOr(null, [id, 'response', 'status'], error);
  };

  renderErrorMessage = () => {
    const type = this.getDataType();
    // A 404 for projects/events/moratorium means it no longer exists,
    // but for users it means he's either disabled or we have no permission
    // to see the full details.
    if (this.getErrorStatus() === 404 && type !== 'user') {
      return `This ${type} no longer exists.`;
    }
    return `You cannot view this ${type}'s info.`;
  };

  openInNewWindow = url => window.open(url, '_blank');

  render() {
    const { children, data, text } = this.props;
    return (
      <div
        onMouseOver={this.onMouseOver}
        onMouseOut={this.delayHoverOff}
        role="presentation"
        styleName="tooltip-wrapper"
      >
        {children || text}
        {data && this.renderTooltip(this.renderPopover())}
        {this.hasError() && this.renderTooltip(this.renderErrorPopover())}
        {!data && !this.hasError() && this.renderTooltip(this.renderSpinner())}
      </div>
    );
  }
}

BaseTooltip.propTypes = {
  children: PropTypes.node,
  data: PropTypes.object,
  dataType: PropTypes.string,
  error: PropTypes.object,
  fetchTooltipData: PropTypes.func,
  text: PropTypes.string
};

BaseTooltip.defaultProps = {
  tooltipStyle: {
    backgroundColor: colors.overlayTextColor,
    borderRadius: '2px',
    boxShadow: `0 1px 18px 0 rgba(0, 0, 0, 0.12), 0 6px 10px 0 rgba(0, 0, 0, 0.14), 0 3px 5px -1px ${colors.dotmapsBlack20}`,
    width: '21.25rem',
    color: colors.textColor,
    fontFamily: 'Roboto, sans-serif',
    fontSize: '0.75rem',  // 12px
    lineHeight: '1.33',
    pointerEvents: 'auto'
  }
};

export default BaseTooltip;
