// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.src-components-filters-layouts-___horizontal__horizontal-layout.src-components-filters-layouts-___horizontal__horizontal-layout{display:flex;flex-direction:row;flex-wrap:wrap;align-items:center;gap:1rem;overflow:hidden}.src-components-filters-layouts-___horizontal__horizontal-layout .src-components-filters-layouts-___horizontal__reset{padding-block:0.25rem;padding-inline:0.5rem;color:#616161}
`, "",{"version":3,"sources":["webpack://./src/components/filters/layouts/horizontal.scss"],"names":[],"mappings":"AAAA,iIAEI,YAAa,CACb,kBAAmB,CACnB,cAAe,CACf,kBAAmB,CACnB,QAAS,CAET,eAAgB,CARpB,sHAYI,qBAAsB,CACtB,qBAAsB,CAEtB,aAAc","sourcesContent":[".horizontal-layout {\n  &.horizontal-layout {\n    display: flex;\n    flex-direction: row;\n    flex-wrap: wrap;\n    align-items: center;\n    gap: 1rem;\n\n    overflow: hidden;\n  }\n\n  .reset {\n    padding-block: 0.25rem;\n    padding-inline: 0.5rem;\n\n    color: #616161;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"horizontal-layout": `src-components-filters-layouts-___horizontal__horizontal-layout`,
	"horizontalLayout": `src-components-filters-layouts-___horizontal__horizontal-layout`,
	"reset": `src-components-filters-layouts-___horizontal__reset`
};
export default ___CSS_LOADER_EXPORT___;
