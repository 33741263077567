import React, { Fragment, useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import Popover from '@material-ui/core/Popover';
import { makeStyles } from '@material-ui/core/styles';
import HelpIcon from '@material-ui/icons/Help';

const useStyles = makeStyles(theme => ({
  popover: {
    fontSize: '0.875rem',
    pointerEvents: 'none'
  },
  paper: {
    maxWidth: '18rem',
    padding: theme.spacing(2)
  }
}));

const FieldHelpIcon = ({ children }) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);

  const onOpen = useCallback(event => {
    setAnchorEl(event.currentTarget);
  }, [setAnchorEl]);

  const onClose = useCallback(() => {
    setAnchorEl(null);
  }, [setAnchorEl]);

  const open = Boolean(anchorEl);

  return (
    <Fragment>
      <HelpIcon
        htmlColor="#999999"
        onMouseEnter={onOpen}
        onMouseLeave={onClose}
        style={{ fontSize: '1rem', paddingLeft: '0.5rem' }}
      />
      <Popover
        anchorEl={anchorEl}
        className={classes.popover}
        classes={{ paper: classes.paper }}
        onClose={onClose}
        open={open}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
      >
        {children}
      </Popover>
    </Fragment>
  );
};

FieldHelpIcon.propTypes = {
  children: PropTypes.number
};

export default FieldHelpIcon;
