import React, { memo, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  closeDashboardDialog,
  deleteDashboardEntry
} from '@actions/dashboard-actions';
import * as dialog from '@constants/dialogs';
import Dialog from '@shared/dialogs/dialog';
import DialogActions from '@shared/dialogs/dialog-actions';

const DeleteGroupDialog = () => {
  const dispatch = useDispatch();
  const { activeDialogs, dialogParams } = useSelector(state => state.dashboard);
  const isDialogActive = activeDialogs[dialog.DELETE_GROUP];

  const onClose = useCallback(
    () => {
      dispatch(closeDashboardDialog(dialog.DELETE_GROUP));
    },
    [dispatch]
  );

  const onDelete = useCallback(
    () => {
      const payload = { ...dialogParams[dialog.DELETE_GROUP] };
      const groupId = payload.group.id;
      dispatch(deleteDashboardEntry('group', groupId));
      onClose();
    },
    [dialogParams, dispatch, onClose]
  );

  if (!isDialogActive || !dialogParams) {
    return null;
  }

  const { group } = dialogParams[dialog.DELETE_GROUP];

  if (!group) {
    return null;
  }

  return (
    <Dialog
      actions={
        <DialogActions
          cancelLabel="NO"
          onCancel={onClose}
          onSubmit={onDelete}
          submitColor="secondary"
          submitLabel="YES"
        />
      }
      title="Delete group?"
    >
      Are you sure you want to delete group '{group.name}'
    </Dialog>
  );
};

export default memo(DeleteGroupDialog);
