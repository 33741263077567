/* eslint-disable react/jsx-no-bind */
import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { cloneDeep } from 'lodash';
import moment from 'moment-timezone';
import { scheduleTypes } from '@constants/component-configs';
import OneTime from '@shared/dialogs/edit-schedule-dialog-schedule-one-time';
import Recurring from '@shared/dialogs/edit-schedule-dialog-schedule-recurring';

const ScheduleType = ({
  endDate,
  endTime,
  errors,
  exclusionsOpen,
  maxDate,
  minDate,
  segment,
  setEndDate,
  setEndTime,
  setExclusionsOpen,
  setSegment,
  setStartDate,
  setStartTime,
  startDate,
  startTime
}) => {
  const schedule = segment.schedules[0];
  const getId = prefix => `${prefix}_picker_${segment.id}`;

  const handleDateTimeChange = (scheduleFieldName, stateSetter, value, date, time) => {
    if (
      (date && time) ||
      (schedule.type === scheduleTypes.recurring.value) ||
      (schedule.type === scheduleTypes.oneTime.value)
    ) {
      const newSegment = cloneDeep(segment);
      const newSchedule = newSegment.schedules[0];

      if (date && time) {
        newSchedule[scheduleFieldName] = moment(`${moment(date).format('YYYY-MM-DD')}T${time.format('HH:mm:ssZ')}`);
        setSegment(newSegment);
      } else
        if (date) {
          newSchedule[scheduleFieldName] = moment(date);
          setSegment(newSegment);
        }
    }
    stateSetter(value);
  };

  const handleTimeChange = (value, fieldNamePrefix, stateSetter, stateValue) => {
    const scheduleFieldName = `${fieldNamePrefix}_date`;
    const time = value ? moment(value) : value;
    handleDateTimeChange(scheduleFieldName, stateSetter, time, stateValue, time);
  };

  const handleDateChange = (value, fieldNamePrefix, stateSetter, stateValue) => {
    const scheduleFieldName = `${fieldNamePrefix}_date`;
    const date = value ? moment(value) : value;
    handleDateTimeChange(scheduleFieldName, stateSetter, date ? date.toDate() : null, date, stateValue);
  };

  const onStartTimeChange = value => handleTimeChange(value, 'start', setStartTime, startDate);
  const onEndTimeChange = value => handleTimeChange(value, 'end', setEndTime, endDate);
  const onStartDateChange = value => handleDateChange(value, 'start', setStartDate, startTime);
  const onEndDateChange = value => handleDateChange(value, 'end', setEndDate, endTime);

  return (
    <Fragment>
      {schedule.type === scheduleTypes.oneTime.value && (
        <OneTime
          endDate={endDate}
          endTime={endTime}
          errors={errors}
          getId={getId}
          maxDate={maxDate}
          minDate={minDate}
          onEndDateChange={onEndDateChange}
          onEndTimeChange={onEndTimeChange}
          onStartDateChange={onStartDateChange}
          onStartTimeChange={onStartTimeChange}
          segment={segment}
          setSegment={setSegment}
          startDate={startDate}
          startTime={startTime}
        />
      )}
      {schedule.type === scheduleTypes.recurring.value && (
        <Recurring
          endDate={endDate}
          errors={errors}
          getId={getId}
          exclusionsOpen={exclusionsOpen}
          maxDate={maxDate}
          minDate={minDate}
          onEndDateChange={onEndDateChange}
          onStartDateChange={onStartDateChange}
          segment={segment}
          setSegment={setSegment}
          setExclusionsOpen={setExclusionsOpen}
          startDate={startDate}
        />
      )}
    </Fragment>
  );
};

ScheduleType.propTypes = {
  endDate: PropTypes.object,
  endTime: PropTypes.object,
  errors: PropTypes.object,
  exclusionsOpen: PropTypes.bool,
  maxDate: PropTypes.object,
  minDate: PropTypes.object,
  segment: PropTypes.object,
  setEndDate: PropTypes.func,
  setEndTime: PropTypes.func,
  setExclusionsOpen: PropTypes.func,
  setSegment: PropTypes.func,
  setStartDate: PropTypes.func,
  setStartTime: PropTypes.func,
  startDate: PropTypes.object,
  startTime: PropTypes.object
};

export default ScheduleType;
