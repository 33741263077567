import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import { getArea, getLastIndex, onInteractiveStop } from '@utils/measurement-utils';
import './drawing-tools.scss';

const MeasurementTool = ({
  closeWindow,
  distance,
  done,
  onMeasureDone,
  resetWindow,
  setDrawingPath
}) => {
  const onMeasureDragEnd = useCallback(
    () => setDrawingPath(window.measure.path),
    [setDrawingPath]
  );

  const onDone = useCallback(
    () => onInteractiveStop(onMeasureDragEnd, onMeasureDone),
    [onMeasureDone, onMeasureDragEnd]
  );

  return (
    <div styleName="tool-container">
      <div styleName="title">Measure distance</div>
      <div styleName="close-tools">
        <IconButton onClick={closeWindow} style={{ padding: 0 }}>
          <CloseIcon />
        </IconButton>
      </div>
      <div styleName="text">
        Click on the map to trace a path you want to measure.
      </div>
      {distance.value && (
        <div key={distance.label} styleName="entity">
          {window.measure.type === 'polygon' ? 'Perimeter' : distance.label}: {distance.value}
        </div>
      )}
      {done && window.measure.type === 'polygon' && (
        <div key="area" styleName="entity">
          Area: {getArea()} square miles
        </div>
      )}
      {!done && getLastIndex() > 0 && (
        <div styleName="action">
          <Button color="primary" key="done" onClick={onDone}>DONE</Button>
        </div>
      )}
      {done && (
        <div styleName="action">
          <Button color="primary" key="clear" onClick={resetWindow}>CLEAR MEASUREMENT</Button>
        </div>
      )}
    </div>
  );
};

MeasurementTool.propTypes = {
  closeWindow: PropTypes.func,
  distance: PropTypes.object,
  done: PropTypes.bool,
  onMeasureDone: PropTypes.func,
  resetWindow: PropTypes.func,
  setDrawingPath: PropTypes.func
};

export default MeasurementTool;
