import React from 'react';
import DataUriIcon from './index';

// Map PNG image (PNG to base64):
const data = 'iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4' +
  'c6QAAAARnQU1BAACxjwv8YQUAAAMeSURBVHgBpVZbctNIFD39kiXLTuxyHjNxMA7UTM3HfAw7YQvsYGYlLIEtzPzP' +
  'HngUf1QRAsRAcArZka1Xq5vbMiZRcEIMp6pLLbX6nPvSbTEQDg8Ph9baRzT+otsOfh7/cs7/OTg4eMUcuTHm8euk2' +
  'xmlm9CWr9wx2Prfhv4xu4rRWol5vgNuGUK7ia7tRSRyTxL5w5fzXkV+FRgzYDw3NBUrycEwS/fQCt5UdwbHSIpBp6mH' +
  'j5y590+yNq6D5HMTeOPV5GRxnNxG6C/Il0jkCC7kVTyuCstXAZHyOLm1cm2W9dHyj8hLWxdm2l061zMvrcm3EKcD60J' +
  'xGcz5cIncPe1hr5rdSMAhmt1l4+m9cwvJ67NkiMA7YXVqjj7/FcNmWd1LrAFrRBGn+4Yz4zkJCg2/aL1gDEOvjU1ucXK' +
  '2v75AV4S8I5VKxAiGZTXLW1zhwN+A1W0cT3YxEUcI8dt6Aj2pWEMPnC+YySMsChTYUQH2VQuTZBunWQNT9QwlS9YTUM' +
  'IYn+eMMo2mvo1GuYVEvUY/yLDBmxSSPtIiROw9rciXuLFATyXsYhn5TOFWs0GZCPQo2helldWaV/ZQ8Gg9Adpp+82pX' +
  'UzJAy/GVmtEX2tYjOM96Qp18aZFSvm5iBuV6W4wt22xSGonGGO79RaTeS//eNZX5+QLU3yzW9tb80BQyTWEQadBCRIa' +
  '81JCG4674SmtWLbTPkZDzc2H6cCkuumtMsblp2AxcjGuC0huIIlm25/h9/Zp9cxQUIxhKKnX9cL38GRWvIvuCG3kta' +
  'HdKP5AxB7XBZylhrpmSxbIinpLaKoYoTel+r4jiZzhO2DUdDeKP6t5LQeGPn9f6G8tCk4xTbvQpfou+RLC+t8KOKRlvS' +
  'sL6oq+ShBnHYMfQE3A5eHFtItYn+dPyQylFbYovRs3xssCkWQL41weciPwbPILPuVB1cWosVGiBX4UnE6dJ3v+pPbQHU' +
  'DPp7tsZjzryDnXdl1i4nXjPy6EeDAIPkVOZOlJ9QKNs7zBCurMgpV0fmu9JnlE3H9XVfHlz+IhTe/j5xExxp7QeOB+Wz' +
  '4DcGxg/4fN9PYAAAAASUVORK5CYII=';

const DefaultMapTypeIcon = props => <DataUriIcon data={data} {...props} />;

export default DefaultMapTypeIcon;
