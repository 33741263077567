import { useMemo, useRef } from 'react';
import { useSelector } from 'react-redux';

import { DATA_TYPES, ENTITY_DATA_TYPE, OVERLAP_DATA_TYPE, WORKFLOW_DATA_TYPE } from '@components/config/constants';

import { getDataTypeFilterParams } from '@components/filters/selectors';
import { getPortalOrdering, getPortalPaging } from './selectors';

export const useLegacyTypes = (dataType, subType) => (
  useMemo(() => {
    if (dataType === ENTITY_DATA_TYPE || dataType === WORKFLOW_DATA_TYPE) {
      return [subType, null, {dataType: subType}];
    }
    return [
      dataType,
      subType,
      {
        baseType: dataType === OVERLAP_DATA_TYPE ? 'overlaps' : dataType,
        dataType: subType
      }
    ];
  }, [dataType, subType])
);

export const useMemoFilterMulti = (dataType, subType) => {
  const typeInfo = DATA_TYPES[dataType];
  const multiFilter = useRef({});
  const filterParams = useSelector(state => getDataTypeFilterParams(state, dataType, subType));
  const orderParams = useSelector(state => getPortalOrdering(state, dataType, subType));
  const pageParams = useSelector(state => getPortalPaging(state, dataType, subType));
  let filterChanged = false;
  let prevParams;
  if (typeInfo.subType) {
    prevParams = multiFilter.current?.[dataType]?.[subType] || {};
  } else {
    prevParams = multiFilter.current?.[dataType] || {};
  }
  if (
    filterParams !== prevParams.filterParams ||
    orderParams !== prevParams.orderParams ||
    pageParams !== prevParams.pageParams
  ) {
    filterChanged = true;
    const newParams = {filterParams, orderParams, pageParams};
    if (typeInfo.subType) {
      multiFilter.current[dataType] = {
        ...multiFilter.current?.[dataType] || {},
        [subType]: newParams
      };
    } else {
      multiFilter.current[dataType] = newParams;
    }
  }
  if (typeInfo.subType) {
    return [multiFilter.current[dataType][subType], filterChanged];
  }
  return [multiFilter.current[dataType], filterChanged];
};
