import React from 'react';
import PropTypes from 'prop-types';
import { topBarStyles } from '@constants/mui-theme';
import RefreshIndicator from 'material-ui/RefreshIndicator';

// Material's UI <RefreshIndicator> component to be used within icons.
const LoaderIcon = ({ color }) => (
  <div style={topBarStyles.loader}>
    <RefreshIndicator
      size={30}
      left={0}
      top={0}
      loadingColor={color}
      status="loading"
    />
  </div>
);

LoaderIcon.propTypes = {
  color: PropTypes.string
};

export default LoaderIcon;
