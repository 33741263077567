import {
  TOOLTIP_FETCH_ERROR,
  TOOLTIP_FETCH_SUCCESS
} from '@constants/action-types';
import initialState from '../store/initial-state';

const tooltipReducer = (state = initialState.tooltips, action) => {
  switch (action.type) {
  case TOOLTIP_FETCH_ERROR: {
    const { dataType, error, id } = action;
    return {
      ...state,
      error: {
        ...state.error,
        [dataType]: {
          ...state.error[dataType],
          [id]: error
        }
      }
    };
  }
  case TOOLTIP_FETCH_SUCCESS: {
    const { dataType, payload: { data } } = action;
    return {
      ...state,
      [dataType]: {
        ...state[dataType],
        [data.id]: data
      }
    };
  }
  default:
    return state;
  }
};

export default tooltipReducer;
