import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import { styleGuide } from '@constants/mui-theme';
import Divider from '@material-ui/core/Divider';
import MenuItem from 'material-ui/MenuItem';
import SelectField from 'material-ui/SelectField';
import { getDataForType } from '@selectors/data-types-selector';
import { itemFormatters } from '@utils/data-detail-utils';
import { sortByField } from '@utils/data-types-utils';
// TODO: Must use this, when MenuItem is moved to the new Material UI version.
// import { buildFilterMenuItem } from '@utils/filter-utils';
import { deepShallowEqual } from '@utils/react-utils';

class DataTypesSelect extends Component {
  UNSAFE_componentWillReceiveProps(nextProps) {
    const {
      onChange,
      options,
      value
    } = nextProps;

    // Validate that the selected value is (still) valid otherwise clear it
    if (value &&
       !deepShallowEqual(this.props.options, options) &&
       !(value in options)) {
      onChange(null, null, null);
    }
  }

  // The primary text is usually 'option.name', but this was extended
  // to allow using a custom item formatter.
  getPrimaryText = option => {
    const { customItemFormatter } = this.props;
    if (customItemFormatter) {
      if (typeof customItemFormatter === 'string') {
        return itemFormatters[customItemFormatter](option);
      }
      return customItemFormatter(option.id);
    }
    return option.name;
  };

  // This is similar to getPrimaryText(), but this will render the label that will display the selected
  // value, while we are not selecting it.
  // Thus, we have to render just the option.name or the custom formatter, only if it's a function
  // (if it's a string, it will use a custom function which can render several lines).
  getLabel = option => {
    const { customItemFormatter } = this.props;
    if (customItemFormatter) {
      if (typeof customItemFormatter !== 'string') {
        return customItemFormatter(option.id);
      }
    }
    return option.name;
  };

  getSortedOptions = () => {
    const { dataSortField, options } = this.props;
    // If the options dataset has an active flag, we need to filter out the inactive options
    // specifically added to removed deleted agencies.
    // const filteredOptions = R.pickBy(option => ((!Object.prototype.hasOwnProperty.call(option, 'active') || option.active)), options);
    const filteredOptions = R.pickBy(option => (
      (!Object.prototype.hasOwnProperty.call(option, 'active') || option.active) && option.name !== 'TBD'), options);
    let sortedOptions = sortByField(dataSortField || 'name')(R.values(filteredOptions));
    sortedOptions = sortedOptions.concat(R.values(R.pickBy(option => option.name === 'TBD', options)));

    // Return the sorted options taking care of putting the 'unassigned/None' option first:
    const unassignedOption = sortedOptions.find(option => option.is_unassigned);
    if (unassignedOption) {
      return [
        unassignedOption,
        ...sortedOptions.filter(option => !option.is_unassigned)
      ];
    }
    return sortedOptions;
  };

  selectionRenderer = (values, menuItems) => {
    const { multiple } = this.props;

    if (multiple) {
      // If all items are selected, display some 'All xxxx' label instead of the selected options.
      {
        const items = this.getSortedOptions();
        const { all } = this.props;
        if (all && all.label && items.length > 0 && items.length === values.length) {
          return all.label;
        }
      }
      // Else render each selected option separated by comma:
      const multipleValues = [];
      menuItems.forEach(item => {
        multipleValues.push(item.props.label || item.props.primaryText);
      });
      return multipleValues.join(', ');
    }

    return menuItems.props.label || menuItems.props.primaryText;
  };

  getChecked = item => {
    const { value } = this.props;
    return value && value.includes(item.id);
  };

  getPromoted = items => {
    const { value } = this.props;
    // Show the promoted section if there are selected values:
    if (value && value.length > 0) {
      const promotedItems = R.values(items).filter(item => this.getChecked(item));
      const demotedItems = R.values(items).filter(item => !this.getChecked(item));
      return {
        demotedItems,
        promotedItems,
        showPromoted: true
      };
    }
    return { showPromoted: false };
  };

  getAllMenuItemLabel = items => {
    const { value } = this.props;
    if (items && value && items.length > 0 && items.length === value.length) {
      return 'UNSELECT ALL';
    }
    return 'SELECT ALL';
  };

  // Handle the click on 'SELECT ALL / UNSELECT ALL'.
  //
  // If any item is selected or no items are selected, this method will select them all.
  // Only if all items are currently selected, this method will unselect them all.
  onSelectAll = () => {
    const { value } = this.props;
    const items = this.getSortedOptions();
    // If there's any item selected (but not all selected),
    // or if there's no item selected, let's select all.
    if (!value ||  // If nothing selected yet (value is null).
         items && (
           (value.length > 0 && value.length !== items.length) ||  // Any item selected but not all.
         value.length === 0  // Again nothing selected yet (value is []).
         )
    ) {
      const allIds = items.map(item => item.id);
      this.props.onChange(null, null, allIds);
      return;
    }
    // Else unselect all
    this.props.onChange(null, null, null);
  };

  buildMenuItem = item => {
    const checked = this.getChecked(item);
    return (
      <MenuItem
        checked={checked}
        key={item.id}
        insetChildren
        primaryText={item.name}
        value={item.id}
        style={item.name === 'TBD' ? { fontStyle: 'italic' } : {}}
      />
    );
  };

  getMenuItems = () => {
    const { multiple } = this.props;
    const items = this.getSortedOptions();

    if (multiple) {
      const { demotedItems, promotedItems, showPromoted } = this.getPromoted(items);
      let menuItems = [
        <MenuItem
          key="all"
          primaryText={this.getAllMenuItemLabel(items)}
          onClick={this.onSelectAll}
          style={{ fontWeight: 500 }}
        />
      ];
      // When the dropdown is opened, it must show the selected options in a promoted place:
      if (showPromoted) {
        menuItems = [
          ...menuItems,
          ...promotedItems.map(item => this.buildMenuItem(item))
        ];
        if (!R.isEmpty(promotedItems) && !R.isEmpty(demotedItems)) {
          menuItems.push(<Divider key="divider" />);
        }
        menuItems = [
          ...menuItems,
          ...demotedItems.map(item => this.buildMenuItem(item))
        ];
      } else {
        // When working normally, show all value without putting them on the promotion zone:
        menuItems = [
          ...menuItems,
          ...items.map(item => this.buildMenuItem(item))
        ];
      }

      return menuItems;
    }
    return items.map(option => (
      <MenuItem
        key={option.id}
        value={option.id}
        disabled={option.is_unassigned && !option.select_unassigned}
        label={this.getLabel(option)}
        style={option.name === 'TBD' ? { fontStyle: 'italic' } : {}}
      >
        {this.getPrimaryText(option)}
      </MenuItem>
    ));
  };

  render() {
    const {
      all,  // eslint-disable-line no-unused-vars
      customItemFormatter,  // eslint-disable-line no-unused-vars
      dataName,  // eslint-disable-line no-unused-vars
      dataSource,  // eslint-disable-line no-unused-vars
      dataSortField,  // eslint-disable-line no-unused-vars
      options,  // eslint-disable-line no-unused-vars
      onChange,  // eslint-disable-line no-unused-vars
      labelStyle,
      ...other
    } = this.props;

    return (
      <SelectField
        {...other}
        {...styleGuide.select}
        labelStyle={{
          ...styleGuide.select.labelStyle,
          ...labelStyle
        }}
        onChange={this.props.onChange}
        selectionRenderer={this.selectionRenderer}
      >
        {this.getMenuItems()}
      </SelectField>
    );
  }
}

DataTypesSelect.propTypes = {
  all: PropTypes.object,
  customItemFormatter: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  data: PropTypes.object,
  dataName: PropTypes.string,
  dataSortField: PropTypes.string,
  dataSource: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  labelStyle: PropTypes.object,
  multiple: PropTypes.bool,
  onChange: PropTypes.func,
  options: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  required: PropTypes.bool,
  value: PropTypes.any  // eslint-disable-line react/forbid-prop-types
};

const mapStateToProps = (state, ownProps) => {
  const { map_category_type: categoryTypes } = state.dataTypes;
  const { dataName, dataSource, name, required } = ownProps;
  if (dataSource) {
    return { options: dataSource };
  }
  let options = getDataForType(state, dataName);
  // If it's a category dropdown, filter it by category type
  // (since categories are all on the same database table).
  if (categoryTypes && name.startsWith('|')) {
    const categoryTypeName = name.substring(1);
    const categoryType = parseInt(Object.keys(categoryTypes).find(key => categoryTypes[key].name === categoryTypeName), 10);
    options = R.pickBy(option => option.type === categoryType, options);

    // If this field is required, don't allow users to see and set the '(blank)/TBD' value.
    //
    // Also do the same for the special 'priority' category, since it already contains a 'No priority' value,
    // which is used by default when no priority is set.
    if (required || categoryTypeName === 'priority') {
      options = R.pickBy(option => option.id > 0, options);
    }
  }
  return { options };
};

export default connect(mapStateToProps, {})(DataTypesSelect);
