import { useCallback, useEffect, useState } from 'react';

export const useDeferredCallback = (callback, deps) => {
  // A useCallback hook that does not immediately run callbacks.
  // Instead it saves the args and triggers the callback in an effect.
  // This ensures that this callback is run after all state changes and
  // memoized values are re-calculated before running the callback.
  const [deferredArgs, setDeferredArgs] = useState(null);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const memoizedCallback = useCallback(callback, deps);
  useEffect(() => {
    if (deferredArgs) {
      memoizedCallback(...deferredArgs);
      setDeferredArgs(null);
    }
  }, [deferredArgs, setDeferredArgs, memoizedCallback]);
  return useCallback((...args) => {
    setDeferredArgs(args);
  }, [setDeferredArgs]);
};
