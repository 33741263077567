import React from 'react';
import PropTypes from 'prop-types';

import * as R from 'ramda';

import { Icon } from '@mui';

import {
  CARD_LOAD_STYLE_CHART
} from '../constants';
import { getCardHeight } from '../utils';

import SkeletonListRow from './skeleton-list-row';
import SkeletonTableRow from './skeleton-table-row';

import '../dashboard-card.scss';

const skeletonRows = type => {
  const row = index =>
    type === 'TABLE' ? (
      <SkeletonTableRow key={index} />
    ) : (
      <SkeletonListRow key={index} />
    );
  return R.times(index => row(index), 12);
};

const CardLoading = ({ card }) => {
  const style = {
    outerBodyChart: {
      height: getCardHeight(card, 48),
      margin: '24px'
    },
    outerBody: {
      height: getCardHeight(card)
    },
    innerBody: {
      display: 'flex',
      alignItems: 'center'
    }
  };
  const content =
    card.loadStyle === CARD_LOAD_STYLE_CHART ? (
      <div style={style.outerBodyChart} styleName="card-body-loading-chart">
        <div style={style.innerBody}>
          <Icon>bar_chart</Icon>
        </div>
      </div>
    ) : (
      <div style={style.outerBody} styleName="card-body-loading">
        {skeletonRows(card.loadStyle)}
      </div>
    );

  return content;
};

CardLoading.propTypes = {
  card: PropTypes.object
};

export default CardLoading;
