import React, { Fragment } from 'react';
import { v4 as uuid } from 'uuid';
import PropTypes from 'prop-types';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import InsertDriveFileOutlinedIcon from '@material-ui/icons/InsertDriveFileOutlined';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import EntityTypeIcon from '@icons/entity-type-icon';
import GroupTypeIcon from '@icons/group-type-icon';
import TruncatedCell from '@shared/data-table/cells/truncated-cell';
import LateLabel from '@shared/workflow/late-label';
import TaskStatusChip from '@shared/workflow/task-status-chip';
import { renderAgency } from '@utils/data-table/agency';
import { getUserAvatar } from '@utils/data-table/user';
import UserTeams from '@utils/data-table/column-renderers/user-teams';
import { renderCustomIconAndLabel } from '@utils/icon-utils';
import { enDash, renderTooltipDate } from '@utils/shared-utils';
import { findTaskStatus, isLate } from '@utils/workflow-utils';
import '../icon-utils.scss';

const renderStartedOn = (item, id) => item.cycle_started_type ? (
  <TruncatedCell tooltip={item.cycle_started_name} targetId={id}>
    {renderCustomIconAndLabel(
      item.cycle_started_name,
      <Fragment>
        {item.cycle_started_type === 'entity' && <EntityTypeIcon type={item.cycle_started_type_name} />}
        {item.cycle_started_type === 'group' && <GroupTypeIcon type={item.cycle_started_type_name} />}
      </Fragment>,
      id
    )}
  </TruncatedCell>
) : enDash;

const renderOwnerAgencyName = (task, agencyTypes, id) => renderAgency(task.owner_agency_name, agencyTypes, task.owner_agency_type, id);

const renderAgencyName = (activity, agencyTypes, id) => renderAgency(activity.agency_name, agencyTypes, activity.agency_type, id);

const renderTaskOwnerAgencyName = (activity, agencyTypes, id) => renderAgency(activity.task_owner_agency_name, agencyTypes, activity.agency_type, id);

const renderOwnerName = (task, id) => task.owner_name ? (
  <TruncatedCell tooltip={task.owner_name} targetId={id}>
    {renderCustomIconAndLabel(
      task.owner_name,
      getUserAvatar({ name: task.owner_name, is_active: task.owner_is_active }, 'small'),
      id
    )}
  </TruncatedCell>
) : enDash;

export const optimizeActivityData = (activity, agencyTypes, userRoles, taskStatus) => ({
  ...activity,
  task_name: <TruncatedCell>{activity.task_name}</TruncatedCell>,
  cycle_name: <TruncatedCell>{activity.cycle_name}</TruncatedCell>,
  status_name: (
    <div styleName="item-container">
      <TaskStatusChip
        taskStatus={findTaskStatus(activity.status_name, taskStatus)}
      />
      {isLate(activity) && <LateLabel />}
    </div>
  ),
  agency_name: renderAgencyName(activity, agencyTypes, uuid()),
  cycle_started_name: renderStartedOn(activity, uuid()),
  task_owner_agency_name: renderTaskOwnerAgencyName(activity, agencyTypes, uuid()),
  roles: activity.roles.length ? <UserTeams roles={activity.roles} userRoles={userRoles} /> : enDash,
  task_due_date: renderTooltipDate(activity.task_due_date),
  created: renderTooltipDate(activity.created),
  modified: renderTooltipDate(activity.modified)
});

export const optimizeCycleData = cycle => ({
  ...cycle,
  name: <TruncatedCell>{cycle.name}</TruncatedCell>,
  workflow_name: <TruncatedCell>{cycle.workflow_name}</TruncatedCell>,
  cycle_started_name: renderStartedOn(cycle, uuid()),
  created: renderTooltipDate(cycle.created),
  modified: renderTooltipDate(cycle.modified)
});

export const optimizeTaskData = (task, agencyTypes) => ({
  ...task,
  name: <TruncatedCell>{task.name}</TruncatedCell>,
  cycle_started_name: renderStartedOn(task, uuid()),
  owner_name: renderOwnerName(task, uuid()),
  owner_agency_name: renderOwnerAgencyName(task, agencyTypes, uuid()),
  due_date: renderTooltipDate(task.due_date),
  created: renderTooltipDate(task.created),
  modified: renderTooltipDate(task.modified)
});

const CreatedBy = ({ obj: { created_by: { is_active, name } }, id }) => (
  <TruncatedCell tooltip={name} targetId={id}>
    {renderCustomIconAndLabel(
      name,
      getUserAvatar({ name, is_active }, 'small'),
      id
    )}
  </TruncatedCell>
);

CreatedBy.propTypes = {
  id: PropTypes.number,
  obj: PropTypes.object
};

const getStatus = taskType => {
  const id = uuid();
  if (taskType.draft) {
    if (taskType.version > 1) {
      return renderCustomIconAndLabel('Unpublished changes', <ErrorOutlineIcon htmlColor="#FF9800" />, id);
    }
    return renderCustomIconAndLabel('Draft', <InsertDriveFileOutlinedIcon htmlColor="#f9a825" />, id);
  }
  return renderCustomIconAndLabel('Published', <LockOutlinedIcon htmlColor="#00C853" />, id);
};

export const optimizeTaskTypeData = taskType => ({
  ...taskType,
  name: <TruncatedCell>{taskType.name}</TruncatedCell>,
  draft: getStatus(taskType),
  created_by: <CreatedBy obj={taskType} id={uuid()} />,
  created: renderTooltipDate(taskType.created),
  modified: renderTooltipDate(taskType.modified)
});

export const optimizeWorkflowData = workflow => ({
  ...workflow,
  name: <TruncatedCell>{workflow.name}</TruncatedCell>,
  created_by: <CreatedBy obj={workflow} id={uuid()} />,
  created: renderTooltipDate(workflow.created),
  modified: renderTooltipDate(workflow.modified)
});
