import React, { memo } from 'react';
import { useSelector } from 'react-redux';
import Input from '@components/map/tray/search/input';
import Empty from '@components/map/tray/search/empty';
import MapSearchResults from '@components/map/tray/search/results';
import { mapTabsConfig } from '@constants/component-configs';
import '../tray.scss';

const Search = () => {
  const { currentTab, traySearch } = useSelector(state => state.map);

  if (currentTab !== mapTabsConfig.tabs.search.value) {
    // Don't render if it's not the search tab.
    return null;
  }

  const hasResults = traySearch.term.length > 0;
  return (
    <div styleName="map-tray-body-wrapper map-tray-body-shadow">
      <Input />
      {!hasResults && <Empty />}
      {hasResults && <MapSearchResults results={traySearch} />}
    </div>
  );
};

export default memo(Search);
