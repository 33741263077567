import React from 'react';
import DrawingTools from '@components/map/components/drawing-tools';
import Entities from '@components/map/components/entities';
import Groups from '@components/map/components/groups';
import LayerList from '@components/map/components/layer-list';
import SearchMarker from '@components/map/components/search-marker';
import MarkerOverlaps from '@components/map/marker-overlaps';
import MarkersModal from '@components/map/markers-modal';

const Components = () => (
  <div>
    <Entities />
    <MarkerOverlaps />
    <MarkersModal />
    <LayerList />
    <Groups />
    <DrawingTools />
    <SearchMarker />
  </div>
);

export default Components;
