import React, { memo, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { paper } from '@constants/mui-theme';
import { mapTabsConfig } from '@constants/component-configs';
import { getTrayDepth } from '@selectors/map-selector';
import VerticalTab from '@shared/vertical-tab';
import './tab-bar.scss';

const OPEN = 'rgba(0, 0, 0, 0.12) 0px 1px 6px, rgba(0, 0, 0, 0.12) 0px 1px 4px';
const CLOSE = 'rgba(0, 0, 0, 0.19) 0px 10px 30px, rgba(0, 0, 0, 0.23) 0px 6px 10px';

const TabBar = () => {
  const depth = useSelector(getTrayDepth);
  const boxShadow = useMemo(() => depth > 0 ? OPEN : CLOSE, [depth]);
  return (
    <div data-testid="map-tab-bar" styleName="map-tab-bar-container" style={{ ...paper, boxShadow }}>
      <VerticalTab {...mapTabsConfig.tabs.filters} />
      <VerticalTab {...mapTabsConfig.tabs.search} />
      <VerticalTab {...mapTabsConfig.tabs.details} />
      <VerticalTab {...mapTabsConfig.tabs.recent} />
    </div>
  );
};

export default memo(TabBar);
