import {
  calendarConfig,
  mapTabsConfig,
  segmentDirections
} from '@constants/component-configs';

export const agencySuppressFilters = {
  // This must match the entries of 'filterRadioTypes' below.
  agencyType: {
    name: 'agency__type__in',
    filter: null,
    linked: ['agency']
  },
  agency: {
    name: 'agency__in',
    filter: null,
    linked: ['agencyType']
  }
};

export const filterRadioTypes = {
  agencyType: {
    checked: true,
    linked: ['agency']
  },
  agency: {
    checked: false,
    linked: ['agencyType']
  }
};

const initialState = {
  attachments: {
    attachments: {},
    preUploadAttachments: {},
    contentType: null,
    objectId: null,
    loading: false,
    index: -1,
    errors: []
  },
  auth: {
    errors: {
      username: '',
      password: ''
    },
    user: {
      username: '',
      fullName: '',
      passwordErrorText: '',
      email: '',
      emailErrorText: '',
      requestedReset: {},
      passwordChangeData: {}
    }
  },
  calendar: {
    data: [],
    filters: {
      selectedDate: calendarConfig.initialFilters.selectedDate,
      selectedView: calendarConfig.initialFilters.selectedView
    },
    errors: [],
    fetchId: null,
    loading: false
  },
  confirmation: {
    visible: false,
    actionCallback: null,
    title: '',
    question: '',
    params: {},
    discard: false
  },
  dashboard: {
    activeDialogs: {},
    dialogParams: {},
    autocomplete: {
      suggestions: [],
      loading: false
    },
    details: {},
    resolution: {
      loading: false,
      entityId: null
    },
    // 'saving' tells for each data-type if we are currently saving/updating it
    // (i.e. in the middle of the AJAX call).
    saving: {},
    snackbar: {
      messages: []
    }
  },
  dataTables: {
    autocomplete: {},
    columns: {},
    count: {},
    errors: [],
    rows: {},
    filters: {},
    loader: {},
    next: {},
    selectedRow: null
  },
  dataDetail: {
    currentTab: {},
    cycles: [],
    cyclesErrors: null,
    data: null,
    dataType: null,
    errors: [],
    // List of fields with errors set on the frontend (during validations).
    frontendErrorFields: new Set(),
    loading: false,
    saving: false,
    modified: false,
    options: null,
    overlapEntities: {},
    overlapErrors: null,
    scrollId: null
  },
  dataTypes: {
    agency: null,
    agency_type: null,
    group_status: null,
    overlap_resolution_reason: null,
    map_category: null,
    map_category_type: null,
    map_type: null,
    permission: null,
    // It's a hardcoded type on the backend, not a database table,
    // thus let's not issue an additional call to retrieve this,
    // since we already know the content, it won't change, unless
    // the backend code changes:
    segment_direction: {
      1: {id: 0, name: 'Unknown'},
      2: {id: segmentDirections.one.value, name: 'One direction'},
      3: {id: segmentDirections.both.value, name: 'Both directions'}
    },
    segment_impact_type: null,
    stats: null,
    task_type: null,
    task_status: null,
    task_response: null,
    group: {},
    user_settings: null,
    user_roles: null,
    user: {}, // Use empty dict instead of null, to skip waiting for this type
    workflow: null
  },
  dialogs: {
    open: false,
    redirectPath: null,
    text: null
  },
  entities: {
    types: {
      // Upon app load, this will get filled with an entry for each
      // entity type.
    },
    typesConfig: {}
  },
  errors: [],
  groups: {
    dialog: {
      addDataGroups: [],
      addDataId: null,
      addDataType: ''
    },
    edit: {
      applyShape: false,
      group: {
        name: '',
        owner: null,
        description: '',
        external_id: '',
        status: null,
        // Default to the 'boundary' group type.
        type: 1,
        type_name: 'boundary'
      },
      polygonEntities: {
        // This will be dynamically filled with all existing entity types.
      },
      drawing: false,
      dialog: {},
      overlaps: {},
      loading: false,
      saving: false,
      modified: false,
      error: {}
    },
    filters: {
      status__name: 'Open',
      pagination: 'off'
    },
    gantt: {
      dialogs: {},
      filters: {},
      // This stores the state of the row groups, if
      // it's open (expanded) or closed (collapsed)
      locationTypeGroups: {}
    },
    list: [],
    loading: false,
    options: null,
    // Will contain the group 'id' and group 'type' to use to pre-fill
    // the 'Add to group' form (i.e. when we navigate to the main map
    // from a '+ Add record' link on the group edit page, we must pre-fil
    // the form with the group's data which we were editing, since we
    // do additions from the main map and not within the group edit page).
    preFill: null,
    showLayerBoundaries: false,
    showLayerLabels: false,
    showMapLayer: false,
    markers: {
      calculated: false,
      centers: [],
      layers: {}
    }
  },
  layers: {},
  logs: {
    model: null,
    current_pms: null,
    list: [],
    loading: false,
    name: null,
    next: null,
    type: null,
    reload: false
  },
  notifications: {
    list: [],
    loading: false,
    next: null,
    unread: 0
  },
  map: {
    boundsData: null,
    filters: null,
    filterToggles: {
      // This will contain one entry per defined filter, upon config load.
    },
    filterRadios: {
      // This will contain the 'filterRadioTypes' object for each entity type.
    },
    // Downloading flag, if it's not zero, we'll show a loading icon indicator
    // instead of the 'file_download' Material icon button.
    // It's a number (a counter) and not a boolean, since it counts the number of files
    // when we do multiple downloads.
    downloading: 0,
    // Flags for when entities are loading:
    loading: {},
    mapTypeId: 'roadmap',
    traffic: false,
    bicycle: false,
    transit: false,
    drawing: {
      done: false,  // True when done drawing a path.
      mode: '',
      // Locations for measuring distances:
      path: null
    },
    viewport: {
      bounds: null,
      center: null,
      defaultMarkerPosition: null,
      markerItem: null,
      source: null
    },
    modes: [],
    onlyShowOverlaps: false,
    // Open/close states for different map components:
    legendOpen: true,
    layersMenuOpen: false,
    savedLegendOpen: true,  // Store old legendOpen value (for turning it off temporarily while drawing a shape).
    currentTab: mapTabsConfig.tabs.filters.value,  // Default to the filters tab.
    selectedPosition: {},
    trays: [],
    traySearch: {
      term: '',  // Search term.
      backend: [],  // Results from our backend API.
      google: []  // Results from Google Map Autocomplete.
    },
    trayRecent: [],  // Store recently opened entities (permits, layers, etc.).
    overlapTrayEntities: {},
    markers: {
      markerModalOpen: false,
      markersOn: true,
      poisOn: false
    }
  },
  markers: {
    calculated: false,
    centers: [],
    layers: {}
  },
  messages: [],
  settings: {
    email: null
  },
  tooltips: {
    error: {
      cycle: {},
      log_user: {},
      map: {},
      task: {},
      task_activity: {}
    },
    cycle: {},
    log_user: {},
    map: {},
    task: {},
    task_activity: {}
  },
  workflow: {
    cycle: null,
    errors: {},
    loading: false,
    saving: false
  }
};

export default initialState;
