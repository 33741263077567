import { createStore, compose, applyMiddleware } from 'redux';
import { connectRouter, routerMiddleware } from 'connected-react-router';
import reduxImmutableStateInvariant from 'redux-immutable-state-invariant';
import thunk from 'redux-thunk';
import rootReducer from '../reducers';
import isImmutable from 'redux-immutable-state-invariant/dist/isImmutable';
import { isOldIE } from '@utils/browser-detect';
import {
  DATA_TYPES_FETCH_SUCCESS,
  ENTITY_FETCH_SUCCESS,
  GROUPS_FETCH_SUCCESS,
  LAYER_FETCH_SUCCESS,
  MAP_TRAY_OVERLAP_ENTITY_CACHE_ADD
} from '@constants/action-types';

function configureStoreProd(initialState, history) {
  const reactRouterMiddleware = routerMiddleware(history);
  const middlewares = [
    // Add other middleware on this line...
    // thunk middleware can also accept an extra argument to be passed to each thunk action
    // https://github.com/reduxjs/redux-thunk#injecting-a-custom-argument
    thunk,
    reactRouterMiddleware
  ];

  return createStore(
    rootReducer(history),
    initialState,
    compose(applyMiddleware(...middlewares))
  );
}

// Truncate long list of map entities sent to redux dev tools:
const sanitizeEntities = state => {
  const newTypes = {};
  Object.keys(state.entities.types).forEach(key => {
    newTypes[key] = {
      ...state.entities.types[key],
      list: state.entities.types[key].list.slice(0, 10)
    };
  });
  return {
    ...state,
    entities: {
      ...state.entities,
      types: { ...newTypes }
    }
  };
};

const actionSanitizer = action => {
  switch (action.type) {
  case DATA_TYPES_FETCH_SUCCESS:
    return {...action, payload: {...action.payload, data: `<<DATA_LIST (${action.payload?.data?.length})>>`}};
  case ENTITY_FETCH_SUCCESS:
    return {...action, entities: `<<ENTITIES_LIST (${action.entities?.length})>>`};
  case GROUPS_FETCH_SUCCESS:
    return {...action, groups: `<<GROUPS_LIST (${action.groups?.length})>>`};
  case LAYER_FETCH_SUCCESS:
    return {
      ...action, payload: {
        ...action.payload, data: {
          ...action.payload.data,
          results: `<<LAYERS_LIST (${action.payload.data?.results?.length})>>`
        }
      }
    };
  case MAP_TRAY_OVERLAP_ENTITY_CACHE_ADD:
    return {...action, entityDetails: `<<ENTITIES_LIST (${action.entityDetails?.length})>>`};
  default:
    return action;
  }
};

function configureStoreDev(initialState, history) {
  const reactRouterMiddleware = routerMiddleware(history);
  const middlewares = [
    // Add other middleware on this line...

    // thunk middleware can also accept an extra argument to be passed to each thunk action
    // https://github.com/reduxjs/redux-thunk#injecting-a-custom-argument
    thunk,
    reactRouterMiddleware
  ];

  // Under Internet Explorer we must not use 'redux-immutable-state-invariant',
  // since it causes a stack overflow error when we try to open the tray.
  //
  // The problem is that for IE we need to set 'optimized: false' on Google
  // Map Markers, which causes the marker element to be rendered in a different
  // way, and that causes this library to enter an infinite loop and crash.
  //
  // Since this library is only for development, and we use Chrome/Safari for it,
  // we can safely skip it for IE.
  //
  // Testing for state mutation can be done with without IE and use IE only
  // for being sure the application works on that platform.
  if (!isOldIE()) {
    // Redux middleware that spits an error on you when you try to mutate your
    // state either inside a dispatch or between dispatches.
    middlewares.unshift(reduxImmutableStateInvariant({
      isImmutable,
      ignore: [
        'calendar.data',
        'dataTables.rows',
        'entities',
        'groups.markers',
        'layers',
        'markers'
      ]
    }));
  }

  // add support for Redux dev tools
  const composeEnhancers = (
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ?
      window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
        actionSanitizer,
        stateSanitizer: state => sanitizeEntities(state)
      }) :
      compose
  );
  const store = createStore(
    rootReducer(history),
    initialState,
    composeEnhancers(
      applyMiddleware(...middlewares)
    )
  );

  if (module.hot) {
    const connectRouterHistory = connectRouter(history);

    // Enable Webpack hot module replacement for reducers
    module.hot.accept('../reducers', () => {
      const nextRootReducer = require('../reducers').default; // eslint-disable-line global-require
      store.replaceReducer(connectRouterHistory(nextRootReducer));
    });
  }

  return store;
}

const configureStore = process.env.NODE_ENV === 'production' ? configureStoreProd : configureStoreDev;

export default configureStore;
