import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { openDashboardDialog } from '@actions/dashboard-actions';
import * as dialog from '@constants/dialogs';
import AddButton from '@shared/add-button';
import { canAccessWorkflow } from '@utils/permission-utils';
import { getTasks, getTasksTitle } from '@selectors/workflow-selector';
import AddTaskDialog from './add-task-dialog';
import './add-task.scss';

const AddTask = () => {
  const dispatch = useDispatch();
  const { cycle } = useSelector(state => state.workflow);
  const tasks = useSelector(getTasks);
  const title = useSelector(getTasksTitle);

  const openAddTaskDialog = useCallback(
    () => dispatch(openDashboardDialog(dialog.ADD_TASK, { cycleId: cycle.id, workflow: cycle.workflow })),
    [cycle, dispatch]
  );

  if (!cycle) {
    return null;
  }

  const canAdd = canAccessWorkflow('task', 'add');
  const taskCount = tasks?.length || 0;

  return (
    <div styleName="add-task-container">
      <div styleName="add-task-title">
        <div styleName="add-task-title-text">
          {title}
        </div>
        {taskCount > 0 && <div styleName="add-task-title-count">{taskCount}</div>}
      </div>
      {canAdd && (
        <div styleName="add-task-action">
          <AddButton tooltip="Add task" onClick={openAddTaskDialog} />
        </div>
      )}
      {canAdd && <AddTaskDialog />}
    </div>
  );
};

export default AddTask;
