import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import ConflictListItem from './conflict-list-item';
import { ListItem } from 'material-ui/List';
import { fetchGroupEntityOverlaps } from '@actions/groups-actions';
import { dotmapsBlue } from '@constants/colors';
import { detailStyles } from '@constants/mui-theme';
import DotmapsLoader from '@shared/dotmaps-loader';
import './conflict-list.scss';

class ConflictList extends Component {
  UNSAFE_componentWillMount() {
    const { overlaps } = this.props;
    if (!overlaps || !this.props.overlaps[this.props.entity.id]) {
      this.props.fetchGroupEntityOverlaps(this.props.entity.id);
    }
  }

  getNestedItems = entityId => {
    const { overlaps, conflictList } = this.props;
    const conflicts = R.values(overlaps[entityId].conflicts);
    const conflictedEntities = conflicts.filter(entity => conflictList.includes(entity.id));
    return conflictedEntities.map(
      entity => (
        <ListItem
          key={`conflict-list-item-${entity.id}`}
          {...detailStyles.conflictListItem}
        >
          <ConflictListItem mainEntity={overlaps[entityId].mainEntity} entity={entity} onClick={this.props.onClick} />
        </ListItem>
      )
    );
  };

  render() {
    const { entity, overlaps } = this.props;
    if (overlaps && overlaps[entity.id]) {
      return (
        <div styleName="conflict-list-container">
          {this.getNestedItems(entity.id)}
        </div>
      );
    }
    return (
      <ListItem>
        <div styleName="conflict-loader-container">
          <DotmapsLoader display color={dotmapsBlue} />
        </div>
      </ListItem>
    );
  }
}

ConflictList.propTypes = {
  conflictList: PropTypes.array,
  entity: PropTypes.object,
  fetchGroupEntityOverlaps: PropTypes.func,
  onClick: PropTypes.func,
  overlaps: PropTypes.object
};

const mapStateToProps = state => ({ overlaps: state.groups.edit.overlaps });

export default connect(mapStateToProps, {fetchGroupEntityOverlaps})(ConflictList);
