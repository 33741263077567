// Details page table column definitions:

export const FILES_TAB_COLUMNS = [
  { key: 'checkbox' },
  {
    label: 'file',
    key: 'file_name',
    format: 'file-icon-name',
    formatProps: { iconKey: 'file_content_type' },
    style: { width: '10rem', maxWidth: '10rem' }
  },
  { label: 'size', key: 'file_size', format: 'file-size', style: { width: '5rem', whiteSpace: 'nowrap' } },
  { label: 'description', key: 'description', format: 'truncated-text', style: { width: '20rem', maxWidth: '20rem' } },
  { label: 'uploaded by', key: 'created_by', format: 'user', style: { width: '5rem' } },
  { label: 'date uploaded', key: 'created', format: 'date', style: { width: '5rem' } }
  // The files table doesn't need a dummy column it has no space.
];

export const PUBLIC_FILES_TAB_COLUMNS = [
  { label: 'file', key: 'file_name', format: 'bold-text' }
];

export const GROUPS_TAB_COLUMNS = [
  { label: 'group', key: 'name', format: 'group' },
  { label: 'status', key: 'status_name' },
  { label: '', key: 'actions', style: { width: '2rem' } }  // Dummy column for actions.
                                                           // (It will be replaced by the actions, on tables with
                                                           // few columns, we can spare space, thus we can add a
                                                           // dummy column, else it's always the last column
                                                           // the one that disappears on hover to give space
                                                           // for the actions cell).
];

export const CYCLES_TAB_COLUMNS = [
  { label: 'workflow', key: 'name', format: 'bold-text' },
  { label: 'workflow template', key: 'workflow_name' },
  { label: 'tasks', key: 'task_count' },
  { label: 'date started', key: 'created', format: 'date' },
  { label: '', key: 'actions', style: { width: '2rem' } }  // Dummy column.
];
