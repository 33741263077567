import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@mui';
import TextField from '@material-ui/core/TextField';
import '../layouts/not-authenticated/not-authenticated.scss';

const PasswordCreate = ({
  formChange, formSubmit, isOnboarding, passwordErrorText,
  fullname, password, passwordTwo
}) => {
  const passwordCreateHeaderText = isOnboarding ? 'Complete your account' : 'Create new password';
  const submitLabel = isOnboarding ? 'CREATE ACCOUNT' : 'CONFIRM NEW PASSWORD';
  const submitDisabled = (isOnboarding && !fullname) || !password || !passwordTwo;

  return (
    <div data-testid="password-create">
      <div styleName="auth-heading">{ passwordCreateHeaderText }</div>
      <form onSubmit={formSubmit}>
        {isOnboarding &&
          <div styleName="not-authenticated-form-text-field">
            <TextField
              fullWidth
              id="fullname"
              name="fullname"
              onChange={formChange}
              label="Full name"
            />
          </div>
        }
        <div styleName="not-authenticated-form-text-field">
          <TextField
            fullWidth
            id="password"
            name="password"
            type="password"
            onChange={formChange}
            label="New password"
          />
        </div>
        <div styleName="not-authenticated-form-text-field">
          <TextField
            fullWidth
            id="passwordTwo"
            name="passwordTwo"
            type="password"
            onChange={formChange}
            errorText={passwordErrorText}
            error={passwordErrorText?.length > 0}
            helperText={passwordErrorText}
            label="Confirm password"
          />
        </div>
        <div>
          <Button
            color="primary"
            disabled={submitDisabled}
            fullWidth
            type="submit"
            variant="contained"
          >
            {submitLabel}
          </Button>
        </div>
      </form>
    </div>
  );
};

PasswordCreate.propTypes = {
  formChange: PropTypes.func,
  formSubmit: PropTypes.func,
  fullname: PropTypes.string,
  isOnboarding: PropTypes.bool,
  password: PropTypes.string,
  passwordErrorText: PropTypes.string,
  passwordTwo: PropTypes.string
};

export default PasswordCreate;
