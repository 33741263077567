import { BASE_URL } from '@constants/endpoints';
import axios from 'axios';
import { getServerErrorAction } from '@actions/shared-actions';

export const fetchDashboard = (portalType, callback) => {
  const cardsRequest = axios.get(`${BASE_URL}/api/user_config/${portalType}-cards`);
  const columnsRequest = axios.get(`${BASE_URL}/api/user_config/${portalType}-columns`);
  return dispatch =>
    cardsRequest.then(
      cardsResponse => {
        columnsRequest.then(
          columnsResponse => {
            callback(cardsResponse.data, columnsResponse.data);
          },
          error => dispatch(getServerErrorAction(portalType, error))
        );
      },
      error => dispatch(getServerErrorAction(portalType, error))
    );
};

export const updateDashboard = (name, data, callback) => {
  const request = axios.put(
    `${BASE_URL}/api/user_config/${name}`,
    { data }
  );
  return dispatch =>
    request.then(
      response => {
        callback(response);
      },
      error => dispatch(getServerErrorAction('dashboard', error))
    );
};
