/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import PropTypes from 'prop-types';
import { Icon } from '@mui';
import './show-more.scss';

const ShowMore = ({ onClick, open }) => (
  <li styleName="show-more-container" onClick={onClick} role="presentation">
    <div styleName="icon">
      <Icon>more_horiz</Icon>
    </div>
    <div styleName="text">
      SHOW { open ? 'LESS' : 'MORE' } RESULTS
    </div>
  </li>
);

ShowMore.propTypes = {
  onClick: PropTypes.func,
  open: PropTypes.bool
};

export default ShowMore;
