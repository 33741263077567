/**
 * Data table utils for entities.
 */
import React from 'react';
import { getDataTableActions, getEntityTypeLabel } from '@constants/config';
import TruncatedCell from '@shared/data-table/cells/truncated-cell';
import DefaultTime from '@shared/formatted-time-helper';
import { getAgencyIcon, renderIconAndLabel } from '@utils/icon-utils';
import ActionsMenu from '@utils/data-table/column-renderers/actions-menu';
import {
  getOnStreet,
  getOwners,
  insertCategories,
  renderGroupsCell
} from '@utils/data-table/shared';
import { enDash, renderTooltipDate } from '@utils/shared-utils';

const getEntityAction = (row, dataType) => {
  const actions = getDataTableActions().entity;
  return <ActionsMenu actions={actions} dataType={dataType} dataId={row.id} row={row} />;
};

export const optimizeEntityData = (entity, dataType, agencyTypes) => ({
  ...entity,
  ...insertCategories(entity.type_name, entity.category_dict),
  name: <TruncatedCell>{entity.name}</TruncatedCell>,
  action: getEntityAction(entity, dataType),
  agency_id: entity.agency,  // Need the id for permissions check.
  agency: (
    <TruncatedCell tooltip={entity.agency_name} targetId={entity.id}>
      {renderIconAndLabel(entity.agency_name, getAgencyIcon(agencyTypes[entity.agency_type]), entity.id)}
    </TruncatedCell>
  ),
  groups: renderGroupsCell(getEntityTypeLabel(dataType), entity.groups),
  owner_list: getOwners(entity, dataType),
  start_date: renderTooltipDate(entity.start_date),
  end_date: renderTooltipDate(entity.end_date),
  start_time: entity.start_date ? <DefaultTime value={entity.start_date} /> : enDash,
  end_time: entity.end_date ? <DefaultTime value={entity.end_date} /> : enDash,
  modified: renderTooltipDate(entity.modified),
  on_street: getOnStreet(entity, dataType)
});
