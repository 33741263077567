/* eslint-disable react/jsx-no-bind */
import React, { memo, useContext } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import { openGroupExcludeOverlapDialog } from '@actions/groups-actions';
import OverlapContext from '@components/overlap/overlap-context';
import IconMenu from '@shared/icon-menu';
import IconMenuItem from '@shared/icon-menu-item';
import { canEditGroups } from '@utils/permission-utils';

const MoreButton = ({ entity }) => {
  const dispatch = useDispatch();
  const { groups } = useContext(OverlapContext);

  if (isEmpty(groups) || !canEditGroups()) {
    return null;
  }

  const onExclude = event => {
    event.stopPropagation();
    dispatch(openGroupExcludeOverlapDialog('overlap', entity.entity_type, entity.id, groups));
  };

  return (
    <IconMenu>
      <IconMenuItem onClick={onExclude}>
        Exclude overlap
      </IconMenuItem>
    </IconMenu>
  );
};

MoreButton.propTypes = {
  entity: PropTypes.object
};

export default memo(MoreButton);
