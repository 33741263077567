/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/jsx-no-bind */
import React from 'react';
import PropTypes from 'prop-types';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import { Select } from '@mui';
import CheckboxControl from '@shared/form/checkbox-control';
import {
  buildRecurrenceTimeMenu,
  buildRecurrenceWeekMenu,
  buildRecurrenceWeekMenuValues,
  recurrenceTimeSelectionRenderer,
  recurrenceWeekSelectionRenderer
} from '@utils/segment-schedule/recurrence';
import './edit-schedule-dialog-recurrence-list.scss';

const RecurrenceRow = ({
  deleteRecurrence,
  onAllDayToggle,
  onDayChange,
  onEndTimeChange,
  onStartTimeChange,
  recurrence
}) => {
  const onRemove = () => deleteRecurrence(recurrence);

  const onChangeDay = event => onDayChange(recurrence, event.target.value);

  const onChangeStart = event => onStartTimeChange(recurrence, event.target.value);

  const onChangeEnd = event => onEndTimeChange(recurrence, event.target.value);

  const toggleAllDay = () => onAllDayToggle(recurrence);

  return (
    <div styleName="recurrence-row">
      <div styleName="day item">
        <Select
          displayEmpty
          multiple
          onChange={onChangeDay}
          value={buildRecurrenceWeekMenuValues(recurrence)}
          renderValue={values => recurrenceWeekSelectionRenderer(recurrence, values)}
          InputProps={{ disableUnderline: true }}
        >
          {buildRecurrenceWeekMenu(recurrence)}
        </Select>
      </div>
      <div styleName="start item">
        <Select
          disabled={recurrence.all_day}
          displayEmpty
          multiple={false}
          onChange={onChangeStart}
          renderValue={value => recurrenceTimeSelectionRenderer(value, 'Start time')}
          value={recurrence.start_time}
          InputProps={{ disableUnderline: true }}
        >
          {buildRecurrenceTimeMenu('start_time', recurrence)}
        </Select>
      </div>
      <div styleName="to item">
        &ndash;
      </div>
      <div styleName="end item">
        <Select
          disabled={recurrence.all_day}
          displayEmpty
          multiple={false}
          onChange={onChangeEnd}
          renderValue={value => recurrenceTimeSelectionRenderer(value, 'End time')}
          value={recurrence.end_time}
          InputProps={{ disableUnderline: true }}
        >
          {buildRecurrenceTimeMenu('end_time', recurrence)}
        </Select>
      </div>
      <div styleName="all-day item">
        <CheckboxControl
          label={<div styleName="label">All day</div>}
          onChange={toggleAllDay}
          value={recurrence.all_day}
        />
      </div>
      <div styleName="action item">
        <IconButton onClick={onRemove} style={{ padding: 0 }}>
          <CloseIcon />
        </IconButton>
      </div>
    </div>
  );
};

RecurrenceRow.propTypes = {
  deleteRecurrence: PropTypes.func,
  onAllDayToggle: PropTypes.func,
  onDayChange: PropTypes.func,
  onEndTimeChange: PropTypes.func,
  onStartTimeChange: PropTypes.func,
  recurrence: PropTypes.object
};

export default RecurrenceRow;
