import React, { memo, useCallback, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import * as R from 'ramda';

import PageHeaderSmall from '@components/shared/ui-library/page-header/page-header-small';

import { clearTrays, popTray } from '@actions/map-actions';
import { getTraySecond } from '@selectors/map-selector';

const TrayHeader = ({
  title,
  icon,
  iconUrl,
  moreActions
}) => {
  const dispatch = useDispatch();
  const trayBack = useSelector(state => {
    const previousTray = getTraySecond(state);
    if (R.isEmpty(previousTray)) {
      return null;
    }
    return previousTray;
  });
  const backTitle = useMemo(() => {
    if (trayBack) {
      return `Back to ${trayBack.trayName}`;
    }
    return null;
  }, [trayBack]);
  const handlePopTray = useMemo(() => {
    if (trayBack) {
      return () => {
        dispatch(popTray());
      };
    }
    return null;
  }, [dispatch, trayBack]);
  const handleClearTrays = useCallback(() => {
    dispatch(clearTrays());
  }, [dispatch]);

  return (
    <PageHeaderSmall
      backTitle={backTitle}
      thumbnail= {(
        icon ||
        (iconUrl && <img src={iconUrl} alt=""/>) ||
        null
      )}
      moreActions={moreActions}
      onBack={handlePopTray}
      onClose={handleClearTrays}
      title={title}
    />
  );
};

TrayHeader.propTypes = {
  icon: PropTypes.node,
  iconUrl: PropTypes.string,
  moreActions: PropTypes.array,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node])
};

export default memo(TrayHeader);
