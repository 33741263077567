/* eslint-disable jsx-a11y/mouse-events-have-key-events */
/* eslint-disable react/jsx-no-bind */
import React from 'react';
import PropTypes from 'prop-types';
import { includes } from 'lodash';
import OverflowTooltipable from '@shared/overflow-tooltipable';
import FilterCheckbox from '@shared/filter-checkbox';
import '../filter.scss';

class FilterGroupItem extends OverflowTooltipable {
  isTooltipOpen = () => {
    const { item } = this.props;
    return this.state.open && this.state.itemName === item.name;
  };
  getEventField = event => ({
    itemName: event.currentTarget.innerText
  });
  render() {
    const {
      enabled,
      currentValues,
      filterEnabled,
      getIcon,
      item
    } = this.props;
    const icon = getIcon ? getIcon(item) : null;
    return (
      <div styleName="list-item list-item-half" key={item.id}>
        <div styleName="checkbox">
          <FilterCheckbox
            checked={includes(currentValues, item.id)}
            disabled={!filterEnabled || !enabled}
            onChange={() => this.props.onToggleCheckbox(item.id)}
          />
        </div>
        {icon && <div styleName="icon">{icon}</div>}
        <div styleName="label"
          onMouseOver={this.hoverOn}
          onMouseOut={this.hoverOff}
          role="presentation"
        >
          {item.name}
        </div>
        {this.renderTooltip(item.name)}
      </div>
    );
  }
}

FilterGroupItem.propTypes = {
  currentValues: PropTypes.array,
  enabled: PropTypes.bool,
  filterEnabled: PropTypes.bool,
  getIcon: PropTypes.func,
  item: PropTypes.object,
  onToggleCheckbox: PropTypes.func
};

export default FilterGroupItem;
