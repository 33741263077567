/* eslint-disable react/jsx-no-bind */
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Snackbar from 'material-ui/Snackbar';
import { popDashboardMessage } from '@actions/dashboard-actions';
import { snackbarStyles } from '@constants/mui-theme';

// The dialog snackbar is a Snackbar that will appear within a Dialog component.
//
// The original Snackbar appears at the bottom of the screen, its default effect
// is to be centered horizontally when it's open:
//
//   transform: translate(-50%, 0)
//
// And when it's closed, it will also be centered, but moved below the screen:
//
//   transform: translate(-50%, 48px)
//
// It will appear/disappear by sliding, with a CSS transition:
//
//   transition: transitions.easeOut('400ms', 'transform'), transitions.easeOut('400ms', 'visibility')
//
// In our case, if we maintained the original behavior, the Snackbar won't disappear,
// it will just slide below the dialog (and the dialog doesn't ends at the bottom of the page
// it's centered vertically and horizontally).
//
// Thus, we'll override the Snackbar's style, to only transition the 'visibility' property.
// And for the transformation, we'll only do it for 'x', so it's centered horizontally.
//
// Then, we'll add a bodyStyle customization, to change the height to zero when it's closed.
// And add a transition by the height attribute, so instead of sliding down the bottom of the
// page, it "disappears" below the dialog.
const DialogSnackbar = () => {
  const dispatch = useDispatch();
  const { messages } = useSelector(state => state.dashboard.snackbar);
  const isOpen = messages.length !== 0;
  return (
    <Snackbar
      open={isOpen}
      message={messages[0]}
      autoHideDuration={2500}
      onRequestClose={(...args) => dispatch(popDashboardMessage(...args))}
      {...snackbarStyles(isOpen)}
    />
  );
};

export default DialogSnackbar;
