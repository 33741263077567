import { createSelector } from 'reselect';
import * as R from 'ramda';
import {
  optimizeEntitiesForGanttChart,
  optimizeEntitiesForExport
} from '@utils/entity-utils';

const getGroupEntities = R.converge(
  R.concat,
  [R.pathOr([], ['group', 'entities']), R.pathOr([], ['polygonEntities', 'entities'])]
);

export const optimizeEntitiesForGanttChartSelector = createSelector([getGroupEntities], optimizeEntitiesForGanttChart);
export const optimizeEntitiesForExportSelector = createSelector([getGroupEntities], optimizeEntitiesForExport);
