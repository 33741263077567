import React, { memo } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import DateFilterChip from '../chips/date';

import { getDataTypeDisplayName } from '@components/config/selectors';
import { useDataTypeFilterHandleChange, useDefaultFutureDateOptions } from '../hooks';

const DateFilter = ({filter, value, dataType, subType, filterKey}) => {
  const typeLabel = useSelector(state => getDataTypeDisplayName(state, dataType, subType));
  const handleChange = useDataTypeFilterHandleChange(dataType, subType, filterKey);
  const options = useDefaultFutureDateOptions();
  const allowContained = filter.params.length === 4;

  if (!(filter.params.length === 2 || filter.params.length === 4)) {
    // Invalid configuration data. Escape to avoid anything bad.
    return null;
  }
  return (
    <DateFilterChip
      label={filter.label || 'Date'}
      typeLabel={typeLabel}
      dateOptions={options}
      dateValues={value}
      onChange={handleChange}
      contained={allowContained}
      customRange
    />
  );
};

DateFilter.propTypes = {
  dataType: PropTypes.string.isRequired,
  filter: PropTypes.object.isRequired,
  filterKey: PropTypes.string.isRequired,
  subType: PropTypes.string,
  value: PropTypes.object
};

export default memo(DateFilter);
