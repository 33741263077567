/* eslint-disable max-depth */
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import MapComponents from './map-components';
import DotMapsGoogleMap from '@shared/dotmaps-google-map';
import { segmentsToBounds } from '@utils/map-utils';
import './section.scss';

const Map = ({ segments, selectedSegmentId }) => {
  const [mapInstance, setMapInstance] = useState(null);

  const locations = useMemo(() => {
    if (segments) {
      if (selectedSegmentId) {
        return segments.filter(segment => segment.shape && segment.id === selectedSegmentId);
      }
    }
    return segments;
  }, [segments, selectedSegmentId]);

  const fitSegmentBounds = useCallback(() => {
    if (locations?.length > 0) {
      const bounds = segmentsToBounds(locations);
      if (bounds) {
        mapInstance.fitBounds(bounds);
        if (mapInstance.getZoom() > 18) {
          mapInstance.setZoom(18);
        }
      }
    }
  }, [locations, mapInstance]);

  const onMapLoad = useCallback(map => {
    window.mapInstance = map;
    setMapInstance(map);
  }, [setMapInstance]);

  useEffect(() => {
    if (selectedSegmentId) {
      fitSegmentBounds();
    }
  }, [fitSegmentBounds, selectedSegmentId]);

  useEffect(() => {
    if (mapInstance) {
      fitSegmentBounds();
    }
  }, [fitSegmentBounds, mapInstance]);

  return (
    <div styleName="section-map">
      <DotMapsGoogleMap
        components={<MapComponents segments={segments} />}
        onMapLoad={onMapLoad}
      />
    </div>
  );
};

Map.propTypes = {
  segments: PropTypes.array,
  selectedSegmentId: PropTypes.string
};

export default Map;
