import React, { memo, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import Header from '@components/portal/details/header';
import Sections from '@components/portal/details/sections';
import TopBar from '@components/portal/details/top-bar';
import {
  createEmptyDataSet,
  fetchDashboardDetailsData
} from '@actions/dashboard-actions';
import { getInitialValues } from '@utils/dashboard-details-utils';
import './details.scss';

const Details = () => {
  const { dataType, edit: editParam, id } = useParams();
  const { details } = useSelector(state => state.config);
  const dispatch = useDispatch();

  const edit = useMemo(() => editParam === 'edit' || id === 'new', [editParam, id]);

  useEffect(() => {
    if (id === 'new') {
      const fields = details[dataType];
      const initialValues = getInitialValues(fields);
      dispatch(createEmptyDataSet(dataType, initialValues));
    } else {
      dispatch(fetchDashboardDetailsData(dataType, id));
    }
  }, [id, dataType, details, dispatch]);

  return (
    <div styleName="portal-details-container">
      <TopBar edit={edit} />
      <div styleName="portal-details-content">
        {!edit && dataType !== 'batch' && <Header />}
        <Sections edit={edit} />
      </div>
    </div>
  );
};

export default memo(Details);
