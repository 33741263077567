import React from 'react';
import axios from 'axios';
import * as R from 'ramda';
import moment from 'moment-timezone';
import Cookies from 'universal-cookie';
import { Icon } from '@mui';
import DefaultDate from '@shared/formatted-date-helper';
import DefaultTooltipDate from '@shared/formatted-date-tooltip-helper';
import { getConfig } from '@utils/config-utils';

export const getCookies = () => new Cookies();

export const sortBy = (array, field) => R.sortBy(R.compose(R.toLower, R.prop(field)))(array);

export const parseDate = date => {
  const dateObj = moment(date, 'YYYY-MM-DD');
  if (dateObj.isValid()) {
    return dateObj;
  }
  return null;
};

export const parseDateTime = dateTime => {
  const dateTimeObj = moment(dateTime, 'YYYY-MM-DDThh:mm:ss');
  if (dateTimeObj.isValid()) {
    return dateTimeObj;
  }
  return null;
};

export const renderDate = date => <DefaultDate value={parseDate(date)} />;

export const renderTooltipDate = date => <DefaultTooltipDate value={date} />;

// Truncates a string if it exceeds the specified length.
export const truncateString = (str, len) => {
  if (str.length > len) {
    return `${str.substring(0, len)}...`;
  }
  return str;
};

export const isWithinLastWeek = date => moment().diff(date, 'days') <= 7;

export const openHelpPage = () => window.open(getConfig().helpDocsUrl);

// Helper for abstract class methods.
export const NotImplemented = () => {
  throw new Error('Not implemented');
};

// Removes dictionary/list items whose values are empty string
export const rejectEmptyStrings = R.reject(value => value === '');

export const pluralize = (num, label, pluralLabel = null) => {
  if (num === 1) {
    return label;
  }
  if (pluralLabel) {
    // For when adding an 's' is not correct for the label
    // (i.e. 'agencies' is the plural for 'agency', not 'agencys').
    return pluralLabel;
  }
  return `${label}s`;
};

export const pagedFetchThunk = (url, params, updateThunk, errorThunk) => async dispatch => {
  let nextParams = params;
  let nextUrl = url;
  try {
    while (nextUrl) {
      const { data } = await axios.get(nextUrl, nextParams);
      dispatch(updateThunk(data));
      nextParams = {};
      nextUrl = data.next;
    }
    return Promise.resolve();
  } catch (error) {
    dispatch(errorThunk(error));
    throw error;
  }
};

// eslint-disable-next-line no-useless-escape
const escapeRegex = str => str.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&');

// Highlights a 'term' on the specified text by making it bold.
export const highlightTerm = (text, term) => {
  const parts = text.split(new RegExp(`(${escapeRegex(term)})`, 'gi'));  // gi == global + case insensitive
  return (
    <span>
      {parts.map((part, index) => <span key={index} style={part.toLowerCase() === term.toLowerCase() ? { fontWeight: 'bold' } : {}}>{part}</span>)}
    </span>
  );
};

export const formatNumber = number => number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');

export const formatDate = date => moment(date).format('M/D/YYYY');

export const enDash = String.fromCharCode(0x2013);

// Formatter for Material UI's KeyboardDatePicker.
//
// Use it as:
//
//     <KeyboardDatePicker ... rifmFormatter={maskedDateFormatter} ... />

const MASK_USER_INPUT_SYMBOL = '_';
const MASK = '__/__/____';
const ACCEPT_REGEX = /[\d]/gi;

export const maskedDateFormatter = value => {
  return value
    .split('')
    .map((chr, index) => {
      ACCEPT_REGEX.lastIndex = 0;

      if (index > MASK.length - 1) {
        return '';
      }

      const maskChar = MASK[index];
      const nextMaskChar = MASK[index + 1];

      const acceptedChar = ACCEPT_REGEX.test(chr) ? chr : '';
      const formattedChar =
        maskChar === MASK_USER_INPUT_SYMBOL ? acceptedChar : maskChar + acceptedChar;

      if (index === value.length - 1 && nextMaskChar && nextMaskChar !== MASK_USER_INPUT_SYMBOL) {
        // When cursor at the end of mask part (e.g. month) prerender next symbol "21" -> "21/"
        return formattedChar ? formattedChar + nextMaskChar : '';
      }

      return formattedChar;
    })
    .join('');
};

export const getListOfIds = R.map(R.prop('id'));

// Delete icon for SquareChips:
export const squareChipDeleteIcon = <Icon style={{ fontSize: '1rem' }}>close</Icon>;

export const mergeArraysById = (oldArray, newArray) => {
  const merged = new Map();

  // Add all objects from both arrays to the map, overwriting existing entries:
  oldArray.forEach(obj => merged.set(obj.id, obj));
  newArray.forEach(obj => merged.set(obj.id, obj));

  // Convert the map back to an array:
  return Array.from(merged.values());
};
