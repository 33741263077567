import React, { memo } from 'react';
import { getConfig } from '@utils/config-utils';
import './city.scss';

const City = () => (
  <div>
    <div styleName="header-city-separator" />
    <div styleName="header-city-container">{getConfig().cityName}</div>
  </div>
);

export default memo(City);
