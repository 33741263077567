/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/jsx-no-bind */
import React, { memo, useCallback, useContext, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { isEmpty } from 'lodash';
import { openGroupsTray } from '@actions/map-actions';
import OverlapContext from '@components/overlap/overlap-context';
import Skeleton from '@material-ui/lab/Skeleton';
import { Box } from '@material-ui/core';
import DetailCard from '@shared/list-card/detail-card';
import '../shared/list-item/list-item.scss';

const DrawerMainCard = () => {
  const dispatch = useDispatch();
  const { groups, mainEntity, isSelected, selectEntity } = useContext(OverlapContext);

  const openGroup = useCallback((event, group) => {
    event.stopPropagation();
    const trayProps = { id: group.id, item: group, position: null };
    dispatch(openGroupsTray(trayProps));
  }, [dispatch]);

  const noConflictGroups = useMemo(() => {
    if (isEmpty(groups)) {
      return null;
    }
    return (
      <div styleName="group-list">
        <div styleName="group-list-text">
          The list below excludes overlaps with records in&nbsp;
        </div>
        <div styleName="group-list-bold">
          {groups.map((group, index) => (
            <span key={group.id}>
              <div
                onClick={event => openGroup(event, group)}
                role="presentation"
                styleName="group-list-link"
              >
                {group.name}
              </div>
              {index < groups.length - 1 ? ', ' : ''}
            </span>
          ))}
        </div>
      </div>
    );
  }, [groups, openGroup]);

  const onClick = useCallback(() => selectEntity(mainEntity), [mainEntity, selectEntity]);

  const selectedStyle = useMemo(() => isSelected(mainEntity?.id) ? 'list-item-selected' : '', [mainEntity?.id, isSelected]);

  return (
    <div
      key={mainEntity?.id}
      id={`list-item-${mainEntity?.id}`}
      onClick={onClick}
      role="presentation"
      styleName={`list-item fixed-main-list-item overlap-list-item ${selectedStyle}`}
    >
      {mainEntity && <DetailCard id={`list-item-${mainEntity?.id}`} entity={mainEntity} />}
      {!mainEntity && <Box m={2}><Skeleton variant="text" width="100%" height="10rem" /></Box>}
      <div styleName="footer">
        {mainEntity?.description && <div styleName="description">{mainEntity?.description}</div>}
        {noConflictGroups}
      </div>
    </div>
  );
};

export default memo(DrawerMainCard);
