/* eslint-disable react/jsx-no-bind */
import React, { memo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateBatch } from '@components/settings/actions';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import './settings.scss';

const Batch = () => {
  const { global: { batch } } = useSelector(state => state.config);
  const dispatch = useDispatch();

  const onChange = event => dispatch(updateBatch(event.target.value));

  return (
    <div styleName="settings-form-container">
      <div styleName="title">
        <div styleName="text">Batch</div>
      </div>
      <div styleName="hint">
        Switch between the old and new batch design.
      </div>
      <div styleName="form">
        <FormControl component="fieldset">
          <RadioGroup aria-label="batch" name="batch" value={batch} onChange={onChange}>
            <FormControlLabel value="old" control={<Radio color="primary" />} label="Old Batch Design" />
            <FormControlLabel value="default" control={<Radio color="primary" />} label="New Batch Design" />
          </RadioGroup>
        </FormControl>
      </div>
    </div>
  );
};

export default memo(Batch);
