import * as R from 'ramda';

export const isNilOrEmpty = val => R.or(R.isNil(val), R.isEmpty(val));

export const URL_ERROR_MSG = 'Please provide a valid URL';

export const isValidUrlWithMessage = str => {
  try {
    if (str !== '' && !R.test(/^https:\/\//, str)) {
      return [false, URL_ERROR_MSG];
    }

    const newUrl = new URL(str);
    // catch valid URL with no top-level-domain or empty domain/subdomain:
    const [extension, ...domain] = newUrl.hostname
      .split('.')
      .filter(segment => segment !== 'www')
      .reverse();
    if (
      !extension ||
      isNilOrEmpty(domain) ||
      domain.some(segment => !segment)
    ) {
      return [false, URL_ERROR_MSG];
    }
    return [true, ''];
  } catch (err) {
    if (str === '') {
      return [true, ''];
    }
    return [false, URL_ERROR_MSG];
  }
};
