import React from 'react';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import ArrowDropDownCircleIcon from '@material-ui/icons/ArrowDropDownCircle';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import BusinessIcon from '@material-ui/icons/Business';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import LabelOutlinedIcon from '@material-ui/icons/LabelOutlined';
import LocalAtmIcon from '@material-ui/icons/LocalAtm';
import LooksOneIcon from '@material-ui/icons/LooksOne';
import NotesIcon from '@material-ui/icons/Notes';
import PersonIcon from '@material-ui/icons/Person';
import ReplyIcon from '@material-ui/icons/Reply';
import ShortTextIcon from '@material-ui/icons/ShortText';

const iconProps = { htmlColor: '#555555', style: { fontSize: '1rem' } };

const getIcon = Icon => <Icon {...iconProps} />;

export const DETAILS_FIELDS = [
  {
    core: true,
    field: 'owner',
    icon: getIcon(PersonIcon),
    key: 'owner',
    label: 'Owner',
    order: 2,
    required: true,
    source: 'user'
  },
  {
    core: true,
    field: 'activities',
    icon: getIcon(BusinessIcon),
    key: 'activities',
    label: 'Assigned agencies',
    order: 3,
    required: true,
    source: 'agency'
  },
  {
    core: true,
    field: 'roles',
    icon: getIcon(LabelOutlinedIcon),
    key: 'roles',
    label: 'Assigned teams',
    order: 4,
    required: true,
    source: 'user_roles'
  },
  {
    core: true,
    field: 'due_date',
    icon: getIcon(CalendarTodayIcon),
    key: 'due_date',
    label: 'Due date',
    order: 5
  }
];

export const ADMIN_DETAILS_FIELDS = [
  {
    core: true,
    field: 'name',
    icon: getIcon(ShortTextIcon),
    key: 'name',
    label: 'Task name',
    order: 1,
    required: true
  },
  ...DETAILS_FIELDS
];

export const RESPONSE_FIELDS = [
  {
    core: true,
    field: 'assignee',
    icon: getIcon(PersonIcon),
    key: 'assignee',
    label: 'Assignee',
    order: -3,
    required: true,
    source: 'user'
  },
  {
    core: true,
    field: 'response',
    icon: getIcon(ReplyIcon),
    key: 'response',
    label: 'Response',
    order: -2,
    required: true,
    source: 'task_response'
  },
  {
    core: true,
    field: 'attachments',
    icon: getIcon(AttachFileIcon),
    key: 'attachments',
    label: 'Attachments',
    order: -1
  }
];

const FIELD_SHORT_TEXT = 1;
const FIELD_LONG_TEXT = 2;
const FIELD_SINGLE_SELECT = 3;
const FIELD_MULTI_SELECT = 4;
const FIELD_DATE_PICKER = 5;
const FIELD_TIME_PICKER = 6;
const FIELD_NUMERIC = 7;
const FIELD_CURRENCY = 8;
const FIELD_FILE_UPLOAD = 9;
const FIELD_CHIP_MULTI_SELECT = 10;
const FIELD_SQUARE_CHIP_MULTI_SELECT = 11;

export const TASK_TYPE_FIELD_TYPE_ICON = {
  [FIELD_SHORT_TEXT]: getIcon(ShortTextIcon),
  [FIELD_LONG_TEXT]: getIcon(NotesIcon),
  [FIELD_SINGLE_SELECT]: getIcon(ArrowDropDownCircleIcon),
  [FIELD_MULTI_SELECT]: getIcon(ArrowDropDownCircleIcon),
  [FIELD_DATE_PICKER]: getIcon(CalendarTodayIcon),
  [FIELD_TIME_PICKER]: getIcon(AccessTimeIcon),
  [FIELD_NUMERIC]: getIcon(LooksOneIcon),
  [FIELD_CURRENCY]: getIcon(LocalAtmIcon),
  [FIELD_FILE_UPLOAD]: getIcon(AttachFileIcon),
  [FIELD_CHIP_MULTI_SELECT]: getIcon(ArrowDropDownCircleIcon),
  [FIELD_SQUARE_CHIP_MULTI_SELECT]: getIcon(ArrowDropDownCircleIcon)
};

export const LIST_FIELDS = [FIELD_SINGLE_SELECT, FIELD_MULTI_SELECT, FIELD_CHIP_MULTI_SELECT, FIELD_SQUARE_CHIP_MULTI_SELECT];

export const sectionTooltips = {
  1: (
    <div>
      Context fields are system defined fields for assigning, managing and tracking tasks.
      <br />
      <br />
      Assigned agencies cannot edit context fields
    </div>
  ),
  2: (
    <div>
      Information fields provides information of the work that needs to be done.
      <br />
      <br />
      Assigned agencies cannot edit information fields
    </div>
  ),
  3: (
    <div>
      Response fields collect information from each assigned agencies individually.
      <br />
      <br />
      Owner agency cannot edit response fields
    </div>
  )
};
