import React, { memo } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { colors } from '@constants/colors';
import { getFilters } from '@selectors/data-table-selector';
import { isCurrentFilter } from '@utils/filter-utils';
import SortIconButton from './sort-icon-button';
import './header.scss';

const Header = ({ columns, dataType, isFixed, subType }) => {
  const filters = useSelector(state => getFilters(state, {
    columns,
    dataType,
    isFixed,
    subType
  }));

  const getColumnWidth = width => ({
    maxWidth: `${width}rem`,
    minWidth: `${width}rem`,
    width: `${width}rem`
  });

  const getTextColor = column => {
    if (column.ordering && filters) {
      const { ordering } = column;
      if (isCurrentFilter(filters, ordering)) {
        return colors.opacity.black0_8;
      }
    }
    return colors.opacity.black0_5;
  };

  return (
    <thead data-testid="datatable-header" styleName="table-header">
      <tr>
        {columns.map(column => {
          const { styleName } = column;
          const isRight = styleName && styleName.includes('right');
          // If we are not sorting, use a hidden SortIcon to maintain space,
          // since if it's the first column, height might be misaligned, since
          // the first column is fixed (i.e. it doesn't scroll).
          const sortIcon = column.ordering ?
            <SortIconButton column={column} dataType={dataType} subType={subType} /> :
            <SortIconButton hidden />;
          return (
            <td key={column.key}
              data-testid="data-table-header-column-container"
              style={getColumnWidth(column.width)}
              styleName={`column-container ${styleName ? styleName : ''} ${isFixed ? 'column-fixed' : ''}`}
            >
              <div styleName="column-wrapper">
                {isRight && sortIcon}
                <span style={{ color: getTextColor(column) }}>{column.label}</span>
                {!isRight && sortIcon}
              </div>
            </td>
          );
        })}
      </tr>
    </thead>
  );
};

Header.propTypes = {
  columns: PropTypes.array.isRequired,
  dataType: PropTypes.string.isRequired,
  isFixed: PropTypes.bool,
  subType: PropTypes.string
};

export default memo(Header);
