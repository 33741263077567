import axios from 'axios';
import * as R from 'ramda';
import { replace } from 'connected-react-router';
import { openDialog } from '@actions/dialogs-actions';
import { setGroupsLayerVisible, setGroupsLayerBoundariesVisible } from '@actions/groups-actions';
import { fetchLayerConfig, setLayerVisible } from '@actions/layers-actions';
import { getServerErrorAction } from '@actions/shared-actions';
import {
  ENTITY_ADD_SINGLE,
  MAP_CLEAR_TRAY,
  MAP_FILTERS_CHANGED,
  MAP_FIT_BOUNDS,
  MAP_OVERLAP_TRAY_HOVER,
  MAP_POP_TRAY,
  MAP_PUSH_TRAY,
  MAP_SELECT_TRAY_SEGMENT,
  MAP_SET_DRAWING_DONE,
  MAP_SET_DRAWING_MODE,
  MAP_SET_DRAWING_PROPS,
  MAP_SET_DRAWING_PATH,
  MAP_SET_GOOGLE_AUTOCOMPLETE_SUGGESTIONS,
  MAP_SET_MAP_FILTER_RADIO,
  MAP_SET_MODE,
  MAP_SET_SEARCH_TERM,
  MAP_SET_TYPE_ID,
  MAP_SET_MAP_FILTER_TOGGLE,
  MAP_TOGGLE_BICYCLE,
  MAP_TOGGLE_LEGEND,
  MAP_TOGGLE_LAYERS_MENU,
  MAP_TOGGLE_MAP_FILTER_TOGGLE,
  MAP_TOGGLE_MARKER_MODAL,
  MAP_TOGGLE_MARKERS,
  MAP_TOGGLE_ONLY_SHOW_OVERLAPS,
  MAP_TOGGLE_POIS,
  MAP_TOGGLE_TRAFFIC,
  MAP_TOGGLE_TRANSIT,
  MAP_TOGGLE_TRAY,
  MAP_VIEWPORT_CHANGE,
  RESET_MAP_FILTERS,
  SET_MAP_SELECTION_POSITION
} from '@constants/action-types';
import { getMarkerVisibleZoom } from '@constants/config';
import { BASE_API_URL } from '@constants/endpoints';
import {
  ADD_TO_GROUP_TRAY,
  ENTITY_TRAY,
  FILTER_TRAY,
  LAYER_TRAY,
  GROUP_TRAY,
  OVERLAP_TRAY
} from '@constants/map-trays';
import { boundsEntitiesSelector, getEnabledEntityTypes } from '@selectors/entities-selector';
import { boundsLayersSelector, optimizeLayerForMap } from '@selectors/layers-selector';
import { mapGroupsSelector } from '@selectors/groups-selector';
import { getTrayGroup } from '@selectors/map-selector';
import { optimizeEntityForTray, optimizeEntitiesForMap } from '@utils/entity-utils';
import { circleIntersects } from '@utils/geometry-utils';
import { optimizeLayerForTray } from '@utils/layer-utils';
import {
  calculateRadius, circleBounds, getActiveTabTray, getWKTViewportBoundsFromState
} from '@utils/map-utils';
import { generateEntityId } from '@utils/markers-utils';
import { optimizeOverlapForTray } from '@utils/overlap-utils';

const requestUrl = (id, type) => `${BASE_API_URL}/${type}/${id}/`;

const newFilterValues = (filters, viewport) => ({
  type: MAP_FILTERS_CHANGED,
  filters,
  viewport
});

const toggleHighlightCircle = mode => {
  if (window.highlightCircle) {
    if (mode === '') {
      window.highlightCircle.setVisible(true);
    } else
      if (mode !== null) {
        window.highlightCircle.setVisible(false);
      }
    // else it's null, which means mode was not set, thus do nothing.
  }
};

export const setDrawingProps = props => dispatch => {
  const { shape, mode, start, end } = props;
  if (!shape) {
    // If the shape is cleared, close the tray:
    dispatch({ type: MAP_CLEAR_TRAY });
  }
  toggleHighlightCircle(mode);
  dispatch({ type: MAP_SET_DRAWING_PROPS, props });

  if (mode === '' && start === null && end === null) {
    // Reset the cursor, in case it was in measurement mode (crosshair):
    window.mapInstance?.setOptions({draggableCursor: ''});
  }
};

export const setDrawingMode = mode => dispatch => {
  // Set crosshair cursor when in measurement mode:
  if (mode === 'measurement') {
    window.mapInstance?.setOptions({draggableCursor: 'crosshair'});
  }
  dispatch({ type: MAP_SET_DRAWING_MODE, mode });
  toggleHighlightCircle(mode);
};

export const setDrawingDone = done => dispatch => dispatch({ type: MAP_SET_DRAWING_DONE, done });

export const setDrawingPath = coords => dispatch => dispatch({ type: MAP_SET_DRAWING_PATH, coords });

export const openTrayAction = (trayType, trayName, payload = null) => (
  { type: MAP_PUSH_TRAY, trayType, trayName, payload }
);

export const setMapModes = modes => dispatch => dispatch({ type: MAP_SET_MODE, modes });

export const setMapActiveTab = tab => dispatch => {
  const newTray = getActiveTabTray(tab);
  dispatch(openTrayAction(newTray.trayType, newTray.trayName));
};

export const setMapFilters = filters => (dispatch, getState) => {
  const viewport = getWKTViewportBoundsFromState(getState());
  dispatch(newFilterValues(filters, viewport));
};

const newMapTypeId = payload => ({ type: MAP_SET_TYPE_ID, payload });

export const setMapTypeId = mapTypeId => dispatch => dispatch(newMapTypeId(mapTypeId));

export const setMapFilterRadio = (entityType, event, value) => (dispatch, getState) => {
  const viewport = getWKTViewportBoundsFromState(getState());
  return dispatch({
    type: MAP_SET_MAP_FILTER_RADIO,
    entityType,
    value,
    viewport
  });
};

// Toggle a map filter (i.e. enabling/disabling the display of layers, groups, or entities):
export const toggleMapFilterToggle = filter => (dispatch, getState) => {
  const { filterToggles } = getState().map;
  dispatch({ type: MAP_TOGGLE_MAP_FILTER_TOGGLE, filter, filterToggles });
};

// Like toggleMapFilterToggle() but this specifically enables or disables a filter (instead of toggling its current status).
export const setMapFilterToggle = (filter, visible) => (dispatch, getState) => {
  const { filterToggles } = getState().map;
  dispatch({ type: MAP_SET_MAP_FILTER_TOGGLE, filter, visible, filterToggles });
};

export const toggleTray = () => dispatch => dispatch({ type: MAP_TOGGLE_TRAY });

export const toggleOnlyShowOverlaps = () => dispatch => dispatch({ type: MAP_TOGGLE_ONLY_SHOW_OVERLAPS });

export const clearTrays = () => dispatch => dispatch({ type: MAP_CLEAR_TRAY });

export const toggleTraffic = () => dispatch => dispatch({ type: MAP_TOGGLE_TRAFFIC });

export const toggleBicycle = () => dispatch => dispatch({ type: MAP_TOGGLE_BICYCLE });

export const toggleTransit = () => dispatch => dispatch({ type: MAP_TOGGLE_TRANSIT });

export const popTray = () => dispatch => dispatch({ type: MAP_POP_TRAY });

export const toggleLegend = () => dispatch => dispatch({ type: MAP_TOGGLE_LEGEND });

export const toggleLayersMenu = () => dispatch => dispatch({ type: MAP_TOGGLE_LAYERS_MENU });

export const toggleMarkerModal = () => dispatch => dispatch({ type: MAP_TOGGLE_MARKER_MODAL });

export const toggleMarkers = () => dispatch => dispatch({ type: MAP_TOGGLE_MARKERS });

export const togglePois = () => dispatch => dispatch({ type: MAP_TOGGLE_POIS });

const setMapSelectionPosition = (position, radius, bounds) => dispatch => dispatch(
  {type: SET_MAP_SELECTION_POSITION, position, radius, bounds}
);

export const openEntityTray = ({
  id: entityId,
  type: entityType,
  name: placeholderName = '',
  position = null,
  clearPrevious = false,
  fitEntityBounds = false
}) => async (dispatch, getState) => {
  if (clearPrevious) {
    dispatch(clearTrays());
  }
  dispatch(openTrayAction(ENTITY_TRAY, placeholderName, {entityId, entity: { id: entityId, entity_type: entityType }, pending: true}));
  const request = axios.get(`${requestUrl(entityId, 'map')}?type=${entityType}`);
  let response;
  try {
    response = await request;
  } catch (err) {
    if (err.response && err.response.status === 404) {
      dispatch(openDialog(entityType));
      return;
    }
    dispatch(getServerErrorAction(entityType, err));
    return;
  }
  const { data } = response;
  const { agency_type, map_type } = getState().dataTypes;
  const entity = optimizeEntitiesForMap([data], entityType, { agency_type, map_type })[0];
  // Adds to map. If we are on the map view, we need to ensure it is actually displayed.
  dispatch({ type: ENTITY_ADD_SINGLE, entity, entityType });
  const { segments } = data;
  let center = position;
  if (entity.segments && entity.segments.length > 0) {
    if (!center && entity.segments[0].center) {
      center = entity.segments[0].center;
    }
    if (fitEntityBounds) {
      dispatch({ type: MAP_FIT_BOUNDS, payload: { segments } });
    }
  }
  if (!center) {
    center = {};
  }
  dispatch(openTrayAction(ENTITY_TRAY, data.name, optimizeEntityForTray(data, center, entityType, getState().dataTypes)));
};

export const openFilterTray = () => dispatch => {
  dispatch(openTrayAction(FILTER_TRAY, 'filters'));
};

export const openLayerTray = (trayProps, clearPrevious = false) => (dispatch, getState) => {
  const { id, type } = trayProps;
  const layer = getState().layers[type];
  if (clearPrevious) {
    dispatch(clearTrays());
  }
  dispatch(openTrayAction(LAYER_TRAY, layer.label, {layerId: id, pending: true}));
  // The layer type is 'moratoriums', but the entity type 'moratorium':
  const url = type === 'moratoriums' ? `${requestUrl(id, 'map')}?type=moratorium` : requestUrl(id, layer.request_url);
  axios.get(url).then(({ data }) => {
    dispatch(openTrayAction(
      LAYER_TRAY,
      layer.label,
      optimizeLayerForTray(data, layer.aliases, layer.custom_aliases, type, layer.label, layer.icon)
    ));
  });
};

export const openGroupsTray = (trayProps, clearPrevious = false) => (dispatch, getState) => {
  const { id, item: group, position } = trayProps;
  if (clearPrevious) {
    dispatch(clearTrays());
  }
  dispatch(openTrayAction(GROUP_TRAY, group.name, { groupId: id, group, position}));
  axios.get(requestUrl(id, 'group')).then(({ data }) => {
    const currentTrayGroup = getTrayGroup(getState());
    // Check that the tray has not changed while loading
    if (currentTrayGroup && currentTrayGroup.id === id) {
      dispatch(popTray());
      dispatch(openTrayAction(GROUP_TRAY, data.name, { groupId: data.id, group: data, position }));
    }
  });
};

export const openAddToGroupTray = trayProps => dispatch => {
  dispatch(openTrayAction(ADD_TO_GROUP_TRAY, 'add to group', { ...trayProps }));
};

export const openOverlapTray = (id, position, data, clearPrevious = true) => dispatch => {
  if (clearPrevious) {
    dispatch(clearTrays());
  }
  dispatch(openTrayAction(OVERLAP_TRAY, 'results', optimizeOverlapForTray(id, data, position)));
};

// Same as the overlap tray above but without setting data
// (which will be obtained from the redux store).
export const openAreaResultsTray = () => dispatch => dispatch(openTrayAction(OVERLAP_TRAY, 'results', { overlap: null }));

export const isWithin = ({center, radius, bounds}, entity) => (
  (!entity.bounds || bounds.intersects(entity.bounds)) &&
  circleIntersects(center, radius, entity.shape)
);

// Retrieve all layers within radius:
const getLayersWithin = (selection, layers) => {
  const layersInRadius = {};
  // For each layer, return only the entries within radius:
  Object.keys(layers).forEach(key => {
    const { list } = layers[key];
    const inRadiusList = list.filter(layer => isWithin(selection, layer));
    layersInRadius[key] = { ...layers[key], list: inRadiusList };
  });
  return layersInRadius;
};

const layersLength = layers => {
  let len = 0;
  Object.keys(layers).forEach(key => {
    const { list } = layers[key];
    len += list.length;
  });
  return len;
};

// Returns the first layer with data
// (the method calling this already checked there's only
// one layer with data).
const getFirstLayer = layers => {
  let layer = {};
  Object.keys(layers).forEach(key => {
    const { list } = layers[key];
    if (!R.isEmpty(list)) {
      layer = {
        id: list[0].id,
        type: key
      };
    }
  });
  return layer;
};

const optimizeLayersForTray = layers => {
  const optimizedLayers = {};
  Object.keys(layers).forEach(key => {
    // Don't retrieve 'visible', all layers here are already visible.
    const { label, icon, list } = layers[key];
    optimizedLayers[key] = optimizeLayerForMap(label, icon, list);
  });
  return optimizedLayers;
};

// For the current clicked position on the map (which is actually a marker
// we clicked), find all entities and layers within a specific
// radius and open all those entities in the tray.
export const openAreaTray = position => (dispatch, getState) => {
  const state = getState();
  const zoom = state.map.viewport.zoom;
  if (zoom < getMarkerVisibleZoom()) {
    // Do nothing at high zoom levels.
    return;
  }

  const radius = calculateRadius(zoom);
  const selection = {
    center: [position.lng, position.lat],
    radius,
    bounds: circleBounds(position.lng, position.lat, radius)
  };

  // For performance use the bounds selector (it's faster since we only
  // lookup the entities within the current viewport and because it's a selector).
  const entityTypes = getEnabledEntityTypes(state);
  const entities = {};
  const entitiesCount = {};
  let allEntitiesCount = 0;

  // Tests whether the specified entity is within the specified radius of
  // the clicked position on the map.
  const isEntityInSelection = entity => (
    selection.bounds.intersects(entity.bounds) &&
    entity.segments.some(segment => isWithin(selection, segment))
  );
  entityTypes.forEach(type => {
    entities[type] = boundsEntitiesSelector(state, { type }).filter(isEntityInSelection);
    entitiesCount[type] = entities[type].length;
    allEntitiesCount += entitiesCount[type];
  });
  const layers = getLayersWithin(selection, boundsLayersSelector(state));
  const groups = (
    mapGroupsSelector(state)
      .filter(group => (
        group.auto_area &&
      selection.bounds.intersects(group.bounds) &&
      circleIntersects(selection.center, selection.radius, group.auto_area.shape)
      ))
  );

  // If the total count of all these arrays is one, open that specific entity,
  // else open all the selected elements in the tray:
  const layersCount = layersLength(layers);
  const groupsCount = groups.length;
  const totalCount = layersCount + groupsCount + allEntitiesCount;

  if (totalCount === 0) {
    // Don't open the tray if there are nothing to show
    // (like when we clicked on an empty area).
    return;
  }

  dispatch(setMapSelectionPosition(position, radius, selection.bounds));
  if (totalCount === 1) {
    if (layersCount) {
      const layer = getFirstLayer(layers);
      const trayProps = { ...layer };
      dispatch(openLayerTray(trayProps, true));
    } else if (groupsCount) {
      const trayProps = { id: groups[0].id, item: groups[0], position};
      dispatch(openGroupsTray(trayProps, true));
    } else if (allEntitiesCount) {
      Object.keys(entitiesCount).forEach(type => {
        if (entitiesCount[type]) {
          const trayProps = { id: entities[type][0].id, position, type };
          dispatch(openEntityTray({...trayProps, clearPrevious: true}));
        }
      });
    }
  } else {
    // Else there's more than one element, gather them all and open them on the tray:
    const items = { layers: optimizeLayersForTray(layers), groups, entities };
    const id = generateEntityId(items);
    dispatch(openOverlapTray(id, position, items, true));
  }
};

export const fitBounds = segments => dispatch => dispatch({ type: MAP_FIT_BOUNDS, payload: { segments } });

const getSetViewportAction = (source, map) => ({
  type: MAP_VIEWPORT_CHANGE,
  payload: {
    source,
    bounds: map.getBounds(),
    center: map.getCenter(),
    zoom: map.getZoom()
  }
});

export const setViewport = (source, map) => dispatch => dispatch(getSetViewportAction(source, map));

export const focusMap = (center, item) => dispatch => dispatch({
  type: MAP_VIEWPORT_CHANGE,
  payload: {
    source: 'map',
    center,
    markerItem: item,
    defaultMarkerPosition: center,
    zoom: 18
  }
});

export const showAutocompleteError = error => dispatch => dispatch(getServerErrorAction('autocomplete', new Error(error)));

export const enableShapeSelection = () => (dispatch, getState) => {
  dispatch(replace('/map'));
  setDrawingProps({ shape: null, mode: 'polygon' })(dispatch);
  setMapFilters({ geom_in: null })(dispatch, getState);
};

export const viewEntityOnMap = (entityId, entityType, name = '') => dispatch => {
  dispatch(openEntityTray({id: entityId, type: entityType, name, fitEntityBounds: true}));
};

export const viewGroupOnMap = groupId => dispatch => {
  axios.get(requestUrl(groupId, 'group')).then(({ data }) => {
    // If the group is one in which area capture is enabled,
    // it means it has an area, thus enable display of
    // group shapes and zoom to it.
    if (data.area_capture > 0) {
      // Enable the group map filter to be able to see the map boundaries on the map:
      dispatch(setMapFilterToggle('group', true));
      dispatch(setGroupsLayerVisible(true));
      dispatch(setGroupsLayerBoundariesVisible(true));
      // Center and zoom the map to the group's shape:
      if (data.auto_area) {
        dispatch({ type: MAP_FIT_BOUNDS, payload: { segments: [data.auto_area] } });
      }
    }
    // Open the map tray and display the specified group in it:
    dispatch(openTrayAction(GROUP_TRAY, data.name, { groupId: data.id, group: data }));
  });
};

export const viewMoratoriumOnMap = moratoriumId => (dispatch, getState) => {
  const type = 'moratoriums';
  dispatch(fetchLayerConfig()).then(() => {
    const layer = getState().layers[type];
    axios.get(`${requestUrl(moratoriumId, 'map')}?type=moratorium`).then(({ data }) => {
      const { shape } = data;
      const segments = [{ shape }];
      dispatch({ type: MAP_FIT_BOUNDS, payload: { segments } });
      dispatch(setMapFilterToggle('layer', true));
      dispatch(setLayerVisible(type, true));
      dispatch(clearTrays());
      dispatch(openTrayAction(
        LAYER_TRAY,
        layer.label,
        optimizeLayerForTray(data, layer.aliases, layer.custom_aliases, type, layer.label, layer.icon))
      );
    },
    ({response: errorPayload}) => {
      if (errorPayload.status === 404) {
        dispatch(openDialog('moratorium'));
      }
    });
  });
};

const setOverlapHover = (type, value) => dispatch => dispatch({
  type: MAP_OVERLAP_TRAY_HOVER,
  hover: { [type]: value }
});

export const setOverlapHoverGroup = (group = null) => setOverlapHover('group', group);

export const setOverlapHoverEntity = (entity = null) => setOverlapHover('entity', entity);

export const setOverlapHoverLayer = (layer = null) => setOverlapHover('layer', layer);

export const resetFilters = () => (dispatch, getState) => {
  const viewport = getWKTViewportBoundsFromState(getState());
  dispatch({
    type: RESET_MAP_FILTERS,
    viewport
  });
};

export const setGoogleAutocompleteSuggestions = predictions => dispatch => dispatch({
  type: MAP_SET_GOOGLE_AUTOCOMPLETE_SUGGESTIONS,
  predictions
});

export const setSearchTerm = term => dispatch => dispatch({
  type: MAP_SET_SEARCH_TERM,
  term
});

export const selectSegment = segmentId => dispatch => dispatch({
  type: MAP_SELECT_TRAY_SEGMENT,
  segmentId
});
