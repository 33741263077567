import React from 'react';
import PropTypes from 'prop-types';

const LegendIcon = ({ color }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="18" height="3" viewBox="0 0 18 3">
    <g fill="none" fillRule="evenodd" transform="translate(0 -8)">
      <path d="M0 0H18V18H0z" />
      <rect width="18" height="3" y="8" fill={color} rx="1.5" />
    </g>
  </svg>
);

LegendIcon.propTypes = {
  color: PropTypes.string
};

export default LegendIcon;
