import React, { Fragment } from 'react';
import { useParams } from 'react-router-dom';
import TopBarActionCreate from './top-bar-action-create';
import TopBarActionEntityIcons from './top-bar-action-entity-icons';
import TopBarActionTaskTypeIcons from './top-bar-action-task-type-icons';
import TopBarActionEntityMenu from './top-bar-action-entity-menu';
import TopBarActionTaskTypeMenu from './top-bar-action-task-type-menu';
import TopBarActionWorkflowMenu from './top-bar-action-workflow-menu';
import TopBarActionCycleMenu from './top-bar-action-cycle-menu';
import { isEntity } from '@constants/endpoints';
import './top-bar.scss';

const TopBarActions = () => {
  const { dataType, id, action } = useParams();
  return (
    <div styleName="actions">
      {id && action !== 'duplicate' && (
        <Fragment>
          {isEntity(dataType) && <TopBarActionEntityIcons />}
          {dataType === 'task_type' && <TopBarActionTaskTypeIcons />}
          {isEntity(dataType) && <TopBarActionEntityMenu />}
          {dataType === 'task_type' && <TopBarActionTaskTypeMenu />}
          {dataType === 'workflow' && <TopBarActionWorkflowMenu />}
          {dataType === 'cycle' && <TopBarActionCycleMenu />}
        </Fragment>
      )}
      {(!id || action === 'duplicate') && <TopBarActionCreate />}
    </div>
  );
};

export default TopBarActions;
