/* eslint-disable react/jsx-no-bind */
import React, { Fragment, memo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import MoreTimeIcon from '@icons/more-time-icon';
import { openDashboardDialog } from '@actions/dashboard-actions';
import { createNewSegment } from '@actions/data-detail-actions';
import * as dialog from '@constants/dialogs';
import { detailEdit } from '@constants/mui-theme';
import AddIcon from '@material-ui/icons/Add';
import IconButton from '@material-ui/core/IconButton';
import DescriptionIcon from '@material-ui/icons/Description';
import { isScheduleEnabled } from '@utils/segment-schedule/common';
import { pluralize } from '@utils/shared-utils';
import '../../../../../forms/forms.scss';

const SegmentTitle = ({ readOnly, tab }) => {
  const dispatch = useDispatch();
  const { dataType } = useParams();
  const { data } = useSelector(state => state.dataDetail);
  const { segments = [] } = useSelector(state => state.dataDetail.data);

  const onAdd = () => dispatch(createNewSegment());

  const onBulk = () => dispatch(openDashboardDialog(dialog.BULK_SCHEDULE, { data }));

  const onView = () => dispatch(openDashboardDialog(dialog.VIEW_SCHEDULE, { data, dataType }));

  const segmentCount = segments ? segments.length : 0;
  const extraStyle = `form-title-label-extra ${segmentCount === 0 ? 'form-title-label-extra-error' : ''}`;

  return (
    <div styleName="form-title" id={`tab-section-${tab.id}-title`}>
      <div styleName="form-title-counter">
        {segmentCount}
      </div>
      <div styleName="form-title-label">
        {pluralize(segmentCount, tab.label)}
      </div>
      <div styleName="form-title-copy">
        {isScheduleEnabled(dataType) && segmentCount > 0 && (
          <IconButton onClick={onView} style={{ padding: 0 }}>
            <DescriptionIcon {...detailEdit.segmentEdit.openScheduleButton} />
          </IconButton>
        )}
      </div>
      {tab.extra && (
        <div styleName={extraStyle}>
          {tab.extra}
        </div>
      )}
      <div styleName="form-title-filler" />
      {!readOnly &&
        <Fragment>
          {isScheduleEnabled(dataType) && segmentCount > 0 && (
            <IconButton onClick={onBulk} style={{ padding: 0, marginRight: '1.5rem' }}>
              <MoreTimeIcon />
            </IconButton>
          )}
          <IconButton onClick={onAdd} style={{ padding: 0 }}>
            <AddIcon />
          </IconButton>
        </Fragment>
      }
    </div>
  );
};

SegmentTitle.propTypes = {
  readOnly: PropTypes.bool,
  tab: PropTypes.object
};

export default memo(SegmentTitle);
