import React, { memo, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Skeleton from '@material-ui/lab/Skeleton';
import { dashboardDetailsDataSelector } from '@selectors/dashboard-details-selector';
import { parseLog } from '@utils/data-detail-utils';
import './batch-errors-table.scss';

const BatchErrorsTable = () => {
  const { dataType } = useParams();
  const data = useSelector(state => dashboardDetailsDataSelector(state, dataType));
  const entries = data?.errors;
  const loaded = useMemo(() => typeof data !== 'undefined' && data !== null, [data]);

  if (!loaded) {
    return <Skeleton variant="text" width="100%" height="4rem" />;
  }

  if (entries?.length > 0) {
    return (
      <div styleName="batch-errors-table-container">
        <TableContainer>
          <Table styleName="detail-table" aria-label="errors">
            <TableHead>
              <TableRow>
                <TableCell styleName="header-cell">ROW</TableCell>
                <TableCell styleName="header-cell">ERROR</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {entries.map((entry) => (
                <TableRow key={entry.id}>
                  <TableCell styleName="table-cell">{entry.line_number}</TableCell>
                  <TableCell styleName="table-cell">{parseLog(entry) || entry.details}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    );
  }

  return <div styleName="batch-errors-table-empty">There are no errors</div>;
};

export default memo(BatchErrorsTable);
