import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import * as colors from '@constants/colors';
import { isAnyMapItemLoading } from '@selectors/map-selector';
import DotmapsLoader from '@shared/dotmaps-loader';

// Use this component instead of using DotmapsLoader directly,
// to avoid having to re-render the main Map component if
// the 'loader' property changes.
const Loader = ({ loader }) => loader ? <DotmapsLoader display color={colors.dotmapsGreen} /> : null;

Loader.propTypes = {
  loader: PropTypes.bool
};

const mapStateToProps = state => ({
  loader: isAnyMapItemLoading(state)
});

export default connect(mapStateToProps, {})(Loader);
