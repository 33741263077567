/* eslint-disable react/jsx-key */
import React, { memo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';

import Button from '@material-ui/core/Button';

import CategoryFilter from '../types/category';
import DateFilter from '../types/date';
import PastDateFilter from '../types/past-date';
import AgencyFilter from '../types/agency';
import AgencyTypeFilter from '../types/agency-type';
import FieldFilter from '../types/field';
import CheckboxFilter from '../types/checkbox';
import UserFilter from '../types/user';
import SearchFilter from '@components/filters/types/search';

import { getDataTypeFilterConfig, getDataTypeFilterValues } from '../selectors';
import { resetFilter } from '../actions';

import {
  AGENCY_FILTER_TYPE,
  AGENCY_TYPE_FILTER_TYPE,
  CATEGORY_FILTER_TYPE,
  CHECKBOX_FILTER_TYPE,
  DATE_FILTER_TYPE,
  FIELD_FILTER_TYPE,
  PAST_DATE_FILTER_TYPE,
  USER_FILTER_TYPE
} from '../constants';

import styles from './horizontal.scss';

const HorizontalFilters = ({dataType, subType, children, className}) => {
  const filters = useSelector(state => getDataTypeFilterConfig(state, dataType, subType));
  const filterValues = useSelector(state => getDataTypeFilterValues(state, dataType, subType));

  const dispatch = useDispatch();
  const handleReset = useCallback(() => {
    dispatch(resetFilter(dataType, subType));
  }, [dispatch, dataType, subType]);

  return (
    <div className={classNames(styles.horizontalLayout, className)}>
      {children}
      <SearchFilter
        filter={{label: 'Search'}}
        dataType={dataType}
        subType={subType}
        filterKey="search"
        value={filterValues?.search || ''}
      />
      {filters.map((filterConfig) => {
        const filterKey = filterConfig.name;
        let value = null;
        if (filterKey in filterValues) {
          value = filterValues[filterKey];
        }
        let FilterComponent;
        switch (filterConfig.type) {
        case CATEGORY_FILTER_TYPE:
          FilterComponent = CategoryFilter;
          break;
        case DATE_FILTER_TYPE:
          FilterComponent = DateFilter;
          break;
        case PAST_DATE_FILTER_TYPE:
          FilterComponent = PastDateFilter;
          break;
        case AGENCY_FILTER_TYPE:
          FilterComponent = AgencyFilter;
          break;
        case AGENCY_TYPE_FILTER_TYPE:
          FilterComponent = AgencyTypeFilter;
          break;
        case FIELD_FILTER_TYPE:
          FilterComponent = FieldFilter;
          break;
        case CHECKBOX_FILTER_TYPE:
          FilterComponent = CheckboxFilter;
          break;
        case USER_FILTER_TYPE:
          FilterComponent = UserFilter;
          break;
        default:
          return null;
        }
        return (
          <div key={filterConfig.name} >
            <FilterComponent filter={filterConfig} value={value} dataType={dataType} subType={subType} filterKey={filterKey} />
          </div>
        );
      })}
      <Button onClick={handleReset} classes={{root: styles.reset}}>RESET</Button>
    </div>
  );
};

HorizontalFilters.propTypes = {
  categoryTypes: PropTypes.array,
  children: PropTypes.node,
  className: PropTypes.string,
  dataType: PropTypes.string,
  subType: PropTypes.string
};

export default memo(HorizontalFilters);
