import { createSelector } from 'reselect';
import { ATTRS_DATA_TYPES, DATA_TYPES } from '@components/config/constants';
import { getConfig, getDataTypeConfig } from '@components/config/selectors';
import { MULTISELECT_FILTER_TYPES } from './constants';

export const getFilters = store => store.filters;

export const getDataTypeFilterConfig = createSelector(
  getConfig,
  getDataTypeConfig,
  (state, dataType) => dataType,
  (state, dataType, subType) => subType,
  (config, dataTypeConfig, dataTypeName, subTypeName) => {
    const dataType = DATA_TYPES[dataTypeName];
    let filters = null;
    if (dataType) {
      filters = dataTypeConfig?.filters || null;
      if (filters === null) {
        // Attempt to search for filter config in legacy dashboard config.
        if (dataTypeName === 'entity' && subTypeName) {
          filters = config.filters?.dashboard?.[subTypeName] || null;
        } else if (dataType.subType) {
          filters = config.filters?.dashboard?.[dataTypeName]?.[subTypeName] || null;
        } else {
          filters = config.filters?.dashboard?.[dataTypeName] || null;
        }
      }
    }
    if (filters === null) {
      filters = [];
    }
    return filters;
  }
);

const parseTypeValue = (type, value) => {
  if (MULTISELECT_FILTER_TYPES.has(type) && value) {
    return value.split(',').map(element => {
      const parsed = Number.parseInt(element, 10);
      return Number.isInteger(parsed) ? parsed : element;
    });
  }
  return Number.isInteger(value) ? Number(value) : value;
};

const convertLegacyDateDefaults = (filterConfig, legacyDefaults) => {
  const start = legacyDefaults[filterConfig.params?.[0]];
  const end = legacyDefaults[filterConfig.params?.[1]];
  const end2 = legacyDefaults[filterConfig.params?.[2]];
  const start2 = legacyDefaults[filterConfig.params?.[3]];
  if (start && end) {
    if (start2 && end2 && start === start2 && end === end2) {
      return { start, end, contained: true };
    }
    return { start, end, contained: false };
  }
  return null;
};

export const getDataTypeFilterDefaults = createSelector(
  getConfig,
  getDataTypeFilterConfig,
  (state, dataType) => dataType,
  (state, dataType, subType) => subType,
  (config, filterConfigs, dataTypeName, subTypeName) => {
    const defaults = {};
    const legacyDefaults = {
      ...(config?.filters?.dashboard?.global || config?.filters?.global || {}),
      ...(dataTypeName === 'entity' && (config?.filters?.entities?.[subTypeName] || {})),
      ...(config?.dashboard?.list?.datatable?.config?.[dataTypeName] || {}),
      ...(dataTypeName === 'entity' && (config?.dashboard?.list?.datatable?.config?.[subTypeName] || {})),
      table: config?.dashboard?.list?.datatable?.initialFilters
    };
    filterConfigs.forEach(filterConfig => {
      const filterKey = filterConfig.name;
      defaults[filterKey] = null;
      if (filterConfig.default) {
        defaults[filterKey] = filterConfig.default;
      } else {
        // Search some other places we may have put defaults and convert filter store format
        let typeDefault = null;
        if (filterConfig.type === 'date' || filterConfig.type === 'past-date') {
          typeDefault = convertLegacyDateDefaults(filterConfig, legacyDefaults);
        } else if (filterConfig.params.length === 1) {
          const param = filterConfig.params[0];
          if (param === 'categories__in') {
            typeDefault = legacyDefaults[`categories__in|${filterKey}`] || null;
          } else {
            typeDefault = legacyDefaults[param] || null;
          }
          typeDefault = parseTypeValue(filterConfig.type, typeDefault);
        } else if (filterConfig.params.length > 1) {
          typeDefault = {};
          filterConfig.params.forEach(param => {
            if (legacyDefaults[param]) {
              typeDefault[param] = parseTypeValue(filterConfig.type, legacyDefaults[param]);
            }
          });
          if (Object.keys(typeDefault).length === 0) {
            typeDefault = null;
          }
        }
        defaults[filterKey] = typeDefault;
      }
    });
    return defaults;
  }
);

export const getDataTypeFilterValuesInitialized = createSelector(
  getFilters,
  (state, dataType) => dataType,
  (state, dataType, subType) => subType,
  (filters, dataType, subType) => {
    const type = DATA_TYPES[dataType];
    if (type.subType) {
      return dataType in filters && subType in filters[dataType];
    }
    return dataType in filters;
  }
);

export const getDataTypeFilterValues = createSelector(
  getFilters,
  getDataTypeFilterValuesInitialized,
  getDataTypeFilterDefaults,
  (state, dataType) => dataType,
  (state, dataType, subType) => subType,
  (filters, typeFilterValuesInitialized, filterDefaults, dataType, subType) => {
    if (!typeFilterValuesInitialized) {
      return filterDefaults;
    }
    const type = DATA_TYPES[dataType];
    if (type.subType) {
      return filters[dataType][subType];
    }
    return filters[dataType];
  }
);

const serializeDateFilterParams = (filterConfig, value) => {
  const filters = {};
  const {start, end, contained} = value;
  if (start && end) {
    if (filterConfig.params.length === 4) {
      filters[filterConfig.params[0]] = start;
      filters[filterConfig.params[1]] = end;
      if (contained) {
        filters[filterConfig.params[2]] = end;
        filters[filterConfig.params[3]] = start;
      }
    } else if (filterConfig.params.length === 2) {
      filters[filterConfig.params[0]] = start;
      filters[filterConfig.params[1]] = end;
    }
  }
  return filters;
};

export const getDataTypeFilterParams = createSelector(
  getDataTypeFilterConfig,
  getDataTypeFilterValues,
  (state, dataType) => dataType,
  (filterConfigs, filterValues, dataType) => {
    const categories = new Set();
    const attributes = [];
    const filters = {};
    filterConfigs.forEach((filterConfig) => {
      const filterKey = filterConfig.name;
      if (!(filterKey in filterValues)) {
        return;
      }
      const value = filterValues[filterKey];
      if (value === null) {
        return;
      }
      if (filterConfig.type === 'category') {
        value.forEach(categoryId => categories.add(categoryId));
      } else if (filterConfig.type === 'date' || filterConfig.type === 'past-date') {
        Object.assign(filters, serializeDateFilterParams(filterConfig, value));
      } else {
        const param = filterConfig.params[0];
        if (ATTRS_DATA_TYPES.has(dataType) && param.includes('attrs__')) {
          attributes.push(`${param.split('__')[1]}|${value}`);
        } else {
          filters[param] = value;
        }
      }
    });
    if (categories.size > 0) {
      filters.categories__in = Array.from(categories);
    }
    if (attributes.length > 0) {
      filters.attrs = attributes;
    }
    const search = filterValues?.search;
    if (search) {
      // Quote search for django 5 / DRF 3.15 search format to prevent splitting on spaces
      const quoted = `"${search.replace('\\', '\\\\').replace('"', '\\"')}"`;
      filters.search = quoted;
    }
    Object.entries(filters).forEach(([key, value]) => {
      if (Array.isArray(value)) {
        filters[key] = value.join(',');
      } else {
        filters[key] = `${value}`;
      }
    });
    return filters;
  }
);
