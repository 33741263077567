import React, { memo, useMemo } from 'react';
import PropTypes from 'prop-types';

import { useSelector } from 'react-redux';

import SelectFilterChip from '../chips/select';

import { useDataTypeFilterHandleChange } from '../hooks';
import { getCategoryIcon, getPriorityIconUrl } from '@utils/icon-utils';

const CategoryFilter = ({filter, value, dataType, subType, filterKey}) => {
  const handleChange = useDataTypeFilterHandleChange(dataType, subType, filterKey);
  const categoryTypes = useSelector(state => state.dataTypes.map_category_type);
  const categories = useSelector(state => state.dataTypes.map_category);
  const categoryType = useMemo(
    () => Object.values(categoryTypes).find(type => type.name === filter.name),
    [filter, categoryTypes]
  );
  const options = useMemo(() => {
    if (!categoryType) {
      return [];
    }
    const categoryOptions = (Object.values(categories)
      .filter(category => category.type === categoryType.id && category.active)
      .map(category => ({
        label: category.name,
        value: category.id
      }))
    );
    if (filter.name === 'priority') {
      categoryOptions.forEach(option => {
        option.icon = getPriorityIconUrl(option.value);
      });
    } else if (filter.showIcon) {
      categoryOptions.forEach(option => {
        option.icon = getCategoryIcon(dataType, option.value);
      });
    }
    return categoryOptions;
  }, [filter, categoryType, categories, dataType]);

  if (categoryType) {
    return (
      <SelectFilterChip
        label={categoryType.label}
        options={options}
        value={value}
        onChange={handleChange}
        multiSelect
        search
      />
    );
  }

  return null;
};

CategoryFilter.propTypes = {
  dataType: PropTypes.string.isRequired,
  filter: PropTypes.object.isRequired,
  filterKey: PropTypes.string.isRequired,
  subType: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.array, PropTypes.number])
};

export default memo(CategoryFilter);
