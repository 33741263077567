import React, { memo } from 'react';
import PropTypes from 'prop-types';
import CoreEditField from '@components/workflow/section/core/edit-field';
import EditField from '@components/workflow/section/edit-field';

const WorkflowEditFields = ({ sectionId, fields, isPreview }) => fields.map((field, index) => {
  if (field.core) {
    return <CoreEditField isPreview={isPreview} key={sectionId} sectionId={sectionId} {...field} />;
  }

  return <EditField isPreview={isPreview} key={sectionId} field={field} position={index} sectionId={sectionId} />;
});

WorkflowEditFields.propTypes = {
  fields: PropTypes.array,
  isPreview: PropTypes.bool,
  sectionId: PropTypes.number
};

export default memo(WorkflowEditFields);
