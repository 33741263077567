import React, { Component } from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import { colors } from '@constants/colors';
import FilterSelect from '@shared/filter-select';
import { buildFilterMenuItem } from '@utils/filter-utils';

export class BaseDropDownFilter extends Component {
  constructor(props) {
    super(props);
    this.state = this.getDefaultState();
    this.getDefaultState = this.getDefaultState.bind(this);
    this.getFilters = this.getFilters.bind(this);
    this.getMultiple = this.getMultiple.bind(this);
    this.filterChange = this.filterChange.bind(this);
    this.filterClose = this.filterClose.bind(this);
    this.renderValue = this.renderValue.bind(this);
    this.setFilter = this.setFilter.bind(this);
  }

  getDefaultState() {
    return this.buildStateFromProps(this.props);
  }

  buildStateFromProps(props) {
    return { selected: props.filter };
  }

  getFilters() {
    return this.state.selected;
  }

  getMultiple() {
    return false;
  }

  filterChange(event) {
    this.setState({ selected: event.target.value });
  }

  getChecked(item) {
    return this.state.selected === item.id;
  }

  setFilter(filter) {
    const { name } = this.props;
    this.props.setFilter(name, filter);
  }

  filterClose() {
    this.setFilter(this.getFilters());
  }

  renderValue(value) {}  // eslint-disable-line no-unused-vars

  render() {
    const { items } = this.props;
    return (
      <div>
        <FilterSelect
          displayEmpty
          isValueSelected={!R.isEmpty(this.state.selected)}
          labelStyles={{
            backgroundColor: 'rgba(255,255,255,0.3)',
            margin: '8px 0 8px 0',
            color: colors.neutral.white
          }}
          multiple={this.getMultiple()}
          onChange={this.filterChange}
          onClose={this.filterClose}
          renderValue={this.renderValue}
          value={this.state.selected}
        >
          {items.map(item => buildFilterMenuItem(this.getChecked(item), null, item))}
        </FilterSelect>
      </div>
    );
  }
}

BaseDropDownFilter.propTypes = {
  items: PropTypes.array,
  name: PropTypes.string,
  setFilter: PropTypes.func
};

export const baseMapStateToProps = (state, { lazyItems, name }) => {
  const filter = state.groups.gantt.filters[name];
  // The items are lazy loaded, since it requires the backend config data loaded.
  const items = lazyItems();
  return { filter, items, name };
};
