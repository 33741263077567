import React from 'react';
import * as R from 'ramda';
import moment from 'moment-timezone';
import { capitalize, flattenDeep, includes, isEmpty } from 'lodash';
import { dotmapsGray } from '@constants/colors';
import {
  getEntitiesListConfig,
  getEntityTypeLabel,
  getGanttConfig,
  getGroupTypesConfig
} from '@constants/config';
import {
  optimizeEntitiesForGanttChartSelector
} from '@selectors/gantt-selector';
import { getConfig } from '@utils/config-utils';
import { parseDate } from '@utils/shared-utils';
import {
  calculateDays,
  calculateDaysSaved,
  calculateWidth,
  getMaxDate,
  getMinDate
} from '@utils/timeline-chart-utils';
import './gantt-utils.scss';

export const getGroupEntitiesList = groupType => {
  const groupTypes = getGroupTypesConfig();
  const { excluded_types } = groupTypes.find(type => type.id === groupType);
  const entities = getEntitiesListConfig();
  // Remove entities excluded for this group type:
  return entities.filter(entity => excluded_types.indexOf(entity) === -1);
};

export const getGanttEntityLabels = groupType => {
  const result = {};
  const entities = getGroupEntitiesList(groupType);
  entities.forEach((entity, index) => {
    const entityName = getEntityTypeLabel(entity);
    result[entity] = {
      id: index,
      simpleLabel: `${capitalize(entityName)}`,
      pluralType: `${entityName}s`,
      label: `Show ${entityName}s`,
      selectedLabel: `${capitalize(entityName)}s only`
    };
  });
  return result;
};

const buildGanttPopoverRow = (label, value) => {
  return (
    <div styleName="gantt-popover-row">
      <div styleName="gantt-popover-row-label">{label}</div>
      <div styleName="gantt-popover-row-value">{value}</div>
    </div>
  );
};

// Always display days + 1, since when start and end are the
// same, the entity uses 1 day or less, which is rounded to one day.
// (the same for the plural 's' calculation).
export const renderDays = (start, end) => {
  const days = calculateDays(start, end);
  return `${days + 1} day${days === 0 ? '' : 's'}`;
};

// Unlike renderDays() don't add + 1 for this method,
// since it's already calculated at the backend.
export const renderDurationDays = duration => `${duration} day${duration === 0 ? '' : 's'}`;

// Renders the popover for Gantt rows:
export const buildGanttPopover = row => {
  const start = parseDate(row.start_date);
  const end = parseDate(row.end_date);
  const daysLabel = renderDays(row.start_date, row.end_date);

  return (
    <div styleName="gantt-popover">
      <div styleName="gantt-popover-title">{row.name}</div>
      {buildGanttPopoverRow('Starts', start && start.format('M/D/YYYY'))}
      {buildGanttPopoverRow('Ends', end && end.format('M/D/YYYY'))}
      {buildGanttPopoverRow('Duration', daysLabel)}
    </div>
  );
};

const getUniqueStreets = rows => {
  // Get all on_street properties from entities.
  //
  // Since each on_street entry may contain several streets (separated by comman)
  // split them into arrays too.
  const streets = R.pluck('on_street', rows).map(street => street.split(','));

  // But we have to flatten all that into a single list (removing empty entries):
  const flattenedStreets = [].concat(...streets)
    .map(street => street.trim())
    .filter(street => !isEmpty(street));

  // And return a sorted unique list of streets:
  return R.uniq(flattenedStreets).sort();
};

// Like getUniqueStreets() but for blocks.
const getUniqueBlocks = rows => {
  const blocks = R.pluck('blocks', rows).map(block => R.values(block));
  // Flatten the blocks array which has more than one level of depth:
  const flattenedBlocks = flattenDeep(blocks)
    .map(block => block.trim())
    .filter(block => !isEmpty(block));
  return R.uniq(flattenedBlocks).sort();
};

// Get all the rows on the specified street:
const getRowsOnStreet = (street, rows) => {
  return rows.filter(row => {
    const streets = row.on_street.split(',').map(streetElement => streetElement.trim());
    return includes(streets, street);
  });
};

// Get all rows on the specified street/block combination:
const getRowsOnStreetBlock = (street, block, rows) => {
  return rows.filter(row => {
    const blocks = row.blocks[street];
    return includes(blocks, block);
  });
};

const getStartDate = rows => getMinDate(rows).format('YYYY-MM-DD');
const getEndDate = rows => getMaxDate(rows).format('YYYY-MM-DD');

const groupRowsByAll = rows => {
  return [
    {
      id: 'All',
      color: dotmapsGray,
      label: 'All',
      nestedLocations: false,
      rows,
      start_date: getStartDate(rows),
      end_date: getEndDate(rows)
    }
  ];
};

const groupRowsByStreet = rows => {
  // Split rows by street address
  // (although, per requirements, rows may appear on more than
  // one group, if they are on multiple streets):
  const streets = getUniqueStreets(rows);
  return streets.map(street => {
    const rowsOnStreet = getRowsOnStreet(street, rows);
    const label = `On ${street}`;
    return {
      color: dotmapsGray,
      id: label,
      label,
      nestedLocations: false,
      rows: rowsOnStreet,
      start_date: getStartDate(rowsOnStreet),
      end_date: getEndDate(rowsOnStreet)
    };
  });
};

const groupRowsByBlocks = (street, rows) => {
  const blocks = getUniqueBlocks(rows);
  return blocks.map(block => {
    const rowsOnBlock = getRowsOnStreetBlock(street, block, rows);
    if (isEmpty(rowsOnBlock)) {
      // Don't add empty blocks if they don't have rows:
      return null;
    }
    return {
      color: dotmapsGray,
      id: `${street}-${block}`,
      label: block,
      nestedLocations: false,
      rows: rowsOnBlock,
      start_date: getStartDate(rowsOnBlock),
      end_date: getEndDate(rowsOnBlock)
    };
  }).filter(block => !isEmpty(block));
};

const groupRowsByStreetAndBlocks = rows => {
  const streets = getUniqueStreets(rows);
  return streets.map(street => {
    const rowsOnStreet = getRowsOnStreet(street, rows);
    const label = `On ${street}`;
    return {
      color: dotmapsGray,
      id: label,
      label,
      nestedLocations: true,
      rows: groupRowsByBlocks(street, rowsOnStreet),
      start_date: getStartDate(rowsOnStreet),
      end_date: getEndDate(rowsOnStreet)
    };
  });
};

// Group the entity rows by the specified location type (all, by streets, etc):
export const groupRowsByLocationType = (rows, locationType) => {
  const { locationTypes } = getGanttConfig();
  switch (locationType) {  // eslint-disable-line default-case
  case locationTypes.all.id: return groupRowsByAll(rows);
  case locationTypes.byStreets.id: return groupRowsByStreet(rows);
  case locationTypes.byStreetsAndBlocks.id: return groupRowsByStreetAndBlocks(rows);
  }
  return null;
};

// Count gantt rows recursivelly:
const countRows = rows => {
  const rowCount = rows.map(row => {
    if (row.rows) {
      return countRows(row.rows);
    }
    return 1;
  });

  return R.reduce(R.add, 0, rowCount) + 1;  // +1 to count the current row.
};

// Verifies if the number of rows to display is above the performance threshold
// (since displaying too many rows can be slow or crash the browser).
const isPerformanceThresholdReached = rows => {
  return countRows(rows) > getGanttConfig().rowPerformanceThreshold;
};

// Calculate the list-item left-padding depending on the depth level:
export const calculateLevelStyle = level => ({ padding: `0 0 0 ${level + 1}rem` });
export const calculateLeftContainerLevelStyle = level => ({ width: `${19 - level}rem`, maxWidth: `${19 - level}rem` });

// Returns true if the specified location type is expanded in the list of items:
export const isLocationTypeOpen = (id, locationTypeGroups, locationType) => {
  const locationTypeGroup = locationTypeGroups[locationType];
  return R.propOr(true, id, locationTypeGroup);
};

// Returns the entities to use in the Gantt chart:
const getRawRows = (entity, state) => {
  if (isEmpty(entity)) {
    return optimizeEntitiesForGanttChartSelector(state);
  }
  const ids = getGanttEntityLabels(state.group.type);
  let entities = [];
  Object.keys(ids).forEach(key => {
    if (includes(entity, ids[key].id)) {
      const allEntities = optimizeEntitiesForGanttChartSelector(state);
      const filteredEntities = allEntities.filter(item => item.entityType === key);
      entities = [...entities, ...filteredEntities];
    }
  });
  return entities;
};

// Returns true if there are rows with no dates:
const getNoDatesWarning = rawRows => {
  const emptyDateRows = rawRows.filter(row => isEmpty(row.start_date) || isEmpty(row.end_date));
  return emptyDateRows.length > 0;
};

// Sort function for gantt chart rows.
//
// Sort first by start_date, if they are the same,
// sort by the ones ending first, else alphabetically
// and finally by entity id.
export const ganttRowSort = R.sortWith([
  R.ascend(R.prop('start_date')),
  R.ascend(R.prop('end_date')),
  R.ascend(R.prop('name')),
  R.ascend(R.prop('id'))
]);

// Return only the rows with dates and sort them:
const getGanttRows = rawRows => {
  // Skip rows with no dates:
  const rows = rawRows.filter(row => !isEmpty(row.start_date) && !isEmpty(row.end_date));

  // And sort.
  return ganttRowSort(rows);
};

// Builds the processed gantt rows data after we retrieve that data from the backend
// (or after some filters changes):
export const buildGanttRowsState = (entity, locationType, locationTypeGroups, state) => {
  const rawRows = getRawRows(entity, state);
  const noDatesWarning = getNoDatesWarning(rawRows);
  const rows = getGanttRows(rawRows);
  const groupedRows = groupRowsByLocationType(rows, locationType);
  const performanceThresholdReached = isPerformanceThresholdReached(groupedRows);
  let updatedLocationTypeGroups = { ...locationTypeGroups };

  // For 'By streets and blocks' collapse all top rows:
  const { locationTypes } = getGanttConfig();
  const collapseIfEmpty = R.isEmpty(updatedLocationTypeGroups[locationType]);
  if (locationType === locationTypes.byStreetsAndBlocks.id && collapseIfEmpty) {
    const locations = {...updatedLocationTypeGroups[locationType]};
    groupedRows.forEach(row => {
      locations[row.id] = false;
    });

    updatedLocationTypeGroups = {
      ...locationTypeGroups,
      [locationType]: locations
    };
  }

  return {
    groupedRows,
    locationTypeGroups: updatedLocationTypeGroups,
    noDatesWarning,
    performanceThresholdReached,
    rows
  };
};

// Renders the savings/exceeds metrics message (long text version):
export const renderSavingsLong = (baseline, current) => {
  const daysSaved = calculateDaysSaved(baseline, current);

  return (
    <div styleName="centered-block">
      {daysSaved === 0 && 'No savings yet'}
      {daysSaved > 0 && <div><div styleName="positive-text">{daysSaved} days</div> have been saved</div>}
      {daysSaved < 0 && <div>Exceeds the baseline by <div styleName="negative-text">{daysSaved * -1} days</div></div>}
    </div>
  );
};

// Like renderSavingsLong, but uses less text:
export const renderSavingsShort = (baseline, current) => {
  const daysSaved = calculateDaysSaved(baseline, current);

  return (
    <div styleName="centered-block">
      {daysSaved === 0 && 'No savings yet'}
      {daysSaved > 0 && <div><div styleName="positive-text">{daysSaved} days</div><br />saved</div>}
      {daysSaved < 0 && <div>Exceeds by<br /><div styleName="negative-text">{daysSaved * -1} days</div></div>}
    </div>
  );
};

const diffColumn = (baseline, current) => {
  const diff = current - baseline;
  return (
    <div>
      {current}
      {diff !== 0 && <div styleName="diff">{diff > 0 ? '+' : ''}{diff}</div>}
    </div>
  );
};

const ndash = <div>&ndash;</div>;

const calculateBaselineMetrics = (entities, tz, baseline) => {
  const hasBaseline = !isEmpty(baseline);
  const startDate = hasBaseline && baseline.start_date ? moment.tz(baseline.start_date, tz).format('M/D/YYYY') : ndash;
  const endDate = hasBaseline && baseline.end_date ? moment.tz(baseline.end_date, tz).format('M/D/YYYY') : ndash;
  const duration = hasBaseline && baseline.duration ? renderDurationDays(baseline.duration) : ndash;

  // Add the count of each entity:
  const entityCounts = {};
  entities.forEach(key => {
    if (hasBaseline) {
      if (typeof baseline.entities_count[key] === 'undefined') {
        entityCounts[key] = 0;
      } else {
        entityCounts[key] = baseline.entities_count[key];
      }
    } else {
      entityCounts[key] = ndash;
    }
  });

  return { duration, startDate, endDate, ...entityCounts };
};

const calculateCurrentMetrics = (entities, tz, baseline, current) => {
  const hasBaseline = !isEmpty(baseline);
  if (!current) {
    return {};
  }
  const startDate = current.start_date ? moment.tz(current.start_date, tz).format('M/D/YYYY') : ndash;
  const endDate = current.end_date ? moment.tz(current.end_date, tz).format('M/D/YYYY') : ndash;
  const duration = current.duration ? renderDurationDays(current.duration) : ndash;

  // Add the count of each entity:
  const entityCounts = {};
  entities.forEach(key => {
    if (typeof current.entities_count[key] === 'undefined') {
      entityCounts[key] = 0;
    } else {
      // eslint-disable-next-line no-lonely-if
      if (hasBaseline) {
        entityCounts[key] = diffColumn(baseline.entities_count[key], current.entities_count[key]);
      } else {
        entityCounts[key] = current.entities_count[key];
      }
    }
  });

  return { duration, startDate, endDate, ...entityCounts };
};

// Returns the metrics values to render on the page:
export const calculateMetrics = (baseline, current, groupType) => {
  const config = getConfig();
  const tz = config.timezoneName;
  const entities = getGroupEntitiesList(groupType);
  return {
    baseline: calculateBaselineMetrics(entities, tz, baseline),
    current: calculateCurrentMetrics(entities, tz, baseline, current),
    entities
  };
};

const buildCalendarItem = (start, format, rawFormat) => {
  return {
    formatted: start.format(format),
    // For the raw entry we convert it to the specified format,
    // which is either a year or a month/year combination,
    // but we want it to be the start of that year or month/year:
    raw: moment(start.format(rawFormat))
  };
};

// Used by getUnitItems() to return all the items for the specified start and ends dates
// and format them using moment's formats and units.
const getCalendarUnitItems = props => {
  const items = [];
  const start = moment(props.start);
  while (props.end >= start) {
    items.push(buildCalendarItem(start, props.format, props.rawFormat));
    start.add(1, props.unit);
  }
  if (props.isTop) {
    // moment.isSame() isn't working properly here, thus using string for comparison:
    const endDateStr = moment(props.end).format('YYYY-MM-DD');
    const endOfStr = moment(props.end).endOf(props.unit)
      .format('YYYY-MM-DD');
    const hasSpareDays = endDateStr !== endOfStr;
    if (hasSpareDays) {
      items.push(buildCalendarItem(start, props.format, props.rawFormat));
    }
  }
  return items;
};

// Return all the items for the specified date unit between two dates.
//
// This means that for the day unit, it should return all day numbers between the
// start and end dates for the specified rows.
// For the week unit, it will return all week numbers, for month, all month names
// and for year, all the year numbers.
export const getUnitItems = (dateUnit, rows) => {
  const { dateUnits } = getGanttConfig();
  const props = {
    start: getMinDate(rows),
    end: getMaxDate(rows),
    rawFormat: 'YYYY-01-01',
    isTop: false  // Tells if we are generating entries for the top row.
  };

  switch (dateUnit) {  // eslint-disable-line default-case
  case dateUnits.day.id: return getCalendarUnitItems({...props, format: 'D', unit: 'day'});
  case dateUnits.week.id: return getCalendarUnitItems({...props, format: 'MMM D', unit: 'week'});
  case dateUnits.month.id: return getCalendarUnitItems({...props, format: 'MMMM', unit: 'month'});
  case dateUnits.quarter.id: {
    // Prefix items with 'Quarter ':
    const items = getCalendarUnitItems({...props, format: 'Q', unit: 'quarter'});
    return items.map(item => ({ formatted: `Quarter ${item.formatted}`, raw: item.raw}));
  }
  case dateUnits.year.id: return getCalendarUnitItems({...props, format: 'YYYY', unit: 'year'});
  }

  return null;
};

// Like getUnitItems() but this returns the items for the top header.
export const getTopUnitItems = (dateUnit, rows) => {
  const { dateUnits } = getGanttConfig();
  const props = {
    start: getMinDate(rows),
    end: getMaxDate(rows),
    isTop: true
  };

  if (dateUnit === dateUnits.day.id) {
    return getCalendarUnitItems({...props, format: 'MMMM YYYY', unit: 'month', rawFormat: 'YYYY-MM-01'});
  }

  // Else for week, month, quarter and year use this format:
  return getCalendarUnitItems({...props, format: 'YYYY', unit: 'year', rawFormat: 'YYYY-01-01'});
};

// Returns the pixels size for the specified date unit:
export const getUnitSize = dateUnit => {
  return R.find(R.propEq(dateUnit, 'id'))(R.values(getGanttConfig().dateUnits)).pixels;
};

// For day and week views, the top unit size is not constant, it depends on the date ranges.
const getTopRangeUnitSize = (dateUnit, rows, items, index) => {
  const start = items[index].raw;
  let end = null;
  if (index === items.length - 1) {
    // Since we don't store the end date, use the max one:
    end = getMaxDate(rows);
  } else {
    end = items[index + 1].raw;
  }
  return calculateWidth(start, end, dateUnit, 0);
};

// Return the pixel size for specified date unit for displaying on the top header.
export const getTopUnitSize = (dateUnit, rows, items, index) => {
  // First get the unit size for the specified date unit.
  const unitSize = getUnitSize(dateUnit);
  const { dateUnits } = getGanttConfig();

  // Then depending on the date unit, adjust it:
  switch (dateUnit) {  // eslint-disable-line default-case
  case dateUnits.day.id:  // ditto as week:
  case dateUnits.week.id: return getTopRangeUnitSize(dateUnit, rows, items, index);
  case dateUnits.month.id: return unitSize * 12;
  case dateUnits.quarter.id: return unitSize * 4;
  case dateUnits.year.id: return unitSize;
  }

  return null;
};

// Return the pixel size for the first item of the top header
export const getFistItemTopUnitSize = (dateUnit, rows, items, index) => {
  const topUnitSize = getTopUnitSize(dateUnit, rows, items, index);
  const { dateUnits } = getGanttConfig();

  if (dateUnit === dateUnits.year.id) {
    // For year, there's no bottom row, and the
    // top one is correctly sized already:
    return topUnitSize;
  }

  // For all other date units we show the year at the top
  // row, thus we must size the first item to be relative
  // to the first day (the only exception is the day unit,
  // which renders as "month year":
  const minDate = getMinDate(rows);
  const minimumDate = moment(minDate); // Clone, since startOf() mutates the object.
  let firstDate = null;

  // Find the start of the date unit:
  if (dateUnit === dateUnits.day.id) {
    firstDate = minimumDate.startOf('month');
  } else {
    // Else for week, month, quarter and year:
    firstDate = minimumDate.startOf('year');
  }
  // Calculate the width from the first date to the minimum one
  // to know how much to remove from the first item:
  const startWidth = calculateWidth(firstDate, minDate, dateUnit, 0);

  // Find the bottom unit size to calculate how many units we miss for this period:
  const bottomUnitSize = getUnitSize(dateUnit);

  // And the number of units to skip:
  const floorUnitSize = Math.floor(startWidth / bottomUnitSize);

  // From the unit size we use, remove the ones to skip:
  return topUnitSize - (bottomUnitSize * floorUnitSize);
};
