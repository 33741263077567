import React, { Fragment, useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import styles from './dm-list-item-contents.scss';
import Button from '@material-ui/core/Button';

const DmListItemContents = ({
  afterList = null,
  beforeList = null,
  border = false,
  children,
  className,
  onClick = null,
  secondaryText = null,
  ...passThroughProps
}) => {
  const [afterListWidth, setAfterListWidth] = useState(0);
  const [endPadding, setEndPadding] = useState(24);
  const listItemRefCallback = useCallback(
    node => setEndPadding((node && getComputedStyle(node).paddingInlineEnd) || 0),
    [setEndPadding]
  );
  const afterListRefCallback = useCallback(
    node => setAfterListWidth((node && node.offsetWidth) || 0),
    [setAfterListWidth]
  );

  const afterString = typeof afterList === 'string' && afterList;
  const hasAfter = afterList && !afterString;

  const content = (
    <Fragment>
      {beforeList &&
        <span className={styles.before}>
          {beforeList}
        </span>
      }
      <span className={styles.text}>
        <span className={styles.primary}>{children}</span>
        {secondaryText &&
          <span className={styles.secondary}>{secondaryText}</span>
        }
      </span>
      {afterString &&
        <span className={classNames(styles.afterText)}>
          {afterString}
        </span>
      }
      {afterListWidth > 0 &&
        <span className={styles.after} style={{ width: afterListWidth }} />
      }
    </Fragment>
  );

  const afterContent = (
    <Fragment>
      {hasAfter &&
        <span style={{marginInlineEnd: endPadding}} className={styles.afterList} ref={afterListRefCallback}>
          {afterList}
        </span>
      }
    </Fragment>
  );
  const listItemClassName = classNames(
    className,
    styles.listItem,
    {
      [styles.hasAfter]: hasAfter,
      [styles.border]: border
    }
  );

  if (onClick) {
    return (
      <Fragment>
        <Button
          classes={{root: listItemClassName}}
          onClick={onClick}
          fullWidth
          ref={listItemRefCallback}
          {...passThroughProps}
        >
          {content}
        </Button>
        {afterContent}
      </Fragment>
    );
  }

  return (
    <Fragment>
      <div
        className={listItemClassName}
        ref={listItemRefCallback}
        {...passThroughProps}
      >
        {content}
      </div>
      {afterContent}
    </Fragment>
  );
};

DmListItemContents.propTypes = {
  afterList: PropTypes.node,
  beforeList: PropTypes.node,
  border: PropTypes.bool,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  onClick: PropTypes.func,
  secondaryText: PropTypes.string
};

export default DmListItemContents;
