import React from 'react';
import PropTypes from 'prop-types';
import SvgIcon from 'material-ui/SvgIcon';

const DrawCircleIcon = props => (
  <SvgIcon {...props} style={{...props.style, height: '24px', width: '24px'}} viewBox="0 0 24 24">
    <path fillRule="evenodd" clipRule="evenodd"
      d={`
        M7.28767 3.09915C8.47161 2.48026 9.77144 2.10434 11.1224 2L11.3226 4.59228C10.3244 4.66937 9.36594 4.94656 8.49215
        5.40333L7.28767 3.09915ZM18.513 4.48062C17.5103 3.59061 16.3376 2.90943 15.0625 2.48295L14.2378 4.94868C15.1788 5.26342
        16.0453 5.76675 16.787 6.42514L18.513 4.48062ZM20.9104 7.67381C21.4816 8.87941 21.8063 10.192 21.8584 11.5479L19.2603
        11.6478C19.2218 10.6448 18.9823 9.67672 18.5608 8.78706L20.9104 7.67381ZM19.0855 18.8427C20.0147 17.876 20.7421
        16.7311 21.2191 15.4752L18.7885 14.552C18.4363 15.4792 17.8986 16.3256 17.211 17.0409L19.0855 18.8427ZM11.8992
        21.9034C13.2582 21.9034 14.5814 21.6306 15.8066 21.108L14.7865 18.7164C13.8824 19.1021 12.906 19.3034 11.8992
        19.3034V21.9034ZM4.18431 5.62646C3.33503 6.6637 2.7013 7.86246 2.32627 9.15499L4.82329 9.87951C5.09998 8.9259 5.56799
        8.04062 6.196 7.27361L4.18431 5.62646ZM3.24882 16.89C2.58338 15.7301 2.15664 14.4453 2 13.1016L4.58251 12.8006C4.69816
        13.7926 5.01281 14.7399 5.50406 15.5962L3.24882 16.89ZM5.90269 19.8983C6.97265 20.7053 8.19546 21.2904 9.5035
        21.6133L10.1266 19.0891C9.16174 18.8509 8.25916 18.419 7.46829 17.8225L5.90269 19.8983ZM11.9659 14.6701C13.4386 14.6701
        14.6325 13.4762 14.6325 12.0034C14.6325 10.5307 13.4386 9.33678 11.9659 9.33678C10.4931 9.33678 9.2992 10.5307 9.2992
        12.0034C9.2992 13.4762 10.4931 14.6701 11.9659 14.6701Z
      `}
      fill={props.color}
    />
  </SvgIcon>
);

DrawCircleIcon.propTypes = {
  color: PropTypes.string,
  style: PropTypes.object
};

export default DrawCircleIcon;
