/* eslint-disable jsx-a11y/mouse-events-have-key-events */
import React from 'react';
import PropTypes from 'prop-types';
import OverflowTooltipable from '@shared/overflow-tooltipable';
import './truncated-cell.scss';

class TruncatedCell extends OverflowTooltipable {
  render() {
    const { children } = this.props;
    const tooltip = this.props.tooltip || children;
    return (
      <div
        onMouseOver={this.hoverOn}
        onMouseOut={this.hoverOff}
        role="presentation"
        styleName="truncated-cell-container"
      >
        {children}
        {this.renderTooltip(tooltip)}
      </div>
    );
  }
}

TruncatedCell.propTypes = {
  children: PropTypes.node,
  targetId: PropTypes.number,
  tooltip: PropTypes.oneOfType([PropTypes.string, PropTypes.node])
};

export default TruncatedCell;
