import React from 'react';
import { Link } from 'react-router-dom';
import robot from './robot.png';
import Logo from '@icons/logo';
import './not-found.scss';

const NotFoundPage = () => (
  <div styleName="page-not-found-container">
    <div>
      <div>
        <img src={robot} alt="robot" />
      </div>
      <div styleName="page-not-found-header">
        404 Error - Page Not Found
      </div>
      <div styleName="page-not-found-body">
        Sorry, the page you are looking for could not be found.
      </div>
      <div>
        <Link to="/">
          <Logo />
        </Link>
      </div>
    </div>
  </div>
);

export default NotFoundPage;
