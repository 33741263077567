import React, { memo } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import DefaultDateTime from '@shared/formatted-datetime-helper';
import { getUserAvatar } from '@utils/data-table/user';
import { getConflictActionIcon } from '@utils/logs-utils';
import { renderCommentWithMentions } from '@utils/notification-utils';
import './log-item.scss';

const LogItem = ({
  index,
  item,
  resolveIndex,
  revokeIndex
}) => {
  const { agency, user: users } = useSelector(state => state.dataTypes);
  const { entry } = item;
  const { user } = entry;

  const renderResolution = (action, label, both_sides, actionAgency) => (
    <div styleName="resolution-container">
      <div styleName="resolution-icon">
        {getConflictActionIcon(action, both_sides)}
      </div>
      <div styleName="resolution-label">
        {label}
        {both_sides && ' from both sides'}
        {!both_sides && actionAgency && (
          <span>
            &nbsp;on behalf of&nbsp;
            <div styleName="resolution-bold">
              {actionAgency}
            </div>
          </span>
        )}
      </div>
    </div>
  );

  const getAgencyName = () => {
    if (user && user.agency && agency && agency[user.agency]) {
      return agency[user.agency].name;
    }
    return null;
  };

  const renderHeader = () => {
    const agencyName = getAgencyName();
    return (
      <div styleName="header">
        <div styleName="user">
          {user && user.name}
        </div>
        {agencyName && <span>&bull;</span>}
        {agencyName && <div styleName="agency">{agencyName}</div>}
        <div styleName="filler" />
        <div styleName="date">
          <DefaultDateTime value={entry.created} />
        </div>
      </div>
    );
  };

  const renderAction = () => {
    const { attrs } = item;
    const { action, comment } = entry;
    const { agency: actionAgency, both_sides } = attrs || {};
    const isComment = action === 'overlap_comment' || action === 'task_activity_comment' || action === 'entity_comment';
    const style = `action${isComment ? ' comment' : ''}`;
    return (
      <div styleName={style}>
        {isComment && renderCommentWithMentions(comment, users)}
        {action === 'overlap_resolve' && renderResolution(action, 'Resolved the conflict', both_sides, actionAgency)}
        {action === 'overlap_revoke' && renderResolution(action, 'Revoked the resolution', both_sides, actionAgency)}
      </div>
    );
  };

  const getLabel = () => {
    const { action } = entry;
    if (action === 'overlap_revoke' || action === 'overlap_resolve') {
      // All revoked entries means the previous resolve operation is
      // revoked, thus the current status for that conflict
      // is always 'Revoked':
      if (action === 'overlap_revoke') {
        return 'Revoked';
      }

      // For resolved items:
      if (action === 'overlap_resolve') {
        // If a resolved action was revoked, then the current
        // status of that revocation is also 'Revoked'.
        if (revokeIndex >= 0 && revokeIndex < index) {
          return 'Revoked';
        }
        // If the first resolve item is the current one and
        // either, there are no revocations, or they
        // exist but none of them revokes the current
        // resolution, flag this resolution as the 'Active' one.
        if (resolveIndex === index && (revokeIndex === -1 || revokeIndex > index)) {
          return 'Active';
        }
      }
    }
    return null;
  };

  const renderTag = () => {
    const label = getLabel();
    if (label) {
      const tagStyle = `tag ${label === 'Active' ? 'active' : ''}`;
      return (
        <div styleName="tag-container">
          <div styleName={tagStyle}>{label}</div>
          <div styleName="filler" />
        </div>
      );
    }
    return null;
  };

  const renderInfoCard = () => {
    const { attrs } = item;
    if (attrs && attrs.reason) {
      return (
        <div styleName="info-card">
          {renderTag()}
          <div styleName="row-title">Resolution</div>
          <div styleName="row-body">{attrs.reason}</div>
          {attrs.notes && <div styleName="row-title">Description</div>}
          {attrs.notes && <div styleName="row-body">{attrs.notes}</div>}
        </div>
      );
    }
    return null;
  };

  return (
    <div styleName="log-item-container">
      <div styleName="avatar-column">
        {getUserAvatar(user)}
      </div>
      <div styleName="content-column">
        {renderHeader()}
        {renderAction()}
        {renderInfoCard()}
      </div>
    </div>
  );
};

LogItem.propTypes = {
  index: PropTypes.number,
  item: PropTypes.object,
  resolveIndex: PropTypes.number,
  revokeIndex: PropTypes.number
};

export default memo(LogItem);
