import React from 'react';
import PropTypes from 'prop-types';
import { colors, dotmapsBlue } from '@constants/colors';
import { Icon, IconButton } from '@mui';
import LoaderIconWrapper from '@shared/loader-icon-wrapper';

// Show an <IconButton> component for exporting data.
// This takes care of the 'downloading' state, displaying
// a loader icon in that case.
const ActionDownloadIconButton = ({ disabled, loaderColor, onClick, color, toolbar }) => (
  <LoaderIconWrapper loaderColor={loaderColor}>
    <IconButton
      onClick={onClick}
      tooltip="Export"
      style={{
        ...(toolbar && {
          padding: 0,
          margin: '2px 6px',
          width: '24px',
          height: '24px'
        }),
        ...(disabled && { cursor: 'default' })
      }}
    >
      <Icon
        color={color || colors.neutral.white}
        style={{...(disabled && { opacity: 0.5 })}}
      >
        file_download
      </Icon>
    </IconButton>
  </LoaderIconWrapper>
);

ActionDownloadIconButton.propTypes = {
  color: PropTypes.string,
  disabled: PropTypes.bool,
  loaderColor: PropTypes.string,
  onClick: PropTypes.func,
  toolbar: PropTypes.bool
};

ActionDownloadIconButton.defaultProps = {
  disabled: false,
  loaderColor: dotmapsBlue
};

export default ActionDownloadIconButton;
