import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';

import { isNilOrEmpty } from '@utils/validation-utils';

import {
  CARD_STATE_EMPTY,
  CARD_STATE_ERROR,
  CARD_STATE_LOADING,
  CARD_STATE_READY
} from './constants';
import CardContent from './card-content';
import CardEmpty from './card-states/card-empty';
import CardError from './card-states/card-error';
import CardLoading from './card-states/card-loading';
import DashboardContext from './dashboard-context';

import './dashboard-card.scss';

const DashboardCardLoader = ({ card }) => {
  const [cardData, setCardData] = useState();
  const { updateCardState } = useContext(DashboardContext);

  useEffect(() => {
    const loadCardContent = async getCardData => {
      try {
        const data = await getCardData({ card });
        setCardData(R.pathOr({}, ['data'], data));
        updateCardState(card, CARD_STATE_READY);
      } catch (error) {
        updateCardState(card, CARD_STATE_ERROR);
      }
    };

    if (card.contentState === CARD_STATE_LOADING) {
      if (isNilOrEmpty(card.getCardData)) {
        updateCardState(card, CARD_STATE_READY);
        return;
      }
      loadCardContent(card.getCardData);
    }
  }, [card, updateCardState]);

  switch (card.contentState) {
  case CARD_STATE_ERROR:
    return <CardError card={card} />;
  case CARD_STATE_LOADING:
    return <CardLoading card={card} />;
  case CARD_STATE_READY:
    return <CardContent cardData={cardData} card={card} />;
  case CARD_STATE_EMPTY:
  default:
    return <CardEmpty card={card} />;
  }
};

DashboardCardLoader.propTypes = {
  card: PropTypes.object
};

export default DashboardCardLoader;
