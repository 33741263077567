import React, { memo, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { dismissDialog } from '@actions/dialogs-actions';
import Button from '@material-ui/core/Button';
import Dialog from '@shared/dialogs/dialog';

const WarningDialog = () => {
  const dispatch = useDispatch();
  const { open, redirectPath, text } = useSelector(state => state.dialogs);

  const onClose = useCallback(
    () => dispatch(dismissDialog(redirectPath)),
    [dispatch, redirectPath]
  );

  return (
    <Dialog
      actions={
        <Button color="primary" onClick={onClose}>OK</Button>
      }
      open={open}
    >
      {text}
    </Dialog>
  );
};

export default memo(WarningDialog);
