import React, { memo, useMemo } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import GroupTypeIcon from '@icons/group-type-icon';
import DmListItem from '../ui-library/list/dm-list-item';

import { getAllEntityTypeConfigs, getEntityNames } from '@selectors/entities-selector';

const GroupListItem = ({
  border = false,
  className = null,
  draggableProps = null,
  onClick = null,
  group = {},
  ...passThroughProps
}) => {
  const entityNames = useSelector(getEntityNames);
  const entityTypes = useSelector(getAllEntityTypeConfigs);
  const detailText = useMemo(() => {
    const detailItems = [
      (entityNames
        .map(entityName => {
          const typeInfo = entityTypes[entityName];
          const count = group?.current?.entities_count?.[entityName] || 0;
          if (count > 0 && typeInfo.label) {
            return `${count}\u00A0${typeInfo.label}`;
          }
          return null;
        }
        )
        .filter(value => value !== null)
        .join(' ')
      ) || null,
      group?.created_by?.name || null,
      group?.external_id || null,
      group?.status_name || null
    ].filter(value => value !== null);
    return detailItems.join(' \u2022\u00A0');
  }, [group, entityNames, entityTypes]);

  return (
    <DmListItem
      beforeList={<GroupTypeIcon type={group?.type_name} size="1.125rem" />}
      border={border}
      className={className}
      draggableProps={draggableProps}
      onClick={onClick}
      secondaryText={detailText}
      {...passThroughProps}
    >
      {group?.name}
    </DmListItem>
  );
};

GroupListItem.propTypes = {
  border: PropTypes.bool,
  className: PropTypes.string,
  draggableProps: PropTypes.object,
  group: PropTypes.object,
  onClick: PropTypes.func
};

export default memo(GroupListItem);
