import React, { memo, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { updateDashboardField } from '@actions/dashboard-actions';
import ReadOnlyField from '@components/portal/details/fields/read-only-field';
import { autocompleteSearchStyles } from '@constants/mui-theme';
import { dashboardDetailsDataSelector } from '@selectors/dashboard-details-selector';
import InputSearch from '@shared/input-search';
import { simpleToOptions } from '@utils/autocomplete-utils';
import { enDash, squareChipDeleteIcon } from '@utils/shared-utils';
import './search-field.scss';

const SearchField = ({ disabled, edit, label, loaded }) => {
  const dispatch = useDispatch();
  const data = useSelector(state => dashboardDetailsDataSelector(state, 'user'));
  const { roles } = data || {};

  const onChange = useCallback(
    (event, newValues) => {
      const values = newValues.map(value => value.value);
      dispatch(updateDashboardField('user', 'roles', values));
    },
    [dispatch]
  );

  if (!edit && (!roles || roles.length === 0)) {
    // If there's no data use a DetailsBlock component to show a dash.
    return <ReadOnlyField label={label} loaded={loaded} info={enDash} />;
  }

  return (
    <div styleName="search-container">
      <InputSearch
        autocompleteContainerStyles={autocompleteSearchStyles.normal.autocompleteContainerStyles}
        containerStyles={{ margin: '0' }}
        deleteIcon={squareChipDeleteIcon}
        disabled={disabled || !edit}
        fieldName={'user_roles'}
        label={<div styleName="search-container-label">{label}</div>}
        onChange={onChange}
        squareChips
        storePath={['dataTypes', 'user_roles']}
        textReadOnly
        toOptions={simpleToOptions}
        values={roles}
      />
    </div>
  );
};

SearchField.propTypes = {
  disabled: PropTypes.bool,
  edit: PropTypes.bool,
  label: PropTypes.string,
  loaded: PropTypes.bool
};

export default memo(SearchField);
