import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { errorDataField, updateDataFieldInline } from '@actions/data-detail-actions';
import { updateActivity } from '@actions/workflow-actions';
import { useOnChangeHandlers } from '@hooks/workflow-hooks';
import {
  buildInlineEditCommonProps,
  getFieldValue,
  isResponderReadOnly
} from '@utils/workflow-utils';
import InlineAttachmentsField from './inline-attachments-field';
import InlineAssigneeField from './inline-assignee-field';
import InlineResponseField from './inline-response-field';
import '../inline-edit-field.scss';

const CoreInlineEditField = ({ data, disabled, error, field, fieldPrefix, label, required, source }) => {
  const dispatch = useDispatch();
  const { loading } = useSelector(state => state.dataDetail);

  const onChange = useCallback(
    (event, value) => {
      // Only change the value if it's not null.
      // And if the field is the assignee one allow setting it to null.
      if (value || field === 'assignee') {
        const payload = { [field]: value };
        // If the section doesn't point to a data detail root field
        // (i.e. we are editing task activities), we must specify
        // the field's path within data in order be able to update
        // it on the right place within the data object.
        const fieldPath = fieldPrefix ? `${fieldPrefix}.${field}` : field;
        // Update the value in the redux store, so we display
        // the new value before it's retrieved from the backend,
        // for a smoother UI experience.
        dispatch(updateDataFieldInline(fieldPath, value));
        dispatch(updateActivity(data.id, payload, field));
      } else {
        dispatch(errorDataField(field, 'This field is required', true));
      }
    },
    [data.id, dispatch, field, fieldPrefix]
  );

  const { onSelectChange } = useOnChangeHandlers(onChange);

  const newLabel = required ? `${label}*` : label;
  const value = getFieldValue(data, field);
  const errors = error ? error[field] : null;
  const hasErrors = Array.isArray(errors);
  const isReadOnly = isResponderReadOnly(data) || loading || disabled;
  const commonProps = {
    ...buildInlineEditCommonProps(isReadOnly, hasErrors, errors),
    id: field,
    name: field,
    value
  };

  if (field === 'attachments') {
    return (
      <InlineAttachmentsField
        activity={data}
        attachments={value}
        hasErrors={hasErrors}
        errors={errors}
        isReadOnly={isReadOnly}
        label={newLabel}
      />
    );
  } else
    if (field === 'response') {
      return (
        <InlineResponseField
          commonProps={commonProps}
          hasErrors={hasErrors}
          label={newLabel}
          onSelectChange={onSelectChange}
          source={source}
        />
      );
    } else
      if (field === 'assignee') {
        return (
          <InlineAssigneeField
            commonProps={commonProps}
            data={data}
            hasErrors={hasErrors}
            label={newLabel}
            onSelectChange={onSelectChange}
          />
        );
      }

  return null;
};

CoreInlineEditField.propTypes = {
  data: PropTypes.object,
  disabled: PropTypes.bool,
  error: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  field: PropTypes.string,
  fieldPrefix: PropTypes.string,
  label: PropTypes.string,
  required: PropTypes.bool,
  source: PropTypes.string
};

export default CoreInlineEditField;
