/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import classNames from 'classnames';

import EntityCard from './entity-card';

import styles from './more-entity-card.scss';

const MoreEntityCard = ({
  border,
  deselectAction,
  entity,
  moreButton,
  openAction,
  selectAction,
  selected
}) => {
  const toggleActive = useCallback(
    () => {
      if (selected) {
        deselectAction();
      } else {
        selectAction();
      }
    },
    [deselectAction, selectAction, selected]
  );

  return (
    <div
      onClick={toggleActive}
      className={classNames(styles.trayCardContainer, {[styles.active]: selected, [styles.border]: border})}
      role="button"
      tabIndex={0}
    >
      <EntityCard
        entity={entity}
        selected={selected}
        ovViewDetails={openAction}
        embedded
        className={styles.entityCard}
      />
      { moreButton &&
        <div className={styles.moreButton}>
          {moreButton}
        </div>
      }
    </div>
  );
};

MoreEntityCard.propTypes = {
  border: PropTypes.bool,
  deselectAction: PropTypes.func,
  entity: PropTypes.object,
  moreButton: PropTypes.object,
  openAction: PropTypes.func,
  selectAction: PropTypes.func,
  selected: PropTypes.bool
};

export default MoreEntityCard;
