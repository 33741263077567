/* eslint-disable react/display-name */
import React from 'react';
import LoadingIndicator from '@components/details/loading-indicator';
import './details-layout.scss';

const TaskTypeLayout = (Preview, Drawer) => props => (
  <div styleName="details-layout" data-testid="task-type-layout">
    <LoadingIndicator />
    <div styleName="preview-container">
      <Preview {...props} />
    </div>
    <div styleName="drawer-container">
      <Drawer {...props} />
    </div>
  </div>
);

export default TaskTypeLayout;
