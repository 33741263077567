import { useMemo } from 'react';
import moment from 'moment-timezone';

export const useOnChangeHandlers = onChange => useMemo(() => {
  const onSelectChange = (event, index, value) => onChange(event, value);

  const onDateChange = value => {
    const date = value ? moment(value) : value;
    onChange(null, date);
    return date;
  };

  const onChipSelectChange = (event, newValues) => {
    if (Array.isArray(newValues)) {
      const values = newValues.map(value => value.value);
      onChange(event, values);
      return values;
    }
    onChange(event, [newValues.value]);
    return newValues.value;
  };

  return { onSelectChange, onDateChange, onChipSelectChange };
}, [onChange]);
