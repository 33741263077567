import React from 'react';
import PropTypes from 'prop-types';
import { v4 as uuid } from 'uuid';
import { tooltipStyles } from '@constants/mui-theme';
import TruncatedCell from '@shared/data-table/cells/truncated-cell';
import { renderUsername } from '@utils/data-table/user';
import { renderGroupName } from '@utils/data-table/group';
import { formatFileSize, renderFileIcon } from '@utils/file-utils';
import { renderCustomIconAndLabel } from '@utils/icon-utils';
import { enDash, renderTooltipDate } from '@utils/shared-utils';

const Value = ({ column: { format, formatProps, key }, row }) => {
  if (row) {
    const tooltipStyle = {
      ...tooltipStyles,
      // Make tooltips wrap at 20rem.
      maxWidth: '20rem',
      overflowWrap: 'anywhere'
    };
    if (format === 'group') {
      return renderGroupName(row);
    }
    const value = row[key];
    if (format === 'bold-text') {
      return <div style={{ fontWeight: '500' }}>{value}</div>;
    }
    if (format === 'truncated-text') {
      if (!value) {
        return enDash;
      }
      return (
        <TruncatedCell tooltipStyle={tooltipStyle}>
          {value}
        </TruncatedCell>
      );
    }
    if (format === 'file-icon-name') {
      return renderCustomIconAndLabel(
        <TruncatedCell tooltipStyle={tooltipStyle}>
          {value}
        </TruncatedCell>,
        renderFileIcon(row[formatProps.iconKey]),
        row.id
      );
    }
    if (format === 'file-size') {
      return formatFileSize(value);
    }
    if (format === 'date') {
      return renderTooltipDate(value);
    }
    if (format === 'user') {
      return renderUsername(value, uuid());
    }
    return value || null;  // If 'undefined' returns null
  }
  return null;
};

Value.propTypes = {
  column: PropTypes.object,
  row: PropTypes.object
};

export default Value;
