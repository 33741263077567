import React, { memo, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import BatchErrorsSection from '@components/batch/batch-errors-section';
import PublicViewerSection from '@components/people/public-viewer-section';
import SectionField from '@components/portal/details/section-field';
import { verifyPermissions } from '@utils/permission-utils';
import './sections.scss';

const Sections = ({ edit }) => {
  const { dataType } = useParams();
  const { details } = useSelector(state => state.config);
  const detailFields = useMemo(
    () => details[dataType],
    [dataType, details]
  );
  const { sections } = detailFields;

  return (
    <div styleName="sections-container">
      {sections.map(({ fields, permissions, title }) => verifyPermissions(permissions) ? (
        <div key={title} styleName="sections-section">
          <div styleName="sections-title">{title}</div>
          {fields?.map(field => <SectionField edit={edit} field={field} key={field?.key} />)}
        </div>
      ) : null)}
      {dataType === 'agency' && <PublicViewerSection edit={edit} />}
      {dataType === 'batch' && <BatchErrorsSection />}
    </div>
  );
};

Sections.propTypes = {
  edit: PropTypes.bool
};

export default memo(Sections);
