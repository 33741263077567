import React from 'react';

const WarningOffIcon = () => (
  <svg width="18px" height="18px" version="1.1" viewBox="0 0 18 18">
    <defs>
      <path
        id="warning-off-path-1"
        d={`
            M2.453 0L16.5 14.422l-.953.953L1.5.953 2.453 0zm2.511
            5.675L7.5 8.287V9h.692l5.098 5.25H0l4.964-8.575zM9
            10.5H7.5V12H9v-1.5zM8.25 0l6.816 11.773-8.54-8.796L8.25 0z
        `}
      />
    </defs>
    <g fill="none" fillRule="evenodd" transform="translate(.75 1.5)">
      <mask id="warning-off-path-2" fill="#fff">
        <use xlinkHref="#warning-off-path-1" />
      </mask>
      <g fill="#000" fillOpacity=".4" mask="url(#warning-off-path-2)">
        <path d="M-.75-1.5h18v18h-18z" />
      </g>
    </g>
  </svg>
);

export default WarningOffIcon;
