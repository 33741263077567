import React, {memo} from 'react';
import PropTypes from 'prop-types';
import { BASE_URL } from '@constants/endpoints';

const getIconUrl = (app, type, color) => `${BASE_URL}/icons/${app}/${type}?color=${color.replace(/^#/, '')}`;

const getIconStyle = (size, style) => ({
  height: size,
  width: size,
  ...style
});

const AppIcon = ({ app, type, size = '1rem', color = '666666', style = {} }) => (
  <img alt="" src={getIconUrl(app, type, color)} style={{...getIconStyle(size, style)}} />
);

AppIcon.propTypes = {
  app: PropTypes.string,
  color: PropTypes.string,
  size: PropTypes.string,
  style: PropTypes.object,
  type: PropTypes.string
};

export default memo(AppIcon);
