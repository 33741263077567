import { dotmapsIconImage, dotmapsIconPDF } from '@constants/colors';
import { openDashboardDialog } from '@actions/dashboard-actions';
import * as dialog from '@constants/dialogs';
import {
  deleteAttachment,
  deleteTemporaryAttachment,
  deleteStoredAttachment
} from '@actions/attachment-actions';

export const downloadAttachment = attachment => {
  const link = document.createElement('a');
  link.href = attachment.signed_download_url;
  link.download = attachment.file_name;
  document.body.appendChild(link);
  link.click();
  link.parentNode.removeChild(link);
};

export const deleteAttachments = (attachment, objectId, objectType, storedInMemory, infoIndex) => dispatch => {
  // attachment and entity have both been saved
  let deleteFile = () => dispatch(deleteAttachment(attachment.id));
  if (objectId && objectId.startsWith('temp')) {
    // attachment that has been saved to the DB, but the entity has not yet been saved
    deleteFile = () => dispatch(deleteTemporaryAttachment(objectType, objectId, attachment.file_name));
  } else if (storedInMemory) {
    // attachment that is only uploaded to memory but not saved
    deleteFile = () => dispatch(deleteStoredAttachment(infoIndex));
  }
  const params = {
    filename: storedInMemory ? attachment.get('file_name') : attachment.file_name,
    deleteFile
  };
  dispatch(openDashboardDialog(dialog.DELETE_FILE, params));
};

export const getIconColor = contentType => contentType === 'application/pdf' ? dotmapsIconPDF : dotmapsIconImage;

export const getIconName = contentType => contentType === 'application/pdf' ? 'picture_as_pdf' : 'insert_photo';

export const getSubHeader = attachment => {
  const { created, created_by } = attachment;
  const uploadedDate = new Date(created);
  const username = created_by && created_by.name ? `by ${created_by.name}` : '';
  return `Uploaded ${uploadedDate.toLocaleDateString()} ${username}`;
};
