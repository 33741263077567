/**
 * Data table utils for groups.
 */
import React from 'react';
import { getEntitiesListConfig } from '@constants/config';
import GroupTypeIcon from '@icons/group-type-icon';
import TruncatedCell from '@shared/data-table/cells/truncated-cell';
import { renderDurationDays } from '@utils/gantt-utils';
import { renderCustomIconAndLabel } from '@utils/icon-utils';
import { enDash, renderTooltipDate } from '@utils/shared-utils';

const getGroupOwner = owner => owner ? owner.name : <span>{enDash}</span>;

const getEntityGroupItems = item => item ? item : <span>{enDash}</span>;

const getEntityCounts = group => {
  const entities = getEntitiesListConfig();
  const counts = {};
  entities.forEach(entity => {
    const count = group.current.entities_count[entity];
    counts[`${entity}s_count`] = getEntityGroupItems(count);
  });
  return counts;
};

export const optimizeGroupData = group => ({
  ...group,
  name: <TruncatedCell>{group.name}</TruncatedCell>,
  created: renderTooltipDate(group.created),
  status: group.status_name && group.status_name.toUpperCase(),
  modified: renderTooltipDate(group.modified),
  owner: getGroupOwner(group.owner),
  current: {
    ...getEntityCounts(group),
    start_date: group.current.start_date ? renderTooltipDate(group.current.start_date) : enDash,
    end_date: group.current.end_date ? renderTooltipDate(group.current.end_date) : enDash,
    duration: group.current.duration ? renderDurationDays(group.current.duration) : enDash
  }
});

export const renderGroupName = group => renderCustomIconAndLabel(
  <div style={{ fontWeight: '500' }}>{group.name}</div>,
  <GroupTypeIcon type={group.type_name} />,
  group.id
);
