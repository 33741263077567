import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import { DrawingManager, Polygon } from '@react-google-maps/api';
import {
  disableDrawingManager,
  enableDrawingManager,
  fetchEntitiesFromPolygon,
  setGroupLoading,
  setGroupShape
} from '@actions/groups-actions';
import { fitBounds } from '@actions/map-actions';
import EntityMapFigure from '@components/map/components/entities/map-figure';
import { mapConfig } from '@constants/component-configs';
import {
  optimizeEntitiesForMapSelector
} from '@selectors/groups-selector';
import { getGroupEntitiesList } from '@utils/gantt-utils';
import { getFiltersFromGoogleMapsShape, convertFromGoogleMapsShape } from '@utils/map-tools-utils';
import {
  convertToGoogleMapsShape,
  generatePolygonPaths
} from '@utils/map-utils';
import { withRouter } from '@utils/router-utils';
import MarkerOverlaps from './marker-overlaps';
import BoundaryEntities from './boundary-entities';

class Components extends Component {
  UNSAFE_componentWillMount() {
    this.initializePage();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (!this.props.applyShape && nextProps.applyShape) {
      this.applyShape(this.polygonRef);
    }
  }

  componentDidUpdate(prevProps) {
    const { location } = this.props;
    if (location !== prevProps.location) {
      this.initializePage();
    }
  }

  initializePage = () => {
    const shape = R.path(['state', 'shape'], this.props.location);
    if (shape) {
      this.props.fitBounds([{shape}]);
      this.props.enableDrawingManager().then(() => {
        this.applyShape(convertToGoogleMapsShape(shape));
      });
    }
  };

  getEntitiesToFetch = () => {
    const { groupType } = this.props;
    // Return only entities allowed for this group type:
    return getGroupEntitiesList(groupType);
  };

  // Applies the edited shape into the store:
  applyShape = mapsShape => {
    let geomIn = null;
    let shape = null;
    if (mapsShape) {
      if (R.hasIn('getPath', mapsShape)) {
        // Read shape info from shape drawn on the map:
        geomIn = getFiltersFromGoogleMapsShape(mapsShape);
        shape = convertFromGoogleMapsShape(mapsShape);
        this.removeShape(mapsShape);
      } else {
        // Read shape info from and edited polygon:
        const polygonShape = mapsShape.state.polygon;
        geomIn = getFiltersFromGoogleMapsShape(polygonShape);
        shape = convertFromGoogleMapsShape(polygonShape);
      }
    } else
      if (this.props.shape) {
        // Read shape from existing group shape:
        const { coordinates } = this.props.shape;
        geomIn = `POLYGON((${coordinates[0].map(coordinate => coordinate.join(' ')).join(',')}))`;
        shape = this.props.shape;
      } else {
        // No shape set yet:
        return;
      }
    this.props.setGroupLoading(true).then(() => {
      this.props.setGroupShape(shape).then(() => {
        const fetchParams = {
          geom_in: geomIn,
          generates_overlaps: 1,
          ...(this.props.startDate && this.props.endDate && {
            // It's correct to apply endDate to start_date__lte, there's no mistake,
            // since it's lte (less than equal), the same for end_date__gte:
            start_date__lte: this.props.endDate,
            end_date__gte: this.props.startDate
          }),
          ...(this.props.groupId &&
              this.props.shape && { unknown_to_group: this.props.groupId })
        };
        const promises = [
          ...this.getEntitiesToFetch().map(entity => this.props.fetchEntitiesFromPolygon(fetchParams, entity)),
          ...[
            this.props.disableDrawingManager()
          ]
        ];
        Promise.all(promises).then(() => this.props.setGroupLoading(false));
      });
    });
  };

  // Called when we complete a polygon (i.e. when we close the shape)
  // on the DrawingManager.
  onPolygonComplete = mapsShape => {
    this.applyShape(mapsShape);
  };

  // Removes the shape from the map:
  removeShape = mapsShape => {
    // This will run only for when we delete a shape created with the
    // DrawingManager class.
    //
    // The ones created with the react-google-maps's Polygon class,
    // doesn't have a setMap() method, however it calls internally
    // setMap(null) when the Polygon is unmounted.
    if (mapsShape && mapsShape.setMap) {
      mapsShape.setMap(null);
    }
  };

  setPolygonRef = ref => {
    this.polygonRef = ref;
  };

  // Render the shape as a Polygon component if a shape exists.
  renderPolygon = () => {
    const { drawing, shape } = this.props;

    // If we are in drawing mode and there's no shape,
    // render the DrawingManager component.
    if (drawing && !shape) {
      return (
        <DrawingManager
          drawingMode="polygon"
          onPolygonComplete={this.onPolygonComplete}
          options={mapConfig.drawingManager}
        />
      );
    }

    // Else, if there's a shape render it:
    if (shape && shape.type === 'Polygon' && shape.coordinates) {
      const paths = generatePolygonPaths(shape.coordinates);
      let drawingProps = {};

      if (drawing) {
        // Properties that are only needed in drawing mode.
        drawingProps = {
          ref: this.setPolygonRef
        };
      }

      return (
        <Fragment>
          <Polygon {...drawingProps} editable={drawing} paths={paths} />
          <BoundaryEntities />
        </Fragment>
      );
    }

    return null;
  };

  render() {
    const { entities } = this.props;
    return (
      <Fragment>
        {this.renderPolygon()}
        <MarkerOverlaps />
        {entities && entities.map(entity => (
          <EntityMapFigure
            key={entity.id}
            {...entity}
            markerSource="group"
            type={entity.entityType}
          />
        ))}
      </Fragment>
    );
  }
}

Components.propTypes = {
  applyShape: PropTypes.bool,
  disableDrawingManager: PropTypes.func,
  drawing: PropTypes.bool,
  enableDrawingManager: PropTypes.func,
  endDate: PropTypes.string,
  entities: PropTypes.array,
  fetchEntitiesFromPolygon: PropTypes.func,
  fitBounds: PropTypes.func,
  groupId: PropTypes.number,
  groupType: PropTypes.number,
  location: PropTypes.object,
  setGroupLoading: PropTypes.func,
  setGroupShape: PropTypes.func,
  shape: PropTypes.object,
  startDate: PropTypes.string
};

const mapStateToProps = state => {
  const { groups: { edit } } = state;
  const { applyShape, drawing, group: { id = null, type } } = edit;
  const shape = R.path(['auto_area', 'shape'], edit.group);
  const startDate = R.path(['auto_area', 'start_date'], edit.group);
  const endDate = R.path(['auto_area', 'end_date'], edit.group);
  return {
    applyShape,
    drawing,
    entities: optimizeEntitiesForMapSelector(state),
    shape,
    groupId: id,
    groupType: type,
    startDate,
    endDate
  };
};

const mapDispatchToProps = {
  disableDrawingManager,
  enableDrawingManager,
  fetchEntitiesFromPolygon,
  fitBounds,
  setGroupLoading,
  setGroupShape
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Components));
