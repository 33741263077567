/* eslint-disable react/jsx-no-bind */
import React, { memo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Icon } from '@mui';
import { toggleTray } from '@actions/map-actions';
import { getTrayDepth } from '@selectors/map-selector';
import './tab-bar-toggle.scss';

const TabBarToggle = () => {
  const dispatch = useDispatch();
  const depth = useSelector(getTrayDepth);
  const trayOpen = depth > 0;

  const onClick = () => dispatch(toggleTray());

  return (
    <div styleName={`map-tab-bar-toggle-container ${trayOpen ? 'open' : ''}`}>
      <Icon onClick={onClick}>
        { trayOpen ? 'arrow_left' : 'arrow_right' }
      </Icon>
    </div>
  );
};

export default memo(TabBarToggle);
