import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import TopBar from './top-bar';
import { fetchDataDetail, createDuplicateDataDetail } from '@actions/data-detail-actions';
import './details.scss';

const Details = ({ children }) => {
  const dispatch = useDispatch();
  const { dataType, id, action } = useParams();

  useEffect(() => {
    if (id) {
      if (action !== 'duplicate') {
        dispatch(fetchDataDetail(dataType, id));
      } else {
        dispatch(createDuplicateDataDetail(dataType, id));
      }
    }
  }, [dataType, dispatch, id, action]);

  return (
    <div styleName="details-container">
      <TopBar />
      {children}
    </div>
  );
};

Details.propTypes = {
  children: PropTypes.element
};

export default Details;
