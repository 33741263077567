/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { isFunction } from 'lodash';
import { getEntityType } from '@constants/config';
import {
  allowedToChangeStatus,
  CONFLICT_STATUS_ROW,
  getStatus
} from '@utils/data-detail/conflicts';
import { allowResolution } from '@utils/permission-utils';
import './overlap-status-row.scss';

class OverlapStatusRow extends Component {
  getStatus = () => {
    const { isOpportunity, mainOverlapStatus, overlapStatus } = this.props;
    return getStatus(mainOverlapStatus, overlapStatus, isOpportunity);
  };

  isAllowComments = () => {
    const { itemEntityType } = this.props;
    const itemType = getEntityType(itemEntityType);
    return itemType.overlaps['allow-comments'];
  };

  showLink = () => {
    const { mainAgency } = this.props;
    const canChangeStatus = allowedToChangeStatus(mainAgency);
    const canComment = this.isAllowComments();
    return canChangeStatus || canComment;
  };

  getHint = hint => {
    if (isFunction(hint)) {
      const { overlapStatus } = this.props;
      if (overlapStatus.length > 0) {
        return hint(overlapStatus[0]);
      }
      return null;
    }
    return hint;
  };

  render() {
    const { itemEntityType, mainEntityType, onClick, showShadow } = this.props;
    const { status } = this.getStatus();
    const { badge, hint } = CONFLICT_STATUS_ROW[status];
    const hintText = this.getHint(hint);

    const isAllowResolution = allowResolution(mainEntityType, itemEntityType);
    return (
      <div
        onClick={onClick}
        role="presentation"
        styleName={`status-row-container ${showShadow ? 'status-row-shadow' : ''}`}
      >
        {isAllowResolution && badge}
        <div styleName="status-hint">
          {isAllowResolution && hintText}
        </div>
        {this.showLink() && <div styleName="link">VIEW STATUS</div>}
      </div>
    );
  }
}

OverlapStatusRow.propTypes = {
  isOpportunity: PropTypes.bool,
  itemEntityType: PropTypes.string,
  mainAgency: PropTypes.number,
  mainEntityType: PropTypes.string,
  mainOverlapStatus: PropTypes.array,
  onClick: PropTypes.func,
  overlapStatus: PropTypes.array,
  showShadow: PropTypes.bool
};

export default OverlapStatusRow;
