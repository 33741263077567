export const CARD_LOAD_STYLE_CHART = 'CHART';

export const CARD_SIZE_LG = 'LG';
export const CARD_SIZE_SM = 'SM';

export const CARD_STATE_LOADING = 'LOADING';
export const CARD_STATE_ERROR = 'ERROR';
export const CARD_STATE_EMPTY = 'EMPTY';
export const CARD_STATE_READY = 'READY';

export const CARD_TYPE_EMBED = 'EMBED';
