/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import { includes } from 'lodash';
import { RadioButton } from 'material-ui/RadioButton';
import { filterStyles } from '@constants/mui-theme';
import { categoryToInt } from '@utils/filter-utils';
import FilterGroupItem from './filter-group-item';
import '../filter.scss';

class FilterGroup extends Component {
  getFilterName = () => {
    const { category, filterName } = this.props;
    if (!category) {
      return filterName;
    }
    // Since we can have more than one category per entity,
    // each category must have their own filter slot.
    return `${filterName}|${category}`;
  };

  getCurrentValues = () => {
    const { values } = this.props;
    const filterName = this.getFilterName();

    // Get the current filters for the specified name:
    const current = values[filterName];

    if (R.isNil(current) || R.isEmpty(current)) {
      return [];
    }

    // Convert to array:
    return current.split(',').map(id => categoryToInt(id));
  };

  getNegatedFilterName = () => {
    const name = this.getFilterName();
    return name.replace(new RegExp('__in$'), '__not_in');
  };

  onToggleCheckbox = id => {
    const filterName = this.getFilterName();
    const currentIds = this.getCurrentValues();

    let newIds = null;

    if (includes(currentIds, id)) {
      // If the id is in the list, toggle it off:
      newIds = currentIds.filter(currentId => currentId !== id);
    } else {
      // Else add it to the list:
      newIds = [...currentIds, id];
    }

    const isEmpty = R.isEmpty(newIds);
    if (isEmpty) {
      // If there are no ids, use a value to flag that this
      // filter is off and should be removed.
      newIds = ['clear'];
    }

    const newFilter = {
      [filterName]: newIds.join(',')  // Convert to string to apply it.
    };

    if (this.props.negateIfEmpty) {
      if (isEmpty) {
        const allValues = this.props.items.map(item => item.id).join(',');
        newFilter[this.getNegatedFilterName()] = allValues;
      } else {
        newFilter[this.getNegatedFilterName()] = '';
      }
    }

    this.props.setFilters(newFilter);
  };

  isAllSelected = () => {
    const { items } = this.props;
    const currentIds = this.getCurrentValues();
    return items.filter(item => includes(currentIds, item.id)).length === items.length;
  };

  onSelectAll = () => {
    const { filterEnabled, items, negateIfEmpty, category } = this.props;
    if (!filterEnabled) {
      return;
    }
    const filterName = this.getFilterName();
    const allValues = items.map(item => item.id).join(',');
    if (this.isAllSelected()) {
      const currentIds = this.getCurrentValues();
      const itemIds = items.map(item => item.id);
      const newIds = currentIds.filter(currentId => !includes(itemIds, currentId));
      const newFilter = {[filterName]: newIds.join(',')};
      if (negateIfEmpty) {
        newFilter[this.getNegatedFilterName()] = allValues;
      }
      if (category && !newFilter[filterName]) {
        newFilter[filterName] = category;
      }
      this.props.setFilters(newFilter);
      return;
    }
    const newFilter = {[filterName]: allValues};
    if (negateIfEmpty) {
      newFilter[this.getNegatedFilterName()] = '';
    }
    this.props.setFilters(newFilter);
  };

  getAllLabel = () => {
    if (this.isAllSelected()) {
      return 'UNSELECT ALL';
    }
    return 'SELECT ALL';
  };

  getRadio = () => (
    <div styleName="radio">
      <RadioButton
        disabled={!this.props.filterEnabled}
        checked={this.props.radio.checked}
        onCheck={this.props.radio.onCheck}
        value={this.props.radio.value}
        {...filterStyles.filterGroupRadio}
      />
    </div>
  );

  render() {
    const {
      enabled,
      filterEnabled,
      getIcon,
      items,
      radio,
      title
    } = this.props;
    const currentValues = this.getCurrentValues();
    return (
      <div styleName="content-filter-group">
        {title && (
          <div styleName="title">
            {radio && this.getRadio()}
            <div styleName="label">{title}</div>
            <div styleName={`button ${filterEnabled ? '' : 'disabled'}`} onClick={this.onSelectAll} role="presentation">
              {this.getAllLabel()}
            </div>
          </div>
        )}
        <div styleName="list">
          {items.map(item => (
            <FilterGroupItem
              currentValues={currentValues}
              enabled={enabled}
              filterEnabled={filterEnabled}
              getIcon={getIcon}
              key={item.id}
              item={item}
              onToggleCheckbox={this.onToggleCheckbox}
            />)
          )}
        </div>
      </div>
    );
  }
}

FilterGroup.propTypes = {
  category: PropTypes.string,
  enabled: PropTypes.bool,
  filterEnabled: PropTypes.bool,
  filterName: PropTypes.string,
  getIcon: PropTypes.func,
  items: PropTypes.array,
  negateIfEmpty: PropTypes.bool,
  onRadioSelect: PropTypes.func,
  radio: PropTypes.object,
  setFilters: PropTypes.func,
  title: PropTypes.string,
  values: PropTypes.object
};

FilterGroup.defaultProps = {
  enabled: true
};

export default FilterGroup;
