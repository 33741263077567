import React, { memo } from 'react';
import PropTypes from 'prop-types';
import SegmentTitle from '@components/entity/info/drawer/form/form-tab/segment-title';
import '../forms/forms.scss';

const DrawerSectionTitle = ({ tab, readOnly }) => {
  if (tab.type === 'segment') {
    return (
      <div>
        <div styleName="form-title form-stepper-title" id={`tab-section-${tab.id}-title`}>
          <div styleName="form-title-label-large">
            {tab.label}
          </div>
        </div>
        <SegmentTitle readOnly={readOnly} tab={tab} />
      </div>
    );
  } else if (tab.type === 'subheader') {
    return (
      <div styleName="form-title" id={`tab-section-${tab.id}-title`}>
        <div styleName="form-title-label-subheader">
          {tab.label}
        </div>
      </div>
    );
  }
  return (
    <div styleName="form-title form-stepper-title" id={`tab-section-${tab.id}-title`}>
      <div styleName="form-title-label-large">
        {tab.label}
      </div>
      {tab.extra && (
        <div styleName="form-title-label-extra">
          {tab.extra}
        </div>
      )}
    </div>
  );
};

DrawerSectionTitle.propTypes = {
  readOnly: PropTypes.bool,
  tab: PropTypes.object
};

export default memo(DrawerSectionTitle);
