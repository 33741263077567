import React from 'react';
import PropTypes from 'prop-types';
import './badge.scss';

const Badge = ({ label, color, small }) => (
  <div styleName={`badge-container ${color ? color : 'primary'} ${small ? 'small' : ''}`}>
    {label}
  </div>
);

Badge.propTypes = {
  action: PropTypes.func,
  color: PropTypes.string,
  label: PropTypes.string,
  small: PropTypes.bool
};

export default Badge;
