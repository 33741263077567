import React from 'react';
import PropTypes from 'prop-types';
import Avatar from '@material-ui/core/Avatar';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  filterSize: {
    fontSize: 10,
    width: 20,
    height: 20
  }
});

const FilterAvatar = ({ src }) => {
  const classes = useStyles();
  return <Avatar className={classes.filterSize} src={src} />;
};

FilterAvatar.propTypes = {
  src: PropTypes.string
};

export default FilterAvatar;
