import React from 'react';
import { useSelector } from 'react-redux';
import { getGroupIsLoading } from '@selectors/groups-selector';
import TimelineChart from '@shared/timeline-chart';
import { buildGanttPopover, getUnitSize } from '@utils/gantt-utils';
import Drawer from './drawer';
import Header from './header';
import './content.scss';

const Content = () => {
  const loading = useSelector(getGroupIsLoading);
  const { dateUnit } = useSelector(state => state.groups.gantt.filters);
  const { groupedRows } = useSelector(state => state.groups.gantt);

  if (loading) {
    return null;
  }

  const unitSize = getUnitSize(dateUnit);

  const contentChartStyle = { backgroundSize: `${unitSize}px 100%` };

  return (
    <div styleName="gantt-content">
      <Drawer rows={groupedRows} />
      <div styleName="gantt-content-container">
        <Header />
        <div style={contentChartStyle} styleName="gantt-content-chart">
          <TimelineChart dateUnit={dateUnit} popoverContent={buildGanttPopover} rows={groupedRows} />
        </div>
      </div>
    </div>
  );
};

export default Content;
