import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import memoize from 'memoize-one';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import muiTheme from '@constants/mui-theme';
import './not-authenticated.scss';

const getLoginStyles = memoize(config => ({
  loginBackground: {
    backgroundImage: `url(${
      (config.siteLoaded && config.backgroundPath) ||
      'https://storage.googleapis.com/coordinate-assets/background/default.png'
    })`
  }
}));

class NotAuthenticatedLayout extends React.Component {
  render() {
    const { config } = this.props;
    if (!config.siteLoaded) {
      return null;
    }
    const styles = getLoginStyles(config);
    return (
      <MuiThemeProvider muiTheme={muiTheme}>
        <div styleName="not-authenticated-wrap" style={styles.loginBackground}>
          <div styleName="ie-spacer"/>
          <div styleName="not-authenticated-body">
            <div styleName="not-authenticated-heading">
              <div styleName="not-authenticated-heading-logo">
                <img src={`${config.LOGIN_ICON_URL}/coordinate-logo-white-horizontal.svg`} alt="" />
              </div>
              <div styleName="not-authenticated-heading-divider" />
              <div styleName="not-authenticated-heading-text">{config.cityName}</div>
            </div>
            <div>
              {this.props.children}
            </div>
          </div>
          <div styleName="ie-spacer"/>
        </div>
      </MuiThemeProvider>
    );
  }
}

NotAuthenticatedLayout.propTypes = {
  children: PropTypes.element,
  config: PropTypes.object
};

const mapStateToProps = state => {
  const { config } = state;
  return { config };
};

export default connect(mapStateToProps, {})(NotAuthenticatedLayout);
