import React, { memo, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import scrollIntoView from 'smooth-scroll-into-view-if-needed';
import { getAttachments } from '@actions/attachment-actions';
import { scrollToField } from '@actions/data-detail-actions';
import DetailCard from '@components/attachments/detail-card';
import Header from './header';
import './section.scss';

const SCROLL_ID = 'attachments-section';

const AttachmentsSection = ({ edit, taskId }) => {
  const dispatch = useDispatch();
  const { data } = useSelector(state => state.dataDetail);
  const { scrollId } = useSelector(state => state.dataDetail);
  const { attachments } = useSelector(state => state.attachments);
  const taskAttachments = attachments?.[`task${taskId}`] || [];

  useEffect(() => {
    if (scrollId === SCROLL_ID) {
      const element = document.getElementById(scrollId);
      scrollIntoView(element, { scrollMode: 'if-needed' });
      // Clear the scroll id in the store, since we executed the scroll action.
      dispatch(scrollToField(null));
    }
  }, [dispatch, scrollId, data?.segments?.length]);

  useEffect(() => {
    if (taskId && !taskId.startsWith('temp')) {
      dispatch(getAttachments('task', taskId));
    }
  }, [dispatch, taskId]);

  const count = useMemo(() => taskAttachments?.length, [taskAttachments?.length]);

  // Skip section in details mode when there are no entries.
  if (!edit && count === 0) {
    return null;
  }

  return (
    <div styleName="section" id={SCROLL_ID}>
      {count > 0 &&
        <Header
          edit={edit}
          headerLabel="Attachments"
          addLabel="Add attachment"
          count={count}
          taskId={taskId}
          attachmentsHeader
          attachments={taskAttachments}
        />
      }
      <div styleName="section-field-list">
        {taskAttachments?.map((attachment, id) => (
          <DetailCard
            deleteButton={taskId?.startsWith('temp')}
            downloadButton={!taskId?.startsWith('temp')}
            attachment={attachment}
            objectId={taskId}
            objectType={'task'}
            key={id}
            infoIndex={id}
            showSubHeader
          />
        ))}
      </div>
    </div>
  );
};

AttachmentsSection.propTypes = {
  edit: PropTypes.bool,
  taskId: PropTypes.string
};

export default memo(AttachmentsSection);
