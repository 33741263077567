import React from 'react';
import PropTypes from 'prop-types';
import { colors } from '@constants/colors';
import DownloadAll from '@components/attachments/download-all';
import ChipCard from '@components/attachments/chip-card';
import { Button, Icon } from '@mui';
import FileUpload from '@shared/files/file-upload';
import './inline-attachments-control.scss';

const InlineAttachmentControl = ({
  activity, attachments, errors, isReadOnly
}) => (
  <div styleName="control-container">
    {!isReadOnly && (
      <div styleName="control-header">
        <FileUpload contentType="task_activity" objectId={activity.id} parentId={activity.task}>
          <Button
            size="small"
            startIcon={<Icon color={colors.opacity.black0_6}>file_upload</Icon>}
            style={{ marginRight: '0.5rem' }}
          >
            Upload
          </Button>
        </FileUpload>
        {attachments.length > 0 && <DownloadAll attachments={attachments} />}
      </div>
    )}
    <div styleName="control-contents">
      {attachments.map((attachment, index) => (
        <ChipCard
          activity={activity}
          attachment={attachment}
          index={index}
          isReadOnly={isReadOnly}
          key={index}
        />
      ))}
    </div>
    {isReadOnly && (!attachments || attachments.length === 0) && (
      <div styleName="control-empty">
        None
      </div>
    )}
    {errors && errors.length > 0 && (
      <div styleName="control-error">
        {errors.map((error, index) => <div key={index}>{error}</div>)}
      </div>
    )}
  </div>
);

InlineAttachmentControl.propTypes = {
  activity: PropTypes.object,
  attachments: PropTypes.array,
  errors: PropTypes.array,
  isReadOnly: PropTypes.bool
};

export default InlineAttachmentControl;
