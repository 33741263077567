import { includes } from 'lodash';
import * as R from 'ramda';
import { push } from 'connected-react-router';
import {
  getDashboardPathFromConfig,
  getDataTableConfig,
  getEntitiesListConfig,
  getEntityTypeLabel
} from '@constants/config';
import { isAdminDataType } from '@constants/endpoints';
import { getConfig } from '@utils/config-utils';
import { canEditEntities, canViewEntityPage, canEditGroups, isRoedit, isPCCO } from '@utils/permission-utils';
import { enDash } from '@utils/shared-utils';

const getColumnData = (portal, type, subType) => {
  const config = getConfig();
  const typesWithSubTypes = ['activity', 'group', 'overlap', 'task'];
  if (typesWithSubTypes.includes(type)) {
    return getDashboardPathFromConfig(config, ['datatable', 'columns', type, subType], portal);
  }
  return getDashboardPathFromConfig(config, ['datatable', 'columns', type], portal);
};

export const getColumns = (portal, type, subType) => {
  const columnData = getColumnData(portal, type, subType);

  // Non-PCCO users should hide the 'agency' column
  // since they can only see conflicts from their
  // own agency.
  if (!isPCCO() && type === 'conflict') {
    // The backend already doesn't send that column
    // but we must hide it from the headers.
    return columnData.filter(column => column.key !== 'agency');
  }

  return columnData;
};

export const reachingTableEnd = ({ target: { scrollHeight, scrollTop } }) => scrollTop >= scrollHeight - 2000;

const getFilters = (config, type, subType) => {
  // Check for subtypes (i.e. /overlap/permits, /group/relational, etc):
  if (type && subType) {
    const filters = config[type];
    if (filters) {
      return filters[subType];
    }
  }
  return config[type];
};

const getColumnOrdering = column => column.initOrder === 'desc' ? `-${column.ordering}` : column.ordering;

const buildOrdering = (portal, dataType, subType) => {
  let ordering = '';
  const columnData = getColumns(portal, dataType, subType);

  // Find the sorting column that has precedence, to put it first:
  const sortColumn = columnData.find(column => column.defaultOrder);
  if (sortColumn) {
    ordering = getColumnOrdering(sortColumn);
  }

  columnData.forEach(column => {
    if (sortColumn && sortColumn.ordering === column.ordering) {
      return;  // Skip the first column.
    }
    if (ordering !== '') {
      const tempOrdering = getColumnOrdering(column);
      if (typeof tempOrdering !== 'undefined') {
        ordering += `,${tempOrdering}`;
      }
    } else {
      ordering = getColumnOrdering(column);
    }
  });
  return ordering;
};

// Return the data-tables filters for the specified data type from the system's config.
export const getConfigFilters = (portal, dataType, subType) => {
  const config = getDataTableConfig(portal);
  return {
    ...config.initialFilters,
    ...getFilters(config, dataType, subType),
    ordering: buildOrdering(portal, dataType, subType)
  };
};

export const getFieldInfo = (key, data) => {
  const path = key.split('.');
  return R.pathOr(enDash, path, data);
};

export const getEmptyLabel = dataType => {
  if (includes(getEntitiesListConfig(), dataType) || dataType === 'group') {
    const label = getEntityTypeLabel(dataType);
    return `No ${label}s to show`;
  } else
    if (dataType === 'overlap') {
      return 'No overlaps to show';
    } else
      if (dataType === 'batch') {
        return 'No batch upload history';
      }
  return null;
};

// Return true if the row can be edited.
export const isRowEditable = (dataType, subType, row) => {
  if (dataType === 'overlap') {
    // Overlap rows are always enabled for edit, since all users
    // can access the overlap details page.
    return true;
  }
  if (dataType === 'batch') {
    // For batch we defined permissions for the 'batchfile' model:
    return canEditEntities(null, 'batchfile');
  }
  if (dataType === 'group' || dataType === 'overlap') {
    // For groups and overlaps, we use a sub-type:
    if (dataType === 'overlap') {
      return canEditEntities(null, subType);
    }
    if (dataType === 'group') {
      // Only let the user access the edit page, if they have
      // edit access to that group type.
      // However if 'roedit' is set for that type, let him
      // pass (the edit page will display the data in
      // read-only mode).
      return canEditGroups(subType) || isRoedit(subType);
    }
  }
  const { agency_id } = row;
  return canViewEntityPage(agency_id, dataType);
};

export const onRowClick = (event, row, dataType, subType, dispatch) => {
  const { id } = row;

  if (!isRowEditable(dataType, subType, row)) {
    return;
  }

  let url = `/${dataType}/${id}`;
  if (subType) {
    url = `/${dataType}/${subType}/${id}`;
  }
  if (dataType === 'activity') {
    const { cycle_id, task } = row;
    url = `/cycle/${cycle_id}/task/${task}/activity/${id}/${subType}`;
  }
  if (dataType === 'task') {
    const { cycle_id } = row;
    url = `/cycle/${cycle_id}/task/${id}`;
  }
  if (isAdminDataType(dataType)) {
    url = `/admin/${dataType}/${id}`;
  }
  // Simulate CTRL or SHIFT CLICK behavior. It might not work on all browsers.
  if (event.ctrlKey) {
    // Simulate CTRL-CLICK behavior on links (open link in a new tab):
    // (works on Chrome and Firefox).
    const win = window.open(url);
    win.focus();
  } else
    if (event.shiftKey) {
      // Simulate SHIFT-CLICK behavior on links (open link in a new window):
      // (works on Chrome, but on Firefox, which opens it on a new tab).
      window.open(url);
    } else {
      // Else, go to the specified link in the current browser window.
      const source = location.pathname || `/library/entity/${dataType}`;
      dispatch(push({ pathname: url, state: { clear: true, source } }));
    }
};
