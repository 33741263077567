import React from 'react';
import * as R from 'ramda';
import Typography from '@material-ui/core/Typography';
import CheckIcon from '@material-ui/icons/Check';
import { ListItemIcon, Select } from '@mui';
import { getConfigPathOr } from '@utils/config-utils';
import { isNumeric } from '@utils/data-detail-utils';
import { getConfigFilters } from '@utils/data-table-utils';
import { getPortalList } from '@utils/dashboard-utils';

const { MenuItem } = Select;

// Converts the specified category to integer, or leave it unchanged if it's not a number
// (i.e. it's a "(blank)/TBD" category name).
export const categoryToInt = item => isNumeric(item) ? parseInt(item, 10) : item;

// Builds a MenuItem for filters:
export const buildFilterMenuItem = (checked, getPrimaryTextIcon, item) => {
  const icon = getPrimaryTextIcon ? getPrimaryTextIcon(item) : null;
  return (
    <MenuItem key={item.id} value={item.id}>
      <ListItemIcon>{checked && <CheckIcon fontSize="small" />}</ListItemIcon>
      {icon && <ListItemIcon>{icon}</ListItemIcon>}
      <Typography variant="body2">{item.name}</Typography>
    </MenuItem>
  );
};

export const isMapPage = location => {
  if (location && location.pathname) {
    const path = location.pathname.split('/');
    if (path.length > 1 && path[1] === 'map') {
      return true;
    }
  }
  return false;
};

// Return the first filter name from a list of filters:
const getFirstFilter = filters => {
  if (filters && filters.ordering) {
    const parts = filters.ordering.split(',');
    if (parts && parts.length > 0) {
      return parts[0];
    }
  }
  return null;
};

// Builds a new filter list, moving the filter we want to take
// precedence to the first place.
export const setFirstFilter = (firstFilter, filters) => {
  if (filters && filters.ordering) {
    const parts = filters.ordering.split(',');
    if (parts && parts.length > 0) {
      const newFilterList = [firstFilter];
      const absFilter = firstFilter.replace(/^-/, '');
      parts.forEach(part => {
        if (part !== absFilter && part !== `-${absFilter}`) {
          newFilterList.push(part);
        }
      });
      return newFilterList.join(',');
    }
  }
  return firstFilter;
};

// Like setFirstFilter(), but moves the specified filter first,
// without changing the direction.
export const activateFilter = (firstFilter, filters) => {
  if (filters && filters.ordering) {
    const parts = filters.ordering.split(',');
    if (parts && parts.length > 0) {
      let activeFilter = null;
      const newFilterList = [];
      const absFilter = firstFilter.replace(/^-/, '');
      parts.forEach(part => {
        if (part !== absFilter && part !== `-${absFilter}`) {
          newFilterList.push(part);
        } else {
          activeFilter = part;
        }
      });
      return [activeFilter, ...newFilterList].join(',');
    }
  }
  return firstFilter;
};

// Returns true if between the filters, the first main one is
// the one specified in the 'ordering' parameter (i.e. it's the current filter).
export const isCurrentFilter = (filters, ordering) => {
  const firstFilter = getFirstFilter(filters);
  return firstFilter === ordering || firstFilter === `-${ordering}`;
};

// Returns true if the filter is an ascending one.
export const isFilterAscending = (filters, ordering) => {
  if (filters && filters.ordering) {
    const parts = filters.ordering.split(',');
    if (parts && parts.length > 0) {
      const filter = parts.find(part => ordering === part.replace(/^-/, ''));
      return filter && filter[0] !== '-';
    }
  }
  return false;
};

// Upon config retrieval build the data-table filters for use in the store.
export const buildAllPortalFilters = () => {
  const filters = {};

  // Build it for all data types on all portals:
  getPortalList().forEach(portal => {
    const config = getConfigPathOr(['dashboard', portal, 'datatable', 'config']);
    const entities = getConfigPathOr(['dashboard', portal, 'datatable', 'columns']);

    Object.keys(entities).forEach(key => {
      const entity = entities[key];
      if (Array.isArray(entity)) {
        // Check if the config contains that data type:
        const filterExists = R.propOr(null, key, config);
        if (filterExists) {
          filters[key] = getConfigFilters(portal, key);
        }
      } else {
        // If the columns entry is not an array, it means it has subtypes.
        Object.keys(entity).forEach(subKey => {
          const filterExists = R.pathOr(null, [key, subKey], config);
          if (filterExists) {
            if (!filters[key]) {
              filters[key] = {};
            }
            filters[key][subKey] = getConfigFilters(portal, key, subKey);
          }
        });
      }
    });
  });

  return filters;
};

// Process the 'attributes' filter before issuing the API call with the filters.
export const processAttributes = (filters = {}) => {
  const processedFilters = {};
  const attrsFilters = [];
  Object.entries(filters).forEach(([key, value]) => {
    if (key.includes('attrs__')) {
      attrsFilters.push(`${key.split('__')[1]}|${value}`);
    } else {
      processedFilters[key] = value;
    }
  });
  if (attrsFilters.length) {
    processedFilters.attrs = attrsFilters.join(',');
  }
  return processedFilters;
};

// Convert categories to str, then split to convert it into an array.
// The string conversion is needed in case a single category is sent as a number.
const splitCategories = categories => String(categories).split(',');

// Category filter pre-processing.
const preProcessCategories = filters => ({
  ...filters,
  // Convert the '1,2,3,4' string filter values into an array:
  categories__in: splitCategories(filters.categories__in)
});

// Category filter post-processing.
const postProcessCategories = (entityType, filters) => {
  const newFilters = { ...filters };

  // Remove duplicates (by converting to Set()):
  newFilters.categories__in = [...new Set(newFilters.categories__in)];

  // Convert to string:
  newFilters.categories__in = newFilters.categories__in.join(',');

  return newFilters;
};

// Category filters are split by category type (i.e. 'categories__in|priority',
// 'categories__in|status', etc), this is required in order to group filters
// by category within the UI code.
//
// But when we need to use those filters to issue a backend API call, we need a
// single 'categories__in' parameter.
//
// This method takes care of squeezing all those 'categories__in|xxxx' filters
// into a single 'categories__in' one.
export const processCategories = (entityType, filters = {}) => {
  const newFilters = {};

  // Squeeze the 'categories__in|xxxx' filters into a single 'categories__in' filter:
  Object.keys(filters).forEach(key => {
    if (key.startsWith('categories__in|')) {
      if (newFilters.categories__in) {
        newFilters.categories__in = `${newFilters.categories__in},${filters[key]}`;
      } else {
        newFilters.categories__in = filters[key];
      }
    } else {
      newFilters[key] = filters[key];
    }
  });

  if (newFilters.categories__in) {
    return postProcessCategories(entityType, preProcessCategories(newFilters));
  }

  return newFilters;
};
