// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.src-components-public-___top-bar__container{padding:1rem 1.5rem;background:#fff;box-shadow:0px 1px 3px rgba(0,0,0,0.12);min-height:4rem;display:flex;flex-direction:row;align-items:center;justify-content:flex-start}.src-components-public-___top-bar__container .src-components-public-___top-bar__logo{height:3rem;width:auto}.src-components-public-___top-bar__container .src-components-public-___top-bar__separator{margin:0 1.5rem;align-self:stretch;width:1px;background:rgba(0,0,0,0.12)}.src-components-public-___top-bar__container .src-components-public-___top-bar__entity-type{font-size:1.375rem;font-weight:400;line-height:1.75rem;color:#212121}
`, "",{"version":3,"sources":["webpack://./src/components/public/top-bar.scss"],"names":[],"mappings":"AAGA,6CACE,mBAAoB,CACpB,eAAgB,CAChB,uCAA2C,CAC3C,eAAgB,CAChB,YAAa,CACb,kBAAmB,CACnB,kBAAmB,CACnB,0BAA2B,CAR7B,qFAWI,WAAY,CACZ,UAAW,CAZf,0FAgBI,eAAgB,CAChB,kBAAmB,CACnB,SAAU,CACV,2BAA+B,CAnBnC,4FAuBI,kBAAmB,CACnB,eAAgB,CAChB,mBAAoB,CACpB,aAAc","sourcesContent":["@import '../../styles/vars';\n@import '../../styles/mixins';\n\n.container {\n  padding: 1rem 1.5rem;\n  background: #fff;\n  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.12);\n  min-height: 4rem;\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  justify-content: flex-start;\n\n  .logo {\n    height: 3rem;\n    width: auto;\n  }\n\n  .separator {\n    margin: 0 1.5rem;\n    align-self: stretch;\n    width: 1px;\n    background: rgba(0, 0, 0, 0.12);\n  }\n\n  .entity-type {\n    font-size: 1.375rem;\n    font-weight: 400;\n    line-height: 1.75rem;\n    color: #212121;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `src-components-public-___top-bar__container`,
	"logo": `src-components-public-___top-bar__logo`,
	"separator": `src-components-public-___top-bar__separator`,
	"entity-type": `src-components-public-___top-bar__entity-type`,
	"entityType": `src-components-public-___top-bar__entity-type`
};
export default ___CSS_LOADER_EXPORT___;
