import React, { memo, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { runConfirmedAction, closeConfirmationDialog } from '@actions/confirmation-actions';
import Button from '@material-ui/core/Button';
import Dialog from '@shared/dialogs/dialog';
import DialogActions from '@shared/dialogs/dialog-actions';

const ActionConfirmationDialog = () => {
  const dispatch = useDispatch();

  const { visible, action, title, question, params } = useSelector(state => state.confirmation);
  const { confirmationType } = params ? params : {};

  const onClose = useCallback(
    () => {
      dispatch(closeConfirmationDialog());
    },
    [dispatch]
  );

  const onSubmit = useCallback(
    () => {
      if (action) {
        dispatch(runConfirmedAction(action));
      }
      dispatch(closeConfirmationDialog());
    },
    [action, dispatch]
  );

  if (!visible) {
    return null;
  }

  return (
    <Dialog
      actions={
        action ? (
          <DialogActions
            cancelLabel={confirmationType === 'discard' ? 'CANCEL' : 'NO'}
            onCancel={onClose}
            onSubmit={onSubmit}
            submitColor="secondary"
            submitLabel={confirmationType === 'discard' ? 'DISCARD' : 'YES'}
          />
        ) : (
          <Button onClick={onClose}>OK</Button>
        )
      }
      title={title}
    >
      {question}
    </Dialog>
  );
};

export default memo(ActionConfirmationDialog);
