import React from 'react';
import PropTypes from 'prop-types';
import { colors } from '@constants/colors';
import Avatar from '@material-ui/core/Avatar';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  tiny: {
    backgroundColor: colors.neutral.white,
    border: '1px solid #EBEBEB',
    color: colors.opacity.black0_6,
    fontFamily: 'Roboto, sans-serif',
    fontSize: 10,
    width: theme.spacing(3),
    height: theme.spacing(3)
  }
}));

const CountAvatar = ({ count }) => {
  const classes = useStyles();
  return (
    <Avatar className={classes.tiny}>
      {count}
    </Avatar>
  );
};

CountAvatar.propTypes = {
  count: PropTypes.string
};

export default CountAvatar;
