/* eslint-disable react/jsx-no-bind */
import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import { showConfirmationDialog } from '@actions/confirmation-actions';
import { openDashboardDialog } from '@actions/dashboard-actions';
import { getConfirmableDeleteAndNavigateAction } from '@actions/data-detail-actions';
import * as colors from '@constants/colors';
import * as dialog from '@constants/dialogs';
import IconMenu from '@shared/icon-menu';
import IconMenuItem from '@shared/icon-menu-item';
import { getDashboardBackURL } from '@utils/navigation-utils';
import { canDeleteEntities } from '@utils/permission-utils';

const TopBarActionTaskTypeMenu = () => {
  const { data } = useSelector(state => state.dataDetail);
  const location = useLocation();
  const dispatch = useDispatch();
  const { id } = data || {};
  const openDialog = useCallback(
    () => dispatch(openDashboardDialog(dialog.TASK_TYPE_DISCARD, { id })),
    [dispatch, id]
  );

  if (data) {
    const { agency } = data;

    const deleteItem = () => {
      const source = getDashboardBackURL(location, 'task_type');
      dispatch(showConfirmationDialog(
        'Delete task template?',
        `Are you sure you want to delete task template "${data.name}"?`,
        getConfirmableDeleteAndNavigateAction('task_type', 'task_type', data.id, source)
      ));
    };

    const deleteEnabled = () => {
      // Datatype is actually named 'tasktype' in the permissions list:
      if (canDeleteEntities(agency, 'tasktype')) {
        // If we have permissions to delete, only allow deletion on initial
        // drafts (i.e. version 1):
        return data.draft && data.version === 1;
      }
      return false;
    };

    const isDeleteEnabled = deleteEnabled();
    const isTaskTypeDiscard = data.draft && data.version > 1;

    if (isDeleteEnabled || isTaskTypeDiscard) {
      return (
        <IconMenu iconButtonStyles={{ padding: 0, margin: '0 0 0 1rem' }}>
          {isDeleteEnabled && (
            <IconMenuItem key="delete" icon={<DeleteOutlineIcon htmlColor={colors.dotmapsRed} />} onClick={() => deleteItem()}>
              <div style={{ color: colors.dotmapsRed }}>DELETE</div>
            </IconMenuItem>
          )}
          {isTaskTypeDiscard && (
            <IconMenuItem key="delete-task-type" icon={<DeleteOutlineIcon htmlColor={colors.dotmapsRed} />} onClick={() => openDialog()}>
              <div style={{ color: colors.dotmapsRed }}>Discard unpublished changes</div>
            </IconMenuItem>
          )}
        </IconMenu>
      );
    }
  }
  return null;
};

export default TopBarActionTaskTypeMenu;
