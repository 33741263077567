import React from 'react';
import PropTypes from 'prop-types';
import { colors } from '@constants/colors';
import Avatar from '@material-ui/core/Avatar';
import { makeStyles } from '@material-ui/core/styles';
import { Icon } from '@mui';

const useAvatarStyles = makeStyles(theme => ({
  small: {
    backgroundColor: colors.coordinate.blue,
    fontSize: '0.875rem',
    width: theme.spacing(3),
    height: theme.spacing(3)
  },
  medium: {
    backgroundColor: colors.coordinate.blue,
    fontSize: '1rem',
    width: theme.spacing(4),
    height: theme.spacing(4)
  },
  large: {
    backgroundColor: colors.coordinate.blue,
    fontSize: '1.25rem',
    width: theme.spacing(5),
    height: theme.spacing(5)
  },
  giant: {
    backgroundColor: colors.coordinate.blue,
    fontSize: '2.5rem',
    width: theme.spacing(10),
    height: theme.spacing(10)
  }
}));

const useIconStyles = makeStyles({
  small: { fontSize: '1rem' },
  medium: { fontSize: '1.125rem' },
  large: { fontSize: '1.5rem' },
  giant: { fontSize: '2.75rem' }
});

const NoUserIcon = ({ size = 'medium' }) => {
  const avatarClasses = useAvatarStyles();
  const iconClasses = useIconStyles();
  return (
    <Avatar className={avatarClasses[size]}>
      <Icon className={iconClasses[size]} color={colors.neutral.white}>location_on</Icon>
    </Avatar>
  );
};

NoUserIcon.propTypes = {
  size: PropTypes.string
};

export default NoUserIcon;
