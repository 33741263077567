/* eslint-disable react/jsx-no-bind */
import React, { useContext } from 'react';
import { useDispatch } from 'react-redux';
import DetailsContext from '@components/workflow/details-context';
import CommentAvatarInput from '@shared/comments/comment-avatar-input';
import { logTaskActivityComment } from '@actions/logs-actions';
import { filterCommentUsers } from '@utils/workflow-utils';

const CommentsInput = () => {
  const dispatch = useDispatch();
  const { activity, task } = useContext(DetailsContext);

  const onComment = (comment, mentions) => {
    const payload = {
      id: activity.id,
      comment,
      mentions
    };
    dispatch(logTaskActivityComment(payload));
  };

  const filterUsers = users => filterCommentUsers(users, task, activity);

  return <CommentAvatarInput filterUsers={filterUsers} onComment={onComment} />;
};

export default CommentsInput;
