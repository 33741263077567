import React from 'react';
import PropTypes from 'prop-types';
import SaveAltIcon from '@material-ui/icons/SaveAlt';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';

const ExportButton = ({ onClick }) => (
  <IconButton onClick={onClick}>
    <Tooltip title="Export">
      <SaveAltIcon />
    </Tooltip>
  </IconButton>
);

ExportButton.propTypes = {
  onClick: PropTypes.func
};

export default ExportButton;
