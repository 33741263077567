import React, { Fragment } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import styles from './top-bar.scss';

const TopBar = () => {
  const { dataType } = useParams();
  const { cityName, details, publicLogo, publicLogoLink } = useSelector(state => state.config);
  const title = details?.[dataType]?.form.title.label;
  return (
    <div className={styles.container}>
      {
        publicLogo &&
        <Fragment>
          {publicLogoLink ?
            <a href={publicLogoLink} rel="noopener noreferrer" target="_blank">
              <img src={publicLogo} className={styles.logo} alt={cityName} />
            </a> :
            <img src={publicLogo} className={styles.logo} alt={cityName} />
          }
          <span className={styles.separator} />
        </Fragment>
      }
      <span className={styles.entityType}>{title}</span>
    </div>
  );
};

export default TopBar;
