import React from 'react';
import TopBarActions from './top-bar-actions';
import TopBarDescription from './top-bar-description';
import TopBarBackButton from './top-bar-back-button';
import TopBarTitle from './top-bar-title';
import TopBarTypeIcon from './top-bar-type-icon';
import './top-bar.scss';

const TopBar = () => (
  <div styleName="top-bar-container">
    <div styleName="heading">
      <TopBarBackButton />
      <TopBarTypeIcon />
      <TopBarTitle />
      <TopBarActions />
    </div>
    <TopBarDescription />
  </div>
);

export default TopBar;
