import React, { Fragment, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import { openDashboardDialog } from '@actions/dashboard-actions';
import { getEntityTypeLabel } from '@constants/config';
import * as dialog from '@constants/dialogs';
import { isEntity } from '@constants/endpoints';
import Skeleton from '@material-ui/lab/Skeleton';
import './top-bar.scss';

const TopBarTitle = () => {
  const dispatch = useDispatch();
  const { dataType, id } = useParams();
  const { data } = useSelector(state => state.dataDetail);
  const { cycle } = useSelector(state => state.workflow);
  const dataTypeDisplayName = getEntityTypeLabel(dataType);
  const openDialog = useCallback(
    () => dispatch(openDashboardDialog(dialog.NAME_DESCRIPTION, { dataType, id, portal: 'admin' })),
    [dataType, dispatch, id]
  );
  const hasEditIcon = data && (dataType === 'task_type' || dataType === 'workflow');
  const label = dataType === 'cycle' ? cycle?.name : data?.name;
  return (
    <div styleName="title">
      {id && (
        <Fragment>
          <div styleName="header">
            {label && <div styleName="header-text">{label}</div>}
            {hasEditIcon && (
              <div styleName="header-icon">
                <EditOutlinedIcon
                  htmlColor="#616161"
                  onClick={openDialog}
                  style={{ fontSize: '1.375rem', padding: '0 0 0 1rem' }}
                />
              </div>
            )}
            {!label && <Skeleton width="100%" />}
          </div>
          {isEntity(dataType) && (
            <div styleName="subtitle">
              ID {data && data.id}
            </div>
          )}
          {dataType === 'cycle' && cycle && (
            <div styleName="subtitle">
              {cycle.workflow_name}
            </div>
          )}
        </Fragment>
      )}
      {!id && (
        <div styleName="header">
          Create new {dataTypeDisplayName}
        </div>
      )}
    </div>
  );
};

export default TopBarTitle;
