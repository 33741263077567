import React from 'react';

const PdfIcon = () => (
  <svg width="1.5rem" height="1.5rem" viewBox="0 0 24 24">
    <defs>
      <path
        d={`
            M7 11.5h1v-1H7v1zM19 3H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9
            2-2V5c0-1.1-.9-2-2-2zm-9.5 8.5c0 .83-.67 1.5-1.5 1.5H7v2H5.5V9H8c.83 0
            1.5.67 1.5 1.5v1zm10-1H17v1h1.5V13H17v2h-1.5V9h4v1.5zm-5 3c0 .83-.67
            1.5-1.5 1.5h-2.5V9H13c.83 0 1.5.67 1.5 1.5v3zm-2.5 0h1v-3h-1v3z
           `}
        id="pdf-icon"
      />
    </defs>
    <use fill="#ED3620" fillRule="nonzero" xlinkHref="#pdf-icon" />
  </svg>
);

export default PdfIcon;
