import React from 'react';
import * as R from 'ramda';
import { isEmpty } from 'lodash';  // lodash's isEmpty checks for 'undefined', the Ramda one doesn't.
import polylabel from '@mapbox/polylabel';
import { getGroupTypesConfig, getMenuItemConfig } from '@constants/config';
import { generateBounds } from '@utils/map-utils';
import { formatDate, getListOfIds } from '@utils/shared-utils';
import './group-utils.scss';

export const prepareGroupsForBulkAdd = ({ selectedGroups, entityIds, includeOverlaps }) => ({
  groups: [...selectedGroups].reduce((groups, groupId) => ({...groups, [groupId]: entityIds}), {}),
  includeOverlaps
});

export const prepareGroupData = ({ group }) => {
  const shape = R.path(['auto_area', 'shape'], group);
  let start_date = null;
  let end_date = null;
  if (shape) {
    if (R.path(['auto_area', 'start_date'], group)) {
      start_date = group.auto_area.start_date;
      end_date = group.auto_area.end_date;
    }
  }
  return {
    description: group.description,
    external_id: group.external_id,
    id: group.id,
    status: group.status,
    name: group.name,
    owner: group.owner && group.owner.id,
    type: group.type,
    ...(group.entities ? { entities: getListOfIds(group.entities) } : {}),
    ...(shape ? { auto_area: { start_date, end_date, shape } } : { auto_area: null })
  };
};

export const validateGroupData = (group, groupType) => {
  const error = {};
  if (!group.name) {
    error.name = ['Please provide a group name'];
  }
  if (groupType === 'boundary' && !group.owner) {
    error.owner = ['Please select an owner for this group'];
  }
  if (!group.status) {
    error.status = ['Please select a status'];
  }
  if (!isEmpty(error)) {
    return error;
  }
  return null;
};

export const getGroupDateRange = group => {
  if (R.path(['auto_area', 'start_date'], group)) {
    const { start_date, end_date } = group.auto_area;
    return `${formatDate(start_date)} - ${formatDate(end_date)}`;
  }
  return false;
};

const getGroupEntitiesSegments = entities => {
  if (entities) {
    const segments = entities.map(entity => entity && entity.segments);
    return [].concat(...segments);  // Flatten segments.
  }
  return [];
};

const shape2Segment = coordinates => {
  return [
    {
      shape: {
        coordinates,
        type: 'LineString'
      }
    }
  ];
};

export const generateGroupBounds = data => {
  const coordinates = R.pathOr(null, ['auto_area', 'shape', 'coordinates', 0], data);

  if (coordinates) {
    // Convert the shape into a segment, to be able to use it as
    // a parameter to fitBounds():
    return shape2Segment(coordinates);
  }

  // If there's no shape, zoom it to include the full extent of all entities in this group:
  return getGroupEntitiesSegments(data.entities);
};

const positionAreaLabel = ({shape: {coordinates}}) => polylabel(coordinates, 0.00005);

export const optimizeGroupsForMap = groups => groups.map(group => {
  const area = R.pathOr(null, ['auto_area'], group);
  return {
    ...group,
    autoArea: group.auto_area,
    bounds: area && area.shape ? generateBounds(area).toJSON() : null,
    center: area && area.shape ? positionAreaLabel(area) : null
  };
});

const renderValueWithPrefix = (value, index, prefixes) => {
  if (prefixes && prefixes[index]) {
    return `${prefixes[index]}: ${value}`;
  }
  return value;
};

export const renderGroupBullFields = (group, fields, prefixes) => {
  return fields.map((field, index) => {
    const value = Array.isArray(field) ? R.pathOr('', field, group) : group[field];
    const isNotLast = index < fields.length - 1;
    return (
      <div styleName="group-bull-field" key={index}>
        {renderValueWithPrefix(value, index, prefixes)}
        {isNotLast && <div styleName="group-bull-field-bull">&bull;</div>}
      </div>
    );
  });
};

// Render a placeholder value for the Autocomplete control for groups.
export const getGroupAutocompletePlaceholder = (groups, groupType) => {
  // Use a different placeholder label if it's in shrinked state:
  if (groups && groups.length > 0) {
    return `${groupType.label} group`;
  }
  return `Select ${groupType.name} group`;
};

export const renderGroupListItem = (group, existing) => {
  const { fields, prefixes } = getMenuItemConfig().group_type[group.type_name];
  const exists = existing.includes(group.id);
  return (
    <div styleName="group-list-item">
      <div styleName="heading">
        <div styleName="left">
          {renderGroupBullFields(group, fields, prefixes)}
        </div>
        {exists && <div styleName="right">Already in this group</div>}
      </div>
      <div styleName="title">
        {group.name}
      </div>
    </div>
  );
};

export const findGroupType = groupTypeId => getGroupTypesConfig().find(item => item.id === groupTypeId);

// Sort the specified list of groups by the defined group type order:
export const sortGroups = groups => {
  const groupTypesOrder = getGroupTypesConfig().map(item => ({ type: item.id, order: item.order }));
  const sortByOrder = R.sortBy(R.prop('order'));
  // Inject into the each group in the list the group type order:
  const injectGroupTypeOrder = R.map(group => R.mergeRight(group, groupTypesOrder.find(item => item.type === group.type)));
  const groupsWithOrder = injectGroupTypeOrder(groups);
  return sortByOrder(groupsWithOrder);
};
