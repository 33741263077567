/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable max-depth */
import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import { dotmapsLightBlue } from '@constants/colors';
import {
  openEntityTray,
  openGroupsTray,
  openLayerTray,
  popTray,
  setMapActiveTab
} from '@actions/map-actions';
import { mapTabsConfig } from '@constants/component-configs';
import { DETAIL_TRAY_TYPES } from '@constants/map-trays';
import Tooltip from '@material-ui/core/Tooltip';
import './vertical-tab.scss';

const VerticalTab = ({ icon, tooltip, value }) => {
  const dispatch = useDispatch();
  const { currentTab, trays, trayRecent } = useSelector(state => state.map);

  const onClick = useCallback(
    () => {
      // If we selected the details tab, open the last opened entity.
      if (value === mapTabsConfig.tabs.details.value) {
        // Check if there is a detail tray in the tray stack, if so just pop the tray stack
        if (trays.length > 1 && DETAIL_TRAY_TYPES.has(trays[trays.length - 2].trayType)) {
          dispatch(popTray());
        } else if (isEmpty(trayRecent)) {
          // Else if the recent list is empty, show the details tab, which
          // will show an "empty state" message.
          dispatch(setMapActiveTab(value));
        } else {
          // Else, open the last item from the recent list:
          const recent = trayRecent[0];
          if (recent) {
            if (recent.group) {
              dispatch(openGroupsTray({ id: recent.groupId, item: recent.group, position: recent.position }, true));
            }
            if (recent.layer) {
              dispatch(openLayerTray({ id: recent.layerId, type: recent.type }, true));
            }
            if (recent.entity) {
              dispatch(openEntityTray({ id: recent.entityId, type: recent.entity.type_name, position: recent.position, clearPrevious: true}));
            }
          }
        }
      } else {
        // Else switch to the selected tab.
        dispatch(setMapActiveTab(value));
      }
    },
    [dispatch, trayRecent, trays, value]
  );

  const getIcon = isSelected => {
    if (icon && React.isValidElement(icon)) {
      let options = {};
      if (isSelected) {
        options = {
          style: {
            color: dotmapsLightBlue
          }
        };
      }

      return React.cloneElement(icon, options);
    }
    return null;
  };

  const isSelected = currentTab === value;
  return (
    <Tooltip title={tooltip} placement="right">
      <div
        onClick={onClick}
        role="presentation"
        styleName={`vertical-tab ${isSelected ? 'selected' : ''}`}
      >
        {getIcon(isSelected)}
      </div>
    </Tooltip>
  );
};

VerticalTab.propTypes = {
  /**
   * Sets the icon of the tab, you can pass `FontIcon` or `SvgIcon` elements.
   */
  icon: PropTypes.object,
  onClick: PropTypes.func,
  /**
   * Defines if the current tab is selected or not.
   */
  selected: PropTypes.bool,
  tooltip: PropTypes.string,
  /**
   * Value of the tab (so it can be selected).
   */
  value: PropTypes.number
};

export default VerticalTab;
