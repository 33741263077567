import axios from 'axios';
import {
  TOOLTIP_FETCH_ERROR,
  TOOLTIP_FETCH_SUCCESS
} from '@constants/action-types';
import { getDetailAPIRequestUrl } from '@constants/endpoints';

export const fetchTooltipData = (dataType, subType, id) => {
  const format = subType ? `?type=${subType}` : '';
  const url = getDetailAPIRequestUrl(dataType, id, format);
  return dispatch => axios.get(url).then(
    payload => dispatch({ type: TOOLTIP_FETCH_SUCCESS, dataType, payload }),
    error => dispatch({ type: TOOLTIP_FETCH_ERROR, dataType, error, id })
  );
};
