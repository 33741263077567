import React, { useCallback, memo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { closeDashboardDialog } from '@actions/dashboard-actions';
import { updateDataFieldInline } from '@actions/data-detail-actions';
import { changeTaskStatus } from '@actions/workflow-actions';
import * as dialog from '@constants/dialogs';
import Dialog from '@shared/dialogs/dialog';
import DialogActions from '@shared/dialogs/dialog-actions';

const TransitionDialog = () => {
  const { activeDialogs, dialogParams } = useSelector(state => state.dashboard);
  const { activityId, fieldPrefix, isLate, statusName } = dialogParams[dialog.TASK_STATUS] || {};
  const isDialogActive = activeDialogs[dialog.TASK_STATUS];
  const title = `Transition task to "${statusName}"`;
  const dispatch = useDispatch();

  const onClose = useCallback(
    () => {
      dispatch(closeDashboardDialog(dialog.TASK_STATUS));
    },
    [dispatch]
  );

  const onSubmit = useCallback(
    () => {
      const fieldPath = fieldPrefix ? `${fieldPrefix}.status_name` : 'status_name';
      dispatch(updateDataFieldInline(fieldPath, statusName));
      dispatch(changeTaskStatus(activityId, statusName, isLate));
    },
    [activityId, dispatch, fieldPrefix, isLate, statusName]
  );

  if (!isDialogActive) {
    return null;
  }

  return (
    <Dialog
      actions={
        <DialogActions
          onCancel={onClose}
          onSubmit={onSubmit}
          submitLabel="YES"
        />
      }
      title={title}
    >
      {statusName === 'Completed' && <span>Tasks in a "Completed" status cannot be edited.</span>}
      {statusName === 'Submitted' && (
        <span>
          We will send a notification to the task owner immediately to review the response.
          <br />
          <br />
          Response cannot be edited when the task is in a "Submitted" status.
        </span>
      )}
    </Dialog>
  );
};

export default memo(TransitionDialog);
