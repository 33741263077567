/* eslint-disable react/jsx-no-bind */
import React, { memo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toggleLegend } from '@actions/map-actions';
import { textColor, dotmapsBlue } from '@constants/colors';
import MapToolbarIconButton from '@shared/map-toolbar-icon-button';
import './legend-icon.scss';

const LegendIcon = () => {
  const { legendOpen } = useSelector(state => state.map);
  const dispatch = useDispatch();

  const onClick = () => dispatch(toggleLegend());

  return (
    <div data-testid="map-legend-icon" styleName="map-legend-icon">
      <MapToolbarIconButton
        onClick={onClick}
        color={legendOpen ? dotmapsBlue : textColor}
        icon="info_outlined"
        tooltip="Legend"
      />
    </div>
  );
};

export default memo(LegendIcon);
