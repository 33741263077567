// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.src-components-portal-table-___batch-upload-stepper__stepper{border-right:1px solid rgba(0,0,0,0.12);min-width:12.5rem;width:12.5rem}
`, "",{"version":3,"sources":["webpack://./src/components/portal/table/batch-upload-stepper.scss"],"names":[],"mappings":"AAAA,8DACE,uCAA2C,CAC3C,iBAAkB,CAClB,aAAc","sourcesContent":[".stepper {\n  border-right: 1px solid rgba(0, 0, 0, 0.12);\n  min-width: 12.5rem;\n  width: 12.5rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"stepper": `src-components-portal-table-___batch-upload-stepper__stepper`
};
export default ___CSS_LOADER_EXPORT___;
