/* eslint-disable react/jsx-no-bind */
import React from 'react';
import PropTypes from 'prop-types';
import {
  FormControl,
  FormHelperText,
  Input,
  InputLabel,
  Select as MUISelect
} from '@material-ui/core';

import * as R from 'ramda';

import { makeStyles } from '@material-ui/core/styles';

import { colors } from '@constants/colors';
import { fonts } from '@constants/fonts';
import { SAFE_ZINDEX } from '@constants/mui-theme';

import { Progress } from '@mui';

import MenuItem from './MenuItem';

const getInputLabel = ({ floatingLabelFixed = true }) => {
  return floatingLabelFixed
    ? { position: 'unset', color: colors.neutral.gray }
    : {
      color: colors.neutral.gray,
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      bottom: '0px',
      maxWidth: '100%'
    };
};

const getInputLabelShrink = ({ floatingLabelFixed = true }) => {
  return floatingLabelFixed
    ? { transform: 'unset', fontSize: fonts.sm }
    : {
      fontSize: fonts.sm,
      overflow: 'unset',
      textOverflow: 'unset',
      whiteSpace: 'unset'
    };
};

const useStyles = makeStyles(theme => ({
  inputRoot: {
    fontSize: fonts.md
  },
  inputUnderline: {
    '&:before': {
      borderBottom: `1px solid ${colors.neutral.silver}`
    },
    '&:after': {
      borderBottom: `1px solid ${colors.neutral.silver}`
    }
  },
  inputLabel: getInputLabel,
  inputLabelShrunk: getInputLabelShrink,
  inputLabelFocused: {
    color: `${colors.neutral.gray} !important`
  },
  inputLabelError: {
    color: `${theme.palette.error.main} !important`
  },
  helperText: {
    height: 0,
    marginTop: 0
  },
  formControl: {
    'label + &': {
      marginTop: '0'
    }
  },
  menuPaper: {
    maxHeight: '25rem'
  }
}));

const defaultStyle = { width: '100%' };

export const Select = props => {
  const classes = useStyles(R.omit(['classes'], props));

  const {
    style,
    helperText,
    multiple,
    input,
    disabled,
    loading = false,
    error = false,
    fullWidth = true,
    floatingLabelFixed = true,
    InputLabelProps = {},
    InputProps = {},
    FormControlProps = {},
    MenuProps = {},
    variant = 'standard',
    ...data
  } = props;

  return (
    <div style={{ ...defaultStyle, ...style }}>
      <FormControl fullWidth={fullWidth} error={error} {...FormControlProps}>
        <InputLabel
          {...InputLabelProps}
          variant={variant}
          classes={{
            root: classes.inputLabel,
            shrink: classes.inputLabelShrunk,
            focused: classes.inputLabelFocused,
            error: classes.inputLabelError,
            ...InputLabelProps?.classes
          }}
          shrink={floatingLabelFixed}
        >
          {props.label}
        </InputLabel>
        <MUISelect
          {...data}
          multiple={multiple}
          disabled={disabled || loading}
          IconComponent={
            loading
              ? iconProps => <Progress {...iconProps} size={16} />
              : props.IconComponent
          }
          MenuProps={{
            ...MenuProps,
            anchorOrigin: {
              horizontal: 'left',
              vertical: 'bottom'
            },
            classes: {
              paper: classes.menuPaper,
              ...MenuProps?.classes
            },
            style: SAFE_ZINDEX,
            ...(multiple && {
              // stops popover from changing position on select
              getContentAnchorEl: null
            })
          }}
          input={
            input || (
              <Input
                {...InputProps}
                classes={{
                  root: classes.inputRoot,
                  underline: classes.inputUnderline,
                  formControl: classes.formControl,
                  ...InputProps?.classes
                }}
              />
            )
          }
        />
        {helperText && (
          <FormHelperText
            {...props.FormHelperTextProps}
            classes={{
              root: classes.helperText,
              ...props.FormHelperTextProps?.classes
            }}
          >
            {helperText}
          </FormHelperText>
        )}
      </FormControl>
    </div>
  );
};

Select.MenuItem = MenuItem;

Select.propTypes = {
  FormControlProps: PropTypes.object,
  FormHelperTextProps: PropTypes.object,
  IconComponent: PropTypes.object,
  InputLabelProps: PropTypes.object,
  InputProps: PropTypes.object,
  MenuProps: PropTypes.object,
  disabled: PropTypes.bool,
  error: PropTypes.bool,
  floatingLabelFixed: PropTypes.bool,
  fullWidth: PropTypes.bool,
  helperText: PropTypes.string,
  input: PropTypes.node,
  label: PropTypes.string,
  loading: PropTypes.bool,
  multiple: PropTypes.bool,
  style: PropTypes.object,
  variant: PropTypes.string
};

export default Select;
