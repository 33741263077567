import NewBaseListItem from './base-list-item';

class LayerListItem extends NewBaseListItem {
  getTitleName = () => this.props.name;
  getTypeIconName = () => 'layers';
  getEntityTypeName = () => 'layer';
  getContent = () => null;
  getFooter = () => null;
  onClick = () => {
    const { id, segments } = this.props;
    this.props.onClick('layer', id, segments);
  };
}

export default LayerListItem;
