import React from 'react';
import PropTypes from 'prop-types';
import './section.scss';

const Section = ({ children, name }) => (
  <div data-testid="workflow-section" styleName="section">
    <div styleName="section-header">
      {/* This nesting is needed, since other sections (like attachments and linked entities)
          may have a count and action DIVs. */}
      <div styleName="section-header-title">
        <div data-testid="workflow-section-header-title-text" styleName="section-header-title-text">
          {name}
        </div>
      </div>
    </div>
    <div styleName="section-field-list">
      {children}
    </div>
  </div>
);

Section.propTypes = {
  children: PropTypes.node,
  name: PropTypes.string
};

export default Section;
