import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment-timezone';
import Popover from '@material-ui/core/Popover';
import EventDetailsPopup from '@components/calendar/event-details-popup';
import { getConfig } from '@components/config/selectors';
import { calendarStyles } from '@constants/mui-theme';
import { getCalendarEventTypeItems } from '@selectors/calendar-selector';
import { getCalendarEventTypeKey } from '@utils/calendar-utils';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import './react-big-calendar-override.css';
import styles from './content.scss'; // eslint-disable-line

const localizer = momentLocalizer(moment);

const Content = ({ selectedDate, selectedView }) => {
  const [open, setOpen] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState({});
  const [anchorEl, setAnchorEl] = useState(null);
  const { data, filters } = useSelector(state => state.calendar);
  const config = useSelector(getConfig);
  const dataType = getCalendarEventTypeKey();
  const eventTypeMetadata = useSelector(state => getCalendarEventTypeItems(dataType)(state));

  const onSelectHandler = useCallback(
    (event, clickEvent) => {
      setOpen(true);
      setAnchorEl(clickEvent.target);
      setSelectedEvent(event);
    },
    [setAnchorEl, setOpen, setSelectedEvent]
  );

  const onClose = useCallback(() => setOpen(false), [setOpen]);

  const eventStyleGetter = useCallback(
    event => {
      const { attrs: { color } } = eventTypeMetadata[event.type];
      return { style: calendarStyles.event(color) };
    },
    [eventTypeMetadata]
  );

  const getEventTypeId = event => {
    if (event.category_dict) {
      const type = event.category_dict.find(category => category.name === dataType);
      if (type) {
        return type.id;
      }
    }
    return null;
  };

  const isEventTypeSelected = event => {
    const eventTypeValue = filters[dataType];
    const typeId = getEventTypeId(event);
    if (typeId) {
      return eventTypeValue.indexOf(String(typeId)) > -1;
    }
    return false;
  };

  const events = data.map(event => {
    if (isEventTypeSelected(event)) {
      // we get the dates in a string, but react-big-calendar requires them in date
      // the conversion uses a local timezone offset, which sets both dates a day early
      // so I'm adding an offset manually to counteract that
      const start_date = new Date(moment(event.start_date).utcOffset(8));
      const end_date = new Date(moment(event.end_date));
      const typeId = getEventTypeId(event);
      return {
        id: event.id,
        title: event.name,
        start: start_date,
        end: end_date,
        type: typeId,
        typeName: eventTypeMetadata[typeId].name,
        address: event.segments.length > 0 ? event.segments[0].display_from : 'N/A',
        agency: 'Special Events'
      };
    }
    return {};
  });

  const min = moment.tz('00:00:00', 'HH:mm:ss', config.timezoneName).toDate();
  const max = moment.tz('23:59:59', 'HH:mm:ss', config.timezoneName).toDate();
  const formats = {
    dayFormat: 'ddd D'
  };
  return (
    // onView and onNavigate are required, but because we're not using the default
    // toolbar, they don't actually work. The logic is handled in the base calendar
    // component. setting both handlers to an empty function to avoid js warnings
    <div styleName="styles.content">
      <Calendar
        date={selectedDate}
        events={events}
        eventPropGetter={eventStyleGetter}
        formats={formats}
        localizer={localizer}
        max={max}
        min={min}
        onSelectEvent={onSelectHandler}
        popup
        popupOffset={16}
        toolbar={false}
        view={selectedView}
      />
      <Popover
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{horizontal: 'middle', vertical: 'center'}}
        targetOrigin={{horizontal: 'left', vertical: 'center'}}
        onClose={onClose}
      >
        <EventDetailsPopup selectedEvent={selectedEvent} windowClose={onClose} />
      </Popover>
    </div>
  );
};

Content.propTypes = {
  selectedDate: PropTypes.object,
  selectedView: PropTypes.string
};

export default Content;
