import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Toggle from 'material-ui/Toggle';
import { toggleMapFilterToggle } from '@actions/map-actions';
import {
  dotmapsLightGrayText,
  textColor
} from '@constants/colors';
import { filterStyles, listItemStyles } from '@constants/mui-theme';
import { Icon, IconButton } from '@mui';
import { isAnyMapItemLoading } from '@selectors/map-selector';
import './filter.scss';

export class BaseTrayFilter extends Component {
  state = { open: false };

  toggleOpen = () => this.setState({open: !this.state.open});

  getAction = () => (
    <IconButton
      onClick={this.toggleOpen}
      style={listItemStyles.filterButtonStyle}
    >
      <Icon color={textColor}>
        {this.state.open ? 'expand_less' : 'expand_more'}
      </Icon>
    </IconButton>
  );

  isToggleEnabled = () => !this.props.loading;

  getToggleStyles = () => filterStyles.toggle;

  getToggle = () => (
    <Toggle
      {...this.getToggleStyles()}
      disabled={!this.isToggleEnabled()}
      onClick={this.onToggleClick}
      toggled={this.isFilterEnabled()}
    />
  );

  getIconStyle = () => 'icon';

  getIcon = () => {
    const iconName = this.getIconName();

    if (iconName) {
      return (
        <div styleName={this.getIconStyle()}>
          <Icon
            color={this.getIconColor()}
            style={{ fontSize: '1.5rem' }}
          >
            {iconName}
          </Icon>
        </div>
      );
    }

    return null;
  };

  getIconColor = () => dotmapsLightGrayText;

  isFilterEnabled = () => this.props.filterEnabled;

  getIconName = () => this.props.config.icon;

  getLabel = () => this.props.config.label;

  // Implement these in subclasses:
  getContent = () => {};
  onToggleClick = () => {};

  render() {
    const content = this.getContent();
    if (content.props.children.length === 0) {
      return (
        <div styleName="filter-container">
          <div styleName="row">
            <div styleName="toggle">
              {this.getToggle()}
            </div>
            {this.getIcon()}
            <div styleName="label">
              {this.getLabel()}
            </div>
          </div>
        </div>
      );
    }
    return (
      <div styleName="filter-container">
        <div styleName="row">
          <div styleName="toggle">
            {this.getToggle()}
          </div>
          {this.getIcon()}
          <div styleName="label">
            {this.getLabel()}
          </div>
          <div styleName="action">
            {this.getAction()}
          </div>
        </div>
        {this.state.open && (
          <div styleName="content">
            {this.getContent()}
          </div>
        )}
      </div>
    );
  }
}

BaseTrayFilter.propTypes = {
  config: PropTypes.object,
  filterEnabled: PropTypes.bool,
  loading: PropTypes.bool,
  toggleMapFilterToggle: PropTypes.func
};

export const baseMapStateToProps = state => {
  return { loading: isAnyMapItemLoading(state) };
};

export const baseMapDispatchToProps = { toggleMapFilterToggle };
