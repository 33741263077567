/* eslint-disable react/jsx-no-bind */
import React, { memo, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty } from 'lodash';
import { push } from 'connected-react-router';
import { closeDashboardDialog } from '@actions/dashboard-actions';
import { fetchDataType } from '@actions/data-types-actions';
import * as dialog from '@constants/dialogs';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Dialog from '@shared/dialogs/dialog';
import DialogActions from '@shared/dialogs/dialog-actions';
import SelectField from '@shared/select-field';
import {
  getLatestPublishedTaskTypes,
  getTaskTypesInWorkflow
} from '@utils/workflow-utils';

const AddTaskDialog = () => {
  const [taskType, setTaskType] = useState('');
  const dispatch = useDispatch();
  const { task_type } = useSelector(state => state.dataTypes);
  const { activeDialogs, dialogParams } = useSelector(state => state.dashboard);
  const { workflow: workflows } = useSelector(state => state.dataTypes);
  const { cycleId, workflow } = dialogParams[dialog.ADD_TASK] || {};
  // Lodash's isEmpty flags numbers as non-empty, thus check if taskType is '' or > 0:
  const active = !isEmpty(taskType) || taskType > 0;
  const isDialogActive = activeDialogs[dialog.ADD_TASK];

  useEffect(() => {
    // When the dialog open, reload the task templates, in case there are changes to them:
    if (isDialogActive) {
      dispatch(fetchDataType('task_type'));
    }
  }, [dispatch, isDialogActive]);

  const onClose = useCallback(
    () => {
      setTaskType('');
      dispatch(closeDashboardDialog(dialog.ADD_TASK));
    },
    [dispatch]
  );

  const onContinue = useCallback(
    () => {
      onClose();
      dispatch(push(
        {
          pathname: `/cycle/${cycleId}/task/new/${taskType}/`,
          state: { source: `/cycle/${cycleId}`, clear: true }
        }
      ));
    },
    [onClose, cycleId, dispatch, taskType]
  );

  if (!isDialogActive) {
    return null;
  }

  const onTaskTypeChange = event => setTaskType(event.target.value);

  const getTaskTypeItems = () => {
    if (task_type && workflow) {
      const taskTypes = getTaskTypesInWorkflow(task_type, workflows[workflow]);
      return getLatestPublishedTaskTypes(taskTypes).map(item => (
        <MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>
      ));
    }
    return null;
  };

  return (
    <Dialog
      actions={
        <DialogActions
          disabledSubmit={!active}
          onCancel={onClose}
          onSubmit={onContinue}
          submitLabel="CONTINUE"
        />
      }
      onClose={onClose}
      title="Create a task"
    >
      <FormControl fullWidth>
        <InputLabel id="new-task-label">Choose a task template</InputLabel>
        <SelectField
          labelId="new-task-label"
          id="new-task-select"
          fullWidth
          onChange={onTaskTypeChange}
          value={taskType}
        >
          {getTaskTypeItems()}
        </SelectField>
      </FormControl>
    </Dialog>
  );
};

export default memo(AddTaskDialog);
