import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import * as dialog from '@constants/dialogs';
import BaseDateRangeDialog from '@shared/dialogs/base-date-range-dialog';

// A simplified, prop controlled DataRangeDialog based on existing BaseDateRangeDialog.
class DateRangeDialog extends BaseDateRangeDialog {
  componentDidMount() {
    this.resetFilters();
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.open && this.props.open) {
      this.resetFilters();
    }
  }

  resetFilters = () => {
    this.setState(this.getDefaultState());
  };

  getFilters = () => {
    const {start, end, contain} = this.state;
    return {
      start: moment(start).format('YYYY-MM-DD'),
      end: moment(end).format('YYYY-MM-DD'),
      contained: contain
    };
  };

  getSetFiltersFunction = () => this.props.onChange;

  getDataTypeDisplayName = () => this.props.typeLabel;

  // Don't show the 'fully contained' checkbox for Batch uploads:
  showContain = () => this.props.contained;
}

DateRangeDialog.propTypes = {
  contained: PropTypes.bool,
  defaultRange: PropTypes.object,
  onChange: PropTypes.func,
  onClose: PropTypes.func,
  open: PropTypes.bool,
  typeLabel: PropTypes.string
};

export const mapStateToProps = (state, props) => {
  const activeDialogs = {
    [dialog.DATE_RANGE]: props.open
  };
  const { onChange, onClose } = props;
  const { start, end, contained } = (props.defaultRange || {});
  return {
    activeDialogs,
    start,
    end,
    contain: contained,
    setFilter: onChange,
    closeDialog: onClose
  };
};

export default connect(mapStateToProps, {})(DateRangeDialog);
