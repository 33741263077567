import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import Snackbar from 'material-ui/Snackbar';
import { snackbarStyles } from '@constants/mui-theme';

const ErrorSnackbar = ({ errors }) => {
  const isOpen = errors.length !== 0;
  return (
    <div>
      <Snackbar
        open={isOpen}
        autoHideDuration={5000}
        message={R.propOr('', 'message', R.last(errors))}
        {...snackbarStyles(isOpen)}
      />
    </div>
  );
};

ErrorSnackbar.propTypes = {
  errors: PropTypes.array
};

const mapStateToProps = state => {
  const { errors } = state;
  return { errors };
};

export default connect(mapStateToProps, {})(ErrorSnackbar);
