import { createSelector } from 'reselect';
import { getConfig } from '../selectors';

export const getUser = createSelector(
  getConfig,
  config => {
    if (config.userLoaded) {
      return config.user;
    }
    return {};
  }
);

export const getPermissions = createSelector(
  getConfig,
  config => {
    if (config.userLoaded) {
      return config.permissions;
    }
    return {};
  }
);
