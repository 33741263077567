import React from 'react';
import SvgIcon from 'material-ui/SvgIcon';

const PoiMarkerIcon = props => (
  <SvgIcon {...props} viewBox="-1 -2 24 24">
    <path
      d={`
         M17 0c2.764 0 5 2.191 5 4.9 0 3.675-5 9.1-5 9.1s-5-5.425-5-9.1C12 2.191 14.236
         0 17 0zm0 6.65c.986 0 1.786-.784 1.786-1.75s-.8-1.75-1.786-1.75-1.786.784-1.786
         1.75.8 1.75 1.786 1.75zM5 6c2.764 0 5 2.191 5 4.9 0 3.675-5 9.1-5 9.1s-5-5.425-5-9.1C0
         8.191 2.236 6 5 6zm-3.571 4.9c0 1.995 2.085 5.047 3.571 6.916 1.514-1.883 3.571-4.9
         3.571-6.916 0-1.932-1.6-3.5-3.571-3.5s-3.571 1.568-3.571 3.5zM5 12.65c-.986
         0-1.786-.784-1.786-1.75S4.014 9.15 5 9.15s1.786.784 1.786 1.75-.8 1.75-1.786 1.75z
         `}
    />
  </SvgIcon>
);

export default PoiMarkerIcon;
