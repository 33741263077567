/* eslint-disable jsx-a11y/mouse-events-have-key-events */
/* eslint-disable react/jsx-key */
/* global google */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Marker, Polyline, Polygon } from '@react-google-maps/api';
import {
  mapConfig,
  markerConfig,
  segmentDirections
} from '@constants/component-configs';
import {
  getOneDirectionIcon,
  getBothDirectionIcon
} from '@icons/map-icons';
import memoize from 'memoize-one';

// Abstract class.
class BaseMapFigure extends Component {
  constructor(props) {
    super(props);
    this.markerClicks = {};
    this.props.segments.forEach(segment => {
      this.markerClicks[segment.id] = this.markerClick.bind(this, segment.center);
    });
    this.getInnerStyle = memoize(this.getInnerStyle);
  }

  markerClick(position) {
    if (this.props.drawingMode !== '') {
      // Don't allow clicking on a marker while we are drawing a shape.
      return;
    }
    if (this.props.clustering) {
      this.props.openAreaTray(position);
    } else {
      const trayProps = {
        id: this.props.id,
        type: this.props.type,
        position
      };
      this.props.openEntityInTray(trayProps);
    }
  }

  onMouseOver = position => {
    // Some maps might not have the radius circle (like the group edit page).
    if (window.highlightCircle) {
      window.highlightCircle.setCenter(position.latLng);
    }
  };

  getSegmentId = id => `${this.props.type}-${id}`;

  getPolylineId = (id, index) => `poly-${this.props.type}-${id}-${index}`;

  isHighlighted = segmentId => {
    const { shouldHighlight, shouldHighlightSegment } = this.props;
    return shouldHighlight && shouldHighlightSegment(segmentId);
  };

  getInnerStyle = (color, highlighted = false) => {
    if (highlighted) {
      return {...mapConfig.hoverStyle.inner, strokeColor: color };
    }
    return { ...mapConfig.defaultStyle, strokeColor: color };
  };

  getSegmentArrowOptions = (segment, innerOptions) => {
    const icon = { offset: '0', repeat: '20px' };
    let arrowOptions = {
      ...innerOptions,
      strokeOpacity: 0,
      zIndex: 205  // Arrows must be on top of all other Polylines.
    };
    if (segment.direction === segmentDirections.one.value) {
      arrowOptions = {
        ...arrowOptions,
        icons: [{
          ...icon,
          icon: getOneDirectionIcon()
        }]
      };
    }
    if (segment.direction === segmentDirections.both.value) {
      arrowOptions = {
        ...arrowOptions,
        icons: [{
          ...icon,
          icon: getBothDirectionIcon()
        }]
      };
    }
    return arrowOptions;
  };

  renderSegment = (segment, highlight) => {
    const { color } = this.props;
    if (highlight) {
      const { selectedSegmentId } = this.props;
      const innerOptions = this.getInnerStyle(color, true);

      // When the segment is highlighted, we must show a "glow"
      // around it. Since we are restricted to only set color
      // and thickness for Google Maps Polylines, we achieve
      // that effect by rendering three Polylines at the same
      // place, with different colors:
      if (segment.path) {
        const lines = [
          <Polyline key={this.getPolylineId(segment.id, 0)} path={segment.path} options={innerOptions} />
        ];

        // Show the arrows only if the direction is set,
        // and if we are highlighting a specific segment.
        if (segment && segment.direction && selectedSegmentId === segment.id) {
          // Like in the edit page, add another Polyline which only contains the arrows:
          const arrowOptions = this.getSegmentArrowOptions(segment, innerOptions);
          lines.push(<Polyline key={this.getPolylineId(segment.id, 1)} path={segment.path} options={arrowOptions} />);
        }
        lines.push(<Polyline key={this.getPolylineId(segment.id, 2)} path={segment.path} options={mapConfig.hoverStyle.middle} />);
        lines.push(<Polyline key={this.getPolylineId(segment.id, 3)} path={segment.path} options={mapConfig.hoverStyle.outer} />);

        return lines;
      }
      if (segment.rings) {
        return [
          <Polygon
            key={this.getPolylineId(segment.id, 0)}
            paths={segment.rings}
            options={{...mapConfig.polygonHoverStyle.inner, strokeColor: color, fillColor: color}}
          />,
          <Polygon
            key={this.getPolylineId(segment.id, 1)}
            paths={segment.rings}
            options={{...mapConfig.polygonHoverStyle.middle, strokePosition: google.maps.StrokePosition.OUTSIDE}}
          />,
          <Polygon
            key={this.getPolylineId(segment.id, 2)}
            paths={segment.rings}
            options={{...mapConfig.polygonHoverStyle.outer, strokePosition: google.maps.StrokePosition.OUTSIDE}}
          />
        ];
      }
      return [
        <Polyline key={this.getPolylineId(segment.id, 0)} path={[segment.center, segment.center]} options={innerOptions} />,
        <Polyline key={this.getPolylineId(segment.id, 1)} path={[segment.center, segment.center]} options={mapConfig.hoverStyle.middle} />,
        <Polyline key={this.getPolylineId(segment.id, 2)} path={[segment.center, segment.center]} options={mapConfig.hoverStyle.outer} />
      ];
    }
    if (segment.path) {
      return <Polyline key={this.getPolylineId(segment.id, 0)} path={segment.path} options={this.getInnerStyle(color)} />;
    }
    if (segment.rings) {
      return <Polygon key={this.getPolylineId(segment.id, 0)} paths={segment.rings} options={{ ...this.getInnerStyle(color), fillColor: color }} />;
    }
    // If there is no path, nor rings, it's a point, render it on the map.
    return (
      <Polyline
        key={this.getPolylineId(segment.id, 0)}
        path={[segment.center, segment.center]}
        options={{...this.getInnerStyle(color), strokeWeight: 6}}
      />
    );
  };

  isClickable = () => this.props.drawingMode === '';

  render() {
    const { segments, markerVisible, getMarker, getOffsetMarker, showOffset } = this.props;
    if (markerVisible) {
      return segments.map(segment => {
        const highlight = this.isHighlighted(segment.id);
        return (
          <div key={this.getSegmentId(segment.id)}>
            <Marker
              clickable={this.isClickable()}
              icon={getMarker(highlight)}
              position={segment.center}
              onMouseOver={this.onMouseOver}
              onClick={this.markerClicks[segment.id]}
              options={markerConfig.options}
              zIndex={highlight ? 210 : 199}
            />
            {showOffset &&
              <Marker
                clickable={this.isClickable()}
                icon={getOffsetMarker(highlight)}
                position={segment.center}
                options={markerConfig.options}
                zIndex={highlight ? 211 : 200}
              />
            }
            {this.renderSegment(segment, highlight)}
          </div>
        );
      });
    }
    return segments.map(segment => this.renderSegment(segment, this.isHighlighted(segment.id)));
  }
}

BaseMapFigure.propTypes = {
  // If true, clustering is enabled and we must use radius clicks to open
  // the entities.
  clustering: PropTypes.bool,
  color: PropTypes.string,
  drawingMode: PropTypes.string,
  getMarker: PropTypes.func,
  getOffsetMarker: PropTypes.func,
  id: PropTypes.number,
  markerVisible: PropTypes.bool,
  openAreaTray: PropTypes.func,
  openEntityInTray: PropTypes.func,
  segments: PropTypes.array,
  selectedSegmentId: PropTypes.string,
  shouldHighlight: PropTypes.bool,
  shouldHighlightSegment: PropTypes.func,
  /**
   * This flag tells whether to show an offset icon over the current marker
   * (for example the 'star' icon).
   */
  showOffset: PropTypes.bool,
  type: PropTypes.string
};

export default BaseMapFigure;
