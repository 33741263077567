import React, { memo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { Tooltip } from '@material-ui/core';

import styles from './attribute-label.scss';

const AttributeLabel = ({
  contained = true,
  icon,
  text,
  toolTip,
  className,
  ...passThroughProps
}) => {
  const contents = (
    <span className={classNames(styles.container, {[styles.contained]: contained}, className)}{...passThroughProps}>
      { icon &&
        <span className={styles.icon}>{icon}</span>
      }
      { text &&
        <span className={styles.text}>{text}</span>
      }
    </span>
  );

  if (toolTip) {
    return (
      <Tooltip title={toolTip} /*followCursor*/ >
        {contents}
      </Tooltip>
    );
  }
  return contents;
};

AttributeLabel.propTypes = {
  className: PropTypes.string,
  contained: PropTypes.bool,
  icon: PropTypes.node,
  text: PropTypes.string,
  toolTip: PropTypes.string
};

export default memo(AttributeLabel);
