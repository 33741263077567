// Component to render categories as radio button options.
import React, { memo, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import * as R from 'ramda';
import { RadioGroup } from '@mui';
import { getDataForType } from '@selectors/data-types-selector';
import './forms.scss';

const { RadioButton } = RadioGroup;

const Radio = ({ dataName, disabled, label, name, onChange, value }) => {
  const { map_category_type: categoryTypes } = useSelector(state => state.dataTypes);
  const options = useSelector(state => getDataForType(state, dataName));

  const items = useMemo(() => {
    // Filter by category type (since categories are all on the same database table).
    if (categoryTypes && name.startsWith('|')) {
      const categoryTypeName = name.substring(1);
      const categoryType = parseInt(Object.keys(categoryTypes).find(key => categoryTypes[key].name === categoryTypeName), 10);
      const optionItems = R.pickBy(option => option.type === categoryType, options);

      // For radio, skip the '(blank)/TBD' value.
      return Object.values(R.pickBy(option => option.id > 0, optionItems));
    }
    return [];
  }, [categoryTypes, name, options]);

  const handleChange = useCallback(event => onChange(event, parseInt(event.target.value, 10)), [onChange]);

  return (
    <div styleName="radio-container">
      <RadioGroup
        label={label}
        name={name}
        onChange={handleChange}
        value={value}
      >
        {items.map(option => (
          <RadioButton
            disabled={disabled}
            key={option.id}
            label={option.name}
            value={option.id}
          />
        ))}
      </RadioGroup>
    </div>
  );
};

Radio.propTypes = {
  dataName: PropTypes.string,
  disabled: PropTypes.bool,
  label: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.any  // eslint-disable-line react/forbid-prop-types
};

export default memo(Radio);
