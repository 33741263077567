import {
  OPEN_CONFIRMATION_DIALOG,
  CLOSE_CONFIRMATION_DIALOG,
  DISCARD_CHANGES
} from '@constants/action-types';
import { push } from 'connected-react-router';
import { getConfirmedAction, buildConfirmableAction } from '@utils/confirmation-utils';

const showConfirmationAction = (title, question, action, params) => ({
  type: OPEN_CONFIRMATION_DIALOG,
  payload: {
    title,
    question,
    action,
    params
  }
});

const closeConfirmationAction = () => ({ type: CLOSE_CONFIRMATION_DIALOG });

export const showConfirmationDialog = (title, question, action, params) => dispatch => {
  return dispatch(showConfirmationAction(title, question, action, params));
};

export const closeConfirmationDialog = () => dispatch => dispatch(closeConfirmationAction());

export const runConfirmedAction = action => dispatch => dispatch(getConfirmedAction(action));

export const discardChangesAction = (discard = true) => dispatch => {
  dispatch({ type: DISCARD_CHANGES, discard });
  return Promise.resolve();
};

export const discardChanges = location => dispatch => dispatch(discardChangesAction()).then(() => dispatch(push(location)));

export const getConfirmableDiscardChanges = buildConfirmableAction(
  discardChanges,
  'confirmationActionsDiscardChanges'
);

export const routerWillLeave = nextLocation => dispatch => {
  dispatch(
    showConfirmationDialog(
      'Discard changes',
      'You have unsaved changes. Navigating away from this page without saving will discard the changes.',
      getConfirmableDiscardChanges(nextLocation.pathname),
      { confirmationType: 'discard' }
    )
  );
  return false;
};
