/* eslint-disable jsx-a11y/mouse-events-have-key-events */
/* eslint-disable react/jsx-no-bind */
import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { push } from 'connected-react-router';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import { fetchDataDetail } from '@actions/data-detail-actions';
import { saveDataType } from '@actions/data-types-actions';
import { pushApplicationMessage } from '@actions/messages-actions';
import { reorderCustomFields } from '@actions/workflow-actions';
import * as colors from '@constants/colors';
import { getWorkflowCustomFieldSections } from '@constants/config';
import { sectionTooltips } from '@constants/workflow';
import SwitchControl from '@shared/form/switch-control';
import { getTaskFieldIcon } from '@utils/icon-utils';
import {
  getFieldsMaxOrder,
  getTaskFields,
  getTaskSectionTitleFieldName
} from '@utils/workflow-utils';
import DragHandle from './drag-handle';
import FieldHelpIcon from './field-help-icon';
import './details-layout-drawer.scss';

// Button to add new fields.
const AddButton = ({ id, onAdd, order }) => (
  <div data-testid="task-type-drawer-add-button" styleName="section-row row" key={`add-button-${id}`}>
    <Button color="primary" onClick={() => onAdd(id, order)} startIcon={<AddIcon />}>ADD FIELD</Button>
  </div>
);

AddButton.propTypes = {
  id: PropTypes.number,
  onAdd: PropTypes.func,
  order: PropTypes.number
};

const FieldActions = ({ field, hovered, onDelete, onEdit }) => {
  if (!field.core && hovered) {
    const iconProps = { htmlColor: '#616161', style: { fontSize: '1.25rem', padding: '0 0 0 0.5rem' } };
    return (
      <div styleName="row-action">
        <EditIcon {...iconProps} onClick={() => onEdit(field)} />
        <DeleteIcon {...iconProps} onClick={() => onDelete(field)} />
      </div>
    );
  }
  return field.required ? 'Required' : 'Optional';
};

FieldActions.propTypes = {
  field: PropTypes.object,
  hovered: PropTypes.bool,
  onDelete: PropTypes.func,
  onEdit: PropTypes.func
};

// Renders a field row.
const Field = ({ field, index, onDelete, onEdit }) => {
  const [hovered, setHovered] = useState(false);
  const hoverOn = () => setHovered(true);
  const hoverOff = () => setHovered(false);

  return (
    <Draggable isDragDisabled={field.core || !field.id} key={index} draggableId={`draggable-${index}`} index={index}>
      {(provided, snapshot) => (
        <div
          {...provided.draggableProps}
          onMouseEnter={hoverOn}
          onMouseLeave={hoverOff}
          ref={provided.innerRef}
          styleName="section-row row field"
          style={{
            border: snapshot.isDragging ? `1px solid ${colors.dotmapsGrayBorder}` : 'none',
            width: '100%',
            ...provided.draggableProps.style
          }}
        >
          <div styleName="row-left field-container">
            {!field.core && field.id && <DragHandle provided={provided} />}
            <div styleName="field-icon">
              {getTaskFieldIcon(field)}
            </div>
            <div styleName="field-label">
              {field.label || field.name}
            </div>
          </div>
          <div styleName="row-right second-text">
            <FieldActions field={field} hovered={hovered} onDelete={onDelete} onEdit={onEdit} />
          </div>
        </div>
      )}
    </Draggable>
  );
};

Field.propTypes = {
  field: PropTypes.object,
  index: PropTypes.number,
  onDelete: PropTypes.func,
  onEdit: PropTypes.func
};

// Lists each section (group of fields, i.e. Information fields, Response fields) and
// its list of fields.
const TaskTypeDrawerList = ({ onAdd, onDelete, onEdit }) => {
  const { dataType } = useParams();
  const { data, loading } = useSelector(state => state.dataDetail);
  const dispatch = useDispatch();
  const [droppedField, setDroppedField] = useState(null);  // Last moved (dropped) field.

  // Trick to show the drag-and-dropped field in the new position before we finish reloading
  // the task template's fields.
  // Initially 'loading' is false, then when we drop a field, we trigger the reload,
  // thus it changes its state and we override the moved field's order.
  // After the new data is loaded, the new field order also came from the backend
  // and we no longer need to override it.
  // Going forward we need to block while we fetch the modified data (i.e.
  // modify the code to make fetchDataDetail() use async/await).
  useEffect(() => {
    if (loading === false) {
      setDroppedField(null);
    }
  }, [loading]);

  if (!data) {
    return null;
  }

  const sections = getWorkflowCustomFieldSections();

  const toggleResponse = value => {
    const callback = ({ id }) => {
      dispatch(fetchDataDetail(dataType, data.id));
      const successMessage = value ? 'Response fields are enabled.' : 'Response fields are disabled.';
      dispatch(pushApplicationMessage(successMessage));
      // If the id changed (due to versioning) redirect.
      if (String(data.id) !== String(id)) {
        dispatch(push({
          pathname: `/admin/task_type/${id}`,
          state: { clear: true }
        }));
      }
    };

    const payload = { response: value };
    dispatch(saveDataType(dataType, data.id, payload, callback));
  };

  return (
    <Fragment>
      <div data-testid="task-type-drawer-list-header" styleName="header row">
        <div data-testid="task-type-drawer-list-header-title" styleName="row-left">Task fields</div>
        <div data-testid="task-type-drawer-list-header-switch" styleName="row-right">
          <SwitchControl label="Response fields enabled" labelPlacement="start" onChange={toggleResponse} value={data.response} />
        </div>
      </div>
      {sections.map(section => {
        const title = getTaskSectionTitleFieldName(section);
        const fields = getTaskFields(section, data, droppedField, true);
        const coreFields = fields.filter(field => field.core || !field.id);
        const normalFields = fields.filter(field => !field.core && field.id);

        // If the response fields are not enabled, don't render that section:
        if (section.name === 'Response' && !data.response) {
          return null;
        }

        const onDragEnd = result => {
          if (!result.destination) {
            // Dropped outside the section:
            return;
          }

          const field = normalFields[result.source.index];  // Get dragged field.
          const new_order = result.destination.index + 1;  // Set new order value for this field.
          setDroppedField({ ...field, order: new_order });  // Set it on the state to display the new order until data is reloaded.

          const payload = {
            id: field.id,
            section: field.section,
            new_order,
            order: field.order,
            task_type: field.task_type
          };

          // Call backend to save the new order:
          dispatch(reorderCustomFields(payload));
        };

        return (  // eslint-disable-next-line react/jsx-key
          <Fragment>
            <div
              data-testid="task-type-drawer-list-section-title"
              styleName="section-title row"
            >
              {title}
              <FieldHelpIcon>
                {sectionTooltips[section.id]}
              </FieldHelpIcon>
            </div>
            <DragDropContext>
              <Droppable droppableId={`disabled-${section.id}`} isDropDisabled>
                {provided => (
                  <div
                    data-testid="task-type-drawer-list-droppable-1"
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                    style={{
                      border: '2px solid white',
                      backgroundColor: colors.overlayTextColor,
                      boxSizing: 'border-box',
                      width: '100%'
                    }}
                  >
                    {coreFields.map((field, index) => <Field field={field} index={index} key={index} onDelete={onDelete} onEdit={onEdit} />)}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
            <DragDropContext onDragEnd={onDragEnd}>
              <Droppable droppableId={String(section.id)}>
                {(provided, snapshot) => (
                  <div
                    data-testid="task-type-drawer-list-droppable-2"
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                    style={{
                      border: snapshot.isDraggingOver ? `2px dashed ${colors.dotmapsBlue}` : '2px solid white',
                      backgroundColor: snapshot.isDraggingOver ? '#E9F0FD' : colors.overlayTextColor,
                      boxSizing: 'border-box',
                      width: '100%'
                    }}
                  >
                    {normalFields.map((field, index) => <Field field={field} index={index} key={index} onDelete={onDelete} onEdit={onEdit} />)}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
            {(section.name === 'Response' || section.name === 'Information') && (
              <AddButton id={section.id} onAdd={onAdd} order={getFieldsMaxOrder(fields)} />
            )}
          </Fragment>
        );
      })}
    </Fragment>
  );
};

TaskTypeDrawerList.propTypes = {
  onAdd: PropTypes.func,
  onDelete: PropTypes.func,
  onEdit: PropTypes.func
};

export default TaskTypeDrawerList;
