import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import scrollIntoView from 'smooth-scroll-into-view-if-needed';
import { scrollToField, updateDataField } from '@actions/data-detail-actions';
import LocationRow from '@components/workflow/section/location-row';
import ShowMore from '@shared/show-more';
import Header from './header';
import Map from './map';
import './section.scss';

const MAX_LOCATIONS = 5;

const SCROLL_ID = 'locations-section';

const LocationsSection = ({ edit }) => {
  const dispatch = useDispatch();
  const { data } = useSelector(state => state.dataDetail);
  const { scrollId } = useSelector(state => state.dataDetail);
  const [showAll, setShowAll] = useState(false);

  const onChange = useCallback(
    (fieldName, value) => dispatch(updateDataField(fieldName, value)),
    [dispatch]
  );

  useEffect(() => {
    if (scrollId === SCROLL_ID) {
      const element = document.getElementById(scrollId);
      scrollIntoView(element, { scrollMode: 'if-needed' });
      // Clear the scroll id in the store, since we executed the scroll action.
      dispatch(scrollToField(null));
    }
  }, [dispatch, scrollId, data?.segments?.length]);

  const toggleShowAll = useCallback(() => setShowAll(!showAll), [showAll]);

  const onSegmentDelete = useCallback(segmentId => {
    if (data?.segments && segmentId) {
      const newSegments = data.segments.filter(segment => segment.id !== segmentId);
      onChange('segments', newSegments);
    }
  }, [data?.segments, onChange]);

  const onSegmentSelect = useCallback(segmentId => {
    if (data && segmentId) {
      onChange('_selectedSegmentId', segmentId);
    }
  }, [data, onChange]);

  const count = useMemo(() => data?.segments?.length || 0, [data?.segments?.length]);

  const locations = useMemo(() => {
    if (data?.segments) {
      return showAll ? data.segments : data.segments.slice(0, MAX_LOCATIONS);
    }
    return null;
  }, [data?.segments, showAll]);

  if (!data || (!edit && count === 0)) {
    return null;
  }

  return (
    <div styleName="section" id={SCROLL_ID}>
      {(!edit || count > 0) && (
        <Header
          edit={false} {...{} /* 'Locations' header is always in read-only mode */}
          headerLabel="Locations"
          count={count}
        />
      )}
      {data.segments && <Map segments={data.segments} selectedSegmentId={data._selectedSegmentId} />}
      {locations && (
        <div styleName="section-field-list">
          {locations.map(segment => (
            <LocationRow
              edit={edit}
              key={segment.id}
              onDelete={onSegmentDelete}
              onSelect={onSegmentSelect}
              segment={segment}
              selectedSegmentId={data._selectedSegmentId}
            />
          ))}
        </div>
      )}
      <ShowMore list={data.segments} max={MAX_LOCATIONS} onClick={toggleShowAll} showAll={showAll} />
    </div>
  );
};

LocationsSection.propTypes = {
  edit: PropTypes.bool
};

export default memo(LocationsSection);
