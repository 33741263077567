import React from 'react';

const WordIcon = () => (
  <svg width="1.5rem" height="1.5rem" viewBox="0 0 24 24">
    <defs>
      <path
        d={`
            M19 3H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9
            2-2V5c0-1.1-.9-2-2-2zm-3.5 14H14l-2-7.5-2 7.5H8.5L6.1
            7h1.7l1.54 7.51L11.3 7h1.4l1.97 7.51L16.2 7h1.7l-2.4 10z
           `}
        id="word-icon"
      />
    </defs>
    <use fill="#0283EA" fillRule="nonzero" xlinkHref="#word-icon" />
  </svg>
);

export default WordIcon;
