/* eslint-disable jsx-a11y/mouse-events-have-key-events */
/* eslint-disable react/display-name */
import React from 'react';
import { connect } from 'react-redux';
import * as R from 'ramda';
import PropTypes from 'prop-types';
import { fetchTooltipData } from '@actions/tooltips-actions';
import BaseTooltip from '@components/activity-logs/tooltips';
import { colors } from '@constants/colors';
import {
  getActivityLogTooltipsConfig,
  getEntityTypeLabel
} from '@constants/config';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import TriptychWidget from '@shared/list-item/widgets/triptych-widget';
import { getAgencyIcon } from '@utils/icon-utils';
import './tooltip.scss';

class EntityTooltip extends BaseTooltip {
  getDataType = () => this.props.dataType;

  getTitle = () => this.props.data.name;

  renderHeader = () => {
    const { data } = this.props;
    const tooltipsConfig = getActivityLogTooltipsConfig();
    const { field, label } = R.pathOr({}, ['header', this.getDataType()], tooltipsConfig);
    return (
      <div styleName="body-header body-item can-overflow">
        <div styleName="id">
          ID: {data.id}
        </div>
        {field && label && data[field] && (
          <div styleName="row can-overflow">
            <div styleName="bull">&bull;</div>
            <div>
              {label}: {data[field]}
            </div>
          </div>
        )}
      </div>
    );
  };

  getFromCategoryDict = (fieldName, valueField) => {
    const { data } = this.props;
    if (data.category_dict) {
      // eslint-disable-next-line id-length, no-plusplus
      for (let i = 0; i < data.category_dict.length; i++) {
        if (data.category_dict[i].name === fieldName) {
          return data.category_dict[i][valueField];
        }
      }
    }
    return null;
  };

  getRow = rowConfig => {
    const { data } = this.props;
    if (rowConfig.type === 'field') {
      return data[rowConfig['field-name']];
    }
    if (rowConfig.type === 'category') {
      return this.getFromCategoryDict(rowConfig['category-name'], 'value');
    }
    if (rowConfig.type === 'triptych') {
      const status = this.getFromCategoryDict(rowConfig['status-name'], 'value');
      const priority = this.getFromCategoryDict('priority', 'id');
      return (
        <TriptychWidget
          name={status}
          priority={priority}
          start={data.start_date}
          end={data.end_date}
        />
      );
    }
    return null;
  };

  renderContent = () => {
    const { data } = this.props;
    const tooltipsConfig = getActivityLogTooltipsConfig();
    const { row1, row2 } = R.pathOr({}, ['body', this.getDataType()], tooltipsConfig);
    return (
      <div styleName="body-content">
        <div styleName="body-row body-item">
          {row1 && this.getRow(row1)}
        </div>
        <div styleName="body-row">
          {row2 && this.getRow(row2)}
        </div>
        {(data.owner_list && data.owner_list.length > 0) || data.agency && (
          <div styleName="sep" />
        )}
        {data.owner_list && data.owner_list.length > 0 && (
          <div styleName="body-row body-item body-user-icon-row">
            <div styleName="icon">
              <div styleName="avatar">
                <AccountCircleIcon htmlColor={colors.coordinate.blue} style={{ fontSize: '1.125rem' }} />
              </div>
            </div>
            {data.owner_list.join(', ')}
          </div>
        )}
        {data.agency && (
          <div styleName="body-row body-item body-agency-icon-row">
            <img
              alt=""
              src={getAgencyIcon({id: data.agency_type})}
              styleName="icon"
            />
            {data.agency_name}
          </div>
        )}
      </div>
    );
  };

  renderClickLabel = () => `VIEW ${getEntityTypeLabel(this.props.dataType)}`;

  onLinkClick = () => {
    const { id } = this.props;
    this.openInNewWindow(`/map?${this.props.dataType}=${id}`);
  };

  onMouseOver = event => {
    const { id, data } = this.props;
    this.delayHoverOn(event);
    if (!data) {
      this.props.fetchTooltipData('map', this.props.dataType, id);
    }
  };
}

EntityTooltip.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

const mapStateToProps = (state, props) => {
  const { map, error: { map: error } } = state.tooltips;
  return { data: map[props.id], error };
};

const mapDispatchToProps = { fetchTooltipData };

export default connect(mapStateToProps, mapDispatchToProps)(EntityTooltip);
