// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.src-components-filters-chips-___select__select-menu .src-components-filters-chips-___select__select-contents{display:flex;flex-direction:column;overflow:hidden}.src-components-filters-chips-___select__select-menu .src-components-filters-chips-___select__search{height:3rem;padding-block:0.5rem;padding-inline:1rem;box-sizing:border-box;flex:0 0 auto}.src-components-filters-chips-___select__select-menu .src-components-filters-chips-___select__search .src-components-filters-chips-___select__search-adornments{color:#616161}.src-components-filters-chips-___select__select-menu .src-components-filters-chips-___select__options{flex:1 1 100%;overflow:auto}.src-components-filters-chips-___select__select-menu .src-components-filters-chips-___select__options .src-components-filters-chips-___select__option-divider{margin-inline:1rem}.src-components-filters-chips-___select__select-menu .src-components-filters-chips-___select__buttons{display:flex;align-items:center;flex-wrap:wrap;flex:0 0 auto;padding-block:0.5rem;padding-inline:0.75rem;gap:0.25rem;min-height:1.75rem;border-top:0.0625rem solid #0000001F}.src-components-filters-chips-___select__select-menu .src-components-filters-chips-___select__buttons .src-components-filters-chips-___select__button{color:#406EE3;line-height:1.25rem;text-transform:none;min-width:0.25rem;padding-block:0.25rem;padding-inline:0.3125rem}.src-components-filters-chips-___select__select-menu .src-components-filters-chips-___select__buttons .src-components-filters-chips-___select__disabled-button{color:#9E9E9E}.src-components-filters-chips-___select__select-menu .src-components-filters-chips-___select__buttons .src-components-filters-chips-___select__button-divider{height:0.875rem}
`, "",{"version":3,"sources":["webpack://./src/components/filters/chips/select.scss"],"names":[],"mappings":"AAAA,8GAEI,YAAa,CACb,qBAAsB,CACtB,eAAgB,CAJpB,qGAQI,WAAY,CACZ,oBAAqB,CACrB,mBAAoB,CACpB,qBAAsB,CAEtB,aAAc,CAblB,gKAgBM,aAAc,CAhBpB,sGAqBI,aAAc,CACd,aAAc,CAtBlB,8JAyBM,kBAAmB,CAzBzB,sGA8BI,YAAa,CACb,kBAAmB,CACnB,cAAe,CAEf,aAAc,CACd,oBAAqB,CACrB,sBAAuB,CACvB,WAAY,CAEZ,kBAAmB,CACnB,oCAAqC,CAxCzC,sJA2CM,aAAc,CACd,mBAAoB,CACpB,mBAAoB,CACpB,iBAAkB,CAElB,qBAAsB,CACtB,wBAAyB,CAjD/B,+JAqDM,aAAc,CArDpB,8JAyDM,eAAgB","sourcesContent":[".select-menu {\n  .select-contents {\n    display: flex;\n    flex-direction: column;\n    overflow: hidden;\n  }\n\n  .search {\n    height: 3rem;\n    padding-block: 0.5rem;\n    padding-inline: 1rem;\n    box-sizing: border-box;\n\n    flex: 0 0 auto;\n\n    .search-adornments {\n      color: #616161;\n    }\n  }\n\n  .options {\n    flex: 1 1 100%;\n    overflow: auto;\n\n    .option-divider {\n      margin-inline: 1rem;\n    }\n  }\n\n  .buttons {\n    display: flex;\n    align-items: center;\n    flex-wrap: wrap;\n\n    flex: 0 0 auto;\n    padding-block: 0.5rem;\n    padding-inline: 0.75rem;\n    gap: 0.25rem;\n\n    min-height: 1.75rem;\n    border-top: 0.0625rem solid #0000001F;\n\n    .button {\n      color: #406EE3;\n      line-height: 1.25rem;\n      text-transform: none;\n      min-width: 0.25rem;\n\n      padding-block: 0.25rem;\n      padding-inline: 0.3125rem;\n    }\n\n    .disabled-button {\n      color: #9E9E9E;\n    }\n\n    .button-divider {\n      height: 0.875rem;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"select-menu": `src-components-filters-chips-___select__select-menu`,
	"selectMenu": `src-components-filters-chips-___select__select-menu`,
	"select-contents": `src-components-filters-chips-___select__select-contents`,
	"selectContents": `src-components-filters-chips-___select__select-contents`,
	"search": `src-components-filters-chips-___select__search`,
	"search-adornments": `src-components-filters-chips-___select__search-adornments`,
	"searchAdornments": `src-components-filters-chips-___select__search-adornments`,
	"options": `src-components-filters-chips-___select__options`,
	"option-divider": `src-components-filters-chips-___select__option-divider`,
	"optionDivider": `src-components-filters-chips-___select__option-divider`,
	"buttons": `src-components-filters-chips-___select__buttons`,
	"button": `src-components-filters-chips-___select__button`,
	"disabled-button": `src-components-filters-chips-___select__disabled-button`,
	"disabledButton": `src-components-filters-chips-___select__disabled-button`,
	"button-divider": `src-components-filters-chips-___select__button-divider`,
	"buttonDivider": `src-components-filters-chips-___select__button-divider`
};
export default ___CSS_LOADER_EXPORT___;
