import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import MenuSectionItem from './menu-section-item';
import { verifyPermissions } from '@utils/permission-utils';
import './menu-section.scss';

const MenuSection = ({ section: { items, permissions, title } }) => {
  if (!verifyPermissions(permissions)) {
    return null;
  }

  return (
    <Fragment>
      <div styleName="menu-title">{title}</div>
      {items.map(item => <MenuSectionItem key={item.label} item={item} />)}
    </Fragment>
  );
};

MenuSection.propTypes = {
  section: PropTypes.object
};

export default MenuSection;
