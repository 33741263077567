import React from 'react';

const ProjectManagerIcon = () => (
  <svg width="24px" height="14px" version="1.1" viewBox="0 0 24 14">
    <g fill="none" fillRule="evenodd">
      <rect width="24" height="14" fill="#1DAF54" rx="2" />
      <text
        fill="#FFF"
        fontFamily="Roboto-Medium, Roboto"
        fontSize="10"
        fontWeight="400"
        style={{ pointerEvents: 'none' }}
      >
        <tspan x="4" y="10">PM</tspan>
      </text>
    </g>
  </svg>
);

export default ProjectManagerIcon;
