import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import GroupCards from '@shared/group-cards';
import { sortGroups } from '@utils/group-utils';
import './details-table-groups-list.scss';

const DetailsTableGroupsList = ({ groups }) => (
  <div styleName="groups-container">
    {groups.length === 0 &&
      <div styleName="empty-list">No Groups to show</div>
    }
    {groups.length > 0 && <GroupCards groups={groups} />}
  </div>
);

DetailsTableGroupsList.propTypes = {
  entity: PropTypes.object,
  groups: PropTypes.array
};

const mapStateToProps = (state, props) => {
  const { list } = state.groups;
  const { group_ids } = props.entity;
  const groups = list.filter(group => group_ids.includes(group.id));
  return { groups: sortGroups(groups) };
};

export default connect(mapStateToProps, {})(DetailsTableGroupsList);
