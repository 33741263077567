import React from 'react';

const PolygonIcon = () => (
  <svg width="18px" height="18px" version="1.1" viewBox="0 0 18 18">
    <defs>
      <path
        id="polygon-path-1"
        d={`
            M7.432 2.844l.735 1.244-3.3 1.95a2.469 2.469 0 0 1-.043
            1.11L7.58 8.885a2.45 2.45 0 0 1 1.809-.795c.605 0 1.16.219
            1.588.582l2.172-1.598a.722.722 0 0 1 .856 1.164L11.757
            9.89A2.458 2.458 0 0 1 9.389 13a2.456 2.456 0 0 1-2.432-2.8L4.05
            8.367a2.456 2.456 0 1 1 .14-3.607l3.243-1.917zM9.389 0a2.456
            2.456 0 1 1 0 4.911 2.456 2.456 0 0 1 0-4.911zm0 1.444a1.011
            1.011 0 1 0 0 2.023 1.011 1.011 0 0 0 0-2.023z
        `}
      />
    </defs>
    <g fill="none" fillRule="evenodd" transform="translate(1.5 2.25)">
      <mask id="polygon-path-2" fill="#fff">
        <use xlinkHref="#polygon-path-1" />
      </mask>
      <g fill="#000" fillOpacity=".4" mask="url(#polygon-path-2)">
        <path d="M-1.5-2.25h18v18h-18z" />
      </g>
    </g>
  </svg>
);

export default PolygonIcon;
