import React, { memo, useCallback, useMemo, useState } from 'react';
import { capitalize } from 'lodash';

import PropTypes from 'prop-types';

import {
  ENTITY_DATA_TYPE,
  OVERLAP_DATA_TYPE
} from '@components/config/constants';

import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import SearchIcon from '@material-ui/icons/Search';
import TextField from '@material-ui/core/TextField';
import CloseIcon from '@material-ui/icons/Close';
import Tooltip from '@material-ui/core/Tooltip';

import styles from './text.scss';

const TextFilterChip = ({ dataType, label, onChange, subType, value }) => {
  const [prevValue, setPrevValue] = useState(null);
  const [pendingValue, setPendingValue] = useState(null);

  const handleChange = useCallback(event => {
    setPendingValue(event.target.value);
  }, [setPendingValue]);

  const handleSubmit = useCallback(() => {
    if (value !== pendingValue) {
      onChange(pendingValue);
    }
  }, [value, onChange, pendingValue]);

  const handleKeyDown = useCallback((keyboardEvent) => {
    if (keyboardEvent.key === 'Enter') {
      handleSubmit();
    }
  }, [handleSubmit]);

  const clearValue = useCallback(() => {
    setPendingValue('');
    if (value !== '') {
      onChange('');
    }
  }, [value, onChange, setPendingValue]);

  const byType = useMemo(() => {
    if (dataType === ENTITY_DATA_TYPE || dataType === OVERLAP_DATA_TYPE) {
      return <li>{capitalize(subType)} ID</li>;
    }
    return <li>{capitalize(dataType)} ID</li>;
  }, [dataType, subType]);

  const byExternalID = useMemo(() => {
    if (dataType === ENTITY_DATA_TYPE) {
      return <li>External ID</li>;
    }
    return null;
  }, [dataType]);

  if (value !== prevValue) {
    setPendingValue(value);
    setPrevValue(value);
  }

  return (
    <Tooltip
      disableFocusListener
      disableTouchListener
      placement={'bottom-start'}
      title={
        <div className={styles.tooltip}>
          <span>Search by:</span>
          <ul>
            <li>Name</li>
            {byType}
            {byExternalID}
          </ul>
        </div>
      }
    >
      <TextField
        variant="outlined"
        value={pendingValue || ''}
        placeholder={label}
        onChange={handleChange}
        InputProps={{
          onBlur: handleSubmit,
          onKeyDown: handleKeyDown,
          classes: {
            root: styles.container,
            input: styles.text
          },
          endAdornment: (
            <InputAdornment
              classes={{root: styles.icon}}
              position="end"
            >
              {pendingValue && pendingValue.length > 0 ?
                <IconButton
                  color="inherit"
                  onClick={clearValue}
                >
                  <CloseIcon />
                </IconButton> :
                <SearchIcon />
              }
            </InputAdornment>
          )
        }}
      />
    </Tooltip>
  );
};

TextFilterChip.propTypes = {
  dataType: PropTypes.string,
  label: PropTypes.string,
  onChange: PropTypes.func,
  subType: PropTypes.string,
  value: PropTypes.string
};

export default memo(TextFilterChip);
