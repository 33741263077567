import React, { memo } from 'react';
import PropTypes from 'prop-types';
import * as colors from '@constants/colors';
import { Icon } from '@mui';
import Tooltipable from '@shared/tooltipable';
import './form-tooltip.scss';

const renderIcon = props => (
  <Icon
    color={colors.dotmapsBlack60}
    styleName="icon"
    style={{ fontSize: '18px', display: 'block' }}
    {...props}
  >
    help
  </Icon>
);

const FormTooltip = ({ children, tooltipStyle }) => (
  <div styleName="form-tooltip-container">
    <Tooltipable hoverElement={renderIcon} tooltipStyle={tooltipStyle}>
      <div styleName="form-tooltip-field-tooltip">
        {children}
      </div>
    </Tooltipable>
  </div>
);

FormTooltip.propTypes = {
  children: PropTypes.node,
  tooltipStyle: PropTypes.object
};

FormTooltip.defaultProps = {
  tooltipStyle: {}  // Override/reset the default 'Tooltipable' style.
};

export default memo(FormTooltip);
