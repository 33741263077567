import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { includes } from 'lodash';
import memoize from 'memoize-one';
import { isAnyTrayItemHovered, getTraySelectedSegmentId } from '@selectors/map-selector';

const getComponentName = component => (
  component.displayName || component.name || 'Component'
);

const getShouldHighlightSegment = (shouldHighlight, selectedSegmentId = null) => {
  let shouldHighlightSegment = () => false;
  if (shouldHighlight) {
    if (selectedSegmentId) {
      shouldHighlightSegment = segmentId => selectedSegmentId === segmentId;
    } else {
      shouldHighlightSegment = () => true;
    }
  }
  return shouldHighlightSegment;
};

const withShouldHighlight = (
  (
    WrappedComponent,
    trayItemIdsSelector,
    hoveredItemIdSelector = (() => null)
  ) => {
    class WithShouldHighlight extends Component {
      constructor() {
        super();
        this.getShouldHighlightSegment = memoize(getShouldHighlightSegment);
      }

      render() {
        const { hovering, hoveredId, selectedSegmentId, trayItemIds, ...itemProps } = this.props;
        const { id: itemId = null} = itemProps;
        let shouldHighlighSegment;
        let shouldHighlight = false;
        if (hovering) {
          shouldHighlight = hoveredId === itemId;
          // When hovering we should disregard the selectedSegmentId, because the highlight is for another entity.
          shouldHighlighSegment = this.getShouldHighlightSegment(shouldHighlight);
        } else {
          shouldHighlight = includes(trayItemIds, itemId);
          shouldHighlighSegment = this.getShouldHighlightSegment(shouldHighlight, selectedSegmentId);
        }
        return (
          <WrappedComponent
            {...itemProps}
            selectedSegmentId={selectedSegmentId}
            shouldHighlight={shouldHighlight}
            shouldHighlightSegment={shouldHighlighSegment}
          />
        );
      }
    }

    WithShouldHighlight.propTypes = {
      hoveredId: PropTypes.number,
      hovering: PropTypes.bool,
      selectedSegmentId: PropTypes.string,
      trayItemIds: PropTypes.array
    };

    const mapStateToProps = state => ({
      hoveredId: hoveredItemIdSelector(state),
      hovering: isAnyTrayItemHovered(state),
      selectedSegmentId: getTraySelectedSegmentId(state),
      trayItemIds: trayItemIdsSelector(state)
    });

    return connect(
      mapStateToProps, null, null,
      {getDisplayName: () => `withShouldHighlight(${getComponentName(WrappedComponent)})`}
    )(WithShouldHighlight);
  }
);

export default withShouldHighlight;
