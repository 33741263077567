/* eslint-disable react/jsx-no-bind */
import React, { useContext } from 'react';
import * as R from 'ramda';

import { DragDropContext } from 'react-beautiful-dnd';

import {
  CARD_SIZE_LG,
  CARD_SIZE_SM
} from './constants';
import DashboardColumn from './dashboard-column';
import DashboardContext from './dashboard-context';
import { getColumnsData } from './utils';

import './dashboard.scss';

const DashboardGrid = () => {
  const {
    setAllCards,
    allCards,
    lgColumn,
    smColumn,
    updateLayout
  } = useContext(DashboardContext);

  const onDragEnd = (result, col) => {
    const { source, destination } = result;

    if (!destination || source.index === destination.index) {
      return;
    }

    const updatedCards = R.move(
      source.index,
      destination.index,
      col === CARD_SIZE_LG ? lgColumn : smColumn
    ).map((card, idx) => ({ ...card, order: idx }));

    const newAllCards = allCards.map(card => {
      return card.size !== col || card.order === -1
        ? card
        : {
          ...updatedCards.find(updatedCard => updatedCard.id === card.id)
        };
    });

    const updatedColumns = col === CARD_SIZE_LG
      ? getColumnsData(updatedCards, smColumn)
      : getColumnsData(lgColumn, updatedCards);

    setAllCards(newAllCards);
    updateLayout(updatedColumns);
  };

  return (
    <div styleName="grid-container">
      {!R.isEmpty(lgColumn) && (
        <DragDropContext
          onDragEnd={result => onDragEnd(result, CARD_SIZE_LG)}
        >
          <DashboardColumn cards={lgColumn} size="large" />
        </DragDropContext>
      )}
      <DragDropContext
        onDragEnd={result => onDragEnd(result, CARD_SIZE_SM)}
      >
        <DashboardColumn cards={smColumn} size="small" />
      </DragDropContext>
    </div>
  );
};

export default DashboardGrid;
