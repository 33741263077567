import Tooltipable from '@shared/tooltipable';

// This is a tooltip that displays data retrieved from a backend API call.
//
// This type of tooltip won't appear immediatelly on hover, it will have
// a small delay (needed for usability, the data should pop up after the
// user hover a label for some small amount of time).
//
// Also, it doesn't close as we move the mouse over the tooltip, only when
// we move out of it, since the tooltip contents might have some interaction
// (i.e. links).
class DataFetchTooltip extends Tooltipable {
  // The popover sets the 'childNode' flag on 'hover', to keep the parent
  // label component aware that it's open, so it doesn't get closed due
  // to 'hovering off' the label.
  onMouseOverChildren = event => {  // eslint-disable-line no-unused-vars
    // Use this.xxx instead of this.setState(), since setState will re-render the
    // component and will be too slow which won't make the hoverOn/Off code to work.
    this.childNode = true;
  };

  // If we hover off the popover, then call the
  // method to close it (with the delay effect).
  onMouseOutChildren = event => {
    this.childNode = false;
    this.delayHoverOff(event);
  };

  // Implement in subclass, it should contains something like this:
  //
  //   <div
  //     onMouseOver={this.onMouseOverChildren}
  //     onMouseOut={this.onMouseOutChildren}
  //     role="presentation"
  //   >{content}</div>
  //
  // This will render the popover contents. The onMouseOver() method
  // must call onMouseOverChildren() to flag that we are hovering
  // the popup contents, to avoid the top label to close this popup
  // when we hoverOff it (hovering off the label happens
  // if we move the mouse over the popup, since it's off the label).
  renderPopover = () => null;

  // Delay hover on/off, so the tooltip will appear only after
  // we spent some time hovering the label (and it will disappear with a
  // delay too if we hover off).
  //
  // Usually the onMouseOver() reference in the top label, will
  // call Tooltipable's onHover() method.
  // Thus the way to use delayHoverOn() is to place it
  // in our own onMouseOver() handler, for example,
  // doing something like this:
  //
  //   onMouseOver = event => {
  //     const { id, data } = this.props;
  //     this.delayHoverOn(event);
  //     if (!data) {
  //       this.props.fetchTooltipData('permit', null, id);
  //     }
  //   }
  //
  // On hovering the label, we'll delay the HoverOn call
  // and we can fetch the data if it's missing.
  delayHoverOn = event => {
    clearTimeout(this.enterTimer);
    clearTimeout(this.leaveTimer);
    event.persist();
    this.enterTimer = setTimeout(() => this.hoverOn(event), 1000);
  };

  // Instead of using the default Tooltipable's onMouseLeave() handler,
  // we should use this method below on the label's onMouseOut()
  // handler, so hovering off is also delayed.
  delayHoverOff = event => {
    clearTimeout(this.enterTimer);
    clearTimeout(this.leaveTimer);
    event.persist();
    // Call the actual 'off' handler later, this will give it time for children
    // hoverOn to activate and will also delay closing if we moved out of
    // the label.
    this.leaveTimer = setTimeout(() => this.hoverOffHandler(event), 1000);
  };

  // HoverOff() is wrapped with this handler, so we don't close the popup
  // when hovering off the label and into the popup.
  hoverOffHandler = event => {  // eslint-disable-line no-unused-vars
    // If there's no popup hovered, close it immediatelly (we already did the delay
    // before calling this.
    if (!this.childNode) {
      this.hoverOff();
    }
  };
}

export default DataFetchTooltip;
