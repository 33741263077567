import React from 'react';
import PropTypes from 'prop-types';
import ViewListIcon from '@material-ui/icons/ViewList';
import './empty-body.scss';

const EmptyBody = ({ icon, message, subText }) => (
  <div styleName="empty-body-container">
    <div styleName="content">
      <div styleName="icon">
        {icon}
        {!icon && <ViewListIcon htmlColor="#E0E0E0" style={{ fontSize: '6rem' }} />}
      </div>
      <div styleName="message">{message}</div>
      <div styleName="subtext">{subText}</div>
    </div>
  </div>
);

EmptyBody.propTypes = {
  icon: PropTypes.node,
  message: PropTypes.string,
  subText: PropTypes.string
};

export default EmptyBody;
