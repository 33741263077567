/* eslint-disable react/jsx-no-bind */
import React from 'react';
import PropTypes from 'prop-types';
import Chip from '@material-ui/core/Chip';
import DoneIcon from '@material-ui/icons/Done';
import { getEntityTypeLabel } from '@constants/config';
import './chips-add-to-group.scss';

const ChipsAddToGroup = ({
  dataType, groupTypeName, groups, setSelectedGroups
}) => {
  const toggleChip = groupId => {
    const chipGroup = groups.find(group => group.id === groupId);
    chipGroup.selected = !chipGroup.selected;
    const selectedGroupIds = groups.filter(group => group.selected).map(group => group.id);
    setSelectedGroups(selectedGroupIds);
  };

  return (
    <div styleName="chips-add-to-group-container">
      <div styleName="description">
        To exclude overlap you will need to add this {getEntityTypeLabel(dataType)} to the following {groupTypeName} group(s):
      </div>
      <div styleName="chips">
        {groups.map(group => (
          <Chip
            key={group.id}
            avatar={group.selected ? <DoneIcon /> : null}
            label={group.name}
            onClick={() => toggleChip(group.id)}
          />
        ))}
      </div>
    </div>
  );
};

ChipsAddToGroup.propTypes = {
  dataType: PropTypes.string,
  groupTypeName: PropTypes.string,
  groups: PropTypes.array,
  setSelectedGroups: PropTypes.func
};

export default ChipsAddToGroup;
