import {
  ENTITY_FETCH_SUCCESS,
  ENTITY_FETCH_COMPLETE,
  ENTITY_FILTERS_CHANGED,
  ENTITY_LOADING,
  ENTITY_MAP_LOAD_OUTSIDE_VIEWPORT,
  ENTITY_SET_INITIAL_BOUNDS
} from '@constants/action-types';
import { fetchData, getServerErrorAction, testIsCancel } from './shared-actions';
import { pushApplicationMessage } from './messages-actions';
import { optimizeEntitiesForMap } from '@utils/entity-utils';
import { boundsToWKT, getWKTViewportBoundsFromState } from '@utils/map-utils';
import { getMapFilters } from '@selectors/entities-selector';

const fetchEntitySuccessAction = payload => ({ type: ENTITY_FETCH_SUCCESS, ...payload });
const fetchEntityCompleteAction = entityType => ({ type: ENTITY_FETCH_COMPLETE, entityType });
const getLoadOutsideViewportAction = entityType => ({ type: ENTITY_MAP_LOAD_OUTSIDE_VIEWPORT, entityType });
const setEntitiesLoadingAction = entityType => ({ type: ENTITY_LOADING, entityType });

export const setEmptyEntities = type => dispatch => {
  dispatch(fetchEntitySuccessAction({
    entities: [],
    entityType: type,
    next: null,
    url: null
  }));
  return Promise.resolve();
};

const entityRequestTokens = {};

export const fetchEntityData = (type, url) => async (dispatch, getState) => {
  const state = getState();
  const { shape_selection: shapeSelection } = getMapFilters(state);
  // Cancel any existing requests.
  if (entityRequestTokens[type]) {
    const cancelToken = entityRequestTokens[type];
    entityRequestTokens[type] = null;
    cancelToken.cancel();
  }
  // Only show the loading indicator when loading entities within the viewport.
  if (state.entities.types[type].inViewport) {
    dispatch(setEntitiesLoadingAction(type));
  }

  const { request, token } = fetchData(url, true); // Returns a promise for the Axios' request and its cancel token
  entityRequestTokens[type] = token;
  try {
    const response = await request;
    const { data } = response;
    const { agency_type, map_type } = state.dataTypes;
    const entities = optimizeEntitiesForMap(data.results, type, { agency_type, map_type });
    if (entities.length === 0 && state.entities.types[type].notifyEmpty) {
      dispatch(pushApplicationMessage('No results. Try adjusting your filter.'));
    }
    const next = data.next;
    dispatch(fetchEntitySuccessAction({ entities, next, url, entityType: type }));
    if (!next && !shapeSelection && (state.entities.types[type].inViewport)) {
      dispatch(getLoadOutsideViewportAction(type));
    } else {
      dispatch(fetchEntityCompleteAction(type));
      entityRequestTokens[type] = null;
    }
    return response;
  } catch (errorOrCancel) {
    if (testIsCancel(errorOrCancel)) {
      return errorOrCancel;
    }
    if (errorOrCancel.message) {
      dispatch(getServerErrorAction(type, errorOrCancel));
    }
    throw errorOrCancel;
  }
};

const getSetFiltersAction = (entityType, filters, viewport) => ({
  type: ENTITY_FILTERS_CHANGED,
  entityType,
  filters,
  viewport
});

export const setEntityFilters = (entityType, filters) => (dispatch, getState) => {
  const viewport = getWKTViewportBoundsFromState(getState());
  dispatch(getSetFiltersAction(entityType, filters, viewport));
};

export const setInitialEntityBounds = bounds => dispatch => {
  const viewport = boundsToWKT(bounds);
  dispatch({
    type: ENTITY_SET_INITIAL_BOUNDS,
    viewport
  });
};
