import React from 'react';
import PropTypes from 'prop-types';
import SvgIcon from 'material-ui/SvgIcon';
import { dotmapsGray } from '@constants/colors';

const GanttChartIcon = props => (
  <SvgIcon {...props} style={{...props.style, height: '24px', width: '24px'}} viewBox="0 0 24 24">
    <path d="M2 0v20H0V0h2zm8 14.2V17H4v-2.8h6zm8-5.6v2.8H4V8.6h14zM13.8 3v3H4V3h9.8z" transform="translate(2 2)" />
  </SvgIcon>
);

GanttChartIcon.propTypes = {
  color: PropTypes.string,
  style: PropTypes.object
};

GanttChartIcon.defaultProps = {
  color: dotmapsGray
};

export default GanttChartIcon;
