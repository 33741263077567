/* eslint-disable react/jsx-no-bind */
import React from 'react';
import PropTypes from 'prop-types';

import { Select, TextField } from '@mui';

import { DASHBOARD_SIZE_OPTIONS } from '../settings';

import './customize-dashboard-dialog.scss';

const { MenuItem } = Select;

const CustomEmbedFormView = ({ cardData, setCardData, urlErrorMsg }) => {
  const updateCardField = (field, value) => {
    setCardData({
      ...cardData,
      [field]: value
    });
  };

  return (
    <div styleName="add-customize-embed-form">
      <TextField
        id="title"
        label="Name"
        value={cardData.title}
        onChange={event => updateCardField('title', event.target.value)}
        required
      />
      <TextField
        id="url"
        label="Embed URL"
        value={cardData.embedLink}
        onChange={event => updateCardField('embedLink', event.target.value)}
        required
        error={urlErrorMsg.length > 0}
        helperText={urlErrorMsg}
      />
      <Select
        label="Card Width"
        value={cardData.size}
        onChange={event => updateCardField('size', event.target.value)}
      >
        {DASHBOARD_SIZE_OPTIONS.map(option => (
          <MenuItem key={option.value} value={option.value}>
            <div>{option.label}</div>
          </MenuItem>
        ))}
      </Select>
    </div>
  );
};

CustomEmbedFormView.propTypes = {
  cardData: PropTypes.object,
  setCardData: PropTypes.func,
  urlErrorMsg: PropTypes.string
};

export default CustomEmbedFormView;
