import React from 'react';

const EventIcon = () => (
  <svg width="33px" height="33px" viewBox="0 0 33 33" version="1.1">
    <defs>
      <filter x="-1.7%" y="-1.9%" width="103.4%" height="105.7%" filterUnits="objectBoundingBox" id="filter-1">
        <feOffset dx="0" dy="2" in="SourceAlpha" result="shadowOffsetOuter1" />
        <feGaussianBlur stdDeviation="2" in="shadowOffsetOuter1" result="shadowBlurOuter1" />
        <feColorMatrix
          values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.198539402 0"
          type="matrix" in="shadowBlurOuter1" result="shadowMatrixOuter1"
        />
        <feMerge>
          <feMergeNode in="shadowMatrixOuter1" />
          <feMergeNode in="SourceGraphic" />
        </feMerge>
      </filter>
    </defs>
    <g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Calendar-calendar-window" transform="translate(-23.000000, -15.000000)">
        <g filter="url(#filter-1)" transform="translate(0.000000, 2.000000)" id="Event-Icon">
          <g transform="translate(27.000000, 15.000000)">
            <circle id="Oval-3" fill="#FF5722" cx="12.5" cy="12.5" r="12.5" />
            <g id="ic_event" transform="translate(4.545455, 4.545455)">
              <polygon id="Bounds" points="0 0 15.9090909 0 15.9090909 15.9090909 0 15.9090909" />
              <path d={`
                        M11.2689394,7.95454545 L7.95454545,7.95454545 L7.95454545,11.2689394 L11.2689394,
                        11.2689394 L11.2689394,7.95454545 L11.2689394,7.95454545 Z M10.6060606,0.662878788
                        L10.6060606,1.98863636 L5.3030303,1.98863636 L5.3030303,0.662878788 L3.97727273,0.662878788
                        L3.97727273,1.98863636 L3.31439394,1.98863636 C2.57859848,1.98863636 1.99526515,2.58522727
                        1.99526515,3.31439394 L1.98863636,12.594697 C1.98863636,13.3238636 2.57859848,13.9204545
                        3.31439394,13.9204545 L12.594697,13.9204545 C13.3238636,13.9204545 13.9204545,13.3238636
                        13.9204545,12.594697 L13.9204545,3.31439394 C13.9204545,2.58522727 13.3238636,1.98863636
                        12.594697,1.98863636 L11.9318182,1.98863636 L11.9318182,0.662878788 L10.6060606,0.662878788
                        L10.6060606,0.662878788 Z M12.594697,12.594697 L3.31439394,12.594697 L3.31439394,5.3030303
                        L12.594697,5.3030303 L12.594697,12.594697 L12.594697,12.594697 Z
                       `}
              fill="#FFFFFF"
              id="Icon"
              />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default EventIcon;
