/* eslint-disable id-length */
/* eslint-disable react/jsx-no-bind */
import React, { memo, useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import { MentionsInput, Mention } from 'react-mentions';
import { getAllowAllUsersComment } from '@constants/config';
import { mentionStyles } from '@constants/mui-theme';
import DialogActions from '@shared/dialogs/dialog-actions';
import { renderMentionsUser } from '@utils/autocomplete-utils';
import { sortBy } from '@utils/shared-utils';
import './comment-input.scss';

const MAX_LEN = 5000;

const CommentInput = ({ filterUsers, onComment }) => {
  const [comment, setComment] = useState('');
  const [mentions, setMentions] = useState([]);
  const users = useSelector(state => state.dataTypes.user);

  const resetState = () => {
    setComment('');
    setMentions([]);
  };

  const onSend = () => {
    onComment(comment, mentions);
    resetState();
  };

  // eslint-disable-next-line no-unused-vars
  const onChange = (event, value, plainTextValue, mentionValues) => {
    setComment(value.substring(0, MAX_LEN));
    setMentions(mentionValues.map(mention => mention.id));
  };

  // eslint-disable-next-line no-unused-vars
  const renderUserSuggestion = (entry, search, highlightedDisplay, index, focused) => {
    const user = {
      ...entry,
      highlight: highlightedDisplay
    };
    return renderMentionsUser(user);
  };

  // eslint-disable-next-line no-unused-vars
  const renderDisplay = (id, display) => `  ${display}  `;  // Add leading/trailing spaces for formatting (chip emulation with styles).

  // Return the users list to use for suggestions, copying the 'email' field into the
  // 'id' one and copying 'name' into 'display', something required by the react-mentions library.
  const getUsers = () => {
    let filteredUsers = users;
    // If there's a custom method to filter users apply it:
    // We will ignore filters if the city would like everyone to comment.
    if (filterUsers && !getAllowAllUsersComment()) {
      filteredUsers = filterUsers(filteredUsers);
    }

    const activeUsers = Object.values(filteredUsers).filter(
      user => user.is_active && !user.email.endsWith('dotmapsapp.com') && !user.email.endsWith('atom-ai.com')
    );
    const sortedUsers = sortBy(activeUsers, 'name');
    return sortedUsers.map(user => ({
      ...user,
      id: user.email,
      display: user.name
    }));
  };

  const commentNotEmpty = comment && comment.length > 0;
  return (
    <div styleName="comment-input-container">
      <div styleName="controls">
        <MentionsInput
          allowSpaceInQuery
          a11ySuggestionsListLabel="Suggested user mentions"
          onChange={onChange}
          placeholder="Send a comment, use @ to mention other users"
          style={mentionStyles.input}
          value={comment}
        >
          <Mention
            appendSpaceOnAdd
            data={getUsers()}
            markup="|__id__^__display__|"
            displayTransform={renderDisplay}
            renderSuggestion={renderUserSuggestion}
            style={mentionStyles.mention}
            trigger="@"
          />
        </MentionsInput>
        {commentNotEmpty && (
          <div styleName="field-footer">
            <div styleName="hint" />
            <div styleName="counter">
              {comment.length}/{MAX_LEN}
            </div>
          </div>
        )}
      </div>
      {commentNotEmpty && (
        <div styleName="footer">
          <div styleName="footer-grow" />
          <div styleName="footer-button">
            <DialogActions
              disabledSubmit={isEmpty(comment)}
              onCancel={resetState}
              onSubmit={onSend}
              submitLabel="SEND"
            />
          </div>
        </div>
      )}
    </div>
  );
};

CommentInput.propTypes = {
  filterUsers: PropTypes.func,
  onComment: PropTypes.func
};

export default memo(CommentInput);
