import { useMemo } from 'react';
import { includes } from 'lodash';
import { getAttachmentEnabledList } from '@constants/config';
import { canEditAttachments, canUploadAttachments } from '@utils/permission-utils';

export const useCanEditAttachments = (agency, dataType) => useMemo(() => {
  const enabledEntities = getAttachmentEnabledList();
  const isEnabled = includes(enabledEntities, dataType);
  const canEdit = canEditAttachments(agency, isEnabled);
  return {
    canEdit,
    canUpload: canEdit || (isEnabled && canUploadAttachments())
  };
}, [
  agency,
  dataType
]);
