/*
 * This component connects the redux state language locale to the
 * IntlProvider component and i18n messages.
 */
import React, { memo } from 'react';
import { IntlProvider } from 'react-intl';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { DEFAULT_LOCALE, FORMATS } from '@constants/i18n';

const LanguageProvider = ({ children, messages }) => {
  const language = useSelector(state => state?.language);
  const locale = language && language.locale ? language.locale : DEFAULT_LOCALE;

  return (
    <IntlProvider formats={FORMATS} key={locale} locale={locale} messages={messages[locale]}>
      {React.Children.only(children)}
    </IntlProvider>
  );
};

LanguageProvider.propTypes = {
  children: PropTypes.element.isRequired,
  messages: PropTypes.object
};

export default memo(LanguageProvider);
