import React, { memo, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import EntityMapFigure from './map-figure';
import { fetchEntityData, setEmptyEntities } from '@actions/entities-actions';
import {
  boundsEntitiesSelector,
  entityFiltersUrlSelector
} from '@selectors/entities-selector';

const MapEntities = ({ markerVisible, type }) => {
  const dispatch = useDispatch();

  const next = useSelector(state => entityFiltersUrlSelector(state, { type }));
  const visibleEntities = useSelector(state => boundsEntitiesSelector(state, { type }));

  useEffect(() => {
    if (isEmpty(visibleEntities)) {
      dispatch(fetchEntityData(type, next));
    }
  }, []);  // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (next) {
      dispatch(fetchEntityData(type, next));
    } else {
      dispatch(setEmptyEntities(type));
    }
  }, [dispatch, type, next]);

  return visibleEntities.map(item => (
    <EntityMapFigure clustering key={item.id} {...item} markerVisible={markerVisible} type={type} />
  ));
};

MapEntities.propTypes = {
  markerVisible: PropTypes.bool,
  type: PropTypes.string
};

export default memo(MapEntities);
