import {
  AGENCY_DATA_TYPE,
  CYCLE_DATA_TYPE,
  BATCH_DATA_TYPE,
  ENTITY_DATA_TYPE,
  GROUP_DATA_TYPE,
  OVERLAP_DATA_TYPE,
  TASK_ACTIVITY_DATA_TYPE,
  TASK_DATA_TYPE,
  USER_DATA_TYPE,
  WORKFLOW_DATA_TYPE
} from '@components/config/constants';

export const PORTAL_DATA_TYPES = new Set([
  AGENCY_DATA_TYPE,
  CYCLE_DATA_TYPE,
  BATCH_DATA_TYPE,
  ENTITY_DATA_TYPE,
  GROUP_DATA_TYPE,
  OVERLAP_DATA_TYPE,
  TASK_ACTIVITY_DATA_TYPE,
  TASK_DATA_TYPE,
  USER_DATA_TYPE,
  WORKFLOW_DATA_TYPE
]);
