import * as R from 'ramda';

import {
  SET_FILTERS,
  UPDATE_FILTERS
} from './constants';

const initialState = {};

const rejectEmptyFilters = R.reject(filter => filter === null);

const filtersReducer = (state = initialState, action) => {
  switch (action.type) {
  case SET_FILTERS: {
    const {dataType, subType, filters} = action;
    const existingFilters = R.pathOr({}, [dataType], state);
    if (subType && dataType !== subType) {
      return {
        ...state,
        [dataType]: {
          ...existingFilters,
          [subType]: rejectEmptyFilters(filters)
        }
      };
    }
    return {
      ...state,
      [dataType]: rejectEmptyFilters(filters)
    };
  }
  case UPDATE_FILTERS: {
    const {dataType, subType, filters} = action;
    const existingFilters = R.pathOr({}, [dataType], state);
    if (subType && dataType !== subType) {
      const existingSubFilters = R.pathOr({}, [dataType, subType], state);
      return {
        ...state,
        [dataType]: {
          ...existingFilters,
          [subType]: rejectEmptyFilters({
            ...existingSubFilters,
            ...filters
          })
        }
      };
    }
    return {
      ...state,
      [dataType]: rejectEmptyFilters({
        ...existingFilters,
        ...filters
      })
    };
  }
  default:
    return state;
  }
};

export default filtersReducer;
