import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { FormattedTime } from 'react-intl';
import moment from 'moment-timezone';

const DefaultTime = ({ value, format = '', ...other }) => {
  const parsed = moment.isMoment(value) ? value : moment(value);
  return parsed.isValid() && <FormattedTime value={parsed} format={format} {...other} />;
};

DefaultTime.propTypes = {
  format: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
};

export default memo(DefaultTime);
