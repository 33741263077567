import { memo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import { authTokenLogin } from '@actions/auth-actions';

// This component will authenticate the user with the backend based on a token
// received from the URL parameter.
const TokenAuth = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const { defaultUrl } = useSelector(state => state.config);
  const { token, userId, timestamp } = useParams();

  dispatch(authTokenLogin(location, defaultUrl, token, userId, timestamp));
  return null;
};

export default memo(TokenAuth);
