/* eslint-disable react/jsx-no-bind */
import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import scrollIntoView from 'smooth-scroll-into-view-if-needed';
import { scrollToField, updateDataField } from '@actions/data-detail-actions';
import Header from '@components/workflow/section/header';
import List from '@components/workflow/section/linked-entities-list';
import Map from '@components/workflow/section/map';
import { autocompleteSearchStyles } from '@constants/mui-theme';
import Button from '@material-ui/core/Button';
import { getCycleGroupEntities } from '@selectors/workflow-selector';
import InputSearch from '@shared/input-search';
import {
  activeEntitiesToOptions,
  renderEntityListItem
} from '@utils/autocomplete-utils';
import { filterSelectedEntities } from '@utils/workflow-utils';
import './section.scss';

const SCROLL_ID = 'linked-entities-section';

const LinkedEntitiesSection = ({ edit }) => {
  const dispatch = useDispatch();
  const { data } = useSelector(state => state.dataDetail);
  const { scrollId } = useSelector(state => state.dataDetail);
  const cycleEntities = useSelector(getCycleGroupEntities);
  const [entityIds, setEntityIds] = useState([]);

  const onChange = useCallback(
    (fieldName, value) => dispatch(updateDataField(fieldName, value)),
    [dispatch]
  );

  useEffect(() => {
    if (scrollId === SCROLL_ID) {
      const element = document.getElementById(scrollId);
      scrollIntoView(element, { scrollMode: 'if-needed' });
      // Clear the scroll id in the store, since we executed the scroll action.
      dispatch(scrollToField(null));
    }
  }, [dispatch, scrollId, data?.entities?.length]);

  const count = useMemo(() => data?.entities?.length || 0, [data?.entities?.length]);

  const onEntitiesChange = useCallback((event, newValues) => {
    if (Array.isArray(newValues)) {
      const values = newValues.map(value => value.value);
      setEntityIds(values);
    } else {
      setEntityIds([newValues.value]);
    }
  }, [setEntityIds]);

  const hideLinkedEntitiesForm = useCallback(() => {
    dispatch(updateDataField('_showLinkedEntitiesForm', false));
  }, [dispatch]);

  const linkClick = useCallback(() => {
    const entities = filterSelectedEntities(cycleEntities, entityIds);
    dispatch(updateDataField('entities', entities));
    hideLinkedEntitiesForm();
  }, [cycleEntities, dispatch, entityIds, hideLinkedEntitiesForm]);

  const addEntity = useCallback(() => {
    dispatch(updateDataField('_showLinkedEntitiesForm', true));
  }, [dispatch]);

  const removeEntity = useCallback(id => {
    const newEntityList = entityIds.filter(entityId => entityId !== id);
    setEntityIds(newEntityList);
    const entities = filterSelectedEntities(cycleEntities, newEntityList);
    dispatch(updateDataField('entities', entities));
  }, [cycleEntities, dispatch, entityIds, setEntityIds]);

  const allSegments = useMemo(() => {
    return [].concat(...(data?.entities || []).map(entity => entity.segments));
  }, [data?.entities]);

  // Skip section in details mode when there are no entries.
  if (!data || (!edit && count === 0)) {
    return null;
  }

  const hasSelected = entityIds.length > 0;
  return (
    <div styleName="section" id={SCROLL_ID}>
      {(data._showLinkedEntitiesForm || !edit || count > 0) && (
        <Header
          edit={data._showLinkedEntitiesForm ? false : edit}
          headerLabel="Linked entities"
          addLabel="Add entity"
          count={count}
          onAdd={addEntity}
        />
      )}
      {data.entities?.length > 0 && <Map segments={allSegments} selectedSegmentId={data._selectedSegmentId} />}
      {data._showLinkedEntitiesForm && (
        <InputSearch
          {...autocompleteSearchStyles.normal}
          avatarProps={{ type: 'entity' }}
          chipSize="medium"
          onChange={onEntitiesChange}
          label={hasSelected ? '' : 'Search entities in the relational group'}
          renderOption={option => renderEntityListItem(option, cycleEntities)}
          storePath={['workflow', 'cycle', 'group', 'entities']}
          toOptions={activeEntitiesToOptions}
          values={entityIds}
        />
      )}
      {data._showLinkedEntitiesForm && (
        <div styleName="section-actions">
          <Button onClick={hideLinkedEntitiesForm}>CANCEL</Button>
          <Button color="primary" disabled={!hasSelected} onClick={linkClick}>LINK</Button>
        </div>
      )}
      {data.entities?.length > 0 && (
        <div styleName="section-field-list">
          <List data={data} edit={edit} onChange={onChange} removeEntity={removeEntity} />
        </div>
      )}
    </div>
  );
};

LinkedEntitiesSection.propTypes = {
  edit: PropTypes.bool
};

export default memo(LinkedEntitiesSection);
