import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { FormattedDate } from 'react-intl';
import moment from 'moment-timezone';
import { enDash } from '@utils/shared-utils';

const DefaultDate = ({ value, format = 'default', valueFormat, ...other }) => {
  const parsed = moment.isMoment(value) ? value : moment(value, valueFormat);
  if (parsed.isValid()) {
    return (
      <FormattedDate day="numeric" month="numeric" year="numeric" format={format} value={parsed} {...other} />
    );
  }
  return enDash;
};

DefaultDate.propTypes = {
  format: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  valueFormat: PropTypes.string
};

export default memo(DefaultDate);
