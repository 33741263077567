import { getConfig } from '@components/config/selectors';

import {
  AGENCY_DATA_TYPE,
  BATCH_DATA_TYPE,
  CYCLE_DATA_TYPE,
  DATA_TYPES,
  ENTITY_DATA_TYPE,
  GROUP_DATA_TYPE,
  OVERLAP_DATA_TYPE,
  TASK_ACTIVITY_DATA_TYPE,
  TASK_DATA_TYPE,
  USER_DATA_TYPE
} from '@components/config/constants';

import { getAgencies, getAgencyTypes } from '@selectors/data-table-selector';
import { getDataTypes } from '@selectors/data-types-selector';

import { optimizeAgencyData } from '@utils/data-table/agency';
import { optimizeEntityData } from '@utils/data-table/entity';
import { optimizeGroupData } from '@utils/data-table/group';
import { optimizeOverlapData } from '@utils/data-table/overlap';
import { optimizeUserData } from '@utils/data-table/user';
import {
  optimizeActivityData,
  optimizeCycleData,
  optimizeTaskData
} from '@utils/data-table/workflow';
import { isPCCO } from '@utils/permission-utils';
import { optimizeBatchUploadData } from '@utils/data-table/batch';
import { createSelector } from 'reselect';

export const convertLegacyItems = items => (
  items.map(item => {
    let dataType = item.type === 'overlaps' ? OVERLAP_DATA_TYPE : item.type;
    let subType = item.subtype;
    let children = item.children;
    let type = DATA_TYPES[dataType];
    if (!type) {
      subType = dataType;
      dataType = ENTITY_DATA_TYPE;
      type = DATA_TYPES[ENTITY_DATA_TYPE];
    }
    if (children) {
      children = children.map(child => ({
        ...child,
        dataType,
        subType: child.type
      }));
    }
    if (type.portal === 'library' || type.portal === 'workflow' || type.portal === 'people') {
      if (children) {
        return {
          ...item,
          dataType,
          subType,
          children
        };
      }
      return {
        ...item,
        dataType,
        subType
      };
    }
    return null;
  }).filter(item => item)
);

export const getMenuConfig = createSelector(
  getConfig,
  (state, dataType) => dataType,
  (config, dataType) => {
    const type = DATA_TYPES[dataType];
    let legacyMenu = null;
    if (type) {
      const portal = type.portal;
      const legacyPortal = portal === 'library' ? 'list' : portal;
      legacyMenu = config.dashboard?.[legacyPortal]?.menu;
    }
    const sections = config?.portals?.library?.menu || [];
    const legacySections = legacyMenu || [];
    return [
      ...sections,
      // Filter legacy items for library items, and clean up configuration format.
      ...legacySections.map(section => {
        const legacyItems = convertLegacyItems(section.items);

        if (legacyItems.length > 0) {
          return {
            ...section,
            items: legacyItems
          };
        }
        return null;
      }).filter(section => section)
    ];
  }
);

export const getColumns = createSelector(
  getConfig,
  (state, dataType) => dataType,
  (state, dataType, subType) => subType,
  (config, dataType, subType) => {
    const type = DATA_TYPES[dataType];
    let columnData = null;
    if (type) {
      const portal = type.portal;
      const legacyPortal = portal === 'library' ? 'list' : portal;
      if (type.subType) {
        columnData = config.dashboard?.[legacyPortal]?.datatable?.columns?.[dataType]?.[subType] || null;
      } else {
        columnData = config.dashboard?.[legacyPortal]?.datatable?.columns?.[dataType] || null;
      }
    }
    // search legacy location for entity types
    if (!columnData && (dataType === ENTITY_DATA_TYPE)) {
      columnData = config.dashboard?.list?.datatable?.columns?.[subType] || null;
    }
    if (!columnData) {
      columnData = [];
    }

    // Non-PCCO users should hide the 'agency' column
    // since they can only see conflicts from their
    // own agency.
    if (!isPCCO() && type === 'conflict') {
      // The backend already doesn't send that column
      // but we must hide it from the headers.
      return columnData.filter(column => column.key !== 'agency');
    }
    return columnData;
  }
);

export const getPortalOrdering = createSelector(
  state => state.dataTables.filters,
  (state, dataType) => dataType,
  (state, dataType, subType) => subType,
  (filters, dataType, subType) => {
    const type = DATA_TYPES[dataType];
    if (type.subType) {
      if (dataType === ENTITY_DATA_TYPE) {
        const { ordering = null } = filters[subType] || {};
        return { ordering };
      }
      const { ordering } = filters[dataType][subType];
      return { ordering };
    }
    const { ordering } = filters[dataType];
    return { ordering };
  }
);

export const getPortalPaging = createSelector(
  state => state.dataTables.filters,
  (state, dataType) => dataType,
  (state, dataType, subType) => subType,
  (filters, dataType, subType) => {
    const type = DATA_TYPES[dataType];
    if (type.subType) {
      if (dataType === ENTITY_DATA_TYPE) {
        const { offset = 0, limit = 50 } = filters[subType] || {};
        return { offset, limit };
      }
      const { offset, limit } = filters[dataType][subType];
      return { offset, limit };
    }
    const { offset, limit } = filters[dataType];
    return { offset, limit };
  }
);

const getPortalRows = (state, dataType, subType) => {
  const type = DATA_TYPES[dataType];
  if (type.subType) {
    return state.dataTables.rows[dataType]?.[subType] || null;
  }
  return state.dataTables.rows[dataType] || null;
};

export const getOptimizedPortalRows = createSelector(
  getPortalRows,
  getAgencyTypes,
  getAgencies,
  getDataTypes,
  (state, dataType) => dataType,
  (state, dataType, subType) => subType,
  (rows, agencyTypes, agencies, dataTypes, dataType, subType) => {
    if (!rows) {
      return null;
    }
    switch (dataType) {
    case AGENCY_DATA_TYPE:
      return rows.map(row => optimizeAgencyData(row, agencyTypes));
    case ENTITY_DATA_TYPE:
      return rows.map(row => optimizeEntityData(row, dataType, agencyTypes));
    case BATCH_DATA_TYPE:
      return rows.map(row => optimizeBatchUploadData(row, agencies));
    case OVERLAP_DATA_TYPE:
      return rows.map(row => optimizeOverlapData(row, agencies, agencyTypes, subType));
    case GROUP_DATA_TYPE:
      return rows.map(row => optimizeGroupData(row));
    case TASK_ACTIVITY_DATA_TYPE:
      return rows.map(row => optimizeActivityData(row, agencyTypes, dataTypes.user_roles, dataTypes.task_status));
    case TASK_DATA_TYPE:
      return rows.map(row => optimizeTaskData(row, agencyTypes));
    case CYCLE_DATA_TYPE:
      return rows.map(row => optimizeCycleData(row));
    case USER_DATA_TYPE:
      return rows.map(row => optimizeUserData(row, agencies, dataTypes.user_roles));
    default:
      return null;
    }
  }
);
