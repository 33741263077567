export const ENTITY_TRAY = 'entity';
export const LAYER_TRAY = 'layer';
export const GROUP_TRAY = 'group';
export const ADD_TO_GROUP_TRAY = 'add-to-group';
export const OVERLAP_TRAY = 'overlap';
export const RECENT_TRAY = 'recent';
export const SEARCH_TRAY = 'search';
export const FILTER_TRAY = 'filter';
export const DETAILS_TRAY = 'details'; // empty detail page

export const STACKABLE_TRAYS = new Set([
  ENTITY_TRAY, LAYER_TRAY, GROUP_TRAY, ADD_TO_GROUP_TRAY
]);

export const DETAIL_TRAY_TYPES = new Set([
  ENTITY_TRAY, LAYER_TRAY, GROUP_TRAY, OVERLAP_TRAY, ADD_TO_GROUP_TRAY, DETAILS_TRAY
]);
