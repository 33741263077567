// Icons to use with Google Maps Polyline icons:

export const getOneDirectionIcon = () => ({
  path: 'M1,-4 L1,-1 L8,-1 L8,1 L1,1 L1,4 L-3,0 Z',
  fillColor: '#FFF',
  fillOpacity: 1,
  rotation: 90,
  scale: 1
});

export const getBothDirectionIcon = () => ({
  path: 'M1,-4 L1,-1 L8,-1 L8,1 L1,1 L1,4 L-3,0 Z M12,0 L8,4 L8,1 L1,1 L1,-1 L8,-1 L8,-4 L12,0 Z',
  fillColor: '#FFF',
  fillOpacity: 1,
  rotation: 90,
  scale: 1
});
