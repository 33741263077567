import {
  AUTH_LOGIN_ERROR,
  AUTH_UPDATE_USER,
  AUTH_PASSWORD_CHANGE_SUCCESS,
  AUTH_PASSWORD_CHANGE_ERROR,
  AUTH_PASSWORD_REQUEST_SUCCESS,
  AUTH_PASSWORD_REQUEST_ERROR,
  AUTH_SET_AGENCY_SUCCESS
} from '@constants/action-types';
import initialState from '../store/initial-state';

const authReducer = (state = initialState.auth, action) => {
  switch (action.type) {
  case AUTH_UPDATE_USER: {
    return { ...state, user: { ...state.user, ...action.payload } };
  }
  case AUTH_LOGIN_ERROR: {
    return { ...state, errors: { ...state.errors, ...action.payload.response.data } };
  }
  case AUTH_PASSWORD_REQUEST_SUCCESS:
    return { ...state, user: { ...state.user, requestedReset: { ...action.payload } } };
  case AUTH_PASSWORD_REQUEST_ERROR:
    return { ...state, user: { ...state.user, requestedReset: { ...action.error } } };
  case AUTH_PASSWORD_CHANGE_SUCCESS:
    return { ...state, user: { ...state.user, passwordChangeData: { ...action.payload } } };
  case AUTH_PASSWORD_CHANGE_ERROR:
    return { ...state, user: { ...state.user, passwordChangeData: { ...action.error } } };
  case AUTH_SET_AGENCY_SUCCESS:
    return { ...state, user: { ...state.user, agencyId: action.agencyId } };
  default:
    return state;
  }
};

export default authReducer;
