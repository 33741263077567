import * as R from 'ramda';

export const setConfig = props => {
  window.config = props;
};

// Merge props into the existing windows config.
export const mergeConfig = props => {
  window.config = { ...window.config, ...props };
};

export const getConfig = () => window.config || {};

export const getConfigPath = paths => R.pathOr(null, paths, getConfig());

// Like getConfigPath, but returns an empty object if the path is not found:
export const getConfigPathOr = paths => R.pathOr({}, paths, getConfig());

export const getConfigKey = key => getConfigPath([key]);
