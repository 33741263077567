/* eslint-disable max-depth */
/* eslint-disable react/display-name */
import React from 'react';
import * as R from 'ramda';
import { v4 as uuid } from 'uuid';
import { isString } from 'lodash';
import moment from 'moment-timezone';
import { scheduleTypes } from '@constants/component-configs';
import { getCategoryTypes, getDetailsConfig } from '@constants/config';
import { authSelector } from '@utils/auth-utils';
import { getConfig } from '@utils/config-utils';
import { renderPriorityIconAndLabel } from '@utils/icon-utils';
import { getEntityMetaScript } from '@utils/data-detail/templates/entity';
import { getAgencyId, isAppAdmin } from '@utils/permission-utils';
import './data-detail-utils.scss';

// Returns the action object and description from django rest options response object
export const getAction = R.pipe(R.propOr({}, 'actions'), R.cond([
  [R.has('PUT'), R.applySpec({action: R.prop('PUT'), actionType: R.always('PUT')})],
  [R.has('POST'), R.applySpec({action: R.prop('POST'), actionType: R.always('POST')})],
  [R.T, R.always({action: null, actionType: null})]
]));

const isBlank = object => Object.keys(object).every(key => {
  const value = object[key];
  return (typeof value === 'undefined' || value === null || (value.trim && value.trim() === ''));
});

const filterEditableMetadata = R.filter(meta => !meta.read_only || meta.includeInSave);

export const pruneUneditableData = (data, metadata) => {
  const output = {};
  if (data.id) {
    output.id = data.id;
  }
  if (data.group_ids) {
    output.group_ids = data.group_ids;
  }
  if (metadata) {
    Object.keys(filterEditableMetadata(metadata)).forEach(fieldName => {
      const value = data[fieldName];
      const meta = metadata[fieldName];
      if (meta.type === 'nested object' && value) {
        output[fieldName] = pruneUneditableData(value, meta.children);
      } else {
        output[fieldName] = value;
      }
    });
  }
  return output;
};

const processValues = (data, metadata, processFunction) => {
  const output = {};
  if (metadata) {
    Object.keys(metadata).forEach(fieldName => {
      const meta = metadata[fieldName];
      let value = null;
      if (fieldName.startsWith('|') && data.category_dict) {
        const categoryTypes = getCategoryTypes();
        const categoryType = categoryTypes.find(cat => cat.name === fieldName.substring(1));
        if (categoryType) {
          // Fields starting with a pipe are categories,
          // their values are taken from the categories dict field:
          const categoryEntries = data.category_dict.filter(category => category.name === fieldName.substring(1));
          if (categoryEntries) {
            if (categoryType.is_multiple) {
              value = categoryEntries.map(categoryEntry => categoryEntry.id);
            } else if (categoryEntries.length === 1) {
              value = categoryEntries[0].id;
            }
          }
        }
      } else {
        // Else a normal top level entity field:
        value = data[fieldName];
      }
      output[fieldName] = processFunction(meta, value);
    });
    if ('id' in data && data.id) {
      output.id = data.id;
    }
    if ('group_ids' in data && data.group_ids) {
      output.group_ids = data.group_ids;
    }
    if ('cycles' in data && data.cycles) {
      output.cycles = data.cycles;
    }
    if ('overlaps' in data && data.overlaps) {
      output.overlaps = data.overlaps;
    }
  } else {
    // If there's no metadata, it means the backend doesn't allow us to
    // create or update this kind of object.
    // But if we are able to see it as read-only, we should be able to
    // render the details, thus interpret the object anyways, trying
    // to decode the details based on the values.
    //
    // This is required for when we don't have permissions to edit an
    // object, but we have permissions to see the details (like a
    // manager role with the groups object).
    Object.keys(data).forEach(fieldName => {
      const type = typeof (data[fieldName]);
      // This is not perfect, but it's enough for Groups
      // (due to its fields) and when we use this, it's because
      // we don't have write access, so we don't need all field values,
      // only the ones for display.
      const meta = { type };
      output[fieldName] = processFunction(meta, data[fieldName]);
    });
  }
  return output;
};

export const decodeDetail = (meta, value) => {
  let decoded = null;

  switch (meta.type) {
  case 'boolean':
    if (value === true || value === false) {
      decoded = value;
    } else
      // It's null, undefined or has other data type,
      // thus use the default if exists, or false.
      if (typeof meta.default !== 'undefined') {
        decoded = meta.default;
      } else {
        const isPublic = !authSelector();
        if (!isPublic && typeof meta.default_internal !== 'undefined') {
          decoded = meta.default_internal;
        } else {
          decoded = false;
        }
      }
    break;
  case 'date': {
    const defaultedValue = value || meta.default;
    decoded = defaultedValue ? moment(defaultedValue, 'YYYY-MM-DD') : null;
    break;
  }
  case 'datetime': {
    const defaultedValue = value || meta.default;
    const config = getConfig();
    decoded = defaultedValue && config ? moment.tz(defaultedValue, config.timezoneName) : null;
    break;
  }
  case 'nested object':
    decoded = processValues(value || meta.default || {}, meta.children, decodeDetail);
    break;
  case 'string':
    decoded = value || meta.default || '';
    break;
  default:
    decoded = value || value === 0 ? value : meta.default || meta.defaultIds || null;  // Avoid 'undefined'
  }

  // When decoding, if the subType is an array, we must ensure the decoded values
  // is an array if it's not that type already.
  if (meta.subType === 'array' && !Array.isArray(decoded)) {
    return [decoded];
  }

  return decoded;
};

const toBoolean = value => {
  if (value === 'true') {
    return true;
  } else if (value === 'false') {
    return false;
  } else if (value === 'null') {
    return null;
  }
  return value;
};

export const encodeDetail = (meta, value) => {
  switch (meta.type) {
  case 'boolean':
    if (isString(value)) {
      return toBoolean(value);
    }
    if (value === null && typeof meta.default !== 'undefined') {
      return meta.default;
    }
    return value || false;
  case 'date':
    return value ? value.format('YYYY-MM-DD') : null;
  case 'datetime':
    return value ? value.format() : null;
  case 'nested object':
    if (value) {
      const child = processValues(value, meta.children, encodeDetail);
      if (meta.required || !isBlank(child)) {
        return child;
      }
    }
    return null;
  case 'string':
    return value || '';
  default:
    return value || value === 0 ? value : meta.default || meta.defaultIds || null;  // Avoid 'undefined'
  }
};

export const decodeDetails = (data, metadata) => processValues(data, metadata, decodeDetail);

export const encodeDetails = (data, saveMetadata) => pruneUneditableData(processValues(data, saveMetadata, encodeDetail), saveMetadata);

export const createTemporalId = () => `temp-${uuid()}`;

export const createEmptySegment = () => ({
  id: createTemporalId(),
  shape: null,
  on_street: null,
  from_address: null,
  from_street: null,
  to_address: null,
  to_street: null,
  display_to: null,
  display_from: null
});

export const createEmptyRecurrence = () => ({
  id: createTemporalId(),
  start_time: null,
  end_time: null,
  monday: false,
  tuesday: false,
  wednesday: false,
  thursday: false,
  friday: false,
  saturday: false,
  sunday: false,
  all_day: false
});

export const createNewException = date => ({
  id: createTemporalId(),
  exception_date: moment(date).format('YYYY-MM-DD')
});

export const createEmptySchedule = () => ({
  id: createTemporalId(),
  all_day: false,
  type: scheduleTypes.oneTime.value,
  start_date: null,
  end_date: null,
  exceptions: [],
  recurrences: []
});

export const itemFormatters = {
  renderNameDescriptionItem: item => (
    <div styleName="item-container">
      <div styleName="description">
        {item.description}
      </div>
      <div styleName="name">
        {item.name}
      </div>
    </div>
  )
};

// These are the template fields which will be merged with the fields
// of an entity, if any of these fields exists in the entity.
// This is required in order to have funtion calls as field
// attributes, since the backend can only return scalar values,
// string and array attributes.
const commonTemplateFields = (isPublic = false) => ({
  agency: {
    defaultId: getAgencyId(),
    // Non app-admins must not select the agency.
    read_only: !isAppAdmin() && !isPublic
  },
  '|priority': {
    customItemFormatter: item => renderPriorityIconAndLabel(item)
  }
});

export const mergeActionWithTemplate = (action, template) => {
  const templatedAction = { ...template?.columns };
  Object.keys(templatedAction)
    .filter(key => key !== 'metaScript')
    .forEach(key => {
      templatedAction[key] = R.mergeDeepRight(
        {...{style: 'col100'}, ...R.propOr({}, key, action)},
        (key in templatedAction ? templatedAction[key] : {})
      );
    });
  return templatedAction;
};

export const getTemplate = (type, isPublic = false) => {
  const typeConfig = getDetailsConfig()[type];
  if (typeConfig) {
    const { form } = typeConfig;
    if (form) {
      // Reuse mergeActionWithTemplate() to merge the entity
      // fields with dynamic attributes with the backend
      // form settings:
      return {
        ...form,
        columns: {
          ...(
            type !== 'group' && { metaScript: getEntityMetaScript }
          ),
          ...mergeActionWithTemplate(commonTemplateFields(isPublic), form)
        }
      };
    }
  }
  return null;
};

const getDynamicStyles = (styleTemplate, data) => {
  let templateChanges = {};
  Object.keys(styleTemplate)
    .filter(key => key !== 'metaScript')
    .forEach(fieldName => {
      const templateElement = styleTemplate[fieldName];
      let fieldChanges = {};
      if (templateElement.metaScript) {
        fieldChanges = templateElement.metaScript(data[fieldName], styleTemplate[fieldName]);
      }
      if (templateElement.children) {
        const nestedChanges = getDynamicStyles(templateElement.children, data[fieldName] || {});
        if (nestedChanges) {
          fieldChanges = R.mergeDeepRight(fieldChanges, {children: nestedChanges});
        }
      }
      if (Object.keys(fieldChanges).length > 0) {
        templateChanges = R.mergeDeepRight(templateChanges, {[fieldName]: fieldChanges});
      }
    });
  if (Object.keys(templateChanges).length > 0) {
    return templateChanges;
  }
  return {};
};

export const applyDynamicStyles = (styleTemplate, data) => {
  const { metaScript, ...newTemplate } = styleTemplate;
  let changes = {};
  if (metaScript) {
    changes = metaScript(data, styleTemplate);
  }
  changes = R.mergeDeepRight(changes, getDynamicStyles(newTemplate, data));
  if (Object.keys(changes).length > 0) {
    return R.mergeDeepRight(newTemplate, changes);
  }
  return newTemplate;
};

const getMetadataDefaults = (metadata, dataTypes) => {
  const metadataChanges = {};
  Object.keys(metadata).forEach(fieldName => {
    const meta = metadata[fieldName];
    const fieldChanges = {};
    if (meta.data && dataTypes[meta.data] && (meta.defaultName || meta.defaultId || meta.defaultIds)) {
      let defaultType = null;
      const values = R.values(dataTypes[meta.data]);
      if (meta.defaultId) {
        defaultType = values.find(type => type.id === meta.defaultId);
      } else
        if (meta.defaultName) {
          // If the field has a defaultName and is a category, lookup the category type id
          // and use that to restrict the data source values (the contents of the 'value'
          // variable). Basically, we are only showing the values for the category that this
          // field represents, since 'values' contains values for all categories
          // and some categories might share the same name value, like 'Active'.
          if (fieldName.startsWith('|')) {
            const categoryTypes = dataTypes.map_category_type;
            if (categoryTypes) {
              const categoryType = Object.values(categoryTypes).find(type => type.name === fieldName.substring(1));
              if (categoryType) {
                defaultType = values.find(type => type.name === meta.defaultName && type.type === categoryType.id);
              }
            }
          } else {
            defaultType = values.find(type => type.name === meta.defaultName);
          }
        }
      if (meta.defaultIds && meta.defaultMatchField) {
        const defaultTypes = values.filter(type => meta.defaultIds.includes(type[meta.defaultMatchField]));
        if (defaultTypes.length > 0) {
          fieldChanges.defaultIds = defaultTypes.map(type => type.id);
        }
      }
      if (defaultType) {
        fieldChanges.default = defaultType.id;
      }
    }
    if (metadata.children) {
      const nestedChanges = getMetadataDefaults(meta.children, dataTypes);
      if (nestedChanges) {
        fieldChanges.children = nestedChanges;
      }
    }
    if (fieldChanges) {
      metadataChanges[fieldName] = fieldChanges;
    }
  });
  return metadataChanges;
};

export const computeMetadataDefaults = (action, dataTypes) => {
  const changes = getMetadataDefaults(action, dataTypes);
  if (changes) {
    return R.mergeDeepRight(action, changes);
  }
  return action;
};

export const parseLog = error => {
  if (error.column) {
    return `${error.column}: ${error.details}`;
  }
  try {
    const detailsObject = JSON.parse(error.details.replace(/'/g, '"'));
    if (typeof detailsObject === 'string') {
      return detailsObject;
    }
    if (detailsObject instanceof Array) {
      return detailsObject.join(', ');
    }
    const entries = Object.entries(detailsObject);
    if (entries.length > 0) {
      return (
        entries.map(([column, description]) => {
          if (description instanceof Array) {
            return `${column}: ${description.join(', ')}`;
          }
          return `${column}: ${description}`;
        })
      ).join('. ');
    }
    return detailsObject;
  } catch (err) {
    if (!(err instanceof SyntaxError)) {
      throw err;
    }
  }
  return error.details;
};

export const isNumeric = value => {
  if (typeof value === 'number') {
    return true;  // Already a number.
  }
  // If it's not a number we'll process it from a string,
  // so if it's not a string, it's already not a valid number.
  if (typeof value !== 'string') {
    return false;
  }
  // Something containing letters will make isNaN() tell it's not a number
  // (unless it's something like '1e10000' which is a number).
  // However an empty string, or one with just spaces, or a boolean will
  // also return the same as if we supplied it with a number,
  // so we need to use parseFloat() too make empty/space strings fail.
  return !isNaN(value) && !isNaN(parseFloat(value));
};

// R.assocPath interprets numbers in a path as array indexes, but only if the number is
// not a string.
//
// i.e. if 'field_values.10.name' is split into ['field_values', '10', 'name'], the '10' is
// a string, not a number, thus it's interpreted as an object property named '10'.
//
// In order for assocPath to interpret it as an array index, we must supply it
// as ['field_values', 10, 'name'] (assocPath will take care of creating an array
// if it doesn't exist and will fill the previous 9 elements with 'undefined').
export const splitAssocPath = pathsString => {
  const paths = pathsString.split('.');
  return paths.map(path => {
    // Convert the path element into a number if it's a number:
    if (isNumeric(path)) {
      return Number(path);
    }
    return path;
  });
};
