import React, { Fragment, memo } from 'react';
import { Link, useParams } from 'react-router-dom';
import AgencyActions from '@components/portal/details/top-bar-agency-actions';
import UserActions from '@components/portal/details/top-bar-user-actions';
import { colors } from '@constants/colors';
import { PERMISSION_GROUP_ADMIN } from '@constants/permission';
import { Icon, IconButton } from '@mui';
import { allowedFor } from '@utils/permission-utils';

const TopBarViewActions = () => {
  const { dataType, id } = useParams();

  if (!allowedFor([PERMISSION_GROUP_ADMIN])) {
    return null;
  }

  return (
    <Fragment>
      {dataType !== 'batch' &&
        <Link to={`/${dataType}/${id}/edit`}>
          <IconButton tooltip="Edit">
            <Icon color={colors.opacity.black0_8}>edit</Icon>
          </IconButton>
        </Link>
      }
      {dataType === 'agency' && <AgencyActions />}
      {dataType === 'user' && <UserActions />}
    </Fragment>
  );
};

export default memo(TopBarViewActions);
