import React, { memo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import styles from './list-header.scss';

const ListHeader = ({
  after = null,
  before = null,
  className,
  count = null,
  secondaryText = null,
  text,
  ...passThroughProps
}) => {

  const listHeaderClassName = classNames(
    className,
    styles.listHeader,
    {
      [styles.twoLine]: secondaryText
    }
  );

  return (
    <li
      className={listHeaderClassName}
      {...passThroughProps}
    >
      <span className={styles.primary}>
        {before &&
          <span className={styles.before}>
            {before}
          </span>
        }
        <span className={styles.primaryText}>
          {text}
          {(count || count === 0) &&
            <span className={styles.count}>{count}</span>
          }
        </span>
        {after &&
          <span className={styles.after}>
            {after}
          </span>
        }
      </span>
      {secondaryText &&
      <span className={styles.secondary}>{secondaryText}</span>
      }
    </li>
  );
};

ListHeader.propTypes = {
  after: PropTypes.node,
  before: PropTypes.node,
  className: PropTypes.string,
  count: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  secondaryText: PropTypes.string,
  text: PropTypes.string
};

export default memo(ListHeader);
