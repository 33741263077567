import React from 'react';
import PropTypes from 'prop-types';
import { dotmapsBlack60 } from '@constants/colors';

const AddToGroupIcon = ({ color, index }) => (
  <svg width="24px" height="24px" version="1.1" viewBox="0 0 24 24">
    <defs>
      <path
        id={`path-${index}`}
        d={`
            M12.5 11H11v6h2v2h1.362c-1.319.64-2.799 1-4.362 1-5.52 0-10-4.48-10-10S4.48 0 10
            0s10 4.48 10 10c0 .337-.017.671-.05 1H19V9h-6v1.708a2.5 2.5 0 0 0-.5.292zM6
            15.5a2.5 2.5 0 0 0 0-5 2.5 2.5 0 0 0 0 5zM7.5 6a2.5 2.5 0 0 0 5 0 2.5 2.5 0
            0 0-5 0zm7.752 7.252V11h1.495v2.252H19v1.495h-2.253V17h-1.495v-2.252H13v-1.495h2.252z
           `}
      />
    </defs>
    <g fill="none" fillRule="evenodd" transform="translate(2 2)">
      <mask id={`mask-${index}`} fill="#fff">
        <use xlinkHref={`#path-${index}`} />
      </mask>
      <g fill={color} mask={`url(#mask-${index})`}>
        <path d="M-2-2h24v24H-2z" />
      </g>
    </g>
  </svg>
);

AddToGroupIcon.propTypes = {
  color: PropTypes.string,
  index: PropTypes.number
};

AddToGroupIcon.defaultProps = {
  color: dotmapsBlack60
};

export default AddToGroupIcon;
