import React from 'react';
import { useParams } from 'react-router-dom';
import TaskType from './task-type';
import Workflow from './workflow';

const Details = () => {
  const { dataType } = useParams();

  if (dataType === 'task_type') {
    return <TaskType />;
  }

  if (dataType === 'workflow') {
    return <Workflow />;
  }

  return null;
};

export default Details;
