import React, { memo, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { closeDashboardDialog } from '@actions/dashboard-actions';
import * as dialog from '@constants/dialogs';
import Dialog from '@shared/dialogs/dialog';
import DialogActions from '@shared/dialogs/dialog-actions';

const BulkDeleteFilesDialog = () => {
  const dispatch = useDispatch();

  const { activeDialogs, dialogParams } = useSelector(state => state.dashboard);

  const isDialogActive = activeDialogs[dialog.BULK_DELETE_FILES];

  const onClose = useCallback(
    () => {
      dispatch(closeDashboardDialog(dialog.BULK_DELETE_FILES));
    },
    [dispatch]
  );

  const onDelete = useCallback(
    () => {
      const { bulkDeleteFiles, ids } = dialogParams[dialog.BULK_DELETE_FILES] || {};
      bulkDeleteFiles(ids);
      onClose();
    },
    [dialogParams, onClose]
  );

  if (!isDialogActive || !dialogParams) {
    return null;
  }

  return (
    <Dialog
      actions={
        <DialogActions
          onCancel={onClose}
          onSubmit={onDelete}
          submitColor="secondary"
          submitLabel="DELETE"
        />
      }
      title="Delete Files?"
    >
      Are you sure you want to delete the selected files?
    </Dialog>
  );
};

export default memo(BulkDeleteFilesDialog);
