import {
  WORKFLOW_CYCLE_FETCH_INIT,
  WORKFLOW_CYCLE_FETCH_ERROR,
  WORKFLOW_CYCLE_FETCH_SUCCESS
} from '@constants/action-types';
import initialState from '../store/initial-state';

const workflowReducer = (state = initialState.workflow, action) => {
  switch (action.type) {
  case WORKFLOW_CYCLE_FETCH_INIT:
    return { ...state, loading: true, saving: false };
  case WORKFLOW_CYCLE_FETCH_SUCCESS:
    return { ...state, cycle: { ...action.payload }, errors: {}, loading: false };
  case WORKFLOW_CYCLE_FETCH_ERROR:
    return { ...state, cycle: null, errors: { ...action.payload }, loading: false };
  default:
    return state;
  }
};

export default workflowReducer;
