import React, { memo, useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { setOverlapHoverLayer, openLayerTray } from '@actions/map-actions';
import CityLayerListItem from '../list/city-layer-list-item';

import { getHoverLayerId } from '@selectors/map-selector';

import styles from './city-layer-card.scss';

const CityLayerCard = ({
  layerItem,
  trayHover = false,
  type,
  ...passThroughProps
}) => {
  const dispatch = useDispatch();
  const selectedLayerItemId = useSelector(state => getHoverLayerId(state));
  const selected = layerItem.id === selectedLayerItemId;

  const handleMouseEnter = useMemo(() => {
    if (trayHover) {
      return () => dispatch(setOverlapHoverLayer(layerItem));
    }
    return null;
  }, [dispatch, layerItem, trayHover]);

  const handleMouseExit = useMemo(() => {
    if (trayHover) {
      return () => dispatch(setOverlapHoverLayer());
    }
    return null;
  }, [dispatch, trayHover]);

  const openLayerItem = useCallback(
    () => {
      const layerItemProps = {
        id: layerItem.id,
        type
      };
      dispatch(openLayerTray(layerItemProps));
    },
    [dispatch, layerItem, type]
  );
  return (
    <CityLayerListItem
      onClick={openLayerItem}
      layerItem={layerItem}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseExit}
      className={classNames(styles.container, {[styles.selected]: selected})}
      {...passThroughProps}
    />
  );
};

CityLayerCard.propTypes = {
  layerItem: PropTypes.object,
  trayHover: PropTypes.bool,
  type: PropTypes.string
};

export default memo(CityLayerCard);
