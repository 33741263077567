import React, { useCallback, useContext } from 'react';
import { useDispatch } from 'react-redux';
import { push } from 'connected-react-router';
import DetailsContext from '@components/workflow/details-context';
import ActivitiesContent from './activities-content';
import ActivityRow from './activity-row';
import './activities-tab.scss';

const ActivitiesTab = () => {
  const dispatch = useDispatch();
  const { cycle, task } = useContext(DetailsContext);

  const onSelectActivity = useCallback(
    activity => {
      dispatch(push(`/cycle/${cycle.id}/task/${activity.task}/activity/${activity.id}`));
    },
    [cycle.id, dispatch]
  );

  return (
    <div styleName="activities-tab-container">
      <div styleName="list">
        {task?.activities?.map(item => (
          <ActivityRow
            activity={item}
            key={item.id}
            onClick={onSelectActivity}
            taskDueDate={task.due_date}
          />
        ))}
      </div>
      <ActivitiesContent />
    </div>
  );
};

export default ActivitiesTab;
