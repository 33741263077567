import {
  OPEN_CONFIRMATION_DIALOG,
  CLOSE_CONFIRMATION_DIALOG,
  DISCARD_CHANGES
} from '@constants/action-types';
import initialState from '../store/initial-state';

const confirmationReducer = (state = initialState.confirmation, action) => {
  switch (action.type) {
  case OPEN_CONFIRMATION_DIALOG: {
    return { ...state, ...action.payload, visible: true };
  }
  case CLOSE_CONFIRMATION_DIALOG: {
    return { discard: state.discard, visible: false };
  }
  case DISCARD_CHANGES: {
    return { ...state, discard: action.discard };
  }
  default:
    return state;
  }
};

export default confirmationReducer;
