import React from 'react';
import { capitalize } from 'lodash';
import { getEntityTypeLabel } from '@constants/config';
import TruncatedCell from '@shared/data-table/cells/truncated-cell';
import { renderCustomIconAndLabel } from '@utils/icon-utils';
import { enDash, renderTooltipDate } from '@utils/shared-utils';

const getCreated = batch => batch.created_by.name ? batch.created_by.name : batch.created_by.email;

const getBatchTypeLabel = typeName => {
  switch (typeName) {
  case 'Layer':
    return 'City Layer';
  default:
    return capitalize(getEntityTypeLabel(typeName.toLowerCase()));
  }
};

const getStatusColor = batch => {
  if (batch?.error_count > 0) {
    return '#f44336';
  }

  // Generic color for an unknown status:
  const text = batch?.status?.toLowerCase();
  if (text === 'pending') {
    return '#ffeb3b';
  }
  if (text === 'processing') {
    return '#2196f3';
  }
  if (text === 'processed') {
    return '#4caf50';
  }

  return '#9e9e9e';
};

const renderStatus = batch => {
  const text = batch?.status;
  const color = getStatusColor(batch);

  const icon = (
    <span style={{
      backgroundColor: color,
      borderRadius: '50%',
      display: 'inline-flex',
      marginRight: '0.5rem',
      height: '0.75rem',
      width: '0.75rem'
    }} />
  );

  return renderCustomIconAndLabel(text, icon, batch?.id);
};

export const optimizeBatchUploadData = (batch, agencies) => ({
  ...batch,
  file: <TruncatedCell>{batch.file}</TruncatedCell>,
  agency: agencies[batch.created_by.agency] ? agencies[batch.created_by.agency].name : enDash,
  created: renderTooltipDate(batch.created),
  status: renderStatus(batch),
  type_name: getBatchTypeLabel(batch.type_name),
  uploaded_by: <TruncatedCell>{getCreated(batch)}</TruncatedCell>
});

export const optimizeBatchUploadDetail = (batch) => ({
  ...batch,
  type_name: getBatchTypeLabel(batch.type_name)
});
