import React from 'react';
import PropTypes from 'prop-types';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import { Checkbox } from '@mui';

// Checkbox for the main map filters.
//
// The difference with the standard Checkbox is that it has no padding
// around the checkbox, and that we use smaller icons for the check mark
// (so there's more space for the label and hey have less truncation).
const FilterCheckbox = ({ checked, disabled, onChange }) => (
  <Checkbox
    checked={checked}
    disabled={disabled}
    checkedIcon={<CheckBoxIcon style={{ fontSize: '1.125rem' }} />}
    icon={<CheckBoxOutlineBlankIcon style={{ fontSize: '1.125rem' }} />}
    onChange={onChange}
    size="small"
    style={{ padding: '0' }}
  />
);

FilterCheckbox.propTypes = {
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
  onChange: PropTypes.func
};

export default FilterCheckbox;
