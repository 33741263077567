import { get } from 'lodash';
import { BASE_API_URL } from '@constants/endpoints';
import { buildFilterMenuItem } from '@utils/filter-utils';

export const overlapUrl = id => `${BASE_API_URL}/overlap/${id}/`;

export const overlapLink = id => `/overlap/${id}`;

export const optimizeOverlapForTray = (id, overlap, position) => ({
  position,
  overlapId: id,
  overlap
});

// Return the entity specified by the id in the URL params.
// (i.e. ?focus=1234 or ?open=1234).
export const getUrlParamEntity = (entities, location, key) => {
  const searchParams = new URLSearchParams(location.search);
  const value = searchParams.get(key);
  if (value) {
    const id = parseInt(value, 10);
    return entities?.find(entity => entity?.id === id) || null;
  }
  return null;
};

export const isLeadEntityVisible = (mainEntity, entities) => entities.filter(entity => entity.id === mainEntity.id);

const isChecked = (filter, id) => get(filter, id, true);

export const buildMenuItem = (filter, item) => buildFilterMenuItem(isChecked(filter, item.id), null, item);

export const getItemHeight = mainEntity => document.getElementById(`list-item-${mainEntity.id}`).offsetHeight;
