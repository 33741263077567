import React from 'react';
import PropTypes from 'prop-types';

// Uses the same SVG path as Material-UI's DropDownArrow icon,
// we duplicate it here to be able to set the fill color for
// DropdownMenus, which doesn't allow caret customization.
const DropDownArrowIcon = ({ color }) => (
  <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M7 10l5 5 5-5z"
      fill={color}
    />
  </svg>
);

DropDownArrowIcon.propTypes = {
  color: PropTypes.string
};

export default DropDownArrowIcon;
