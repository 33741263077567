import * as R from 'ramda';
import { LOCATION_CHANGE } from 'connected-react-router';
import {
  AUTOCOMPLETE_VALUES_CHANGED,
  AUTOCOMPLETE_LOADER_CHANGED,
  // DATA_DETAIL_FETCH_SUCCESS,
  DASHBOARD_DETAIL_DATA_CHANGED,
  DASHBOARD_DETAIL_UPDATE_FIELD,
  DASHBOARD_DETAIL_DATA_SAVE_ERROR,
  DASHBOARD_DETAIL_DATA_CLEAR_SAVE_ERROR_FIELD,
  DASHBOARD_DETAIL_DATA_SAVING,
  DASHBOARD_DIALOG_PARAM,
  DASHBOARD_POP_MESSAGE,
  DASHBOARD_PUSH_MESSAGE,
  DASHBOARD_CLOSE_DIALOG,
  DASHBOARD_OPEN_DIALOG,
  DASHBOARD_RESOLUTION_LOADING,
  GROUPS_ENTITY_OVERLAPS_FETCH_SUCCESS,
  HANDLE_SERVER_ERROR
} from '@constants/action-types';
import { OVERLAP_STATUS } from '@constants/dialogs';
import initialState from '../store/initial-state';

// Fills the dialog parameters based on the fetched conflict data:
const overlapToDialogState = (state, data) => {
  const { entity: mainEntity, conflicts } = data;
  const entityId = R.pathOr(null, [OVERLAP_STATUS, 'overlapEntityId'], state.dialogParams);
  const entity = conflicts.find(({id}) => id === entityId);
  return {
    dialogParams: {
      ...state.dialogParams,
      [OVERLAP_STATUS]: {
        ...state.dialogParams[OVERLAP_STATUS],
        mainEntity,
        entity
      }
    }
  };
};

// Removes empty objects recursively from the given object.
const removeEmptyObjects = obj => {
  if (typeof obj !== 'object' || obj === null) {
    return obj;
  }

  if (Array.isArray(obj)) {
    return obj;
  }

  const cleaned = Object.entries(obj)
    .map(([key, value]) => [key, removeEmptyObjects(value)])
    .filter(([_, value]) => typeof value !== 'undefined')  // eslint-disable-line id-length, no-unused-vars
    .reduce((acc, [key, value]) => ({ ...acc, [key]: value }), {});

  // eslint-disable-next-line no-undefined
  return Object.keys(cleaned).length ? cleaned : undefined;
};

// Enhanced version of dissocPath that also cleans up empty parent objects.
const dissocPathAndClean = (path, state) => {
  const afterDissoc = R.dissocPath(path, state);
  return removeEmptyObjects(afterDissoc) || {};
};

const dashboardReducer = (state = initialState.dashboard, action) => {
  switch (action.type) {
  case DASHBOARD_DETAIL_DATA_CHANGED: {
    const { dataType, payload } = action;
    const originalDetails = state.details || {};
    const originalSaving = state.saving || {};
    const detailData = originalDetails[dataType] || {};
    const details = { ...originalDetails, [dataType]: { ...detailData, data: payload, error: null } };
    const saving = { ...originalSaving, [dataType]: false };
    return { ...state, details, saving };
  }
  case DASHBOARD_DETAIL_UPDATE_FIELD: {
    const { dataType, field, data } = action;
    const updatePath = field.split('.');
    const lens = R.lensPath(['details', dataType, 'data', ...updatePath]);
    return R.set(lens, data, state);
  }
  case HANDLE_SERVER_ERROR: {
    const { dataType } = action;
    const originalSaving = state.saving || {};
    const saving = { ...originalSaving, [dataType]: false };
    return { ...state, saving };
  }
  case DASHBOARD_DETAIL_DATA_SAVE_ERROR: {
    const { dataType, payload } = action;
    const originalDetails = state.details || {};
    const originalSaving = state.saving || {};
    const detailData = originalDetails[dataType] || {};
    const details = { ...originalDetails, [dataType]: { ...detailData, error: payload } };
    const saving = { ...originalSaving, [dataType]: false };
    return { ...state, details, saving };
  }
  case DASHBOARD_DETAIL_DATA_CLEAR_SAVE_ERROR_FIELD: {
    const { dataType, path } = action;
    const errorPath = [dataType, 'error', ...path];
    const details = { ...dissocPathAndClean(errorPath, state.details) };
    return { ...state, details };
  }
  case AUTOCOMPLETE_VALUES_CHANGED: {
    return {
      ...state,
      autocomplete: { ...state.autocomplete, ...action.payload }
    };
  }
  case AUTOCOMPLETE_LOADER_CHANGED: {
    return {
      ...state,
      autocomplete: { ...state.autocomplete, loading: action.payload }
    };
  }
  case DASHBOARD_DIALOG_PARAM: {
    const { dialog, params } = action;
    return {
      ...state,
      dialogParams: {
        ...state.dialogParams,
        [dialog]: params
      }
    };
  }
  case DASHBOARD_CLOSE_DIALOG: {
    const { dialog } = action;
    return {
      ...state,
      activeDialogs: {
        ...state.activeDialogs,
        [dialog]: false
      },
      dialogParams: {
        ...state.dialogParams,
        [dialog]: null
      }
    };
  }
  case DASHBOARD_OPEN_DIALOG: {
    const { dialog, params } = action;
    return {
      ...state,
      activeDialogs: {
        ...state.activeDialogs,
        [dialog]: true
      },
      dialogParams: {
        ...state.dialogParams,
        [dialog]: params
      }
    };
  }
  case GROUPS_ENTITY_OVERLAPS_FETCH_SUCCESS: {
    // When we revoke or resolve a conflict on the groups page,
    // we fetch the overlap's entity data again, thus we must
    // catch this action and inject the new data in the dialog
    // so, if it's open, it will reflect the changes.
    const newState = overlapToDialogState(state, action.payload.data);
    return { ...state, ...newState };
  }
  // case DATA_DETAIL_FETCH_SUCCESS: {
  //   const { dataType, data } = action;
  //   if (dataType === 'conflict') {
  //     const newState = overlapToDialogState(state, data);
  //     return { ...state, ...newState };
  //   }
  //   return state;
  // }
  case DASHBOARD_RESOLUTION_LOADING: {
    const { loading } = action;
    return {
      ...state,
      resolution: { loading }
    };
  }
  case DASHBOARD_DETAIL_DATA_SAVING: {
    const { dataType } = action;
    const originalSaving = state.saving || {};
    const saving = { ...originalSaving, [dataType]: true };
    return { ...state, saving };
  }
  case DASHBOARD_POP_MESSAGE: {
    const [, ...remaining] = state.snackbar.messages;
    return {
      ...state,
      snackbar: {
        messages: remaining
      }
    };
  }
  case DASHBOARD_PUSH_MESSAGE: {
    return {
      ...state,
      snackbar: {
        messages: [action.message, ...state.snackbar.messages]
      }
    };
  }
  // Listen to location changes and clear the state, data is always loaded
  // later, thus if we don't clear it, we are initially displaying old data.
  case LOCATION_CHANGE: {
    if (action?.payload) {
      const { location: { state: locationState } } = action.payload;
      // If we pushed something with browserHistory with a 'clear' flag, it means
      // we wanted to clear the data directly:
      if (locationState?.clear) {
        return initialState.dashboard;
      }
    }
    return state;
  }
  default:
    return state;
  }
};

export default dashboardReducer;
