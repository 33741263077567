import * as R from 'ramda';
import { LOCATION_CHANGE } from 'connected-react-router';
import ReactGA from 'react-ga4';

const trackingReducer = (state = {}, action) => {
  switch (action.type) {
  case LOCATION_CHANGE: {
    if (action && action.payload) {
      const { pathname, search } = R.pathOr(null, ['payload', 'location'], action);
      ReactGA.send({ hitType: 'pageview', page: `${pathname}${search}`});
    }
    return state;
  }
  default:
    return state;
  }
};

export default trackingReducer;
