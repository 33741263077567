import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { isString } from 'lodash';
import Snackbar from 'material-ui/Snackbar';
import { popApplicationMessage } from '@actions/messages-actions';
import { snackbarStyles } from '@constants/mui-theme';

class InfoSnackbar extends Component {
  getSnackbarProps = () => {
    const { messages } = this.props;
    const isOpen = messages.length > 0;
    const message = messages[0] || '';
    let props = {
      open: isOpen,
      autoHideDuration: 5000,
      onRequestClose: this.props.popMessage,
      ...snackbarStyles(isOpen)
    };

    if (isString(message)) {
      props.message = message;
    } else {
      props = { ...props, ...message };
    }

    return props;
  };

  render() {
    return <Snackbar {...this.getSnackbarProps()} />;
  }
}

InfoSnackbar.propTypes = {
  messages: PropTypes.array,
  popMessage: PropTypes.func
};

const mapStateToProps = state => {
  const { messages } = state;
  return { messages };
};

const mapDispatchToProps = {
  popMessage: popApplicationMessage
};

export default connect(mapStateToProps, mapDispatchToProps)(InfoSnackbar);
