/* eslint-disable react/jsx-no-bind */
import React from 'react';
import PropTypes from 'prop-types';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { Checkbox } from '@mui';
import Cell from './cell';

const Head = ({ checkedProps, columns, onToggleAll }) => (
  <TableHead>
    <TableRow>
      {columns.map(column => {
        const style = {
          ...(column.style || {}),
          whiteSpace: 'nowrap'
        };
        if (column.key === 'checkbox') {
          const { isAllChecked, isAnyChecked } = checkedProps;
          return (
            <Cell key={`header-${column.key}`} style={style}>
              <Checkbox
                checked={isAnyChecked}
                indeterminate={isAnyChecked && !isAllChecked}
                onChange={onToggleAll}
                onClick={event => event.stopPropagation()}
                size="small"
              />
            </Cell>
          );
        }
        return (
          <Cell key={`header-${column.key}`} style={style}>{column.label}</Cell>
        );
      })}
    </TableRow>
  </TableHead>
);

Head.propTypes = {
  checkedProps: PropTypes.object,
  columns: PropTypes.array,
  onToggleAll: PropTypes.func
};

export default Head;
