import { createSelector } from 'reselect';

import { getMap, getTrays } from '@selectors/map-selector';
import { ADD_TO_GROUP_TRAY, OVERLAP_TRAY } from '@constants/map-trays';

const OVERLAP_TRAY_DATA_DEFAULT = {};
export const getOverlapTrayData = createSelector(
  getTrays,
  trays => trays.find(({trayType}) => trayType === OVERLAP_TRAY)?.data || OVERLAP_TRAY_DATA_DEFAULT
);

const ADD_TO_GROUP_TRAY_DATA_DEFAULT = {};
export const getAddToGroupTrayData = createSelector(
  getTrays,
  trays => trays.find(({trayType}) => trayType === ADD_TO_GROUP_TRAY)?.data || ADD_TO_GROUP_TRAY_DATA_DEFAULT
);

export const getOverlapTrayDataOverlap = createSelector(
  getOverlapTrayData,
  overlapTrayData => {
    const {entities = {}, layers = {}, groups = []} = overlapTrayData.overlap || {};
    return {
      entities,
      layers,
      groups
    };
  }
);

const DEFAULT_ENTITY_DATA = {};
export const getAllEntityDataListStable = createSelector(
  state => state.entities?.types,
  (allEntityData) => {
    if (
      Object.values(allEntityData).some(typeData => typeData.pendingLoad)
    ) {
      // While loading is in progress, return no entities as they will be changing each time a load completes.
      return DEFAULT_ENTITY_DATA;
    }
    return allEntityData;
  }
);

export const getOverlapTrayItems = createSelector(
  getOverlapTrayDataOverlap,
  state => state.map.drawing?.shape || null,
  getAllEntityDataListStable,
  (overlaps, drawingShape, allEntityData) => {
    // If a drawing shape is defined, take the entities from all
    // existing entities in the map (which are already filtered
    // by the shape).
    if (drawingShape) {
      const {layers = {}, groups = []} = overlaps;
      const allEntitiesByType = {};
      Object.entries(allEntityData).forEach(([entityType, {list}]) => {
        allEntitiesByType[entityType] = list;
      });
      return {
        entities: allEntitiesByType || {},
        layers,
        groups
      };
    }
    return overlaps;
  }
);

export const getOverlapTrayPosition = createSelector(
  getOverlapTrayData,
  overlapTrayData => overlapTrayData.position
);


export const getCachedOverlapEntities = createSelector(
  getMap,
  map => map.overlapTrayEntities
);


export const getCachedOverlapEntityIds = createSelector(
  getCachedOverlapEntities,
  cachedOverlapEntities => {
    const entityIds = {};
    Object.entries(cachedOverlapEntities).forEach(([type, entities]) => {
      entityIds[type] = new Set(Object.keys(entities || {}).map(key => parseInt(key, 10)));
    });
    return entityIds;
  }
);

export const getOverlapTrayItemsWithEntityDetails = createSelector(
  getOverlapTrayItems,
  getCachedOverlapEntities,
  (overlapTrayItems, overlapTrayEntitiesCache) => {
    const detailEntities = {};
    Object.entries(overlapTrayItems.entities).forEach(([type, typeEntities]) => {
      if (overlapTrayEntitiesCache[type]) {
        detailEntities[type] = typeEntities.map(entity => {
          if (entity.id in overlapTrayEntitiesCache[type]) {
            return overlapTrayEntitiesCache[type][entity.id] || entity;
          }
          return entity;
        });
      } else {
        detailEntities[type] = typeEntities;
      }
    });
    return {
      ...overlapTrayItems,
      entities: detailEntities
    };
  }
);
