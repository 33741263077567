/* eslint-disable react/jsx-no-bind */
/* eslint-disable react/jsx-key */
import React, { memo, useCallback, useMemo } from 'react';
import * as R from 'ramda';
import { useSelector } from 'react-redux';
import { includes } from 'lodash';
import PropTypes from 'prop-types';
import {
  activeToOptions,
  CustomAutocomplete,
  defaultAutoCompleteProps,
  renderInput,
  renderTags
} from '@utils/autocomplete-utils';
import './input-search.scss';

const InputSearch = ({
  autocompleteContainerStyles,
  avatarProps,
  chipSize,
  containerStyles,
  deleteIcon,
  disabled,
  errors,
  fieldName,
  filterFunc,
  label,
  limit,
  onChange,
  onInputChange,
  options,
  placeHolder,
  squareChips,
  storePath,
  renderOption,
  textReadOnly,
  toOptions,
  values,
  variant
}) => {
  const data = useSelector(state => R.path(storePath || [], state));

  const optionList = useMemo(() => {
    if (options) {
      return options.map(option => ({ title: option.name, value: option.id }));
    }
    const convertOptionsFunc = toOptions ? toOptions : activeToOptions;
    return convertOptionsFunc(data, filterFunc);
  }, [data, filterFunc, options, toOptions]);

  const id = useMemo(() => `${fieldName}-input-search-textfield`, [fieldName]);

  // Return the options that match the selected values:
  const defaultValues = useMemo(() => optionList?.filter(option => includes(values, option.value)), [optionList, values]);

  const handleChange = useCallback((event, newValues) => {
    // If a limit was set, only keep selected the new value:
    if (limit === 1) {
      // Remove from the list of selected options the ones that are already on the old list:
      const newValue = newValues.filter(val => !defaultValues.includes(val));
      onChange(event, newValue);
    } else {
      onChange(event, newValues);
    }
  }, [defaultValues, limit, onChange]);

  const autoCompleteProps = useMemo(() => {
    const props = { ...defaultAutoCompleteProps };
    if (renderOption) {
      props.renderOption = renderOption;
    }
    return props;
  }, [renderOption]);

  if (!optionList) {
    return null;
  }

  return (
    <div styleName="input-search-container" style={{...containerStyles}}>
      <div styleName="input-search-autocomplete-container" style={{...autocompleteContainerStyles}}>
        <CustomAutocomplete
          {...autoCompleteProps}
          disabled={disabled}
          id={id}
          options={optionList}
          defaultValue={defaultValues}
          value={defaultValues}
          onChange={handleChange}
          onInputChange={onInputChange}
          renderTags={renderTags({
            avatarProps,
            chipSize,
            deleteIcon,
            disabled,
            squareChips
          })}
          renderInput={renderInput(errors, label, placeHolder, variant, textReadOnly)}
        />
      </div>
    </div>
  );
};

InputSearch.propTypes = {
  autocompleteContainerStyles: PropTypes.object,
  avatarProps: PropTypes.object,
  chipSize: PropTypes.string,
  containerStyles: PropTypes.object,
  deleteIcon: PropTypes.node,
  disabled: PropTypes.bool,
  errors: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  fieldName: PropTypes.string,
  filterFunc: PropTypes.func,
  label: PropTypes.string,
  limit: PropTypes.number,
  onChange: PropTypes.func,
  onInputChange: PropTypes.func,
  options: PropTypes.array,
  placeHolder: PropTypes.string,
  renderOption: PropTypes.func,
  squareChips: PropTypes.bool,
  storePath: PropTypes.array,
  textReadOnly: PropTypes.bool,
  toOptions: PropTypes.func,
  values: PropTypes.any,  // eslint-disable-line react/forbid-prop-types
  variant: PropTypes.string
};

InputSearch.defaultProps = {
  autocompleteContainerStyles: {
    maxWidth: '16rem'
  },
  variant: 'standard'
};

export default memo(InputSearch);
