import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { isEmpty } from 'lodash';
import { getGanttConfig } from '@constants/config';
import { getGanttGroupById } from '@actions/groups-actions';
import { withRouter } from '@utils/router-utils';
import Content from './content';
import Empty from './empty';
import WarningDialog from './warning-dialog';
import './container.scss';

const NoDatesWarningDialog = ({ groupId }) => (
  <WarningDialog
    dialogType={getGanttConfig().warningDialogTypes.noDates}
    groupId={groupId}
    text="There are items in this group with no dates or just one date. These items will not be included in the Gantt chart until dates are provided."
  />
);

const PerformanceWarningDialog = ({ groupId }) => (
  <WarningDialog
    dialogType={getGanttConfig().warningDialogTypes.performance}
    groupId={groupId}
    text="This view will list items multiple times and could make this page load slow."
  />
);

NoDatesWarningDialog.propTypes = {
  groupId: PropTypes.string
};

PerformanceWarningDialog.propTypes = {
  groupId: PropTypes.string
};

class Container extends Component {
  UNSAFE_componentWillMount() {
    this.initializePage();
  }

  componentDidUpdate(prevProps) {
    const {
      location,
      locationType
    } = this.props;
    if (locationType) {
      const { locationTypes } = getGanttConfig();
      if (location !== prevProps.location ||
          // Reload when we change the location type,
          // but only if it's "by streets and blocks",
          // since it retrieves the additional block data
          // which takes more time.
          (locationType !== prevProps.locationType &&
           locationType === locationTypes.byStreetsAndBlocks.id
          )
      ) {
        this.initializePage();
      }
    }
  }

  initializePage = () => {
    const { dataId } = this.props.params;
    if (!!+dataId) {
      const { locationType } = this.props;
      const { locationTypes } = getGanttConfig();
      const getBlocks = locationType === locationTypes.byStreetsAndBlocks.id;
      this.props.getGanttGroupById(dataId, true, getBlocks);
    }
  };

  render() {
    const {
      noDatesWarning,
      performanceThresholdReached,
      rows
    } = this.props;
    if (!rows) {
      return null;
    }
    const { dataId } = this.props.params;
    const isRowsEmpty = isEmpty(rows);
    return (
      <div styleName="gantt-container">
        {!isRowsEmpty && <Content />}
        {isRowsEmpty && <Empty />}
        {noDatesWarning && <NoDatesWarningDialog groupId={dataId} />}
        {performanceThresholdReached && <PerformanceWarningDialog groupId={dataId} />}
      </div>
    );
  }
}

Container.propTypes = {
  getGanttGroupById: PropTypes.func,
  location: PropTypes.object,
  locationType: PropTypes.number,
  noDatesWarning: PropTypes.bool,
  params: PropTypes.object,
  performanceThresholdReached: PropTypes.bool,
  rows: PropTypes.array
};

const mapStateToProps = state => {
  const { noDatesWarning, performanceThresholdReached, rows } = state.groups.gantt;
  const { locationType } = state.groups.gantt.filters;
  return {
    locationType,
    noDatesWarning,
    performanceThresholdReached,
    rows
  };
};

const mapDispatchToProps = {
  getGanttGroupById
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Container));
