/* eslint-disable react/jsx-no-bind */
import React, { useEffect, useMemo, useState } from 'react';
import {
  Snackbar as MUISnackbar,
  SnackbarContent
} from '@material-ui/core';
import * as R from 'ramda';

import { Button, Icon, IconButton } from '@mui';
import { colors } from '@constants/colors';

const EMPTY_NOTIFICATION = {
  type: 'info',
  message: ''
};

const styles = {
  icon: {
    marginRight: '1rem',
    color: colors.neutral.white
  },
  button: {
    color: colors.neutral.white,
    minWidth: 0
  },
  info: {
    backgroundColor: colors.neutral.dark
  },
  error: {
    backgroundColor: colors.utility.error
  }
};

const icons = {
  info: null,
  warning: <Icon style={styles.icon}>warning</Icon>,
  error: <Icon style={styles.icon}>error</Icon>
};

export class SnackbarWrapper {
  constructor() {
    this.notifications = [];
    this.subscribers = [];
  }

  subscribe = subscriber => {
    this.subscribers = [...this.subscribers, subscriber];
  };

  notify = () => {
    this.subscribers.forEach(subscriber => subscriber(this.notifications));
  };

  getNotificationHandler = type => (notification) => {
    this.notifications = [...this.notifications, { ...notification, type }];
    this.notify();
  };

  handleExit = () => {
    this.notifications = R.remove(0, 1, this.notifications);
    this.notify();
  };

  info = this.getNotificationHandler('info');
  warning = this.getNotificationHandler('warning');
  error = this.getNotificationHandler('error');

  dismiss = () => {
    this.notifications = [];
    this.notify();
  };

  Provider = () => {
    const [open, setOpen] = useState(false);
    const [notifications, setNotifications] = useState([]);

    useEffect(() => {
      this.subscribe(setNotifications);

      return () => {
        this.subscribers = [];
      };
    }, []);

    useEffect(() => {
      // keeps peeling notifications off the front of the stack until 1 remains
      // this ensures component is properly opened and closed instead of the content being instantly replaced
      // setting open to false causes MUI Snackbar to call onExited which in turn removes the first notification
      // from the stack causing this effect to be run again, looping until 1 remains
      if (notifications.length >= 2) {
        setOpen(false);
      } else if (notifications.length === 1) {
        setOpen(true);
      }
    }, [notifications]);

    const notification = useMemo(() => {
      return R.pathOr(EMPTY_NOTIFICATION, [0], notifications);
    }, [notifications]);

    const {
      type,
      message = '',
      autoHideDuration = 10000,
      action,
      disableClickAwayDismiss,
      onActionClick = () => {},
      onDismiss = () => {}
    } = notification;

    const handleClose = (event, reason) => {
      if (!disableClickAwayDismiss || reason !== 'clickaway') {
        setOpen(false);
        onDismiss();
      }
    };

    const handleDismiss = () => {
      setOpen(false);
      onDismiss();
    };

    const handleActionClick = () => {
      setOpen(false);
      onActionClick();
    };

    return (
      <MUISnackbar
        open={open}
        autoHideDuration={autoHideDuration}
        onClose={handleClose}
        TransitionProps={{
          onExited: this.handleExit
        }}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <SnackbarContent
          message={
            <>
              {icons[type]}
              {message}
            </>
          }
          action={
            <>
              <Button
                onClick={handleActionClick}
                size="small"
                style={styles.button}
              >
                {action || 'Dismiss'}
              </Button>
              {action && (
                <IconButton size="small" onClick={handleDismiss}>
                  <Icon color={colors.neutral.white}>close</Icon>
                </IconButton>
              )}
            </>
          }
          style={type === 'error' ? styles.error : styles.info}
        />
      </MUISnackbar>
    );
  };
}

export const Snackbar = new SnackbarWrapper();
