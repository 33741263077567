import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import Avatar from '@material-ui/core/Avatar';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import { makeStyles } from '@material-ui/core/styles';
import { getAgencyIcon } from '@utils/icon-utils';
import './print-map.scss';

// Split the agency types array into groups of three elements:
const getAgencyGroups = agencyType => R.splitEvery(3, R.values(agencyType));

const useStyles = makeStyles(theme => ({
  small: {
    width: theme.spacing(3),
    height: theme.spacing(3)
  }
}));

const Footer = ({ agency_type }) => {
  const classes = useStyles();
  return (
    <div styleName="footer">
      {getAgencyGroups(agency_type).map((agencyGroup, index) => (
        <List key={index} dense>
          {agencyGroup.map(item => (
            <ListItem key={item.id}>
              <ListItemAvatar>
                <Avatar className={classes.small} src={getAgencyIcon(item)} />
              </ListItemAvatar>
              <ListItemText primary={item.name} />
            </ListItem>
          ))}
        </List>
      ))}
    </div>
  );
};

Footer.propTypes = {
  agency_type: PropTypes.object
};

const mapStateToProps = state => {
  const { agency_type } = state.dataTypes;
  return { agency_type };
};

export default connect(mapStateToProps, {})(Footer);
