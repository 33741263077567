import React, { Fragment, memo, useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { getSchema } from '@actions/batch-actions';

import BatchUploadErrors from '@components/portal/table/batch-upload-errors';

import * as colors from '@constants/colors';

import EntityTypeIcon from '@icons/entity-type-icon';

import { makeStyles } from '@material-ui/core/styles';
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';
import Collapse from '@material-ui/core/Collapse';
import Switch from '@material-ui/core/Switch';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import { renderFileIcon } from '@utils/file-utils';

import './batch-upload-entities-select-fields.scss';

const useStyles = makeStyles({
  root: {
    width: '100%'
  },
  container: {
    maxHeight: 440
  }
});

const NESTED_CELL_STYLE = {
  border: 'none',
  paddingBottom: 0,
  paddingTop: 0
};

const NestedCell = ({ open, children, colspan = 1 }) => (
  <TableCell colspan={colspan} style={NESTED_CELL_STYLE}>
    <Collapse in={open} timeout="auto" unmountOnExit>
      <div style={{ paddingBottom: '0.75rem', paddingTop: '0.75rem' }}>
        {children}
      </div>
    </Collapse>
  </TableCell>
);

NestedCell.propTypes = {
  children: PropTypes.node,
  colspan: PropTypes.number,
  open: PropTypes.bool
};

const Row = ({ children, disabled, fields, label, name, onToggle, required }) => {
  const [open, setOpen] = useState(true);

  const onToggleOpen = useCallback(() => setOpen(!open), [open, setOpen]);

  return (
    <Fragment>
      <TableRow hover role="checkbox" tabIndex={-1} onClick={onToggleOpen} style={{ cursor: children ? 'pointer' : 'default' }}>
        <TableCell>
          <Switch
            checked={fields[name]}
            color="primary"
            disabled={disabled}
            name={name}
            onChange={onToggle}
          />
        </TableCell>
        <TableCell>
          {label}
        </TableCell>
        <TableCell>
          <ArrowRightAltIcon htmlColor={colors.dotmapsDisabledText} />
        </TableCell>
        <TableCell>
          {label}{required ? ' *' : ''}
        </TableCell>
      </TableRow>
      {children &&
        <Fragment>
          <TableRow>
            <TableCell style={NESTED_CELL_STYLE}>{ /* empty switch */ }</TableCell>
            <NestedCell colspan={3} open={open} children={children.header} />
          </TableRow>
          {children.rows.map(row => (
            <TableRow key={row}>
              <TableCell style={NESTED_CELL_STYLE}>{ /* empty switch */ }</TableCell>
              <NestedCell open={open} children={row} />
              <NestedCell open={open} children={<ArrowRightAltIcon htmlColor={colors.dotmapsDisabledText} />} />
              <NestedCell open={open} children={row} />
            </TableRow>
          ))}
        </Fragment>
      }
    </Fragment>
  );
};

Row.propTypes = {
  children: PropTypes.object,
  disabled: PropTypes.bool,
  fields: PropTypes.array,
  label: PropTypes.string,
  name: PropTypes.string,
  onToggle: PropTypes.func,
  required: PropTypes.bool
};

const BatchUploadEntitiesSelectFields = ({ entity, file }) => {
  const [fields, setFields] = useState({});
  const [error, setError] = useState(null);
  const [rows, setRows] = useState([]);
  const classes = useStyles();

  // Load schema (it's the field list for the user to select).
  useEffect(() => {
    if (entity && file) {
      getSchema(file, entity, setError, setRows);
    }
  }, [entity, file, setError, setRows]);

  // Initialize fields when rows are loaded:
  useEffect(() => {
    if (rows.length > 0) {
      setFields(
        rows.filter(row => !row.disabled).map(({ name }) => ({ [name]: true }))
          .reduce((acc, obj) => {
            return { ...acc, ...obj };
          }, {})
      );
    }
  }, [rows.length, setFields]);

  const onToggleAll = useCallback(() => {
    // TODO: set all entries in fields to true or false.
  }, [fields, setFields]);

  const onToggle = useCallback(event => {
    setFields({ ...fields, [event.target.name]: event.target.checked });
  }, [fields, setFields]);

  return (
    <div styleName="select-fields-container">
      <div styleName="select-fields-header">
        <div styleName="select-fields-title">
          Select the columns you want to upload:
        </div>
        <div styleName="select-fields-subtitle">
          Unselected columns will be ignored and keep their existing values.
          Please select all required fields if you're creating entities. * Required field.
        </div>
      </div>
      {error && <BatchUploadErrors errors={[error]} />}
      {!error && (
        <TableContainer className={classes.container}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                <TableCell style={{ width: '2rem' }}>
                  <Switch
                    color="primary"
                    onChange={onToggleAll}
                  />
                </TableCell>
                <TableCell>
                  <div styleName="select-fields-file-item-container">
                    <div styleName="select-fields-file-item-icon">
                      {renderFileIcon(file.type)}
                    </div>
                    <div styleName="select-fields-file-item-name">
                      {file.name}
                    </div>
                  </div>
                </TableCell>
                <TableCell style={{ width: '1rem' }}>
                  {/* empty arrow column */}
                </TableCell>
                <TableCell>
                  <div styleName="select-fields-file-item-container">
                    <div styleName="select-fields-file-item-icon">
                      <EntityTypeIcon type={entity} size="1.5rem" color="#616161" />
                    </div>
                    <div styleName="select-fields-file-item-name">
                      {entity}
                    </div>
                  </div>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map(row => (
                <Row
                  children={row.children}
                  disabled={row.disabled}
                  fields={fields}
                  key={row.name}
                  label={row.label}
                  name={row.name}
                  onToggle={onToggle}
                  required={row.required}
                />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </div>
  );
};

BatchUploadEntitiesSelectFields.propTypes = {
  entity: PropTypes.string,
  file: PropTypes.object
};

export default memo(BatchUploadEntitiesSelectFields);
