import React from 'react';

const ConflictRevokeIcon = () => (
  <svg width="24px" height="24px" version="1.1" viewBox="0 0 24 24">
    <defs>
      <path
        id="conflict-revoke-path-1"
        d={`
            M1.27 0l2.57 2.65L16 15.2l1 1.03-1.27 1.27L2.8 4.07 0 1.27 1.27
            0zm10.74 5L16 9l-2.27 2.275L10.568 8h1.442V5zM5.008 8l1.931
            2H0V8h5.008z
        `}
      />
    </defs>
    <g fill="none" fillRule="evenodd" transform="translate(4 3)">
      <mask id="conflict-revoke-path-2" fill="#fff">
        <use xlinkHref="#conflict-revoke-path-1" />
      </mask>
      <g fill="rgba(0, 0, 0, 0.6)" mask="url(#conflict-revoke-path-2)">
        <path d="M-4-3h24v24H-4z" />
      </g>
    </g>
  </svg>
);

export default ConflictRevokeIcon;
