import { Component } from 'react';
import { setAuthLastActivityTime } from '@utils/auth-utils';
import { getConfig } from '@utils/config-utils';

// Events to listen for checking if the user is idle.
const EVENTS = [
  'mousemove',
  'keydown',
  'wheel',
  'DOMMouseScroll',
  'mouseWheel',
  'mousedown',
  'touchstart',
  'touchmove',
  'MSPointerDown',
  'MSPointerMove'
];

// Component to monitor if the user is idle.
//
// This is based on https://github.com/SupremeTechnopriest/react-idle-timer
// I didn't use directly that 'react-idle-timer' package, since it's not
// correctly packaged and causes problems with the React package (npm will see
// two conflicting React versions and nothing will work).
//
// 'react-idle-timer' has some bugs (for example it doesn't set this.state.oldDate)
// and has other functionality that we don't need, so this version is a stripped-down one,
// it contains only what we need, and with bugs fixed.
export default class IdleMonitor extends Component {
  componentDidMount() {
    this.idleTimeout = getConfig().idleTimeout;
    this.setTimer();
    EVENTS.forEach(event => document.addEventListener(event, this.onEvent));
  }

  componentWillUnmount() {
    // Clear timeout to prevent delayed state changes:
    this.clearTimer();

    // Unbind all events:
    EVENTS.forEach(event => document.removeEventListener(event, this.onEvent));
  }

  setTimer = () => {
    this.setState({
      oldDate: +new Date(),
      timeoutId: setTimeout(this.onIdle, this.idleTimeout)
    });
  };

  clearTimer = () => clearTimeout(this.state.timeoutId);

  // Called when the user becomes idle:
  onIdle = () => {};   // Implement in subclass.

  // Called when the user moves the mouse or do some action on the page:
  onEvent = event => {
    // Throttle mousemove events:
    if (event.type === 'mousemove') {
      const elapsed = (+new Date()) - this.state.oldDate;
      if (elapsed < 10000) {
        return;
      }
    }

    // Clear the session expiration timer:
    setAuthLastActivityTime();

    // Clear any existing timer:
    this.clearTimer();

    // Set timer again:
    this.setTimer();
  };
}
