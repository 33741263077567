/* eslint-disable prefer-template */
import { scheduleTypes } from '@constants/component-configs';
import {
  formatRecurrenceWeekdays,
  isNextDayDateTime,
  isScheduleEnabled
} from '@utils/segment-schedule/common';
import {
  formatLocationDate,
  formatLocationDateTime,
  formatAbbrExceptionDate,
  formatRecurrenceTime
} from '@utils/segment-schedule/dates';
import { enDash } from '@utils/shared-utils';
import { calculateDays } from '@utils/timeline-chart-utils';

// Returns the '(next day)' string if the end date ends after midnight:
const addNextDay = (start_time, end_time) => {
  if (isNextDayDateTime(start_time, end_time)) {
    return ' (next day)';
  }
  return '';
};

// Formats a single recurrecen start/end times:
export const formatRecurrenceTimes = recurrence => {
  const { start_time, end_time } = recurrence;
  if (start_time && end_time) {
    return `${formatRecurrenceTime(start_time)}${enDash}${formatRecurrenceTime(end_time)}${addNextDay(start_time, end_time)}`;
  }
  if (recurrence.all_day) {
    return '(all day)';
  }
  return '';
};

// Formats a single location start/end dates:
const formatLocationDates = dates => {
  const { start_date, end_date } = dates;
  if (start_date && end_date) {
    return `${formatLocationDate(start_date)}${enDash}${formatLocationDate(end_date)}`;
  }
  return '';
};

// Like formatLocationDates() but including times:
const formatLocationTimes = dates => {
  const { start_date, end_date } = dates;
  if (start_date && end_date) {
    return `${formatLocationDateTime(start_date)}${enDash}${formatLocationDateTime(end_date)}`;
  }
  return '';
};

// Format a location schedule start/end dates (taking care of schedule type,
// thus formatting start/end times for one-time schedules).
const formatLocationDateTimes = schedule => {
  if (schedule.type === scheduleTypes.oneTime.value && schedule.all_day) {
    return formatLocationDates(schedule);
  }
  return formatLocationTimes(schedule);
};

// Shows the duration between start/end dates:
const calculateLocationDuration = dates => {
  const { start_date, end_date } = dates;
  const duration = calculateDays(start_date, end_date);
  return `(${duration} days)`;
};

// Returns the exceptions list as a string:
const buildLocationScheduleExceptions = exceptions => {
  if (exceptions) {
    return `Excludes ${exceptions.map(exception => formatAbbrExceptionDate(exception.exception_date)).join(', ')}`;
  }
  return '';
};

// Formats a single recurrence (showing the weekdays and start/end times):
const formatRecurrence = recurrence => {
  return `${formatRecurrenceWeekdays(recurrence)} ${formatRecurrenceTimes(recurrence)}`;
};

// Returns a string with all formatted recurrences:
const buildLocationScheduleRecurrences = recurrences => {
  if (recurrences) {
    return recurrences.map(recurrence => formatRecurrence(recurrence)).join('\n');
  }
  return '';
};

// Returns the string with the location schedule data (schedule dates, duration,
// recurrences and exceptions):
const buildLocationSchedules = schedules => {
  if (!schedules) {
    return '';
  }
  return schedules.map(schedule => {
    const {
      exceptions,
      recurrences
    } = schedule;
    return `${formatLocationDateTimes(schedule)} ${calculateLocationDuration(schedule)}\n` +
      `${buildLocationScheduleRecurrences(recurrences)}\n` +
      `${buildLocationScheduleExceptions(exceptions)}\n`;
  }).join('\n');
};

// Helper methods to build from/to fields:
const buildFrom = from => from ? `From ${from}` : '';
const buildTo = to => to ? ` to ${to}` : '';

// Returns the locations list:
// it's a string with the location number, the title, description,
// from/to addresses and the schedule (dates, duration, recurrences
// and exceptions).
const buildLocations = segments => {
  if (!segments) {
    return '';
  }
  return segments.map((segment, index) => {
    const {
      description,
      from_address,
      to_address,
      schedules,
      title
    } = segment;
    // Add segment title and description if they are present:
    let titleString = '';
    let descriptionString = '';
    if (title) {
      titleString = `: ${title}`;
    }
    if (description) {
      descriptionString = `${description}\n`;
    }
    return '\n--\n\n' +
       `<Location ${index + 1}${titleString}>\n` +
       `${descriptionString}` +
       `${buildFrom(from_address)}${buildTo(to_address)}\n` +
       buildLocationSchedules(schedules);
  }).join('\n');
};

// Builds the entity schedule string:
export const buildEntitySchedule = (entityType, data) => {
  if (!isScheduleEnabled(entityType)) {
    return null;
  }
  const {
    name,
    description,
    segments
  } = data;
  return `${name}\n` +
    `${formatLocationDates(data)}\n` +
    `${description}\n` +
    buildLocations(segments);
};
