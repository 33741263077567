import React from 'react';
import PropTypes from 'prop-types';
import { detailCircleStyle, detailLineStyle, detailPolyLineStyle } from '@constants/data-detail';
import MapFigure from '@shared/map-figure';

const options = { ...detailCircleStyle, ...detailLineStyle, ...detailPolyLineStyle };

const MapComponents = ({ segments }) => (
  <div>
    {segments && segments.map(segment => (
      <MapFigure key={segment.id} options={options} shape={segment.shape} />
    ))}
  </div>
);

MapComponents.propTypes = {
  segments: PropTypes.array
};

export default MapComponents;
