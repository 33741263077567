import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import debounce from 'lodash.debounce';
import { setViewport } from '@actions/map-actions';
import Components from './components';
import DotMapsGoogleMap from '@shared/dotmaps-google-map';
import {
  didViewportChanged,
  segmentsToBounds
} from '@utils/map-utils';
import { shallowEqual } from '@utils/react-utils';

class Map extends Component {
  constructor() {
    super();
    this.state = {
      fitBound: false,
      mapInstance: null
    };
    this.updateViewport = debounce(this.updateViewport.bind(this), 600);
  }

  shouldComponentUpdate(nextProps, nextState) {
    return (
      didViewportChanged(nextProps.viewport, this.props.viewport) ||
      !shallowEqual(nextState.fitBound, this.state.fitBound) ||
      // For boundsData changes, only re-render while we still didn't fit bounds.
      (this.state.fitBound === false && !shallowEqual(nextProps.boundsData, this.props.boundsData))
    );
  }

  componentDidUpdate(prevProps) {  // eslint-disable-line no-unused-vars
    if (this.state.mapInstance &&
        this.props.boundsData &&
        !this.state.fitBound) {
      const bounds = segmentsToBounds(this.props.boundsData.segments);
      if (bounds) {
        this.state.mapInstance.fitBounds(bounds);
        // For the group map, only zoom it when loading it the first time.
        // We must not zoom to fit the shape when we are editing it, since
        // if we increase the shape area, we'll be zooming to the original
        // shape extent.
        this.setState({ fitBound: true });  // eslint-disable-line react/no-did-update-set-state
      }
    }
  }

  onMapLoad = mapInstance => {
    if (mapInstance) {
      this.setState({ mapInstance });
      window.mapInstance = mapInstance;
    }
  };

  updateViewport() {
    this.props.setViewport('group', this.state.mapInstance);
  }

  render() {
    return (
      <DotMapsGoogleMap
        components={<Components />}
        onBoundsChanged={this.updateViewport}
        onMapLoad={this.onMapLoad}
      />
    );
  }
}

Map.propTypes = {
  boundsData: PropTypes.object,
  setViewport: PropTypes.func,
  viewport: PropTypes.object
};

const mapStateToProps = state => {
  const { boundsData, viewport } = state.map;
  return { boundsData, viewport };
};

export default connect(mapStateToProps, { setViewport })(Map);
