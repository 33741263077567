import React from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { isAdminDataType } from '@constants/endpoints';
import './top-bar.scss';

const TopBarDescription = () => {
  const { dataType } = useParams();
  const { data } = useSelector(state => state.dataDetail);
  if (!data || !data.description || !isAdminDataType(dataType)) {
    return null;
  }
  return (
    <div styleName="description">
      {data.description}
    </div>
  );
};

export default TopBarDescription;
