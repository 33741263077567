// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.src-components-shared-ui-library-list-___dm-list-item__list-item-wrap{display:block;position:relative;top:0;right:0;width:100%}.src-components-shared-ui-library-list-___dm-list-item__draggable{position:relative;top:0;right:0}.src-components-shared-ui-library-list-___dm-list-item__draggable .src-components-shared-ui-library-list-___dm-list-item__drag-handle{position:absolute;top:0;width:1rem;height:100%}.src-components-shared-ui-library-list-___dm-list-item__draggable .src-components-shared-ui-library-list-___dm-list-item__drag-handle:dir(ltr){right:0.25rem}.src-components-shared-ui-library-list-___dm-list-item__draggable .src-components-shared-ui-library-list-___dm-list-item__drag-handle:dir(rtl){left:0.25rem}.src-components-shared-ui-library-list-___dm-list-item__draggable.src-components-shared-ui-library-list-___dm-list-item__dragging{box-shadow:0px 1px 10px 0px rgba(0,0,0,0.12),0px 4px 5px 0px rgba(0,0,0,0.14),0px 2px 4px -1px rgba(0,0,0,0.2)}
`, "",{"version":3,"sources":["webpack://./src/components/shared/ui-library/list/dm-list-item.scss"],"names":[],"mappings":"AAGA,uEACE,aAAc,CACd,iBAAkB,CAClB,KAAM,CACN,OAAQ,CAER,UAAW,CACZ,kEAGC,iBAAkB,CAClB,KAAM,CACN,OAAQ,CAHV,sIAMI,iBAAkB,CAClB,KAAM,CAEN,UAAW,CACX,WAAY,CAVhB,+IAaM,aAAc,CAbpB,+IAgBM,YAAa,CAhBnB,kIAqBI,8GAA2H","sourcesContent":["@import '../../../../styles/vars';\n@import '../../../../styles/mixins';\n\n.list-item-wrap {\n  display: block;\n  position: relative;\n  top: 0;\n  right: 0;\n\n  width: 100%;\n}\n\n.draggable {\n  position: relative;\n  top: 0;\n  right: 0;\n\n  .drag-handle {\n    position: absolute;\n    top: 0;\n\n    width: 1rem;\n    height: 100%;\n\n    &:dir(ltr) {\n      right: 0.25rem;\n    }\n    &:dir(rtl) {\n      left: 0.25rem;\n    }\n  }\n\n  &.dragging {\n    box-shadow: 0px 1px 10px 0px rgba(0, 0, 0, 0.12), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 2px 4px -1px rgba(0, 0, 0, 0.20);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"list-item-wrap": `src-components-shared-ui-library-list-___dm-list-item__list-item-wrap`,
	"listItemWrap": `src-components-shared-ui-library-list-___dm-list-item__list-item-wrap`,
	"draggable": `src-components-shared-ui-library-list-___dm-list-item__draggable`,
	"drag-handle": `src-components-shared-ui-library-list-___dm-list-item__drag-handle`,
	"dragHandle": `src-components-shared-ui-library-list-___dm-list-item__drag-handle`,
	"dragging": `src-components-shared-ui-library-list-___dm-list-item__dragging`
};
export default ___CSS_LOADER_EXPORT___;
