import React from 'react';
import PropTypes from 'prop-types';
import RefreshIndicator from 'material-ui/RefreshIndicator';
import './dotmaps-loader.scss';

const DotmapsLoader = ({ display, color, small }) => {
  const position = small ? {size: 30, left: -30, top: -15} : {size: 70, left: -50, top: -50};
  return (
    <div
      styleName={
        display ? 'dotmaps-loader-container' : 'dotmaps-loader-container hidden'
      }
    >
      <RefreshIndicator
        size={position.size}
        left={position.left}
        top={position.top}
        loadingColor={color}
        status="loading"
      />
    </div>
  );
};

DotmapsLoader.propTypes = {
  color: PropTypes.string,
  display: PropTypes.bool,
  small: PropTypes.bool
};

export default DotmapsLoader;
