import React, { memo } from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import './top-bar-navigation.scss';

const Navigation = ({ next, previous, today, selectedDate }) => (
  <div styleName="navigation-container">
    <div styleName="navigation-button">
      <Button onClick={today} variant="outlined">Today</Button>
    </div>
    <IconButton onClick={previous} size="small">
      <KeyboardArrowLeft />
    </IconButton>
    <div styleName="navigation-sep" />
    <IconButton onClick={next} size="small">
      <KeyboardArrowRight />
    </IconButton>
    <div styleName="navigation-date">{selectedDate.format('MMMM YYYY')}</div>
  </div>
);

Navigation.propTypes = {
  next: PropTypes.func,
  previous: PropTypes.func,
  selectedDate: PropTypes.object,
  today: PropTypes.func
};

export default memo(Navigation);
