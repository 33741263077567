import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import {
  ListItemIcon,
  ListItemText,
  MenuItem,
  Typography
} from '@material-ui/core';

const Icon = withStyles({
  root: {
    minWidth: 'auto',
    paddingRight: '1rem'
  }
})(ListItemIcon);

const Text = withStyles({
  root: {
    paddingRight: '2rem'
  }
})(ListItemText);

const IconMenuItem = ({ children, icon, onClick, secondary }) => (
  <MenuItem onClick={onClick}>
    {icon && <Icon>{icon}</Icon>}
    <Text>{children}</Text>
    {secondary && (
      <Typography variant="body2" color="text.secondary">
        {secondary}
      </Typography>
    )}
  </MenuItem>
);

IconMenuItem.propTypes = {
  children: PropTypes.node,
  icon: PropTypes.object,
  onClick: PropTypes.func,
  secondary: PropTypes.string
};

export default IconMenuItem;
