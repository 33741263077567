import React, { memo, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import classNames from 'classnames';
import { capitalize } from 'lodash';
import PropTypes from 'prop-types';

import { downloadTemplate } from '@actions/batch-actions';

import Button from '@material-ui/core/Button';
import SaveAltIcon from '@material-ui/icons/SaveAlt';

import styles from './batch-upload-download-template.scss';

const BatchUploadDownloadTemplate = ({ type }) => {
  const dispatch = useDispatch();

  const onDownloadTemplate = useCallback(() => {
    dispatch(downloadTemplate(type));
  }, [dispatch, type]);

  return (
    <div className={styles.downloadTemplateContainer}>
      <Button
        classes={{
          root: classNames(styles.downloadButton, {}),
          label: classNames(styles.downloadButtonLabel, {})
        }}
        onClick={onDownloadTemplate}
        startIcon={<SaveAltIcon />}
        variant="outlined"
      >
        {capitalize(type)} template
      </Button>
    </div>
  );
};

BatchUploadDownloadTemplate.propTypes = {
  type: PropTypes.string
};

export default memo(BatchUploadDownloadTemplate);
