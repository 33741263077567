import { createSelector } from 'reselect';
import { optimizeEntitiesForList } from '@utils/entity-utils';
import { getAgencyAndEntityTypes } from '@selectors/shared-selector';

const getEntity = state => state.dataDetail.data && [state.dataDetail.data.entity] || [];
const getConflicts = state => state.dataDetail?.data?.conflicts || [];
const getOpportunities = state => state.dataDetail?.data?.opportunities || [];

export const optimizeOverlapConflictsForListSelector = createSelector([getConflicts, getAgencyAndEntityTypes], optimizeEntitiesForList);
export const optimizeOverlapOpportunitiesForListSelector = createSelector([getOpportunities, getAgencyAndEntityTypes], optimizeEntitiesForList);
export const optimizeEntityForListSelector = createSelector([getEntity, getAgencyAndEntityTypes], optimizeEntitiesForList);

export const getOverlapsSelector = createSelector(
  [optimizeOverlapConflictsForListSelector, optimizeOverlapOpportunitiesForListSelector],
  (conflicts, opportunities) => [].concat(conflicts, opportunities)
);
