/* eslint-disable react/jsx-no-bind */
import React, { useCallback } from 'react';
import * as R from 'ramda';
import { push } from 'connected-react-router';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import ArrowBackIcon from '@shared/arrow-back-icon';
import { getDashboardBackURL, getDefaultWorkflowBackURL } from '@utils/navigation-utils';
import './details.scss';

const TopBarBackButton = () => {
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const { dataType, taskActivityId } = useParams();
  const source = R.path(['state', 'source'], location);
  const { data } = useSelector(state => state.dataDetail);

  const sourceClick = useCallback(() => dispatch(push(source)), [dispatch, source]);
  const onBackClick = source ? sourceClick : () => history.go(-1);

  const onWorkflowBackClick = useCallback(
    () => {
      const defaultUrl = getDefaultWorkflowBackURL(data, taskActivityId);
      const backUrl = getDashboardBackURL(location, 'cycle', defaultUrl);
      dispatch(push(backUrl));
    },
    [data, dispatch, location, taskActivityId]
  );

  const onClick = dataType === 'cycle' ? onWorkflowBackClick : onBackClick;

  return <ArrowBackIcon onClick={onClick} style={{ padding: '0 1rem 0 0' }} />;
};

export default TopBarBackButton;
