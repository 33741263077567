/*
 * File Upload related utilities.
 */
import React from 'react';
import { filesize } from 'filesize';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import ImageIcon from '@material-ui/icons/Image';
// Not supported for now, but keep in case we enable uploading some movie format mime type:
// import MovieIcon from '@material-ui/icons/Movie';
import * as colors from '@constants/colors';
import CsvIcon from '@icons/files/csv-icon';
import PdfIcon from '@icons/files/pdf-icon';
import WordIcon from '@icons/files/word-icon';

const iconStyle = { fontSize: '1.5rem' };

// List of accepted file types for upload:
const ACCEPTED_FILE_TYPES = {
  'image/png': <ImageIcon htmlColor={colors.dotmapsIconImage} style={iconStyle} />,
  'image/jpeg': <ImageIcon htmlColor={colors.dotmapsIconImage} style={iconStyle} />,
  'application/pdf': <PdfIcon />,
  'application/msword': <WordIcon />,
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document': <WordIcon />,
  'image/x-dwg': null,  // Use the default.
  'text/csv': <CsvIcon />,
  'application/zip': null,
  'application/vnd.ms-excel': <CsvIcon />,
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': <CsvIcon />
};

export const getAcceptedFileTypes = () => Object.keys(ACCEPTED_FILE_TYPES).join(',');

// Renders a predefined icon based on the specified file type.
export const renderFileIcon = type => ACCEPTED_FILE_TYPES[type] || <InsertDriveFileIcon htmlColor="#5585E0" style={iconStyle} />;

// Format file size:
export const formatFileSize = size => filesize(size, { round: 0 });
