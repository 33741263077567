export const SET_FILTERS = 'SET_FILTERS';
export const UPDATE_FILTERS = 'UPDATE_FILTERS';

export const CATEGORY_FILTER_TYPE = 'category';
export const DATE_FILTER_TYPE = 'date';
export const PAST_DATE_FILTER_TYPE = 'past-date';
export const AGENCY_TYPE_FILTER_TYPE = 'agency-type';
export const AGENCY_FILTER_TYPE = 'agency';
export const FIELD_FILTER_TYPE = 'field';
export const CHECKBOX_FILTER_TYPE = 'checkbox';
export const USER_FILTER_TYPE = 'user';

export const MULTISELECT_FILTER_TYPES = new Set([CATEGORY_FILTER_TYPE, AGENCY_TYPE_FILTER_TYPE, FIELD_FILTER_TYPE]);
