/* eslint-disable react/jsx-no-bind */
import React, { useContext } from 'react';

import { useParams } from 'react-router-dom';

import { capitalize } from 'lodash';

import { colors } from '@constants/colors';

import { IconButton } from '@mui';

import DashboardContext from './dashboard-context';
import DashboardCustomizeIcon from './dashboard-customize-icon';

import './dashboard.scss';

const DashboardTitleBar = () => {
  const { setShowCustomize } = useContext(DashboardContext);
  const { portalType } = useParams();

  return (
    <div styleName="dashboard-title-bar">
      <div styleName="dashboard-title">{capitalize(portalType)}</div>
      <div styleName="dashboard-icons">
        <IconButton
          onClick={() => setShowCustomize(true)}
          tooltip="Customize Dashboard"
          data-cy="dashboardHeaderCustomizeIcon"
        >
          <DashboardCustomizeIcon color={colors.neutral.gray} />
        </IconButton>
      </div>
    </div>
  );
};

export default DashboardTitleBar;
