import React, { memo, useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { updateDashboardField } from '@actions/dashboard-actions';
import CustomSelectField from '@components/portal/details/fields/custom-select-field';
import ReadOnlyField from '@components/portal/details/fields/read-only-field';
import { dashboardDetailsDataSelector } from '@selectors/dashboard-details-selector';
import '../portal/details/section-field.scss';

const options = [
  {
    id: 'none',
    name: 'None',
    hint: null
  },
  {
    id: 'agency',
    name: 'Agency contact',
    hint: 'Display the information of the agency contact for all projects.'
  },
  {
    id: 'owner',
    name: 'Assigned project manager',
    hint: 'Display the information of the assigned project manager for each project.'
  }
];

// Source field for the Agency Public Viewer settings, it specifies from where we'll
// display contact information on the Public Viewer, if from the agency contact information
// or from the project's owner.
const PublicViewerSourceField = ({ edit }) => {
  const dispatch = useDispatch();
  const data = useSelector(state => dashboardDetailsDataSelector(state, 'agency'));

  const loaded = useMemo(() => typeof data !== 'undefined' && data !== null, [data]);

  const value = useMemo(
    () => {
      if (!data?.show_email && !data?.show_phone && !data?.show_pm_email && !data?.show_pm_phone) {
        return 'none';
      } else
        if ((data?.show_email || data?.show_phone) && !data?.show_pm_email && !data?.show_pm_phone) {
          return 'agency';
        } else
          if (!data?.show_email && !data?.show_phone && (data?.show_pm_email || data?.show_pm_phone)) {
            return 'owner';
          }
      return null;
    },
    [data]
  );

  const displayValue = useMemo(() => {
    return options.find(option => option.id === value)?.name;
  }, [value]);

  const onChange = useCallback(event => {
    const val = event.target.value;
    if (val === 'none') {
      dispatch(updateDashboardField('agency', 'show_email', false));
      dispatch(updateDashboardField('agency', 'show_phone', false));
      dispatch(updateDashboardField('agency', 'show_pm_email', false));
      dispatch(updateDashboardField('agency', 'show_pm_phone', false));
    } else
      if (val === 'agency') {
        // If nothing is set yet, set both (email and phone):
        if (!data?.show_email && !data?.show_phone && !data?.show_pm_email && !data?.show_pm_phone) {
          dispatch(updateDashboardField('agency', 'show_email', true));
          dispatch(updateDashboardField('agency', 'show_phone', true));
        } else {
          // If email or phone fields were set for 'owner', set it for agency:
          if (data?.show_pm_email) {
            dispatch(updateDashboardField('agency', 'show_email', true));
          }
          if (data?.show_pm_phone) {
            dispatch(updateDashboardField('agency', 'show_phone', true));
          }
        }

        // And unset 'owner' fields:
        dispatch(updateDashboardField('agency', 'show_pm_email', false));
        dispatch(updateDashboardField('agency', 'show_pm_phone', false));
      } else
        if (val === 'owner') {
          // If nothing is set yet, set both (email and phone):
          if (!data?.show_email && !data?.show_phone && !data?.show_pm_email && !data?.show_pm_phone) {
            dispatch(updateDashboardField('agency', 'show_pm_email', true));
            dispatch(updateDashboardField('agency', 'show_pm_phone', true));
          } else {
            // If email or phone fields were set for 'agency', set it for 'owner':
            if (data?.show_email) {
              dispatch(updateDashboardField('agency', 'show_pm_email', true));
            }
            if (data?.show_phone) {
              dispatch(updateDashboardField('agency', 'show_pm_phone', true));
            }
          }

          // And unset 'agency' fields:
          dispatch(updateDashboardField('agency', 'show_email', false));
          dispatch(updateDashboardField('agency', 'show_phone', false));
        }
  }, [data, dispatch]);

  return (
    <div key="source" styleName="section-field">
      {edit &&
        <CustomSelectField
          label="Display on Public Viewer"
          onChange={onChange}
          options={options}
          value={value}
        />
      }
      {!edit && <ReadOnlyField label="Display on Public Viewer" loaded={loaded} value={displayValue} />}
    </div>
  );
};

PublicViewerSourceField.propTypes = {
  edit: PropTypes.bool
};

export default memo(PublicViewerSourceField);
