import React, { useState } from 'react';
import { Tab, Tabs } from '@shared/tabs';
import DetailsContent from '@components/details/details-content';
import TaskTypeLayout from './task-type-layout';
import TaskTypeDrawer from './task-type-drawer';
import TaskTypePreview from './task-type-preview';
import TaskTypeStatus from './task-type-status';

const Fields = TaskTypeLayout(TaskTypePreview, TaskTypeDrawer);

const TaskType = () => {
  const [tab, setTab] = useState('fields');

  return (
    <DetailsContent>
      <Tabs
        data-testid="admin-tasktype-tabs"
        onChange={setTab}
        tabItemWidth="100px"
        tabTemplateStyle={{ height: '100%'}}
        value={tab}
      >
        <Tab data-testid="admin-tasktype-tab-1" label="FIELDS" value="fields">
          {tab === 'fields' && <Fields />}
        </Tab>
        <Tab data-testid="admin-tasktype-tab-2" label="STATUS" value="status">
          {tab === 'status' && <TaskTypeStatus />}
        </Tab>
      </Tabs>
    </DetailsContent>
  );
};

export default TaskType;
