import React, { memo, useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { updateDashboardField } from '@actions/dashboard-actions';
import CustomSelectField from '@components/portal/details/fields/custom-select-field';
import ReadOnlyField from '@components/portal/details/fields/read-only-field';
import { dashboardDetailsDataSelector } from '@selectors/dashboard-details-selector';
import '../portal/details/section-field.scss';

const options = [
  { name: 'Email only', id: 'email' },
  { name: 'Phone only', id: 'phone' },
  { name: 'Both email and phone', id: 'both' }
];

// The "what" field means what to display on the Public Viewer about the contact information,
// if we'll display the email only, the phone only or both.
const PublicViewerWhatField = ({ edit }) => {
  const dispatch = useDispatch();
  const data = useSelector(state => dashboardDetailsDataSelector(state, 'agency'));

  const loaded = useMemo(() => typeof data !== 'undefined' && data !== null, [data]);

  const value = useMemo(
    () => {
      if ((data?.show_email || data?.show_phone) && !data?.show_pm_email && !data?.show_pm_phone) {
        if (data?.show_email && data?.show_phone) {
          return 'both';
        } else
          if (data?.show_email) {
            return 'email';
          } else
            if (data?.show_phone) {
              return 'phone';
            }
      }

      if (!data?.show_email && !data?.show_phone && (data?.show_pm_email || data?.show_pm_phone)) {
        if (data?.show_pm_email && data?.show_pm_phone) {
          return 'both';
        } else
          if (data?.show_pm_email) {
            return 'email';
          } else
            if (data?.show_pm_phone) {
              return 'phone';
            }
      }

      return null;
    },
    [data]
  );

  const displayValue = useMemo(() => {
    return options.find(option => option.id === value)?.name;
  }, [value]);

  const disabled = useMemo(() => {
    return !data?.show_email && !data?.show_phone && !data?.show_pm_email && !data?.show_pm_phone;
  }, [data]);

  const onChange = useCallback(event => {
    const val = event.target.value;
    if (val === 'both') {
      if (data?.show_pm_email || data?.show_pm_phone) {
        dispatch(updateDashboardField('agency', 'show_pm_email', true));
        dispatch(updateDashboardField('agency', 'show_pm_phone', true));
      } else {
        dispatch(updateDashboardField('agency', 'show_email', true));
        dispatch(updateDashboardField('agency', 'show_phone', true));
      }
    } else
      if (val === 'email') {
        if (data?.show_pm_phone) {
          dispatch(updateDashboardField('agency', 'show_pm_email', true));
          dispatch(updateDashboardField('agency', 'show_pm_phone', false));
        } else
          if (data?.show_phone) {
            dispatch(updateDashboardField('agency', 'show_email', true));
            dispatch(updateDashboardField('agency', 'show_phone', false));
          }
      } else
        if (val === 'phone') {
          if (data?.show_pm_email) {
            dispatch(updateDashboardField('agency', 'show_pm_phone', true));
            dispatch(updateDashboardField('agency', 'show_pm_email', false));
          } else
            if (data?.show_email) {
              dispatch(updateDashboardField('agency', 'show_phone', true));
              dispatch(updateDashboardField('agency', 'show_email', false));
            }
        }
  }, [data, dispatch]);

  return (
    <div key="what" styleName="section-field">
      {edit &&
        <CustomSelectField
          disabled={disabled}
          label="Contact information"
          onChange={onChange}
          options={options}
          value={value}
        />
      }
      {!edit && <ReadOnlyField label="Contact information" loaded={loaded} value={displayValue} />}
    </div>
  );
};

PublicViewerWhatField.propTypes = {
  edit: PropTypes.bool
};

export default memo(PublicViewerWhatField);
