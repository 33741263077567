import React from 'react';
import PropTypes from 'prop-types';
import { dotmapsBlue } from '@constants/colors';

const ConflictBothSidesIcon = ({ color }) => (
  <svg width="24px" height="24px" version="1.1" viewBox="0 0 24 24">
    <defs>
      <path
        id="conflict-both-sides-path-1"
        d="M7.01 9H0v2h7.01v3L11 10 7.01 6v3zm5.98-1V5H20V3h-7.01V0L9 4l3.99 4z"
      />
    </defs>
    <g fill="none" fillRule="evenodd" transform="translate(2 5)">
      <mask id="conflict-both-sides-path-2" fill="#fff">
        <use xlinkHref="#conflict-both-sides-path-1" />
      </mask>
      <g fill={color} mask="url(#conflict-both-sides-path-2)">
        <path d="M-2-5h24v24H-2z" />
      </g>
    </g>
  </svg>
);

ConflictBothSidesIcon.propTypes = {
  color: PropTypes.string
};

ConflictBothSidesIcon.defaultProps = {
  color: dotmapsBlue
};

export default ConflictBothSidesIcon;
