import { createSelector } from 'reselect';

import {
  AGENCY_DATA_TYPE,
  BATCH_DATA_TYPE,
  CYCLE_DATA_TYPE,
  DATA_TYPES,
  ENTITY_DATA_TYPE,
  GROUP_DATA_TYPE,
  OVERLAP_DATA_TYPE,
  TASK_ACTIVITY_DATA_TYPE,
  TASK_DATA_TYPE,
  USER_DATA_TYPE
} from './constants';

export const getConfig = state => state.config;

// Convert workflow menu items into the format used by the entities
// overlap menu items.
const convertWorkflowItems = (config, ndx1, ndx2) => {
  const items = config.dashboard?.workflow?.menu?.[0]?.items;
  // Overwrite type with subtype:
  const children = [
    {...items?.[ndx1], type: items?.[ndx1].subtype},
    {...items?.[ndx2], type: items?.[ndx2].subtype}
  ];
  // Convert into a 'children' key to mimic overlaps menu.
  return { children };
};

export const getDataTypeConfig = createSelector(
  getConfig,
  (state, dataType) => dataType,
  (state, dataType, subType) => subType,
  (config, dataType, subType) => {
    const type = DATA_TYPES[dataType];
    try {
      if (dataType === OVERLAP_DATA_TYPE) {
        return {
          ...config.dataTypes[ENTITY_DATA_TYPE][subType],
          ...config.dataTypes[dataType][subType]
        };
      }
      if (type.subType) {
        return config.dataTypes[dataType][subType] || {};
      }
      return config.dataTypes[dataType] || {};
    } catch (err) {
      if (!(err instanceof TypeError)) {
        throw err;
      }
    }

    // Attempt to get config from other legacy sources
    switch (dataType) {
    case AGENCY_DATA_TYPE:
      return {
        ...config.dashboard?.people?.menu[0].items[1] || {},
        ['top-bar']: config.dashboard?.people?.['top-bar']?.agency || {}
      };
    case ENTITY_DATA_TYPE: {
      return {
        ...config.entity_types.find(typeConfig => typeConfig.name === subType) || {},
        ['top-bar']: config.dashboard?.list?.['top-bar']?.[subType] || {}
      };
    }
    case GROUP_DATA_TYPE: {
      return {
        ...config.group_types.find(typeConfig => typeConfig.name === subType) || {},
        ['top-bar']: config.dashboard?.list?.['top-bar']?.[dataType]?.[subType] || {}
      };
    }
    case OVERLAP_DATA_TYPE: {
      return config.dataManagementMenuItems[0] || {};
    }
    case BATCH_DATA_TYPE: {
      return config.dataManagementMenuItems[1] || {};
    }
    case CYCLE_DATA_TYPE:
      return config.dashboard?.workflow?.menu?.[1]?.items?.[0];
    case TASK_ACTIVITY_DATA_TYPE:
      return convertWorkflowItems(config, 2, 3);
    case TASK_DATA_TYPE:
      return convertWorkflowItems(config, 0, 1);
    case USER_DATA_TYPE:
      return {
        ...config.dashboard?.people?.menu[0].items[0] || {},
        ['top-bar']: config.dashboard?.people?.['top-bar']?.user || {}
      };
    default:
      return {};
    }
  }
);

export const getDataTypeDisplayName = createSelector(
  getConfig,
  getDataTypeConfig,
  (state, dataType) => dataType,
  (state, dataType, subType) => subType,
  (config, typeConfig, dataType, subType) => {
    if (typeConfig.displayName) {
      return typeConfig.display_name;
    }
    if (dataType === 'entity') {
      const entityType = (config?.entity_types || []).find(type => type.name === subType);
      if (entityType && entityType.display_name) {
        return entityType.display_name;
      }
    }
    return (subType || dataType || '').replace(/_/g, ' ');
  }
);

export const getDateDisplayFormat = createSelector(
  getConfig,
  config => (config?.localization?.dateFormat || 'M/D/YYYY')
);
