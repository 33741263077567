import React, { memo } from 'react';
import PropTypes from 'prop-types';
import CoreViewField from '@components/workflow/section/core/view-field';
import ViewField from '@components/workflow/section/view-field';

const WorkflowFields = ({ sectionId, fields }) => fields.map(field => {
  if (field.core) {
    return <CoreViewField key={sectionId} sectionId={sectionId} {...field} />;
  }

  return <ViewField key={sectionId} field={field} />;
});

WorkflowFields.propTypes = {
  fields: PropTypes.array,
  sectionId: PropTypes.number
};

export default memo(WorkflowFields);
