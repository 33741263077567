/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { geocodeByPlaceId, getLatLng } from 'react-places-autocomplete';
import { highlightTerm } from '@utils/shared-utils';

import DmListItem from '@components/shared/ui-library/list/dm-list-item';

import { focusMap, showAutocompleteError } from '@actions/map-actions';
import styles from './google-item.scss';

class GoogleItem extends Component {
  onClick = () => {
    const { prediction: {place_id: placeId, description } } = this.props;
    geocodeByPlaceId(placeId)
      .then(results => getLatLng(results[0]))
      .then(center => this.props.focusMap(center, description))
      .catch(this.props.showAutocompleteError);
  };
  render() {
    const { prediction: { description }, markerItem, term } = this.props;
    const text = highlightTerm(description, term);
    return (
      <DmListItem
        className={classNames(
          styles.googleItem,
          {
            [styles.googleItemActive]: markerItem === description
          }
        )}
        onClick={this.onClick}
      >
        <span className={styles.label}>
          {text}
        </span>
      </DmListItem>
    );
  }
}

GoogleItem.propTypes = {
  focusMap: PropTypes.func,
  markerItem: PropTypes.string,
  prediction: PropTypes.object,
  showAutocompleteError: PropTypes.func,
  term: PropTypes.string
};

const mapStateToProps = state => {
  const { term } = state.map.traySearch;
  const { viewport } = state.map;
  const { markerItem } = viewport;
  return { term, markerItem };
};

const mapDispatchToProps = {
  focusMap,
  showAutocompleteError
};

export default connect(mapStateToProps, mapDispatchToProps)(GoogleItem);
