import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import MaterialToggle from 'material-ui/Toggle';
import { getCategoryDataForType } from '@selectors/data-types-selector';

const Toggle = ({
  /* eslint-disable no-unused-vars */
  data,
  dataCheckError,
  dataCheckErrorStyle,
  dataCheckValue,
  dataExtract,
  dataField,
  dataName,
  /* eslint-enable no-unused-vars */
  style,
  styleName,
  disabled,
  error,
  errorStyle,
  showError,
  ...other
}) => (
  <div style={style || {}} styleName={styleName || ''}>
    <MaterialToggle {...other} disabled={disabled || showError} />
    {showError && <div style={errorStyle || {}}>{error}</div>}
  </div>
);

Toggle.propTypes = {
  data: PropTypes.object,
  dataCheckError: PropTypes.string,
  dataCheckErrorStyle: PropTypes.object,
  dataCheckValue: PropTypes.any,  // eslint-disable-line react/forbid-prop-types
  dataExtract: PropTypes.string,
  dataField: PropTypes.array,
  dataName: PropTypes.object,
  disabled: PropTypes.bool,
  error: PropTypes.string,
  errorStyle: PropTypes.object,
  showError: PropTypes.bool,
  style: PropTypes.object,
  styleName: PropTypes.string
};

const mapStateToProps = (state, props) => {
  const {
    data,
    dataCheckError: error,
    dataCheckErrorStyle: errorStyle,
    dataCheckValue,
    dataExtract,
    dataField,
    dataName
  } = props;
  let showError = false;
  dataField.forEach(fieldName => {
    const field = data[fieldName];
    // stop checking if anything error out
    if (field && !showError) {
      const dataType = dataName[fieldName];
      const selectedData = Object.values(getCategoryDataForType(dataType)(state)).find(({id}) => id === field);
      const value = selectedData[dataExtract];
      showError = value === dataCheckValue;
    }
  });
  return { error, errorStyle, showError };
};

export default connect(mapStateToProps, {})(Toggle);
