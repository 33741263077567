import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
// Convert the tooltip title to string, to avoid prop types validation errors
// when the value is a number (ReactOverflowTooltip prop types is expecting a string).
import { toString } from 'lodash';
import ReactOverflowTooltip from 'react-overflow-tooltip';
import DetailTableBlobField from '@components/map/tray/entity-tabs/details/details-table-blob-field';
import DefaultDate from '@shared/formatted-date-helper';
import DefaultTime from '@shared/formatted-time-helper';
import DefaultDatetime from '@shared/formatted-datetime-helper';
import { enDash } from '@utils/shared-utils';
import './details-table-block.scss';

const DetailsTableBlock = ({ fields }) => {
  const isDateLabel = label => label === 'Use Start Date' || label === 'Use Expire Date';

  const formatBoolean = value => {
    if (value === null) {
      return enDash;
    }
    return value ? 'Yes' : 'No';
  };

  const formatValue = (label, value, showIfEmpty, type) => {
    if (!value && type !== 'boolean') {
      return typeof (showIfEmpty) !== 'undefined' && !showIfEmpty ? null : <div styleName="value">{enDash}</div>;
    }
    if (type === 'email' && value) {
      return (
        <ReactOverflowTooltip title={toString(value)}>
          <div styleName="value"><a href={`mailto:${value}`} rel="noopener noreferrer" target="_blank">{value}</a></div>
        </ReactOverflowTooltip>
      );
    }
    if (type === 'url') {
      return <div styleName="value"><a styleName="link" href={value} rel="noopener noreferrer" target="_blank">{value}</a></div>;
    }
    if (type === 'date') {
      return <div styleName="value"><DefaultDate value={value} /></div>;
    }
    if (type === 'time') {
      return <div styleName="value"><DefaultTime value={value} /></div>;
    }
    if (type === 'datetime') {
      return <div styleName="value"><DefaultDatetime separator="," value={value} /></div>;
    }
    if (type === 'boolean') {
      return <div styleName="value">{formatBoolean(value)}</div>;
    }
    if (type === 'blob') {
      return <div styleName="value"><DetailTableBlobField value={value} /></div>;
    }
    if (type === 'fulltext') {
      return <div styleName="value">{value}</div>;
    }
    if (isDateLabel(label)) {
      return <div styleName="value"><DefaultDatetime separator="," value={value} /></div>;
    }
    // If type wasn't defined but the value is a moment date, render it as a date time
    // (do this last, since date/time/datetime types must be interpreted first).
    if (value && typeof (value) === 'object' && moment.isMoment(value)) {
      return <div styleName="value"><DefaultDatetime separator="," value={value} /></div>;
    }
    return <ReactOverflowTooltip title={toString(value)}><div styleName="value">{value}</div></ReactOverflowTooltip>;
  };

  return (
    <div styleName="details-table-block-component">
      {fields.map((field, index) => {
        const { label, value, showIfEmpty, type } = field;
        if (type === 'header') {
          return (
            <div styleName="header" key={index}>
              <div styleName="label">{label}</div>
            </div>
          );
        }
        return (
          <div styleName={type === 'blob' ? 'newline-detail' : 'inline-detail'} key={index}>
            <div styleName="label">{label}</div>
            {formatValue(label, value, showIfEmpty, type)}
          </div>
        );
      })}
    </div>
  );
};

DetailsTableBlock.propTypes = {
  fields: PropTypes.array
};

export default DetailsTableBlock;
