import React, { Fragment, memo, useEffect} from 'react';

import { useDispatch } from 'react-redux';
import { useParams, useLocation } from 'react-router-dom';

import { replace } from 'connected-react-router';

import { getDefaultEntity, isWorkflowEnabled } from '@constants/config';

import { ENTITY_DATA_TYPE } from '@components/config/constants';
import Filter from '@components/filters';
import { PORTAL_DATA_TYPES } from '@components/portal/constants';
import DateRangeDialog from '@components/portal/dialogs/date-range-dialog';
import PortalDataTable from '@components/portal/table/data-table';
import PortalHeader from '@components/portal/table/header';
import { useLegacyTypes } from '@components/portal/hooks';
import PortalFooter from '@components/portal/table/footer';
import PortalMenu from '@components/portal/menu/menu';

import AddToGroupDialog from '@shared/dialogs/add-to-group-dialog';
import StartCycleDialog from '@shared/dialogs/start-cycle-dialog';

import styles from './portal.scss';

const Portal = () => {
  const dispatch = useDispatch();
  const { dataType, subType } = useParams();
  const location = useLocation();

  useEffect(() => {
    if (!dataType || !PORTAL_DATA_TYPES.has(dataType)) {
      dispatch(replace(`/library/${ENTITY_DATA_TYPE}/${getDefaultEntity()}`));
    }
  }, [dispatch, dataType, subType]);
  const [legacyDataType, /* legacySubType */, legacyParams] = useLegacyTypes(dataType, subType);

  if (!PORTAL_DATA_TYPES.has(dataType)) {
    return null;
  }

  return (
    <Fragment>
      <div className={styles.portal}>
        <PortalMenu currentDataType={dataType} currentSubType={subType} />
        <div className={styles.data}>
          <PortalHeader dataType={dataType} subType={subType} />
          <Filter
            dataType={dataType}
            subType={subType}
            layout="horizontal"
            className={styles.filters}
          />
          <div className={styles.table}>
            <PortalDataTable dataType={dataType} subType={subType} />
          </div>
          <PortalFooter dataType={dataType} subType={subType} />
        </div>
      </div>
      <DateRangeDialog dataType={legacyDataType} params={legacyParams} location={location} />
      <AddToGroupDialog />
      {isWorkflowEnabled() && <StartCycleDialog />}
    </Fragment>
  );
};

Portal.propTypes = {};

export default memo(Portal);
