import React, { memo, useCallback, useContext } from 'react';
import PropTypes from 'prop-types';
import OverlapContext from '@components/overlap/overlap-context';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';

const DrawerOverlapToggle = ({ toggleHighlightGeometry }) => {
  const { highlightGeometry } = useContext(OverlapContext);

  const onToggleClick = useCallback(event => {
    event.stopPropagation();
  }, []);
  const onToggleChange = useCallback(() => toggleHighlightGeometry(), [toggleHighlightGeometry]);

  return (
    <FormControlLabel
      control={
        <Switch
          onClick={onToggleClick}
          onChange={onToggleChange}
          checked={highlightGeometry}
          color="primary"
        />
      }
      label="Highlight the overlapping geometry"
    />
  );
};

DrawerOverlapToggle.propTypes = {
  toggleHighlightGeometry: PropTypes.func
};

export default memo(DrawerOverlapToggle);
