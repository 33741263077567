/* eslint-disable max-depth */
import {
  AUTOCOMPLETE_VALUES_CHANGED,
  ENTITY_FETCH_SUCCESS,
  ENTITY_LOADING,
  EXPORT_DOWNLOADING,
  EXPORT_DOWNLOADING_FINISHED,
  HANDLE_SERVER_ERROR,
  LAYER_FETCH_SUCCESS,
  LAYER_LOADING,
  MAP_CLEAR_TRAY,
  MAP_FILTERS_CHANGED,
  MAP_FIT_BOUNDS,
  MAP_OVERLAP_TRAY_HOVER,
  MAP_POP_TRAY,
  MAP_PUSH_TRAY,
  MAP_SELECT_TRAY_SEGMENT,
  MAP_SET_DRAWING_DONE,
  MAP_SET_DRAWING_MODE,
  MAP_SET_DRAWING_PATH,
  MAP_SET_DRAWING_PROPS,
  MAP_SET_GOOGLE_AUTOCOMPLETE_SUGGESTIONS,
  MAP_SET_MAP_FILTER_RADIO,
  MAP_SET_MAP_FILTER_TOGGLE,
  MAP_SET_MODE,
  MAP_SET_SEARCH_TERM,
  MAP_SET_TYPE_ID,
  MAP_TOGGLE_BICYCLE,
  MAP_TOGGLE_LEGEND,
  MAP_TOGGLE_LAYERS_MENU,
  MAP_TOGGLE_MAP_FILTER_TOGGLE,
  MAP_TOGGLE_MARKER_MODAL,
  MAP_TOGGLE_MARKERS,
  MAP_TOGGLE_ONLY_SHOW_OVERLAPS,
  MAP_TOGGLE_POIS,
  MAP_TOGGLE_TRAFFIC,
  MAP_TOGGLE_TRANSIT,
  MAP_TOGGLE_TRAY,
  MAP_UPDATE_GROUP_TRAY,
  MAP_VIEWPORT_CHANGE,
  MAP_TRAY_OVERLAP_ENTITY_CACHE_ADD,
  MAP_TRAY_OVERLAP_ENTITY_CACHE_REMOVE,
  RESET_MAP_FILTERS,
  SET_MAP_SELECTION_POSITION
} from '@constants/action-types';
import {
  FETCH_CONFIG_SUCCESS
} from '@components/config/constants';
import {
  STACKABLE_TRAYS
} from '@constants/map-trays';
import {
  getFiltersGlobal
} from '@constants/config';
import { LOCATION_CHANGE } from 'connected-react-router';
import initialState, { filterRadioTypes } from '../store/initial-state';
import {
  getActiveTabTray,
  getTrayTabValue,
  prependToRecent
} from '@utils/map-utils';

const mapReducer = (state = initialState.map, action) => {
  switch (action.type) {
  case MAP_FILTERS_CHANGED: {
    const newFilters = {
      ...state.filters, ...action.filters
    };
    if (action.filters) {
      if (action.filters.undated) {
        // If 'undated' is set, it means we must filter not only by start/end dates
        // but also show 'undated' items.
        //
        // But the django_filters backend can't accept filters for the same field with isnull,
        // which means having both 'start_date__lte' and 'start_date__lte__isnull',
        // only one will be used.
        //
        // Thus, we'll use the 'start_date__lte__isnull', which behaves like 'start_date__lte',
        // but also includes the NULL items.
        //
        // So, we'll get rid of the non-isnull parameters:
        delete newFilters.start_date__lte;
        delete newFilters.start_date__gte;
        delete newFilters.end_date__lte;
        delete newFilters.end_date__gte;
        if (action.filters.undatedOnly) {
          // If we must show 'undated only' items, remove also the lte/gte__isnull entries,
          // since we'll send only the exact start/end_date__isnull fields:
          delete newFilters.start_date__lte__isnull;
          delete newFilters.start_date__gte__isnull;
          delete newFilters.end_date__lte__isnull;
          delete newFilters.end_date__gte__isnull;
        } else {
          // If undated is off, remove the exact start/end_date__isnull fields:
          delete newFilters.start_date__isnull;
          delete newFilters.end_date__isnull;
        }
      } else {
        // Else delete the isnull ones:
        delete newFilters.start_date__lte__isnull;
        delete newFilters.start_date__gte__isnull;
        delete newFilters.end_date__lte__isnull;
        delete newFilters.end_date__gte__isnull;
        delete newFilters.start_date__isnull;
        delete newFilters.end_date__isnull;
      }
      delete newFilters.undated;
      delete newFilters.undatedOnly;
    }
    return { ...state, filters: newFilters };
  }
  case MAP_SET_TYPE_ID: {
    const mapTypeId = action.payload;
    return { ...state, mapTypeId };
  }
  case MAP_TOGGLE_TRAY: {
    if (state.trays.length > 0) {
      return {
        ...state,
        onlyShowOverlaps: false,
        trays: initialState.map.trays
      };
    }
    return {
      ...state,
      trays: [getActiveTabTray(state.currentTab)]
    };
  }
  case MAP_TOGGLE_TRAFFIC: {
    const traffic = !state.traffic;
    return { ...state, traffic };
  }
  case MAP_TOGGLE_BICYCLE: {
    const bicycle = !state.bicycle;
    return { ...state, bicycle };
  }
  case MAP_TOGGLE_TRANSIT: {
    const transit = !state.transit;
    return { ...state, transit };
  }
  case MAP_TOGGLE_ONLY_SHOW_OVERLAPS: {
    return {
      ...state,
      onlyShowOverlaps: !state.onlyShowOverlaps
    };
  }
  case LOCATION_CHANGE: {
    // When the location changes, always close the tray
    // (but don't clear the recent list).
    // This is needed, because if you open an entity
    // on the conflicts page, we don't want it open
    // if you switch to the /map page.
    return {
      ...state,
      drawing: { ...initialState.map.drawing },
      filters: {
        ...state.filters,
        geom_in: null
      },
      trays: initialState.map.trays,
      onlyShowOverlaps: false,
      boundsData: null
    };
  }
  case MAP_CLEAR_TRAY: {
    return {
      ...state,
      onlyShowOverlaps: false,
      trays: initialState.map.trays
    };
  }
  case MAP_TOGGLE_LEGEND: {
    const legendOpen = !state.legendOpen;
    return { ...state, legendOpen, savedLegendOpen: legendOpen };
  }
  case MAP_TOGGLE_LAYERS_MENU: {
    const layersMenuOpen = !state.layersMenuOpen;
    return { ...state, layersMenuOpen };
  }
  case MAP_TOGGLE_MARKER_MODAL: {
    const markerModalOpen = !state.markers.markerModalOpen;
    return { ...state, markers: {...state.markers, markerModalOpen }};
  }
  case MAP_TOGGLE_MARKERS: {
    const markersOn = !state.markers.markersOn;
    return { ...state, markers: {...state.markers, markersOn }};
  }
  case MAP_TOGGLE_POIS: {
    const poisOn = !state.markers.poisOn;
    return { ...state, markers: {...state.markers, poisOn }};
  }
  case MAP_PUSH_TRAY: {
    const newTrays = [...state.trays];
    //pending trays are always overwritten and automatically poped
    const top = newTrays.length > 0 && newTrays[newTrays.length - 1];
    if (top && top.data && top.data.pending) {
      newTrays.pop();
    }
    const tray = {trayType: action.trayType, trayName: action.trayName, data: action.payload};
    const newState = {
      ...state,
      currentTab: getTrayTabValue(action.trayType)
    };
    if (state.currentTab !== newState.currentTab) {
      newState.onlyShowOverlaps = false;
    }
    const newTrayCanStack = STACKABLE_TRAYS.has(action.trayType);
    const previousTrayIsNotStackable = (
      newTrays.length > 0 &&
      !STACKABLE_TRAYS.has(newTrays[newTrays.length - 1].trayType)
    );
    if (newTrayCanStack) {
      newState.trayRecent = prependToRecent(state.trayRecent, action.trayType, action.payload);
      // If a stackable tray is pushed onto a non stackable tray discard the rest of the tray stack.
      if (previousTrayIsNotStackable) {
        newTrays.splice(0, newTrays.length - 1);
      }
    } else {
      // Unstackable trays replace eachother instead of stacking.
      if (previousTrayIsNotStackable) { // eslint-disable-line no-lonely-if
        newTrays.pop();
      }
    }
    newTrays.push(tray);
    newState.trays = newTrays;
    return newState;
  }
  case MAP_POP_TRAY: {
    const newTrays = state.trays.slice(0, -1);
    // Remove any previous hover state, as we are re-opening the tray anew.
    if (newTrays.length > 0 && newTrays[newTrays.length - 1].hover) {
      newTrays[newTrays.length - 1] = {...newTrays[newTrays.length - 1]};
      delete newTrays[newTrays.length - 1].hover;
    }
    const newState = {
      ...state,
      trays: newTrays
    };
    const currentTray = newTrays[newTrays.length - 1];
    if (currentTray) {
      const currentTab = getTrayTabValue(currentTray.trayType);
      if (currentTab) {
        newState.currentTab = currentTab;
      }
    }
    return newState;
  }
  // Updates the group entry in the tray (after creating a new cycle).
  case MAP_UPDATE_GROUP_TRAY: {
    const { group } = action;
    const newTrays = [...state.trays];
    if (newTrays.length > 0 && newTrays[newTrays.length - 1]) {
      newTrays[newTrays.length - 1] = {
        ...newTrays[newTrays.length - 1],
        data: {
          ...newTrays[newTrays.length - 1].data,
          group  // Updating only the group's data to display the new cycles.
        }
      };
    }
    return {
      ...state,
      trays: newTrays
    };
  }
  case MAP_VIEWPORT_CHANGE: {
    const viewport = { ...state.viewport, ...action.payload };
    return { ...state, viewport };
  }
  case MAP_FIT_BOUNDS: {
    const boundsData = { ...state.boundsData, ...action.payload };
    return { ...state, boundsData };
  }
  case MAP_SET_DRAWING_DONE: {
    const { done } = action;
    const drawing = { ...state.drawing, done };
    return { ...state, drawing };
  }
  case MAP_SET_DRAWING_MODE: {
    const { mode } = action;
    const drawing = { ...state.drawing, mode };
    // When turning off the drawing mode, restore the stored legendOpen state
    // (it's usually on, but the user might have it turned it off, that's
    // why we need to restore the saved state).
    // And when turning the drawing mode on, we must always hide
    // the legend.
    const legendOpen = mode === '' ? state.savedLegendOpen : false;
    return { ...state, drawing, legendOpen };
  }
  case MAP_SET_DRAWING_PROPS: {
    const { props } = action;
    const drawing = { ...state.drawing, ...props };
    const legendOpen = props.mode === '' ? state.savedLegendOpen : state.legendOpen;
    return { ...state, drawing, legendOpen };
  }
  case MAP_SET_DRAWING_PATH: {
    const { coords } = action;
    const drawing = { ...state.drawing, path: [...coords] };
    return { ...state, drawing };
  }
  case MAP_SET_MAP_FILTER_RADIO: {
    const { entityType, value } = action;
    const newFilterRadios = {
      ...state.filterRadios,
      [entityType]: {
        [value]: {
          ...state.filterRadios[entityType][value],
          checked: true
        }
      }
    };
    const { linked } = state.filterRadios[entityType][value];
    linked.forEach(item => {
      newFilterRadios[entityType][item] = {
        ...state.filterRadios[entityType][item],
        checked: false
      };
    });
    return { ...state, filterRadios: { ... newFilterRadios } };
  }
  case MAP_TOGGLE_MAP_FILTER_TOGGLE: {
    const { filter } = action;
    return {
      ...state,
      filterToggles: {
        ...state.filterToggles,
        [filter]: !state.filterToggles[filter]
      }
    };
  }
  case MAP_SET_MAP_FILTER_TOGGLE: {
    const { filter, visible } = action;
    return {
      ...state,
      filterToggles: {
        ...state.filterToggles,
        [filter]: visible
      }
    };
  }
  case MAP_SET_MODE: {
    const { modes } = action;
    return { ...state, modes };
  }
  case MAP_OVERLAP_TRAY_HOVER: {
    const { hover } = action;
    const top = state.trays[state.trays.length - 1];
    return {
      ...state,
      trays: [
        ...state.trays.slice(0, -1),
        { ...top, hover }
      ]
    };
  }
  case LAYER_LOADING: {
    const loading = { ...state.loading, layers: true };
    return { ...state, loading };
  }
  case ENTITY_LOADING: {
    const loading = { ...state.loading, [action.entityType]: true };
    return { ...state, loading };
  }
  case EXPORT_DOWNLOADING: {
    return { ...state, downloading: state.downloading + 1 };
  }
  case EXPORT_DOWNLOADING_FINISHED: {
    return { ...state, downloading: state.downloading - 1 };
  }
  // On success turn off the loading flag:
  case LAYER_FETCH_SUCCESS: {
    const loading = { ...state.loading, layers: false };
    return { ...state, loading };
  }
  case ENTITY_FETCH_SUCCESS: {
    const loading = { ...state.loading, [action.entityType]: false };
    return { ...state, loading };
  }
  case AUTOCOMPLETE_VALUES_CHANGED: {
    const { suggestions } = action.payload;
    return {
      ...state,
      traySearch: {
        ...state.traySearch,
        backend: suggestions
      }
    };
  }
  case MAP_SET_GOOGLE_AUTOCOMPLETE_SUGGESTIONS: {
    const { predictions } = action;
    return {
      ...state,
      traySearch: {
        ...state.traySearch,
        google: predictions
      }
    };
  }
  case MAP_SET_SEARCH_TERM: {
    const term = action.term;
    const viewport = { ...state.viewport };
    // If we clear the search input box,
    // clear also the search marker from
    // the map.
    if (term === '') {
      viewport.defaultMarkerPosition = null;
      viewport.markerItem = null;
    }
    return {
      ...state,
      traySearch: {
        ...state.traySearch,
        term
      },
      viewport
    };
  }
  // In case of error loading entities,
  // turn off the loading flag for that data type:
  case HANDLE_SERVER_ERROR: {
    const loadingType = action.payload.type;
    if (loadingType) {
      const loading = { ...state.loading };
      loading[loadingType] = false;
      return { ...state, loading };
    }
    // Avoid modifying the loading state for other kind of errors,
    // since HANDLE_SERVER_ERROR is a shared action.
    return state;
  }
  case RESET_MAP_FILTERS: {
    return {
      ...state,
      filters: {
        ...(getFiltersGlobal()),
        cb: +new Date()
      },
      loading: {}
    };
  }
  case MAP_SELECT_TRAY_SEGMENT: {
    const top = state.trays[state.trays.length - 1];
    return {
      ...state,
      trays: [
        ...state.trays.slice(0, -1),
        {
          ...top,
          selectedSegment: {id: action.segmentId}
        }
      ]
    };
  }
  case SET_MAP_SELECTION_POSITION: {
    const { position, radius, bounds } = action;
    return {
      ...state,
      selectedPosition: position,
      selectedRadius: radius,
      selectedBounds: bounds
    };
  }
  case MAP_TRAY_OVERLAP_ENTITY_CACHE_ADD: {
    const { entityType, fetchIds, entityDetails } = action;
    const newOverlapTrayEntities = {...(state.overlapTrayEntities[entityType] || {})};
    fetchIds.forEach(id => {
      newOverlapTrayEntities[id] = null;
    });
    entityDetails.forEach(entity => {
      const id = entity.id;
      newOverlapTrayEntities[id] = entity;
    });
    return {
      ...state,
      overlapTrayEntities: {...state.overlapTrayEntities, [entityType]: newOverlapTrayEntities}
    };
  }
  case MAP_TRAY_OVERLAP_ENTITY_CACHE_REMOVE: {
    const { entityIds } = action;
    const newOverlapTrayEntities = {};
    Object.entries(state.overlapTrayEntities).forEach(([type, details]) => {
      const newDetails = {...details};
      entityIds.forEach(id => {
        delete newDetails[id];
      });
      if (Object.keys(newDetails).length > 0) {
        newOverlapTrayEntities[type] = newDetails;
      } else {
        delete newOverlapTrayEntities[type];
      }
    });
    return {
      ...state,
      overlapTrayEntities: newOverlapTrayEntities
    };
  }
  case FETCH_CONFIG_SUCCESS: {
    const config = action.payload.data;
    const { mapCenter } = config;
    const viewport = {
      ...state.viewport,
      center: mapCenter,
      zoom: config.map.zoom.defaultZoom
    };
    // Build filterToggles based on all toggled on filters
    // from the config.
    const filtersConfig = config.filters.filters;
    const filterToggles = {};
    const filterRadios = {};
    Object.keys(filtersConfig).forEach(filter => {
      const { type, sections, toggled } = filtersConfig[filter];
      if (toggled) {
        filterToggles[type] = true;
      }
      if (sections) {
        // If there's any section with radio buttons,
        // add the radio config for that entity type:
        if (sections.some(section => section.radio)) {
          filterRadios[type] = { ...filterRadioTypes };
        }
      }
    });
    return {
      ...state,
      filters: { ...config.filters.global },
      viewport,
      filterRadios: {
        ...state.filterRadios,
        ...filterRadios
      },
      filterToggles,
      toolbar: config.map.toolbar,
      ...config.map.features
    };
  }
  default:
    return state;
  }
};

export default mapReducer;
