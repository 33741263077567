import React, { Fragment, memo, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { paper } from '@constants/mui-theme';
import VerticalScrollTabs from '@shared/vertical-scroll-tabs';
import Form from './form';
import Footer from './footer';
import '../../../forms/forms.scss';

const Drawer = () => {
  const { dataType, id, action } = useParams();
  const { details } = useSelector(state => state.config);
  const tabs = useMemo(() => details?.[dataType]?.tabs.filter(tab => !tab.hide_internal), [dataType, details]);
  return (
    <div styleName="drawer-form-container" key="drawer" style={paper}>
      {/* id is present, it's an existing entity, show an action/messages
          footer below the tab and forms. */}
      {id && action !== 'duplicate' && (
        <div styleName="edit-form-container">
          <div styleName="tab-form-container">
            <VerticalScrollTabs dataType={dataType} tabs={tabs} />
            <Form />
          </div>
          <Footer />
        </div>
      )}
      {/* else it's the new entity page, render only the tab and forms,
          the save/cancel action will appear on the top-bar. */}
      {(!id || action === 'duplicate') && (
        <Fragment>
          <VerticalScrollTabs dataType={dataType} tabs={tabs} />
          <Form />
        </Fragment>
      )}
    </div>
  );
};

export default memo(Drawer);
