import React from 'react';
import PropTypes from 'prop-types';
import {
  IconButton as MUIIconButton,
  Tooltip as MUITooltip
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  root: ({ style }) => ({
    ...style
  })
});

export const IconButton = props => {
  const classes = useStyles(props);

  const {
    // eslint-disable-next-line id-length, no-unused-vars
    style: _,
    size = 'medium',
    tooltip = '',
    TooltipProps = {},
    ...data
  } = props;

  return (
    <MUITooltip title={tooltip} {...TooltipProps}>
      <MUIIconButton
        {...data}
        size={size}
        classes={{ root: classes.root, ...props.classes }}
      />
    </MUITooltip>
  );
};

IconButton.propTypes = {
  TooltipProps: PropTypes.object,
  classes: PropTypes.object,
  size: PropTypes.string,
  style: PropTypes.object,
  tooltip: PropTypes.string
};
