import React from 'react';

const AttachmentOffIcon = () => (
  <svg width="18px" height="18px" version="1.1" viewBox="0 0 18 18">
    <defs>
      <path
        id="attachment-off-path-1"
        d={`
            M2.25 5.94l1.125 1.165v5.27a3 3 0 0 0 2.824 2.995l.176.005A3 3 0 0 0 9.26
            13.2l.866.896A4.123 4.123 0 0 1 6.375 16.5a4.123 4.123 0 0 1-4.125-4.125V5.94zM.953
            1.5L2.88 3.487 12 12.9l.75.772-.953.953L2.1 4.553 0 2.453.953 1.5zM4.5 8.27l1.125
            1.165v1.44c0 .378.284.693.649.743l.101.007c.377 0 .69-.281.743-.644l.851.88a1.874
            1.874 0 0 1-3.469-.986V8.27zm6-4.52v6.415L9.375 9V3.75H10.5zM5.25 0a3 3 0 0 1 3
            3v4.835L7.125 6.67V3c0-.986-.762-1.795-1.729-1.87l-.146-.005c-.967 0-1.764.733-1.864
            1.674l-.91-.943A3 3 0 0 1 5.25 0zm.375 3.75v1.367L4.5 3.952V3.75h1.125z
        `}
      />
    </defs>
    <g fill="none" fillRule="evenodd" transform="translate(3 .75)">
      <mask id="attachment-off-path-2" fill="#fff">
        <use xlinkHref="#attachment-off-path-1" />
      </mask>
      <g fill="#000" fillOpacity=".4" mask="url(#attachment-off-path-2)">
        <path d="M-3-.75h18v18H-3z" />
      </g>
    </g>
  </svg>
);

export default AttachmentOffIcon;
