import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import LocationRow from '@components/workflow/section/location-row';
import Row from '@components/workflow/section/linked-entities-row';
import ShowMore from '@shared/show-more';
import './linked-entities-list.scss';

const MAX_ENTITIES = 5;

const Accordion = withStyles({
  root: {
    boxShadow: 'none',
    width: '100%',
    '&:not(:last-child)': {
      borderBottom: 0
    },
    '&:before': {
      display: 'none'
    },
    '&$expanded': {
      margin: 'auto'
    }
  },
  expanded: {}
})(MuiAccordion);

const AccordionSummary = withStyles({
  root: {
    backgroundColor: 'rgba(0, 0, 0, .03)',
    borderBottom: '1px solid rgba(0, 0, 0, .125)',
    marginBottom: -1,
    minHeight: 'auto',
    '&$expanded': {
      minHeight: 'auto'
    }
  },
  content: {
    margin: '0.75rem 0',
    '&$expanded': {
      margin: '0.75rem 0'
    }
  },
  expanded: {},
  expandIcon: {
    padding: 0
  }
})(MuiAccordionSummary);

const AccordionDetails = withStyles({
  root: {
    padding: 0
  }
})(MuiAccordionDetails);

const List = ({ data, edit, onChange, removeEntity }) => {
  const [showAll, setShowAll] = useState(false);

  const toggleShowAll = useCallback(() => setShowAll(!showAll), [showAll, setShowAll]);

  const onSegmentDelete = useCallback(
    segmentId => {
      if (data && data.entities && segmentId) {
        const newEntities = [];
        data.entities.forEach(entity => {
          const newEntity = {
            ...entity,
            segments: [
              ...entity.segments.filter(segment => segment.id !== segmentId)
            ]
          };
          newEntities.push(newEntity);
        });
        onChange('entities', newEntities);
      }
    },
    [data, onChange]
  );

  const onSegmentSelect = useCallback(
    segmentId => {
      if (data && segmentId) {
        onChange('_selectedSegmentId', segmentId);
      }
    },
    [data, onChange]
  );

  const entityList = showAll ? data.entities : data.entities.slice(0, MAX_ENTITIES);

  return (
    <div styleName="list-container">
      {entityList.map(entity => (
        <Accordion key={entity.id}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Row edit={edit} key={entity.id} entity={entity} removeEntity={removeEntity} />
          </AccordionSummary>
          <AccordionDetails>
            <div styleName="segment-list">
              {entity.segments.map(segment => (
                <LocationRow
                  edit={edit}
                  key={segment.id}
                  onDelete={onSegmentDelete}
                  onSelect={onSegmentSelect}
                  segment={segment}
                  selectedSegmentId={data._selectedSegmentId}
                />
              ))}
            </div>
          </AccordionDetails>
        </Accordion>
      ))}
      <ShowMore list={data.entities} max={MAX_ENTITIES} onClick={toggleShowAll} showAll={showAll} />
    </div>
  );
};

List.propTypes = {
  data: PropTypes.object,
  edit: PropTypes.bool,
  onChange: PropTypes.func,
  removeEntity: PropTypes.func
};

export default List;
