/* eslint-disable react/jsx-no-bind */
/* eslint-disable no-return-assign */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import CustomCalendar from '@shared/custom-calendar';
import './edit-schedule-dialog-exclusions.scss';

class Exclusions extends Component {
  dualCalendarRefs = {};

  onExcludeDate = date => {
    this.props.onExcludeDate(date);
  };

  onMonthChange = months => {
    this.dualCalendarRefs.left.onMonthChange(months);
    this.dualCalendarRefs.right.onMonthChange(months);
  };

  render() {
    const {
      exceptions,
      maxDate,
      minDate,
      weekdays
    } = this.props;
    const calendarProps = {
      exceptions,
      maxDate,
      minDate,
      onExcludeDate: this.onExcludeDate,
      onMonthChange: this.onMonthChange,
      weekdays
    };
    return (
      <div styleName="exclusions-dual-calendar-container">
        <CustomCalendar
          dualPosition="left"
          ref={ref => this.dualCalendarRefs.left = ref}
          {...calendarProps}
        />
        <CustomCalendar
          dualPosition="right"
          ref={ref => this.dualCalendarRefs.right = ref}
          {...calendarProps}
        />
      </div>
    );
  }
}

Exclusions.propTypes = {
  exceptions: PropTypes.array,
  maxDate: PropTypes.object,
  minDate: PropTypes.object,
  onExcludeDate: PropTypes.func,
  weekdays: PropTypes.array
};

export default Exclusions;
