import {
  HANDLE_SERVER_ERROR,
  NOTIFICATIONS_FETCH_SUCCESS,
  NOTIFICATIONS_SET_READ_SUCCESS,
  NOTIFICATIONS_UNREAD_FETCH_SUCCESS,
  NOTIFICATIONS_LOADING
} from '@constants/action-types';
import initialState from '../store/initial-state';
import { mergeArraysById } from '@utils/shared-utils';

const notificationsReducer = (state = initialState.notifications, action) => {
  switch (action.type) {
  case NOTIFICATIONS_FETCH_SUCCESS: {
    const { next, results } = action.payload;
    return {
      ...state,
      list: [...mergeArraysById(state.list, results)],
      loading: false,
      next
    };
  }
  case NOTIFICATIONS_UNREAD_FETCH_SUCCESS: {
    const { notifications } = action.payload;
    return {
      ...state,
      unread: notifications
    };
  }
  case NOTIFICATIONS_SET_READ_SUCCESS: {
    const { id, read } = action;
    const newList = state.list.map(notification => {
      if (id === notification.id) {
        return {
          ...notification,
          read
        };
      }
      return notification;
    });
    return {
      ...state,
      list: [...newList]
    };
  }
  case HANDLE_SERVER_ERROR: {
    return { ...state, loading: false };
  }
  case NOTIFICATIONS_LOADING: {
    return { ...state, loading: true };
  }
  default:
    return state;
  }
};

export default notificationsReducer;
