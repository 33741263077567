import React from 'react';
import PropTypes from 'prop-types';
import {
  Checkbox as MUICheckBox,
  FormControlLabel as MUIFormControlLabel
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { fonts } from '@constants/fonts';

const useStyles = makeStyles({
  label: {
    fontSize: fonts.md
  }
});

export const Checkbox = ({
  color = 'primary',
  label = '',
  checked = false,
  ...data
}) => {
  const classes = useStyles();

  return label ? (
    <MUIFormControlLabel
      label={label}
      classes={classes}
      control={<MUICheckBox color={color} checked={checked} {...data} />}
    />
  ) : (
    <MUICheckBox color={color} checked={checked} {...data} />
  );
};

Checkbox.propTypes = {
  checked: PropTypes.bool,
  color: PropTypes.string,
  label: PropTypes.string
};

export default Checkbox;
