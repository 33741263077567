import React from 'react';
import PropTypes from 'prop-types';
import SvgIcon from 'material-ui/SvgIcon';

const WorkflowIcon = props => (
  <SvgIcon {...props} style={{...props.style, height: '24px', width: '24px'}} viewBox="0 0 26 26">
    <path
      clipRule="evenodd"
      fillRule="evenodd"
      d={`
          M2 3.25V7.625H12V6H16.25C17.7688 6 19 7.23122 19 8.75V9.5C19
          10.6046 18.1046 11.5 17 11.5V9.8125H7V14.1875H17V12.5C18.6569
          12.5 20 11.1569 20 9.5V8.75C20 6.67893 18.3211 5 16.25 5H12V3.25H2ZM22
          20.75V16.375H12V18L7.75 18C6.23122 18 5 16.7688 5 15.25V14.5C5 13.3954
          5.89543 12.5 7 12.5L7 11.5C5.34314 11.5 4 12.8431 4 14.5L4 15.25C4
          17.3211 5.67893 19 7.75 19L12 19V20.75H22Z
         `}
      fill={props.color}
    />
  </SvgIcon>
);

WorkflowIcon.propTypes = {
  color: PropTypes.string,
  style: PropTypes.object
};

export default WorkflowIcon;
