import { createSelector } from 'reselect';

const DEFAULT_DATA = {};
export const getDataForType = (state, dataType) => state.dataTypes[dataType] || DEFAULT_DATA;
export const getDataForTypeSelector = dataType => state => state.dataTypes[dataType] || DEFAULT_DATA;
export const getDataTypes = state => state.dataTypes;

export const getCategoryDataForType = dataType => createSelector(
  [
    getDataForTypeSelector('map_category_type'),
    getDataForTypeSelector('map_category')
  ],
  (
    categoryTypes,
    categories
  ) => {
    const type = Object.values(categoryTypes).find(categoryType => categoryType.name === dataType);
    if (type) {
      const items = {};
      Object.keys(categories).forEach(key => {
        if (categories[key].type === type.id) {
          items[key] = categories[key];
        }
      });
      return items;
    }
    return {};
  }
);

export const dataTypesLoaderSelector = createSelector(
  [getDataTypes],
  dataTypes => {
    let count = 0;
    const types = Object.values(dataTypes);
    types.forEach(type => {
      if (type !== null) {
        count = count + 1;
      }
    });
    return {
      count,
      total: types.length
    };
  }
);
