/* eslint-disable react/jsx-no-bind */
import React, { Fragment, memo, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty } from 'lodash';
import { useParams } from 'react-router-dom';
import { push } from 'connected-react-router';
import {
  fetchDashboardDetailsData,
  updateDashboardDetailsData
} from '@actions/dashboard-actions';
import { DATA_TYPES } from '@components/config/constants';
import { Button } from '@mui';

const TopBarEditActions = () => {
  const dispatch = useDispatch();
  const { id, dataType } = useParams();
  const { saving } = useSelector(state => state.dashboard);
  const isSaving = saving[dataType] ? saving[dataType] : false;
  const errors = useSelector(state => state.dashboard.details?.[dataType]?.error);

  const portal = useMemo(() => DATA_TYPES[dataType].portal, [dataType]);

  const cancel = () => {
    if (id === 'new') {
      dispatch(push(`/${portal}/${dataType}`));
    } else {
      dispatch(fetchDashboardDetailsData(dataType, id));
      dispatch(push(`/${dataType}/${id}`));
    }
  };

  const save = () => {
    dispatch(updateDashboardDetailsData(dataType));
  };

  return (
    <Fragment>
      <Button disabled={isSaving} onClick={cancel} style={{ marginRight: '0.5rem' }}>Cancel</Button>
      <Button color="primary" disabled={isSaving || !isEmpty(errors)} onClick={save} variant="contained">Save</Button>
    </Fragment>
  );
};

export default memo(TopBarEditActions);
