import * as R from 'ramda';
import { sortBy } from '@utils/shared-utils';

export const arrayToObjectIds = array => {
  const object = {};
  if (array) {
    array.forEach(element => {
      object[element.id] = element;
    });
  }
  return object;
};

export const sortByField = field => R.sort(R.ascend(R.prop(field)));

export const getSortedUniqueDataType = (key, list, types, sortField) => {
  // Build a list of unique ids:
  const uniqueIds = new Set();

  list.forEach(entity => {
    if (entity[key]) {
      uniqueIds.add(entity[key]);
    }
  });

  // And return all data types for those unique ids:
  const existing = [...uniqueIds].map(id => types[id]);
  return sortBy(R.values(existing), sortField);
};
