/* eslint-disable max-nested-callbacks */
import {
  HANDLE_SERVER_ERROR,
  NOTIFICATIONS_FETCH_SUCCESS,
  NOTIFICATIONS_SET_READ_SUCCESS,
  NOTIFICATIONS_UNREAD_FETCH_SUCCESS,
  NOTIFICATIONS_LOADING
} from '@constants/action-types';
import { getServerErrorAction } from './shared-actions';
import { BASE_API_URL } from '@constants/endpoints';
import { getLastNotificationId } from '@utils/notification-utils';
import axios from 'axios';

const setNotificationsLoading = () => dispatch => {
  dispatch({ type: NOTIFICATIONS_LOADING });
  return Promise.resolve();
};

const fetchNotificationsSuccess = payload => ({
  type: NOTIFICATIONS_FETCH_SUCCESS,
  payload
});

const fetchUnreadNotificationsSuccess = payload => ({
  type: NOTIFICATIONS_UNREAD_FETCH_SUCCESS,
  payload
});

const notificationsSetReadSuccess = (id, read) => ({
  type: NOTIFICATIONS_SET_READ_SUCCESS,
  id,
  read
});

export const fetchNotifications = next => dispatch => {
  return dispatch(setNotificationsLoading()).then(() => {
    const url = next ? next : `${BASE_API_URL}/notification/?limit=50`;
    const request = axios.get(url);
    request.then(
      ({ data }) => dispatch(fetchNotificationsSuccess(data)),
      error => dispatch(getServerErrorAction('notifications', error))
    );
  });
};

export const fetchUnreadNotifications = () => dispatch => {
  const url = `${BASE_API_URL}/notification/unread/`;
  const request = axios.get(url);
  request.then(
    ({ data }) => dispatch(fetchUnreadNotificationsSuccess(data))
  );
};

export const pollUnreadNotificationsAsync = () => async dispatch => {
  if (window.stopPolling) {
    return;
  }
  try {
    const url = `${BASE_API_URL}/notification/unread/`;
    const response = await axios.get(url);
    dispatch(fetchUnreadNotificationsSuccess(response.data));
  } finally {
    setTimeout(() => pollUnreadNotificationsAsync()(dispatch), 60000);
  }
};

// Save the id of the last notification shown.
export const clearNotifications = () => (dispatch, getState) => {
  const url = `${BASE_API_URL}/notification/clear/`;
  const { list } = getState().notifications;
  const maxNotificationId = getLastNotificationId(list);
  const request = axios.post(url, { maxNotificationId });
  request.then(
    data => {  // eslint-disable-line no-unused-vars
      // Reload the 'unread' count to make the notification badge go away:
      dispatch(fetchUnreadNotifications());
    },
    error => dispatch({ type: HANDLE_SERVER_ERROR, payload: { error } })
  );
};

export const setNotificationRead = (id, read) => dispatch => {
  const url = `${BASE_API_URL}/notification/read/`;
  const request = axios.post(url, { id, read });
  request.then(
    data => {  // eslint-disable-line no-unused-vars
      dispatch(notificationsSetReadSuccess(id, read));
    },
    error => dispatch({ type: HANDLE_SERVER_ERROR, payload: { error } })
  );
};
