import React from 'react';
import PropTypes from 'prop-types';
import Item from './item';
import { renderGroupItemDate } from '@utils/logs-utils';
import './items-group.scss';

const ItemsGroup = ({ groupedLog: { day, logs } }) => (
  <div styleName="items-group-container">
    <div styleName="items-group-header">
      <div styleName="items-group-header-label">
        {renderGroupItemDate(day)}
      </div>
    </div>
    <div styleName="items-group-list">
      {logs.map((log, index) => {
        const key = `${day}-${index}`;
        return <Item key={key} index={key} log={log} />;
      })}
    </div>
  </div>
);

ItemsGroup.propTypes = {
  groupedLog: PropTypes.object
};

export default ItemsGroup;
