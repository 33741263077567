import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import './form.scss';

const DropdownControl = ({
  id, onChange, selected, values
}) => {
  const handleChange = useCallback(event => onChange(event.target.value), [onChange]);
  const hint = values.find(value => value.id === selected).hint;

  return (
    <div styleName="control-container">
      <FormControl style={{ minWidth: '10rem' }}>
        <Select id={id} value={selected} onChange={handleChange} >
          {values.map(value => (
            <MenuItem key={value.id} value={value.id}>{value.value}</MenuItem>
          ))}
        </Select>
        {hint && <FormHelperText>{hint}</FormHelperText>}
      </FormControl>
    </div>
  );
};

DropdownControl.propTypes = {
  id: PropTypes.string,
  onChange: PropTypes.func,
  selected: PropTypes.number,
  values: PropTypes.array
};

export default DropdownControl;
