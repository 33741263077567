/* eslint-disable max-depth */
import {
  ENTITY_FETCH_SUCCESS,
  ENTITY_ADD_SINGLE,
  ENTITY_FILTERS_CHANGED,
  ENTITY_MAP_LOAD_OUTSIDE_VIEWPORT,
  ENTITY_SET_INITIAL_BOUNDS,
  MAP_FILTERS_CHANGED,
  MAP_SET_MAP_FILTER_RADIO,
  MAP_SET_DRAWING_PROPS,
  RESET_MAP_FILTERS,
  ENTITY_LOADING,
  ENTITY_FETCH_COMPLETE
} from '@constants/action-types';
import {
  FETCH_CONFIG_SUCCESS
} from '@components/config/constants';
import initialState, { agencySuppressFilters } from '../store/initial-state';

// Returns a new 'types' object (the object in the store which
// contains all entities and their filters and attributes),
// calling the specified 'setter' function to set the
// new attributes on the entity.
const typesSetter = (state, setter) => {
  const types = {};
  Object.keys(state.types).forEach(key => {
    types[key] = {
      ...state.types[key],
      ...setter(key)
    };
  });
  return types;
};

// Upon loading the backend config, set the initial filters
// for all entities.
const setInitialFilters = state => typesSetter(state, key => {
  const {[key]: defaultConfig = {}} = state.typesConfig;
  return {
    filters: { ...defaultConfig.defaultFilters, cb: +new Date() }
  };
});

// eslint-disable-next-line no-unused-vars
const resetTypes = (state, queryViewport) => typesSetter(state, key => ({
  list: [], next: '', queryViewport, inViewport: true
}));

const entitiesReducer = (state = initialState.entities, action) => {
  switch (action.type) {
  case FETCH_CONFIG_SUCCESS: {
    const { data: {
      entities,
      entity_types: entityTypes,
      filters: {entities: entityFilters}
    }} = action.payload;

    const entityDict = {};
    entityTypes.forEach(entityType => {
      entityDict[entityType.name] = entityType;
    });
    const types = {};
    const typesConfig = {};
    entities.forEach(type => {
      typesConfig[type] = {
        ... entityDict[type].config,
        defaultFilters: { ...entityFilters[type] }
      };
      types[type] = {
        ...(state.types[type] ? state.types[type] : {}),
        list: [],
        next: '',
        filters: { ...entityFilters[type], cb: +new Date() },
        suppressedFilters: { ...agencySuppressFilters },
        queryViewport: null,
        inViewport: true
      };
    });
    return {
      ...state,
      types: {
        ...state.types,
        ...types
      },
      typesConfig: {
        ...state.typesConfig,
        ...typesConfig
      }
    };
  }
  case ENTITY_SET_INITIAL_BOUNDS: {
    const { viewport } = action;
    const types = {};
    Object.keys(state.types).forEach(type => {
      types[type] = {
        ...state.types[type],
        queryViewport: viewport,
        inViewport: true
      };
    });
    return {
      ...state,
      types: {
        ...state.types,
        ...types
      }
    };
  }
  case MAP_SET_DRAWING_PROPS: {
    const { props: { shape } } = action;
    if (shape) {
      // When a shape is set, clear the list of entities,
      // since they'll be loaded when the new geometry filter is set.
      return { ...state, types: resetTypes(state) };
    }
    return state;
  }
  // Some filters are mutually exclusive, like agency type vs agency,
  // when one of them is selected, the other one must be "suppressed".
  case MAP_SET_MAP_FILTER_RADIO: {
    const { entityType, value, viewport } = action;

    // Copy suppressed filters (to avoid state mutation warnings):
    const newSuppressedFilters = { ...state.types[entityType].suppressedFilters };

    // Copy the suppressed filter values to storage:
    const { linked } = state.types[entityType].suppressedFilters[value];
    linked.forEach(item => {
      const { name } = newSuppressedFilters[item];
      newSuppressedFilters[item] = {
        ...newSuppressedFilters[item],
        filter: state.types[entityType].filters[name]
      };
    });

    return {
      ...state,
      types: {
        ...state.types,
        [entityType]: {
          ...state.types[entityType],
          suppressedFilters: {
            ...newSuppressedFilters,
            [value]: {
              ...newSuppressedFilters[value],
              filter: null  // "unsuppress" filter.
            }
          },
          list: [],
          next: '',
          notifyEmpty: true,
          queryViewport: viewport,
          inViewport: true
        }
      }
    };
  }
  case ENTITY_FILTERS_CHANGED: {
    const { entityType, filters, viewport } = action;
    const newFilters = {
      ...state.types[entityType].filters,
      ...filters
    };
    Object.keys(filters).forEach(key => {
      if (filters[key] === 'clear') {
        delete newFilters[key];
      }
    });
    return {
      ...state,
      types: {
        ...state.types,
        [entityType]: {
          ...state.types[entityType],
          filters: { ...newFilters },
          list: [],
          next: '',
          notifyEmpty: true,
          queryViewport: viewport,
          inViewport: true
        }
      }
    };
  }
  case ENTITY_LOADING: {
    const { entityType } = action;
    return {
      ...state,
      types: {
        ...state.types,
        [entityType]: {
          ...state.types[entityType],
          pendingLoad: true
        }
      }
    };
  }
  case ENTITY_ADD_SINGLE: {
    const { entityType } = action;
    const entityState = state.types[entityType];
    const newList = [...entityState.list.filter(entity => entity.id !== action.entity.id), action.entity];
    return {
      ...state,
      types: {
        ...state.types,
        [entityType]: {
          ...entityState,
          list: newList
        }
      }
    };
  }
  case ENTITY_FETCH_SUCCESS: {
    const { entities, next, url, entityType } = action;
    const entityState = state.types[entityType];
    if (!entityState.next || entityState.next === url) {
      const newIDs = new Set();
      entities.forEach(entity => {
        newIDs.add(entity.id);
      });
      const newNext = next ? next : entityState.next;
      const newList = [...entityState.list.filter(entity => !newIDs.has(entity.id)), ...entities];
      return {
        ...state,
        types: {
          ...state.types,
          [entityType]: {
            ...entityState,
            list: newList,
            next: newNext,
            notifyEmpty: false
          }
        }
      };
    }
    return state;
  }
  case ENTITY_FETCH_COMPLETE: {
    const { entityType } = action;
    return {
      ...state,
      types: {
        ...state.types,
        [entityType]: {
          ...state.types[entityType],
          pendingLoad: false
        }
      }
    };
  }
  case ENTITY_MAP_LOAD_OUTSIDE_VIEWPORT: {
    const {entityType} = action;
    return {
      ...state,
      types: {...state.types,
        [entityType]: {...state.types[entityType], inViewport: false}
      }
    };
  }
  case MAP_FILTERS_CHANGED: {
    const { viewport } = action;
    return {
      ...state,
      types: resetTypes(state, viewport)
    };
  }
  case RESET_MAP_FILTERS: {
    const { viewport } = action;
    return {
      ...state,
      types: {
        ...resetTypes(state, viewport),
        ...setInitialFilters(state)
      }
    };
  }
  default:
    return state;
  }
};

export default entitiesReducer;
