import React, { memo, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';

import IconButton from '@material-ui/core/IconButton';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import IndeterminateCheckBoxIcon from '@material-ui/icons/IndeterminateCheckBox';

import ListHeader from '@components/shared/ui-library/list/list-header';

import styles from './add-to-group-tray-header.scss';

const AddToGroupTrayHeader = ({
  entities,
  name,
  onUpdateSelectedEntityIds,
  selectedEntityIds
}) => {
  const [hasSelected, hasUnselected] = useMemo(() => {
    return [
      entities.some(entity => selectedEntityIds[entity.id]),
      entities.some(entity => !selectedEntityIds[entity.id])
    ];
  }, [entities, selectedEntityIds]);

  const handleCheckClicked = useCallback(() => {
    const newSelections = {};
    if (hasUnselected) {
      entities.forEach(entity => {
        newSelections[entity.id] = true;
      });
    } else {
      entities.forEach(entity => {
        newSelections[entity.id] = false;
      });
    }
    onUpdateSelectedEntityIds(newSelections);
  }, [hasUnselected, entities, onUpdateSelectedEntityIds]);

  const checked = hasSelected && !hasUnselected;
  const unchecked = !hasSelected && hasUnselected;
  const indeterminate = hasSelected === hasUnselected;
  return (
    <ListHeader
      className={styles.container}
      before={(
        <IconButton
          onClick={handleCheckClicked}
          classes={{root: styles.button}}
        >
          { checked &&
            <CheckBoxIcon
              classes={{root: styles.checkboxChecked}}
              titleAccess={`Deselect all ${name}`}
            />
          }
          { unchecked &&
            <CheckBoxOutlineBlankIcon
              classes={{root: styles.checkbox}}
              titleAccess={`Select all ${name}`}
            />
          }
          { indeterminate &&
            <IndeterminateCheckBoxIcon
              classes={{root: styles.checkboxChecked}}
              titleAccess={`Select all ${name}`}
            />
          }
        </IconButton>
      )}
      text={name}
      count={entities.length}
    />
  );
};

AddToGroupTrayHeader.propTypes = {
  entities: PropTypes.array,
  name: PropTypes.string,
  onUpdateSelectedEntityIds: PropTypes.func,
  selectedEntityIds: PropTypes.object
};

export default memo(AddToGroupTrayHeader);
