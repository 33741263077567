/* eslint-disable jsx-a11y/mouse-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/display-name */
import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { fetchTooltipData } from '@actions/tooltips-actions';
import BaseTooltip from '@components/activity-logs/tooltips';
import { getAgencyIcon } from '@utils/icon-utils';
import './tooltip.scss';

class UserTooltip extends BaseTooltip {
  getDataType = () => 'user';

  renderContent = () => {
    const { data } = this.props;
    return (
      <div styleName="body-content">
        {data.agency_type && (
          <div styleName="body-row body-item">
            <img
              alt=""
              src={getAgencyIcon({id: data.agency_type})}
              styleName="icon"
            />
            {data.agency_name}
          </div>
        )}
        <div styleName="body-title">
          {data.name}
          {!data.is_active && (
            <div styleName="inactive">
              (Inactive)
            </div>
          )}
        </div>
        {(data.email || data.phone) && <div styleName="body-padding" />}
        {(data.email || data.phone) && (
          <div styleName="body-row">
            {data.email}
            {data.phone && <div styleName="bull">&bull;</div>}
            {data.phone}
          </div>
        )}
      </div>
    );
  };

  // Disable title and footer (i.e. use a different layout).
  renderTitle = () => null;
  renderFooter = () => null;

  onMouseOver = event => {
    const { id, data } = this.props;
    this.delayHoverOn(event);
    if (!data) {
      this.props.fetchTooltipData('log_user', null, id);
    }
  };
}

UserTooltip.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

const mapStateToProps = (state, props) => {
  const { log_user, error: { log_user: error } } = state.tooltips;
  return { data: log_user[props.id], error };
};

const mapDispatchToProps = { fetchTooltipData };

export default connect(mapStateToProps, mapDispatchToProps)(UserTooltip);
