/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import { includes } from 'lodash';
import { RadioButton } from 'material-ui/RadioButton';
import { filterStyles } from '@constants/mui-theme';
import AsyncInputSearch from '@shared/async-input-search';
import '../filter.scss';

class FilterAttrSearch extends Component {
  getCurrentValues = () => {
    const { filterName, values } = this.props;

    // Get the current filters for the specified name:
    const current = values[filterName];

    if (R.isNil(current) || R.isEmpty(current)) {
      return [];
    }

    if (Array.isArray(current)) {
      return current;
    }

    return [{
      arg: { filterName: current },
      title: current
    }];
  };

  onChange = suggestions => {
    const { filterName } = this.props;
    const newFilter = {
      [filterName]: suggestions.length ? suggestions[0].title : ''
    };
    this.props.setFilters(newFilter);
  };

  onQuickSelect = () => {
    const { filterName, quickSelect } = this.props;
    if (!this.quickSelectEnabled()) {
      return;
    }
    this.props.setFilters({[filterName]: [quickSelect.value]});
  };

  // We can only render the link if there's quick select data.
  showQuickSelect = () => {
    const { quickSelect } = this.props;
    // Show the quick selection link if the label and value are defined:
    return quickSelect && quickSelect.label && quickSelect.value;
  };

  // Disable the quick select text if the filter is disabled
  // or if the value is already selected.
  quickSelectEnabled = () => {
    const { enabled, quickSelect } = this.props;
    const values = this.getCurrentValues();
    return enabled && quickSelect.value && !includes(values, quickSelect.value);
  };

  getRadio = () => (
    <div styleName="radio">
      <RadioButton
        disabled={!this.props.filterEnabled}
        checked={this.props.radio.checked}
        onCheck={this.props.radio.onCheck}
        value={this.props.radio.value}
        {...filterStyles.filterGroupRadio}
      />
    </div>
  );

  toOptions = results => results.suggestions.map(
    (
      {
        arg,
        display,
        extra,
        id,
        icon,
        type,
        type_label
      }
    ) => (
      {
        arg,
        id,
        icon,
        extra,
        title: display,
        type,
        type_label,
        value: `${type}-${id}`
      }
    )
  );

  render() {
    const {
      dataType,
      enabled,
      filterName,
      placeHolder,
      quickSelect,
      radio,
      title,
      attrField
    } = this.props;
    // If there's data use a space to make the TextField component
    // think there's a label, thus making it reserve a 16px top margin.
    const label = R.isEmpty(this.getCurrentValues()) ? placeHolder : ' ';
    return (
      <div styleName="content-filter-group">
        {title && (
          <div styleName="title">
            {radio && this.getRadio()}
            <div styleName="label">{title}</div>
          </div>
        )}
        <div styleName="list">
          <AsyncInputSearch
            avatarProps={{ type: 'star', value: quickSelect ? quickSelect.value : null }}
            fieldName={filterName}
            dataType={dataType}
            disabled={!enabled}
            onChange={this.onChange}
            label={label}
            value={this.getCurrentValues()}
            toOptions={this.toOptions}
            attrField={attrField}
            autocompleteContainerStyles={{maxWidth: '16rem'}}
          />
        </div>
        {this.showQuickSelect() && (
          <div
            role="presentation"
            onClick={this.onQuickSelect}
            styleName={`quick-select-link ${this.quickSelectEnabled() ? '' : 'quick-disabled'}`}
          >
            Select <span styleName="quick-select-name">{quickSelect.label}</span>
          </div>
        )}
      </div>
    );
  }
}

FilterAttrSearch.propTypes = {
  attrField: PropTypes.string,
  dataType: PropTypes.string,
  enabled: PropTypes.bool,
  filterEnabled: PropTypes.bool,
  filterFunc: PropTypes.func,
  filterName: PropTypes.string,
  onRadioSelect: PropTypes.func,
  placeHolder: PropTypes.string,
  quickSelect: PropTypes.object,
  radio: PropTypes.object,
  setFilters: PropTypes.func,
  title: PropTypes.string,
  values: PropTypes.object
};

export default FilterAttrSearch;
