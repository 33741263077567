import React from 'react';
import PropTypes from 'prop-types';
import {
  FormControlLabel as MUIFormControlLabel,
  Switch as MUISwitch
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { fonts } from '@constants/fonts';

const useStyles = makeStyles(theme => ({  // eslint-disable-line no-unused-vars
  label: {
    fontSize: fonts.md
  }
}));

export const Switch = props => {
  const classes = useStyles(props);
  const {
    label = '',
    labelPlacement = 'end',
    color = 'primary',
    style,
    ...data
  } = props;

  return (
    <MUIFormControlLabel
      control={<MUISwitch {...data} color={color} />}
      classes={{
        label: classes.label,
        ...props.classes
      }}
      style={style}
      label={label}
      labelPlacement={labelPlacement}
    />
  );
};

Switch.propTypes = {
  classes: PropTypes.object,
  color: PropTypes.string,
  label: PropTypes.string,
  labelPlacement: PropTypes.string,
  style: PropTypes.object
};

export default Switch;
