import React from 'react';
import PropTypes from 'prop-types';
import {
  FormControl,
  FormLabel,
  RadioGroup as MUIRadioGroup
} from '@material-ui/core';
import RadioButton from './RadioButton';

export const RadioGroup = props => {
  const { label = '', ...data } = props;
  return (
    <FormControl component="fieldset">
      <FormLabel component="legend">{label}</FormLabel>
      <MUIRadioGroup {...data} />
    </FormControl>
  );
};

RadioGroup.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.any   // eslint-disable-line react/forbid-prop-types
};

RadioGroup.RadioButton = RadioButton;

export default RadioGroup;
