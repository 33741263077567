import * as R from 'ramda';
import { dotmapsBlue } from '@constants/colors';
import { getEntityType } from '@constants/config';
import { getEntityCategoryIcon, getEntityLegend } from '@utils/icon-utils';
import {
  calculateOverallBounds,
  optimizeSegmentForMap,
  parseCenterTuple
} from '@utils/map-utils';
import { getAgencyId } from '@utils/permission-utils';
import { enDash, parseDateTime } from '@utils/shared-utils';
import { getTrayTitle } from '@utils/tray-utils';

const statusOrder = ['conflict', 'pending', 'opportunity', 'resolved'];

export const getEntityMode = (openConflicts, pendingConflicts, resolvedConflicts, opportunities, modes) => {
  const entityModes = new Set();
  if (modes.has('conflict') && openConflicts && openConflicts.length > 0) {
    entityModes.add('conflict');
  }
  if (modes.has('pending') && pendingConflicts && pendingConflicts.length > 0) {
    entityModes.add('pending');
  }
  if (modes.has('resolved') && resolvedConflicts && resolvedConflicts.length > 0) {
    entityModes.add('resolved');
  }
  if (modes.has('opportunity') && opportunities) {
    entityModes.add('opportunity');
  }
  return statusOrder.find(mode => modes.has(mode) && entityModes.has(mode));
};

export const getHighestOverlapLevel = (entities, modes) => {
  const entityModes = new Set();
  entities.forEach(entity => {
    if (entity.openConflicts && entity.openConflicts.length > 0) {
      entityModes.add('conflict');
    }
    if (entity.pendingConflicts && entity.pendingConflicts.length > 0) {
      entityModes.add('pending');
    }
    if (entity.resolvedConflicts && entity.resolvedConflicts.length > 0) {
      entityModes.add('resolved');
    }
    if (entity.opportunities && entity.opportunities.length > 0) {
      entityModes.add('opportunity');
    }
  });
  return statusOrder.find(mode => modes.has(mode) && entityModes.has(mode));
};

const getEntityColor = (color, category_dict, agency_type, agencyTypes) => {
  // If an entity color is defined, it has precedence:
  if (color) {
    return color;
  }
  if (category_dict) {
    // If there's a category with a color, user it:
    const firstCategoryWithColor = category_dict.find(category => category.color);
    if (firstCategoryWithColor) {
      return firstCategoryWithColor.color;
    }
  }
  // Else default to the agency color or dotmapsBlue if there is no agency:
  return R.pathOr(dotmapsBlue, [agency_type, 'color'], agencyTypes);
};

export const optimizeEntityForTray = (entity, position, entityType, { agency, agency_type: agencyTypes, map_type: entityTypes }) => ({
  position: position || parseCenterTuple(R.pathOr(null, ['segments', 0, 'center'], entity)),
  entityId: entity.id,
  entity: {
    ...entity,
    conflicts: entity.overlaps.conflicts,
    opportunities: entity.overlaps.opportunities,
    start_date: parseDateTime(entity.start_date),
    end_date: parseDateTime(entity.end_date),
    agency_type_name: R.pathOr('', [entity.agency_type, 'name'], agencyTypes),
    agency_name: R.pathOr('', [entity.agency, 'name'], agency),
    iconId: getEntityCategoryIcon(entity, entityTypes),
    icon: getEntityLegend(agencyTypes[entity.agency_type], entityType, getEntityCategoryIcon(entity, entityTypes)),
    entity_type: entityType,
    fromStreet: R.pathOr('', ['segments', 0, 'from_street'], entity),
    toStreet: R.pathOr('', ['segments', 0, 'to_street'], entity),
    onStreet: R.pathOr('', ['segments', 0, 'on_street'], entity),
    title: getTrayTitle(entity)
  }
});

const optimizeOverlaps = overlaps => {
  if (overlaps) {
    return {
      // Handle both old and compact overlap keys:
      conflicts: overlaps.conflicts || overlaps.conf,
      opportunities: overlaps.opportunities || overlaps.opps,
      openConflicts: overlaps.open_conflicts || overlaps.open,
      pendingConflicts: overlaps.pending_conflicts || overlaps.pending,
      resolvedConflicts: overlaps.resolved_conflicts || overlaps.resolved
    };
  }
  return {
    conflicts: null,
    opportunities: null,
    openConflicts: null,
    pendingConflicts: null,
    resolvedConflicts: null
  };
};

export const optimizeEntitiesForMap = (entities, entityType, { agency_type: agencyTypes, map_type: entityTypes }) =>
  entities.map(
    ({
      id,
      segments,
      agency,
      agency_type,
      overlaps,
      category_dict,
      name,
      type_name
    }) => {
      const optimizedSegments = segments.map(optimizeSegmentForMap);
      const entityTypeName = entityType || type_name;
      const entityTypeObj = Object.values(entityTypes).find(type => type.name === entityTypeName);
      const color = entityTypeObj && entityTypeObj.color;
      return {
        id,
        icon: getEntityCategoryIcon({ category_dict, type_name: entityTypeName }, entityTypes),
        name,
        entityType: entityTypeName,
        ...optimizeOverlaps(overlaps),
        segments: optimizedSegments,
        color: getEntityColor(color, category_dict, agency_type, agencyTypes),
        category_dict,
        agencyTypeId: agency_type,
        ownEntity: getAgencyId() === agency,
        bounds: calculateOverallBounds(optimizedSegments)
      };
    }
  );

export const optimizeEntitiesForList = (entities, { agency_type: agencyTypes, map_type: entityTypes }) => entities.map(({
  agency_name,
  agency,
  description,
  category_dict,
  name,
  group_ids,
  id,
  overlaps,
  overlap_status,
  overlap_type,
  type_name,
  start_date,
  end_date,
  agency_type,
  segments,
  priority,
  extra
}) => {
  const optimizedSegments = segments.map(optimizeSegmentForMap);
  const iconId = getEntityCategoryIcon({
    category_dict,
    type_name
  }, entityTypes);
  const conflicts = overlaps?.conflicts || overlaps?.conf;
  return {
    id,
    description,
    name,
    group_ids,
    agency_type,
    category_dict,
    conflict: conflicts ? !!conflicts.length : false,
    conflicts: conflicts ? conflicts.split(',').map(conflict => parseInt(conflict, 10)) : null,
    agency_name,
    agency,
    type_name,
    entity_type: type_name,
    start_date,
    end_date,
    iconId,
    icon: getEntityLegend(agencyTypes[agency_type], type_name, iconId),
    overlap_status,
    overlap_type,
    segments,
    priority,
    bounds: calculateOverallBounds(optimizedSegments),
    extra
  };
});

export const optimizeEntitiesForGanttChart = R.map(entity => {
  const {
    id, name, agency_name, agency_type, blocks, type_name, on_street, start_date, end_date
  } = entity;
  return {
    agency_name,
    agency_type,
    blocks,
    entityType: type_name,
    id,
    name,
    on_street,
    start_date,
    end_date,
    title: getTrayTitle(entity)
  };
});

export const optimizeEntitiesForExport = R.map(entity => {
  const { type_name } = entity;
  // The only optimization we need for exporting entity data is to set the 'entityType'
  // field. Besides that, send all entity fields.
  return {
    ...entity,
    entityType: type_name
  };
});

// Given this method is called with these parameters:
//
//     url: http://localhost/?id=field1&name=field2
//     entity: { id: 1, name: 'A Project' }
//     fields: { field1: 'id', field2: 'name' }
//
// We'll return the URL with the template fields replaced with
// the entity values, for example:
//
//     http://localhost/?id=1&name=A+Project
//
export const entityURLFieldBuilder = (url, entity, fields) => {
  let newUrl = url;
  for (const [key, value] of Object.entries(fields)) {
    newUrl = newUrl.replace(key, entity[value]);
  }
  return newUrl;
};

export const getEntityCount = entities => {
  const counts = [];
  Object.keys(entities).forEach(key => {
    counts.push({
      label: `${getEntityType(key).label}`,
      value: entities[key].list.length || enDash
    });
  });
  return counts;
};

export const getGroupEntityCount = entities => {
  const counts = entities.reduce((acc, entity) => {
    const type = entity.type_name;
    acc[type] = (acc[type] || 0) + 1;
    return acc;
  }, {});

  return Object.entries(counts).map(([label, value]) => ({ label, value }));
};
