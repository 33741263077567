import React, { memo, useCallback } from 'react';
import PropTypes from 'prop-types';

import EntityCard from '@shared/list-card/entity-card';

import { SELECT_MODE_CHECK } from '@shared/ui-library/card/entity-data-card';

import '../tray.scss';

const AddToGroupTrayItem = ({
  checked,
  entity,
  onUpdateSelectedEntityIds
}) => {
  const handleSelect = useCallback(() => {
    onUpdateSelectedEntityIds({[entity.id]: !checked});
  }, [checked, entity, onUpdateSelectedEntityIds]);

  return (
    <EntityCard
      entity={entity}
      selectMode={SELECT_MODE_CHECK}
      checked={checked}
      onSelect={handleSelect}
      trayHover
    />
  );
};

AddToGroupTrayItem.propTypes = {
  checkClassName: PropTypes.string,
  checked: PropTypes.bool,
  entity: PropTypes.object,
  onUpdateSelectedEntityIds: PropTypes.func
};

export default memo(AddToGroupTrayItem);
