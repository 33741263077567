import React, { memo } from 'react';
import { useSelector } from 'react-redux';
import '../../../../forms/forms.scss';

const NonFieldErrors = () => {
  const errors = useSelector(state => state.dataDetail?.error?.non_field_errors);
  if (errors) {
    return (
      <div styleName="detail-error">
        {errors.map((msg, index) => <div key={index}>{msg}</div>)}
      </div>
    );
  }
  return null;
};

export default memo(NonFieldErrors);
