/* eslint-disable react/jsx-no-bind */
import React, { memo, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getAttachments } from '@actions/attachment-actions';
import { fetchDataDetail } from '@actions/data-detail-actions';
import { getDashboardBackgroundColor } from '@constants/config';
import DotmapsLoader from '@shared/dotmaps-loader';
import { Tabs, Tab } from '@shared/tabs';
import { getTaskType } from '@selectors/workflow-selector';
import { reachingTableEnd } from '@utils/data-table-utils';
import { findActivity, isSingleActivityLayout } from '@utils/workflow-utils';
import ActivitiesContent from './activities-content';
import ActivitiesTab from './activities-tab';
import DetailsContext from './details-context';
import DetailsHeader from './details-header';
import DetailsTab from './details-tab';
import './details.scss';

const Details = () => {
  const dispatch = useDispatch();
  const [activeTab, setActiveTab] = useState('activities');
  const [scrollTop, setScrollTop] = useState(0);
  const { taskActivityId, taskId } = useParams();
  const { cycle, loading: cycleLoading } = useSelector(state => state.workflow);
  const { data: task, error, loading } = useSelector(state => state.dataDetail);
  const taskType = useSelector(getTaskType);

  useEffect(() => {
    if (taskId) {
      dispatch(fetchDataDetail('task', taskId));
    }
    if (taskActivityId) {
      dispatch(getAttachments('task_activity', taskActivityId));
    }
  }, [dispatch, taskActivityId, taskId]);

  const selectedActivity = useMemo(() => {
    // If an activity is selected from the URL path, use that one:
    if (taskActivityId) {
      const activity = findActivity(taskActivityId, task?.activities);
      if (activity) {
        return activity;
      }
    }

    // Else use the first activity:
    return task?.activities?.[0] || null;
  }, [taskActivityId, task?.activities]);

  const onScroll = event => {
    if (activeTab === 'activities' && reachingTableEnd(event)) {
      setScrollTop(event.target.scrollTop);
    }
  };

  if (cycleLoading) {
    return null;
  }

  if (!task && loading) {
    return (
      <div styleName="task-details-container">
        <DotmapsLoader display color={getDashboardBackgroundColor()} />
      </div>
    );
  }

  if (!taskId || !task || !taskType) {
    return null;
  }

  const contextValue = {
    activity: selectedActivity,
    cycle,
    error,
    scrollTop,
    task,
    taskActivityId,
    taskId,
    taskType
  };

  if (taskActivityId && isSingleActivityLayout(task)) {
    return (
      <DetailsContext.Provider value={contextValue}>
        <div styleName="task-details-container task-details-activity-container">
          <DetailsHeader />
          {loading && <DotmapsLoader display color={getDashboardBackgroundColor()} />}
          <div styleName="task-details-form">
            <div styleName="task-details-form-column">
              <DetailsTab />
            </div>
            <div styleName="task-details-form-column">
              <ActivitiesContent />
            </div>
          </div>
        </div>
      </DetailsContext.Provider>
    );
  }

  return (
    <DetailsContext.Provider value={contextValue}>
      <div styleName="task-details-container">
        <DetailsHeader showDelete />
        {loading && <DotmapsLoader display color={getDashboardBackgroundColor()} />}
        <Tabs tabItemWidth="100px" onChange={setActiveTab} onScroll={onScroll} value={activeTab}>
          <Tab label="DETAILS" value="details">
            <DetailsTab />
          </Tab>
          <Tab label="ACTIVITIES" value="activities">
            <ActivitiesTab />
          </Tab>
        </Tabs>
      </div>
    </DetailsContext.Provider>
  );
};

export default memo(Details);
