import React from 'react';
import PropTypes from 'prop-types';
import { dotmapsBlue } from '@constants/colors';
import { Icon } from '@mui';
import { entityURLFieldBuilder } from '@utils/entity-utils';
import './fields-link-button.scss';

const FieldsLinkButton = ({ entity, link }) => {
  const getUrl = () => {
    const { url_fields, url } = link;
    if (url_fields) {
      return entityURLFieldBuilder(url, entity, url_fields);
    }
    return url;
  };

  const { icon, label } = link;
  const url = getUrl();

  return (
    <a href={url} rel="noopener noreferrer" target="_blank" styleName="fields-link-button-link">
      <div styleName="button-box">
        <Icon
          color={dotmapsBlue}
          style={{ fontSize: '1.125rem', paddingRight: '0.5rem' }}
        >{icon}</Icon>
        {label}
      </div>
    </a>
  );
};

FieldsLinkButton.propTypes = {
  entity: PropTypes.object,
  link: PropTypes.object
};

export default FieldsLinkButton;
