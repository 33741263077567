import React, { memo } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import classNames from 'classnames';

import List from '@material-ui/core/List';
import ListSubheader from '@material-ui/core/ListSubheader';
import FlexSeparator from '@shared/helpers/flex-separator';
import SectionItem from './section-item';

import { getMenuConfig } from '../selectors';

import { isMenuSectionActive } from '@utils/dashboard-details-utils';
import { verifyPermissions } from '@utils/permission-utils';

import styles from './menu.scss';

const PortalMenu = ({currentDataType, currentSubType}) => {
  const menuConfig = useSelector(state => getMenuConfig(state, currentDataType));

  return (
    <div className={styles.dashboardMenuContainer}>
      {menuConfig.map(section => {
        const { items, permissions, title } = section;
        if (!verifyPermissions(permissions)) {
          return null;
        }
        return (
          <div key={title}>
            <List>
              <ListSubheader
                classes={{
                  root: classNames(styles.title, {[isMenuSectionActive(section, currentDataType)]: styles.activeHeader})
                }}
              >
                {title}
              </ListSubheader>
              {items.map(item => (
                <SectionItem
                  key={`${item.type}-${item.subtype}`}
                  sectionItem={item}
                  currentDataType={currentDataType}
                  currentSubType={currentSubType}
                />
              ))}
            </List>
          </div>
        );
      })}
      <FlexSeparator />
    </div>
  );
};

PortalMenu.propTypes = {
  currentDataType: PropTypes.string,
  currentSubType: PropTypes.string
};

export default memo(PortalMenu);
