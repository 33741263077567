import React, { memo, useMemo } from 'react';
import PropTypes from 'prop-types';

import DataCard from './data-card';

import { Button } from '@material-ui/core';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';

export const SELECT_MODE_CHECK = 'check';
export const SELECT_MODE_DETAILS = 'details';
export const SELECT_MODE_EXPAND = 'expand';
export const SELECT_MODE_NONE = 'none';

import styles from './entity-data-card.scss';

const EntityDataCard = ({
  checked,
  children,
  className,
  icon,
  onSelect = null,
  onViewDetails = null,
  selectMode = SELECT_MODE_EXPAND,
  selected = false,
  title,
  ...passThroughProps
}) => {

  const expand = useMemo(() => {
    if (selectMode === SELECT_MODE_EXPAND) {
      return (
        <Button
          classes={{
            root: styles.detailButton
          }}
          onClick={onViewDetails}
        >
          View Details
        </Button>
      );
    }
    return null;
  }, [onViewDetails, selectMode]);

  const expanded = useMemo(() => {
    return selectMode === SELECT_MODE_EXPAND && selected;
  }, [selected, selectMode]);

  const cardIcon = useMemo(() => {
    if (selectMode === SELECT_MODE_CHECK) {
      if (checked) {
        return (
          <CheckBoxIcon
            classes={{root: styles.checkboxChecked}}
            titleAccess="Selected"
          />
        );
      }
      return (
        <CheckBoxOutlineBlankIcon
          classes={{root: styles.checkbox}}
          titleAccess="Not Selected"
        />
      );
    }
    return icon;
  }, [checked, selectMode, icon]);

  const handleClick = useMemo(() => {
    switch (selectMode) {
    case SELECT_MODE_DETAILS: {
      return () => onViewDetails();
    }
    case SELECT_MODE_CHECK:
    case SELECT_MODE_EXPAND: {
      return onSelect;
    }
    default: {
      return null;
    }
    }
  }, [selectMode, onSelect, onViewDetails]);

  return (
    <DataCard
      borderRadius={false}
      className={className}
      expand={expand}
      expanded={expanded}
      icon={cardIcon}
      onClick={handleClick}
      selected={selected}
      title={title}
      {...passThroughProps}
    >
      {children}
    </DataCard>
  );
};

EntityDataCard.propTypes = {
  checked: PropTypes.bool,
  children: PropTypes.node,
  className: PropTypes.string,
  icon: PropTypes.node,
  onSelect: PropTypes.func,
  onViewDetails: PropTypes.func,
  selectMode: PropTypes.oneOf([
    SELECT_MODE_DETAILS,
    SELECT_MODE_CHECK,
    SELECT_MODE_EXPAND,
    SELECT_MODE_NONE
  ]),
  selected: PropTypes.bool,
  title: PropTypes.string
};

export default memo(EntityDataCard);
