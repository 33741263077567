/* eslint-disable react/display-name */
import React from 'react';
import PropTypes from 'prop-types';
import { Tooltip as MUITooltip } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { colors } from '@constants/colors';

const useStyles = makeStyles({
  tooltip: {
    backgroundColor: colors.neutral.white,
    color: colors.neutral.black,
    boxShadow: '0px 2px 1px -1px rgba(0,0,0,0.2)',
    fontSize: 11,
    fontWeight: 'normal'
  }
});

export const Tooltip = React.forwardRef((props, ref) => {
  const classes = useStyles();
  const { children, lightTooltip, ...data } = props;

  return (
    <MUITooltip
      {...data}
      classes={{
        ...(lightTooltip && { tooltip: classes.tooltip }),
        ...props?.classes
      }}
      ref={ref}
    >
      {children}
    </MUITooltip>
  );
});

Tooltip.propTypes = {
  children: PropTypes.node,
  classes: PropTypes.object,
  lightTooltip: PropTypes.bool
};
