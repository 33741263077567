/* eslint-disable react/jsx-no-bind */
import React from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Button from '@material-ui/core/Button';
import DnsOutlinedIcon from '@material-ui/icons/DnsOutlined';
import { openDashboardDialog } from '@actions/dashboard-actions';
import TaskTypePreviewForm from '@components/admin/task-type-preview-form';
import * as dialog from '@constants/dialogs';
import './workflow-preview.scss';

const WorkflowPreview = ({ selectedTaskType }) => {
  const { id } = useParams();
  const { loading } = useSelector(state => state.dataDetail);
  const dispatch = useDispatch();

  if (loading) {
    return null;
  }

  const onAdd = () => dispatch(openDashboardDialog(dialog.ADD_WORKFLOW_TASK_TYPE, { id }));

  if (!selectedTaskType) {
    return (
      <div data-testid="workflow-preview-empty" styleName="workflow-preview-empty-container">
        <div styleName="content">
          <div styleName="icon">
            <DnsOutlinedIcon htmlColor="#E0E0E0" style={{ fontSize: '6rem' }} />
          </div>
          <div styleName="title">No task templates yet</div>
          <div styleName="subtext">
            To start building a workflow, please
            <br />
            add task templates first.
          </div>
          <div styleName="button">
            <Button color="primary" onClick={onAdd}>ADD TASK TEMPLATES</Button>
          </div>
        </div>
      </div>
    );
  }
  return <TaskTypePreviewForm taskType={selectedTaskType} />;
};

WorkflowPreview.propTypes = {
  selectedTaskType: PropTypes.object
};

export default WorkflowPreview;
