import React, { memo, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { push } from 'connected-react-router';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { DATA_TYPES } from '@components/config/constants';
import EditActions from '@components/portal/details/top-bar-edit-actions';
import ViewActions from '@components/portal/details/top-bar-view-actions';
import { PERMISSION_GROUP_ANALYST } from '@constants/permission';
import ArrowBackIcon from '@shared/arrow-back-icon';
import { isInGroup } from '@utils/permission-utils';
import './top-bar.scss';

const TopBar = ({ edit }) => {
  const dispatch = useDispatch();
  const { dataType } = useParams();

  const portal = useMemo(() => DATA_TYPES[dataType].portal, [dataType]);

  const onClick = useCallback(() => {
    dispatch(push(`/${portal}/${dataType}`));
  }, [dataType, dispatch, portal]);

  // Don't show the back arrow on the agency details page
  // for analysts (since they don't see the agency list, they only see the details
  // for their agency, thus they don't have a place to return).
  const showBackArrow = !(isInGroup(PERMISSION_GROUP_ANALYST) && dataType === 'agency');
  return (
    <div styleName="portal-top-bar">
      {showBackArrow && <ArrowBackIcon onClick={onClick} style={{ padding: '0' }} />}
      <div style={{ flexGrow: 1 }} />
      {edit && <EditActions />}
      {!edit && <ViewActions />}
    </div>
  );
};

TopBar.propTypes = {
  edit: PropTypes.bool
};

export default memo(TopBar);
