import React, { memo, useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import EntityDataCard from '@shared/ui-library/card/entity-data-card';
import AttributeLabelContainer from '@shared/ui-library/attribute/attribute-label-container';
import AttributeLabel from '@shared/ui-library/attribute/attribute-label';
import GroupTypeIcon from '@icons/group-type-icon';

import { getConfig } from '@components/config/selectors';
import { getHoverGroupId } from '@selectors/map-selector';
import { setOverlapHoverGroup, openGroupsTray } from '@actions/map-actions';

const GroupCard = ({
  group,
  trayHover = false,
  ...passThroughProps
}) => {
  const dispatch = useDispatch();
  const { name, external_id: externalId, type, status_name: statusName, entities } = group;
  const {group_types: groupTypes} = useSelector(getConfig);
  const selectedGroupId = useSelector(state => getHoverGroupId(state));
  const selected = group.id === selectedGroupId;

  const icon = useMemo(() => {
    const typeConfig = groupTypes.find(config => config.id === type);
    if (typeConfig) {
      return <GroupTypeIcon type={typeConfig.name} />;
    }
    return <GroupTypeIcon type="group"/>;
  }, [type, groupTypes]);

  const selectGroup = useCallback(() => dispatch(setOverlapHoverGroup(group)), [dispatch, group]);
  const deselectGroup = useCallback(() => dispatch(setOverlapHoverGroup()), [dispatch]);
  const handleClick = useMemo(() => {
    if (selected) {
      return deselectGroup;
    }
    return selectGroup;
  }, [selected, selectGroup, deselectGroup]);

  const handleMouseEnter = useMemo(() => {
    if (trayHover) {
      return () => dispatch(setOverlapHoverGroup(group));
    }
    return null;
  }, [dispatch, group, trayHover]);

  const handleMouseExit = useMemo(() => {
    if (trayHover) {
      return () => dispatch(setOverlapHoverGroup());
    }
    return null;
  }, [dispatch, trayHover]);

  const openGroup = useCallback(
    () => {
      const groupProps = { id: group.id, item: group };
      dispatch(openGroupsTray(groupProps));
    },
    [dispatch, group]
  );
  return (
    <EntityDataCard
      selected={selected}
      icon={icon}
      onSelect={handleClick}
      onViewDetails={openGroup}
      title={name}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseExit}
      {...passThroughProps}
    >
      <AttributeLabelContainer>
        <AttributeLabel text={`${entities.length} Items`} />
        {externalId && <AttributeLabel text={externalId} />}
        <AttributeLabel text={statusName} />
      </AttributeLabelContainer>
    </EntityDataCard>
  );
};

GroupCard.propTypes = {
  group: PropTypes.object,
  trayHover: PropTypes.bool
};

export default memo(GroupCard);
