/**
 * i18n.js
 *
 * Internationalization support code.
 */
import { DEFAULT_LOCALE } from './constants/i18n';

// eslint-disable-next-line import/extensions
import enTranslationMessages from './translations/en.json';
// eslint-disable-next-line import/extensions
import esTranslationMessages from './translations/es.json';
// eslint-disable-next-line import/extensions
import ruTranslationMessages from './translations/ru.json';

export const appLocales = [
  'en',
  'es',
  'ru'
];

// Keep browser's language code commented, we'll probably use it from some setup
// in the database (but we can uncomment it if we choose to use the browser's one):

// // Define user's language. Different browsers have the user locale defined
// // on different fields on the `navigator` object, so we make sure to account
// // for these different by checking all of them
// let lang = (navigator.languages && navigator.languages[0]) ||
//             navigator.language ||
//            navigator.userLanguage;
//
// // Check if the user's language is supported.
// if (typeof lang !== 'undefined' && !appLocales.includes(lang)) {
//   // If it's not supported, check if it has a country part and get the language part of it.
//   if (lang.includes('-')) {
//     lang = lang.split('-')[0];
//   } else {
//     // Else default to English:
//     lang = 'en';
//   }
// } else {
//   lang = 'en';
// }
//
// export const language = lang;

export const formatTranslationMessages = (locale, messages) => {
  const defaultFormattedMessages =
    locale !== DEFAULT_LOCALE
      ? formatTranslationMessages(DEFAULT_LOCALE, enTranslationMessages)
      : {};
  const flattenFormattedMessages = (formattedMessages, key) => {
    const formattedMessage =
      !messages[key] && locale !== DEFAULT_LOCALE
        ? defaultFormattedMessages[key]
        : messages[key];
    return Object.assign(formattedMessages, { [key]: formattedMessage });
  };
  return Object.keys(messages).reduce(flattenFormattedMessages, {});
};

export const translationMessages = {
  en: formatTranslationMessages('en', enTranslationMessages),
  es: formatTranslationMessages('es', esTranslationMessages),
  ru: formatTranslationMessages('ru', ruTranslationMessages)
};

