/* eslint-disable react/jsx-no-bind */
import React, { memo, useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setAgency } from '@actions/auth-actions';
import Button from '@material-ui/core/Button';
import { Select } from '@mui';
import Dialog from '@shared/dialogs/dialog';
import { filterAgencies, isAppAdmin } from '@utils/permission-utils';

const { MenuItem } = Select;

const AgencySelect = () => {
  const dispatch = useDispatch();
  const [selected, setSelected] = useState(null);
  const { agency: agencies, agency_type: agencyType } = useSelector(state => state.dataTypes);
  const { agencyId } = useSelector(state => state.auth.user);

  const onChange = useCallback(
    event => setSelected(event.target.value),
    [setSelected]
  );

  const onSubmit = useCallback(
    () => dispatch(setAgency(selected)),
    [dispatch, selected]
  );

  if (agencyId || !agencies || !agencyType || isAppAdmin()) {
    return null;
  }

  const agencyRenderer = (current, values) => {
    if (values?.length === 0 || !current) {
      return 'Agency name';
    }
    return Object.values(agencies).filter(agency => agency.id === current)[0].name;
  };

  return (
    <Dialog
      actions={
        <Button
          color="primary"
          disabled={!selected}
          onClick={onSubmit}
          variant="contained"
        >
          GET STARTED
        </Button>
      }
      maxWidth="26"
      title="Welcome to Coordinate!"
    >
      <p>Please select your agency to get started.</p>
      <Select
        displayEmpty
        renderValue={values => agencyRenderer(selected, values)}
        value={selected}
        onChange={onChange}
      >
        {filterAgencies(agencies).map(agency => (
          <MenuItem key={agency.id} value={agency.id}>
            {agency.name}
          </MenuItem>
        ))}
      </Select>
    </Dialog>
  );
};

export default memo(AgencySelect);
