/* eslint-disable max-nested-callbacks */
import {
  LOGS_CLEAR,
  LOGS_FETCH_SUCCESS,
  LOGS_LOADING,
  LOGS_RELOAD
} from '@constants/action-types';
import { pushDashboardMessage, setResolutionLoading } from './dashboard-actions';
import { clearDataType, fetchDataDetail } from './data-detail-actions';
import { getServerErrorAction } from './shared-actions';
import { BASE_API_URL } from '@constants/endpoints';
import axios from 'axios';

const requestUrl = (app, model, id) => `${BASE_API_URL}/log/${app}/${model}/${id}/`;

export const clearLogs = () => dispatch => {
  dispatch({ type: LOGS_CLEAR });
  return Promise.resolve();
};

const setLogsLoading = () => dispatch => {
  dispatch({ type: LOGS_LOADING });
  return Promise.resolve();
};

const fetchLogsSuccess = payload => ({
  type: LOGS_FETCH_SUCCESS,
  payload
});

export const fetchLogsData = (app, model, id, next, extra = {}, sort = '-entry__id') => dispatch => {
  return dispatch(setLogsLoading()).then(() => {
    const url = next ? next : requestUrl(app, model, id);
    const params = { ordering: sort, limit: 50, ...extra };
    const request = next ? axios.get(url) : axios.get(url, { params });
    request.then(
      ({ data }) => dispatch(fetchLogsSuccess(data)),
      error => dispatch(getServerErrorAction('logs', error))
    );
  });
};

export const setLogReload = () => dispatch => {
  dispatch({ type: LOGS_RELOAD });
  return Promise.resolve();
};

export const logOverlapComment = payload => {
  const { entityId, overlapEntityId } = payload;
  const url = `${BASE_API_URL}/overlap/${overlapEntityId}/comment/`;
  const params = {
    comment: payload.comment,
    mentions: payload.mentions,
    overlap_id: payload.overlapId
  };
  return dispatch => dispatch(setResolutionLoading(true)).then(() => {
    const request = axios.post(url, params);
    request.then(
      data => {  // eslint-disable-line no-unused-vars
        dispatch(clearDataType('overlap')).then(() => {
          // Reload the data:
          dispatch(fetchDataDetail('overlap', entityId)).then(() => {
            dispatch(setLogReload()).then(() => {
              dispatch(setResolutionLoading(false)).then(() => {
                dispatch(pushDashboardMessage('Comment sent.'));
              });
            });
          });
        });
      },
      // eslint-disable-next-line no-unused-vars
      error => dispatch(setResolutionLoading(false)).then(() => {
        dispatch(pushDashboardMessage('Error saving comment.'));
      })
    );
  });
};

export const logTaskActivityComment = payload => {
  const { comment, id, mentions } = payload;
  const url = `${BASE_API_URL}/task_activity/${id}/comment/`;
  const params = { comment, mentions };
  const request = axios.post(url, params);
  return dispatch => request.then(
    data => {  // eslint-disable-line no-unused-vars
      dispatch(setLogReload()).then(() => {
        dispatch(pushDashboardMessage('Comment sent.'));
      });
    },
    // eslint-disable-next-line no-unused-vars
    error => dispatch(pushDashboardMessage('Error saving comment.'))
  );
};

export const logEntityActivityComment = payload => {
  const { comment, id, mentions } = payload;
  const url = `${BASE_API_URL}/map/${id}/comment/`;
  const params = { comment, mentions };
  const request = axios.post(url, params);
  return dispatch => request.then(
    data => {  // eslint-disable-line no-unused-vars
      dispatch(setLogReload()).then(() => {
        dispatch(pushDashboardMessage('Comment sent.'));
      });
    },
    // eslint-disable-next-line no-unused-vars
    error => dispatch(pushDashboardMessage('Error saving comment.'))
  );
};
