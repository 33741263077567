/* eslint-disable css-modules/no-unused-class */
import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import FilterList from '@components/calendar/filter-list';
import { getCalendarEntity } from '@constants/config';
import Button from '@material-ui/core/Button';
import styles from './filter-sidebar.scss';

const FilterSidebar = ({ resetAllFilters }) => {
  const calendarEntity = getCalendarEntity();
  const { map_type } = useSelector(state => state.dataTypes);
  const entity = Object.values(map_type).find(type => type.name === calendarEntity);
  return (
    <div styleName="styles.filter-sidebar-container">
      <div styleName="styles.filter-sidebar-title">
        Filters
      </div>
      <div styleName="styles.filter-sidebar-buttons">
        <Button
          classes={{
            label: styles.reset,
            root: styles.reset
          }}
          onClick={resetAllFilters}
        >
          Reset
        </Button>
      </div>
      <FilterList allText={`All ${entity.label}`} />
    </div>
  );
};

FilterSidebar.propTypes = {
  resetAllFilters: PropTypes.func
};

export default memo(FilterSidebar);
