import React, { memo } from 'react';
import { useSelector } from 'react-redux';
import * as R from 'ramda';
import ListItem from '@components/map/tray/recent/list-item';
import { mapTabsConfig } from '@constants/component-configs';
import * as colors from '@constants/colors';
import { Icon } from '@mui';
import '../tray.scss';

const Recent = () => {
  const { currentTab, trayRecent } = useSelector(state => state.map);

  if (currentTab !== mapTabsConfig.tabs.recent.value) {
    // Don't render if it's not the recent tab.
    return null;
  }

  return (
    <div styleName="map-tray-body-wrapper">
      {R.isEmpty(trayRecent) && (
        <div styleName="map-tray-body-empty map-tray-body-shadow">
          <Icon
            color={colors.dotmapsGrayEmptyIcon}
            style={{ fontSize: 72 }}
          >schedule</Icon>
          <div styleName="map-tray-body-empty-content">
            Recently selected markers will<br />appear here.
          </div>
        </div>
      )}
      {!R.isEmpty(trayRecent) && (
        <div styleName="map-tray-body">
          {trayRecent.map(item => <ListItem item={item} key={item.id} />)}
        </div>
      )}
    </div>
  );
};

export default memo(Recent);
