/* eslint-disable react/display-name */
/* eslint-disable react/jsx-no-bind */
import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { push } from 'connected-react-router';
import SettingsForm, { settingsFormMapDispatchToProps } from '@components/settings/settings-form';
import { getEmailSettingsConfig } from '@constants/config';
import { formMapStateToProps } from '@shared/form';
import ToggleControl from '@shared/form/toggle-control';
import DropdownControl from '@shared/form/dropdown-control';
import '../settings.scss';

class Details extends SettingsForm {
  onBackClick = () => this.props.push('/settings/email');

  afterReceiveProps = data => {
    // Check if we must disable the fields after load:
    const layout = this.getLayout();
    layout.columns.forEach(column => this.checkDisabled(column, data[column.field]));
  };

  checkDisabled = (column, val) => {
    // If it's a toggle column:
    if (column.is_toggle) {
      // Find the display value:
      const value = column.values.find(item => item.id === val).value;
      // If it's Off, disable all other fields:
      if (value === 'Off') {
        this.setState({ disabled: true });
      } else {
        this.setState({ disabled: false });
      }
    }
  };

  getPayload = () => {
    const { type } = this.props.match.params;
    const { data } = this.state;
    return {
      email: {
        [type]: { ...data }
      }
    };
  };

  getLayout = () => {
    const { type } = this.props.match.params;
    return getEmailSettingsConfig().details[type];
  };

  renderForm = layout => {
    const { disabled, data } = this.state;
    const { type } = this.props.match.params;
    return (
      <div styleName="form">
        {layout.columns.map(column => (
          <div styleName="row row-single-split" key={column.field}>
            <div styleName="col1">
              <div styleName="row-title">
                {column.label}
              </div>
              <div styleName="row-hint">
                {column.hint && column.hint[type]}
              </div>
            </div>
            <div styleName="col2">
              {column.type === 'dropdown' && (
                <DropdownControl
                  id={column.field}
                  onChange={val => {
                    this.checkDisabled(column, val);
                    this.onChange(column.field, val);
                  }}
                  selected={data[column.field]}
                  values={column.values}
                />
              )}
              {column.type === 'toggle' && (
                <ToggleControl
                  disabled={disabled}
                  onChange={val => this.onChange(column.field, val)}
                  value={data[column.field]}
                />
              )}
            </div>
          </div>
        ))}
      </div>
    );
  };
}

Details.propTypes = {
  match: PropTypes.object,
  push: PropTypes.func
};

const mapStateToProps = (state, props) => {
  const { email } = state.settings;
  const { type } = props.match.params;
  const data = email ? email[type] : null;
  return { data, ...formMapStateToProps(state)};
};

const mapDispatchToProps = { ...settingsFormMapDispatchToProps, push };

export default connect(mapStateToProps, mapDispatchToProps)(Details);
