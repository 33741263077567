/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/jsx-no-bind */
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { push } from 'connected-react-router';
import GroupTypeIcon from '@icons/group-type-icon';
import { getDetailCardConfig } from '@constants/config';
import { renderGroupBullFields } from '@utils/group-utils';
import './group-cards.scss';

const GroupCards = ({ groups }) => {
  const dispatch = useDispatch();
  const [open, setOpen] = useState({});

  const goToGroupEdit = (id, groupTypeName) => {
    const source = location.pathname;
    dispatch(push({ pathname: `/group/${groupTypeName}/${id}`, state: { source } }));
  };

  const toggleOpen = id => setOpen({ [id]: !open[id] });

  return groups.map(group => {
    const { fields, prefixes } = getDetailCardConfig().group_type[group.type_name];
    return (
      <div
        key={group.id}
        onClick={() => toggleOpen(group.id)}
        role="presentation"
        styleName={`groups-card ${open[group.id] ? 'active-card' : ''}`}
      >
        <div styleName="icon-column">
          <GroupTypeIcon type={group.type_name} />
        </div>
        <div styleName="data-column">
          <div styleName="title">
            {group.name}
          </div>
          <div styleName="body">
            {renderGroupBullFields(group, fields, prefixes)}
          </div>
          {open[group.id] && (
            <div styleName="link" onClick={() => goToGroupEdit(group.id, group.type_name)} role="presentation">
              VIEW DETAILS
            </div>
          )}
        </div>
      </div>
    );
  });
};

GroupCards.propTypes = {
  groups: PropTypes.array
};

export default GroupCards;
