/* eslint-disable react/jsx-no-bind */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { PureComponent, Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  countOverlaps,
  ItemUser,
  renderOverlapCreatedType
} from '@utils/logs-utils';
import {
  EntityComment,
  OverlapComment,
  Resolve,
  Revoke,
  WorkflowInfo,
  TaskActivityStatus,
  EntityInfo,
  getTimeSinceCreated
} from '@utils/notification-utils';
import { buildAssignedTaskTo } from '@utils/workflow-utils';
import './notification-contents.scss';

const NotificationContentsWrap = ({ contents, extra, user }) => (
  <Fragment>
    <div styleName="item-message-header">
      <div styleName="log-item-message-container">
        <ItemUser user={user} />
        {contents}
      </div>
    </div>
    {extra &&
      <div styleName="item-message-extra">
        {extra}
      </div>
    }
  </Fragment>
);

NotificationContentsWrap.propTypes = {
  contents: PropTypes.node,
  extra: PropTypes.node,
  user: PropTypes.object
};

class NotificationContents extends PureComponent {
  render() {
    const { action, attrs, user, users, created, currentUser } = this.props;
    const timeSinceCreated = getTimeSinceCreated(created);
    switch (action) {
    case 'overlap_created': {
      const count = countOverlaps(attrs);
      if (count > 0) {
        if (count === 1) {
          return (
            <NotificationContentsWrap
              user={user}
              contents={(
                <span>
                  The following {attrs.entity1.entity_type} is now in conflict with 1 {attrs.entity}. {renderOverlapCreatedType(attrs)}
                  <div styleName="created">{timeSinceCreated}</div>
                  <EntityInfo entity={attrs.entity1} />
                </span>
              )}
            />
          );
        }
        return (
          <NotificationContentsWrap
            user={user}
            contents={(
              <span>
                The following {attrs.entity1.entity_type} is now in conflict with {count} {attrs.entity}s. {renderOverlapCreatedType(attrs)}
                <div styleName="created">{timeSinceCreated}</div>
                <EntityInfo entity={attrs.entity1} />
              </span>
            )}
          />
        );
      } else
        if (attrs.ids) {
          if (attrs.ids.length === 1) {
            return (
              <NotificationContentsWrap
                user={user}
                contents={(
                  <span>
                    The following {attrs.entity1.entity_type} is now in conflict with 1 {attrs.entity}. {renderOverlapCreatedType(attrs)}
                    <div styleName="created">{timeSinceCreated}</div>
                    <EntityInfo entity={attrs.entity1} />
                  </span>
                )}
              />
            );
          }
          return (
            <NotificationContentsWrap
              user={user}
              contents={(
                <span>
                  The following {attrs.entity1.entity_type} is now in conflict with {attrs.ids.length} {attrs.entity}s.
                  {renderOverlapCreatedType(attrs)}
                  <div styleName="created">{timeSinceCreated}</div>
                  <EntityInfo entity={attrs.entity1} />
                </span>
              )}
            />
          );
        }
      return (
        <NotificationContentsWrap
          user={user}
          contents={(
            <span>
              The following {attrs.entity1.entity_type} is now in conflict with 1 {attrs.entity2.entity_type}. {renderOverlapCreatedType(attrs)}
              <div styleName="created">{timeSinceCreated}</div>
              <EntityInfo entity={attrs.entity1} />
            </span>
          )}
        />
      );
    }
    case 'opportunity_to_conflict': {
      if (attrs.ids) {
        if (attrs.ids.length === 1) {
          return (
            <NotificationContentsWrap
              user={user}
              contents={
                <span>
                  The following {attrs.entity1.entity_type} is now in conflict with 1 {attrs.entity}. {renderOverlapCreatedType(attrs)}
                  <div styleName="created">{timeSinceCreated}</div>
                  <EntityInfo entity={attrs.entity1} />
                </span>
              }
            />
          );
        }
        return (
          <NotificationContentsWrap
            user={user}
            contents={
              <span>
                The following {attrs.entity1.entity_type} is now in conflict with {attrs.ids.length} {attrs.entity}s.
                {renderOverlapCreatedType(attrs)}
                <div styleName="created">{timeSinceCreated}</div>
                <EntityInfo entity={attrs.entity1} />
              </span>
            }
          />
        );
      }
      return (
        <NotificationContentsWrap
          user={user}
          contents={
            <span>
              The following {attrs.entity1.entity_type} is now in conflict with 1 {attrs.entity2.entity_type}. {renderOverlapCreatedType(attrs)}
              <div styleName="created">{timeSinceCreated}</div>
              <EntityInfo entity={attrs.entity1} />
            </span>
          }
        />
      );
    }
    case 'entity_comment': {
      let contentString = ` commented on a ${attrs.entity_type}`;
      const currentUserEmail = currentUser.email;
      if (attrs.mentions && attrs.mentions.includes(currentUserEmail)) {
        contentString = ' mentioned you in a comment';
      }
      return (
        <NotificationContentsWrap
          user={user}
          contents={<span>{contentString}</span>}
          extra={<EntityComment attrs={attrs} users={users} />}
        />
      );
    }
    case 'task_activity_comment': {
      return (
        <NotificationContentsWrap
          user={user}
          contents={<span> commented on a task</span>}
          extra={<div>
            <WorkflowInfo attrs={attrs} />
            <OverlapComment attrs={attrs} users={users} />
          </div>}
        />
      );
    }
    case 'task_assign': {
      const to = buildAssignedTaskTo(attrs);
      return (
        <NotificationContentsWrap
          user={user}
          contents={<span> has assigned a task to {to}</span>}
          extra={<WorkflowInfo attrs={attrs} />}
        />
      );
    }
    case 'task_activity_status_update': {
      const { taskStatuses } = this.props;
      const statuses = Object.values(taskStatuses);
      const oldStatus = statuses.find(status => status.name === attrs.old_status);
      const newStatus = statuses.find(status => status.name === attrs.new_status);
      return (
        <NotificationContentsWrap
          user={user}
          contents={<span> transitioned a task</span>}
          extra={<div>
            <WorkflowInfo attrs={attrs} />
            <TaskActivityStatus
              attrs={attrs}
              oldStatus={oldStatus}
              newStatus={newStatus}
            />
          </div>}
        />
      );
    }
    case 'overlap_comment': {
      return (
        <NotificationContentsWrap
          user={user}
          contents={<span> commented on a conflict.</span>}
          extra={<OverlapComment attrs={attrs} users={users} />}
        />
      );
    }
    case 'overlap_resolve': {
      return (
        <NotificationContentsWrap
          user={user}
          contents={<span> resolved a conflict.</span>}
          extra={<Resolve attrs={attrs} users={users} />}
        />
      );
    }
    case 'overlap_revoke': {
      return (
        <NotificationContentsWrap
          user={user}
          contents={<span> revoked a resolution.</span>}
          extra={<Revoke attrs={attrs} users={users} />}
        />
      );
    }
    default:
      return null;
    }
  }
}

NotificationContents.propTypes = {
  action: PropTypes.string,
  attrs: PropTypes.object,
  created: PropTypes.string,
  currentUser: PropTypes.object,
  taskStatuses: PropTypes.object,
  user: PropTypes.object,
  users: PropTypes.object
};

const mapStateToProps = state => {
  const { user, task_status } = state.dataTypes;
  const currentUser = state.config.user;
  return { users: user, taskStatuses: task_status, currentUser };
};

export default connect(mapStateToProps, {})(NotificationContents);
