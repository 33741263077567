import moment from 'moment-timezone';
import { getCalendarEntity, getLegendItems, getCategoryTypes } from '@constants/config';

const serializeFilterDates = date => date.format('YYYY-MM-DD');

export const getFilters = (currentDate, currentView) => {
  let start;
  let end;
  /* eslint-disable  */
  switch (currentView) {
  case 'day':
    end = moment(currentDate).startOf('day');
    start = moment(currentDate).endOf('day');
    break;
  case 'week':
    end = moment(currentDate).locale('en').startOf('week');
    start = moment(currentDate).locale('en').endOf('week');
    break;
  case 'month':
    end = moment(currentDate).startOf('month').subtract(7, 'days');
    start = moment(currentDate).endOf('month').add(7, 'days');
    break;
  default:
    break;
  }
  /* eslint-enable .add */

  return {
    limit: 250,
    end_date__gte: serializeFilterDates(end),
    start_date__lte: serializeFilterDates(start)
  }
};

// Calendar entity event category key:
export const getCalendarEventTypeKey = () => {
  const calendarEntityType = getCalendarEntity();
  const legendItems = getLegendItems();
  const legendItem = legendItems.filter(x => x.id === calendarEntityType)
  if (legendItem && legendItem.length > 0) {
    const categoryTypeId = legendItem[0]['category-type']
    const categoryTypes = getCategoryTypes()
    const categoryType = categoryTypes.filter(x => x.id === categoryTypeId)
    if (categoryType && categoryType.length > 0) {
      return categoryType[0]['name']
    }
  }
  return 'event-type'
}
