import React from 'react';

const Logo = () => (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" fill="#89E1A1"
      d={`
        M16.9382 4.07492C15.5962 4.58261 14.3378 5.3767 13.2573 6.45717C9.18095 10.5335 9.18095 17.1426
        13.2573 21.2189L16.9382 24.8998L13.2382 28.5998L5.85731 21.219C1.78096 17.1426 1.78096 10.5335 5.85731
        6.45717C8.8532 3.46128 13.2171 2.6672 16.9382 4.07492Z
      `} />
    <path fillRule="evenodd" clipRule="evenodd" fill="#537CE6"
      d={`
        M11.4462 6.45717C15.5077 2.38081 22.0926 2.38081 26.1541 6.45717C30.2155 10.5335 30.2155 17.1426
        26.1541 21.219L18.8001 28.5998L11.4462 21.219C7.38478 17.1426 7.38478 10.5335 11.4462 6.45717ZM17.9965
        9.44634C19.3854 9.1916 20.8718 9.60315 21.9457 10.681C23.6829 12.4246 23.6829 15.2515 21.9457
        16.9951L18.8001 20.1522L18.8001 20.1521L18.8 20.1522L15.6545 16.9951C13.9173 15.2515 13.9173 12.4246
        15.6545 10.681C16.3179 10.0152 17.1386 9.60367 17.9965 9.44634Z
      `} />
  </svg>
);

export default Logo;
