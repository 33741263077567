import React from 'react';
import PropTypes from 'prop-types';
import { fonts } from '@constants/fonts';
import { FormControlLabel as MUIFormControlLabel, Radio } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  label: {
    fontSize: fonts.md
  }
});

const RadioButton = props => {
  const classes = useStyles();
  const { disabled = false, ...data } = props;

  return (
    <MUIFormControlLabel
      classes={{ label: classes.label }}
      disabled={disabled}
      control={<Radio color="primary" size="small" />}
      {...data}
    />
  );
};

RadioButton.propTypes = {
  disabled: PropTypes.bool,
  label: PropTypes.string,
  value: PropTypes.string
};

export default RadioButton;
