/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { OverlayView } from '@react-google-maps/api';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import { openAreaTray } from '@actions/map-actions';
import { dotmapsClusterBlue, overlayTextColor } from '@constants/colors';
import {
  getMapModesSet,
  getTrayTop,
  getTrayHover,
  getTrayEntitiesIds,
  getTrayLayersIds,
  isAnyTrayItemHovered
} from '@selectors/map-selector';
import { getHighestOverlapLevel } from '@utils/entity-utils';
import {
  isAnyTrayItemInCluster,
  isHoveredTrayEntityInCluster,
  isTrayEntityInCluster
} from '@utils/markers-utils';
import { shallowEqual } from '@utils/react-utils';
import './overlap-count-marker.scss';

class OverlapCountMarker extends Component {
  shouldComponentUpdate(nextProps) {
    if (!shallowEqual(nextProps.center, this.props.center) ||
        nextProps.modes !== this.props.modes ||
        Object.keys(nextProps.items).length !== Object.keys(this.props.items).length) {
      return true;
    }
    // Check if highlight status changed:
    if (!this.props.isProximity) {  // Only exact clusters can be highlighted
      // Check if any of the properties used to calculate the highlighting changed:
      if (!shallowEqual(nextProps.isAnyHovered, this.props.isAnyHovered) ||
          !shallowEqual(nextProps.traySingleEntries, this.props.traySingleEntries) ||
          !shallowEqual(nextProps.trayEntitiesIds, this.props.trayEntitiesIds) ||
          !shallowEqual(nextProps.trayLayersIds, this.props.trayLayersIds) ||
          !shallowEqual(nextProps.trayOverlapHoverEntries, this.props.trayOverlapHoverEntries)) {
        const nextIsHighlighted = this.shouldHighlight(nextProps) || false;
        const thisIsHighlighted = this.shouldHighlight(this.props) || false;
        // And finally check if the calculated highlight value changed:
        if (!shallowEqual(nextIsHighlighted, thisIsHighlighted)) {
          return true;
        }
      }
    }
    // Check to see if entities or layers changed:
    let itemsChanged = false;
    Object.keys(this.props.items).forEach(key => {
      if (!shallowEqual(nextProps.items[key], this.props.items[key])) {
        itemsChanged = true;
      }
    });
    return itemsChanged;
  }
  shouldHighlight = props => {
    const {
      items,
      isAnyHovered,
      traySingleEntries,
      trayEntitiesIds,
      trayLayersIds,
      trayOverlapHoverEntries
    } = props;
    // Highlight the marker if:

    // There's a single entity in the tray, and it matches
    // one of the cluster entries:
    if (isTrayEntityInCluster(traySingleEntries, items)) {
      return true;
    }
    // Or if there are many entities in the tray list and
    // we are hovering one contained in the cluster:
    if (isHoveredTrayEntityInCluster(trayOverlapHoverEntries, items)) {
      return true;
    }
    // Or if we are not hovering any items on the tray,
    // and one of the elements in the tray is contained in the cluster:
    if (!isAnyHovered && isAnyTrayItemInCluster(trayEntitiesIds, trayLayersIds, items)) {
      return true;
    }
    return false;
  };
  isHighlighted = () => {
    const { isProximity } = this.props;
    return !isProximity &&  // Proximity clusters can't be highlighted:
           this.shouldHighlight(this.props);
  };
  markerClick = event => {
    const { center, isProximity, zoom } = this.props;
    // If the cluster contains items on the same location or
    // we are at the max zoom level, show the items on the tray:
    if (!isProximity || zoom >= 22) {
      this.props.openAreaTray(center);
    } else {
      // If it's a proximity cluster, zoom in.
      //
      // I had to use this hack to access the Google Map's instance
      // and call the zoom method directly.
      // The proper way of doing this is by calling a Redux "Zoom"
      // action that will set the current zoom plus one on the store,
      // and then we render the map again.
      //
      // But since executing all that takes time, the user doesn't
      // have a good experience. This method (although a hack) is
      // as fast as when we click the "+" zoom icon on the map.
      window.mapInstance.setZoom(zoom + 1);
      // Also center the map to the marker center, when we zoom:
      window.mapInstance.setCenter(center);
    }
    event.stopPropagation();
    event.preventDefault();
  };
  getStyle() {
    const { modes, items } = this.props;
    if (modes.size === 0 || isEmpty(items)) {
      return 'default';
    }
    // Flatten all entities into a single array:
    const entities = [].concat(...Object.values(items).map(item => Object.values(item)));
    return getHighestOverlapLevel(entities, modes) || 'default';
  }
  getTransform = highLighted => {
    if (highLighted) {
      return 'translateX(-13px) translateY(-33px)';
    }
    return 'translateX(-13px) translateY(-13px)';
  };
  getOuterClusterStyle = highLighted => {
    const { markerStyle = {}} = this.props;
    if (highLighted) {
      // Add water drop style if highlighted:
      return {
        ...markerStyle,
        borderRadius: '50% 50% 50% 0'
      };
    }
    return markerStyle;
  };
  // The inner div only needs the font and color set:
  getInnerClusterStyle = (highLighted, style) => {
    const { markerStyle = {}} = this.props;
    const padDiameter = diameter => {
      const newDiameter = parseFloat(diameter?.replace(/rem$/g, ''));
      return `${newDiameter - 0.25}rem`;
    };
    const innerStyle = {
      fontSize: markerStyle.fontSize,
      color: style === 'default' ? markerStyle.color : overlayTextColor,
      height: padDiameter(markerStyle.height),
      width: padDiameter(markerStyle.width)
    };
    if (highLighted) {
      return {
        ...innerStyle,
        background: style === 'default' ? dotmapsClusterBlue : '',
        color: overlayTextColor
      };
    }
    return innerStyle;
  };
  render() {
    const { center, count, isProximity } = this.props;
    const style = isProximity ? '' : this.getStyle();
    const highLighted = this.isHighlighted();
    return (
      <OverlayView
        data-testid="overlap-count-marker"
        mapPaneName={OverlayView.FLOAT_PANE}
        position={center}
        zIndex={150}
      >
        <div
          style={{
            zIndex: 150,
            position: 'absolute',
            msTransform: this.getTransform(highLighted),
            transform: this.getTransform(highLighted)
          }}
        >
          <div onClick={this.markerClick}
            styleName={`marker-overlap ${isProximity ? '' : 'exact-cluster'}`}
            style={this.getOuterClusterStyle(highLighted)}
            role="presentation">
            <div styleName={`marker-overlap-inner ${style} ${isProximity ? '' : 'exact-cluster'}`}
              style={this.getInnerClusterStyle(highLighted, style)}>
              {count}
            </div>
          </div>
          {/* Render it two times for exact type cluster, to show a shadow effect */}
          {!isProximity && (
            <div styleName="marker-overlap exact-cluster marker-overlap-stacked"
              style={this.getOuterClusterStyle(highLighted)}
              role="presentation">
              <div styleName={`marker-overlap-inner ${style} exact-cluster`}
                style={this.getInnerClusterStyle(highLighted, '')}>{ /* Style doesn't matter here,
                                                                         let's care only about inner
                                                                         circle diameter. */ }
                {count}
              </div>
            </div>
          )}
        </div>
      </OverlayView>
    );
  }
}

OverlapCountMarker.propTypes = {
  center: PropTypes.object.isRequired,
  count: PropTypes.number.isRequired,
  isAnyHovered: PropTypes.bool,
  isProximity: PropTypes.bool.isRequired,
  items: PropTypes.object.isRequired,
  markerStyle: PropTypes.object.isRequired,
  modes: PropTypes.instanceOf(Set),
  openAreaTray: PropTypes.func,
  trayEntitiesIds: PropTypes.array,
  trayLayersIds: PropTypes.object,
  trayOverlapHoverEntries: PropTypes.object,
  traySingleEntries: PropTypes.object,
  zoom: PropTypes.number
};

const getEntities = entitiesContainer => {
  if (entitiesContainer) {
    const { data } = entitiesContainer;
    if (data) {
      const { entity, layer } = data;
      return { entity, layer };
    }
  }
  return null;
};

const getHighlightProps = (state, props) => {
  if (props.isProximity) {
    // If it's a proximity cluster, don't waste time
    // obtaining data for the highlight calculations:
    return {};
  }
  // For exact location clusters, retrieve ids in the tray
  // and if they are hovered, to know if we must highlight
  // this cluster:
  const trayEntitiesIds = getTrayEntitiesIds(state);
  const trayLayersIds = getTrayLayersIds(state);
  const traySingleEntries = getEntities(getTrayTop(state));
  const trayOverlapHoverEntries = getEntities(getTrayHover(state));
  return {
    isAnyHovered: isAnyTrayItemHovered(state),
    traySingleEntries,
    trayEntitiesIds,
    trayLayersIds,
    trayOverlapHoverEntries
  };
};

const mapStateToProps = (state, props) => {
  const { viewport: { zoom } } = state.map;
  return {
    ...getHighlightProps(state, props),
    modes: getMapModesSet(state),
    zoom
  };
};

export default connect(mapStateToProps, { openAreaTray })(OverlapCountMarker);
