/* eslint-disable jsx-a11y/mouse-events-have-key-events */
import React, { Fragment, memo } from 'react';
import PropTypes from 'prop-types';
import { FormattedDate, FormattedTime } from 'react-intl';
import moment from 'moment-timezone';
import Tooltip from '@material-ui/core/Tooltip';
import { enDash } from '@utils/shared-utils';

const DefaultTooltipDate = ({ value }) => {
  const parsed = moment.isMoment(value) ? value : moment(value);
  if (parsed.isValid()) {
    const date = <FormattedDate day="numeric" month="numeric" year="numeric" format="default" value={parsed} />;
    const time = <FormattedTime hour="numeric" minute="numeric" value={parsed} />;
    return (
      <div role="presentation" style={{display: 'flex'}} >
        <Tooltip title={<Fragment>{date}&nbsp;{time}</Fragment>}>
          <span>{date}</span>
        </Tooltip>
      </div>
    );
  }
  return enDash;
};

DefaultTooltipDate.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
};

export default memo(DefaultTooltipDate);
