import React, { memo, useCallback } from 'react';
import PropTypes from 'prop-types';
import MenuItem from '@material-ui/core/MenuItem';
import SelectField from '@shared/select-field';
import './fields.scss';

// This is like the SelectField component in this directory, but it only
// renders the specified values instead of getting them from the 'dataTypes'
// in the Redux store.
const CustomSelectField = ({ disabled, label, onChange, options, value }) => {
  const renderValue = useCallback(val => options.find(option => option.id === val)?.name, [options]);

  return (
    <div styleName="field-container">
      <div styleName="field-label">
        {label}
      </div>
      <div styleName="field-input">
        <SelectField
          disabled={disabled}
          fullWidth
          onChange={onChange}
          renderValue={renderValue}
          selectStyles={{
            root: {
              marginLeft: '0',
              paddingLeft: '0'
            }
          }}
          value={value}
        >
          {options.map(option => (
            <MenuItem key={option.id} value={option.id}>
              <div styleName="field-option">
                <div styleName="field-option-name">{option.name}</div>
                {option.hint && <div styleName="field-option-hint">{option.hint}</div>}
              </div>
            </MenuItem>
          ))}
        </SelectField>
      </div>
    </div>
  );
};

CustomSelectField.propTypes = {
  disabled: PropTypes.bool,
  label: PropTypes.string,
  onChange: PropTypes.func,
  options: PropTypes.array,
  value: PropTypes.object
};

export default memo(CustomSelectField);
