import React from 'react';
import PropTypes from 'prop-types';

const FixedSeparator = ({ style }) => <div style={style} />;

FixedSeparator.propTypes = {
  style: PropTypes.object
};

export default FixedSeparator;
