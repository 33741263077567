/* global google */
/* eslint-disable react/display-name */
/* eslint-disable react/prop-types */
import React from 'react';

const wrapSymbolPath = WrappedComponent => {
  return props => {
    if (props.options && props.options.icon && props.options.icon.googleSymbolPath) {
      // Substitute googleSymbolPath string name, with the google.maps.SymbolPath enum it references
      const { googleSymbolPath, ...iconProps } = props.options.icon;
      const path = google.maps.SymbolPath[googleSymbolPath];
      return <WrappedComponent {...props} options={{ ...props.options, icon: { ...iconProps, path } }} />;
    }
    return <WrappedComponent {...props} />;
  };
};

export default wrapSymbolPath;
