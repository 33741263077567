// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.src-components-shared-list-card-___city-layer-card__container.src-components-shared-list-card-___city-layer-card__container{padding-inline:1rem;padding-block:0.75rem;border:1px solid #EBEBEB;background:#FFF;border-radius:0;margin-block-end:-1px}.src-components-shared-list-card-___city-layer-card__container.src-components-shared-list-card-___city-layer-card__container.src-components-shared-list-card-___city-layer-card__selected{box-shadow:3px 0px 0px 0px #406EE3 inset}
`, "",{"version":3,"sources":["webpack://./src/components/shared/list-card/city-layer-card.scss"],"names":[],"mappings":"AAAA,6HACI,mBAAoB,CACpB,qBAAsB,CACtB,wBAAyB,CACzB,eAAgB,CAChB,eAAgB,CAChB,qBAAsB,CAN1B,0LASM,wCAAyC","sourcesContent":[".container.container {\n    padding-inline: 1rem;\n    padding-block: 0.75rem;\n    border: 1px solid #EBEBEB;\n    background: #FFF;\n    border-radius: 0;\n    margin-block-end: -1px;\n\n    &.selected {\n      box-shadow: 3px 0px 0px 0px #406EE3 inset;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `src-components-shared-list-card-___city-layer-card__container`,
	"selected": `src-components-shared-list-card-___city-layer-card__selected`
};
export default ___CSS_LOADER_EXPORT___;
