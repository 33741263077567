/* eslint-disable react/jsx-no-bind */
import React from 'react';
import PropTypes from 'prop-types';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import { withStyles } from '@material-ui/core/styles';
import { getGroupTypesConfig } from '@constants/config';
import { autocompleteSearchStyles } from '@constants/mui-theme';
import GroupTypeIcon from '@icons/group-type-icon';
import SelectField from '@shared/select-field';
import InputSearch from '@shared/input-search';
import {
  findGroupType,
  getGroupAutocompletePlaceholder,
  renderGroupListItem
} from '@utils/group-utils';
import { canEditGroups } from '@utils/permission-utils';
import './add-to-group.scss';

const CustomInputLabel = withStyles({
  root: {
    marginLeft: '0.5rem'
  }
})(InputLabel);

const getGroupTypeItems = () => getGroupTypesConfig().map(groupType => {
  if (canEditGroups(groupType.name)) {
    return (
      <MenuItem key={groupType.id} value={groupType.id}>
        <div styleName="menu-item-icon">
          <GroupTypeIcon type={groupType.name} size="1.5rem" />
        </div>
        <div styleName="menu-item-label">
          {groupType.label} group
        </div>
      </MenuItem>
    );
  }
  return null;
});

const AddToGroup = ({
  existingGroups,
  groupType,
  groupsById,
  selectedGroups,
  setGroupType,
  setSelectedGroups,
  showSearch = true
}) => {
  const handleGroupTypeChange = event => setGroupType(event.target.value);

  const selectedGroupType = findGroupType(groupType);

  return (
    <FormControl style={{ width: '100%'}}>
      <CustomInputLabel id="group-type-label">Group type</CustomInputLabel>
      <SelectField
        labelId="group-type-label"
        id="group-type-select"
        onChange={handleGroupTypeChange}
        renderValue={value => `${findGroupType(value).label} group`}
        value={groupType}
      >
        {getGroupTypeItems()}
      </SelectField>
      {showSearch && (
        <InputSearch
          {...autocompleteSearchStyles.normal}
          fieldName="group_ids"
          filterFunc={item => item.type === selectedGroupType.id && item.status_name === 'Open'}
          label={getGroupAutocompletePlaceholder(selectedGroups, selectedGroupType)}
          onChange={(event, newValues) => {
            const values = newValues.map(value => value.value);
            setSelectedGroups(values);
          }}
          renderOption={(option, state) => { // eslint-disable-line no-unused-vars
            const group = groupsById[option.value];
            return renderGroupListItem(group, existingGroups);
          }}
          storePath={['groups', 'list']}
          values={selectedGroups}
        />
      )}
    </FormControl>
  );
};

AddToGroup.propTypes = {
  existingGroups: PropTypes.array,
  groupType: PropTypes.number,
  groupsById: PropTypes.object,
  selectedGroups: PropTypes.array,
  setGroupType: PropTypes.func,
  setSelectedGroups: PropTypes.func,
  showSearch: PropTypes.bool
};

export default AddToGroup;
