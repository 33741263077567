/* eslint-disable react/jsx-boolean-value */
import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import ControlLabel from './control-label';
import './form.scss';

const ToggleControl = ({ disabled, onChange, value }) => {
  // The value is the 'true' string, thus convert to boolean with ===.
  const handleChange = useCallback(event => onChange(event.target.value === 'true'), [onChange]);

  return (
    <div styleName="control-container toggle-control">
      <FormControl component="fieldset">
        <RadioGroup
          row
          aria-label="boolean"
          name="radio-buttons-group"
          value={value}
          onChange={handleChange}
        >
          <FormControlLabel
            control={<Radio color="primary" disabled={disabled} size="small" />}
            label={<ControlLabel disabled={disabled} label="Yes" />}
            value={true}
          />
          <FormControlLabel
            control={<Radio color="primary" disabled={disabled} size="small" />}
            label={<ControlLabel disabled={disabled} label="No" />}
            value={false}
          />
        </RadioGroup>
      </FormControl>
    </div>
  );
};

ToggleControl.propTypes = {
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  value: PropTypes.bool
};

export default ToggleControl;
