import React, { Fragment, memo, useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import DataCard from '@shared/ui-library/card/data-card';
import AttributeLabelContainer from '@shared/ui-library/attribute/attribute-label-container';
import AttributeLabel from '@shared/ui-library/attribute/attribute-label';
import GroupTypeIcon from '@icons/group-type-icon';

import { getConfig } from '@components/config/selectors';
import { getHoverGroupId } from '@selectors/map-selector';
import { setOverlapHoverGroup, openGroupsTray } from '@actions/map-actions';

// Group Card to display as a main card (i.e. one that is in the tray, at the top
// with list of entities below).
const MainGroupCard = ({ group, scrolling }) => {
  const dispatch = useDispatch();
  const { name, description, external_id: externalId, type, status_name: statusName, entities } = group;
  const { group_types: groupTypes } = useSelector(getConfig);
  const selectedGroupId = useSelector(state => getHoverGroupId(state));
  const selected = group.id === selectedGroupId;

  const icon = useMemo(() => {
    const typeConfig = groupTypes.find(config => config.id === type);
    if (typeConfig) {
      return (
        <GroupTypeIcon type={typeConfig.name} />
      );
    }
    return (
      <GroupTypeIcon type="group"/>
    );
  }, [type, groupTypes]);

  const groupTypeLabel = useMemo(() => {
    const typeConfig = groupTypes.find(config => config.id === type);
    if (typeConfig) {
      return typeConfig.label;
    }
    return null;
  }, [type, groupTypes]);

  const selectGroup = useCallback(() => dispatch(setOverlapHoverGroup(group)), [dispatch, group]);
  const deselectGroup = useCallback(() => dispatch(setOverlapHoverGroup()), [dispatch]);
  const handleClick = useMemo(() => {
    if (selected) {
      return deselectGroup;
    }
    return selectGroup;
  }, [selected, selectGroup, deselectGroup]);

  const openGroup = useCallback(
    () => {
      const groupProps = {
        id: group.id,
        item: group
      };
      dispatch(openGroupsTray(groupProps));
    },
    [dispatch, group]
  );

  return (
    <DataCard
      compact={false}
      embedded
      selected={selected}
      headerIcon={scrolling ? null : icon}
      headerText={scrolling ? null : `${groupTypeLabel} Group`}
      onSelect={handleClick}
      onViewDetails={openGroup}
      title={<span style={{ fontSize: '1.125rem' }}>{name}</span>}
    >
      {!scrolling &&
        <Fragment>
          <AttributeLabelContainer>
            <AttributeLabel text={`${entities.length} Items`} />
            {externalId && <AttributeLabel text={externalId} />}
            <AttributeLabel text={statusName} />
          </AttributeLabelContainer>
          <AttributeLabelContainer>
            {description}
          </AttributeLabelContainer>
        </Fragment>
      }
    </DataCard>
  );
};

MainGroupCard.propTypes = {
  group: PropTypes.object,
  scrolling: PropTypes.bool
};

export default memo(MainGroupCard);
