import React, { memo, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import SelectFilterChip from '../chips/select';

import { useDataTypeFilterHandleChange } from '../hooks';
import { getAgencyIcon } from '@utils/icon-utils';

const AgencyTypeFilter = ({filter, value, dataType, subType, filterKey}) => {
  const handleChange = useDataTypeFilterHandleChange(dataType, subType, filterKey);
  const agencyTypes = useSelector(state => state.dataTypes.agency_type);
  const options = useMemo(() => {
    return Object.values(agencyTypes).map(type => (
      {
        label: type.name,
        value: type.id,
        icon: getAgencyIcon(type)
      }
    ));
  }, [agencyTypes]);

  return (
    <SelectFilterChip
      label={filter.label || 'Agency Type'}
      options={options}
      value={value}
      onChange={handleChange}
      multiSelect
      search
    />
  );
};

AgencyTypeFilter.propTypes = {
  dataType: PropTypes.string.isRequired,
  filter: PropTypes.object.isRequired,
  filterKey: PropTypes.string.isRequired,
  subType: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.array, PropTypes.number])
};

export default memo(AgencyTypeFilter);
