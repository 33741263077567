import React, { memo, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { closeDashboardDialog } from '@actions/dashboard-actions';
import { updateBaseline } from '@actions/groups-actions';
import * as dialog from '@constants/dialogs';
import Dialog from '@shared/dialogs/dialog';
import DialogActions from '@shared/dialogs/dialog-actions';

const UpdateBaseline = () => {
  const dispatch = useDispatch();
  const { activeDialogs, dialogParams } = useSelector(state => state.dashboard);
  const isDialogActive = activeDialogs[dialog.UPDATE_BASELINE];

  const onClose = useCallback(
    () => {
      dispatch(closeDashboardDialog(dialog.UPDATE_BASELINE));
    },
    [dispatch]
  );

  const onSet = useCallback(
    () => {
      dispatch(updateBaseline({ ...dialogParams[dialog.UPDATE_BASELINE] }));
      dispatch(closeDashboardDialog(dialog.UPDATE_BASELINE));
    },
    [dialogParams, dispatch]
  );

  if (!isDialogActive) {
    return null;
  }

  return (
    <Dialog
      actions={
        <DialogActions
          onCancel={onClose}
          onSubmit={onSet}
          submitLabel="UPDATE"
        />
      }
      title="Update baseline"
    >
      Are you sure you want to update the baseline to the current state?
      It will reset the day saving calculation.
    </Dialog>
  );
};

export default memo(UpdateBaseline);
