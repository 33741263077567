import React, { useCallback } from 'react';
import { push } from 'connected-react-router';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import { saveAndReload } from '@actions/data-detail-actions';
import { getEntityTypeLabel } from '@constants/config';
import { getMetadata } from '@selectors/forms-selector';
import DialogActions from '@shared/dialogs/dialog-actions';
import { isErrorsEmpty } from '@utils/form-utils';
import { getDashboardBackURL } from '@utils/navigation-utils';
import {
  showEntityScheduleContainsWarning
} from '@utils/segment-schedule/common';

const TopBarActionCreate = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const { dataType } = useParams();
  const { data, error, options, saving } = useSelector(state => state.dataDetail);
  const metadata = useSelector(state => getMetadata(dataType)(state));

  const dataTypeDisplayName = getEntityTypeLabel(dataType);

  const hasWarnings = data && showEntityScheduleContainsWarning(
    data.start_date, data.end_date, data.segments
  );

  const onCancel = useCallback(
    () => {
      const source = getDashboardBackURL(location, dataType, null);
      dispatch(push(source));
    },
    [dataType, dispatch, location]
  );

  // Returns true if the save button must be disabled.
  //
  // We must disable the button while we are saving the data
  // or if there are errors in the form.
  const isDisabled = saving || !isErrorsEmpty(data, error) || hasWarnings;

  const saveDetails = useCallback(
    () => {
      dispatch(saveAndReload(dataType, dataTypeDisplayName, data, metadata, options));
    },
    [dataType, dataTypeDisplayName, data, dispatch, metadata, options]
  );

  return (
    <DialogActions
      disabledSubmit={isDisabled}
      onCancel={onCancel}
      onSubmit={saveDetails}
      submitLabel="SAVE"
    />
  );
};

export default TopBarActionCreate;
