//
// A generic dialog to enter a name and a description.
//
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { push } from 'connected-react-router';
import { isEmpty } from 'lodash';
import { TextField } from 'material-ui';
import { closeDashboardDialog } from '@actions/dashboard-actions';
import { fetchDataDetail } from '@actions/data-detail-actions';
import { saveDataType } from '@actions/data-types-actions';
import { pushApplicationMessage } from '@actions/messages-actions';
import * as dialog from '@constants/dialogs';
import { styleGuide } from '@constants/mui-theme';
import Dialog from '@shared/dialogs/dialog';
import DialogActions from '@shared/dialogs/dialog-actions';
import './name-description-dialog.scss';

const NameDescriptionDialog = () => {
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [errors, setErrors] = useState({});

  const dispatch = useDispatch();

  const { data } = useSelector(state => state.dataDetail);
  const { activeDialogs, dialogParams } = useSelector(state => state.dashboard);

  const { id, portal, dataType: type } = dialogParams[dialog.NAME_DESCRIPTION] || {};
  const isDialogActive = activeDialogs[dialog.NAME_DESCRIPTION];
  const saveEnabled = !isEmpty(name) && isEmpty(errors);
  const title = (type || '').replace(/_/g, ' ');

  useEffect(() => {
    if (data && id) {
      setName(data.name);
      setDescription(data.description);
    } else {
      setName('');
      setDescription('');
    }
  }, [data, id, setDescription, setName]);

  const onClose = useCallback(
    () => {
      setName('');
      setDescription('');
      setErrors({});
      dispatch(closeDashboardDialog(dialog.NAME_DESCRIPTION));
    },
    [dispatch, setDescription, setErrors, setName]
  );

  const saveError = useCallback(
    error => {
      const { response: { data: dataError } } = error;
      setErrors({ ...dataError });
    },
    [setErrors]
  );

  const redirect = useCallback(
    ({ id: typeId, name: typeName }) => {
      // Reload if we are updating:
      if (id) {
        dispatch(fetchDataDetail(type, id));
      }
      // If the id changed (due to versioning) redirect.
      if (String(id) !== String(typeId)) {
        dispatch(push({
          pathname: `/${portal}/${type}/${typeId}`,
          state: { clear: true }
        }));
      }
      const label = id ? 'Updated' : 'Created';
      dispatch(pushApplicationMessage(`${label} ${title} "${typeName}".`));
      onClose();
    },
    [onClose, dispatch, id, portal, title, type]
  );

  const onSave = useCallback(
    () => {
      const payload = { name, description };
      dispatch(saveDataType(type, id, payload, redirect, saveError));
    },
    [description, dispatch, id, name, redirect, saveError, type]
  );

  const onNameChange = useCallback(
    (event, value) => {
      setName(value);
      setErrors({});
    },
    [setErrors, setName]
  );

  const onDescriptionChange = useCallback(
    (event, value) => {
      setDescription(value);
      setErrors({});
    },
    [setErrors, setDescription]
  );

  if (!isDialogActive) {
    return null;
  }

  const getTitle = () => {
    if (id) {
      return `Edit ${title} details`;
    }
    return `Create ${title}`;
  };

  return (
    <Dialog
      actions={
        <DialogActions
          onCancel={onClose}
          disabledSubmit={!saveEnabled}
          onSubmit={onSave}
          submitLabel="SAVE"
        />
      }
      title={getTitle()}
    >
      <TextField
        {...styleGuide.textField}
        floatingLabelText="Name*"
        fullWidth
        onChange={onNameChange}
        value={name}
      />
      {errors.name && <div styleName="error-field">{errors.name}</div>}
      <TextField
        {...styleGuide.textField}
        floatingLabelText="Description"
        fullWidth
        onChange={onDescriptionChange}
        value={description}
      />
    </Dialog>
  );
};

export default NameDescriptionDialog;
