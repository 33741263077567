import React, { memo } from 'react';
import PropTypes from 'prop-types';

import TextFilterChip from '../chips/text';

import { useDataTypeFilterHandleChange } from '../hooks';

const SearchFilter = ({filter, value, dataType, subType, filterKey}) => {
  const handleChange = useDataTypeFilterHandleChange(dataType, subType, filterKey);

  return (
    <TextFilterChip
      dataType={dataType}
      label={filter.label || 'Search'}
      value={value}
      onChange={handleChange}
      subType={subType}
    />
  );
};

SearchFilter.propTypes = {
  dataType: PropTypes.string.isRequired,
  filter: PropTypes.object.isRequired,
  filterKey: PropTypes.string.isRequired,
  subType: PropTypes.string,
  value: PropTypes.string
};

export default memo(SearchFilter);
