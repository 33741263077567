/* eslint-disable react/jsx-no-bind */
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { closeDashboardDialog } from '@actions/dashboard-actions';
import { addWorkflowTaskType } from '@actions/workflow-actions';
import * as dialog from '@constants/dialogs';
import { autocompleteSearchStyles
} from '@constants/mui-theme';
import Dialog from '@shared/dialogs/dialog';
import DialogActions from '@shared/dialogs/dialog-actions';
import InputSearch from '@shared/input-search';
import {
  getCorrelationIds,
  getLatestPublishedTaskTypes,
  getTaskTypesNotInWorkflow
} from '@utils/workflow-utils';

const AddWorkflowTaskTypeDialog = () => {
  const [items, setItems] = useState([]);

  const dispatch = useDispatch();

  const { activeDialogs, dialogParams } = useSelector(state => state.dashboard);
  const { task_type: task_types } = useSelector(state => state.dataTypes);
  const correlationIds = useMemo(() => getCorrelationIds(items, task_types), [items, task_types]);

  const { id, workflow = { task_types: [] } } = dialogParams[dialog.ADD_WORKFLOW_TASK_TYPE] || {};
  const isDialogActive = activeDialogs[dialog.ADD_WORKFLOW_TASK_TYPE];
  const taskTypes = getLatestPublishedTaskTypes(getTaskTypesNotInWorkflow(task_types, workflow));

  useEffect(() => {
    if (!isDialogActive) {
      setItems([]);
    }
  }, [isDialogActive, setItems]);

  const onClose = useCallback(
    () => {
      dispatch(closeDashboardDialog(dialog.ADD_WORKFLOW_TASK_TYPE));
    },
    [dispatch]
  );

  const onAdd = useCallback(
    () => dispatch(addWorkflowTaskType(id, correlationIds)),
    [correlationIds, dispatch, id]
  );

  const handleTaskTypeChange = (event, values) => setItems(values.map(value => value.value));

  if (!isDialogActive) {
    return null;
  }

  return (
    <Dialog
      actions={
        <DialogActions
          onCancel={onClose}
          onSubmit={onAdd}
          submitLabel="ADD"
        />
      }
      title="Add task templates"
    >
      <InputSearch
        {...autocompleteSearchStyles.normal}
        fieldName="published_task_types"
        label="Search published task templates"
        onChange={handleTaskTypeChange}
        options={taskTypes}
        values={items}
      />
    </Dialog>
  );
};

export default AddWorkflowTaskTypeDialog;
