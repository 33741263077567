// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root{--fs-xxxl: 1.5rem;--fs-xxhl: 1.375rem;--fs-xxl: 1.25rem;--fs-xl: 1.125rem;--fs-lg: 1rem;--fs-md: 0.875rem;--fs-sm: 0.75rem;--fs-xs: 0.625rem;--fs-xxs: 0.5625rem;--lh-xxxl: 1.75rem;--lh-xxl: 1.5rem;--lh-xl: 1.375rem;--lh-lg: 1.25rem;--lh-md: 1.125rem;--lh-sm: 1rem;--lh-xs: 0.875rem}.src-components-overlap-___drawer-list__no-overlaps{font-size:var(--fs-md);padding:1em}
`, "",{"version":3,"sources":["webpack://./src/styles/_fonts.scss","webpack://./src/components/overlap/drawer-list.scss"],"names":[],"mappings":"AAAA,MAEE,iBAAU,CACV,mBAAU,CACV,iBAAS,CACT,iBAAQ,CACR,aAAQ,CACR,iBAAQ,CACR,gBAAQ,CACR,iBAAQ,CACR,mBAAS,CAGT,kBAAU,CACV,gBAAS,CACT,iBAAQ,CACR,gBAAQ,CACR,iBAAQ,CACR,aAAQ,CACR,iBAAQ,CCjBV,oDACE,sBAAuB,CACvB,WAAY","sourcesContent":[":root {\n  /* Font Size */\n  --fs-xxxl: 1.5rem; /* 24px */\n  --fs-xxhl: 1.375rem; /* 22px */\n  --fs-xxl: 1.25rem; /* 20px */\n  --fs-xl: 1.125rem; /* 18px */\n  --fs-lg: 1rem; /* 16px */\n  --fs-md: 0.875rem; /* 14px */\n  --fs-sm: 0.75rem; /* 12px */\n  --fs-xs: 0.625rem; /* 10px */\n  --fs-xxs: 0.5625rem; /* 9px */\n\n  /* LineHeight */\n  --lh-xxxl: 1.75rem; /* 28px */\n  --lh-xxl: 1.5rem; /* 24px */\n  --lh-xl: 1.375rem; /* 22px */\n  --lh-lg: 1.25rem; /* 20px */\n  --lh-md: 1.125rem; /* 18px */\n  --lh-sm: 1rem; /* 16px */\n  --lh-xs: 0.875rem; /* 14px */\n}\n","@import '../../styles/fonts';\n\n.no-overlaps {\n  font-size: var(--fs-md);\n  padding: 1em;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"no-overlaps": `src-components-overlap-___drawer-list__no-overlaps`,
	"noOverlaps": `src-components-overlap-___drawer-list__no-overlaps`
};
export default ___CSS_LOADER_EXPORT___;
