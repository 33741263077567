import React, { memo, useMemo } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import EntityTypeIcon from '@icons/entity-type-icon';
import DmListItem from '../ui-library/list/dm-list-item';

import { getEntityListDetailConfig } from '@selectors/entities-selector';

const EntityListItem = ({
  border = false,
  className = null,
  draggableProps = null,
  onClick = null,
  entity = {},
  ...passThroughProps
}) => {
  const listItemConfig = useSelector(state => getEntityListDetailConfig(state, entity?.type_name));

  const detailText = useMemo(() => {
    const detailItems = listItemConfig.map(item => {
      const {type, field = null, label = null, autoHide = true} = item;
      switch (type) {
      case 'category': {
        const categoryData = entity?.category_dict?.find?.(category => category.name === field);
        if (!categoryData) {
          if (autoHide) {
            return null;
          }
          if (label) {
            return `${label}\u00A0`;
          }
          return '';
        }
        if (label) {
          return `${label}\u00A0${categoryData.value}`;
        }
        return categoryData.value;
      }
      case 'agency': {
        const agencyName = entity?.agency_name;
        if (autoHide && !agencyName) {
          return null;
        }
        if (label) {
          return `${label}\u00A0${agencyName}`;
        }
        return agencyName;
      }
      case 'field':
      default : {
        const value = entity?.[field] || entity?.extra?.[field];
        if (autoHide && !value) {
          return null;
        }
        if (label) {
          return `${label}\u00A0${value}`;
        }
        return value;
      }
      }
    }).filter(value => value !== null);

    return detailItems.join(' \u2022\u00A0');
  }, [entity, listItemConfig]);

  return (
    <DmListItem
      beforeList={<EntityTypeIcon type={entity?.type_name} size="1.125rem" />}
      border={border}
      className={className}
      draggableProps={draggableProps}
      onClick={onClick}
      secondaryText={detailText}
      {...passThroughProps}
    >
      {entity?.name}
    </DmListItem>
  );
};

EntityListItem.propTypes = {
  border: PropTypes.bool,
  className: PropTypes.string,
  draggableProps: PropTypes.object,
  entity: PropTypes.object,
  onClick: PropTypes.func
};

export default memo(EntityListItem);
