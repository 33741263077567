/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/jsx-no-bind */
import React, { useContext } from 'react';
import PropTypes from 'prop-types';

import { Icon } from '@mui';
import { colors } from '@constants/colors';

import { CARD_STATE_LOADING } from '../constants';
import DashboardContext from '../dashboard-context';
import { getCardHeight } from '../utils';

import '../dashboard-card.scss';

const CardError = ({ card }) => {
  const { updateCardState } = useContext(DashboardContext);

  const handleRefresh = () => {
    updateCardState(card, CARD_STATE_LOADING);
  };

  const style = {
    buttonLink: {
      paddingLeft: '0.25rem',
      color: colors.brand.blue,
      fontWeight: '500'
    },
    cardBody: {
      height: getCardHeight(card)
    }
  };

  return (
    <div style={style.cardBody} styleName="card-body-empty">
      <div>
        <Icon style={{ fontSize: '2rem' }} color={colors.neutral.ash}>
          warning
        </Icon>
      </div>
      <div>
        Cannot Load Data.
        <a style={style.buttonLink} onClick={handleRefresh}>
          Refresh
        </a>
      </div>
    </div>
  );
};

CardError.propTypes = {
  card: PropTypes.object
};

export default CardError;
