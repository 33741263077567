import {
  AUTH_LOGIN_ERROR,
  AUTH_UPDATE_USER,
  AUTH_LOGOUT_RESET,
  AUTH_PASSWORD_CHANGE_SUCCESS,
  AUTH_PASSWORD_CHANGE_ERROR,
  AUTH_PASSWORD_REQUEST_SUCCESS,
  AUTH_PASSWORD_REQUEST_ERROR,
  AUTH_SET_AGENCY_SUCCESS
} from '@constants/action-types';
import { AUTH_LOGIN_ROUTE } from '@constants/auth';
import { push } from 'connected-react-router';
import axios from 'axios';
import { getServerErrorAction } from './shared-actions';
import { pushApplicationMessage } from './messages-actions';
import { getAuthRequestUrl, BASE_API_URL } from '@constants/endpoints';
import {
  clearAuth,
  getDefaultRoute,
  setAuthHeaders,
  setAuthToken,
  setAuthUsername
} from '@utils/auth-utils';
import { setPermissions, setAgencyPermission } from '@utils/permission-utils';

const authLoginError = error => ({
  type: AUTH_LOGIN_ERROR,
  payload: error
});

export const setAgencySuccess = agencyId => ({
  type: AUTH_SET_AGENCY_SUCCESS,
  agencyId
});

// Authenticate based on a token:
export const authTokenLogin = (location, defaultUrl, token, userId, timestamp) => {
  clearAuth();
  const url = getAuthRequestUrl('api-temp-token-auth');
  // eslint-disable-next-line id-length
  const request = axios.post(url, { ts: timestamp, tk: token, u: userId});
  return dispatch =>
    request.then(
      data => {
        setAuthToken(data.data.token);
        setPermissions({...data.data});
        setAuthHeaders();
        setAuthUsername(data.data.username);
        dispatch(setAgencySuccess(data.data.agency_id));
        dispatch(push(getDefaultRoute(location, defaultUrl)));
      },
      error => {
        clearAuth();
        dispatch(authLoginError(error));
        dispatch(push(getDefaultRoute(location, defaultUrl)));
      }
    );
};

export const authLogin = (user, location, defaultUrl) => {
  // Ensure auth information is clear before performing an auth action.
  clearAuth();
  const url = getAuthRequestUrl('api-token-auth');
  const request = axios.post(url, { username: user.username, password: user.password});
  return dispatch =>
    request.then(
      data => {
        setAuthToken(data.data.token);
        setPermissions({...data.data});
        setAuthHeaders();
        setAuthUsername(data.data.username);
        dispatch(setAgencySuccess(data.data.agency_id));
        dispatch(push(getDefaultRoute(location, defaultUrl)));
      },
      error => {
        clearAuth();
        dispatch(authLoginError(error));
        dispatch(push(getDefaultRoute(location, defaultUrl)));
      }
    );
};

const baseLogoutReset = () => ({ type: AUTH_LOGOUT_RESET });

export const authLogout = location => dispatch => {
  clearAuth();
  dispatch(baseLogoutReset());  // Clear the state
  if (location && location.pathname) {
    // If the location was specified, redirect there
    // so we can return to that place when we login again.
    dispatch(push(location.pathname));
  } else {
    dispatch(push(AUTH_LOGIN_ROUTE));  // redirect to login
  }
};

const authUpdateUser = (field, value) => ({
  type: AUTH_UPDATE_USER,
  payload: {[field]: value}
});

export const authSetUserField = (field, value) => dispatch => dispatch(authUpdateUser(field, value));

const savePasswordChangeRequestSuccess = data => ({
  type: AUTH_PASSWORD_REQUEST_SUCCESS,
  payload: { status: data.status }
});

const savePasswordChangeRequestError = error => ({
  type: AUTH_PASSWORD_REQUEST_ERROR,
  error: {
    status: 400,
    data: error
  }
});

export const sendPasswordChangeRequest = email => {
  const url = getAuthRequestUrl('user/password_reset_request');
  const request = axios.post(url, { email });
  return dispatch =>
    request.then(
      data => dispatch(savePasswordChangeRequestSuccess(data)),
      error => dispatch(savePasswordChangeRequestError(error))
    );
};

const savePasswordChangeSuccess = data => ({
  type: AUTH_PASSWORD_CHANGE_SUCCESS,
  payload: { status: data.status }
});

const savePasswordChangeError = error => ({
  type: AUTH_PASSWORD_CHANGE_ERROR,
  error: {
    status: 400,
    data: error
  }
});

export const sendNewPasswords = ({ userPk, token, ...requestData }) => {
  const url = getAuthRequestUrl(`user/password_reset/${userPk}/${token}`);
  const request = axios.post(url, requestData);
  return dispatch =>
    request.then(
      data => dispatch(savePasswordChangeSuccess(data)),
      error => dispatch(savePasswordChangeError(error))
    );
};

export const setAgency = agencyId => {
  const url = `${BASE_API_URL}/agency/user/`;
  const request = axios.patch(url, { agency: agencyId });
  return dispatch =>
    request.then(
      () => {
        setAgencyPermission(agencyId);
        dispatch(setAgencySuccess(agencyId));
        // Reload the page to reload config after setting the agency.
        location.reload();
      }
    );
};

export const resendOnboardingEmail = userId => {
  const url = `${BASE_API_URL}/user/${userId}/resend_onboarding/`;
  const request = axios.get(url);
  return dispatch =>
    request.then(
      () => {
        dispatch(pushApplicationMessage('The onboarding email was resent.'));
      },
      error => dispatch(getServerErrorAction('user', error))
    );
};
