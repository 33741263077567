import React, { Fragment, memo, useCallback, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import InsertDriveFileTwoToneIcon from '@material-ui/icons/InsertDriveFileTwoTone';
import DeleteIcon from '@material-ui/icons/Delete';
// Upgrade node: it's 'GetApp' in MUI v4, we can use 'Download' on v5 (both looks exactly the same).
import DownloadIcon from '@material-ui/icons/GetApp';
import EditIcon from '@material-ui/icons/Edit';
import {
  bulkDeleteAttachments,
  deleteAttachment,
  getAttachments,
  showFilePreview
} from '@actions/attachment-actions';
import FilePreview from '@shared/files/file-preview';
import { openDashboardDialog } from '@actions/dashboard-actions';
import { downloadAttachment } from '@components/attachments/actions';
import TableHeader from '@components/entity/table-header';
import * as dialog from '@constants/dialogs';
import { FILES_TAB_COLUMNS as columns } from '@constants/table';
import { useCanEditAttachments } from '@hooks/permission-hooks';
import BulkDeleteFilesDialog from '@shared/dialogs/bulk-delete-files-dialog';
import DeleteFileDialog from '@shared/dialogs/delete-file-dialog';
import EditFileDialog from '@shared/dialogs/edit-file-dialog';
import Table from '@shared/table';
import FilesUploadButton from './files-upload-button';

const empty = {
  icon: <InsertDriveFileTwoToneIcon htmlColor="#E0E0E0" style={{ fontSize: '6rem' }} />,
  message: 'No files uploaded yet',
  subText: <span>Upload files to share with other team members<br />and keep records of documents</span>
};

const Files = () => {
  const dispatch = useDispatch();
  const { dataType } = useParams();
  const { data } = useSelector(state => state.dataDetail);
  const { attachments, loading } = useSelector(state => state.attachments);
  const [checked, setChecked] = useState([]);

  const rows = useMemo(() => attachments[`entity${data?.id}`] || [], [attachments, data?.id]);

  const setAll = useCallback(
    value => setChecked(new Array(rows?.length).fill(value)),
    [rows]
  );

  // Initialize checkbox state with "unchecked":
  useEffect(() => {
    if (rows?.length > 0) {
      setAll(false);
    }
  }, [rows, setAll]);

  const isAllChecked = useMemo(() => checked.length > 0 && checked.every(isChecked => isChecked), [checked]);
  const isAnyChecked = useMemo(() => checked.some(isChecked => isChecked), [checked]);

  const onToggleAll = useCallback(() => {
    if (isAllChecked) {
      setAll(false);
    } else {
      setAll(true);
    }
  }, [isAllChecked, setAll]);

  const onToggle = useCallback(index => {
    setChecked(prevChecked => {
      const updatedChecked = [...prevChecked];
      updatedChecked[index] = !updatedChecked[index];
      return updatedChecked;
    });
  }, [setChecked]);

  // Load entity attachments:
  useEffect(() => {
    const fetchAttachments = async () => dispatch(getAttachments('entity', data?.id));
    fetchAttachments();
  }, [data?.id, dispatch]);  // eslint-disable-line react-hooks/exhaustive-deps

  const { canEdit, canUpload } = useCanEditAttachments(data?.agency, dataType);

  // eslint-disable-next-line no-unused-vars
  const onRowClick = useCallback(
    (event, row, index) => dispatch(showFilePreview('entity', data?.id, index)),
    [data?.id, dispatch]
  );

  const editFile = useCallback(row => dispatch(openDashboardDialog(dialog.EDIT_FILE, { data: row })), [dispatch]);

  const bulkRemoveFiles = useCallback(() => {
    const selectedIds = rows.filter((row, index) => checked[index]).map(row => row.id);
    const params = {
      ids: selectedIds,
      bulkDeleteFiles: (...args) => dispatch(bulkDeleteAttachments(...args))
    };
    dispatch(openDashboardDialog(dialog.BULK_DELETE_FILES, params));
  }, [checked, dispatch, rows]);

  const removeFile = useCallback(row => {
    const params = {
      filename: row.file_name,
      index: row.id,
      deleteFile: (...args) => dispatch(deleteAttachment(...args))
    };
    dispatch(openDashboardDialog(dialog.DELETE_FILE, params));
  }, [dispatch]);

  const headerActions = useMemo(() => [
    { icon: DeleteIcon, label: 'Bulk Delete', onClick: bulkRemoveFiles }
  ], [bulkRemoveFiles]);

  const actions = useMemo(() => {
    const actionList = [
      { icon: DownloadIcon, label: 'Download', onClick: downloadAttachment },
      { icon: EditIcon, label: 'Edit', onClick: editFile }
    ];

    if (canEdit) {
      actionList.push({ icon: DeleteIcon, label: 'Delete', onClick: removeFile });
    }

    return actionList;
  }, [canEdit, editFile, removeFile]);

  const header = useMemo(() => (
    <TableHeader
      actions={headerActions}
      button={(
        <Fragment>
          {canUpload && <FilesUploadButton objectId={data?.id} />}
        </Fragment>
      )}
      checked={checked}
      count={rows.length}
      label="file"
    />
  ), [canUpload, checked, data?.id, headerActions, rows]);

  if (!data) {
    return null;
  }

  return (
    <Fragment>
      <Table
        actions={actions}
        checked={checked}
        checkedProps={{ isAllChecked, isAnyChecked }}
        columns={columns}
        empty={empty}
        header={header}
        loading={loading}
        onRowClick={onRowClick}
        onToggleAll={onToggleAll}
        onToggle={onToggle}
        rows={rows}
        style={{ maxWidth: '55rem' }}
      />
      {checked?.filter(item => item)?.length > 0 && <BulkDeleteFilesDialog />}
      {rows.length > 0 && <DeleteFileDialog />}
      {rows.length > 0 && <EditFileDialog />}
      <FilePreview />
    </Fragment>
  );
};

export default memo(Files);
