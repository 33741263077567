// Renders a "handle" icon used for dragging a field row.
import React from 'react';
import PropTypes from 'prop-types';
import DragIndicatorIcon from '@material-ui/icons/DragIndicator';
import './details-layout-drawer.scss';

const DragHandle = ({ provided }) => (
  <div data-testid="drag-handle" styleName="drag-handle" {...provided.dragHandleProps}>
    <DragIndicatorIcon htmlColor="#999999" style={{ fontSize: '1rem', width: '1.5rem' }} />
  </div>
);

DragHandle.propTypes = {
  provided: PropTypes.object
};

export default DragHandle;
