// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.src-components-portal-table-___header__header{display:flex;padding:0.75rem 1.5rem;align-items:center;align-self:stretch;justify-content:space-between;background:white;box-shadow:0px -1px 0px 0px #EBEBEB inset}.src-components-portal-table-___header__header .src-components-portal-table-___header__title{display:flex;align-items:center;justify-content:flex-start;gap:0.5rem;flex-grow:1}.src-components-portal-table-___header__header .src-components-portal-table-___header__title .src-components-portal-table-___header__label{font-size:1.375rem;font-weight:400;line-height:1.75rem}
`, "",{"version":3,"sources":["webpack://./src/components/portal/table/header.scss"],"names":[],"mappings":"AAAA,+CACE,YAAa,CACb,sBAAuB,CACvB,kBAAmB,CACnB,kBAAmB,CACnB,6BAA8B,CAE9B,gBAAiB,CACjB,yCAA0C,CAR5C,6FAWI,YAAa,CACb,kBAAmB,CACnB,0BAA2B,CAC3B,UAAW,CACX,WAAY,CAfhB,2IAkBM,kBAAmB,CACnB,eAAgB,CAChB,mBAAoB","sourcesContent":[".header {\n  display: flex;\n  padding: 0.75rem 1.5rem;\n  align-items: center;\n  align-self: stretch;\n  justify-content: space-between;\n\n  background: white;\n  box-shadow: 0px -1px 0px 0px #EBEBEB inset;\n\n  .title {\n    display: flex;\n    align-items: center;\n    justify-content: flex-start;\n    gap: 0.5rem;\n    flex-grow: 1;\n\n    .label {\n      font-size: 1.375rem;\n      font-weight: 400;\n      line-height: 1.75rem;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header": `src-components-portal-table-___header__header`,
	"title": `src-components-portal-table-___header__title`,
	"label": `src-components-portal-table-___header__label`
};
export default ___CSS_LOADER_EXPORT___;
