import React from 'react';
import PropTypes from 'prop-types';
import { Button as MUIButton } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import * as R from 'ramda';

import { colors } from '@constants/colors';


// box shadow used for Button in MUI 0.4
const MUI_0_BUTTON_BOX_SHADOW =
  'rgb(0 0 0 / 12%) 0px 1px 6px, rgb(0 0 0 / 12%) 0px 1px 4px';

const useStyles = makeStyles(theme => ({  // eslint-disable-line no-unused-vars
  root: ({ style, component }) => ({
    borderRadius: '2px',
    minWidth: '88px',
    padding: '6px 8px',
    whiteSpace: 'nowrap',
    component,
    ...style
  }),
  containedPrimary: {
    boxShadow: MUI_0_BUTTON_BOX_SHADOW,
    '&:hover': {
      boxShadow: MUI_0_BUTTON_BOX_SHADOW
    },
    color: colors.neutral.white,
    backgroundColor: colors.brand.blue
  },
  textInherit: {
    color: colors.neutral.black
  },
  textPrimary: {
    color: colors.brand.blue
  },
  outlinedInherit: {
    border: `1px solid ${colors.neutral.silver}`
  }
}));

export const Button = ({ color = 'inherit', ...props }) => {
  const classes = useStyles(props);

  return (
    <MUIButton
      {...R.omit(['textColor'], props)}
      classes={{
        root: classes.root,
        containedPrimary: classes.containedPrimary,
        textInherit: classes.textInherit,
        textPrimary: classes.textPrimary,
        outlinedInherit: classes.outlinedInherit,
        ...props.classes
      }}
      color={color}
    />
  );
};

Button.propTypes = {
  classes: PropTypes.object,
  color: PropTypes.string
};

export default Button;
