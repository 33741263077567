import React, { memo } from 'react';
import PropTypes from 'prop-types';

import './batch-upload-dialog.scss';

const Column = ({ title, content }) => (
  <div styleName="section-row-field">
    <div styleName="section-row-field-header">
      {title}
    </div>
    <div styleName="section-row-field-content">
      {content}
    </div>
  </div>
);

Column.propTypes = {
  content: PropTypes.string,
  title: PropTypes.string
};

// Render the summary value if it's not empty, or uses "TBD" if it's empty.
// We need this, to render values like zero, that's why we can't just use "!value".
const renderSummary = value => {
  if (value === null || typeof value === 'undefined') {
    return 'TBD';
  }
  return value;
};

const BatchUploadFileSummary = ({ summary }) => (
  <div styleName="section">
    <div styleName="section-header" style={{ padding: '1rem 0 0 0'}}>
      File summary
    </div>
    <div styleName="section-row">
      <div styleName="section-row-fields">
        <Column title="Unique identifier" content={renderSummary(summary.unique_id)} />
        <Column title="New" content={renderSummary(summary.total_new)} />
        <Column title="Updated" content={renderSummary(summary.total_updated)} />
      </div>
    </div>
  </div>
);

BatchUploadFileSummary.propTypes = {
  summary: PropTypes.object
};

export default memo(BatchUploadFileSummary);
