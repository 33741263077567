import { useEffect, memo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import ReactGA from 'react-ga4';

import { fetchConfig } from './actions';
import { getConfig } from './selectors';

const ConfigurationLoader = ({children}) => {
  const dispatch = useDispatch();
  const config = useSelector(getConfig);
  const {siteLoaded, GOOGLE_ANALYTICS_KEY} = config;

  useEffect(() => {
    if (!siteLoaded) {
      dispatch(fetchConfig());
    }
  }, [dispatch, siteLoaded]);

  useEffect(() => {
    if (GOOGLE_ANALYTICS_KEY && siteLoaded) {
      ReactGA.initialize(GOOGLE_ANALYTICS_KEY);
      ReactGA.send({
        hitType: 'pageview',
        page: `${window.location.pathname}${window.location.search}`
      });
    }
  }, [dispatch, GOOGLE_ANALYTICS_KEY, siteLoaded]);

  if (!siteLoaded) {
    return null;
  }
  return children;
};

ConfigurationLoader.propTypes = {
  children: PropTypes.node
};

export default memo(ConfigurationLoader);
