import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Figure from './figure';
import { fetchLayerData } from '@actions/layers-actions';

class Layers extends Component {
  UNSAFE_componentWillMount() {
    const { layers, name } = this.props;
    if (!layers[name].list.length) {
      this.props.fetchLayerData(name);
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { layers, name } = this.props;
    const { next } = nextProps;
    if (!layers[name].loadingComplete && next && this.props.next !== next) {
      this.props.fetchLayerData(name);
    }
  }

  render() {
    const { list, name } = this.props;
    return list.map(layer => <Figure key={layer.id} layer={layer} name={name} />);
  }
}

Layers.propTypes = {
  fetchLayerData: PropTypes.func,
  id: PropTypes.number,
  layers: PropTypes.object,
  list: PropTypes.array,
  name: PropTypes.string,
  next: PropTypes.string
};

const mapStateToProps = state => {
  const { layers } = state;
  return { layers };
};

export default connect(mapStateToProps, { fetchLayerData })(Layers);
