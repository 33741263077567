/* eslint-disable react/jsx-no-bind */
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import { push } from 'connected-react-router';
import { showConfirmationDialog } from '@actions/confirmation-actions';
import { getConfirmableDeleteAndNavigateAction } from '@actions/data-detail-actions';
import * as colors from '@constants/colors';
import { getEntityTypeLabel } from '@constants/config';
import { Icon } from '@mui';
import IconMenu from '@shared/icon-menu';
import IconMenuItem from '@shared/icon-menu-item';
import { getDashboardBackURL } from '@utils/navigation-utils';
import { canDeleteEntities, canEditEntities } from '@utils/permission-utils';

const TopBarActionEntityMenu = () => {
  const { data } = useSelector(state => state.dataDetail);
  const { dataType, id } = useParams();
  const location = useLocation();
  const dispatch = useDispatch();

  const createDuplicate = () => dispatch(push(`/${dataType}/${id}/duplicate`));

  if (data) {
    const { agency } = data;

    const deleteItem = () => {
      const source = getDashboardBackURL(location, dataType);
      const label = getEntityTypeLabel(dataType);
      dispatch(showConfirmationDialog(
        `Delete ${label}?`,
        `Are you sure you want to delete ${label} "${data.name}"?`,  // eslint-disable-line quotes
        getConfirmableDeleteAndNavigateAction(dataType, dataType, data.id, source)
      ));
    };

    const canEdit = canEditEntities(agency, dataType);
    const canDelete = canDeleteEntities(agency, dataType);

    if (canEdit || canDelete) {
      return (
        <IconMenu iconButtonStyles={{ padding: 0, margin: '0 0 0 1rem' }}>
          {
            canEdit && <IconMenuItem key="duplicate" icon={<Icon>content_copy</Icon>} onClick={() => createDuplicate()}>
              <div>Duplicate</div>
            </IconMenuItem>
          }
          {
            canDelete && <IconMenuItem key="delete" icon={<Icon color={colors.dotmapsRed}>delete_outline</Icon>} onClick={() => deleteItem()}>
              <div style={{ color: colors.dotmapsRed }}>Delete {getEntityTypeLabel(dataType)}</div>
            </IconMenuItem>
          }
        </IconMenu>
      );
    }
  }
  return null;
};

export default TopBarActionEntityMenu;
