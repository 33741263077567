// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.src-components-filters-chips-___text__tooltip{color:var(--Surface-White, #fff);font-feature-settings:'clig' off, 'liga' off;font-family:Roboto;font-size:0.75rem;font-style:normal;font-weight:400;line-height:1rem;min-width:7.875rem}.src-components-filters-chips-___text__tooltip>ul{margin:0;padding-inline-start:1.5rem}.src-components-filters-chips-___text__container.src-components-filters-chips-___text__container{display:flex;align-items:center;min-height:2rem;width:12.5rem;padding-block:0.1875rem;padding-inline:0.6875rem 0.4375rem;border-width:0.0625rem;line-height:1.25rem}.src-components-filters-chips-___text__container .src-components-filters-chips-___text__text{padding:0;font-size:0.875rem;font-weight:400}.src-components-filters-chips-___text__container .src-components-filters-chips-___text__icon{color:#616161}
`, "",{"version":3,"sources":["webpack://./src/components/filters/chips/text.scss"],"names":[],"mappings":"AAAA,+CACE,gCAAiC,CACjC,4CAA6C,CAE7C,kBAAmB,CACnB,iBAAkB,CAClB,iBAAkB,CAClB,eAAgB,CAChB,gBAAiB,CAEjB,kBAAmB,CAVrB,kDAaI,QAAS,CACT,2BAA4B,CAC7B,iGAKC,YAAa,CACb,kBAAmB,CACnB,eAAgB,CAChB,aAAc,CAEd,uBAAwB,CACxB,kCAAmC,CACnC,sBAAuB,CACvB,mBAAoB,CAVxB,6FAcI,SAAU,CAEV,kBAAmB,CACnB,eAAgB,CAjBpB,6FAqBI,aAAc","sourcesContent":[".tooltip {\n  color: var(--Surface-White, #FFF);\n  font-feature-settings: 'clig' off, 'liga' off;\n  /* Caption */\n  font-family: Roboto;\n  font-size: 0.75rem;\n  font-style: normal;\n  font-weight: 400;\n  line-height: 1rem; /* 133.333% */\n\n  min-width: 7.875rem;\n\n  &>ul {\n    margin: 0;\n    padding-inline-start: 1.5rem;\n  }\n}\n\n.container {\n  &.container {\n    display: flex;\n    align-items: center;\n    min-height: 2rem;\n    width: 12.5rem;\n\n    padding-block: 0.1875rem;\n    padding-inline: 0.6875rem 0.4375rem;\n    border-width: 0.0625rem;\n    line-height: 1.25rem;\n  }\n\n  .text {\n    padding: 0;\n\n    font-size: 0.875rem;\n    font-weight: 400;\n  }\n\n  .icon {\n    color: #616161;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tooltip": `src-components-filters-chips-___text__tooltip`,
	"container": `src-components-filters-chips-___text__container`,
	"text": `src-components-filters-chips-___text__text`,
	"icon": `src-components-filters-chips-___text__icon`
};
export default ___CSS_LOADER_EXPORT___;
