import React, { memo } from 'react';
import PropTypes from 'prop-types';

import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';

import './batch-upload-stepper.scss';

const STEPS = ['Upload file', 'Select fields', 'Edit'];

const BatchUploadStepper = ({ activeStep }) => (
  <div styleName="stepper">
    <Stepper activeStep={activeStep} orientation="vertical">
      {STEPS.map(label => (
        <Step key={label}>
          <StepLabel>{label}</StepLabel>
        </Step>
      ))}
    </Stepper>
  </div>
);

BatchUploadStepper.propTypes = {
  activeStep: PropTypes.number
};

export default memo(BatchUploadStepper);
