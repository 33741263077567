import React from 'react';

const CsvIcon = () => (
  <svg width="1.5rem" height="1.5rem" viewBox="0 0 24 24">
    <defs>
      <path
        d={`
           M19 3H5c-1.1 0-1.99.9-1.99 2L3 8v11c0 1.1.9 2 2 2h14c1.1 0 2-.9
           2-2V5c0-1.1-.9-2-2-2zm0 8h-8v8H9v-8H5V9h4V5h2v4h8v2z
           `}
        id="csv-icon"
      />
    </defs>
    <use fill="#3FA142" fillRule="nonzero" xlinkHref="#csv-icon" />
  </svg>
);

export default CsvIcon;
