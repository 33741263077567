/* eslint-disable max-depth */
/* eslint-disable react/display-name */
/* eslint-disable react/prop-types */
//
// Utility functions for conflict status processing.
//
import React from 'react';
import * as R from 'ramda';
import { conflictStatuses } from '@constants/data-table-config';
import {
  PERMISSION_GROUP_ADMIN,
  PERMISSION_GROUP_MANAGER
} from '@constants/permission';
import { detailStyles } from '@constants/mui-theme';
import Badge from '@shared/badge';
import { allowedForAgency } from '@utils/permission-utils';

// The constants for overlap entity and overlaps:
export const OVERLAP_ENTITY_STATUS = {
  new: '0',
  resolved: '1'
};

export const OVERLAP_STATUS = {
  new: '0',
  pending: '1',
  cleared: '2',
  inactive: '3'
};

// It's named "FRONTEND" since these are statuses to show on the UI,
// which doesn't match one to one the ones on the backend.
export const FRONTEND_CONFLICT_STATUS = {
  open: 'OPEN',
  pending: 'PENDING',
  resolve: 'RESOLVE',
  resolved: 'RESOLVED',
  opportunity: 'OPPORTUNITY'
};

// Contains the data to build the conflict status row in the UI.
export const CONFLICT_STATUS_ROW = {
  OPEN: {
    badge: <Badge {...conflictStatuses.Open} />,
    buttonStyle: detailStyles.conflictStatus.red,
    dialogHint: 'Coordination is required from both sides.',
    hint: 'Requires coordination',
    mainButtonStyle: detailStyles.conflictMainStatus.red
  },
  PENDING: {
    badge: <Badge {...conflictStatuses.Pending} />,
    buttonStyle: detailStyles.conflictStatus.yellow,
    dialogHint: props => (
      <span>Waiting for <b>{props.agency2}</b> to resolve the conflict. <b>{props.agency1}'s</b> resolution received.</span>
    ),
    hint: 'Awaiting resolution',
    mainButtonStyle: detailStyles.conflictMainStatus.yellow
  },
  RESOLVE: {
    badge: <Badge {...conflictStatuses.Open} />,
    buttonStyle: detailStyles.conflictStatus.white,
    dialogHint: props => (
      <span><b>{props.agency1}</b> and <b>{props.agency2}</b> will both need to resolve the conflict.</span>
    ),
    hint: 'Requires your resolution',
    mainButtonStyle: detailStyles.conflictMainStatus.white
  },
  RESOLVED: {
    badge: <Badge {...conflictStatuses.Resolved} />,
    buttonStyle: detailStyles.conflictStatus.blue,
    mainButtonStyle: detailStyles.conflictMainStatus.blue
  },
  OPPORTUNITY: {
    badge: <Badge {...conflictStatuses.Opportunity} />,
    buttonStyle: detailStyles.conflictStatus.blue,
    dialogHint: props => props && props.overlapStatus.overlap_previously_conflict ? 'Previously conflict.' : '',
    hint: props => props && props.overlap_previously_conflict ? 'Previously conflict' : '',
    mainButtonStyle: detailStyles.conflictMainStatus.blue
  }
};

// Returns the status for the specified entity.
// If the status is RESOLVED, it will additionally return the resolution reason.
export const getEntityStatus = (mainOverlapStatus, entityOverlapStatus) => {
  let status = null;
  let reason = null;

  if (mainOverlapStatus && entityOverlapStatus) {
    const statuses = entityOverlapStatus;
    if (statuses.length > 0) {
      // Grab the statuses from the main entity, since the ones returned
      // on each specific entity represents the overlap_entity for that entity id,
      // not the main one:
      const mainStatuses = mainOverlapStatus;

      // Now take the overlap id from this current entity,
      // we'll use it to look it up on the list from the main entity.
      const overlapId = statuses[0].overlap;  // The entity always have a single entry
      const mainStatus = R.find(R.propEq(overlapId, 'overlap'))(mainStatuses);  // Find the status from the main list.

      // Take the overlap status for this current entity.
      const overlapStatus = statuses[0].overlap_status;
      // And the overlap entity status (also for this entity, but from the main list).
      const overlapEntityStatus = mainStatus.overlap_entity_status;

      // If this overlap entity side status is NEW, the entity status to show is "RESOLVE".
      if (overlapEntityStatus === OVERLAP_ENTITY_STATUS.new) {
        status = FRONTEND_CONFLICT_STATUS.resolve;
      } else
      // If it's RESOLVED, check the overlap status:
        if (overlapEntityStatus === OVERLAP_ENTITY_STATUS.resolved) {
          if (overlapStatus === OVERLAP_STATUS.cleared) {
            // If the overlap is solved (both sides resolved), show the "RESOLVED" label.
            status = FRONTEND_CONFLICT_STATUS.resolved;
          } else
            if (overlapStatus === OVERLAP_STATUS.pending ||
            overlapStatus === OVERLAP_STATUS.new) {
              // If the overlap is pending (one one side is resolved), the status should be "PENDING".
              // However the overlap might be "new" which in this case means it was resolved and
              // later revoked (because it's resolved and new).
              status = FRONTEND_CONFLICT_STATUS.pending;
            }
          // If it's resolved, there's a resolution reason, thus return it too.
          reason = mainStatus.reason;
        } else
          // If the overlap is pending (one of the other entities is resolved)
          // then show it as "PENDING".
          if (overlapStatus === OVERLAP_STATUS.pending) {
            status = FRONTEND_CONFLICT_STATUS.pending;
          }
    }
  }
  return { status, reason };
};

export const getStatus = (mainOverlapStatus, entityOverlapStatus, isOpportunity) => {
  if (isOpportunity) {
    return { status: FRONTEND_CONFLICT_STATUS.opportunity, reason: null };
  }
  return getEntityStatus(mainOverlapStatus, entityOverlapStatus);
};

// Only managers can resolve/revoke (if the entity belongs to their agency).
export const allowedToChangeStatus = mainAgency => allowedForAgency([PERMISSION_GROUP_ADMIN, PERMISSION_GROUP_MANAGER], mainAgency);
