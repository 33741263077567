import React from 'react';
import PropTypes from 'prop-types';

import { CircularProgress } from '@material-ui/core';

import { colors } from '@constants/colors';

const styles = {
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    verticalAlign: 'middle'
  }
};

export const Progress = props => {
  const {
    size = 50,
    thickness = 3,
    progressColor = colors.brand.blue,
    style = {},
    ...data
  } = props;

  return (
    <div style={{ ...styles.container, ...style }}>
      <CircularProgress
        size={size}
        thickness={thickness}
        style={{ color: progressColor }}
        {...data}
      />
    </div>
  );
};

Progress.propTypes = {
  progressColor: PropTypes.string,
  size: PropTypes.number,
  style: PropTypes.object,
  thickness: PropTypes.number
};

export default Progress;
