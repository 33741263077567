/* eslint-disable react/jsx-no-bind */
import React, { memo } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { colors } from '@constants/colors';
import Link from '@material-ui/core/Link';
import { Icon } from '@mui';
import IconMenu from '@shared/icon-menu';
import IconMenuItem from '@shared/icon-menu-item';

const TopBarActions = ({ data, goToActivityLog }) => {
  const { CONTACT_PCCO_EMAIL: pccoEmail } = useSelector(state => state.config);
  if (!data) {
    // If we are still loading data, show a disabled button.
    return <IconMenu iconStyles={{ color: colors.opacity.white0_5 }} />;
  }
  return (
    <IconMenu iconStyles={{ color: colors.neutral.white }}>
      <IconMenuItem key="activity" icon={<Icon>history</Icon>} onClick={goToActivityLog}>
        View activity log
      </IconMenuItem>
      {pccoEmail &&
        <IconMenuItem key="pcco" icon={<Icon>mail_outline</Icon>}>
          <Link
            color="inherit"
            href={`mailto:${pccoEmail}`}
            onClick={event => event.preventDefault()}
            underline="none"
          >
            Contact PCCO
          </Link>
        </IconMenuItem>
      }
    </IconMenu>
  );
};

TopBarActions.propTypes = {
  data: PropTypes.object,
  goToActivityLog: PropTypes.func
};

export default memo(TopBarActions);
