/* eslint-disable react/jsx-no-bind */
/* eslint-disable max-depth */
import React, { Fragment, memo, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { DrawingManager } from '@react-google-maps/api';
import InfoContext from '@components/entity/info/info-context';
import MapComponents from '@components/entity/info/map-components';
import MapEditFigure from '@components/entity/info/map-edit-figure';
import { dotmapsBlue } from '@constants/colors';
import { mapConfig } from '@constants/component-configs';
import { detailLineStyle, detailPolygonStyle } from '@constants/data-detail';
import DotMapsGoogleMap from '@shared/dotmaps-google-map';
import DotmapsLoader from '@shared/dotmaps-loader';
import MapFigure from '@shared/map-figure';
import { segmentsToBounds } from '@utils/map-utils';

const setPolygonRef = ref => {
  window.editMapRef = ref;
};

const MapEdit = ({ onActiveSegmentChanged, onPolygonChanged, segments }) => {
  const [map, setMap] = useState(null);
  const [initLoad, setInitLoad] = useState(true);
  const {
    activeEnd,
    activeSegmentId,
    activeStart,
    activeType,
    geometryLocked
  } = useContext(InfoContext);

  const onMapLoad = useCallback(mapInstance => {
    if (!window.mapSetup) {
      window.initMap();
    }
    window.mapInstance = mapInstance;
    setMap(mapInstance);
  }, [setMap]);

  const filteredSegments = useMemo(
    () => {
      if (segments) {
        return segments.filter(
          segment => segment.shape && ((initLoad && !activeSegmentId) || segment.id === activeSegmentId)
        );
      }
      return segments;
    }, [initLoad, segments, activeSegmentId]
  );

  const fitSegmentBounds = useCallback(
    () => {
      if (filteredSegments) {
        setInitLoad(false);
        if (filteredSegments.length > 0) {
          const bounds = segmentsToBounds(filteredSegments);
          if (bounds) {
            if (filteredSegments[0]?.id?.startsWith('temp-') && activeType === 'LineString' && filteredSegments[0]?.shape?.type === 'Point') {
              map.panToBounds(bounds);
            } else {
              map.fitBounds(bounds);
              if (map.getZoom() > 18) {
                map.setZoom(18);
              }
            }
          }
        }
      }
    },
    [activeType, filteredSegments, map]
  );

  useEffect(() => {
    if (map) {
      fitSegmentBounds();
    }
  }, [fitSegmentBounds, map, segments?.length, activeSegmentId]);

  const onClick = useCallback(({ latLng }) => {
    switch (activeType) {
    case 'Point':
      if (!activeStart) {
        onActiveSegmentChanged({start: latLng.toJSON(), end: null});
      }
      break;
    case 'LineString':
      if (!activeStart) {
        onActiveSegmentChanged({start: latLng.toJSON(), end: activeEnd});
      } else if (!activeEnd) {
        onActiveSegmentChanged({start: activeStart, end: latLng.toJSON()});
      }
      break;
    default:
      break;
    }
  }, [activeType, activeStart, activeEnd, onActiveSegmentChanged]);

  const onPolygonComplete = useCallback(
    mapsShape => {
      onPolygonChanged(mapsShape);
      mapsShape.setMap(null);
    },
    [onPolygonChanged]
  );

  const onMouseUp = useCallback(
    () => onPolygonChanged(window.editMapRef.state.polygon),
    [onPolygonChanged]
  );

  const getComponents = useCallback(
    () => {
      if (segments) {
        return segments.filter(segment => segment.shape || segment.id === activeSegmentId).map(segment => {
          if (segment.id === activeSegmentId) {
            if (activeType !== 'Polygon') {
              return (
                <MapEditFigure
                  key={segment.id}
                  direction={segment.direction}
                  shape={segment.shape}
                  start={activeStart}
                  end={activeType === 'LineString' ? activeEnd : null}
                  onChange={onActiveSegmentChanged}
                  geometryLocked={geometryLocked}
                />
              );
            } else if (activeType === 'Polygon' && !segment.shape) {
              return (
                // eslint-disable-next-line react/jsx-key
                <DrawingManager
                  drawingMode="polygon"
                  onPolygonComplete={onPolygonComplete}
                  options={mapConfig.drawingManager}
                />
              );
            } else if (activeType === 'Polygon' && segment.shape) {
              return (
                <MapFigure
                  key={segment.id}
                  shape={segment.shape}
                  setPolygonRef={setPolygonRef}
                  onMouseUp={onMouseUp}
                  editable={!geometryLocked}
                  options={{...detailLineStyle, ...detailPolygonStyle}}
                />
              );
            }
          }
          return (
            <MapFigure
              key={segment.id}
              shape={segment.shape}
              options={{...detailLineStyle, ...detailPolygonStyle}}
            />
          );
        });
      }
      return [];
    },
    [
      activeEnd,
      activeSegmentId,
      activeStart,
      activeType,
      geometryLocked,
      onActiveSegmentChanged,
      onMouseUp,
      onPolygonComplete,
      segments
    ]
  );

  return (
    <DotMapsGoogleMap
      components={(
        <Fragment>
          {segments && <MapComponents>{getComponents()}</MapComponents>}
          {!segments && <DotmapsLoader color={dotmapsBlue} display key="loader" />}
        </Fragment>
      )}
      onClick={onClick}
      onMapLoad={onMapLoad}
    />
  );
};

MapEdit.propTypes = {
  onActiveSegmentChanged: PropTypes.func,
  onPolygonChanged: PropTypes.func,
  segments: PropTypes.arrayOf(PropTypes.object)
};

export default memo(MapEdit);
