/* eslint-disable react/jsx-no-bind */
import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import MuiTable from '@material-ui/core/Table';
import * as colors from '@constants/colors';
import DotmapsLoader from '@shared/dotmaps-loader';
import Body from './body';
import Head from './head';
import EmptyBody from './empty-body';
import './table.scss';

const useStyles = makeStyles({
  table: {
    minWidth: 650
  }
});

const Table = ({
  actions,
  checked, checkedProps, onToggleAll, onToggle,  // Checkbox props.
  columns,
  empty,
  loading,
  header,
  onRowClick,
  rows,
  search,
  style
}) => {
  const classes = useStyles();
  return (
    <div styleName="table-container" style={style}>
      {header}
      {search}
      <MuiTable stickyHeader className={classes.table} style={{ padding: search ? '0 1.5rem' : '0' }}>
        <Head
          columns={columns}
          checkedProps={checkedProps}
          onToggleAll={onToggleAll}
        />
        <Body
          actions={actions}
          checked={checked}
          columns={columns}
          loading={loading}
          onToggle={onToggle}
          onRowClick={onRowClick}
          rows={rows}
        />
      </MuiTable>
      {loading && (!rows || rows.length === 0) && <DotmapsLoader color={colors.dotmapsBlue} display />}
      {!loading && rows.length === 0 && <EmptyBody {...empty} />}
    </div>
  );
};

Table.propTypes = {
  actions: PropTypes.array,
  checked: PropTypes.array,
  checkedProps: PropTypes.object,
  columns: PropTypes.array,
  empty: PropTypes.object,
  header: PropTypes.node,
  loading: PropTypes.bool,
  onRowClick: PropTypes.func,
  onToggle: PropTypes.func,
  onToggleAll: PropTypes.func,
  rows: PropTypes.array,
  search: PropTypes.node,
  style: PropTypes.object
};

Table.defaultProps = {
  loading: false,
  style: {}
};

export default Table;
