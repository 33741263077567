// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.src-components-map-tray-search-results-___group-item__group-item.src-components-map-tray-search-results-___group-item__group-item{padding-inline:1rem;background:white;border-bottom:1px solid var(--Neutral-Border-white, #EBEBEB)}
`, "",{"version":3,"sources":["webpack://./src/components/map/tray/search/results/group-item.scss"],"names":[],"mappings":"AAGA,mIACE,mBAAoB,CACpB,gBAAiB,CACjB,4DAA6D","sourcesContent":["@import '../../../../../styles/vars';\n@import '../../../../../styles/mixins';\n\n.group-item.group-item {\n  padding-inline: 1rem;\n  background: white;\n  border-bottom: 1px solid var(--Neutral-Border-white, #EBEBEB);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"group-item": `src-components-map-tray-search-results-___group-item__group-item`,
	"groupItem": `src-components-map-tray-search-results-___group-item__group-item`
};
export default ___CSS_LOADER_EXPORT___;
