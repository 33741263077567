import React from 'react';
import PropTypes from 'prop-types';
import { dotmapsBlue } from '@constants/colors';

const ConflictResolveIcon = ({ color }) => (
  <svg width="24px" height="24px" version="1.1" viewBox="0 0 24 24">
    <defs>
      <path id="conflict-resolve-path-1" d="M16.01 11H4v2h12.01v3L20 12l-3.99-4z" />
    </defs>
    <g fill="none" fillRule="evenodd">
      <mask id="conflict-resolve-path-2" fill="#fff">
        <use xlinkHref="#conflict-resolve-path-1" />
      </mask>
      <g fill={color} mask="url(#conflict-resolve-path-2)">
        <path d="M0 0h24v24H0z" />
      </g>
    </g>
  </svg>
);

ConflictResolveIcon.propTypes = {
  color: PropTypes.string
};

ConflictResolveIcon.defaultProps = {
  color: dotmapsBlue
};

export default ConflictResolveIcon;
