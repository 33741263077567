import React, { memo, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import SelectFilterChip from '../chips/select';

import { useDataTypeFilterHandleChange } from '../hooks';

const AgencyFilter = ({filter, value, dataType, subType, filterKey}) => {
  const handleChange = useDataTypeFilterHandleChange(dataType, subType, filterKey);
  const agencies = useSelector(state => state.dataTypes.agency);
  const options = useMemo(() => {
    if (!agencies) {
      return [];
    }
    return Object.values(agencies).map(agency => ({
      label: agency.name,
      value: agency.id
    }));
  }, [agencies]);

  return (
    <SelectFilterChip
      label={filter.label || 'Agency'}
      options={options}
      value={value}
      onChange={handleChange}
      multiSelect
      search
    />
  );
};

AgencyFilter.propTypes = {
  dataType: PropTypes.string.isRequired,
  filter: PropTypes.object.isRequired,
  filterKey: PropTypes.string.isRequired,
  subType: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.array, PropTypes.number])
};

export default memo(AgencyFilter);
