import { createSelector } from 'reselect';
import * as R from 'ramda';
import { getZoom } from '@selectors/map-selector';
import { getBounds } from '@selectors/entities-selector';
import { getAgencyAndEntityTypes } from '@selectors/shared-selector';
import { arrayToObjectIds } from '@utils/data-types-utils';
import {
  optimizeEntitiesForMap,
  optimizeEntitiesForList
} from '@utils/entity-utils';
import { findGroupType } from '@utils/group-utils';
import { groupMarkers } from '@utils/markers-utils';

const getGroups = state => state.groups.list;
const getMapGroupsVisible = state => state.groups.showMapLayer;
export const getMapBoundariesVisible = state => state.groups.showLayerBoundaries;
export const getMapLabelsVisible = state => state.groups.showLayerLabels;
const getEntityGroupIds = (state, props) => props.entity ? props.entity.group_ids : [];

const getGroupEntities = R.converge(
  R.concat,
  [R.pathOr([], ['groups', 'edit', 'group', 'entities']), R.pathOr([], ['groups', 'edit', 'polygonEntities', 'entities'])]
);

export const getGroupIsLoading = R.pathOr(false, ['groups', 'edit', 'loading']);

const getMapOptimizedEntities = createSelector(
  [getGroupEntities, getAgencyAndEntityTypes],
  (entities, agencyAndEntityTypes) => optimizeEntitiesForMap(entities, null, agencyAndEntityTypes)
);

export const optimizeEntitiesForMapSelector = createSelector(
  [getMapOptimizedEntities, getBounds],
  (entities, bounds) => {
    if (bounds) {
      return entities.filter(({ bounds: entityBounds }) => bounds.intersects(entityBounds));
    }
    return entities;
  }
);

export const optimizeEntitiesForListSelector = createSelector([getGroupEntities, getAgencyAndEntityTypes], optimizeEntitiesForList);

const getGroupCycles = state => R.pathOr([], ['groups', 'edit', 'group', 'cycles'], state);

export const getCycleGroupCycles = createSelector(
  [getGroupCycles],
  groupCycles => groupCycles
);

export const mapGroupsSelector = createSelector(
  [getGroups, getMapGroupsVisible],
  (groups, mapLayerVisible) => mapLayerVisible ? groups.filter(group => group.auto_area) : []
);

export const mapBoundsGroupsSelector = createSelector(
  [mapGroupsSelector, getBounds],
  (groups, bounds) => groups.filter(group => group.bounds && bounds.intersects(group.bounds))
);

export const nonOverlapingEntitiesGroups = createSelector(
  [getGroups, getEntityGroupIds],
  (groups, groupIds) => {
    const groupsById = arrayToObjectIds(groups);
    return Object.values(groupsById).filter(
      group => groupIds &&
      groupIds.includes(group.id) &&
      findGroupType(group.type).generates_overlaps === false
    );
  }
);

// Convert the group list to an object hashed by group id for faster lookup
// (since the renderOption method used by Autocomplete only gets the option id,
// i.e. the group id and we need to full group fields to render the option).
export const groupListById = createSelector(
  [getGroups],
  groups => arrayToObjectIds(groups)
);

const getEntities = createSelector([getMapOptimizedEntities], entities => {
  const groupedEntities = {};
  entities.forEach(entity => {
    if (!groupedEntities[entity.type_name]) {
      groupedEntities[entity.type_name] = [];
    }
    groupedEntities[entity.type_name].push(entity);
  });
  return groupedEntities;
});

export const getGroupOverlappingMarkers = createSelector([
  getEntities,
  getZoom
], (
  entities,
  zoom
) => groupMarkers(entities, {}, zoom)
);
