// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.src-components-map-components-groups-___group-figure__group-label{background-color:rgba(244,67,54,0.8);border-radius:3px;color:#FFFFFF;font-size:0.8125rem;font-weight:500;position:absolute;padding:0.5rem;pointer-events:none;white-space:nowrap;z-index:200;transform:translateX(-50%) translateY(-50%)}.src-components-map-components-groups-___group-figure__active{background-color:rgba(255,255,255,0.9) !important;color:#F44336 !important}
`, "",{"version":3,"sources":["webpack://./src/components/map/components/groups/group-figure.scss"],"names":[],"mappings":"AAGA,mEACE,oCAAwC,CACxC,iBAAkB,CAClB,aAAc,CACd,mBAAoB,CACpB,eAAgB,CAChB,iBAAkB,CAClB,cAAe,CACf,mBAAoB,CACpB,kBAAmB,CACnB,WAAY,CACZ,2CAA4C,CAC7C,8DAGC,iDAAkD,CAClD,wBAAyB","sourcesContent":["@import '../../../../styles/vars';\n@import '../../../../styles/mixins';\n\n.group-label {\n  background-color: rgba(244, 67, 54, 0.8);\n  border-radius: 3px;\n  color: #FFFFFF;\n  font-size: 0.8125rem;\n  font-weight: 500;\n  position: absolute;\n  padding: 0.5rem;\n  pointer-events: none;\n  white-space: nowrap;\n  z-index: 200;\n  transform: translateX(-50%) translateY(-50%);\n}\n\n.active {\n  background-color: rgba(255,255,255,0.9) !important;\n  color: #F44336 !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"group-label": `src-components-map-components-groups-___group-figure__group-label`,
	"groupLabel": `src-components-map-components-groups-___group-figure__group-label`,
	"active": `src-components-map-components-groups-___group-figure__active`
};
export default ___CSS_LOADER_EXPORT___;
