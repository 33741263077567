import React, { memo, useMemo } from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';

import { useSelector } from 'react-redux';
import SelectFilterChip from '../chips/select';

import { useDataTypeFilterHandleChange } from '../hooks';

// Does a case insensitive string order sort of objects with the supplied property, on the supplied list/object of objects.
// eslint-disable-next-line react-hooks/rules-of-hooks
const caseInsensitiveSortBy = R.useWith(R.sortBy, [R.curry(R.compose(R.toLower, R.toString, R.prop)), R.values]);

const FieldFilter = ({filter, value, dataType, subType, filterKey}) => {
  const handleChange = useDataTypeFilterHandleChange(dataType, subType, filterKey);
  const dataTypes = useSelector(state => state.dataTypes);
  const options = useMemo(() => {
    let items;
    if (filter.items) {
      items = filter.items;
    } else if (filter.sourceDataType) {
      items = dataTypes[filter.sourceDataType];
      if (!Array.isArray(items)) {
        // items could be an object instead
        items = Object.entries(items)
          .sort((first, second) => first[0] - second[0])
          .map(([/* key */, itemValue]) => itemValue);
      }
      if (filter.sort) {
        items = caseInsensitiveSortBy(filter.sort, items);
      }
    }
    if (items) {
      return items.map(item => ({
        label: item.name,
        value: item.id
      }));
    }
    return null;
  }, [filter, dataTypes]);

  if (options) {
    return (
      <SelectFilterChip
        label={filter.label}
        options={options}
        value={value}
        onChange={handleChange}
        multiSelect
        search
      />
    );
  }
  return null;
};

FieldFilter.propTypes = {
  dataType: PropTypes.string.isRequired,
  filter: PropTypes.object.isRequired,
  filterKey: PropTypes.string.isRequired,
  subType: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.array, PropTypes.number, PropTypes.string, PropTypes.bool])
};

export default memo(FieldFilter);
