import { LOCATION_CHANGE } from 'connected-react-router';
import { isEmpty } from 'lodash';
import * as R from 'ramda';
import {
  CHANGE_GROUP_FIELD_VALUE,
  GET_GROUP_BY_ID_SUCCESS,
  GROUP_APPLY_SHAPE,
  GROUP_CLEAR_DATE,
  GROUP_CREATE_MEMBERS_FETCH_SUCCESS,
  GROUP_DIALOG_SET_ADD_ITEM,
  GROUP_EDIT_LOADING,
  GROUP_SET_LOADING,
  GROUP_SET_SHAPE,
  GROUP_OPTIONS_FETCH_SUCCESS,
  GROUP_OPTIONS_FETCH_ERROR,
  GROUPS_FETCH_POLYGON_START,
  GROUPS_FETCH_POLYGON_SUCCESS,
  GROUPS_FETCH_SET_LOADING,
  GROUPS_FETCH_SUCCESS,
  GROUPS_DETAIL_SAVING,
  GROUPS_DETAIL_SAVING_FAIL,
  GROUPS_DETAIL_SAVING_SUCCESS,
  GROUPS_DETAIL_MODIFIED,
  GROUPS_DISMISS_GANTT_DIALOG,
  GROUPS_ENTITY_OVERLAPS_FETCH_SUCCESS,
  GROUPS_REMOVE_ENTITY_SUCCESS,
  GROUPS_SET_DATE_RANGE,
  GROUPS_SET_GANTT_FILTER,
  GROUPS_SET_LAYER_VISIBLE,
  GROUPS_SET_LAYER_BOUNDARIES_VISIBLE,
  GROUPS_SET_PRE_FILL,
  GROUPS_CLEAR_PRE_FILL,
  GROUPS_TOGGLE_GANTT_LOCATION_TYPE,
  GROUPS_TOGGLE_BOUNDARIES,
  GROUPS_TOGGLE_LABELS,
  HANDLE_SERVER_ERROR,
  GROUPS_DISABLE_DRAWING_MANAGER,
  GROUPS_ENABLE_DRAWING_MANAGER
} from '@constants/action-types';
import { getGanttConfig } from '@constants/config';
import initialState from '../store/initial-state';
import { replaceExternalIDError } from '@utils/form-utils';
import { buildGanttRowsState } from '@utils/gantt-utils';

const getRetrievedEntities = () => R.pathOr([], ['edit', 'group', 'entities']);
const getExistingEntities = () => R.lift(R.concat)(getRetrievedEntities(), R.pathOr([], ['edit', 'polygonEntities', 'entities']));
const getExistingEntityIds = () => R.pipe(getExistingEntities(), R.pluck('id'));

const groupsReducer = (state = initialState.groups, action) => {
  switch (action.type) {
  case GROUP_OPTIONS_FETCH_SUCCESS: {
    const options = action.payload.data;
    return {...state, options};
  }
  case GROUP_OPTIONS_FETCH_ERROR: {
    return {...state, options: null, error: replaceExternalIDError(action.payload.data)};
  }
  case HANDLE_SERVER_ERROR: {
    return { ...state, loading: false };
  }
  case GROUPS_FETCH_SET_LOADING: {
    return { ...state, loading: true };
  }
  case GROUPS_FETCH_SUCCESS: {
    const { groups } = action;
    return { ...state, list: groups, loading: false };
  }
  case GROUPS_FETCH_POLYGON_START: {
    return {
      ...state,
      edit: {
        ...state.edit,
        polygonEntities: {
          ...state.edit.polygonEntities,
          entities: []
        }
      }
    };
  }
  case GROUPS_FETCH_POLYGON_SUCCESS: {
    const { entities } = action.payload;
    const existingEntityIds = new Set(getExistingEntityIds()(state));
    const addPolygonEntities = R.filter(entity => !existingEntityIds.has(entity.id), entities);
    return {
      ...state,
      edit: {
        ...state.edit,
        polygonEntities: {
          ...state.edit.polygonEntities,
          entities: [
            ...state.edit.polygonEntities.entities,
            ...addPolygonEntities
          ]
        }
      }
    };
  }
  case GROUPS_DISABLE_DRAWING_MANAGER: {
    return { ...state, edit: { ...state.edit, drawing: false } };
  }
  case GROUPS_ENABLE_DRAWING_MANAGER: {
    return { ...state, edit: { ...state.edit, drawing: true } };
  }
  case GROUP_EDIT_LOADING: {
    return { ...state, edit: { ...state.edit, group: { ...state.edit.group}, loading: true } };
  }
  case GET_GROUP_BY_ID_SUCCESS: {
    const { ...group } = action.payload.data;
    const { isGantt } = action.payload;
    const edit = { ...state.edit, group, loading: false };
    if (isGantt) {
      let locationTypeGroups = null;
      if (isEmpty(state.gantt.locationTypeGroups)) {
        // If it's empty, initialize it:
        //
        // We split it by type of location type, so
        // if we expand a street on 'byStreetsAndBlocks',
        // by switching to 'byStreets' won't show it collapsed.
        // This is needed since 'byStreetsAndBlocks' defaults
        // all streets to be collapsed.
        locationTypeGroups = {
          [getGanttConfig().locationTypes.all.id]: {},
          [getGanttConfig().locationTypes.byStreets.id]: {},
          [getGanttConfig().locationTypes.byStreetsAndBlocks.id]: {}
        };
      } else {
        locationTypeGroups = { ...state.gantt.locationTypeGroups };
      }
      let filters = null;
      if (isEmpty(state.gantt.filters)) {
        filters = {
          entity: [],
          dateUnit: getGanttConfig().dateUnits.day.id,
          locationType: getGanttConfig().locationTypes.all.id
        };
      } else {
        filters = { ...state.gantt.filters };
      }
      // Process the data required by the Gantt chart:
      const { entity, locationType } = filters;
      const ganttRowState = buildGanttRowsState(entity, locationType, locationTypeGroups, edit);
      return {
        ...state,
        edit,
        gantt: {
          ...state.gantt,
          ...ganttRowState,
          filters: { ...filters }
        }
      };
    }
    return { ...state, edit };
  }
  case GROUPS_SET_DATE_RANGE: {
    const autoAreaLens = R.lensPath(['edit', 'group', 'auto_area']);
    return R.set(autoAreaLens, {...state.edit.group.auto_area, ...action.payload}, state);
  }
  case CHANGE_GROUP_FIELD_VALUE: {
    const { key, value } = action;
    const lens = R.lensPath(['edit', 'group', key]);
    const errorPath = ['edit', 'error', key];
    return R.dissocPath(errorPath, R.set(lens, value, state));
  }
  case GROUP_CREATE_MEMBERS_FETCH_SUCCESS: {
    return {
      ...state,
      edit: {
        ...state.edit,
        group: {
          ...state.edit.group,
          entities: [],
          ...action.payload
        },
        loading: false
      }
    };
  }
  case GROUP_DIALOG_SET_ADD_ITEM: {
    return {
      ...state,
      dialog: {
        ...initialState.groups.dialog,
        ...action.payload
      }
    };
  }
  case GROUP_CLEAR_DATE: {
    const autoAreaLens = R.lensPath(['edit', 'group', 'auto_area']);
    const newAutoArea = {
      ...state.edit.group.auto_area,
      start_date: null,
      end_date: null
    };
    return R.set(autoAreaLens, newAutoArea, state);
  }
  case GROUP_SET_LOADING: {
    const { loading } = action;
    return { ...state, edit: { ...state.edit, loading, applyShape: false } };
  }
  case GROUP_SET_SHAPE: {
    const { shape } = action;
    const polygonLens = R.lensPath(['edit', 'polygonEntities']);
    const shapeLens = R.lensPath(['edit', 'group', 'auto_area', 'shape']);
    return R.set(polygonLens, null, R.set(shapeLens, shape, state));
  }
  case GROUP_APPLY_SHAPE: {
    return { ...state, edit: { ...state.edit, applyShape: true } };
  }
  case GROUPS_ENTITY_OVERLAPS_FETCH_SUCCESS: {
    const { entity, conflicts, status } = action.payload.data;
    return { ...state, edit: { ...state.edit, overlaps: {
      ...state.edit.overlaps,
      [entity.id]: { mainEntity: entity, conflicts, status}
    } } };
  }
  case GROUPS_REMOVE_ENTITY_SUCCESS: {
    if (state.edit.group.entities) {
      const { entityId } = action.payload;
      const entities = state.edit.group.entities.filter(entity => entity.id !== entityId);
      return { ...state, edit: { ...state.edit, group: {...state.edit.group, entities } } };
    }
    return { ...state };
  }
  case GROUPS_DETAIL_SAVING: {
    return {...state, edit: {...state.edit, saving: true, error: {} }};
  }
  case GROUPS_DETAIL_SAVING_SUCCESS: {
    return {...state, edit: {...state.edit, saving: false }};
  }
  case GROUPS_DETAIL_SAVING_FAIL: {
    return {...state, edit: {...state.edit, saving: false, error: replaceExternalIDError(action.payload) }};
  }
  case GROUPS_DETAIL_MODIFIED: {
    return {...state, edit: {...state.edit, modified: action.modified }};
  }
  case LOCATION_CHANGE: {
    if (action && action.payload) {
      const { location: { pathname } } = action.payload;
      if (pathname.startsWith('/group/')) {
        return { ...state, edit: initialState.groups.edit };
      }
    }
    return state;
  }
  case GROUPS_DISMISS_GANTT_DIALOG: {
    const { dialogType, value } = action.payload;
    const dismissed = [...(state.gantt.dialogs[dialogType] || [])];
    const dialogs = { ...state.gantt.dialogs };
    dialogs[dialogType] = [...dismissed, value];
    return { ...state, gantt: { ...state.gantt, dialogs }};
  }
  case GROUPS_SET_GANTT_FILTER: {
    const filters = { ...state.gantt.filters };
    filters[action.dataType] = action.payload;
    // If the filter that changes is the location type or the entity, reprocess the row's data:
    if (action.dataType === 'locationType' || action.dataType === 'entity') {
      const locationTypeGroups = { ...state.gantt.locationTypeGroups };
      const ganttRowState = buildGanttRowsState(filters.entity, filters.locationType, locationTypeGroups, state.edit);
      return {
        ...state,
        gantt: {
          ...state.gantt,
          ...ganttRowState,
          filters
        }
      };
    }
    return { ...state, gantt: { ...state.gantt, filters } };
  }
  case GROUPS_TOGGLE_GANTT_LOCATION_TYPE: {
    const locationTypeGroups = { ...state.gantt.locationTypeGroups };
    const { locationType } = state.gantt.filters;
    const locationTypeGroup = locationTypeGroups[locationType];
    const id = action.payload;
    // Check for undefined, since all id are undefined (thus open)
    // until we start interacting with them.
    const locationElement = typeof locationTypeGroup[id] === 'undefined' ? false : !locationTypeGroup[id];
    return {
      ...state,
      gantt: {
        ...state.gantt,
        locationTypeGroups: {
          ...locationTypeGroups,
          [locationType]: {
            ...locationTypeGroup,
            [id]: locationElement
          }
        }
      }
    };
  }
  case GROUPS_TOGGLE_BOUNDARIES: {
    return { ...state, showLayerBoundaries: !state.showLayerBoundaries };
  }
  case GROUPS_TOGGLE_LABELS: {
    return { ...state, showLayerLabels: !state.showLayerLabels };
  }
  case GROUPS_SET_LAYER_VISIBLE: {
    return { ...state, showMapLayer: action.visible };
  }
  case GROUPS_SET_LAYER_BOUNDARIES_VISIBLE: {
    return { ...state, showLayerBoundaries: action.visible };
  }
  case GROUPS_CLEAR_PRE_FILL: {
    return { ...state, preFill: null };
  }
  case GROUPS_SET_PRE_FILL: {
    return { ...state, preFill: action.group };
  }
  default:
    return state;
  }
};

export default groupsReducer;
