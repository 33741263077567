// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.src-components-auth-layouts-public-___public__public-wrap{height:100%;width:100%;margin:auto;display:flex}.src-components-auth-layouts-public-___public__public-body{margin:auto;height:100%;width:100%}
`, "",{"version":3,"sources":["webpack://./src/components/auth/layouts/public/public.scss"],"names":[],"mappings":"AAGA,2DACE,WAAY,CACZ,UAAW,CACX,WAAY,CACZ,YAAa,CACd,2DAGC,WAAY,CACZ,WAAY,CACZ,UAAW","sourcesContent":["@import '../../../../styles/vars';\n@import '../../../../styles/mixins';\n\n.public-wrap {\n  height: 100%;\n  width: 100%;\n  margin: auto;\n  display: flex;\n}\n\n.public-body {\n  margin: auto;\n  height: 100%;\n  width: 100%;\n}\n\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"public-wrap": `src-components-auth-layouts-public-___public__public-wrap`,
	"publicWrap": `src-components-auth-layouts-public-___public__public-wrap`,
	"public-body": `src-components-auth-layouts-public-___public__public-body`,
	"publicBody": `src-components-auth-layouts-public-___public__public-body`
};
export default ___CSS_LOADER_EXPORT___;
