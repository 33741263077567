import React from 'react';
import PropTypes from 'prop-types';

const ImagePreview = ({ url }) => <img src={url} alt={url} />;

ImagePreview.propTypes = {
  url: PropTypes.string
};

export default ImagePreview;
