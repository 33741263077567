import React from 'react';
import PropTypes from 'prop-types';
import AddIcon from '@material-ui/icons/Add';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';

const AddButton = ({ onClick, tooltip, style = {} }) => (
  <IconButton onClick={onClick} style={{ padding: 0, ...style }}>
    <Tooltip title={tooltip}>
      <AddIcon />
    </Tooltip>
  </IconButton>
);

AddButton.propTypes = {
  onClick: PropTypes.func,
  style: PropTypes.object,
  tooltip: PropTypes.string
};

export default AddButton;
