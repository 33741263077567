/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useCallback, useContext } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import DetailsContext from '@components/workflow/details-context';
import LateLabel from '@shared/workflow/late-label';
import TaskStatusChip from '@shared/workflow/task-status-chip';
import { findTaskStatus, isLate } from '@utils/workflow-utils';
import './activity-row.scss';

const ActivityRow = ({ activity, onClick, taskDueDate }) => {
  const { agency: agencies, task_status: taskStatuses } = useSelector(state => state.dataTypes);
  const { activity: selectedActivity } = useContext(DetailsContext);
  const selected = activity.id === selectedActivity.id;

  const agency = agencies[activity.agency];
  const taskStatus = findTaskStatus(activity.status_name, taskStatuses);
  const late = isLate(activity, taskDueDate);
  const style = `row-container ${selected ? 'row-active' : ''}`;
  const labelStyle = `row-name-label ${late ? 'row-name-label-late' : ''}`;
  const handleClick = useCallback(() => onClick(activity), [activity, onClick]);

  return (
    <div onClick={handleClick} role="presentation" styleName={style}>
      <div styleName="row-name">
        <div styleName={labelStyle}>
          {agency.name}
        </div>
      </div>
      <div styleName="row-status">
        <TaskStatusChip taskStatus={taskStatus} />
        {late && <LateLabel />}
      </div>
    </div>
  );
};

ActivityRow.propTypes = {
  activity: PropTypes.object,
  onClick: PropTypes.func,
  taskDueDate: PropTypes.object
};

export default ActivityRow;
