import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { closeDashboardDialog } from '@actions/dashboard-actions';
import { clearGroupDate } from '@actions/groups-actions';
import * as dialog from '@constants/dialogs';
import Dialog from '@shared/dialogs/dialog';
import DialogActions from '@shared/dialogs/dialog-actions';

const RemoveDateRange = () => {
  const dispatch = useDispatch();
  const { activeDialogs } = useSelector(state => state.dashboard);
  const isDialogActive = activeDialogs[dialog.REMOVE_DATE_RANGE];

  const onClose = useCallback(
    () => dispatch(closeDashboardDialog(dialog.REMOVE_DATE_RANGE)),
    [dispatch]
  );

  const onClear = useCallback(
    () => {
      dispatch(closeDashboardDialog(dialog.REMOVE_DATE_RANGE));
      dispatch(clearGroupDate());
    },
    [dispatch]
  );

  if (!isDialogActive) {
    return null;
  }

  return (
    <Dialog
      actions={
        <DialogActions
          cancelLabel="NO"
          onCancel={onClose}
          onSubmit={onClear}
          submitLabel="YES"
        />
      }
      title="Clear date range"
    >
      By removing the date range you will bring in all records in existence in this area.<br />
      Are you sure you want to proceed?
    </Dialog>
  );
};

export default RemoveDateRange;
