import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { closeDashboardDialog } from '@actions/dashboard-actions';
import { setDatatableFilter } from '@actions/data-table-actions';
import { getEntityTypeLabel } from '@constants/config';
import * as dialog from '@constants/dialogs';
import { getFilters } from '@selectors/data-table-selector';
import BaseDateRangeDialog from '@shared/dialogs/base-date-range-dialog';
import { getDataType, getSubType } from '@utils/dashboard-utils';
import { decodeDateFilters } from '@utils/date-utils';

class DashboardDateRangeDialog extends BaseDateRangeDialog {
  UNSAFE_componentWillReceiveProps(nextProps) {
    // If we are about to open this dialog
    if ((this.props.activeDialogs[dialog.DATE_RANGE] === false ||
         typeof this.props.activeDialogs[dialog.DATE_RANGE] === 'undefined') &&
        nextProps.activeDialogs[dialog.DATE_RANGE] === true) {
      // Sync it to the current dashboard's filter state
      this.setState(this.getDefaultState());
    }
  }

  resetFilters = () => this.setState({
    start: null,
    end: null,
    contain: false
  });

  getSetFiltersFunction = () => this.props.setFilter.bind(this, this.props.dataType, this.props.subType);

  getDataTypeDisplayName = () => getEntityTypeLabel(this.props.dataType);

  // Don't show the 'fully contained' checkbox for Batch uploads:
  showContain = () => this.props.dataType !== 'batch';

  // When closing the dialog build a single date for Batch uploads:
  isMultiple = () => this.props.dataType !== 'batch';
}

DashboardDateRangeDialog.propTypes = {
  dataType: PropTypes.string,
  location: PropTypes.object,
  subType: PropTypes.string
};

export const mapStateToProps = (state, props) => {
  const dataType = getDataType(props.params, props.location);
  const subType = getSubType(dataType, props);
  const { activeDialogs, dialogParams } = state.dashboard;
  const { start, end, contain } = decodeDateFilters(getFilters(state, { dataType: props.dataType, subType }));
  return { activeDialogs, start, end, contain, subType, dialogParams: dialogParams[dialog.DATE_RANGE] };
};

export default connect(mapStateToProps, {
  closeDialog: closeDashboardDialog,
  setFilter: setDatatableFilter
})(DashboardDateRangeDialog);
