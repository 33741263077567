import React, { Fragment, memo, useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import styles from './data-card.scss';
import { Button } from '@material-ui/core';

const DataCard = ({
  borderRadius = false,
  children,
  className,
  compact = true,
  embedded,
  expand,
  expandDivider = false,
  expanded,
  headerIcon,
  headerText,
  icon,
  onClick,
  selected,
  title,
  ...passThroughProps
}) => {
  const [expandHeight, setExpandHeight] = useState(0);
  const belowRefCallback = useCallback(
    node => setExpandHeight((node && node.offsetHeight) || 0),
    [setExpandHeight]
  );

  const content = (
    <Fragment>
      <span className={styles.content}>
        {icon &&
          <span className={styles.icon}>
            {icon}
          </span>
        }
        <span className={styles.info}>
          {(headerIcon || headerText) &&
            <span className={styles.header}>
              {headerIcon &&
                <Fragment>
                  <span className={styles.headerIcon}>
                    {headerIcon}
                  </span>
                  {headerText}
                </Fragment>
              }
            </span>
          }
          {title &&
            <span className={styles.title}>
              {title}
            </span>
          }
          <span className={styles.children}>
            {children}
          </span>
        </span>
      </span>
      {expanded && expandHeight > 0 &&
        <span className={styles.expand} style={{ height: expandHeight }} />
      }
    </Fragment>
  );
  const expandContent = (
    <Fragment>
      {expanded && expand &&
        <span className={styles.expandContent} ref={belowRefCallback}>
          {expand}
        </span>
      }
    </Fragment>
  );

  const containerClasses = classNames(
    styles.container,
    {
      [styles.compact]: compact,
      [styles.selected]: selected && !embedded,
      [styles.expanded]: expanded,
      [styles.expandDivider]: expandDivider,
      [styles.borderRadius]: borderRadius,
      [className]: !embedded
    }
  );

  if (embedded) {
    return (
      <span className={classNames(styles.embeddedContainer, className)}>
        <span
          className={classNames(containerClasses)}
          {...passThroughProps}
        >
          {content}
        </span>
        {expandContent}
      </span>
    );
  }

  if (onClick) {
    return (
      <li className={styles.expandContainer}>
        <Button
          classes={{
            root: containerClasses,
            label: styles.buttonCardContents
          }}
          onClick={onClick}
          fullWidth
          {...passThroughProps}
        >
          {content}
        </Button>
        {expandContent}
      </li>
    );
  }

  return (
    <li className={styles.expandContainer}>
      <div
        className={classNames(containerClasses)}
        {...passThroughProps}
      >
        {content}
      </div>
      {expandContent}
    </li>
  );
};

DataCard.propTypes = {
  borderRadius: PropTypes.bool,
  children: PropTypes.node,
  className: PropTypes.string,
  compact: PropTypes.bool,
  embedded: PropTypes.bool,
  expand: PropTypes.node,
  expandDivider: PropTypes.bool,
  expanded: PropTypes.bool,
  headerIcon: PropTypes.node,
  headerText: PropTypes.string,
  icon: PropTypes.node,
  onClick: PropTypes.func,
  selected: PropTypes.bool,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node])
};

export default memo(DataCard);
