import React, { memo } from 'react';
import { capitalize } from 'lodash';
import PropTypes from 'prop-types';

import CloseIcon from '@material-ui/icons/Close';

import { getEntityTypeLabel, getExternalIdLabel } from '@constants/config';

import './batch-upload-drawer.scss';

const BatchUploadDrawer = ({ entity, onClose }) => (
  <div styleName="drawer-container">
    <div styleName="drawer-header-container">
      <div styleName="drawer-header-icon">
        <CloseIcon onClick={onClose} />
      </div>
      <div styleName="drawer-header-label">
        Before you upload
      </div>
    </div>

    <div styleName="drawer-item-title">
      Please use the correct column names:
    </div>
    <ul>
      <li>Coordinate will only accept column names provided in the template.</li>
    </ul>

    <div styleName="drawer-item-title">
      Must include a Unique Identifier column:
    </div>
    <ul>
      <li>
        File must include {capitalize(getEntityTypeLabel(entity))} ID or {getExternalIdLabel(entity)} column as the unique identifier.
        If both are included, {capitalize(getEntityTypeLabel(entity))} ID will be the unique identifier.
      </li>
      <li>
        Coordinate uses unique identifier to find existing entities in the system and
        upload multiple locations when creating new entities during batch uploads.
      </li>
      <li>
        When adding new entities only, include the identifier column but leave it empty.
      </li>
    </ul>

    <div styleName="drawer-item-title">
      Find existing entity:
    </div>
    <ul>
      <li>
        If an existing entity is found, Coordinate will update it with the uploaded data,
        but location data in the CSV will be ignored.
      </li>
      <li>
        If no existing entity is found, Coordinate will create a new entity using the uploaded data.
        You can upload multiple locations to a new entity.
      </li>
    </ul>

    <div styleName="drawer-item-title">
      Upload multiple locations to a new entity:
    </div>
    <ul>
      <li>
        In your CSV file, each row should represent a location.
        If multiple rows share the same unique identifier, Coordinate will combine these locations
        into a single entity, using the values from the first row for other attributes.
      </li>
    </ul>
  </div>
);

BatchUploadDrawer.propTypes = {
  entity: PropTypes.string,
  onClose: PropTypes.func
};

export default memo(BatchUploadDrawer);
