/* eslint-disable react/jsx-no-bind */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Draggable } from 'react-beautiful-dnd';
import * as colors from '@constants/colors';
import CloseIcon from '@material-ui/icons/Close';
import DragHandle from './drag-handle';
import './details-layout-drawer.scss';

const TaskTypeRow = ({ taskType, index, onDelete, selectedTaskType, setSelectedTaskType }) => {
  const [hovered, setHovered] = useState(false);
  const hoverOn = () => setHovered(true);
  const hoverOff = () => setHovered(false);
  const iconProps = { htmlColor: '#616161', style: { fontSize: '1.25rem', padding: '0 0 0 0.5rem' } };
  const isSelected = taskType.id === selectedTaskType.id;

  return (
    <Draggable key={index} draggableId={`draggable-${index}`} index={index}>
      {(provided, snapshot) => (
        <div
          {...provided.draggableProps}
          onMouseEnter={hoverOn}
          onMouseLeave={hoverOff}
          onClick={() => setSelectedTaskType(taskType)}
          ref={provided.innerRef}
          role="presentation"
          styleName={`section-row row row-clickable field ${isSelected ? 'field-active' : ''}`}
          style={{
            border: snapshot.isDragging ? `1px solid ${colors.dotmapsGrayBorder}` : 'none',
            width: '100%',
            ...provided.draggableProps.style
          }}
        >
          <div styleName="row-left field-container">
            <DragHandle provided={provided} />
            <div styleName={`field-label ${isSelected ? 'field-label-active' : ''}`}>
              {taskType.name}
            </div>
          </div>
          <div styleName="row-right second-text">
            {hovered && (
              <div styleName="row-action">
                <CloseIcon {...iconProps} onClick={() => onDelete(taskType)} />
              </div>
            )}
          </div>
        </div>
      )}
    </Draggable>
  );
};

TaskTypeRow.propTypes = {
  index: PropTypes.number,
  onDelete: PropTypes.func,
  selectedTaskType: PropTypes.object,
  setSelectedTaskType: PropTypes.func,
  taskType: PropTypes.object
};

export default TaskTypeRow;
