/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable react/display-name */
import React, { Fragment, memo, useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import scrollIntoView from 'smooth-scroll-into-view-if-needed';
import PropTypes from 'prop-types';
import { replace } from 'connected-react-router';
import { scrollToField } from '@actions/data-detail-actions';
import AssignmentIcon from '@material-ui/icons/Assignment';
import DefaultDate from '@shared/formatted-date-helper';
import DataCard from '@shared/ui-library/card/data-card';
import AttributeLabelContainer from '@shared/ui-library/attribute/attribute-label-container';
import {
  getDefaultActivity,
  goToDefaultTask,
  isSingleFooter,
  renderStatusItem
} from '@utils/workflow-utils';

const TaskItemCard = ({ task }) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const { taskActivityId, taskId } = useParams();
  const { scrollId } = useSelector(state => state.dataDetail);
  const { agency: agencies, task_status: taskStatuses } = useSelector(state => state.dataTypes);
  const { task_type: taskType } = useSelector(state => state.dataTypes);

  const scrollToId = useMemo(() => `task-card-${task.id}`, [task.id]);

  const scroll = useCallback(() => {
    // Check if either scrollId is set or go to the task id in the URL.
    if (scrollId === scrollToId || `task-card-${taskId}` === scrollToId) {
      const element = document.getElementById(scrollToId);
      scrollIntoView(element, { scrollMode: 'if-needed' });
      // Clear the scroll id in the store, since we executed the scroll action.
      dispatch(scrollToField(null));
    }
  }, [dispatch, scrollId, scrollToId, taskId]);

  useEffect(() => {
    scroll();
  }, [scroll]);

  const selected = useMemo(() => {
    // Build the path for this task-card to check if it's selected:
    const path = `/cycle/${task.cycle}/task/${task.id}/`;
    // Get the current location pathname and add a trailing slash if it's not
    // already there, for comparison:
    const url = location.pathname.replace(/\/?$/, '/');
    return url.startsWith(path);
  }, [location.pathname, task.cycle, task.id]);

  const goToTask = useCallback(() => {
    if (!selected) {
      goToDefaultTask(task, url => dispatch(replace(url)));
    }
  }, [dispatch, selected, task]);

  const renderAgencyStatus = () => {
    const activity = getDefaultActivity(task, taskActivityId);
    if (!activity) {
      return null;
    }
    const agencyName = agencies[activity.agency].name;
    return renderStatusItem(taskStatuses, activity.status_name, agencyName);
  };

  const renderCountStatus = name => {
    const count = task?.activities?.filter(activity => activity.status_name === name).length;
    return renderStatusItem(taskStatuses, name, count);
  };

  return (
    <DataCard
      borderRadius={false}
      icon={<AssignmentIcon />}
      onClick={goToTask}
      selected={selected}
      title={task.name}
    >
      <span id={scrollToId}>
        {taskType[task.task_type]?.name}
        {task.due_date && <span>&nbsp;&bull;&nbsp;Due&nbsp;<DefaultDate value={task.due_date} /></span>}
      </span>
      <AttributeLabelContainer>
        {isSingleFooter(task) ? renderAgencyStatus() : (
          <Fragment>
            {renderCountStatus('Assigned')}
            {renderCountStatus('In progress')}
            {renderCountStatus('Submitted')}
            {renderCountStatus('Completed')}
          </Fragment>
        )}
      </AttributeLabelContainer>
    </DataCard>
  );
};

TaskItemCard.propTypes = {
  task: PropTypes.object
};

export default memo(TaskItemCard);
