import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { flattenDeep } from 'lodash';
import { openDashboardDialog } from '@actions/dashboard-actions';
import { getDashboardMenuConfig } from '@constants/config';
import * as dialog from '@constants/dialogs';
import Button from '@material-ui/core/Button';
import './header.scss';

// TODO: Remove the "S" from "CREATE WORKFLOWS"
const Header = ({ dataType }) => {
  const dispatch = useDispatch();
  const label = flattenDeep(getDashboardMenuConfig().map(section => section.items)).find(item => item.type === dataType).label;
  const openDialog = useCallback(
    () => dispatch(openDashboardDialog(dialog.NAME_DESCRIPTION, { dataType, portal: 'admin' })),
    [dataType, dispatch]
  );
  return (
    <div data-testid="header" styleName="header-container">
      <div styleName="title">
        {label}
      </div>
      <div styleName="action">
        <Button
          color="primary"
          onClick={openDialog}
          variant="contained"
        >
          CREATE {label}
        </Button>
      </div>
    </div>
  );
};

Header.propTypes = {
  dataType: PropTypes.string
};

export default Header;
