import {
  ATTACHMENT_CLOSE_PREVIEW,
  ATTACHMENT_DELETE_STORED_SUCCESS,
  ATTACHMENT_DELETE_SUCCESS,
  ATTACHMENT_FETCH_SUCCESS,
  ATTACHMENT_FETCH_ERROR,
  ATTACHMENT_LOADING,
  ATTACHMENT_SHOW_PREVIEW,
  ATTACHMENT_STORE_CLEAR,
  ATTACHMENT_STORE_SUCCESS,
  ATTACHMENT_TEMPFILE_DELETE_SUCCESS,
  ATTACHMENT_UPLOAD_SUCCESS,
  ATTACHMENT_UPLOAD_ERROR
} from '@constants/action-types';
import axios from 'axios';
import { BASE_API_URL, getAPIRequestUrl } from '@constants/endpoints';
import { fetchDataDetail } from './data-detail-actions';
import { pushApplicationMessage } from './messages-actions';
import { getServerErrorAction } from './shared-actions';

const uploadSuccess = (payload, type, objectId, replace = false) => dispatch => {
  dispatch({
    type: ATTACHMENT_UPLOAD_SUCCESS,
    payload,
    contentType: type,
    objectId,
    replace
  });
  return Promise.resolve();
};

const uploadError = (error, type, objectId) => ({
  type: ATTACHMENT_UPLOAD_ERROR,
  payload: error,
  contentType: type,
  objectId
});

export const uploadAttachment = (attachment, onProgress, onError, replace = false) => {
  const url = getAPIRequestUrl('upload');
  const formData = new FormData();
  formData.append('file_name', attachment.fileName);
  formData.append('content_type', attachment.contentType);
  formData.append('object_id', attachment.objectId);
  formData.append('file', attachment.file);
  formData.append('replace', replace);
  const config = { onUploadProgress: onProgress };
  const request = axios.post(url, formData, config);
  return dispatch =>
    request.then(
      data => {
        dispatch(uploadSuccess(data, attachment.contentType, attachment.objectId, replace)).then(() => {
          if (attachment.contentType === 'task_activity') {
            dispatch(fetchDataDetail('task', attachment.parentId));
          }
        });
      },
      error => {
        const errorWrapper = {
          message: 'Server Error'
        };
        if (error.response) {
          errorWrapper.message = error.response.data.message;
        } else if (error.request) {
          errorWrapper.message = error.request;
        } else {
          errorWrapper.message = error.message;
        }
        dispatch(uploadError(errorWrapper, attachment.contentType, attachment.objectId));
        dispatch(getServerErrorAction('attachment', errorWrapper));
        onError(errorWrapper.message);
      }
    );
};

const fetchAttachmentsSuccess = (payload, contentType, objectId) => ({
  type: ATTACHMENT_FETCH_SUCCESS,
  payload,
  contentType,
  objectId
});

const fetchAttachmentsError = (payload, contentType, objectId) => ({
  type: ATTACHMENT_FETCH_ERROR,
  payload,
  contentType,
  objectId
});

const setAttachmentsLoadingAction = () => dispatch => {
  dispatch({ type: ATTACHMENT_LOADING });
  return Promise.resolve();
};

export const getAttachments = (contentType, objectId, isPublic = false) => dispatch => {
  return dispatch(setAttachmentsLoadingAction()).then(() => {
    const url = getAPIRequestUrl(`documents/${isPublic ? 'public_' : ''}${contentType}/${objectId}`);
    const request = axios.get(url);
    request.then(
      data =>
        dispatch(fetchAttachmentsSuccess(data, contentType, objectId)),
      error =>
        dispatch(fetchAttachmentsError(error, contentType, objectId))
    );
  });
};

const showAttachmentPreview = (contentType, objectId, index) => ({
  type: ATTACHMENT_SHOW_PREVIEW,
  contentType,
  objectId,
  index
});

export const showFilePreview = (contentType, objectId, index) => dispatch => dispatch(showAttachmentPreview(contentType, objectId, index));

export const closeFilePreview = () => dispatch => dispatch({ type: ATTACHMENT_CLOSE_PREVIEW });

const deleteSuccess = payload => ({ type: ATTACHMENT_DELETE_SUCCESS, payload });

export const deleteAttachment = (id, reload) => {
  const url = `${BASE_API_URL}/upload/${id}/`;
  const request = axios.delete(url);
  return dispatch =>
    request.then(
      () => {
        dispatch(deleteSuccess(id));
        dispatch(pushApplicationMessage('The selected file has been successfully deleted.'));
        if (reload) {
          const { dataType, id: reloadId } = reload;
          dispatch(fetchDataDetail(dataType, reloadId));
        }
      },
      error => dispatch(getServerErrorAction('attachment', error))
    );
};

export const bulkDeleteAttachments = ids => {
  const url = `${BASE_API_URL}/upload/?ids=${ids}`;
  const request = axios.delete(url);
  return dispatch =>
    request.then(
      () => {
        ids.forEach(id => dispatch(deleteSuccess(id)));
        dispatch(pushApplicationMessage('The selected files have been successfully deleted.'));
      },
      error => dispatch(getServerErrorAction('attachment', error))
    );
};

const deleteTempFileSuccess = (objectId, fileName, contentType) => ({
  type: ATTACHMENT_TEMPFILE_DELETE_SUCCESS,
  objectId,
  fileName,
  contentType
});

export const deleteTemporaryAttachment = (contentType, objectId, fileName = null) => {
  const url = `${BASE_API_URL}/attachments/deletetemp/`;
  const requestData = {
    content_type: contentType,
    object_id: objectId,
    file_name: fileName
  };
  const request = axios.post(url, { ...requestData });
  return dispatch =>
    request.then(
      () => {
        dispatch(deleteTempFileSuccess(objectId, fileName, contentType));
        dispatch(pushApplicationMessage('The selected file has been successfully deleted.'));
      },
      error => dispatch(getServerErrorAction('attachment', error))
    );
};

export const renameAttachments = (oldObjectId, newObjectId, contentType) => {
  const url = `${BASE_API_URL}/attachments/rename/`;
  const requestData = {
    content_type: contentType,
    old_object_id: oldObjectId,
    new_object_id: newObjectId
  };
  const request = axios.post(url, { ...requestData });
  return dispatch =>
    request.then(
      () => dispatch(getAttachments(contentType, newObjectId)),
      error => dispatch(getServerErrorAction('attachment', error))
    );
};

const deleteStoredSuccess = id => ({ type: ATTACHMENT_DELETE_STORED_SUCCESS, id });

export const deleteStoredAttachment = id => dispatch => dispatch(deleteStoredSuccess(id));

const storeAttachmentSuccess = (attachment, type, objectId) => dispatch => {
  dispatch({
    type: ATTACHMENT_STORE_SUCCESS,
    attachment,
    contentType: type,
    objectId
  });
  return Promise.resolve();
};

export const storeAttachment = (attachment, onProgress) => dispatch => {
  const formData = new FormData();
  formData.append('file_name', attachment.fileName);
  formData.append('content_type', attachment.contentType);
  formData.append('object_id', attachment.objectId);
  formData.append('file', attachment.file);
  dispatch(storeAttachmentSuccess(formData, attachment.contentType, attachment.objectId)).then(
    onProgress()
  );
};

const clearStoredAttachments = (type, objectId) => dispatch => {
  dispatch({
    type: ATTACHMENT_STORE_CLEAR,
    contentType: type,
    objectId
  });
};

export const uploadStoredAttachments = (contentType, objectId, isPublic = false) => (dispatch, getState) => {
  const id = `${contentType}${objectId}`;
  const url = isPublic ? getAPIRequestUrl('public_upload') : getAPIRequestUrl('upload');
  const attachments = getState().attachments.preUploadAttachments;
  if (attachments && attachments[id] && attachments[id].length > 0) {
    attachments[id].forEach((attachment) => {
      const request = axios.post(url, attachment);
      request.then(
        data => {
          dispatch(uploadSuccess(data, contentType, objectId));
          dispatch(clearStoredAttachments(contentType, objectId));
        },
        error => {
          const errorWrapper = {
            message: 'Server Error'
          };
          if (error.response) {
            errorWrapper.message = error.response.data.message;
          } else if (error.request) {
            errorWrapper.message = error.request;
          } else {
            errorWrapper.message = error.message;
          }
          dispatch(uploadError(errorWrapper, contentType, objectId));
          dispatch(getServerErrorAction('attachment', errorWrapper));
        }
      );
    });
  }
  return Promise.resolve();
};

export const saveAttachment = (id, payload) => async dispatch => {
  try {
    const url = `${BASE_API_URL}/upload/${id}/`;
    const response = await axios.patch(url, {
      file_name: payload.name,
      description: payload.description
    });
    const { object_id } = response.data;
    dispatch(getAttachments('entity', object_id));
    dispatch(pushApplicationMessage(`The "${payload.name}" file has been successfully updated.`));
  } catch (error) {
    dispatch(getServerErrorAction('attachment', error));
  }
};
