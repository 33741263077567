/* eslint-disable no-fallthrough */
import {
  ENTITY_FETCH_SUCCESS,
  ENTITY_ADD_SINGLE,
  GET_GROUP_BY_ID_SUCCESS,
  LAYER_FETCH_SUCCESS,
  SET_LAYER_VISIBLE,
  MAP_VIEWPORT_CHANGE,
  MAP_TOGGLE_MAP_FILTER_TOGGLE
} from '@constants/action-types';
import { getMarkerVisibleZoom } from '@constants/config';
import {
  getOverlappingMarkers,
  isAnyMapItemLoading
} from '@selectors/map-selector';
import { getGroupOverlappingMarkers } from '@selectors/groups-selector';

export const rootMarkerReducer = (state, action) => {
  // If the action is from group's page or a view port change:
  if (action.type === MAP_VIEWPORT_CHANGE || action.type === GET_GROUP_BY_ID_SUCCESS) {
    const source = action.payload?.source;
    const isGantt = action.payload?.isGantt;
    // !source means it was a GET_GROUP_BY_ID_SUCCESS action, else it's a viewport change
    // from the groups page, thus recalculate group map markers:
    if (!isGantt && (!source || source === 'group')) {
      if (state.map.viewport.zoom < getMarkerVisibleZoom() || state.groups.loading || state.groups.edit.loading) {
        // Don't calculate the markers for zoom levels below 15 or if we are still loading items:
        return state;
      }
      const markers = getGroupOverlappingMarkers(state);
      return {
        ...state,
        groups: {
          ...state.groups,
          markers
        }
      };
    }
  }

  // Now check for actions that should recalculate the main map markers:
  switch (action.type) {
  case MAP_VIEWPORT_CHANGE:
  case ENTITY_FETCH_SUCCESS:
  case ENTITY_ADD_SINGLE:
  case LAYER_FETCH_SUCCESS:
  case SET_LAYER_VISIBLE:
  case MAP_TOGGLE_MAP_FILTER_TOGGLE: {
    const source = action.payload?.source;
    // Again, !source means it wasn't a MAP_VIEWPORT_CHANGE action, but if it was
    // a MAP_VIEWPORT_CHANGE one, the source should be "map" in order to recalculate
    // the main map markers.
    if (!source || source === 'map') {
      if (state.map.viewport.zoom < getMarkerVisibleZoom() || isAnyMapItemLoading(state)) {
        // Don't calculate the markers for zoom levels below 15 or if we are still loading items:
        return state;
      }
      // Recalculate the marker groups when we modify the entities or layers lists,
      // or the map's viewport zoom changes:
      const markers = getOverlappingMarkers(state);
      return {
        ...state,
        markers
      };
    }
  }
  default:
    return state;
  }
};
