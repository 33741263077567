import React from 'react';
import PropTypes from 'prop-types';
import { colors } from '@constants/colors';
import { Select } from '@mui';
import { makeStyles } from '@material-ui/core/styles';

const useInputStyles = makeStyles({
  formControl: ({ style }) => ({
    borderRadius: '2px',
    fontSize: '0.875rem',
    height: '34px',
    lineHeight: '34px',
    margin: '8px 16px 8px 16px',
    padding: '0 0 0 8px',
    maxWidth: '19em',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    ...style.formControl,
    'label + &': {
      marginTop: '8px'
    },
    '& .MuiSelect-selectMenu': {
      color: style?.formControl?.color || colors.opacity.black0_8
    },
    // Make the dropdown caret icon match the selected value
    // color, only if that color is set.
    '& .MuiSelect-icon': {
      ...(style?.formControl?.color && {
        color: style?.formControl?.color
      })
    }
  })
});

const useMenuStyles = makeStyles({
  paper: {
    padding: '0.5rem 0',
    maxHeight: '25rem',
    minWidth: '15rem !important'
  }
});

// Select component customized for the filters in the navigation
// bar of the library.
const FilterSelect = ({
  children,
  isValueSelected,
  labelStyles = {},
  ...other
}) => {
  const inputClasses = useInputStyles({
    style: {
      formControl: {
        ...(
          isValueSelected ? {
            backgroundColor: 'rgba(0,0,0,0.1)',
            ...labelStyles
          } : {
            ...labelStyles
          })
      }
    }
  });
  const menuClasses = useMenuStyles();

  return (
    <Select
      InputProps={{
        classes: { ...inputClasses },
        disableUnderline: true
      }}
      MenuProps={{
        classes: { ...menuClasses }
      }}
      {...other}
    >
      {children}
    </Select>
  );
};

FilterSelect.propTypes = {
  children: PropTypes.node,
  // Flag to apply a special style if the dropdown has any selected value.
  isValueSelected: PropTypes.bool,
  labelStyles: PropTypes.object
};

export default FilterSelect;
