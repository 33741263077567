import React, { useContext } from 'react';
import { useSelector } from 'react-redux';
import DetailsContext from '@components/workflow/details-context';
import './comments-body.scss';

const CommentsSubheader = () => {
  const { activity, task } = useContext(DetailsContext);
  const { agency: agencies } = useSelector(state => state.dataTypes);
  return (
    <div styleName="subheader-container">
      Shared between&nbsp;
      <div styleName="bold-text">
        {agencies[activity.agency].name}
      </div>
      &nbsp;and&nbsp;
      <div styleName="bold-text">
        {task.owner_agency_name || 'None'}
      </div>
    </div>
  );
};

export default CommentsSubheader;
