import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { dotmapsBlue } from '@constants/colors';
import { topBarStyles } from '@constants/mui-theme';
import LoaderIcon from '@shared/loader-icon';

// Wrapper to display an IconButton (the children prop), taking
// care of the downloading state (in that case, we display a loader
// icon instead).
const LoaderIconWrapper = ({ children, downloading, loaderColor }) => {
  if (downloading > 0) {
    return (
      <div style={topBarStyles.loaderIconWrapper}>
        <LoaderIcon color={loaderColor} />
      </div>
    );
  }
  return children;
};

LoaderIconWrapper.propTypes = {
  children: PropTypes.node,
  downloading: PropTypes.number,
  loaderColor: PropTypes.string
};

LoaderIconWrapper.defaultProps = {
  loaderColor: dotmapsBlue
};

const mapStateToProps = state => {
  const { downloading } = state.map;
  return { downloading };
};

export default connect(mapStateToProps, {})(LoaderIconWrapper);
