/* eslint-disable react/jsx-no-bind */
import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import {
  dotmapsBackgroundError,
  dotmapsError,
  dotmapsGrayBorder
} from '@constants/colors';
import { Icon } from '@mui';
import './file-area.scss';

const getFileFromChangeEvent = R.pathOr(null, ['target', 'files', 0]);
const getFileFromDropEvent = R.pathOr(null, ['dataTransfer', 'files', 0]);

const FileArea = ({ accept, hasError, id, onFileClear, onFileSelect }) => {
  const [file, setFile] = useState(null);
  const fileName = file?.name;
  const fileInput = useRef(null);

  const onClick = event => {
    if (fileInput) {
      fileInput.current.click(event);
    }
  };

  const onClear = event => {
    event.stopPropagation();
    setFile(null);
    onFileClear();
  };

  const onChange = event => {
    if (event.target.files.length === 0) {
      // If len == 0, the user cancelled the file upload dialog.
      onClear(event);
    } else {
      const newFile = getFileFromChangeEvent(event);
      setFile(newFile);
      onFileSelect(newFile);
    }
  };

  const onDragOver = event => {
    event.preventDefault();
    event.stopPropagation();
  };

  const onDrop = event => {
    const newFile = getFileFromDropEvent(event);
    setFile(newFile);
    onFileSelect(newFile);
    event.preventDefault();
    event.stopPropagation();
  };

  const getButtonStyles = () => {
    if (fileName) {
      if (hasError) {
        return { backgroundColor: dotmapsBackgroundError };
      }
      return { backgroundColor: dotmapsGrayBorder };
    }
    return {};
  };

  const getCloseButtonProps = () => {
    const props = { onClick: onClear };
    if (hasError) {
      props.color = dotmapsError;
    }
    return props;
  };

  const getTextStyleName = () => {
    let styleName = null;
    if (fileName) {
      styleName = 'name-text';
    } else {
      styleName = 'name-text-placeholder';
    }
    if (hasError) {
      styleName += ' name-error';
    }
    return styleName;
  };

  return (
    <div styleName="file-area-wrapper">
      <button
        onClick={onClick}
        onDragOver={onDragOver}
        onDrop={onDrop}
        style={{...getButtonStyles()}}
      >
        <div styleName="file-area">
          <div styleName={getTextStyleName()}>
            {fileName || 'CHOOSE A FILE'}
          </div>
          {fileName && (
            <div styleName="clear-button">
              <Icon {...getCloseButtonProps()}>close</Icon>
            </div>
          )}
        </div>
        <input
          accept={accept}
          id={id}
          ref={fileInput}
          onChange={onChange}
          type="file"
          hidden
        />
      </button>
    </div>
  );
};

FileArea.propTypes = {
  accept: PropTypes.string,
  hasError: PropTypes.bool,
  id: PropTypes.string,
  onFileClear: PropTypes.func,
  onFileSelect: PropTypes.func
};

export default FileArea;
