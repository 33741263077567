/* eslint-disable guard-for-in */
import React from 'react';
import { includes, isEmpty } from 'lodash';
import * as R from 'ramda';
import moment from 'moment-timezone';
import abbrevWeekdayRange from 'abbrev-weekday-range';
import { dotmapsOrangeWarning } from '@constants/colors';
import { scheduleTypes } from '@constants/component-configs';
import { getScheduleEnabledList, isMilitaryTime } from '@constants/config';
import { Icon } from '@mui';
import {
  formatLocationDate,
  formatLocationDateTime
} from '@utils/segment-schedule/dates';
import './segment-schedule.scss';

// Weekday data structure:
export const SEGMENT_SCHEDULE_RECURRENCE_WEEKDAYS = [
  {id: 'monday', number: 0, name: 'Monday', abbr: 'M', abbr3: 'Mon'},
  {id: 'tuesday', number: 1, name: 'Tuesday', abbr: 'Tu', abbr3: 'Tue'},
  {id: 'wednesday', number: 2, name: 'Wednesday', abbr: 'W', abbr3: 'Wed'},
  {id: 'thursday', number: 3, name: 'Thursday', abbr: 'Th', abbr3: 'Thu'},
  {id: 'friday', number: 4, name: 'Friday', abbr: 'F', abbr3: 'Fri'},
  {id: 'saturday', number: 5, name: 'Saturday', abbr: 'Sa', abbr3: 'Sat'},
  {id: 'sunday', number: 6, name: 'Sunday', abbr: 'Su', abbr3: 'Sun'}
];

// List of weekdays by week day id:
export const SEGMENT_SCHEDULE_RECURRENCE_WEEKDAYS_ID_ARRAY = SEGMENT_SCHEDULE_RECURRENCE_WEEKDAYS.map(weekday => weekday.id);

export const isStartTimeSet = start => !(typeof start === 'undefined' || start === null || start === 0);

// Returns true if it's an overnight hour.
export const isNextDay = (start, hour) => {
  // If there's no start time, we won't know if the hour is for the next day.
  if (!isStartTimeSet(start) ||
      start === 0 ||
      hour === 0) {  // Don't flag 00:00 as next day.
    return false;
  }
  if (hour <= start) {
    return true;
  }
  return false;
};

export const isNextDayDateTime = (startTime, endTime) => {
  const start = parseInt(moment(startTime, 'HH:mm').format('HH'), 10);
  const end = parseInt(moment(endTime, 'HH:mm').format('HH'), 10);
  return isNextDay(start, end);
};


// Helper method to print the segment title
// (we use the title if it exists, else we use the on_street
// field, and if both are empty we show the 'undefined' message.
export const getSegmentTitle = segment => {
  if (segment.title) {
    return segment.title;
  }
  if (segment.shape && segment.shape.type === 'Polygon') {
    return 'Polygon';
  }
  if (segment.from_street && segment.to_street && segment.on_street) {
    return `${segment.from_street} to ${segment.to_street} on ${segment.on_street}`;
  }
  if (segment.from_address && segment.to_address && segment.on_street) {
    return `${segment.from_address} to ${segment.to_address} ${segment.on_street}`;
  }
  if (segment.display_from && !segment.display_to) {
    return segment.display_from;
  }
  if (segment.on_street) {
    return segment.on_street;
  }
  return 'UNDEFINED STREET';
};


const abbrevWeekdays = (weekNumbers, letters) => {
  const options = {
    // Use custom format (since the library one starts on Sunday):
    format: SEGMENT_SCHEDULE_RECURRENCE_WEEKDAYS.map(weekday => letters === 2 ? weekday.abbr : weekday.abbr3)
  };

  // Abbreviate ranges (like M-F, etc):
  return abbrevWeekdayRange(weekNumbers, options);
};

// Format the recurrence weekdays sequence (taking care of consecutive days):
export const formatRecurrenceWeekdays = recurrence => {
  const weekNumbers = [];
  SEGMENT_SCHEDULE_RECURRENCE_WEEKDAYS.forEach(weekday => {
    if (recurrence[weekday.id]) {
      weekNumbers.push(weekday.number);
    }
  });

  return abbrevWeekdays(weekNumbers, 3);
};

// Group the recurrences by weekday:
export const groupRecurrences = recurrences => {
  const groupedRecurrences = {};
  if (!isEmpty(recurrences)) {
    SEGMENT_SCHEDULE_RECURRENCE_WEEKDAYS.forEach(weekday => {
      recurrences.forEach(recurrence => {
        if (recurrence[weekday.id]) {
          if (!groupedRecurrences[weekday.number]) {
            groupedRecurrences[weekday.number] = [];
          }
          groupedRecurrences[weekday.number].push({
            day: weekday,
            recurrence
          });
        }
      });
    });
  }
  return groupedRecurrences;
};

export const getUniqueWeekdays = recurrences => {
  // Group recurrences by weekday, to obtain the unique weekdays between all recurrences.
  const groupedRecurrences = groupRecurrences(recurrences);

  // Get the weekday numbers (they are strings, so convert to int):
  const weekNumbers = Object.keys(groupedRecurrences).map(num => parseInt(num, 10))
    .sort();

  return weekNumbers;
};

const getOvernightWeekdays = recurrences => {
  const overnightWeekdays = [];
  if (!isEmpty(recurrences)) {
    SEGMENT_SCHEDULE_RECURRENCE_WEEKDAYS.forEach(weekday => {
      recurrences.forEach(recurrence => {
        if (recurrence[weekday.id]) {
          overnightWeekdays.push(weekday.number);
          if (isNextDayDateTime(recurrence.start_time, recurrence.end_time)) {
            const nextDay = weekday.number === 6 ? 0 : weekday.number + 1;
            overnightWeekdays.push(nextDay);
          }
        }
      });
    });
  }
  return overnightWeekdays;
};

export const getUniqueWeekdaysWithOvernight = recurrences => {
  const uniqueWeekdays = getUniqueWeekdays(recurrences);
  const overnightWeekdays = getOvernightWeekdays(recurrences);
  return R.uniq([...uniqueWeekdays, ...overnightWeekdays]);
};

const renderScheduleWeekdays = recurrences => {
  const weekNumbers = getUniqueWeekdays(recurrences);

  // Now show the abbreviated unique weekdays, taking care of consecutive days:
  return abbrevWeekdays(weekNumbers, 2);
};

// Render the schedule string (for example 'On M-F between M/D/YYYY and M/D/YYYY'):
export const renderScheduleString = schedules => {
  const schedule = schedules[0];
  const hasExceptions = !isEmpty(schedule.exceptions);
  if (schedule.type === scheduleTypes.oneTime.value) {
    return (
      <div styleName="schedule-string">
        <div styleName="item">From</div>
        <div styleName="item bolder">
          {schedule.all_day ? formatLocationDate(schedule.start_date) : formatLocationDateTime(schedule.start_date, isMilitaryTime())}
        </div>
        <div styleName="item">to</div>
        <div styleName="item bolder">
          {schedule.all_day ? formatLocationDate(schedule.end_date) : formatLocationDateTime(schedule.end_date, isMilitaryTime())}
        </div>
      </div>
    );
  }
  return (
    <div styleName="schedule-string">
      <div styleName="item">On</div>
      <div styleName="item bolder">{renderScheduleWeekdays(schedule.recurrences)}</div>
      <div styleName="item">between</div>
      <div styleName="item bolder">{formatLocationDate(schedule.start_date)}</div>
      <div styleName="item">and</div>
      <div styleName="item bolder">{formatLocationDate(schedule.end_date)}</div>
      {hasExceptions && <div styleName="item">(with exceptions)</div>}
    </div>
  );
};

// Render the schedule string for when there's no schedule (we only render
// the start/end dates of the entity).
export const renderEmptyScheduleString = entity => {
  return (
    <div styleName="schedule-string">
      <div styleName="item bolder">{formatLocationDate(entity.start_date)}</div>
      <div styleName="item">and</div>
      <div styleName="item bolder">{formatLocationDate(entity.end_date)}</div>
    </div>
  );
};

// Tells whether the recurrence is complete or not
// (i.e. it has any weekday set and start/end times).
const isRecurrenceComplete = recurrence => {
  if (
    (isEmpty(recurrence.start_time) || isEmpty(recurrence.end_time)) && !recurrence.all_day
  ) {
    return false;
  }
  // Check to see if any weekday is set:
  for (const id in SEGMENT_SCHEDULE_RECURRENCE_WEEKDAYS_ID_ARRAY) {
    const weekday = SEGMENT_SCHEDULE_RECURRENCE_WEEKDAYS_ID_ARRAY[id];
    if (recurrence[weekday]) {
      return true;
    }
  }
  return false;
};

// Tells if the schedule has enough fields to let us set it.
// (i.e. if there are no recurrences or the recurrences have no days selected).
export const isScheduleComplete = segment => {
  if (isEmpty(segment.schedules)) {
    return false;  // No schedules, thus not complete.
  }

  const schedule = segment.schedules[0];

  // Lodash's isEmpty() returns true for non-empty Date objects, and Ramda returns true
  // for 'null', thus check for empty dates manually:
  if (schedule.start_date === null ||
      typeof schedule.start_date === 'undefined' ||
      schedule.end_date === null ||
      typeof schedule.end_date === 'undefined'
  ) {
    return false;  // No start/end dates.
  }

  // For recurring schedules, check if recurrences are set:
  if (schedule.type === scheduleTypes.recurring.value) {
    if (isEmpty(schedule.recurrences)) {
      return false;
    }
    // Returns true if all recurrences are complete:
    return schedule.recurrences.every(recurrence => isRecurrenceComplete(recurrence));
  }

  // Else, it's an one-time schedule, thus it's complete.
  // (we don't need to check for 'all_day' since the component that handles this,
  // won't fill start/end time fields until we set the start/end times when 'all_day'
  // is not set).
  return true;
};

// Tells if we should show a warning message on location cards if start or end dates are missing.
export const showMissingDatesWarning = (start, end) => {
  return isEmpty(start) || isEmpty(end);
};

// Tells if we should show a warning when the schedule exceeds the main entity dates:
export const showScheduleExceedsWarning = (start, end, segment) => {
  // If something is empty, we can't check, thus don't show the warning:
  if (isEmpty(start) ||
      isEmpty(end) ||
      isEmpty(segment) ||
      isEmpty(segment.schedules)) {
    return false;
  }
  const schedule = segment.schedules[0];
  if (isEmpty(schedule) ||
      isEmpty(schedule.start_date) ||
      isEmpty(schedule.end_date)) {
    return false;
  }
  // If the schedule dates are not within the entity dates, issue the warning:
  const scheduleStartDate = moment(schedule.start_date);
  const scheduleEndDate = moment(schedule.end_date);

  return !(scheduleStartDate.isSameOrAfter(start) && scheduleEndDate.isSameOrBefore(end));
};

// Checks that all the schedules are contained in the entity.
// It's like showScheduleExceedsWarning(), which checks a single segment, but this one
// checks all segments, since it's intended to show an entity level message.
export const showEntityScheduleContainsWarning = (start, end, segments) => {
  if (isEmpty(start) ||
      isEmpty(end) ||
      isEmpty(segments)) {
    return false;
  }
  return segments.some(segment => showScheduleExceedsWarning(start, end, segment));
};

// Checks if the filter date range encompass the entity range, in order to know
// if we must show a warning.
export const showEntityScheduleDateFilterWarning = (filter, entity) => {
  if (isEmpty(filter) || isEmpty(entity)) {
    return false;
  }

  let { min_date: start, max_date: end } = entity;
  // This is not a mistake, "end_date__gte" is the start date
  // and "start_date__lte" the end one, for example:
  //
  //     end_date__gte: '2018-12-04'
  //     start_date__lte: '2019-12-04'
  //
  let { end_date__gte: filterStart, start_date__lte: filterEnd } = filter;

  if (isEmpty(start) ||
      isEmpty(end) ||
      isEmpty(filterStart) ||
      isEmpty(filterEnd)) {
    return false;
  }

  // Convert to moment():
  start = moment(start);
  end = moment(end);
  filterStart = moment(filterStart);
  filterEnd = moment(filterEnd);

  if (
    start.isBefore(filterStart) ||
    end.isAfter(filterEnd)
  ) {
    return true;
  }

  return false;
};

// Helper method to render warning sections:
export const renderWarning = text => (
  <div styleName="warning">
    <Icon color={dotmapsOrangeWarning} styleName="icon">error</Icon>
    <div styleName="bolder">Warning:&nbsp;</div>
    <div>{text}</div>
  </div>
);

// Like renderWarning() but without the background and 'Warning' text.
export const renderSmallWarning = text => (
  <div styleName="small-warning">
    <Icon color={dotmapsOrangeWarning} styleName="icon">error</Icon>
    <div>{text}</div>
  </div>
);

export const isScheduleEnabled = entityType => {
  const enabledList = getScheduleEnabledList();
  return includes(enabledList, entityType);
};
