import axios from 'axios';
import { HANDLE_SERVER_ERROR } from '@constants/action-types';
import { isOldIE } from '@utils/browser-detect';

// Generic action to handle axios requests server errors:
export const getServerErrorAction = (type, error) => ({
  type: HANDLE_SERVER_ERROR,
  payload: { error, type }
});

// Action to use when we need an action but it shouldn't do anything.
// eslint-disable-next-line no-unused-vars
export const noopAction = () => dispatch => Promise.resolve();

// Old browsers like IE11 and below have a limit of 2000 characters for HTTP GET requests.
// The Radius tool generates a requests longer than that, because it uses too many coordinates
// (they are set on the geom_in/geom_not_in parameter).
//
// This method takes care of that, by detecting old IE browsers, converting the request
// into a POST one and adding a flag for the backend to interpret the request correctly as
// a GET one.
export const fetchData = (url, cancel = false) => {
  const CancelToken = cancel ? axios.CancelToken : null;
  const source = cancel ? CancelToken.source() : null;
  if (isOldIE()) {
    const parts = url.split('?');
    // URL get parameters are converted into POST body parameters
    // and we add new GET parameters to flag the backend middleware
    // that it should internally interpret it as a GET request (the
    // 'verb_override' parameter) and to avoid cache problems (the 'cb',
    // a cache buster parameter).
    const postUrl = `${parts[0]}?verb_override=true&cb=${+new Date()}`;
    const params = parts[1];
    return (
      cancel ?
        {request: axios.post(postUrl, params, { cancelToken: source.token }), token: source} :
        {request: axios.post(postUrl, params)}
    );
  }
  return (
    cancel ?
      {request: axios.get(url, { cancelToken: source.token }), token: source} :
      {request: axios.get(url)}
  );
};

export const testIsCancel = error => axios.isCancel(error);
