import {
  DIALOG_DISMISS,
  DIALOG_OPEN
} from '@constants/action-types';
import initialState from '../store/initial-state';

const dialogsReducer = (state = initialState.dialogs, action) => {
  switch (action.type) {
  case DIALOG_OPEN: {
    const { text, redirectPath } = action.payload;
    return { open: true, text, redirectPath };
  }
  case DIALOG_DISMISS: {
    return { ...initialState.dialogs };
  }
  default:
    return state;
  }
};

export default dialogsReducer;
