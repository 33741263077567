// Base form class, which issues a warning dialog if we try to navigate
// the edit page after modifying the form.
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Prompt } from 'react-router-dom';
import { routerWillLeave } from '@actions/confirmation-actions';
import { shallowEqual } from '@utils/react-utils';
import './form.scss';

class Form extends Component {
  state = {
    modified: false,
    data: null
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { data } = nextProps;
    if (
      !shallowEqual(data, this.props.data) ||
      (data !== null && this.state.data === null)
    ) {
      this.setState({ data });
      this.afterReceiveProps(data);
    }
  }

  // Implement in subclass:
  // eslint-disable-next-line no-unused-vars
  afterReceiveProps = data => {};

  renderPrompt = () => <Prompt when={this.state.modified && !this.props.discard} message={this.props.routerWillLeave} />;

  onChange = (field, value) => this.setState({
    modified: true,
    data: {
      ...this.state.data,
      [field]: value
    }
  });
}

Form.propTypes = {
  data: PropTypes.object,
  discard: PropTypes.bool,
  routerWillLeave: PropTypes.func
};

export const formMapStateToProps = state => {
  const { discard } = state.confirmation;
  return { discard };
};

export const formMapDispatchToProps = { routerWillLeave };

export default Form;
