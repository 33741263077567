import booleanPointInPolygon from '@turf/boolean-point-in-polygon';
import distance from '@turf/distance';
import pointToLineDistance from '@turf/point-to-line-distance';

const pointDistanceFunctions = {
  Point: (shape, point, options) => distance(point, shape.coordinates, options),
  LineString: (shape, point, options) => pointToLineDistance(point, shape.coordinates, options),
  MultiLineString: (
    (shape, point, options) => Math.min(
      ...shape.coordinates.map(lineString => pointToLineDistance(point, lineString, options))
    )
  ),
  Polygon: (
    (shape, point, options) => booleanPointInPolygon(point, shape) ? 0 : (
      Math.min(
        ...shape.coordinates.map(ring => pointToLineDistance(point, ring, options))
      )
    )
  ),
  MultiPolygon: (
    (shape, point, options) => booleanPointInPolygon(point, shape) ? 0 : (
      Math.min(...shape.coordinates.reduce(
        (acc, polygon) => [...acc, ...polygon.map(ring => pointToLineDistance(point, ring, options))], []
      ))
    )
  )
};

export const shapeDistance = (center, shape, units) => {
  if (center && center.length === 2 && typeof center[0] === 'undefined' && typeof center[1] === 'undefined') {
    // When the center is not defined, the distanceFunction could fail (depending
    // on the resolved @turf/helper version, thus to be consistent, if it's not defined,
    // return Infinity.
    return Infinity;
  }
  const unitOptions = { units: units || 'meters' };
  const distanceFunction = pointDistanceFunctions[shape.type];
  if (distanceFunction) {
    return distanceFunction(shape, center, unitOptions);
  }
  throw new Error(`No distance function for shape ${shape}`);
};

export const circleIntersects = (center, radius, shape, units) => {
  try {
    return shapeDistance(center, shape, units) < radius;
  } catch (err) {
    return false;
  }
};
