/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import PropTypes from 'prop-types';

import { CARD_STATE_LOADING } from './constants';

import DashboardCardLoader from './dashboard-card-loader';
import DashboardCardTitlebar from './dashboard-card-titlebar';

import './dashboard-card.scss';

// Prevent clicks on the card body from triggering drag
const handleBodyMouseDown = event => {
  event.stopPropagation();
};

const DashboardCardContainer = ({ card }) => {
  const style =
    card.contentState === CARD_STATE_LOADING
      ? { boxShadow: 'none', background: 'none' }
      : {};
  return (
    <div styleName="card" style={style}>
      <DashboardCardTitlebar card={card} />
      <div
        style={{ position: 'relative', cursor: 'default' }}
        onMouseDown={handleBodyMouseDown}
      >
        <DashboardCardLoader card={card} />
      </div>
    </div>
  );
};

DashboardCardContainer.propTypes = {
  card: PropTypes.object
};

export default DashboardCardContainer;
