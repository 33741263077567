import React from 'react';
import PropTypes from 'prop-types';

import styles from './attribute-label-container.scss';

const AttributeLabelContainer = ({children}) => (
  <span className={styles.container}>
    {children}
  </span>
);

AttributeLabelContainer.propTypes = {
  children: PropTypes.arrayOf(PropTypes.node)
};

export default AttributeLabelContainer;
