import React from 'react';
import ReactDOM from 'react-dom';

import { isES2015Supported } from '@utils/browser-detect';

// Import a polyfill for old browsers.
//
// Important:
//
//   It's generally agreed that the polyfill import should go
//   before any other import, however due to the a bug with
//   React 15.4.1 we should import it after React and ReactDOM,
//   for more information read this:
//
//       https://stackoverflow.com/questions/40897966/objects-are-not-valid-as-a-react-child-in-internet-explorer-11-for-react-15-4-1
//       https://github.com/facebook/react/issues/8379
//
// We detect if ES2015 features are supported, since we don't want to
// load the polyfills for newer browsers, since they can bring potential
// performance issues.
if (!isES2015Supported()) {
  require('@babel/polyfill');

  // 'eventsource-polyfill' is needed for hot-reloading under IE,
  // but we don't need to watch for code changes on production,
  // and developers doesn't use IE, thus keep this always commented.
  //
  // Use only for debugging IE issues, but never commit this uncommented.
  // require('eventsource-polyfill');
}

// Uncomment for debugging React component performance:
// import { debugPerformance } from './debugging';
// debugPerformance();

import { AppContainer } from 'react-hot-loader';
import { createBrowserHistory } from 'history';
import Root from '@components/root';
import { setAuthHeaders } from '@utils/auth-utils';
import configureStore from './store/configure-store';
import { translationMessages } from './i18n';

require('./styles/global.scss');
// require('roboto-fontface');

// To only allow a single tooltip open per namespace:
window.tooltip = {};

// Callback for when the map finish loading.
window.initMap = () => {
  window.mapSetup = true;
  window.placeFrom && window.placeFrom();  // eslint-disable-line no-unused-expressions
  window.placeTo && window.placeTo();  // eslint-disable-line no-unused-expressions
};

const history = createBrowserHistory();

const store = configureStore({}, history);

// Set Axios auth headers.
setAuthHeaders();

const render = messages => {
  ReactDOM.render(
    <AppContainer>
      <Root store={store} history={history} messages={messages} />
    </AppContainer>,
    document.getElementById('app')
  );
};

const MOUNT_NODE = document.getElementById('app');

if (module.hot) {
  module.hot.accept('./components/root', () => {
    const NewRoot = require('./components/root').default;
    ReactDOM.render(
      <AppContainer>
        <NewRoot store={store} history={history} />
      </AppContainer>,
      document.getElementById('app')
    );
  });

  module.hot.accept('./i18n', './components/root', () => {
    ReactDOM.unmountComponentAtNode(MOUNT_NODE);
    render(translationMessages);
  });
}

// If the browser doesn't support Intl (i.e. Safari),
// then we manually import the intl polyfill and locale data.
if (!window.Intl) {
  (new Promise(resolve => {
    resolve(require('intl'));
  }))
    .then(() => Promise.all([
      require('intl/locale-data/jsonp/en.js'),
      require('intl/locale-data/jsonp/es.js')
      // require('intl/locale-data/jsonp/de.js');
      // require('intl/locale-data/jsonp/fr.js');
      // require('intl/locale-data/jsonp/it.js');
    ]))
    .then(() => render(translationMessages))
    .catch(err => {
      throw err;
    });
} else {
  render(translationMessages);
}
