import { getDashboardConfig } from '@constants/config';

export const getDataType = (params, location) => {
  if (location?.pathname) {
    const { pathname } = location;
    if (pathname.startsWith('/library/overlap/')) {
      return 'overlap';
    }
    if (pathname.startsWith('/library/group/')) {
      return 'group';
    }
    if (pathname.startsWith('/workflow/activity/')) {
      return 'activity';
    }
    if (pathname.startsWith('/workflow/task/')) {
      return 'task';
    }
    // Admin paths:
    if (pathname.startsWith('/admin/workflow/')) {
      return 'workflow';
    }
    if (pathname.startsWith('/admin/task_type/')) {
      return 'task_type';
    }
  }
  return params?.dataType || {};
};

export const getSubType = (dataType, props) => {
  const types = ['activity', 'group', 'overlap', 'task'];
  if (types.includes(dataType)) {
    return props.params.dataType;
  }
  return null;
};

const getBasePath = () => {
  const { pathname } = window.location;
  if (pathname !== 'blank') {  // 'blank' is used by the unit tests.
    const parts = pathname.split('/');
    if (parts.length > 0 && parts[1] !== '') {
      return parts[1];
    }
  }
  return 'list';
};

export const getPortalList = () => Object.keys(getDashboardConfig());

// Get the portal path based on 'window.location'.
export const getPortalPath = () => {
  const dashboard = getDashboardConfig();

  // Get the first part of a path.
  const basePath = getBasePath();
  let path = basePath;

  const portals = Object.keys(dashboard);

  // If the first part is a portal name, we found it:
  if (portals.includes(path)) {
    return path;
  }

  // Else, resolve it from the dashboard configuration:
  portals.forEach(key => {
    if (dashboard[key].paths.includes(basePath)) {
      path = key;
    }
  });

  return path;
};
