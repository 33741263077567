//
// Utility functions to convert the Gantt internal rows
// into a multidimensional array, which can be used to export
// to Excel, CSV and any other column delimited format.
//
import { includes, isEmpty } from 'lodash';
import * as R from 'ramda';
import { getGanttConfig } from '@constants/config';
import { optimizeEntitiesForExportSelector } from '@selectors/gantt-selector';
import {
  ganttRowSort,
  getGanttEntityLabels,
  groupRowsByLocationType
} from '@utils/gantt-utils';
import { getMinDate, getMaxDate } from '@utils/timeline-chart-utils';
import moment from 'moment-timezone';

// Format the date to the required format for Excel export:
const formatDate = date => {
  if (!date) {
    return '';
  }
  return moment(date).format('MMMM D, YYYY h:mm A');
};

const mapRow = (column, values) => {
  if (Array.isArray(column.field)) {
    return R.pathOr('', column.field, values);
  }
  return R.pathOr('', [column.field], values);
};

// Convert a single row into an array, taking care of nested rows:
const convertRow = (level, agencyTypes, row) => {
  const agencyType = row.agency_type ? agencyTypes[row.agency_type].name : '';
  const rowValues = {
    level,
    name: row.label || row.name,
    start_date: formatDate(row.start_date),
    end_date: formatDate(row.end_date),
    id: row.rows ? '' : row.id,  // Don't add the id if it's not a leaf row
    agency_name: row.agency_name,
    agency_type_name: agencyType,
    agency: row.agency,
    agency_type: row.agency_type,
    extra: row.extra
  };
  const currentRow = getGanttConfig().export.columns.map(column => mapRow(column, rowValues));

  // For the 'All' view, skip the 'All' row (adjusting the level).
  if (row.label === 'All') {
    // Also, skip all the rows with an empty 'on_street', since they are
    // displayed on the 'No street' category:
    const rows = row.rows.filter(nestedRow => !isEmpty(nestedRow.on_street));
    return [].concat(...rows.map(nestedRow => convertRow(level, agencyTypes, nestedRow)));
  }
  if (row.rows) {
    return [currentRow].concat(...row.rows.map(nestedRow => convertRow(level + 1, agencyTypes, nestedRow)));
  }
  return [currentRow];
};

// The second row after the header is the one with the group's data:
const generateGroupRow = group => {
  const rows = [...group.entities];
  const startDate = getMinDate(rows);
  const endDate = getMaxDate(rows);
  return [
    1,  // level
    group.name,
    formatDate(startDate),
    formatDate(endDate)
  ];
};

// Converts the gantt grouped rows into a multidimensional array
// for exporting the data to Excel.
export const ganttToArray = (agencyTypes, group, rows) => {
  const level = 2;
  const headerRow = getGanttConfig().export.columns.map(column => column.label);
  const groupRow = generateGroupRow(group);
  const resultArray = [headerRow, groupRow].concat(...rows.groupedRows.map(row => convertRow(level, agencyTypes, row)));
  // If there are entities without street, add them to the end, on their own category:
  if (rows.noStreetRows[0].rows.length) {
    return resultArray.concat(...rows.noStreetRows.map(row => convertRow(level, agencyTypes, row)));
  }
  return resultArray;
};

const getExportRawRows = (entity, state) => {
  if (isEmpty(entity)) {
    return optimizeEntitiesForExportSelector(state);
  }
  const ids = getGanttEntityLabels(state.group.type);
  let entities = [];
  Object.keys(ids).forEach(key => {
    if (includes(entity, ids[key].id)) {
      const allEntities = optimizeEntitiesForExportSelector(state);
      const filteredEntities = allEntities.filter(item => item.entityType === key);
      entities = [...entities, ...filteredEntities];
    }
  });
  return entities;
};

const getNoStreetRows = rows => {
  const noStreetRows = rows.filter(row => isEmpty(row.on_street));
  return [
    {
      label: 'No street',
      rows: noStreetRows,
      start_date: getMinDate(noStreetRows).format('YYYY-MM-DD'),
      end_date: getMaxDate(noStreetRows).format('YYYY-MM-DD')
    }
  ];
};

// Build from the state the rows that we are going to export.
// It uses the same functionality as the buildGanttRowsState() method,
// but this one includes rows with missing start/end dates,
// and also groups entities without on_street into a 'No Street' category.
export const buildExportRows = (entity, locationType, state) => {
  const rawRows = getExportRawRows(entity, state);
  const rows = ganttRowSort(rawRows);
  const groupedRows = groupRowsByLocationType(rows, locationType);
  const noStreetRows = getNoStreetRows(rows);
  return { groupedRows, noStreetRows };
};

const getLocationTypeExportName = locationType => {
  const { locationTypes } = getGanttConfig();
  switch (locationType) {  // eslint-disable-line default-case
  case locationTypes.all.id: return locationTypes.all.exportName;
  case locationTypes.byStreets.id: return locationTypes.byStreets.exportName;
  case locationTypes.byStreetsAndBlocks.id: return locationTypes.byStreetsAndBlocks.exportName;
  }
  return '';
};

// Build the Excel export filename, based on the location type filter and the group id:
export const buildExcelFilename = (locationType, groupId) => {
  const type = getLocationTypeExportName(locationType);
  return `gantt-${type}-${groupId}.xls`;
};
