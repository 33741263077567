/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/jsx-no-bind */
import React, { memo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toggleLayersMenu } from '@actions/map-actions';
import { layersMenuStyles } from '@constants/mui-theme';
import DefaultMapTypeIcon from '@icons/data-uri-icon/default-map-type-icon';
import SatelliteMapTypeIcon from '@icons/data-uri-icon/satellite-map-type-icon';
import TerrainMapTypeIcon from '@icons/data-uri-icon/terrain-map-type-icon';
import { Icon } from '@mui';
import './layers-icon.scss';

const LayersIcon = () => {
  const { mapTypeId } = useSelector(state => state.map);
  const dispatch = useDispatch();

  const onClick = () => dispatch(toggleLayersMenu());

  return (
    <div data-testid="map-layers-icon" styleName="map-layers-icon" onClick={onClick} role="button" tabIndex="0">
      {mapTypeId === 'roadmap' && <DefaultMapTypeIcon style={layersMenuStyles.buttonIcons} />}
      {mapTypeId === 'hybrid' && <SatelliteMapTypeIcon style={layersMenuStyles.buttonIcons} />}
      {mapTypeId === 'terrain' && <TerrainMapTypeIcon style={layersMenuStyles.buttonIcons} />}
      <Icon>arrow_drop_down</Icon>
    </div>
  );
};

export default memo(LayersIcon);
