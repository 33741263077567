/* eslint-disable react/jsx-no-bind */
import React, { memo, useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { includes, isEmpty } from 'lodash';
import InputDataTypeSearchInfoCard from '@components/forms/input-datatype-search-info-card';
import InputSearch from '@shared/input-search';
import { renderUserListItem } from '@utils/autocomplete-utils';
import './input-datatype-search.scss';

const InputDataTypeSearch = ({
  agency,
  dataType,
  disabled,
  errors,
  fieldName,
  filterFunc,
  label,
  limit,
  onChange,
  values
}) => {
  const entries = useSelector(state => state.dataTypes[dataType]);

  const infoCards = useMemo(() => {
    const selected = [];
    Object.keys(entries).forEach(key => {
      if (includes(values, parseInt(key, 10))) {
        selected.push(entries[key]);
      }
    });
    return selected;
  }, [entries, values]);

  const renderOption = useCallback((option, state) => { // eslint-disable-line no-unused-vars
    return renderUserListItem(option, entries, 'large');
  }, [entries]);

  return (
    <div styleName="input-datatype-search-container">
      <InputSearch
        autocompleteContainerStyles={{ maxWidth: 'none' }}
        renderOption={renderOption}
        {...{
          agency,
          disabled,
          errors,
          fieldName,
          filterFunc,
          label,
          limit,
          onChange,
          storePath: ['dataTypes', dataType],
          values
        }}
      />
      {!isEmpty(values) && infoCards.map(entry => <InputDataTypeSearchInfoCard key={entry.id} entry={entry} />)}
    </div>
  );
};

InputDataTypeSearch.propTypes = {
  agency: PropTypes.number,
  dataType: PropTypes.string,
  disabled: PropTypes.bool,
  errors: PropTypes.array,
  fieldName: PropTypes.string,
  filterFunc: PropTypes.func,
  label: PropTypes.string,
  limit: PropTypes.number,
  onChange: PropTypes.func,
  values: PropTypes.any  // eslint-disable-line react/forbid-prop-types
};

export default memo(InputDataTypeSearch);
