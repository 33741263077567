import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@mui';
import * as colors from '@constants/colors';
import FileUpload from '@shared/files/file-upload';

const FileUploadButton = ({ objectId }) => (
  <FileUpload contentType="entity" objectId={objectId} isPublic>
    <Button
      style={{
        border: '1px solid #EBEBEB',
        borderRadius: '2px',
        color: colors.secondaryColor,
        height: '36px'
      }}
    >
      CHOOSE FILES
    </Button>
  </FileUpload>
);

FileUploadButton.propTypes = {
  objectId: PropTypes.number
};

export default FileUploadButton;
