/* eslint-disable react/display-name */
import React from 'react';
import PropTypes from 'prop-types';
import { MenuItem as MUIMenuItem } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { colors } from '@constants/colors';
import { fonts } from '@constants/fonts';

const useStyles = makeStyles({
  root: ({ style }) => ({
    minHeight: '2.25rem',
    fontWeight: 500,
    fontSize: fonts.md,
    display: 'flex',
    justifyContent: 'flex-start',
    paddingLeft: '1.5rem',
    paddingRight: '1.5rem',
    width: 'auto',
    ...style
  }),
  selected: {
    backgroundColor: `${colors.neutral.white} !important`,
    color: colors.brand.blue
  }
});

// forwardRef needed to forward ref passed by Select component
const MenuItem = React.forwardRef((props, ref) => {
  const classes = useStyles(props);

  return (
    <MUIMenuItem
      {...props}
      classes={{
        root: classes.root,
        selected: classes.selected,
        ...props?.classes
      }}
      ref={ref}
    />
  );
});

MenuItem.propTypes = {
  classes: PropTypes.object
};

export default MenuItem;
