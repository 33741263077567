import React from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import { Link } from 'react-router-dom';
import { withRouter } from '@utils/router-utils';

// Link that will navigate to the specified path and add source location's
// pathname to a source parameter in target location's state.
const SourceLink = props => {
  const { children, location: { pathname: source }, to, ...other } = R.omit(['router', 'params', 'routes'], props);
  let newLink;
  if (typeof to === 'string') {
    newLink = { pathname: to, state: { source } };
  } else {
    newLink = { ...to, state: { ...to.state, source } };
  }
  return <Link {...other} to={newLink} >{children}</Link>;
};

SourceLink.propTypes = {
  children: PropTypes.node,
  location: PropTypes.object,
  to: PropTypes.oneOfType([PropTypes.object, PropTypes.string])
};

export default withRouter(SourceLink);
