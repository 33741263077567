/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/jsx-no-bind */
import React from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import { includes } from 'lodash';
import { RadioButton } from 'material-ui/RadioButton';
import { filterStyles } from '@constants/mui-theme';
import InputSearch from '@shared/input-search';
import '../filter.scss';

const FilterSearch = ({
  dataType,
  enabled,
  filterEnabled,
  filterFunc,
  filterName,
  placeHolder,
  quickSelect,
  radio,
  setFilters,
  title,
  values
}) => {
  const getCurrentValues = () => {
    // Get the current filters for the specified name:
    const current = values[filterName];

    if (R.isNil(current) || R.isEmpty(current)) {
      return [];
    }

    if (Array.isArray(current)) {
      return current;
    }

    // Convert to array:
    return current.split(',').map(id => parseInt(id, 10));
  };

  const onChange = (event, newValues) => {
    let newIds = newValues.map(value => value.value);

    if (R.isEmpty(newValues)) {
      // If there are no ids, use a value to flag that this
      // filter is off and should be removed.
      newIds = ['clear'];
    }

    const newFilter = {
      [filterName]: newIds.join(',')  // Convert to string to apply it.
    };

    setFilters(newFilter);
  };

  // Disable the quick select text if the filter is disabled
  // or if the value is already selected.
  const quickSelectEnabled = () => {
    const currentValues = getCurrentValues();
    return enabled && quickSelect.value && !includes(currentValues, quickSelect.value);
  };

  const onQuickSelect = () => {
    if (!quickSelectEnabled()) {
      return;
    }
    setFilters({[filterName]: [quickSelect.value]});
  };

  // We can only render the link if there's quick select data.
  const showQuickSelect = () => {
    // Show the quick selection link if the label and value are defined:
    return quickSelect && quickSelect.label && quickSelect.value;
  };

  const getRadio = () => (
    <div styleName="radio">
      <RadioButton
        disabled={!filterEnabled}
        checked={radio.checked}
        onCheck={radio.onCheck}
        value={radio.value}
        {...filterStyles.filterGroupRadio}
      />
    </div>
  );

  // If there's data use a space to make the TextField component
  // think there's a label, thus making it reserve a 16px top margin.
  const label = R.isEmpty(getCurrentValues()) ? placeHolder : ' ';
  return (
    <div styleName="content-filter-group">
      {title &&
        <div styleName="title">
          {radio && getRadio()}
          <div styleName="label">{title}</div>
        </div>
      }
      <div styleName="list">
        <InputSearch
          avatarProps={{ type: 'star', value: quickSelect ? quickSelect.value : null }}
          fieldName={filterName}
          disabled={!enabled}
          filterFunc={filterFunc}
          label={label}
          onChange={onChange}
          storePath={['dataTypes', dataType]}
          values={getCurrentValues()}
        />
      </div>
      {showQuickSelect() && (
        <div
          role="presentation"
          onClick={onQuickSelect}
          styleName={`quick-select-link ${quickSelectEnabled() ? '' : 'quick-disabled'}`}
        >
          Select <span styleName="quick-select-name">{quickSelect.label}</span>
        </div>
      )}
    </div>
  );
};

FilterSearch.propTypes = {
  dataType: PropTypes.string,
  enabled: PropTypes.bool,
  filterEnabled: PropTypes.bool,
  filterFunc: PropTypes.func,
  filterName: PropTypes.string,
  placeHolder: PropTypes.string,
  quickSelect: PropTypes.object,
  radio: PropTypes.object,
  setFilters: PropTypes.func,
  title: PropTypes.string,
  values: PropTypes.object
};

export default FilterSearch;
