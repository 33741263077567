/* eslint-disable jsx-a11y/mouse-events-have-key-events */
/* eslint-disable react/display-name */
import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { fetchTooltipData } from '@actions/tooltips-actions';
import BaseTooltip from '@components/activity-logs/tooltips';
import WorkflowIcon from '@icons/workflow-icon';
import DefaultDate from '@shared/formatted-date-helper';
import './tooltip.scss';

class CycleTooltip extends BaseTooltip {
  getDataType = () => 'cycle';

  getTitle = () => (
    <Fragment>
      <div styleName="body-title-icon">
        <WorkflowIcon />
      </div>
      <div styleName="body-title-text">
        {this.props.data.workflow_name}
      </div>
    </Fragment>
  );

  renderHeader = () => null;

  renderContent = () => {
    const { data } = this.props;
    return (
      <div styleName="body-content">
        <div styleName="body-row body-item">
          {data.name}
        </div>
        <div styleName="body-row">
          <div styleName="body-row-item">
            {data.task_count}&nbsp;Tasks
          </div>
          <div styleName="body-row-bullet">&bull;</div>
          <div styleName="body-row-item">
            <DefaultDate value={data.created} />
          </div>
        </div>
      </div>
    );
  };

  renderClickLabel = () => 'VIEW WORKFLOW';

  onLinkClick = () => this.openInNewWindow(`/cycle/${this.props.id}`);

  onMouseOver = event => {
    const { id, data } = this.props;
    this.delayHoverOn(event);
    if (!data) {
      this.props.fetchTooltipData('cycle', null, id);
    }
  };
}

CycleTooltip.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

const mapStateToProps = (state, props) => {
  const { cycle, error: { cycle: error } } = state.tooltips;
  return { data: cycle[props.id], error };
};

const mapDispatchToProps = { fetchTooltipData };

export default connect(mapStateToProps, mapDispatchToProps)(CycleTooltip);
