import React, { memo } from 'react';
import { useSelector } from 'react-redux';
import FormTooltip from '@forms/form-tooltip';

const SnapTooltip = () => {
  const { cityName } = useSelector(state => state.config);
  return (
    <FormTooltip>
      This uses the {cityName || 'City'}'s street centerline data.
      When this switch is turned off, the app will use Google's Geocoding service to return an address.
    </FormTooltip>
  );
};

export default memo(SnapTooltip);
