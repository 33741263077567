import React from 'react';
import SvgIcon from 'material-ui/SvgIcon';

const EmptyResultsIcon = props => (
  <SvgIcon {...props}>
    <path
      d={`
          M39.804 13.5a19.417 19.417 0 0 1 2.196 9c0 4.83-1.77 9.27-4.71 12.69l.84.81h2.37l14.97 15L51 55.47 36
          40.5v-2.37l-.81-.84A19.414 19.414 0 0 1 22.5 42c-3.246 0-6.307-.793-9-2.196l4.55-4.551c1.393.484
          2.89.747 4.45.747C29.97 36 36 29.97 36 22.5c0-1.56-.263-3.057-.747-4.45l4.55-4.55zM31.26 5.073l-4.583
          4.583A13.537 13.537 0 0 0 22.5 9C15.03 9 9 15.03 9 22.5c0 1.459.23 2.863.656 4.177L5.073 31.26A19.42
          19.42 0 0 1 3 22.5C3 11.73 11.73 3 22.5 3c3.15 0 6.126.747 8.76 2.073zM.577 40.175L40.175.577l4.243
          4.243L4.82 44.418.577 40.175z
         `}
    />
  </SvgIcon>
);

export default EmptyResultsIcon;
