import React from 'react';
import PropTypes from 'prop-types';
import DefaultDate from '@shared/formatted-date-helper';
import { enDash } from '@utils/shared-utils';

const DateWidget = ({ start, end }) => {
  if (start && end) {
    return (
      <span>
        <DefaultDate value={start} />{enDash}<DefaultDate value={end} />
      </span>
    );
  } else
    if (start || end) {
      return (
        start ?
          <span><DefaultDate value={start} /></span> :
          <span><DefaultDate value={end} /></span>
      );
    }
  return 'Start/end dates unspecified.';
};

DateWidget.propTypes = {
  end: PropTypes.string,
  start: PropTypes.string
};

export default DateWidget;
