import Tooltipable from '@shared/tooltipable';

// This component is not intended to be used directly,
// it has to be used by any component that wants to display a tooltip
// when the text it renders is overflown.
//
// It's almost the same as the <Tooltipable> component, but for the 'hoverOn'
// method, in this class we add a check to see if the content overflows,
// thus opening the tooltip.
class OverflowTooltipable extends Tooltipable {
  // Sometimes 'offsetWidth < scrollWidth' won't work on the container
  // element in which we placed the hoverOn/hoverOff handlers, since
  // it's composed by inner elements.
  //
  // In that case we can specify a 'target id' within that container
  // element, to calculate the overflow (for example if the container
  // contains an icon, and a label, which overflows).
  getTarget = event => {
    const { targetId } = this.props;
    if (targetId) {
      return document.getElementById(`item_label_${targetId}`);
    }
    return event.currentTarget;
  };
  hoverOn = (event, fixedPosition = false) => {
    const target = this.getTarget(event);
    // for UI labels, we want the tooltip to appear at a fixed point
    // under the label, instead of at the position of the event
    const xPosition = fixedPosition ? target.getBoundingClientRect().x : event.clientX;
    const yPosition = fixedPosition ?
      target.getBoundingClientRect().y + target.getBoundingClientRect().height + 8 : event.clientY;
    // This opens the tooltip, only if the content doesn't fit
    // (i.e. if the ellipsis appears due to the overflow).
    if (target.offsetWidth < target.scrollWidth) {
      this.setState({
        open: true,
        anchorEl: event.currentTarget,
        x: xPosition,  // eslint-disable-line id-length
        y: yPosition,  // eslint-disable-line id-length
        ...this.getEventField(event)
      });
    }
  };
}

export default OverflowTooltipable;
