import React, { memo } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import TimelineChartRow from '@shared/timeline-chart-row';
import {
  calculateGanttContainerWidth,
  getMinDate
} from '@utils/timeline-chart-utils';
import { isLocationTypeOpen } from '@utils/gantt-utils';
import './timeline-chart.scss';

const TimelineChart = ({
  dateUnit,
  popoverContent,
  rows
}) => {
  const { locationType } = useSelector(state => state.groups.gantt.filters);
  const { locationTypeGroups } = useSelector(state => state.groups.gantt);
  const minDate = getMinDate(rows);
  const containerStyle = {
    width: calculateGanttContainerWidth(rows, dateUnit)
  };
  return (
    <div style={containerStyle} styleName="timeline-chart-container">
      {rows.map(row => {
        const { label, nestedLocations, rows: groupedRows, ...other } = row;
        const rowData = { name: label, ...other };
        const isOpen = isLocationTypeOpen(label, locationTypeGroups, locationType);
        return (
          <div key={row.id}>
            <TimelineChartRow
              dateUnit={dateUnit}
              key={row.id}
              isSmall
              minDate={minDate}
              row={rowData}
              popoverContent={popoverContent}
            />
            {isOpen && groupedRows.map(groupedRow => {
              if (nestedLocations) {
                const isNestedOpen = isLocationTypeOpen(groupedRow.label, locationTypeGroups, locationType);
                const nestedRowData = {
                  name: groupedRow.label,
                  ...groupedRow
                };
                return (
                  <div key={groupedRow.id}>
                    <TimelineChartRow
                      dateUnit={dateUnit}
                      key={groupedRow.id}
                      isSmall
                      minDate={minDate}
                      row={nestedRowData}
                      popoverContent={popoverContent}
                    />
                    {isNestedOpen && groupedRow.rows.map(nestedRow => (
                      <TimelineChartRow
                        dateUnit={dateUnit}
                        key={nestedRow.id}
                        minDate={minDate}
                        row={nestedRow}
                        popoverContent={popoverContent}
                      />
                    ))
                    }
                  </div>
                );
              }
              return (
                <TimelineChartRow dateUnit={dateUnit} key={groupedRow.id} minDate={minDate} row={groupedRow} popoverContent={popoverContent} />
              );
            })}
          </div>
        );
      })}
    </div>
  );
};

TimelineChart.propTypes = {
  dateUnit: PropTypes.number,
  popoverContent: PropTypes.func,
  rows: PropTypes.array
};

export default memo(TimelineChart);
