import React from 'react';
import PropTypes from 'prop-types';

import CardCustomEmbed from './card-custom-embed';

import {
  CARD_TYPE_EMBED
} from '../constants';

import { getCardHeight } from '../utils';

import '../dashboard-card.scss';

const CardContent = ({ card, cardData }) => {
  const style = {
    height: getCardHeight(card)
  };
  switch (card.type) {
  case CARD_TYPE_EMBED:
    return <CardCustomEmbed card={card} embedLink={cardData} />;
  default:
    return (
      <div style={style} styleName="card-content">
        {card.title} is not yet handled
      </div>
    );
  }
};

CardContent.propTypes = {
  card: PropTypes.object,
  cardData: PropTypes.string
};

export default CardContent;
