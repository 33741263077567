import React from 'react';
import { useSelector } from 'react-redux';
import TaskTypePreviewForm from '@components/admin/task-type-preview-form';

const TaskTypePreview = () => {
  const { data } = useSelector(state => state.dataDetail);

  if (!data) {
    return null;
  }

  return <TaskTypePreviewForm taskType={data} />;
};

export default TaskTypePreview;
