export const FETCH_CONFIG_SUCCESS = 'FETCH_CONFIG_SUCCESS';
export const FETCH_CONFIG_UPDATE_SUCCESS = 'FETCH_CONFIG_UPDATE_SUCCESS';

export const ENTITY_DATA_TYPE = 'entity';
export const BATCH_DATA_TYPE = 'batch';
export const OVERLAP_DATA_TYPE = 'overlap';
export const GROUP_DATA_TYPE = 'group';
export const AGENCY_DATA_TYPE = 'agency';
export const USER_DATA_TYPE = 'user';
export const TASK_TYPE_DATA_TYPE = 'task_type';
export const WORKFLOW_DATA_TYPE = 'workflow';
export const TASK_DATA_TYPE = 'task';
export const TASK_ACTIVITY_DATA_TYPE = 'activity';
export const CYCLE_DATA_TYPE = 'cycle';

export const ATTRS_DATA_TYPES = new Set(ENTITY_DATA_TYPE);

export const DATA_TYPES = {
  [ENTITY_DATA_TYPE]: {
    portal: 'library',
    api: '/api/map/',
    subType: 'type'
  },
  [BATCH_DATA_TYPE]: {
    portal: 'library',
    api: '/api/batch/'
  },
  [OVERLAP_DATA_TYPE]: {
    portal: 'library',
    api: '/api/overlap/',
    subType: 'type'
  },
  [GROUP_DATA_TYPE]: {
    portal: 'library',
    api: '/api/group/',
    subType: 'type'
  },
  [AGENCY_DATA_TYPE]: {
    portal: 'people',
    api: '/api/agency/'
  },
  [USER_DATA_TYPE]: {
    portal: 'people',
    api: '/api/user/'
  },
  [TASK_TYPE_DATA_TYPE]: {
    portal: 'admin',
    api: '/api/task_type/'
  },
  [WORKFLOW_DATA_TYPE]: {
    portal: 'admin',
    api: '/api/workflow/'
  },
  [TASK_DATA_TYPE]: {
    portal: 'workflow',
    api: '/api/task/',
    subType: 'type'
  },
  [TASK_ACTIVITY_DATA_TYPE]: {
    portal: 'workflow',
    api: '/api/task_activity/',
    subType: 'type'
  },
  [CYCLE_DATA_TYPE]: {
    portal: 'workflow',
    api: '/api/cycle/'
  }
};
