//
// Import a polyfill for old browsers.
//
// This code is the same to the one that runs inside src/index.js
// but is repeated here (and must be repeated) to allow loading
// the polyfill before any library (because of react-big-calendar).
//
// Any other workaround or problems (for example about that React needs
// to be loaded before the polyfill), are not needed here, we only need
// to load this polyfill here and that's all.
//
// For more information about this code (and gotchas), read the
// comments on src/index.js
//
import { isES2015Supported } from '@utils/browser-detect';

if (!isES2015Supported()) {
  require('@babel/polyfill');
}
