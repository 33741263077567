import React, { memo } from 'react';
import BatchErrorsTable from '@components/batch/batch-errors-table';
import '../portal/details/sections.scss';

const BatchErrorsSection = () => (
  <div styleName="sections-container">
    <div key="batch_errors" styleName="sections-section">
      <div styleName="sections-title">Errors</div>
    </div>
    <BatchErrorsTable />
  </div>
);

export default memo(BatchErrorsSection);
