import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import PortalMenu from '@components/portal/menu/menu';
import './dashboard.scss';

const PortalDashboard = ({ children }) => {
  const { dataType } = useParams();
  return (
    <div styleName="dashboard-container">
      <PortalMenu currentDataType={dataType} />
      <div styleName="dashboard-details">
        {children}
      </div>
    </div>
  );
};

PortalDashboard.propTypes = {
  children: PropTypes.element
};

export default memo(PortalDashboard);
