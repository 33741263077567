import {
  LAYER_FETCH_SUCCESS,
  LAYER_LOADING,
  SET_LAYER_VISIBLE,
  LAYER_FETCH_CONFIG,
  LAYER_MAP_LOAD_OUTSIDE_VIEWPORT,
  LAYER_MAP_LOADING_COMPLETE,
  LAYER_MAP_SAVE_VIEWPORT
} from '@constants/action-types';

import axios from 'axios';
import { getServerErrorAction } from './shared-actions';
import { getAPIRequestUrl } from '@constants/endpoints';
import { getWKTViewportBoundsFromState } from '@utils/map-utils';
import { optimizeLayersForMap } from '@utils/layer-utils';

const fetchLayerConfigAction = (payload) => ({
  type: LAYER_FETCH_CONFIG,
  payload
});

const setLayerVisibleAction = (name, visible) => ({
  type: SET_LAYER_VISIBLE,
  payload: {
    name,
    visible
  }
});

const fetchLayerSuccess = (payload, url, name) => ({
  type: LAYER_FETCH_SUCCESS,
  payload,
  url,
  name
});

export const fetchLayerConfig = () => {
  const url = getAPIRequestUrl('layer');
  const request = axios.get(url);
  return dispatch =>
    request.then(
      data => dispatch(fetchLayerConfigAction(data)),
      error => dispatch(getServerErrorAction(name, error))
    );
};

export const setLayerVisible = (name, visible) => dispatch => {
  return dispatch(setLayerVisibleAction(name, visible));
};

export const setLayerLoading = () => dispatch => {
  dispatch({ type: LAYER_LOADING });
  return Promise.resolve();
};

const getLoadOutsideViewportAction = name => ({
  type: LAYER_MAP_LOAD_OUTSIDE_VIEWPORT,
  name
});

const getLoadingCompleteAction = name => ({
  type: LAYER_MAP_LOADING_COMPLETE,
  name
});

const saveViewport = (name, viewport) => ({
  type: LAYER_MAP_SAVE_VIEWPORT,
  name,
  viewport
});

export const fetchLayerData = name => async (dispatch, getState) => {
  const state = getState();
  const layer = state.layers[name];
  const viewport = layer.viewport || getWKTViewportBoundsFromState(state);
  if (!layer.viewport) {
    dispatch(saveViewport(name, viewport));
  }
  const url = ((layer.next && layer.next !== layer.url) ?
    layer.next : `${layer.url}&${layer.inViewport ? 'geom_in' : 'geom_not_in'}=${viewport}`);
  return await dispatch(setLayerLoading()).then(
    async () => {
      await axios.get(url).then(
        data => {
          if (data.data.next === null && !layer.loadingComplete) {
            if (layer.inViewport) {
              dispatch(getLoadOutsideViewportAction(name));
            } else {
              dispatch(getLoadingCompleteAction(name));
            }
          }
          const layers = optimizeLayersForMap(name, data.data.results);
          const layersData = {
            data: {
              results: layers,
              next: data.data.next
            }
          };
          dispatch(fetchLayerSuccess(layersData, url, name));
        },
        error => dispatch(getServerErrorAction(name, error))
      );
    }
  );
};
