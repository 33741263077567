import React from 'react';
import PropTypes from 'prop-types';
import TruncatedCell from '@shared/data-table/cells/truncated-cell';
import { getAgencyIcon, renderIconAndLabel } from '@utils/icon-utils';

const ConflictAgency = ({ agencies, agencyTypes, conflict }) => {
  const { agency: agencyId } = conflict;
  if (!agencyId) {
    return null;
  }

  const agency = Object.values(agencies).find(agencyItem => agencyItem.id === agencyId);
  if (!agency) {
    return null;
  }

  const getIcon = agencyObj => {
    const agencyType = agencyTypes[agencyObj.type];
    return getAgencyIcon(agencyType);
  };

  return (
    <TruncatedCell tooltip={agency.name} targetId={conflict.id}>
      {renderIconAndLabel(agency.name, getIcon(agency), conflict.id)}
    </TruncatedCell>
  );
};

ConflictAgency.propTypes = {
  agencies: PropTypes.object,
  agencyTypes: PropTypes.object,
  conflict: PropTypes.object
};

export default ConflictAgency;
