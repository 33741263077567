import React from 'react';

const OverlappingIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="18" height="10" viewBox="0 0 18 10">
    <defs>
      <rect id="prefix_overlapping_path" width="18" height="10" x="0" y="4" rx="5" />
    </defs>
    <g fill="none" fillRule="evenodd" transform="translate(0 -4)">
      <path d="M0 0H18V18H0z" />
      <use fill="#FF5252" fillOpacity=".6" xlinkHref="#prefix_overlapping_path" />
    </g>
  </svg>
);

export default OverlappingIcon;
