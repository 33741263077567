import * as R from 'ramda';
import { createSelector } from 'reselect';
import {
  applyDynamicStyles,
  getAction,
  getTemplate,
  mergeActionWithTemplate
} from '@utils/data-detail-utils';

const getData = state => state.dataDetail.data;
const getOptions = state => state.dataDetail.options;

// Returns frontend and backend form metadata merged into a single object:
export const getMetadata = (dataType, isPublic = false) => createSelector(
  [getData, getOptions],
  (data, options) => {
    // Get backend fields definitions (i.e. field name, type, label, etc).
    const { action } = getAction(options);

    // Get frontend field definitions (i.e. field name, CSS style, etc).
    // isPublic passed in to bypass usertype check on agency permissions
    // agency should not be readonly if the page is public
    const fieldTemplate = getTemplate(dataType, isPublic);

    // And merge both:
    return applyDynamicStyles(mergeActionWithTemplate(action || {}, fieldTemplate), data || {});
  }
);

// Return all form fields which can be displayed:
export const getVisibleFields = (dataType, isPublic = false) => createSelector(
  [getMetadata(dataType, isPublic)],
  metadata => Object.keys(R.pickBy(value => value.style !== 'hidden', metadata))
);
