/* eslint-disable no-nested-ternary */
import React, { memo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { setDatatableFilter } from '@actions/data-table-actions';
import { colors } from '@constants/colors';
import { Icon, IconButton } from '@mui';
import { getFilters } from '@selectors/data-table-selector';
import {
  activateFilter,
  isCurrentFilter,
  isFilterAscending,
  setFirstFilter
} from '@utils/filter-utils';

const dataTableSortIconButtonStyles = {
  border: 'none',
  height: '1.125rem',
  width: '1.125rem',
  padding: '0.5rem',
  boxSizing: 'content-box'
};

const SortIconButton = ({ column, dataType, hidden, subType }) => {
  const filters = useSelector(state => getFilters(state, { dataType, subType }));
  const dispatch = useDispatch();

  if (hidden) {
    const hiddenStyles = {
      ...dataTableSortIconButtonStyles,
      // Opacity = zero, hides the icon but maintains the space.
      opacity: 0
    };
    return (
      <IconButton style={{ ...hiddenStyles }}>
        <Icon style={{ fontSize: '18px' }}>arrow_upward</Icon>
      </IconButton>
    );
  }

  if (!filters) {
    return null;
  }

  const { ordering } = column;

  const setMainFilter = filter => setFirstFilter(filter, filters);

  const setActiveFilter = filter => activateFilter(filter, filters);

  const setAscending = () => {
    dispatch(setDatatableFilter(dataType, subType, { ordering: setMainFilter(ordering) }));
  };

  const setDescending = () => {
    dispatch(setDatatableFilter(dataType, subType, { ordering: setMainFilter(`-${ordering}`) }));
  };

  const setActive = () => {
    dispatch(setDatatableFilter(dataType, subType, { ordering: setActiveFilter(ordering) }));
  };

  const getSortIcon = isAscending => {
    const { flipOrderIcon } = column;
    let ascending = isAscending;
    if (flipOrderIcon) {
      ascending = !ascending;
    }
    return ascending ? 'arrow_upward' : 'arrow_downward';
  };

  const getAriaLabel = isAscending => {
    if (getSortIcon(isAscending) === 'arrow_upward') {
      return 'Sort descending';
    }
    return 'Sort ascending';
  };

  const isCurrent = isCurrentFilter(filters, ordering);
  const isAscending = isFilterAscending(filters, ordering);
  const color = isCurrent ? colors.opacity.black0_8 : colors.opacity.black0_5;
  // First click on the icon button (i.e. isCurrent is false), will set this
  // filter as active, but won't change the direction.
  // Subsequent clicks (i.e. when isCurrent is true) will change the direction.
  return (
    <IconButton
      aria-label={getAriaLabel(isAscending)}
      style={{ ...dataTableSortIconButtonStyles }}
      onClick={isCurrent ? isAscending ? setDescending : setAscending : setActive}
    >
      <Icon color={color} style={{ fontSize: '18px' }}>
        {getSortIcon(isAscending)}
      </Icon>
    </IconButton>
  );
};

SortIconButton.propTypes = {
  column: PropTypes.object,
  dataType: PropTypes.string,
  hidden: PropTypes.bool,
  subType: PropTypes.string
};

export default memo(SortIconButton);
