import React, { Component } from 'react';
import PropTypes from 'prop-types';
import transitions from 'material-ui/styles/transitions';
import Month from './month';
import Toolbar from './toolbar';
import SlideInTransitionGroup from 'material-ui/internal/SlideIn';
import {
  defaultUtils,
  dateTimeFormat,
  localizedWeekday
} from 'material-ui/DatePicker/dateUtils';
import './custom-calendar.scss';

const daysArray = [...Array(7)];

// Custom calendar component, based on the Material UI's DatePicker Calendar component.
class CustomCalendar extends Component {
  state = {
    displayDate: null,
    transitionDirection: 'left',
    transitionEnter: true
  };

  UNSAFE_componentWillMount() {
    this.setState({
      displayDate: this.props.utils.getFirstDayOfMonth(this.getInitialDate())
    });
  }

  getMinDate() {
    return this.props.minDate || this.props.utils.addYears(new Date(), -100);
  }

  getMaxDate() {
    return this.props.maxDate || this.props.utils.addYears(new Date(), 100);
  }

  isLeftCalendar = () => {
    return this.props.dualPosition === 'left';
  };

  getInitialDate = () => {
    const initialDate = this.props.minDate || new Date();
    if (this.isLeftCalendar()) {
      return initialDate;
    }
    const firstDay = this.props.utils.getFirstDayOfMonth(initialDate);
    return this.props.utils.addMonths(firstDay, 1);
  };

  handleTouchTapDay = (event, date) => {
    this.props.onExcludeDate(date);
  };

  onMonthChange = months => {
    const nextDirection = this.context.muiTheme.isRtl ? 'right' : 'left';
    const prevDirection = this.context.muiTheme.isRtl ? 'left' : 'right';
    const direction = months >= 0 ? nextDirection : prevDirection;
    this.setState({
      transitionDirection: direction,
      displayDate: this.props.utils.addMonths(this.state.displayDate, months)
    });
  };

  getToolbarInteractions() {
    return {
      // Since it's a dual calendar, hide the next and previous month links for the first
      // and second calendars.
      showNextMonth: !this.isLeftCalendar(),
      showPrevMonth: this.isLeftCalendar(),
      // Then disable the links if we hit the min/max dates:
      prevMonth: this.props.utils.monthDiff(this.state.displayDate, this.getMinDate()) > 0,
      nextMonth: this.props.utils.monthDiff(this.state.displayDate, this.getMaxDate()) < 0
    };
  }

  render() {
    const toolbarInteractions = this.getToolbarInteractions();

    const {
      DateTimeFormat,
      exceptions,
      firstDayOfWeek,
      locale,
      utils,
      weekdays
    } = this.props;

    return (
      <div styleName="custom-calendar-container">
        <div styleName="calendar-container">
          <div styleName="calendar"
            style={{transition: transitions.easeOut()}}
          >
            <Toolbar
              DateTimeFormat={DateTimeFormat}
              locale={locale}
              displayDate={this.state.displayDate}
              onMonthChange={this.props.onMonthChange}
              {...toolbarInteractions}
            />
            <div styleName="week-title">
              {daysArray.map((event, dayIndex) => (
                <span key={dayIndex} styleName="day">
                  {localizedWeekday(DateTimeFormat, locale, dayIndex, firstDayOfWeek)}
                </span>
              ))}
            </div>
            <SlideInTransitionGroup
              direction={this.state.transitionDirection}
              style={{ height: 214 }}
            >
              <Month
                DateTimeFormat={DateTimeFormat}
                exceptions={exceptions}
                locale={locale}
                displayDate={this.state.displayDate}
                firstDayOfWeek={firstDayOfWeek}
                key={this.state.displayDate.toDateString()}
                minDate={this.props.minDate}
                maxDate={this.props.maxDate}
                onTouchTapDay={this.handleTouchTapDay}
                utils={utils}
                weekdays={weekdays}
              />
            </SlideInTransitionGroup>
          </div>
        </div>
      </div>
    );
  }
}

CustomCalendar.propTypes = {
  DateTimeFormat: PropTypes.func.isRequired,
  dualPosition: PropTypes.string,
  exceptions: PropTypes.array,
  firstDayOfWeek: PropTypes.number,
  locale: PropTypes.string.isRequired,
  maxDate: PropTypes.object,
  minDate: PropTypes.object,
  mode: PropTypes.oneOf(['portrait', 'landscape']),
  onExcludeDate: PropTypes.func,
  onMonthChange: PropTypes.func,
  utils: PropTypes.object,
  weekdays: PropTypes.array
};

CustomCalendar.defaultProps = {
  firstDayOfWeek: 1,
  DateTimeFormat: dateTimeFormat,
  locale: 'en-US',
  utils: defaultUtils
};

CustomCalendar.contextTypes = {
  muiTheme: PropTypes.object.isRequired
};

export default CustomCalendar;
