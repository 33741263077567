import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Toggle from 'material-ui/Toggle';
import './switch-label.scss';

export class SwitchLabel extends PureComponent {
  render() {
    const { icon, label, ...other } = this.props;
    if (icon) {
      return (
        <div styleName="toggle-root">
          <div styleName="small-toggle"><Toggle {...other} /></div>
          <div styleName="toggle-icon"> {icon} </div>
          <div styleName="toggle-label"> {label} </div>
        </div>
      );
    }
    return (
      <div styleName="toggle-root">
        <Toggle styleName="toggle" label={label} labelPosition="right" {...other} />
      </div>
    );
  }
}

SwitchLabel.propTypes = {
  icon: PropTypes.node,
  label: PropTypes.string
};

export default SwitchLabel;
