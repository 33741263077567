import * as R from 'ramda';

import { CARD_SIZE_LG, CARD_STATE_LOADING } from './constants';
import { CARD_CONFIG, DEFAULT_LOAD_HEIGHT } from './settings';

// Dashboard Helper Methods
export const getColumnIndex = size => size === CARD_SIZE_LG ? 0 : 1;

export const getColumn = (size, allCards) => {
  const columnCards = allCards.filter(card => card.size === size);
  return R.sort((card1, card2) => card1.order - card2.order, columnCards);
};

export const getCardHeight = (card, margin) => card.emptyHeight ? `${card.emptyHeight - margin}px` : DEFAULT_LOAD_HEIGHT;

export const getColumnsData = (lgColumn, smColumn) => [
  lgColumn.map(card => card.id),
  smColumn.map(card => card.id)
];

// Initialization methods
const applyInitCardState = (card, layout) => {
  const column = getColumnIndex(card.size);
  const order = layout[column].indexOf(card.id);
  return {
    ...card,
    contentState: CARD_STATE_LOADING,
    ...CARD_CONFIG[card.type],
    order
  };
};

export const initCards = (cards, layout) => cards.map(card => applyInitCardState(card, layout));
