import React from 'react';
import PropTypes from 'prop-types';
import './file-preview.scss';

const GoogleFilePreview = ({ url }) => (
  <iframe
    src={`https://docs.google.com/gview?url=${encodeURIComponent(url)}&embedded=true`}
    frameBorder="0"
    styleName="pdf-previewer"
    title="google-docs-pdf-preview"
  />
);

GoogleFilePreview.propTypes = {
  url: PropTypes.string
};

export default GoogleFilePreview;
