import React from 'react';
import { useSelector } from 'react-redux';
import * as R from 'ramda';
import { isEmpty } from 'lodash';
import { getGanttEntityLabels } from '@utils/gantt-utils';
import './empty.scss';

const Empty = () => {
  const { entity } = useSelector(state => state.groups.gantt.filters);
  const { type } = useSelector(state => state.groups.edit.group);

  const name = (!isEmpty(entity) && entity.length === 1) ?
    R.values(getGanttEntityLabels(type)).find(value => value.id === entity[0]).pluralType : 'all entities';

  return (
    <div styleName="empty">
      There are no {name} for this group.
    </div>
  );
};

export default Empty;
