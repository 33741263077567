/* eslint-disable react/jsx-no-bind */
import React, { memo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateTime } from '@components/settings/actions';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import './settings.scss';

const Time = () => {
  const { global: { time } } = useSelector(state => state.config);
  const dispatch = useDispatch();

  const onChange = event => dispatch(updateTime(event.target.value));

  return (
    <div styleName="settings-form-container">
      <div styleName="title">
        <div styleName="text">Time Format</div>
      </div>
      <div styleName="hint">
        Switch time format.
      </div>
      <div styleName="form">
        <FormControl component="fieldset">
          <RadioGroup aria-label="time" name="time" value={time} onChange={onChange}>
            <FormControlLabel value="12" control={<Radio color="primary" />} label="12 Hour Format" />
            <FormControlLabel value="24" control={<Radio color="primary" />} label="24 Hour Format" />
          </RadioGroup>
        </FormControl>
      </div>
    </div>
  );
};

export default memo(Time);
