// Customized label for controls.
// (needed as a way to style labels for checkboxes, radio buttons, etc).
import React from 'react';
import PropTypes from 'prop-types';
import './form.scss';

const ControlLabel = ({ disabled, label }) => (
  <div styleName={`control-label ${disabled ? 'control-label-disabled' : ''}`}>
    {label}
  </div>
);

ControlLabel.propTypes = {
  disabled: PropTypes.bool,
  label: PropTypes.string
};

export default ControlLabel;
