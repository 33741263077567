import React from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import RecurrenceRow from '@shared/dialogs/edit-schedule-dialog-recurrence-row';
import './edit-schedule-dialog-recurrence-list.scss';

const RecurrenceList = ({
  deleteRecurrence,
  onAllDayToggle,
  onDayChange,
  onStartTimeChange,
  onEndTimeChange,
  recurrences
}) => (
  <div styleName="recurrence-list-container">
    {isEmpty(recurrences) &&
      <div styleName="recurrence-empty-row">
        No recurrence
      </div>
    }
    {!isEmpty(recurrences) && recurrences.map((recurrence, index) => (
      <RecurrenceRow
        deleteRecurrence={deleteRecurrence}
        key={`recurrence-row-${index}`}
        onAllDayToggle={onAllDayToggle}
        onDayChange={onDayChange}
        onStartTimeChange={onStartTimeChange}
        onEndTimeChange={onEndTimeChange}
        recurrence={recurrence}
      />
    ))}
  </div>
);

RecurrenceList.propTypes = {
  deleteRecurrence: PropTypes.func,
  onAllDayToggle: PropTypes.func,
  onDayChange: PropTypes.func,
  onEndTimeChange: PropTypes.func,
  onStartTimeChange: PropTypes.func,
  recurrences: PropTypes.array
};

export default RecurrenceList;
