/* eslint-disable react/jsx-no-bind */
import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import * as colors from '@constants/colors';
import { withStyles } from '@material-ui/core/styles';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import { Checkbox } from '@mui';
import Cell from '../cell';
import Actions from './actions';
import Value from './value';

const CustomRow = withStyles(theme => ({  // eslint-disable-line no-unused-vars
  root: {
    backgroundColor: colors.overlayTextColor,
    cursor: 'pointer'
  }
}))(TableRow);

const Row = ({ row, index, actions, checked, columns, onRowClick, onToggle }) => {
  const [hovered, setHovered] = useState(false);
  const hoverOn = () => setHovered(true);
  const hoverOff = () => setHovered(false);
  const onChange = useCallback(() => onToggle(index), [index, onToggle]);
  return (
    <CustomRow
      hover
      key={`body-row-${row.id}`}
      onClick={event => onRowClick(event, row, index)}
      onMouseEnter={hoverOn}
      onMouseLeave={hoverOff}
    >
      {columns.map((column, columnIndex) => {
        const style = {
          ...(column.style || {}),
          boxSizing: 'border-box'
        };
        if (column.key === 'checkbox') {
          return (
            <Cell key={`body-cell-${column.key}`} style={style}>
              <Checkbox
                checked={checked[index] === true}
                onChange={onChange}
                onClick={event => event.stopPropagation()}
                size="small"
              />
            </Cell>
          );
        }
        if (hovered && columnIndex === columns.length - 1 && actions && actions.length > 0) {
          return (
            <Cell key={`body-cell-${column.key}`} style={style}>
              <Actions actions={actions} row={row} />
            </Cell>
          );
        }
        return (
          <Cell key={`body-cell-${column.key}`} style={style}>
            <Value column={column} row={row} />
          </Cell>
        );
      })}
    </CustomRow>
  );
};

Row.propTypes = {
  actions: PropTypes.array,
  checked: PropTypes.array,
  columns: PropTypes.array,
  index: PropTypes.number,
  onRowClick: PropTypes.func,
  onToggle: PropTypes.func,
  row: PropTypes.object
};

const Body = ({ actions, checked, columns, onRowClick, onToggle, rows }) => (
  <TableBody>
    {rows.map((row, index) => (
      <Row
        actions={actions}
        checked={checked}
        columns={columns}
        index={index}
        key={`body-row-wrapper-${row.id}`}
        onToggle={onToggle}
        onRowClick={onRowClick}
        row={row}
      />
    ))}
  </TableBody>
);

Body.propTypes = {
  actions: PropTypes.array,
  checked: PropTypes.array,
  columns: PropTypes.array,
  onRowClick: PropTypes.func,
  onToggle: PropTypes.func,
  rows: PropTypes.array
};

export default Body;
