/* eslint-disable react/jsx-key */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable react/display-name */
import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import { isString } from 'lodash';
import { setEntityFilters } from '@actions/entities-actions';
import { setMapFilterRadio } from '@actions/map-actions';
import FilterGroup from '@components/map/tray/filter/shared/filter-group';
import FilterSearch from '@components/map/tray/filter/shared/filter-search';
import FilterAttrSearch from '@components/map/tray/filter/shared/filter-attr-search';
import { getFiltersMap } from '@constants/config';
import { blankIconStyles } from '@constants/mui-theme';
import EntityTypeIcon from '@icons/entity-type-icon';
import { entityFiltersSelector } from '@selectors/entities-selector';
import FilterAvatar from '@shared/filter-avatar';
import { categoryToInt } from '@utils/filter-utils';
import {
  getAgencyIcon,
  getEntityLegend,
  getPriorityIconUrl
} from '@utils/icon-utils';
import {
  getAgencyId,
  getUserId
} from '@utils/permission-utils';
import { shallowEqual } from '@utils/react-utils';
import { enDash } from '@utils/shared-utils';
import {
  BaseTrayFilter,
  baseMapDispatchToProps,
  baseMapStateToProps
} from './base-filter';
import OverlapsFilter from './overlaps-filter';
import './filter.scss';

class EntityFilter extends BaseTrayFilter {
  // shallowEqual() doesn't compare object properties recursivelly, so we need
  // to loop through each key in 'sectionData' in order to accuratelly know
  // if the 'sectionData' property changed.
  shallowEqualSectionData = nextProps => {
    if (!shallowEqual(nextProps.sectionData, this.props.sectionData)) {
      const result = [];
      Object.keys(nextProps.sectionData).forEach(data => {
        result.push(shallowEqual(nextProps.sectionData[data], this.props.sectionData[data]));
      });
      return result.every(data => data);
    }
    return true;
  };

  shouldComponentUpdate(nextProps, nextState) {
    return (
      !this.shallowEqualSectionData(nextProps) ||
      !shallowEqual(nextProps.agencyTypes, this.props.agencyTypes) ||
      !shallowEqual(nextProps.entityFilters, this.props.entityFilters) ||
      !shallowEqual(nextProps.filterRadios, this.props.filterRadios) ||
      !shallowEqual(nextProps.filterEnabled, this.props.filterEnabled) ||
      !shallowEqual(nextProps.loading, this.props.loading) ||
      this.state !== nextState
    );
  }

  setFilters = filters => {
    this.props.setEntityFilters(this.props.entityType, filters);
  };

  setFilterRadio = (event, value) => {
    this.props.setMapFilterRadio(this.props.entityType, event, value);
  };

  onToggleClick = () => this.props.toggleMapFilterToggle(this.props.config.type);

  getItemIcon = icon => <FilterAvatar src={icon} />;

  getAgencyTypeIcon = item => {
    const icon = getAgencyIcon(item);
    return this.getItemIcon(icon);
  };

  getCategoryIcon = item => {
    const categoryId = categoryToInt(item.id);
    if (isString(categoryId)) {
      // Blank entries has no icons.
      return <div style={blankIconStyles}>{enDash}</div>;
    }
    const icon = getEntityLegend(null, this.props.entityType, categoryId);
    return this.getItemIcon(icon);
  };

  getPriorityIcon = item => {
    const icon = getPriorityIconUrl(item.id);
    if (icon) {
      return this.getItemIcon(icon);
    }
    return null;
  };

  getContent = () => (
    <div styleName="content-filter-group-container">
      {this.props.config.sections.map((section, index) => {
        const key = section.filter_name || `${section.type}: ${index}`;
        if (section.type === 'separator') {
          return <div key={key} styleName="content-filter-group-divider" />;
        }
        if (section.type === 'data-type-group') {
          return <div key={key} >{this.getFilterGroup(section)}</div>;
        }
        if (section.type === 'data-type-search') {
          return <div key={key} >{this.getFilterSearch(section)}</div>;
        }
        if (section.type === 'data-type-overlap') {
          return <div key={key} >{this.getOverlapsFilter()}</div>;
        }
        if (section.type === 'entity-attr-search') {
          return <div key={key} >{this.getAttrSearch(section)}</div>;
        }
        return null;
      })}
    </div>
  );

  getItems = section => {
    const { sectionData } = this.props;
    if (section.values) {
      return section.values;
    }
    if (section.type === 'data-type-group') {
      if (section.data_type === 'map_category') {
        return sectionData[`category-${section.category}`];
      }
      return sectionData[section.data_type];
    }
    return null;
  };

  getIcon = () => {
    const { type } = this.props.config;
    return (
      <div styleName={this.getIconStyle()}>
        <EntityTypeIcon type={type} size="1.5rem" color={this.getIconColor()} />
      </div>
    );
  };

  getFilterKey = () => `${this.props.config.type}Filters`;

  getGenericFilterName = section => {
    if (section.type === 'data-type-group' || section.type === 'data-type-search' || section.type === 'entity-attr-search') {
      return getFiltersMap()[this.getFilterKey()][section.filter_name][0];
    }
    return null;
  };

  getGenericFilterRadio = section => {
    if (section.radio) {
      const { filterRadios } = this.props;
      return {
        checked: filterRadios[section.radioType].checked,
        value: section.radioType,
        onCheck: this.setFilterRadio
      };
    }
    return null;
  };

  getGenericFilterEnabled = section => {
    const { filterEnabled } = this.props;
    if (section.radio) {
      const { filterRadios } = this.props;
      return filterRadios[section.radioType].checked && filterEnabled;
    }
    return filterEnabled;
  };

  getFilterGroupIcon = section => {
    if (section.icon_type === 'agency') {
      // Return the function reference:
      return this.getAgencyTypeIcon;
    }
    if (section.icon_type === 'category') {
      return this.getCategoryIcon;
    }
    if (section.icon_type === 'priority') {
      return this.getPriorityIcon;
    }
    return null;
  };

  getFilterGroup = section => {
    const { entityFilters, filterEnabled } = this.props;
    const items = this.getItems(section);
    return (
      <FilterGroup
        enabled={this.getGenericFilterEnabled(section)}
        category={section.category}
        filterEnabled={filterEnabled}
        getIcon={this.getFilterGroupIcon(section)}
        filterName={this.getGenericFilterName(section)}
        items={items}
        setFilters={this.setFilters}
        negateIfEmpty={section.negateIfEmpty}
        radio={this.getGenericFilterRadio(section)}
        title={section.title}
        values={entityFilters}
      />
    );
  };

  getOverlapsFilter = () => <OverlapsFilter disabled={!this.props.filterEnabled} label={this.getLabel()} />;

  getQuickSelect = section => {
    const { myAgencyName, myUserName } = this.props;
    if (section.data_type === 'agency') {
      return {
        label: myAgencyName,
        value: getAgencyId()
      };
    }
    if (section.data_type === 'user') {
      return {
        label: myUserName,
        value: getUserId()
      };
    }
    return null;
  };

  getFilterSearch = section => {
    const { entityFilters, filterEnabled } = this.props;
    return (
      <FilterSearch
        dataType={section.data_type}
        enabled={this.getGenericFilterEnabled(section)}
        filterName={this.getGenericFilterName(section)}
        filterEnabled={filterEnabled}
        filterFunc={item => {
          // If the data type is 'user',
          // filter them by agency.
          if (section.data_type === 'user') {
            const { agency__in } = entityFilters;
            const isInList = agencyId => {
              let list = null;
              if (Array.isArray(agency__in)) {
                list = agency__in;
              } else {
                list = agency__in.split(',').map(agency => parseInt(agency, 10));
              }
              return list.includes(agencyId);
            };
            if (agency__in && isInList(item.agency)) {
              return true;
            } else
              if (!agency__in) {
                return true;
              }
            return false;
          }
          // Else no filtering:
          return true;
        }}
        placeHolder={section.placeholder}
        quickSelect={this.getQuickSelect(section)}
        radio={this.getGenericFilterRadio(section)}
        setFilters={this.setFilters}
        title={section.title}
        values={entityFilters}
      />
    );
  };

  getAttrSearch = section => {
    const { entityFilters, filterEnabled } = this.props;
    return (
      <FilterAttrSearch
        dataType={section.data_type}
        enabled={this.getGenericFilterEnabled(section)}
        filterName={this.getGenericFilterName(section)}
        filterEnabled={filterEnabled}
        placeHolder={section.placeholder}
        quickSelect={this.getQuickSelect(section)}
        radio={this.getGenericFilterRadio(section)}
        setFilters={this.setFilters}
        title={section.title}
        values={entityFilters}
        attrField={section.field}
      />
    );
  };
}

EntityFilter.propTypes = {
  agencyTypes: PropTypes.array,
  entityFilters: PropTypes.object,
  entityType: PropTypes.string,
  filterRadios: PropTypes.object,
  myAgencyName: PropTypes.string,
  myUserName: PropTypes.string,
  sectionData: PropTypes.object,
  setEntityFilters: PropTypes.func,
  setMapFilterRadio: PropTypes.func
};

const mapStateToProps = (state, props) => {
  const { agency, user } = state.dataTypes;
  const { type, sections } = props.config;
  const filterEnabled = state.map.filterToggles[type];
  const { filterRadios } = state.map;
  const agencyTypes = R.values(state.dataTypes.agency_type);
  const sectionData = {};
  sections.forEach(section => {
    if (section.type === 'data-type-group' || section.type === 'data-type-search') {
      const dataTypeData = R.values(state.dataTypes[section.data_type]);
      if (section.data_type !== 'map_category') {
        sectionData[section.data_type] = dataTypeData;
      } else
        if (section.category) {
          const categoryTypes = state.dataTypes.map_category_type;
          if (categoryTypes) {
            const categoryType = parseInt(Object.keys(categoryTypes).find(key => categoryTypes[key].name === section.category), 10);
            const categories = Object.values(
              R.pickBy(category => category.type === categoryType && category.id > 0 && category.active, dataTypeData)
            );
            const blank = Object.values(
              R.pickBy(category => category.type === categoryType && isString(category.id), dataTypeData)
            ) || [];
            sectionData[`category-${section.category}`] = [...blank, ...categories];
          }
        }
    }
  });
  const entityFilters = entityFiltersSelector(state, { type });
  const myAgencyName = R.pathOr(null, [getAgencyId(), 'name'], agency);
  const myUserName = R.pathOr(null, [getUserId(), 'name'], user);
  return {
    ...baseMapStateToProps(state),
    agencyTypes,
    entityType: type,
    filterEnabled,
    filterRadios: filterRadios[type],
    sectionData,
    entityFilters,
    myAgencyName,
    myUserName
  };
};

const mapDispatchToProps = {
  ...baseMapDispatchToProps,
  setEntityFilters,
  setMapFilterRadio
};

export default connect(mapStateToProps, mapDispatchToProps)(EntityFilter);
