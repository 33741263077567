import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { closeDashboardDialog } from '@actions/dashboard-actions';
import { publishTaskType } from '@actions/workflow-actions';
import * as dialog from '@constants/dialogs';
import Dialog from '@shared/dialogs/dialog';
import DialogActions from '@shared/dialogs/dialog-actions';

const TaskTypePublishDialog = () => {
  const dispatch = useDispatch();

  const { data } = useSelector(state => state.dataDetail);
  const { name } = data || {};
  const { activeDialogs, dialogParams } = useSelector(state => state.dashboard);

  const { id } = dialogParams[dialog.TASK_TYPE_PUBLISH] || {};
  const isDialogActive = activeDialogs[dialog.TASK_TYPE_PUBLISH];

  const onClose = useCallback(
    () => {
      dispatch(closeDashboardDialog(dialog.TASK_TYPE_PUBLISH));
    },
    [dispatch]
  );

  const onPublish = useCallback(
    () => {
      dispatch(publishTaskType(id, name));
    },
    [dispatch, id, name]
  );

  if (!isDialogActive) {
    return null;
  }

  return (
    <Dialog
      actions={
        <DialogActions
          onCancel={onClose}
          onSubmit={onPublish}
          submitLabel="PUBLISH"
        />
      }
      title="Publish task template?"
    >
      Once task template "{name}" is published, it will be permanently added to your system and cannot be deleted.
      Published task templates can be added to workflow templates and used in workflows.
    </Dialog>
  );
};

export default TaskTypePublishDialog;
