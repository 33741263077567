/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/jsx-no-bind */
import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import * as R from 'ramda';
import { push } from 'connected-react-router';
import { getMenuSettingsConfig } from '@constants/config';
import { verifyPermissions } from '@utils/permission-utils';
import { withRouter } from '@utils/router-utils';
import './menu.scss';

const Menu = ({ location }) => {
  const dispatch = useDispatch();

  const onMenuClick = type => dispatch(push(`/settings/${type}`));

  const isActive = type => {
    const { pathname } = location;
    const nameSlice = R.slice(2, 3);
    const name = R.pipe(R.split('/'), nameSlice)(pathname)[0];
    return type === name;
  };

  const renderMenuItem = item => {
    const { label, permissions, type } = item;
    if (!verifyPermissions(permissions)) {
      return null;
    }
    const active = isActive(type);
    return (
      <div
        data-testid="settings-menu-item"
        onClick={() => onMenuClick(type)}
        role="presentation"
        styleName={`menu-item ${active ? 'active' : ''}`}
      >
        {label}
      </div>
    );
  };

  const items = getMenuSettingsConfig();
  return (
    <div data-testid="settings-menu" styleName="menu-container">
      {items.map(item => renderMenuItem(item))}
    </div>
  );
};

Menu.propTypes = {
  location: PropTypes.object
};

export default withRouter(Menu);
