/* eslint-disable no-nested-ternary */
import React, {Component, createElement, cloneElement, Children, isValidElement} from 'react';
import PropTypes from 'prop-types';
import warning from 'warning';
import TabTemplate from './TabTemplate';
import * as colors from '@constants/colors';
import InkBar from './InkBar';
import './tabs.scss';

class Tabs extends Component {
  state = { selectedIndex: 0 };

  componentWillMount() {
    const valueLink = this.getValueLink(this.props);
    const initialIndex = this.props.initialSelectedIndex;

    this.setState({
      selectedIndex: typeof valueLink.value !== 'undefined' ?
        this.getSelectedIndex(this.props) :
        initialIndex < this.getTabCount() ?
          initialIndex :
          0
    });
  }

  componentWillReceiveProps(newProps, nextContext) {
    const valueLink = this.getValueLink(newProps);
    const newState = {
      muiTheme: nextContext.muiTheme || this.context.muiTheme
    };

    if (typeof valueLink.value !== 'undefined') {
      newState.selectedIndex = this.getSelectedIndex(newProps);
    }

    this.setState(newState);
  }

  getTabs(props = this.props) {
    const tabs = [];

    Children.forEach(props.children, (tab) => {
      if (isValidElement(tab)) {
        tabs.push(tab);
      }
    });

    return tabs;
  }

  getTabCount = () => this.getTabs().length;

  // Do not use outside of this component, it will be removed once valueLink is deprecated
  getValueLink(props) {
    return props.valueLink || {
      value: props.value,
      requestChange: props.onChange
    };
  }

  getSelectedIndex = props => {
    const valueLink = this.getValueLink(props);
    let selectedIndex = -1;

    this.getTabs(props).forEach((tab, index) => {
      if (valueLink.value === tab.props.value) {
        selectedIndex = index;
      }
    });

    return selectedIndex;
  };

  handleTabTouchTap = (value, event, tab) => {
    const valueLink = this.getValueLink(this.props);
    const index = tab.props.index;

    if ((valueLink.value && valueLink.value !== value) ||
      this.state.selectedIndex !== index) {
      valueLink.requestChange(value, event, tab);
    }

    this.setState({selectedIndex: index});

    if (tab.props.onActive) {
      tab.props.onActive(tab);
    }
  };

  getSelected = (tab, index) => {
    const valueLink = this.getValueLink(this.props);
    return valueLink.value ? valueLink.value === tab.props.value :
      this.state.selectedIndex === index;
  };

  render() {
    const {
      contentContainerClassName,
      contentContainerStyle,
      initialSelectedIndex, // eslint-disable-line no-unused-vars
      inkBarStyle,
      onChange, // eslint-disable-line no-unused-vars
      style,
      tabItemContainerStyle,
      tabItemWidth,
      tabTemplate,
      tabTemplateStyle,
      ...other
    } = this.props;

    const { prepareStyles } = this.context.muiTheme;
    const valueLink = this.getValueLink(this.props);
    const tabValue = valueLink.value;
    const tabContent = [];
    const defaultWidth = 100 / this.getTabCount();
    const width = tabItemWidth ? tabItemWidth : `${defaultWidth}%`;
    const widthNum = tabItemWidth ? parseInt(tabItemWidth.replace('px', ''), 10) : defaultWidth;

    const tabs = this.getTabs().map((tab, index) => {
      warning(tab.type && tab.type.muiName === 'Tab',
        `Material-UI: Tabs only accepts Tab Components as children.
        Found ${tab.type.muiName || tab.type} as child number ${index + 1} of Tabs`);

      warning(!tabValue || typeof tab.props.value !== 'undefined',
        `Material-UI: Tabs value prop has been passed, but Tab ${index}
        does not have a value prop. Needs value if Tabs is going
        to be a controlled component.`);

      tabContent.push(tab.props.children ?
        createElement(tabTemplate || TabTemplate, {
          key: index,
          selected: this.getSelected(tab, index),
          style: tabTemplateStyle
        }, tab.props.children) : undefined);  // eslint-disable-line no-undefined

      return cloneElement(tab, {
        key: index,
        index,
        selected: this.getSelected(tab, index),
        width,
        onClick: this.handleTabTouchTap
      });
    });

    const realSelectedIndex = valueLink.value ? this.getSelectedIndex(this.props) : this.state.selectedIndex;

    const inkBar = realSelectedIndex !== -1 ? (
      <InkBar
        color={colors.secondaryColor}
        left={`${widthNum * realSelectedIndex}${tabItemWidth ? 'px' : '%'}`}
        width={width}
        style={inkBarStyle}
      />
    ) : null;

    const inkBarContainerWidth = tabItemContainerStyle ?
      tabItemContainerStyle.width : '100%';

    return (
      <div
        style={prepareStyles(Object.assign({}, style))}
        {...other}
        styleName="tabs-container"
      >
        <div
          style={prepareStyles(Object.assign({}, tabItemContainerStyle))}
          styleName="tabs-item-container"
        >
          {tabs}
        </div>
        <div
          style={{width: inkBarContainerWidth}}
          styleName="tabs-ink-bar-container"
        >
          {inkBar}
        </div>
        <div
          className={contentContainerClassName}
          onScroll={this.props.onScroll}
          styleName="tabs-content-container"
          style={prepareStyles(Object.assign({}, contentContainerStyle))}
        >
          {tabContent}
        </div>
      </div>
    );
  }
}

Tabs.propTypes = {
  /**
   * Should be used to pass `Tab` components.
   */
  children: PropTypes.node,
  /**
   * The css class name of the root element.
   */
  className: PropTypes.string,
  /**
   * The css class name of the content's container.
   */
  contentContainerClassName: PropTypes.string,
  /**
   * Override the inline-styles of the content's container.
   */
  contentContainerStyle: PropTypes.object,
  /**
   * Specify initial visible tab index.
   * If `initialSelectedIndex` is set but larger than the total amount of specified tabs,
   * `initialSelectedIndex` will revert back to default.
   * If `initialSelectedIndex` is set to any negative value, no tab will be selected intially.
   */
  initialSelectedIndex: PropTypes.number,
  /**
   * Override the inline-styles of the InkBar.
   */
  inkBarStyle: PropTypes.object,
  /**
   * Called when the selected value change.
   */
  onChange: PropTypes.func,
  /**
   * Callback for content onScroll event.
   */
  onScroll: PropTypes.func,
  /**
   * Override the inline-styles of the root element.
   */
  style: PropTypes.object,
  /**
   * Override the inline-styles of the tab-labels container.
   */
  tabItemContainerStyle: PropTypes.object,
  /**
   * Override the default tab label item and ink bar width.
   */
  tabItemWidth: PropTypes.string,
  /**
   * Override the default tab template used to wrap the content of each tab element.
   */
  tabTemplate: PropTypes.func,
  /**
   * Override the inline-styles of the tab template.
   */
  tabTemplateStyle: PropTypes.object,
  /**
   * Makes Tabs controllable and selects the tab whose value prop matches this prop.
   */
  value: PropTypes.oneOfType([PropTypes.object, PropTypes.string])
};

Tabs.defaultProps = {
  initialSelectedIndex: 0,
  onChange: () => {}
};

Tabs.contextTypes = {
  muiTheme: PropTypes.object.isRequired
};

export default Tabs;
