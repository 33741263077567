/* eslint-disable react/jsx-no-bind */
import React, { memo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { logOverlapComment } from '@actions/logs-actions';
import * as dialog from '@constants/dialogs';
import CommentAvatarInput from '@shared/comments/comment-avatar-input';

const CommentBox = () => {
  const dispatch = useDispatch();
  const { dialogParams } = useSelector(state => state.dashboard);

  const onComment = (comment, mentions) => {
    const payload = {
      ...dialogParams[dialog.OVERLAP_STATUS],
      comment,
      mentions
    };
    dispatch(logOverlapComment(payload));
  };

  return <CommentAvatarInput onComment={onComment} />;
};

export default memo(CommentBox);
