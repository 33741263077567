import { getEmailSettingsConfig } from '@constants/config';

const getEmailSettingsFieldValue = (settings, emailSettings, type, field, index) => {
  const val = emailSettings[type][field];
  const col = settings.details[type].columns.find(column => column.field === field);
  if (col.values) {
    return col.values.find(value => value.id === val).value;
  } else
    if (col.type === 'toggle' && val === true) {
      if (index === 0) {
        return col.label;
      }
      return col.label.toLowerCase();
    }
  return null;
};

export const renderEmailSettingsValue = (emailSettings, type, fields) => {
  const settings = getEmailSettingsConfig();
  if (settings && emailSettings) {
    if (Array.isArray(fields)) {
      const values = fields.map((field, index) => getEmailSettingsFieldValue(settings, emailSettings, type, field, index));
      return values.filter(value => value !== null).join(', ');
    }
    return getEmailSettingsFieldValue(settings, emailSettings, type, fields, 0);
  }
  return '';
};
