/* eslint-disable no-unused-vars */
import React from 'react';
import PropTypes from 'prop-types';

import { Draggable, Droppable } from 'react-beautiful-dnd';

import { colors } from '@constants/colors';

import DashboardCardContainer from './dashboard-card-container';

import './dashboard.scss';

const DashboardColumn = ({ cards, size }) => (
  <div styleName={`col col-${size}`}>
    <Droppable droppableId={`col-${size}`}>
      {(provided, snapshot) => (
        <div
          ref={provided.innerRef}
          style={{ backgroundColor: colors.neutral.white }}
          {...provided.droppableProps}
        >
          {cards.map((card, idx) => (
            <Draggable
              draggableId={card.id}
              index={idx}
              key={card.id}
            >
              {(draggableProvided, draggableSnapshot) => (
                <div
                  ref={draggableProvided.innerRef}
                  {...draggableProvided.draggableProps}
                  {...draggableProvided.dragHandleProps}
                  style={{
                    background: !draggableSnapshot.isDragging ? colors.neutral.white : colors.neutral.fog,
                    boxShadow: !draggableSnapshot.isDragging ? 'none' : 'rgba(0, 0, 0, 0.12) 0 1px 6px, rgba(0, 0, 0, 0.12) 0 1px 4px',
                    userSelect: 'none',
                    ...draggableProvided.draggableProps.style
                  }}
                >
                  <DashboardCardContainer card={card} />
                </div>
              )}
            </Draggable>
          ))}
          {provided.placeholder}
        </div>
      )}
    </Droppable>
  </div>
);

DashboardColumn.propTypes = {
  cards: PropTypes.array,
  size: PropTypes.string
};

export default DashboardColumn;
