import React from 'react';

const initialState = {
  activity: null,
  cycle: null,
  error: null,
  scrollTop: 0,
  task: null,
  taskActivityId: null,
  taskId: null,
  taskType: null
};

const DetailsContext = React.createContext(initialState);

export default DetailsContext;
