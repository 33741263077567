import React from 'react';
import PropTypes from 'prop-types';
import { SAFE_ZINDEX } from '@constants/mui-theme';
import Select from '@material-ui/core/Select';
import { withStyles } from '@material-ui/core/styles';
import * as colors from '@constants/colors';

const CustomSelect = props => {
  const { selectStyles = { root: {}}, ...other } = props;
  const StyledSelect = withStyles({
    root: {
      color: colors.dotmapsBlack80,
      marginLeft: '0.5rem',
      '&:focus': {
        backgroundColor: 'transparent',
        color: colors.dotmapsBlack80
      },
      ...selectStyles.root
    }
  })(Select);
  return <StyledSelect {...other} />;
};

CustomSelect.propTypes = {
  selectStyles: PropTypes.object
};

const SelectField = ({
  children, id, labelId, onChange, renderValue, value, ...other
}) => (
  <CustomSelect
    {...other}
    id={id}
    labelId={labelId}
    onChange={onChange}
    renderValue={renderValue}
    value={value}
    MenuProps={{
      style: SAFE_ZINDEX
    }}
  >
    {children}
  </CustomSelect>
);

SelectField.propTypes = {
  children: PropTypes.node,
  id: PropTypes.string,
  labelId: PropTypes.string,
  onChange: PropTypes.func,
  renderValue: PropTypes.func,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

export default SelectField;
