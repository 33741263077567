import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { includes, isEmpty } from 'lodash';
import moment from 'moment-timezone';
import { isBetweenDates } from 'material-ui/DatePicker/dateUtils';
import DayButton from '../day-button';
import './month.scss';

class Month extends Component {
  handleTouchTapDay = (event, date) => {
    if (this.props.onTouchTapDay) {
      this.props.onTouchTapDay(event, date);
    }
  };

  shouldDisableDate = day => {
    if (!this.props.minDate || !this.props.maxDate) {
      return true;
    }
    if (day === null) {
      return false;
    }
    return !isBetweenDates(day, this.props.minDate, this.props.maxDate);
  };

  isSelected = (day, weekDayIndex) => {
    const { weekdays } = this.props;
    if (!this.props.minDate || !this.props.maxDate) {
      return false;
    }
    if (day === null) {
      return false;
    }
    return isBetweenDates(day, this.props.minDate, this.props.maxDate) &&
           includes(weekdays, weekDayIndex);
  };

  isExcluded = day => {
    const { exceptions } = this.props;
    if (!isEmpty(exceptions)) {
      return exceptions.some(exception => moment(exception.exception_date, 'YYYY-MM-DD').isSame(moment(day), 'day'));
    }
    return false;
  };

  getWeekElements = () => {
    const weekArray = this.props.utils.getWeekArray(this.props.displayDate, this.props.firstDayOfWeek);

    return weekArray.map((week, weekIndex) => {
      return (
        <div key={weekIndex} styleName="week">
          {this.getDayElements(week, weekIndex)}
        </div>
      );
    }, this);
  };

  getDayElements(week, weekIndex) {
    const {
      DateTimeFormat,
      locale
    } = this.props;

    return week.map((day, weekDayIndex) => {
      const disabled = this.shouldDisableDate(day);
      const selected = this.isSelected(day, weekDayIndex);
      const excluded = this.isExcluded(day);

      return (
        <DayButton
          DateTimeFormat={DateTimeFormat}
          excluded={excluded}
          locale={locale}
          date={day}
          disabled={disabled}
          key={`db${(weekIndex + weekDayIndex)}`}
          onClick={this.handleTouchTapDay}
          selected={selected}
        />
      );
    }, this);
  }

  render() {
    return (
      <div styleName="custom-calendar-month-container">
        {this.getWeekElements()}
      </div>
    );
  }
}

Month.propTypes = {
  DateTimeFormat: PropTypes.func.isRequired,
  autoOk: PropTypes.bool,
  displayDate: PropTypes.object.isRequired,
  exceptions: PropTypes.array,
  firstDayOfWeek: PropTypes.number,
  locale: PropTypes.string.isRequired,
  maxDate: PropTypes.object,
  minDate: PropTypes.object,
  onTouchTapDay: PropTypes.func,
  utils: PropTypes.object.isRequired,
  weekdays: PropTypes.array
};

export default Month;
