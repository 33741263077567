import React from 'react';

const AddToGroupIcon = () => (
  <svg width="18px" height="18px" version="1.1" viewBox="0 0 18 18">
    <defs>
      <path
        id="add-to-group-path-1"
        d={`
            M7.5 0a7.503 7.503 0 0 1 7.35 9.001L13.5 9V7.5h-3V9H9v3h1.5v1.5h1.499A7.465
            7.465 0 0 1 7.5 15C3.36 15 0 11.64 0 7.5 0 3.36 3.36 0 7.5 0zm5.06
            8.25v1.69h1.69v1.12h-1.69v1.69h-1.12v-1.69H9.75V9.94h1.69V8.25h1.12zM4.5
            7.875a1.876 1.876 0 1 0 .001 3.751A1.876 1.876 0 0 0 4.5 7.875zm3-5.25a1.876
            1.876 0 1 0 .001 3.751A1.876 1.876 0 0 0 7.5 2.625z
        `}
      />
    </defs>
    <g fill="none" fillRule="evenodd" transform="translate(1.5 1.5)">
      <mask id="add-to-group-path-2" fill="#fff">
        <use xlinkHref="#add-to-group-path-1" />
      </mask>
      <g fill="#000" fillOpacity=".4" mask="url(#add-to-group-path-2)">
        <path d="M-1.5-1.5h18v18h-18z" />
      </g>
    </g>
  </svg>
);

export default AddToGroupIcon;
