import React, { useEffect, memo } from 'react';
import { isEmpty } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import { fetchPublicDataTypes } from '@actions/data-types-actions';
import muiTheme from '@constants/mui-theme';
import './public.scss';

const PublicLayout = ({ children }) => {
  const dispatch = useDispatch();
  const dataTypes = useSelector(state => state.dataTypes);

  useEffect(() => dispatch(fetchPublicDataTypes()), [dispatch]);

  const requiredTypes = [
    'agency',
    'map_category_type',
    'map_category',
    'user'
  ];

  // Check that the configuration and all required data types are loaded before
  // displaying the page contents.
  if (requiredTypes.every(requiredType => !isEmpty(dataTypes[requiredType]))) {
    return (
      <MuiThemeProvider muiTheme={muiTheme}>
        <div styleName="public-wrap">
          <div styleName="public-body">
            {children}
          </div>
        </div>
      </MuiThemeProvider>
    );
  }

  return null;
};

PublicLayout.propTypes = {
  children: PropTypes.element
};

export default memo(PublicLayout);
