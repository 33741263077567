import React from 'react';
import PropTypes from 'prop-types';
import GoogleFilePreview from '@shared/files/file-preview-display-google';
import ImagePreview from '@shared/files/file-preview-display-image';
import './file-preview.scss';

const FilePreviewDisplay = ({ asset }) => {
  const renderDisplay = () => {
    const { file_content_type, signed_url } = asset;
    const mimeTypes = [
      'application/pdf', 'application/msword', 'image/x-dwg', 'text/csv', 'application/zip', 'application/vnd.ms-excel',
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document', 'application/octet-stream',
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    ];

    if (mimeTypes.includes(file_content_type)) {
      return <GoogleFilePreview url={signed_url} />;
    } else if (
      file_content_type === 'image/jpeg' || file_content_type === 'image/png'
    ) {
      return <ImagePreview url={signed_url} />;
    }
    return null;
  };

  return (
    <div styleName="file-preview-display">
      {renderDisplay()}
    </div>
  );
};

FilePreviewDisplay.propTypes = {
  asset: PropTypes.object
};

export default FilePreviewDisplay;
