/**
 * Data table utils for agencies.
 */
import React from 'react';
import { v4 as uuid } from 'uuid';
import TruncatedCell from '@shared/data-table/cells/truncated-cell';
import { getAgencyIcon, renderIconAndLabel } from '@utils/icon-utils';
import { enDash, renderTooltipDate } from '@utils/shared-utils';

export const renderAgency = (name, agencyTypes, agencyType, id) => name ? (
  <TruncatedCell tooltip={name} targetId={id}>
    {renderIconAndLabel(name, getAgencyIcon(agencyTypes[agencyType]), id)}
  </TruncatedCell>
) : enDash;

const renderAgencyType = agencyType => (
  <TruncatedCell>
    {agencyType}
  </TruncatedCell>
);

export const optimizeAgencyData = (agency, agencyTypes) => ({
  ...agency,
  type_name: renderAgencyType(agency.type_name),
  name: renderAgency(agency.name, agencyTypes, agency.type, uuid()),
  modified: renderTooltipDate(agency.modified)
});
