/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-no-bind */
import React, { Fragment, memo, useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { useDropzone } from 'react-dropzone';

import * as colors from '@constants/colors';
import { UPLOAD_RUNNING } from '@constants/file';

import { CircularProgress } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import CloudUploadOutlinedIcon from '@material-ui/icons/CloudUploadOutlined';

import { formatFileSize, renderFileIcon } from '@utils/file-utils';

import './dropzone-single.scss';

// Like Dropzone, but for a single file upload instead.
// And it also has slightly different copy and styling.
const DropzoneSingle = ({
  accept,
  caption,
  file,
  onFileChange,
  onFileReset,
  rowCount,
  status
}) => {
  const [isDraggedOver, setIsDraggedOver] = useState(false);

  const onDrop = useCallback(acceptedFiles => {
    onFileChange(acceptedFiles);
    setIsDraggedOver(false);
  }, [onFileChange]);

  const onDragEnter = () => setIsDraggedOver(true);

  const onDragLeave = () => setIsDraggedOver(false);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    onDragEnter,
    onDragLeave,
    accept
  });

  // If there are no files, render the "drop zone":
  if (!file) {
    const iconDraggingColor = isDraggedOver ? colors.secondaryColor : '#BDBDBD';
    return (
      <div
        styleName={`dropzone-single-container ${isDraggedOver ? 'drag' : 'dashed'}`}
        {...getRootProps({ className: 'dropzone-single-container' })}
      >
        <input {...getInputProps()} />
        <div styleName="empty-container">
          <div styleName="empty-icon">
            <CloudUploadOutlinedIcon htmlColor={iconDraggingColor} style={{ fontSize: '3.5rem' }} />
          </div>
          <div styleName="empty-text">
            Drag and drop or <span styleName="empty-link">choose files</span>
          </div>
          <div styleName="empty-caption">
            {caption}
          </div>
        </div>
      </div>
    );
  }

  return (
    <div styleName="dropzone-single-container">
      <div styleName="file-container">
        <div data-testid="dropzone-file-item" styleName="file-item-container">
          <div data-testid="dropzone-file-item-icon" styleName="file-item-icon">
            {renderFileIcon(file.type)}
          </div>
          <div data-testid="dropzone-file-item-text" styleName="file-item-text">
            <div data-testid="dropzone-file-item-name" styleName="file-item-name">
              {file.name}
            </div>
            <div data-testid="dropzone-file-item-size" styleName="file-item-size">
              {status === UPLOAD_RUNNING && <span>Processing...</span>}
              {status !== UPLOAD_RUNNING && file && (
                <Fragment>
                  {rowCount && <span>{rowCount} rows&nbsp;&bull;&nbsp;</span>}
                  <span>{formatFileSize(file.size)}</span>
                </Fragment>
              )}
            </div>
          </div>
          <div data-testid="dropzone-file-item-action" styleName="file-item-action">
            {status === UPLOAD_RUNNING && <CircularProgress size="1.5rem" thickness={5} />}
            {status !== UPLOAD_RUNNING && file && <CloseIcon htmlColor="#616161" style={{ fontSize: '1.5rem' }} onClick={onFileReset} />}
          </div>
        </div>
      </div>
    </div>
  );
};

DropzoneSingle.propTypes = {
  /**
   * The accept attribute defines which file types can be uploaded. This is a
   * comma separated list of unique file type specifiers as a string.
   * (e.g. .jpg, .png, .csv, .doc, audio/*, video/*, image/*)
   */
  accept: PropTypes.string,
  /**
   * Caption text to display below the "Drag and drop" label and icon.
   */
  caption: PropTypes.string,
  /**
   * Uploaded file object.
   */
  file: PropTypes.object,
  /**
   * Callback called when a file is drag and dropped or selected from device.
   */
  onFileChange: PropTypes.func,
  /**
   * Callback to reset the files state (i.e. remove from memory the selected file to upload).
   */
  onFileReset: PropTypes.func,
  /**
   * Total number of rows in file (if it's a CSV one).
   */
  rowCount: PropTypes.number,
  /**
   * The upload status of each file (i.e. running or finished).
   */
  status: PropTypes.number
};

export default memo(DropzoneSingle);
