import {
  CALENDAR_FETCH_START,
  CALENDAR_FETCH_DATA,
  CALENDAR_FETCH_SUCCESS,
  CALENDAR_FETCH_ERROR,
  CALENDAR_UPDATE_SELECTED_FILTERS
} from '@constants/action-types';
import initialState from '../store/initial-state';

const calendarReducer = (state = initialState.calendar, action) => {
  switch (action.type) {
  case CALENDAR_FETCH_START: {
    const { fetchId } = action.payload;
    return {...state, data: [], fetchId, loading: true};
  }
  case CALENDAR_FETCH_DATA: {
    const { results, next } = action.payload.data;
    return {...state, data: [...state.data, ...results], next };
  }
  case CALENDAR_FETCH_SUCCESS: {
    return {...state, next: null, loading: false};
  }
  case CALENDAR_FETCH_ERROR: {
    return {...state, data: null, errors: [...state.errors, action.payload.data]};
  }
  case CALENDAR_UPDATE_SELECTED_FILTERS: {
    return {...state, filters: {...state.filters, [action.dataType]: action.selectedKeys}};
  }
  default:
    return state;
  }
};

export default calendarReducer;
