import { v4 as uuid } from 'uuid';

export const actionRegistry = {};

export const registerAction = (actionFunction, actionName) => {
  const name = actionName || uuid();
  actionRegistry[name] = actionFunction;
  return name;
};

export const getAction = name => actionRegistry[name];

export const buildConfirmableAction = (actionFunction, name) => {
  const actionName = registerAction(actionFunction, name);
  return (...actionArgs) => {
    return {
      actionName,
      actionArgs
    };
  };
};

export const getConfirmedAction = ({actionName, actionArgs}) => getAction(actionName)(...actionArgs);
