/* eslint-disable react/jsx-no-bind */
import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { getUserAvatar } from '@utils/data-table/user';
import './input-datatype-search-info-card.scss';

const InputDataTypeSearchInfoCard = ({ entry }) => (
  <div styleName="input-datatype-search-info-container">
    <div styleName="info-icon">
      {getUserAvatar(entry, 'large')}
    </div>
    <div styleName="info-data">
      <div styleName="info-data-top">
        {entry.name}
        {!entry.is_active && (
          <div styleName="info-data-inactive">
            (Inactive)
          </div>
        )}
      </div>
      <div styleName="info-data-bottom">
        {entry.email}
        {entry.phone && <div styleName="bull">&bull;</div>}
        {entry.phone}
      </div>
    </div>
  </div>
);

InputDataTypeSearchInfoCard.propTypes = {
  entry: PropTypes.object
};

export default memo(InputDataTypeSearchInfoCard);
