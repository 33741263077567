import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Popover from '@material-ui/core/Popover';
import styles from './popover.scss';

const FilterChipPopover = ({ children, classes = {}, ...passThroughProps }) => {
  const { paper: paperClasses, ...passTroughClasses } = classes;

  return (
    <Popover
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left'
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left'
      }}
      classes={{
        ...passTroughClasses,
        paper: classNames(styles.popover, paperClasses)
      }}
      {...passThroughProps}
    >
      {children}
    </Popover>
  );
};

FilterChipPopover.propTypes = {
  children: PropTypes.node,
  classes: PropTypes.object
};

export default FilterChipPopover;
