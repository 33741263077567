import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import Switch from '@material-ui/core/Switch';
import { withStyles } from '@material-ui/core/styles';
import ControlLabel from './control-label';
import './form.scss';

const CustomFormControlLabel = withStyles({
  root: {
    marginLeft: 0  // Fix margin problem when using the 'end' label placement.
  }
})(FormControlLabel);

const SwitchControl = ({ disabled, label, labelPlacement, onChange, value }) => {
  const handleChange = useCallback(event => onChange(event.target.checked), [onChange]);

  return (
    <div data-testid="switch-control" styleName="control-container switch-control">
      <FormControl component="fieldset">
        <CustomFormControlLabel
          disabled={disabled}
          control={
            <Switch
              checked={value}
              color="primary"
              onChange={handleChange}
              size="small"
            />
          }
          labelPlacement={labelPlacement}
          label={<ControlLabel disabled={disabled} label={label} />}
        />
      </FormControl>
    </div>
  );
};

SwitchControl.propTypes = {
  disabled: PropTypes.bool,
  label: PropTypes.string,
  labelPlacement: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.bool
};


SwitchControl.defaultProps = {
  labelPlacement: 'end'
};

export default SwitchControl;
