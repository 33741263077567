/* eslint-disable react/jsx-no-bind */
import React, { useContext } from 'react';

import { colors } from '@constants/colors';

import { Button } from '@mui';

import DashboardContext from './dashboard-context';
import DashboardCustomizeIcon from './dashboard-customize-icon';
import emptyDashboard from './images/emptyDashboard.svg';

import './dashboard-empty-state.scss';

const styles = {
  blueButton: {
    backgroundColor: colors.brand.blue,
    color: colors.neutral.white,
    padding: '0.5rem 1rem'
  }
};

const DashboardEmptyState = () => {
  const { setShowCustomize } = useContext(DashboardContext);
  return (
    <div styleName="empty-dashboard-container">
      <img src={emptyDashboard} alt="empty" />
      <div styleName="empty-dashboard-title">Start building your dashboard</div>
      <div styleName="empty-dashboard-subtitle">
        Your home dashboard is ready to take off! Customize it with cards to see
        what's important and keep your day on track.
      </div>
      <Button
        style={styles.blueButton}
        onClick={() => setShowCustomize(true)}
        startIcon={<DashboardCustomizeIcon />}
        data-cy="dashboardEmptyCustomizeIcon"
      >
        Customize
      </Button>
    </div>
  );
};

export default DashboardEmptyState;
