/* eslint-disable react/display-name */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable no-undefined */
import React, { useCallback, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { isEmpty, isString } from 'lodash';
import moment from 'moment-timezone';
import { SAFE_ZINDEX } from '@constants/mui-theme';
import CalendarToday from '@material-ui/icons/CalendarToday';
import { KeyboardDatePicker } from '@material-ui/pickers';

const calendarIcon = <CalendarToday style={{ fontSize: '1.125rem' }} />;

const defaultProps = {
  DialogProps: { style: SAFE_ZINDEX },
  KeyboardButtonProps: { 'aria-label': 'change date' },
  autoOk: true,
  format: 'MM/DD/YYYY',
  margin: 'normal'
};

const FormattedDatePicker = ({
  className = '',
  clearable,
  disabled,
  errorText,
  floatingLabelText,
  fullWidth,
  id,
  minDate,
  maxDate,
  onChange,
  style = {},
  styleName = '',
  value
}) => {
  const { timezoneName } = useSelector(state => state.config);

  useEffect(() => {
    if (!value && !clearable && (minDate || maxDate)) {
      onChange(minDate || maxDate);
    }
  }, [clearable, maxDate, minDate, onChange, value]);

  const onAccept = useCallback(date => {
    if (isEmpty(date)) {
      onChange(null);
      return;
    }
    let newDate = date;
    if (isString(date)) {
      newDate = moment(date, 'MM/DD/YYYY');
    }
    const fixedDate = moment.tz(newDate, timezoneName).startOf('day');
    onChange(fixedDate);
  }, [onChange, timezoneName]);

  // Add these props if they are not null (since the date picker component
  // can't handle null values for these props correctly).
  const pickerProps = useMemo(() => {
    const props = {};
    if (clearable) {
      props.clearable = clearable;
    }
    if (maxDate) {
      props.maxDate = maxDate;
    }
    if (minDate) {
      props.minDate = minDate;
    }
    if (errorText) {
      props.error = true;
      props.helperText = errorText;
    }
    return props;
  }, [clearable, errorText, maxDate, minDate]);

  return (
    <div className={className} data-testid="date-picker" style={style} styleName={styleName}>
      <KeyboardDatePicker
        {...pickerProps}
        {...defaultProps}
        disabled={disabled}
        fullWidth={fullWidth}
        id={id}
        inputProps={{style: disabled ? {color: 'rgba(0,0,0,0.3)'} : {}}}
        keyboardIcon={calendarIcon}
        label={floatingLabelText}
        onAccept={onAccept}
        onBlur={event => onAccept(event.target.value)}
        onChange={onAccept}
        value={typeof value === 'undefined' ? null : value}
      />
    </div>
  );
};

FormattedDatePicker.propTypes = {
  className: PropTypes.string,
  clearable: PropTypes.bool,
  disabled: PropTypes.bool,
  errorText: PropTypes.string,
  floatingLabelText: PropTypes.string,
  fullWidth: PropTypes.bool,
  id: PropTypes.string,
  maxDate: PropTypes.object,
  minDate: PropTypes.object,
  onChange: PropTypes.func,
  style: PropTypes.object,
  styleName: PropTypes.string,
  value: PropTypes.object
};

export default FormattedDatePicker;
