import React, { memo, useCallback, useMemo } from 'react';
import * as R from 'ramda';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import { clearDashboardDetailsDataError, updateDashboardField } from '@actions/dashboard-actions';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import MenuItem from '@material-ui/core/MenuItem';
import SharedSelectField from '@shared/select-field';
import { dashboardDetailsDataSelector } from '@selectors/dashboard-details-selector';
import { sortBy } from '@utils/shared-utils';
import './fields.scss';

const SelectField = ({ disabled, field, label, required, select }) => {
  const { dataType } = useParams();
  const dispatch = useDispatch();
  const data = useSelector(state => dashboardDetailsDataSelector(state, dataType));
  const errors = useSelector(state => state.dashboard.details?.[dataType]?.error);
  const dataTypes = useSelector(state => state.dataTypes);

  const types = useMemo(() => {
    return sortBy(R.values(dataTypes[select.type]), select.key);
  }, [dataTypes, select.key, select.type]);

  const onChange = useCallback(
    event => {
      dispatch(clearDashboardDetailsDataError(dataType, field?.split('.')));
      dispatch(updateDashboardField(dataType, field, event.target.value));
    },
    [dataType, dispatch, field]
  );

  return (
    <div styleName="field-container">
      <div styleName="field-label">
        {label}&nbsp;{required ? '*' : ''}
      </div>
      <div styleName="field-input">
        {types &&
          <FormControl error={errors?.[field]} fullWidth>
            <SharedSelectField
              disabled={disabled}
              fullWidth
              onChange={onChange}
              selectStyles={{
                root: {
                  marginLeft: '0',
                  paddingLeft: '0'
                }
              }}
              value={data?.[field]}
            >
              {types.map(type => (
                <MenuItem key={type.id} value={type.id}>
                  {type[select.key]}
                </MenuItem>
              ))}
            </SharedSelectField>
            <FormHelperText>{errors?.[field]}</FormHelperText>
          </FormControl>
        }
      </div>
    </div>
  );
};

SelectField.propTypes = {
  disabled: PropTypes.bool,
  field: PropTypes.string,
  label: PropTypes.string,
  required: PropTypes.bool,
  select: PropTypes.object
};

export default memo(SelectField);
