/* eslint-disable react/jsx-no-bind */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import PropTypes from 'prop-types';
import IconMenu from '@shared/icon-menu';
import IconMenuItem from '@shared/icon-menu-item';
import EntityTypeIcon from '@icons/entity-type-icon';
import './linked-entities-row.scss';

const Row = ({ edit, entity, removeEntity }) => {
  const remove = () => removeEntity(entity.id);

  return (
    <div styleName="row-container">
      <div styleName="row-content">
        <div styleName="row-element">
          <EntityTypeIcon type={entity.type_name} />
        </div>
        <div styleName="row-element">
          {entity.id}
        </div>
        <div styleName="row-element row-text">
          {entity.name}
        </div>
      </div>
      {edit && (
        <div onClick={event => event.stopPropagation()} role="presentation" styleName="row-action">
          <IconMenu>
            <IconMenuItem key="remove" onClick={remove}>Remove entity</IconMenuItem>
          </IconMenu>
        </div>
      )}
    </div>
  );
};

Row.propTypes = {
  edit: PropTypes.bool,
  entity: PropTypes.object,
  removeEntity: PropTypes.func
};

export default Row;
