/* eslint-disable react/jsx-key */
import React from 'react';
import PropTypes from 'prop-types';

import HorizontalFilters from './layouts/horizontal';

const Filters = ({ dataType, subType, layout = 'horizontal', children, ...passThroughProps}) => {
  switch (layout) {
  case 'horizontal':
    // Currently only have horizontal layout
    return (
      <HorizontalFilters dataType={dataType} subType={subType} {...passThroughProps}>
        {children}
      </HorizontalFilters>
    );
  default:
    return null;
  }
};

Filters.propTypes = {
  children: PropTypes.node,
  dataType: PropTypes.string,
  layout: PropTypes.string,
  subType: PropTypes.string
};

export default Filters;
