import React, { memo } from 'react';
import FormTooltip from '@forms/form-tooltip';

const ShapeTypeTooltip = () => (
  <FormTooltip>
    Select one of the following location types to start creating a location for your record.
    Once a location type has been selected, place the cursor on the map and navigate to your location of work.
    Simply click on the location point or the start and end points of your location of work to create your location.
  </FormTooltip>
);

export default memo(ShapeTypeTooltip);
