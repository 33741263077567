import {
  SET_FILTERS,
  UPDATE_FILTERS
} from './constants';

import { getDataTypeFilterDefaults, getDataTypeFilterValuesInitialized } from './selectors';

const setFiltersAction = (dataType, subType, filters) => ({
  type: SET_FILTERS,
  dataType,
  subType,
  filters
});

const updateFiltersAction = (dataType, subType, filters) => ({
  type: UPDATE_FILTERS,
  dataType,
  subType,
  filters
});

export const resetFilter = (dataType, subType) => (dispatch, getState) => {
  const defaultFilters = getDataTypeFilterDefaults(getState(), dataType, subType);
  dispatch(setFiltersAction(dataType, subType, defaultFilters));
};

export const updateFilter = (dataType, subType, filters) => (dispatch, getState) => {
  if (!getDataTypeFilterValuesInitialized(getState(), dataType, subType)) {
    const defaultFilters = getDataTypeFilterDefaults(getState(), dataType, subType);
    dispatch(setFiltersAction(dataType, subType, {...defaultFilters, ...filters}));
  } else {
    dispatch(updateFiltersAction(dataType, subType, filters));
  }
};
