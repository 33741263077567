import React, { memo, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { closeDashboardDialog } from '@actions/dashboard-actions';
import * as dialog from '@constants/dialogs';
import Dialog from '@shared/dialogs/dialog';
import DialogActions from '@shared/dialogs/dialog-actions';

const DeleteFileDialog = () => {
  const dispatch = useDispatch();

  const { activeDialogs, dialogParams } = useSelector(state => state.dashboard);

  const isDialogActive = activeDialogs[dialog.DELETE_FILE];

  const onClose = useCallback(
    () => {
      dispatch(closeDashboardDialog(dialog.DELETE_FILE));
    },
    [dispatch]
  );

  const onDelete = useCallback(
    () => {
      const { deleteFile, index } = dialogParams[dialog.DELETE_FILE] || {};
      deleteFile(index);
      onClose();
    },
    [dialogParams, onClose]
  );

  if (!isDialogActive || !dialogParams) {
    return null;
  }

  const { filename } = dialogParams[dialog.DELETE_FILE] || {};

  return (
    <Dialog
      actions={
        <DialogActions
          onCancel={onClose}
          onSubmit={onDelete}
          submitColor="secondary"
          submitLabel="DELETE"
        />
      }
      title="Delete File?"
    >
      Are you sure you want to delete file <b>{filename}</b>?
    </Dialog>
  );
};

export default memo(DeleteFileDialog);
