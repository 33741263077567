import React from 'react';
import Logo from '@icons/logo';
import City from './city';
import Footer from './footer';
import Body from './body';
import './print-map.scss';

const PrintMap = () => (
  <div styleName="print-map-container">
    <div styleName="title">
      <Logo />
      <City />
    </div>
    <Body />
    <Footer />
  </div>
);

export default PrintMap;

export { PrintMap as PurePrintMap };
