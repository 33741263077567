/* eslint-disable react/jsx-no-bind */
import React, { memo, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import PlacesAutocomplete, {
  geocodeByAddress,
  geocodeByPlaceId,
  getLatLng
} from 'react-places-autocomplete';
import InputAutocompleteContent from '@components/forms/input-autocomplete-content';

const InputAutocomplete = ({
  initName,
  inputProps,
  onGeocodeCompleted,
  onGeocodeError
}) => {
  const { mapCenter } = useSelector(state => state.config);

  const onSelect = useCallback((address, placeId, suggestion) => {  // eslint-disable-line no-unused-vars
    if (placeId) {
      geocodeByPlaceId(placeId)
        .then(results => getLatLng(results[0]))
        .then(latLng => {
          onGeocodeCompleted(latLng);
          inputProps.onChange(address);
        })
        .catch(error => onGeocodeError(error));
    } else {
      geocodeByAddress(address)
        .then(results => {
          const result = results[0];
          const latLng = getLatLng(result);
          onGeocodeCompleted(latLng, result.formatted_address);
          inputProps.onChange(result.formatted_address);
        })
        .catch(error => onGeocodeError(error));
    }
  }, [inputProps, onGeocodeCompleted, onGeocodeError]);

  const onError = useCallback((status, clearSuggestions) => {
    clearSuggestions();
    if (status === 'ZERO_RESULTS') {
      onGeocodeError('Enter a valid address or cross street');
    } else {
      onGeocodeError(status);
    }
  }, [onGeocodeError]);

  const searchOptions = useMemo(() => ({
    location: {
      lat: () => mapCenter.lat,
      lng: () => mapCenter.lng
    },
    radius: 1000,
    types: ['address']
  }), [mapCenter]);

  return (
    <PlacesAutocomplete
      googleCallbackName={initName}
      highlightFirstSuggestion
      onChange={inputProps.onChange}
      onError={onError}
      onSelect={onSelect}
      searchOptions={searchOptions}
      value={inputProps.value}
    >
      {({
        getInputProps,
        suggestions,
        getSuggestionItemProps,
        loading  // eslint-disable-line no-unused-vars
      }) => (
        <InputAutocompleteContent
          getInputProps={getInputProps}
          getSuggestionItemProps={getSuggestionItemProps}
          inputProps={inputProps}
          suggestions={suggestions}
        />
      )}
    </PlacesAutocomplete>
  );
};

InputAutocomplete.propTypes = {
  initName: PropTypes.string,
  inputProps: PropTypes.object.isRequired,
  onGeocodeCompleted: PropTypes.func,
  onGeocodeError: PropTypes.func
};

export default memo(InputAutocomplete);
