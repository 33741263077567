import React, { Fragment, memo, useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Button from '@material-ui/core/Button';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import styles from './button.scss';

const ChipButton = ({ label, values, onClick, classes = {} }) => {
  const [valueContainerRef, setValueContainerRef] = useState(null);
  const [valuesRefs, setValuesRefs] = useState({});

  const containerRefCallback = useCallback(node => {
    setValueContainerRef(node);
  }, [setValueContainerRef]);

  const valuesRefCallbacks = useMemo(() => {
    const callbacks = {};
    values.forEach(value => {
      callbacks[value] = (node) => {
        if (node) {
          setValuesRefs(previous => ({
            ...previous,
            [value]: node
          }));
        } else {
          setValuesRefs(previous => {
            const newRefs = {...previous};
            delete newRefs[value];
            return newRefs;
          });
        }
      };
    });

    return callbacks;
  }, [values, setValuesRefs]);

  const valuesVisible = useMemo(() => {
    const visible = {};
    if (valueContainerRef) {
      const gutter = 1.5 * parseInt(window.getComputedStyle(valueContainerRef)['font-size'], 10);
      const bounds = valueContainerRef.getBoundingClientRect();
      const boundsLeft = bounds.left + gutter;
      const boundsRight = bounds.right - gutter;
      Object.entries(valuesRefs).forEach(([key, node]) => {
        if (node) {
          const {left: nodeLeft, right: nodeRight} = node.getBoundingClientRect();
          visible[key] = (
            nodeRight > boundsLeft &&
            nodeLeft < boundsRight
          );
        }
      });
    }
    return visible;
  }, [valueContainerRef, valuesRefs]);

  const hiddenCount = useMemo(() => (
    Object.values(valuesVisible).filter(visible => !visible).length
  ), [valuesVisible]);

  const {root: rootClasses, label: labelClasses, ...passTroughClasses} = classes;
  const active = values.length > 0;

  return (
    <Button
      variant="outlined"
      onClick={onClick}
      classes={{
        ...passTroughClasses,
        root: classNames(styles.filterChipButton, {[styles.activeChipButton]: active}, rootClasses),
        label: classNames(styles.filterChipButtonLabel, labelClasses)
      }}
    >
      <span className={styles.label}>{label}</span>
      { active &&
        <Fragment>
          <span>:</span>
          <span
            className={styles.values}
            ref={containerRefCallback}
          >
            {values.map(value => (
              <span
                key={value}
                className={classNames(styles.value, {[styles.invisible]: !valuesVisible[value]})}
                ref={valuesRefCallbacks[value]}
              >
                {value}
              </span>
            ))}
          </span>
        </Fragment>
      }
      { hiddenCount > 0 &&
        <span>+{hiddenCount}</span>
      }
      <span className={styles.arrowContainer}>
        <ArrowDropDownIcon />
      </span>
    </Button>
  );
};

ChipButton.propTypes = {
  classes: PropTypes.object,
  label: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  values: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number]))
};

export default memo(ChipButton);
