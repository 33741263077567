import React, { useState } from 'react';
import FilePreview from '@shared/files/file-preview';
import DeleteFileDialog from '@shared/dialogs/delete-file-dialog';
import AssignTaskDialog from './assign-task-dialog';
import AddTask from './add-task';
import Details from './details';
import './content.scss';

const Content = () => {
  const [taskId, setTaskId] = useState(null);

  return (
    <div styleName="content-container">
      <Details />
      <AddTask assignTempTaskId={setTaskId} taskId={taskId} />
      <AssignTaskDialog assignTempTaskId={setTaskId} taskId={taskId} />
      <FilePreview />
      <DeleteFileDialog />
    </div>
  );
};

export default Content;
