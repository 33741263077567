import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as R from 'ramda';

import { uploadBatch } from '@actions/batch-actions';
import { closeDashboardDialog } from '@actions/dashboard-actions';
import { fetchLayerConfig } from '@actions/layers-actions';

import BatchUploadDropzone from '@components/portal/table/batch-upload-dropzone';
import BatchUploadErrors from '@components/portal/table/batch-upload-errors';
import BatchUploadSelectType from '@components/portal/table/batch-upload-select-type';

import * as dialog from '@constants/dialogs';
import {
  UPLOAD_RUNNING,
  UPLOAD_FINISHED
} from '@constants/file';

import { Select } from '@mui';

import Dialog from '@shared/dialogs/dialog';
import DialogActions from '@shared/dialogs/dialog-actions';

import { canAccessEntity } from '@utils/permission-utils';

import './batch-upload-dialog.scss';

const { MenuItem } = Select;

// Restrict file uploads to 8 mb:
const MAX_UPLOAD_SIZE = 8388608;

// Remove 'Zoning' and 'Street Use Permit' from the list of layers, and
// from the remaining layers, disable them all but the 'Hubs' one.
const filterSeattleLayers = layers => Object.values(layers)
  .filter(layer => layer.name !== 'zoning' && layer.name !== 'street_use_permits')
  .map(({ label, name }) => ({ label, name, disabled: name !== 'hubs'}));

const isJSON = file => {
  const ext = file.name.split('.').pop()
    .toLowerCase();
  return ext === 'json' || ext === 'geojson';
};

const BatchUploadLayersDialog = () => {
  const dispatch = useDispatch();
  const { activeDialogs } = useSelector(state => state.dashboard);
  const layers = useSelector(state => state.layers);
  const [errors, setErrors] = useState(null);
  const [file, setFile] = useState(null);
  const [layer, setLayer] = useState(null);
  const [status, setStatus] = useState(null);

  const isDialogActive = useMemo(() => activeDialogs[dialog.BATCH_LAYER], [activeDialogs]);

  useEffect(() => {
    // Fetch data on load.
    if (R.isEmpty(layers)) {
      dispatch(fetchLayerConfig());
    }
  }, [dispatch, layers]);

  const onClose = useCallback(
    () => {
      dispatch(closeDashboardDialog(dialog.BATCH_LAYER));
      // When closing the dialog, reset the state:
      setErrors(null);
      setFile(null);
      setLayer(null);
      setStatus(null);
    },
    [dispatch, setErrors, setLayer, setFile, setStatus]
  );

  useEffect(() => {
    // When upload finishes, close the dialog.
    if (status === UPLOAD_FINISHED) {
      onClose();
    }
  }, [onClose, status]);

  const onProgress = useCallback(event => {
    setStatus(event.loaded === event.total ? UPLOAD_FINISHED : UPLOAD_RUNNING);
  }, [setStatus]);

  const onError = useCallback(error => setErrors([error]), [setErrors]);

  const onUpload = useCallback(
    () => {
      if (file) {
        const upload = {
          active: true,
          file,
          layerType: layer,
          status: 0
        };
        dispatch(uploadBatch(upload, onProgress, onError));
      }
    },
    [dispatch, file, layer, onProgress, onError]
  );

  const filteredLayers = useMemo(() => filterSeattleLayers(layers), [layers]);

  const onChange = useCallback(event => {
    setLayer(event.target.value);
  }, [setLayer]);

  const onFileSelect = useCallback(files => {
    if (files?.length > 0) {
      const newFile = files[0];
      setFile(newFile);
      const errorList = [];
      if (newFile.size > MAX_UPLOAD_SIZE) {
        errorList.push('File must be under 8MB.');
      }
      if (newFile.size === 0) {
        errorList.push('No data in the JSON.');
      }
      if (!isJSON(newFile)) {
        errorList.push('File type is not JSON.');
      }
      if (errorList.length > 0) {
        setErrors(errorList);
      }
    } else if (file) {
      setErrors(null);
      setFile(null);
    }
  }, [file, setErrors, setFile]);

  const onFileReset = useCallback(() => {
    setErrors(null);
    setFile(null);
  }, [setErrors, setFile]);

  const renderer = useCallback(values => {
    if (values?.length === 0 || !layer) {
      return 'Select a layer';
    }
    return filteredLayers.find(item => item.name === layer).label;
  }, [filteredLayers, layer]);

  const menuItems = useMemo(() => filteredLayers.map(item => (
    <MenuItem disabled={item.disabled} key={item.name} value={item.name}>
      {item.label}
    </MenuItem>
  )), [filteredLayers]);

  const actions = useMemo(() => (
    <DialogActions
      disabledSubmit={!file || errors || !layer}
      onCancel={onClose}
      onSubmit={onUpload}
      submitLabel="UPLOAD"
    />
  ), [errors, file, layer, onClose, onUpload]);

  if (!isDialogActive || !canAccessEntity(null, 'batchfile', 'add')) {
    return null;
  }

  return (
    <Dialog actions={actions} dividers maxWidth="32rem" open title="Upload Batch: City Layers">
      <BatchUploadSelectType
        menuItems={menuItems}
        onChange={onChange}
        renderer={renderer}
        title="Select a city layer to upload"
        value={layer}
      />
      <BatchUploadDropzone
        accept="application/json,application/geo+json,.geojson"
        caption="JSON, 8MB max"
        file={file}
        onFileChange={onFileSelect}
        onFileReset={onFileReset}
        value={null /* batch upload has no drawer */}
        status={status}
      />
      {errors && <BatchUploadErrors errors={errors} />}
    </Dialog>
  );
};

export default memo(BatchUploadLayersDialog);
