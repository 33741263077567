import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { openDashboardDialog } from '@actions/dashboard-actions';
import * as dialog from '@constants/dialogs';
import Button from '@material-ui/core/Button';
import { OVERLAP_ENTITY_STATUS } from '@utils/data-detail/conflicts';
import LoadingBox from './actions-loading-box';
import ResolveBox from './actions-resolve-box';
import './actions-box.scss';

class ActionsBox extends Component {
  state = { form: null };

  onCloseForm = () => this.setState({ form: null });

  getStatus = () => {
    const { mainEntity, entity: { overlap_status } } = this.props;
    if (overlap_status[0]) {
      const overlapId = overlap_status[0].overlap;
      return mainEntity.overlap_status.find(overlap => overlap.overlap === overlapId);
    }
    return [];
  };

  onRevoke = () => {
    const status = this.getStatus();
    const params = { status };
    this.props.openDashboardDialog(dialog.OVERLAP_REVOKE, params);
  };

  onResolve = () => this.setState({ form: 'resolve' });

  isStatus = key => {
    const status = this.getStatus();
    const { overlap_entity_status } = status;
    return overlap_entity_status === OVERLAP_ENTITY_STATUS[key];
  };

  showResolve = () => {
    const { isOpportunity, permissions: { resolve } } = this.props;
    return resolve && !isOpportunity && this.isStatus('new');
  };

  showRevoke = () => {
    const { isOpportunity, permissions: { revoke } } = this.props;
    return revoke && !isOpportunity && this.isStatus('resolved');
  };

  render() {
    const { loading, mainEntity } = this.props;
    const { form } = this.state;
    const isDisabled = form !== null || loading;
    return (
      <div styleName="actions-box-container">
        <div styleName="actions-buttons">
          {this.showResolve() && (
            <div styleName="button">
              <Button
                color="primary"
                disabled={isDisabled}
                onClick={this.onResolve}
                variant="contained"
              >
                RESOLVE
              </Button>
            </div>
          )}
          {this.showRevoke() && (
            <div styleName="button">
              <Button
                disabled={isDisabled}
                onClick={this.onRevoke}
                variant="outlined"
              >
                REVOKE
              </Button>
            </div>
          )}
        </div>
        <div styleName="actions-form">
          {form === 'resolve' && <ResolveBox entity={mainEntity} onClose={this.onCloseForm} />}
          <LoadingBox />
        </div>
      </div>
    );
  }
}

ActionsBox.propTypes = {
  entity: PropTypes.object,
  isOpportunity: PropTypes.bool,
  loading: PropTypes.bool,
  mainEntity: PropTypes.object,
  openDashboardDialog: PropTypes.func,
  permissions: PropTypes.object,
  status: PropTypes.object
};

const mapStateToProps = state => {
  const { resolution: { loading } } = state.dashboard;
  return { loading };
};

const mapDispatchToProps = { openDashboardDialog };

export default connect(mapStateToProps, mapDispatchToProps)(ActionsBox);
