import React, { Fragment, memo, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import ReadOnlyField from '@components/portal/details/fields/read-only-field';
import SearchField from '@components/portal/details/fields/search-field';
import SelectField from '@components/portal/details/fields/select-field';
import TextField from '@components/portal/details/fields/text-field';
import ToggleField from '@components/portal/details/fields/toggle-field';
import { dashboardDetailsDataSelector } from '@selectors/dashboard-details-selector';
import { getFieldValue } from '@utils/dashboard-details-utils';
import { verifyPermissions } from '@utils/permission-utils';
import './section-field.scss';

const SectionField = ({ edit, field }) => {
  const { dataType } = useParams();
  const dataTypes = useSelector(state => state.dataTypes);
  const data = useSelector(state => dashboardDetailsDataSelector(state, dataType));

  const {
    key, defaultValue, hidden, label, read_only, permissions, required, select, type
  } = field;

  const loaded = useMemo(() => typeof data !== 'undefined' && data !== null, [data]);

  const value = useMemo(
    () => getFieldValue(key, select, defaultValue, data, dataTypes),
    [data, dataTypes, defaultValue, key, select]
  );

  const disabled = useMemo(() => {
    return read_only || !verifyPermissions(permissions);
  }, [permissions, read_only]);

  if (hidden || (edit && read_only)) {
    // Don't display hidden and read-only fields on edit mode.
    return null;
  }

  return (
    <div key={key} styleName="section-field">
      {type === 'search' && <SearchField disabled={disabled} edit={edit} label={label} loaded={loaded} />}
      {type !== 'search' &&
        <Fragment>
          {edit && select &&
            <SelectField disabled={disabled} field={key} label={label} required={required} select={select} />
          }
          {edit && !select && type !== 'boolean' &&
            <TextField disabled={disabled} field={key} label={label} required={required} value={value} />
          }
          {edit && !select && type === 'boolean' &&
            <ToggleField disabled={disabled} field={key} label={label} required={required} value={value} />
          }
          {!edit &&
            <ReadOnlyField label={label} loaded={loaded} type={type} value={value} />
          }
        </Fragment>
      }
    </div>
  );
};

SectionField.propTypes = {
  edit: PropTypes.bool,
  field: PropTypes.object
};

export default memo(SectionField);
