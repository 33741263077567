export const OVERLAP_STATUS = 'OVERLAP_STATUS';
export const OVERLAP_REVOKE = 'OVERLAP_REVOKE';

export const DATE_RANGE = 'DATE_RANGE';

export const BULK_DELETE_FILES = 'BULK_DELETE_FILES';
export const DELETE_FILE = 'DELETE_FILE';
export const EDIT_FILE = 'EDIT_FILE';

export const SUBMIT_PUBLIC_FORM = 'SUBMIT_PUBLIC_FORM';

export const BULK_SCHEDULE = 'BULK_SCHEDULE';
export const EDIT_SCHEDULE = 'EDIT_SCHEDULE';
export const VIEW_SCHEDULE = 'VIEW_SCHEDULE';

export const ADD_TASK = 'ADD_TASK';
export const ASSIGN_TASK = 'ASSIGN_TASK';
export const START_CYCLE = 'START_CYCLE';
export const TASK_STATUS = 'TASK_STATUS';

export const NAME_DESCRIPTION = 'NAME_DESCRIPTION';

export const DELETE_TASK_TYPE_FIELD = 'DELETE_TASK_TYPE_FIELD';

export const TASK_TYPE_DISCARD = 'TASK_TYPE_DISCARD';
export const TASK_TYPE_PUBLISH = 'TASK_TYPE_PUBLISH';

export const ADD_WORKFLOW_TASK_TYPE = 'ADD_WORKFLOW_TASK_TYPE';
export const DELETE_WORKFLOW_TASK_TYPE = 'DELETE_WORKFLOW_TASK_TYPE';

export const BATCH_ENTITY = 'BATCH_ENTITY';
export const BATCH_LAYER = 'BATCH_LAYER';

// Group dialogs:
export const ADD_TO_GROUP = 'ADD_TO_GROUP';
export const ADD_RECORDS_TO_GROUP = 'ADD_RECORDS_TO_GROUP';
export const ADD_TO_GROUP_EXCLUDE_OVERLAP = 'ADD_TO_GROUP_EXCLUDE_OVERLAP';
export const DELETE_GROUP = 'DELETE_GROUP';
export const SET_BASELINE = 'SET_BASELINE';
export const UPDATE_BASELINE = 'UPDATE_BASELINE';
export const REMOVE_DATE_RANGE = 'REMOVE_DATE_RANGE';
