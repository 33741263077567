/* eslint-disable react/display-name */
import React from 'react';
import NewBaseListItem from './base-list-item';
import EntityTypeIcon from '@icons/entity-type-icon';
import { getConfigValue } from '@utils/list-item-utils';
import './list-item.scss';

class NewEntityListItem extends NewBaseListItem {
  getType = () => {
    const typeRowValue = getConfigValue(this.props.type_name, 'type-row', this.props);
    if (typeRowValue) {
      return (
        <div styleName="footer-type">
          <span>&nbsp;&nbsp;&bull;&nbsp;&nbsp;</span>
          <span>{typeRowValue}</span>
        </div>
      );
    }
    return null;
  };
  getTypeIcon = () => (
    <div styleName="icon type-icon">
      <EntityTypeIcon type={this.props.entity_type} />
    </div>
  );
  getEntityTypeName = () => 'entity';
  onClick = () => {
    const { id, segments } = this.props;
    this.props.onClick('entity', id, segments);
  };
}

export default NewEntityListItem;
