import React from 'react';

const initialState = {
  activeSegmentId: null,
  setActiveSegmentId: () => {},
  activeType: null,
  setActiveType: () => {},
  activeStart: null,
  setActiveStart: () => {},
  activeEnd: null,
  setActiveEnd: () => {},
  geometryLocked: false,
  setGeometryLocked: () => {}
};

const InfoContext = React.createContext(initialState);

export default InfoContext;
