import React from 'react';
import TopBar from './top-bar';
import MetricsBar from './metrics-bar';

const SubNav = () => (
  <div>
    <TopBar />
    <MetricsBar />
  </div>
);

export default SubNav;
