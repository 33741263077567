import { connect } from 'react-redux';
import { openGroupsTray, setOverlapHoverGroup } from '@actions/map-actions';
import GroupListItem from '@shared/new-list-item/group-list-item';

class Group extends GroupListItem {
  onClick = () => {
    const { id, position } = this.props;
    const trayProps = {
      id,
      item: this.props,
      position
    };
    this.props.openGroupsTray(trayProps);
  };

  onMouseEnter = () => {
    // eslint-disable-next-line no-unused-vars
    const { position, ...group } = this.props;
    this.props.setOverlapHoverGroup(group);
  };

  onMouseLeave = () => {
    this.props.setOverlapHoverGroup();
  };
}

export default connect(null, { openGroupsTray, setOverlapHoverGroup })(Group);
