import React, { memo, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  closeDashboardDialog,
  revokeConflict as dashboardRevoke
} from '@actions/dashboard-actions';
import {
  revokeConflict as groupsRevoke
} from '@actions/groups-actions';
import * as dialog from '@constants/dialogs';
import Dialog from '@shared/dialogs/dialog';
import DialogActions from '@shared/dialogs/dialog-actions';
import { OVERLAP_STATUS } from '@utils/data-detail/conflicts';
import './overlap-revoke-dialog.scss';

const RevokeDialog = () => {
  const dispatch = useDispatch();
  const { activeDialogs, dialogParams } = useSelector(state => state.dashboard);
  const isDialogActive = activeDialogs[dialog.OVERLAP_REVOKE];

  const onClose = useCallback(
    () => {
      dispatch(closeDashboardDialog(dialog.OVERLAP_REVOKE));
    },
    [dispatch]
  );

  const onRevoke = useCallback(
    () => {
      const payload = { ...dialogParams[dialog.OVERLAP_STATUS] };
      // Dispatch action to revoke the conflict:
      if (location.pathname.startsWith('/group/')) {
        dispatch(groupsRevoke(payload));
      } else {
        dispatch(dashboardRevoke(payload));
      }
      onClose();
    },
    [dialogParams, dispatch, onClose]
  );

  const getStatusName = () => {
    const { status } = dialogParams[dialog.OVERLAP_REVOKE];
    const { overlap_status: overlapStatus } = status;
    if (overlapStatus === OVERLAP_STATUS.cleared) {
      // Cleared overlaps means it's resolved:
      return 'Resolved';
    }
    if (overlapStatus === OVERLAP_STATUS.pending) {
      return 'Pending';
    }
    // It's not possible to have other status on
    // the revoke dialog:
    return '';
  };

  if (!isDialogActive) {
    return null;
  }

  return (
    <Dialog
      actions={
        <DialogActions
          onCancel={onClose}
          onSubmit={onRevoke}
          submitLabel="REVOKE"
        />
      }
      title="Revoke resolution?"
    >
      <div styleName="overlap-revoke-dialog-container">
        The conflict status will change
        from <span styleName="standout-text">{getStatusName()}</span> to <span styleName="standout-text">Open</span>.
        Are you sure you want to revoke the resolution?
      </div>
    </Dialog>
  );
};

export default memo(RevokeDialog);
