import React from 'react';
import { useSelector } from 'react-redux';
import '../section/section.scss';

const CommentsHeader = () => {
  const { list } = useSelector(state => state.logs);
  return (
    <div styleName="section-header">
      <div styleName="section-header-title">
        <div styleName="section-header-title-text">Comments</div>
        <div styleName="section-header-title-count">{list?.length || 0}</div>
      </div>
    </div>
  );
};

export default CommentsHeader;
