import React from 'react';

const RemoveFromGroupIcon = () => (
  <svg width="18px" height="18px" version="1.1" viewBox="0 0 18 18">
    <defs>
      <path
        id="remove-from-group-path-1"
        d={`
            M7.5 0a7.503 7.503 0 0 1 7.35 9.001L12.219 9a1.876
            1.876 0 1 0-3.22 1.873L9 12h4.499A7.49 7.49 0 0 1
            7.5 15C3.36 15 0 11.64 0 7.5 0 3.36 3.36 0 7.5 0zm-3
            7.875a1.876 1.876 0 1 0 .001 3.751A1.876 1.876 0 0 0
            4.5 7.875zm9.75 2.064v1.122h-4.5V9.939h4.5zM7.5
            2.625a1.876 1.876 0 1 0 .001 3.751A1.876 1.876 0 0 0
            7.5 2.625z
        `}
      />
    </defs>
    <g fill="none" fillRule="evenodd" transform="translate(1.5 1.5)">
      <mask id="remove-from-group-path-2" fill="#fff">
        <use xlinkHref="#remove-from-group-path-1" />
      </mask>
      <g fill="#000" fillOpacity=".4" mask="url(#remove-from-group-path-2)">
        <path d="M-1.5-1.5h18v18h-18z" />
      </g>
    </g>
  </svg>
);

export default RemoveFromGroupIcon;
