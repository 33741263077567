import React, { memo } from 'react';
import { useSelector } from 'react-redux';
import { boundsLayersSelector } from '@selectors/layers-selector';
import Layers from './layers';

const LayerList = () => {
  // Return only layers within bounds (and the visible ones):
  const layers = useSelector(boundsLayersSelector);
  return Object.keys(layers).map(name => <Layers key={`layer-${name}`} {...layers[name]} />);
};

export default memo(LayerList);
