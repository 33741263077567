/* eslint-disable react/display-name */
/*
 * Layout for the workflow details page, it's similar
 * to the task template one, but this one has a drawer
 * first.
 */
import React from 'react';
import LoadingIndicator from '@components/details/loading-indicator';
import './details-layout.scss';

const WorkflowLayout = (Drawer, Preview) => props => (
  <div styleName="details-layout" data-testid="workflow-layout">
    <LoadingIndicator />
    <div styleName="drawer-container">
      <Drawer {...props} />
    </div>
    <div styleName="preview-container">
      <Preview {...props} />
    </div>
  </div>
);

export default WorkflowLayout;
