/* eslint-disable react/jsx-no-bind */
import React from 'react';
import PropTypes from 'prop-types';
import CheckboxControl from '@shared/form/checkbox-control';
import './filter-item.scss';

// A base class for dialogs that shows a list of checkboxes.
const FilterItem = ({ checked, id, item, onCheck }) => {
  const color = item.attrs ? item.attrs.color : null;
  return (
    <CheckboxControl
      label={
        <div>
          {color && <div styleName="color-icon" style={{ backgroundColor: color }} />}
          {item.name}
        </div>
      }
      onChange={() => onCheck(id)}
      value={checked}
    />
  );
};

FilterItem.propTypes = {
  checked: PropTypes.bool,
  id: PropTypes.string,
  item: PropTypes.object,
  onCheck: PropTypes.func
};

export default FilterItem;
