import React from 'react';
import PropTypes from 'prop-types';
import './view-field.scss';

const Row = ({ label, labelStyle, style, value, valueStyle }) => (
  <div styleName="view-field view-field-row" style={style}>
    <div styleName="view-field-label" style={labelStyle}>
      {label}
    </div>
    <div styleName="view-field-value" style={valueStyle}>
      {value}
    </div>
  </div>
);

Row.propTypes = {
  label: PropTypes.string,
  labelStyle: PropTypes.object,
  style: PropTypes.object,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  valueStyle: PropTypes.object
};

export default Row;
