/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/mouse-events-have-key-events */
import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Tooltipable from '@shared/tooltipable';
import {
  calculateGanttBarWidth,
  calculateGanttBarStartWidth
} from '@utils/timeline-chart-utils';
import './timeline-chart.scss';

class TimelineChartRow extends Tooltipable {
  render() {
    const { agencyTypes, dateUnit, isSmall, minDate, popoverContent, row } = this.props;
    const isAllBar = isSmall && row.name === 'All';
    const spacerStyle = {
      width: calculateGanttBarStartWidth(row.start_date, minDate, dateUnit)
    };
    const rowColor = row.color || agencyTypes[row.agency_type] && agencyTypes[row.agency_type].color;
    const labelStyle = { color: rowColor };
    const barStyle = {
      backgroundColor: rowColor,
      opacity: 0.5,
      width: calculateGanttBarWidth(row.start_date, row.end_date, dateUnit)
    };
    const smallStyle = isSmall ? `timeline-chart-row-small${isAllBar ? '-all' : ''}` : '';
    const rowStyle = `timeline-chart-row ${smallStyle}`;
    return (
      <div styleName={rowStyle}>
        <div style={spacerStyle} />
        <div styleName="timeline-chart-row-container">
          {!isAllBar && (
            <div
              styleName="timeline-chart-row-label"
              style={labelStyle}
            >
              {row.name}
            </div>
          )}
          <div
            onMouseOver={this.hoverOn}
            onMouseOut={this.hoverOff}
            role="presentation"
            styleName="timeline-chart-row-bar"
            style={barStyle}
          />
        </div>
        {this.renderTooltip(popoverContent(row))}
      </div>
    );
  }
}

TimelineChartRow.propTypes = {
  agencyTypes: PropTypes.object,
  dateUnit: PropTypes.number,
  isSmall: PropTypes.bool,
  minDate: PropTypes.object,
  popoverContent: PropTypes.func,
  row: PropTypes.object
};

TimelineChartRow.defaultProps = {
  tooltipStyle: {}  // Reset style.
};

const mapStateToProps = state => {
  const { agency_type } = state.dataTypes;
  return {
    agencyTypes: agency_type
  };
};

export default connect(mapStateToProps, {})(TimelineChartRow);
