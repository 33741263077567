import NewBaseListItem from './base-list-item';
import { getGroupIcon } from '@utils/icon-utils';

class GroupListItem extends NewBaseListItem {
  getTitleName = () => this.props.name;
  getHeaderIcon = () => getGroupIcon();
  getTypeIconName = () => 'group_work';
  getEntityTypeName = () => 'group';
  getContent = () => null;
  getFooter = () => null;
}

export default GroupListItem;
