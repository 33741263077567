/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/jsx-no-bind */
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { push } from 'connected-react-router';
import WorkflowIcon from '@icons/workflow-icon';
import DefaultDate from '@shared/formatted-date-helper';
import './cycle-cards.scss';

const CycleCards = ({ cycles }) => {
  const dispatch = useDispatch();
  const [open, setOpen] = useState({});

  const goToCycleEdit = id => {
    const source = location.pathname;
    dispatch(push({ pathname: `/cycle/${id}`, state: { source } }));
  };

  const toggleOpen = id => setOpen({ [id]: !open[id] });

  return cycles.map(cycle => (
    <div
      key={cycle.id}
      onClick={() => toggleOpen(cycle.id)}
      role="presentation"
      styleName={`cycles-card ${open[cycle.id] ? 'active-card' : ''}`}
    >
      <div styleName="cycle-item-header">
        <div styleName="cycle-item-header-icon">
          <WorkflowIcon />
        </div>
        <div styleName="cycle-item-header-name">
          {cycle.workflow_name}
        </div>
      </div>
      <div styleName="cycle-item-name">
        {cycle.name}
      </div>
      <div styleName="cycle-item-footer">
        <div styleName="cycle-item-footer-item">
          {cycle.task_count} Tasks
        </div>
        <div styleName="cycle-item-footer-bullet">&bull;</div>
        <div styleName="cycle-item-footer-item">
          <DefaultDate value={cycle.created} />
        </div>
      </div>
      {open[cycle.id] && (
        <div styleName="cycle-item-open-link" onClick={() => goToCycleEdit(cycle.id)} role="presentation">
          VIEW DETAILS
        </div>
      )}
    </div>
  ));
};

CycleCards.propTypes = {
  cycles: PropTypes.array
};

export default CycleCards;
