// This component renders a user avatar, if the user is disabled,
// it renders it with a bad with the 'block' icon.
import React from 'react';
import PropTypes from 'prop-types';
import Avatar from '@material-ui/core/Avatar';
import { makeStyles } from '@material-ui/core/styles';
import BadgeIcon from '@shared/badge-icon';

const useStyles = makeStyles(theme => ({
  small: {
    fontSize: '0.875rem',
    width: theme.spacing(3),
    height: theme.spacing(3)
  },
  medium: {
    fontSize: '1rem',
    width: theme.spacing(4),
    height: theme.spacing(4)
  },
  large: {
    fontSize: '1.25rem',
    width: theme.spacing(5),
    height: theme.spacing(5)
  },
  giant: {
    fontSize: '2.5rem',
    width: theme.spacing(10),
    height: theme.spacing(10)
  }
}));

const UserAvatar = ({ onClick, size = 'medium', user }) => {
  const classes = useStyles();
  const initial = user?.name ? user.name.substring(0, 1).toUpperCase() : '';
  const opacity = user?.is_active ? 1 : 0.2;

  const avatar = (
    <Avatar className={classes[size]} onClick={onClick} style={{opacity}}>
      {initial}
    </Avatar>
  );

  if (user?.is_active) {
    return avatar;
  }

  return (
    <BadgeIcon size={16}>
      {avatar}
    </BadgeIcon>
  );
};

UserAvatar.propTypes = {
  onClick: PropTypes.func,
  size: PropTypes.string,
  user: PropTypes.object
};

export default UserAvatar;
