/* eslint-disable jsx-a11y/mouse-events-have-key-events */
import React from 'react';
import PropTypes from 'prop-types';
import { actionsMap } from '@constants/logs';
import Tooltipable from '@shared/tooltipable';
import './icon.scss';

class Icon extends Tooltipable {
  getActionTemplate = () => {
    const { action } = this.props;
    return actionsMap[action];
  };
  getTooltip = () => {
    const template = this.getActionTemplate();
    const { tooltip } = template;
    if (typeof tooltip === 'string') {
      return tooltip;
    }
    const { attrs, type } = this.props;
    return tooltip(type, attrs);
  };
  getIcon = () => {
    const template = this.getActionTemplate();
    const { icon } = template;
    if (typeof icon === 'object') {
      return icon;
    }
    const { attrs } = this.props;
    return icon(attrs);
  };
  render() {
    return (
      <div
        onMouseOver={this.hoverOn}
        onMouseOut={this.hoverOff}
        role="presentation"
        styleName="activity-logs-icon"
      >
        {this.getIcon()}
        {this.renderTooltip(this.getTooltip())}
      </div>
    );
  }
}

Icon.propTypes = {
  action: PropTypes.string,
  attrs: PropTypes.object,
  type: PropTypes.string
};

export default Icon;
