import {
  CARD_LOAD_STYLE_CHART,
  CARD_SIZE_LG,
  CARD_SIZE_SM,
  CARD_TYPE_EMBED
} from './constants';

export const DEFAULT_LOAD_HEIGHT = '500px';

export const DASHBOARD_SIZE_OPTIONS = [
  {
    label: 'Large',
    value: CARD_SIZE_LG
  },
  {
    label: 'Small',
    value: CARD_SIZE_SM
  }
];

export const CARD_CONFIG = {
  [CARD_TYPE_EMBED]: {
    getCardData: async ({ card }) => {
      const dataContainer = await Promise.resolve(() => ({
        data: card?.embedLink
      }));
      return dataContainer();
    },
    loadStyle: CARD_LOAD_STYLE_CHART
  }
};
