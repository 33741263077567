import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getCachedOverlapEntityIds, getOverlapTrayItems } from './selectors';
import { addToOverlapCache, removeEntitiesFromOverlapCacheAction } from './actions';

export const useWatchOverlapEntitiesCache = () => {
  const dispatch = useDispatch();
  const {entities} = useSelector(getOverlapTrayItems);
  const overlapTrayEntityCacheIds = useSelector(getCachedOverlapEntityIds);
  const entityIds = useMemo(() => {
    const ids = {};
    Object.entries(entities).forEach(([type, typeEntities]) => {
      ids[type] = new Set();
      typeEntities.forEach(({id: entityId}) => {
        ids[type].add(entityId);
      });
    });
    return ids;
  }, [entities]);
  const [discardEntities, addEntities] = useMemo(() => {
    let discard = [];
    const add = {};
    Object.entries(entityIds).forEach(([type, typeIds]) => {
      if (overlapTrayEntityCacheIds[type]) {
        discard = [...discard, ...Array.from(overlapTrayEntityCacheIds[type]).filter(id => !typeIds.has(id))];
        const adding = Array.from(typeIds).filter(id => !overlapTrayEntityCacheIds[type].has(id));
        if (adding.length > 0) {
          add[type] = adding;
        }
      } else if (typeIds.size > 0) {
        add[type] = Array.from(typeIds);
      }
    });
    Object.entries(overlapTrayEntityCacheIds).forEach(([type, typeIds]) => {
      if (!entityIds[type]) {
        discard = [...discard, ...Array.from(typeIds)];
      }
    });
    return [discard, add];
  }, [overlapTrayEntityCacheIds, entityIds]);

  useEffect(() => {
    if (Object.keys(addEntities).length > 0) {
      if (discardEntities.length > 0) {
        dispatch(removeEntitiesFromOverlapCacheAction(discardEntities));
      }
      dispatch(addToOverlapCache(addEntities));
    }
  }, [dispatch, discardEntities, addEntities]);
};
