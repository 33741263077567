import React, { useContext, memo } from 'react';
import DetailsContext from '@components/workflow/details-context';
import Status from './activity/status';
import Response from './activity/response';
import Comments from './activity/comments';
import './activities-content.scss';

const Divider = () => <div styleName="activities-divider" />;

const ActivitiesContent = () => {
  const { taskType } = useContext(DetailsContext);
  return (
    <div styleName="activities-content">
      <Status />
      <Divider />
      {/* Only show the response section if it's enable the the current task template. */}
      {taskType.response && <Response />}
      {taskType.response && <Divider />}
      <Comments />
    </div>
  );
};

export default memo(ActivitiesContent);
