/* eslint-disable react/jsx-no-bind */
import React, { memo, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import FormField from '@components/entity/info/drawer/form/form-tab/form-field';
import SegmentTitle from '@components/entity/info/drawer/form/form-tab/segment-title';
import Title from '@components/entity/info/drawer/form/form-tab/title';
import { isReadOnly } from '@utils/permission-utils';
import '../../../../../forms/forms.scss';

const FormTab = ({ tab }) => {
  const { dataType } = useParams();
  const readOnly = useMemo(() => isReadOnly(dataType), [dataType]);
  return (
    <div key={`tab-section-${tab.id}`} styleName="form-tab">
      {tab.type !== 'segment' && <Title tab={tab} />}
      {tab.type === 'segment' && <SegmentTitle readOnly={readOnly} tab={tab} />}
      <div>
        {tab.fields.map(fieldName => (
          <FormField fieldName={fieldName} key={fieldName} tab={tab} />
        ))}
      </div>
    </div>
  );
};

FormTab.propTypes = {
  tab: PropTypes.object
};

export default memo(FormTab);
