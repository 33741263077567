/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { isEmpty } from 'lodash';
import { Tabs, Tab } from 'material-ui/Tabs';
import { openDashboardDialog } from '@actions/dashboard-actions';
import { dismissBaselineAlert } from '@actions/groups-actions';
import { getGroupDetailsConfig, isWorkflowEnabled } from '@constants/config';
import * as dialog from '@constants/dialogs';
import { mapTrayStyles } from '@constants/mui-theme';
import AddIcon from '@material-ui/icons/Add';
import Badge from '@material-ui/core/Badge';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import StartCycleDialog from '@shared/dialogs/start-cycle-dialog';
import { canAccessWorkflow, getUserId } from '@utils/permission-utils';
import { withRouter } from '@utils/router-utils';
import { hasBaselineChanged } from '@utils/timeline-chart-utils';
import Details from './details';
import CycleList from './cycle-list';
import EntityList from './entity-list';
import Metrics from './metrics';
import './drawer.scss';

class Drawer extends Component {
  state = { activeTab: 'details' };

  onDetailsActive = () => this.setState({ activeTab: 'details' });

  onCyclesActive = () => this.setState({ activeTab: 'cycles' });

  onListActive = () => this.setState({ activeTab: 'list' });

  onMetricsTabActive = () => {
    const { group } = this.props;
    const ownerId = group.owner.id;
    // Only issue the API call to dismiss the alert, if we
    // show the alert (it will only appear if the baseline
    // has changes, and if the 'dismissed' flag from the
    // backend is not set).
    //
    // Also, to be able to dismiss it, the user must
    // be the group owner.
    if (this.hasChanges() && getUserId() === ownerId) {
      this.props.dismissBaselineAlert(group.id);
    }
    this.setState({ activeTab: 'metrics' });
  };

  hasChanges = () => {
    const { group } = this.props;
    const { baseline, current } = group;
    return hasBaselineChanged(baseline, current);
  };

  getTabs = () => {
    const { groupType } = this.props.params;
    return getGroupDetailsConfig(groupType).tabs;
  };

  tabExists = (tabs, id) => tabs.find(tab => tab.id === id);

  getTabProps = tab => {
    if (this.state.activeTab === tab) {
      return mapTrayStyles.activeTab;
    }
    return mapTrayStyles.tab;
  };

  renderDetailsTab = tabs => {
    if (this.tabExists(tabs, 'details')) {
      return (
        <Tab {...this.getTabProps('details')} label="DETAILS" onActive={this.onDetailsActive}>
          {this.state.activeTab === 'details' && <Details {...this.props} />}
        </Tab>
      );
    }
    return null;
  };

  renderListTab = tabs => {
    if (this.tabExists(tabs, 'list')) {
      return (
        <Tab {...this.getTabProps('list')} label="LIST" onActive={this.onListActive}>
          {this.state.activeTab === 'list' && <EntityList />}
        </Tab>
      );
    }
    return null;
  };

  renderMetricsTab = tabs => {
    if (this.tabExists(tabs, 'metrics')) {
      const metricsWithBadge = (
        <Badge color="secondary" variant="dot">
          <Typography>METRICS</Typography>
        </Badge>
      );
      const { group } = this.props;
      return (
        <Tab
          buttonStyle={{
            ...(this.state.activeTab === 'metrics' ? mapTrayStyles.activeTab.buttonStyle : mapTrayStyles.tab.buttonStyle),
            opacity: group.id ? '1' : '0.5'
          }}
          label={this.hasChanges() ? metricsWithBadge : 'METRICS'}
          onActive={this.onMetricsTabActive}
          disabled={!group.id}
        >
          {this.state.activeTab === 'metrics' && <Metrics />}
        </Tab>
      );
    }
    return null;
  };

  renderCyclesTab = tabs => {
    if (this.tabExists(tabs, 'cycles') && isWorkflowEnabled()) {
      return (
        <Tab {...this.getTabProps('cycles')} label="WORKFLOWS" onActive={this.onCyclesActive}>
          {this.state.activeTab === 'cycles' && <CycleList />}
        </Tab>
      );
    }
    return null;
  };

  getStyles = () => {
    const { isTrayEmpty } = this.props;
    if (isTrayEmpty) {
      return {};
    }
    return {
      opacity: 0.4,
      pointerEvents: 'none',
      userSelect: 'none'
    };
  };

  startCycle = () => {
    const { group } = this.props;
    const params = { groupId: group.id, reloadGroup: true };
    this.props.openDashboardDialog(dialog.START_CYCLE, params);
  };

  render() {
    const { group } = this.props;
    const tabs = this.getTabs();
    const { activeTab } = this.state;
    const canStartWorkflow = canAccessWorkflow('cycle', 'add');
    const renderActions = activeTab === 'cycles' && group && group.id && canStartWorkflow;
    return (
      <div styleName="group-drawer-container" style={this.getStyles()}>
        <div styleName="group-drawer-tabs-container">
          <Tabs {...mapTrayStyles.tabs}>
            {this.renderDetailsTab(tabs)}
            {this.renderListTab(tabs)}
            {this.renderMetricsTab(tabs)}
            {this.renderCyclesTab(tabs)}
          </Tabs>
        </div>
        {renderActions && (
          <div styleName="group-drawer-bottom-actions" role="presentation">
            <Button startIcon={<AddIcon />} onClick={this.startCycle} color="primary" fullWidth>
              START NEW
            </Button>
          </div>
        )}
        {isWorkflowEnabled() && canStartWorkflow && <StartCycleDialog />}
      </div>
    );
  }
}

Drawer.propTypes = {
  dismissBaselineAlert: PropTypes.func,
  group: PropTypes.object,
  isTrayEmpty: PropTypes.bool,
  openDashboardDialog: PropTypes.func,
  params: PropTypes.object
};

const mapStateToProps = state => {
  const { group } = state.groups.edit;
  const { trays } = state.map;
  const isTrayEmpty = isEmpty(trays);
  return { group, isTrayEmpty };
};

const mapDispatchToProps = {
  dismissBaselineAlert,
  openDashboardDialog
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Drawer));
